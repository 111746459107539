import React, {
  useContext
} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  InputAdornment,
  TextField,
  Toolbar,
  Typography,
  IconButton,
  Switch,
  TextareaAutosize,
  Tooltip
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import InfoIcon from '@material-ui/icons/Info'
import UserContext from '../contexts/UserContext'

function descendingComparator (a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator (order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort (array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function EnhancedTableHead ({
  headCells,
  classes,
  order,
  orderBy,
  onRequestSort
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding='default'
            width={headCell.width}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.array.isRequired
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  title: {
    flex: '1 1 60%'
  },
  buttonMargin: {
    marginRight: 20
  }
}))

const EnhancedTableToolbar = ({
  handleSearch
}) => {
  const classes = useToolbarStyles()

  return (
    <Toolbar
      className={clsx(classes.root)}
    >
      {' '}
      <TextField
        id='input-with-icon-textfield'
        label='Search'
        className={classes.margin}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
      <Typography
        className={classes.title}
        color='inherit'
        variant='subtitle1'
        component='div'
      />

    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  handleSearch: PropTypes.func.isRequired
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  error: {
    marginTop: 10,
    color: 'red'
  },
  pending: {
    backgroundColor: '#fff9c4'
  }
}))

export default function TeamsTable ({
  rowsData,
  headCells,
  orderby,
  handleEdit,
  handleSave,
  handleCancel,
  setInputField,
  error,
  switchToggle,
  setTextAreaField,
  revokeAll,
  handleTableSearch,
  sortorder
}) {
  const classes = useStyles()
  const [order, setOrder] = React.useState(sortorder || 'asc')
  const [page, setPage] = React.useState(0)
  const [dense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [orderBy, setOrderBy] = React.useState(orderby)
  const [rows, setrows] = React.useState(rowsData)
  const {
    user
  } = useContext(UserContext)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value))
    setPage(0)
  }

  // to get the field values based on condition
  const getField = (type, value, id, head, index) => {
    switch (type) {
    case 'edit':
      return (
        !id.edit ? (
          <IconButton
            onClick={() => handleEdit(id, rows)}
            disabled={user.switch_user}
          >
            {' '}
            <EditIcon color='primary' />
            {' '}
          </IconButton>
        ) : (
          <div>
            <IconButton onClick={() => handleSave(id, rows)}>
              {' '}
              <CheckIcon color='primary' />
              {' '}
            </IconButton>
            <IconButton onClick={() => handleCancel(id, rows)}>
              {' '}
              <CloseIcon color='secondary' />
              {' '}
            </IconButton>
          </div>
        )
      )
    case 'input':
      return (
        id.edit ? (
          <div>
            <TextField
              id={`input${id.key}`}
              onChange={setInputField}
              defaultValue={id.name}
              style={{
                width: '80%'
              }}
            />
            {error ? (
              <div className={classes.error}>This field is required</div>
            ) : null}
          </div>
        ) : id.name
      )
    case 'toggle':
      return (
        <span>
          <Switch
            checked={id.toggle ||
              (id.cart === 'NA' && id.pending === 'NA' && revokeAll)}
            onChange={() => switchToggle(id, rows)}
            disabled={id.cart !== 'NA' || id.pending !== 'NA'}
          />
          {id.cart !== 'NA' || id.pending !== 'NA' ? (
            <Tooltip title={(
              <span style={{
                fontSize: 12,
                fontFamily: 'Verdana'
              }}
              >
                {id.cart !== 'NA' ? id.cart : id.pending}
              </span>
            )}
            >
              <InfoIcon color='action' fontSize='small' />
            </Tooltip>
          ) : null}
        </span>
      )
    case 'textarea':
      return (
        id.toggle ? (
          <TextareaAutosize
            style={{
              width: '80%'
            }}
            rowsMin={2}
            aria-label='comments-textarea'
            onChange={(e) => setTextAreaField(e, id, rows)}
            placeholder='Leave a reason'
          />
        ) : null
      )
    case 'date':
      return id[head.id.split('_sort')[0]]
    default:
      return value
    }
  }

  // function to handle search
  const handleSearch = (event) => {
    const data = rowsData
    let filteredDatas = []
    filteredDatas = data.filter((e) => {
      const mathesItems = Object.values(e)
      let retVal = null
      for (const e1 of mathesItems) {
        const regex = event.target.value.toLowerCase()

        if (typeof e1 === 'string') {
          retVal = e1.toLowerCase().match(regex)
          if (retVal !== null) {
            break
          }
        }
      }

      return retVal
    })

    setrows(filteredDatas)
    if (handleTableSearch) {
      handleTableSearch(event.target.value)
    }
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  return (
    <div className={classes.root}>
      <EnhancedTableToolbar
        handleSearch={handleSearch}
      />
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby='tableTitle'
          size={dense ? 'small' : 'medium'}
          aria-label='enhanced table'
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <>
                    <TableRow
                      tabIndex={-1}
                      key={row.id}
                      className={(row.cart &&
                        (row.cart !== 'NA' || row.pending !== 'NA'))
                        ? classes.pending
                        : null}
                    >

                      {headCells.map((head) => (

                        <TableCell
                          key={head.id}
                          component='th'
                          align='left'
                          id={labelId}
                          scope='row'
                          padding={
                            head.id === 'action'
                              ? 'none'
                              : 'default'
                          }
                          width={head.width}
                        >
                          {getField(head.type ? head.type : 'none',
                            row[head.id],
                            row,
                            head,
                            index)}
                        </TableCell>

                      ))}
                    </TableRow>

                  </>
                )
              })}
            {emptyRows > 0 && (
              <TableRow style={{
                height: (dense ? 33 : 53) * emptyRows
              }}
              >
                <TableCell colSpan={headCells.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  )
}
TeamsTable.propTypes = {
  rowsData: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
  orderby: PropTypes.string.isRequired,
  handleSave: PropTypes.func,
  handleEdit: PropTypes.func,
  handleCancel: PropTypes.func,
  setInputField: PropTypes.func,
  error: PropTypes.bool,
  switchToggle: PropTypes.func,
  setTextAreaField: PropTypes.string,
  revokeAll: PropTypes.bool,
  handleTableSearch: PropTypes.func
}
