import React, {
  useContext,
  useRef
} from 'react'
import {
  LinearProgress,
  makeStyles,
  Grid,
  Typography,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  CardActions,
  Button,
  Switch
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import queryString from 'query-string'
import RadioButtons from '../inputs/radio'
import requestAccess from '../../services/api/requestaccessService'
import ToolsService from '../../services/api/toolsService'
import TeamsTable from '../sections/mordernTable'
import UserContext from '../contexts/UserContext'
import CartService from '../../services/api/cartService'
import AlertDialog from '../alertbox/alertboxComponent'
import EmailServices from '../../services/api/emailServices'
import LoginService from '../../services/api/loginService'

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  },
  inputDisplay: {
    width: '100%'
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  button2: {
    color: 'pink'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  alerttext: {
    fontStyle: 'italic',
    padding: 100,
    opacity: 0.6,
    textAlign: 'center'
  }
}))

export default function RevokeAccessComponent (props) {
  const queryparams = queryString.parse(props.location.search)
  const classes = useStyle()
  const [loading, setLoading] = React.useState(false)
  const [loadingbar, setLoadingBar] = React.useState(false)
  const {
    user, setUser
  } = useContext(UserContext)
  const form = useRef(null)
  const [radioValue, setRadioValue] = React.useState('Users')
  const [responseUsers, setResponseUsers] = React.useState([])
  const [responseGroups, setResponseGroups] = React.useState([])
  const [allUsers, setAllUsers] = React.useState([])
  const [alltools, setAlltools] = React.useState([])
  const [allProjects, setAllprojects] = React.useState([])
  const [allRoles, setAllRoles] = React.useState([])
  const [button, setButton] = React.useState(null)
  const [successAlert, setsuccessAlert] = React.useState(false)
  const [successalertMessage, setSuccessalertMessage] = React.useState(null)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [failalertMessage, setFailalertMessage] = React.useState(null)
  const [alertbox, setAlerbox] = React.useState(false)
  const [alertboxMessage, setAlertBoxMessage] = React.useState(null)
  const [revokeAll, setRevokeAll] = React.useState(false)
  const [formData, setFormData] = React.useState({
    revokeRadio: '',
    chooseUser: '',
    chooseUserName: '',
    tools: '',
    projectkey: '',
    key: '',
    rolename: '',
    comments: ''
  })

  const getRevokeHead = () => (
    <span>
      Revoke All
      {' '}
      <Switch checked={revokeAll} onChange={() => switchAllToggle()} />
    </span>
  )

  const headCells1 = [
    {
      id: 'group_name',
      numeric: false,
      label: 'Group Name',
      width: '40%'
    },
    {
      id: 'revoke',
      numeric: false,
      label: getRevokeHead(),
      width: '20%',
      type: 'toggle',
      toggle: false
    }
  ]
  const headCells2 = [
    {
      id: 'username',
      numeric: false,
      label: 'Username',
      width: '30%'
    },
    {
      id: 'revoke',
      numeric: false,
      label: getRevokeHead(),
      width: '20%',
      type: 'toggle',
      toggle: false
    }
  ]

  /* Switch All toggle true which is Revoking all at one click */
  const switchAllToggle = () => {
    setRevokeAll(!revokeAll)
    let tempArray = []
    if (radioValue === 'Users') {
      tempArray = responseUsers
    } else {
      tempArray = responseGroups
    }
    tempArray.map((item, index) => {
      if (item.cart === 'NA' && item.pending === 'NA') {
        tempArray[index].toggle = !revokeAll
      }
    })

    if (radioValue === 'Users') {
      setResponseUsers([...tempArray])
    } else {
      setResponseGroups([...tempArray])
    }
  }

  /* to change radio option */
  const changeOption = (value) => {
    setRadioValue(value)
    resetForm()
  }

  /* to get the available tools for tool selection dropdown when the page gets loaded */
  React.useEffect(() => {
    ToolsService.getAvailableTools('idms').then((response) => {
      if (response.data) {
        setAlltools(response.data)
        if (queryparams.username) {
          requestAccess.getallusersforautocomplete(queryparams.username)
            .then((response1) => {
              const filtervalue = response1.data
                .filter((item) => item.name.toLowerCase() ===
                queryparams.username.toLowerCase())
              if (filtervalue.length && filtervalue.length === 1) {
                if (queryparams.tool) {
                  const filtervalue1 = response.data.filter((item) => item.name
                    .toLowerCase() === queryparams.tool.toLowerCase())
                  if (filtervalue1.length && filtervalue1.length === 1) {
                    if (queryparams.project) {
                      requestAccess.getprojects(filtervalue1[0].name,
                        queryparams.project).then((response2) => {
                        const filtervalue2 = response2.data
                          .filter((item) => item.key.toLowerCase() ===
                          queryparams.project.toLowerCase())
                        if (filtervalue2.length && filtervalue2.length === 1) {
                          changeUser(filtervalue[0], filtervalue1[0],
                            filtervalue2[0])
                        } else {
                          changeUser(filtervalue[0], filtervalue1[0])
                        }
                      })
                    } else {
                      changeUser(filtervalue[0], filtervalue1[0])
                    }
                  }
                } else {
                  changeUser(filtervalue[0])
                }
              }
            })
        } else if (queryparams.tool) {
          setRadioValue('Groups')
          const filtervalue1 = response.data.filter((item) => item.name
            .toLowerCase() === queryparams.tool.toLowerCase())
          if (filtervalue1.length && filtervalue1.length === 1) {
            if (queryparams.project) {
              requestAccess.getprojects(filtervalue1[0].name,
                queryparams.project).then((response2) => {
                const filtervalue2 = response2.data.filter((item) => item.key
                  .toLowerCase() === queryparams.project.toLowerCase())
                if (filtervalue2.length && filtervalue2.length === 1) {
                  setFormData({
                    ...formData,
                    projectkey: `${filtervalue2[0].key} - ${filtervalue2[0]
                      .key_app_name}`,
                    key: filtervalue2[0].key,
                    tools: filtervalue1[0]
                  })
                  setResponseGroups([])
                  requestAccess.getProjectRoles(filtervalue1[0].name,
                    filtervalue2[0].key, true)
                    .then((response3) => {
                      setLoading(false)
                      setAllRoles(response3.data)
                      if (queryparams.role) {
                        const filtervalue3 = response3.data
                          .filter((item) => item.name.toLowerCase() ===
                          queryparams.role.toLowerCase())
                        if (filtervalue3.length && filtervalue3.length === 1) {
                          const object = new Object()
                          object.target = {
                            value: filtervalue3[0].name
                          }
                          changeRole(object, filtervalue1[0], filtervalue2[0])
                        }
                      }
                    })
                } else {
                  setFormData({
                    ...formData,
                    tools: filtervalue1[0]
                  })
                }
              })
            } else {
              setFormData({
                ...formData,
                tools: filtervalue1[0]
              })
            }
          }
        }
      }
    })
  }, [])

  /* reset form function */
  const resetForm = () => {
    setFormData({
      revokeRadio: '',
      chooseUser: '',
      chooseUserName: '',
      tools: '',
      projectkey: '',
      key: '',
      rolename: '',
      comments: ''
    })
    setAllUsers([])
    setAllprojects([])
    setAllRoles([])
    setResponseUsers([])
    setResponseGroups([])
    setRevokeAll(false)
    seterrorAlert(false)
  }

  // Get Users for auto complete when we type any username
  const getUsers = (username) => {
    setFormData({
      ...formData,
      searchkey: username
    })
    setLoadingBar(true)
    requestAccess.getallusersforautocomplete(username).then((response) => {
      setLoadingBar(false)
      setAllUsers(response.data)
    })
  }

  // Sets the selected user in the userform fields
  const changeUser = (value, tool, project) => {
    if (value) {
      if (project) {
        changeProjectKey(project, tool, value)
      } else {
        setFormData({
          ...formData,
          chooseUser: `${value.name} - ${value.displayname
            ? value.displayname
            : null}`,
          chooseUserName: value.name,
          tools: tool || '',
          projectkey: ''
        })
        setResponseGroups([])
        setResponseUsers([])
      }
    }
    setAllUsers([])
  }

  /* Handle Tool change in tool field */
  const changeTool = (e) => {
    setFormData({
      ...formData,
      tools: e.target.value,
      projectkey: '',
      rolename: ''
    })
    setAllprojects([])
    setResponseGroups([])
    setResponseUsers([])
  }

  /* function to get the projects list based on the search key */
  const getProjects = (toolname, value) => {
    if (toolname.is_key) {
      setLoadingBar(true)
      requestAccess.getprojects(toolname.name, value).then((response) => {
        setAllprojects(response.data)
        setLoadingBar(false)
      })
    }
  }

  /** Function to change the project key */
  const changeProjectKey = (value, tool, userdata) => {
    if (value) {
      const key = value.key ? value.key : value.name
      setFormData({
        ...formData,
        projectkey: value.key
          ? `${value.key} - ${value.key_app_name}`
          : value.name,
        key: value.key,
        tools: tool || formData.tools,
        chooseUser: userdata
          ? `${userdata.name} - ${userdata.displayname}`
          : formData.chooseUser,
        chooseUserName: userdata ? userdata.name : formData.chooseUserName
      })
      setLoading(true)
      if (radioValue === 'Users') {
        setRevokeAll(false)
        setResponseUsers([])
        requestAccess
          .getRolesWithValidationForRevoke(userdata
            ? userdata.name
            : formData.chooseUserName, key, tool
            ? tool.name
            : formData.tools.name)
          .then((response) => {
            setLoading(false)
            setResponseUsers(response.data)
            if (!response.data.length) {
              setAlertBoxMessage(`
                User is not a member of any groups in the selected project
              `)
              setAlerbox(true)
            }
          })
      }
      if (radioValue === 'Groups') {
        setResponseGroups([])
        requestAccess.getProjectRoles(formData.tools.name, key, true)
          .then((response) => {
            setLoading(false)
            setAllRoles(response.data)
          })
      }
    }
  }
  const changeComment = (e) => {
    setFormData({
      ...formData,
      comments: e.target.value
    })
  }
  /** Function to change the Role in form */
  const changeRole = (e, tool, project) => {
    setFormData({
      ...formData,
      rolename: e.target.value,
      projectkey: project
        ? `${project.key} - ${project.key_app_name}`
        : formData.projectkey,
      key: project ? project.key : formData.key,
      tools: tool || formData.tools,
      username: []
    })
    setResponseGroups([])
    setRevokeAll(false)
    setLoading(true)
    requestAccess.getuserbasedonrole(e.target.value).then((response) => {
      setResponseGroups(response.data)
      if (!response.data.length && radioValue === 'Groups') {
        setAlertBoxMessage('There are no members present in the selected group')
        setAlerbox(true)
      }
      setLoading(false)
    })
  }

  /** Handling switch toggle for groups or users */
  const switchToggle = (id, rows) => {
    const tempArray = rows
    tempArray.map((row, index) => {
      if (radioValue === 'Users') {
        if (row.group_name === id.group_name) {
          tempArray[index].toggle = !tempArray[index].toggle
        }
      } else if (row.username === id.username) {
        tempArray[index].toggle = !tempArray[index].toggle
      }
    })
    if (radioValue === 'Users') {
      setResponseUsers([...tempArray])
    } else {
      setResponseGroups([...tempArray])
    }
  }

  const setTextAreaField = (e, id, rows) => {
    const tempArray = rows
    if (radioValue === 'Users') {
      tempArray.map((row, index) => {
        if (row.group_name === id.group_name) {
          tempArray[index].comments = e.target.value
        }
      })
      setResponseUsers([...tempArray])
    } else if (radioValue === 'Groups') {
      tempArray.map((row, index) => {
        if (row.username === id.username) {
          tempArray[index].comments = e.target.value
        }
      })
      setResponseGroups([...tempArray])
    }
  }

  /* function to set the clicked button event as addtocart or submitnow */
  const setType = (type, e) => {
    setButton(type)
  }

  // Handle submit
  const handleSubmit = () => {
    const inputArray = []
    const usingArray = radioValue === 'Users' ? responseUsers : responseGroups
    let input = {
    }
    usingArray.map((item) => {
      if (item.toggle) {
        input = {
          requestedBy: user.name,
          crowdUsers: radioValue === 'Users'
            ? formData.chooseUserName
            : item.username,
          requestType: formData.tools.name,
          projects: formData.key,
          userRoles: radioValue === 'Groups'
            ? formData.rolename
            : item.group_name,
          comments: item.comments,
          action_type: 2
        }
        inputArray.push(input)
      }
    })
    if (!inputArray.length) {
      setAlerbox(true)
      setAlertBoxMessage('At least one user and group has to be selected')
    } else {
      formSubmit(inputArray)
    }
  }

  /** Close alert */
  const handleAlertClose = () => {
    setAlerbox(false)
  }

  /* function to submit the request */
  const formSubmit = (inputArray) => {
    let url = ''
    if (button === 'addtocart') {
      url = 'addTocart'
    } else if (button === 'submitnow') {
      url = 'submitRequestNow'
    }
    setLoading(true)
    requestAccess[url](inputArray).then((response) => {
      setLoading(false)
      resetForm()
      setRevokeAll(false)
      if (button === 'addtocart') {
        setSuccessalertMessage('Added to Cart Successfully')
        CartService.cartSize(user.name).then((cartdata) => {
          setUser({
            ...user,
            cartsize: cartdata.data.cartSize
          })
        })
      } else {
        setSuccessalertMessage('Request Submitted Successfully')
        LoginService.getAdminaccess().then((approvaldata) => {
          setUser({
            ...user,
            approvalcount: approvaldata.data.approvalcount
          })
        })
      }
      setsuccessAlert(true)
      setTimeout(() => {
        setsuccessAlert(false)
      }, 20000)
      if (button === 'submitnow' && user.email && response.data.data.length) {
        EmailServices.sendMailtoApprover(response.data.data, 'revoke_access')
          .then((respone2) => {
            // empty comment
          })
      }
    },
    (error) => {
      setLoading(false)
      if (error) {
        setFailalertMessage(`
          Something went wrong. Contact system administrator!
        `)
        seterrorAlert(true)
      }
    })
  }

  return (
    <div>
      {loading ? <LinearProgress /> : null}
      <div className={classes.alertroot}>
        {' '}
        {successAlert ? (
          <Alert severity='success'>{successalertMessage}</Alert>
        ) : null}
        {errorAlert ? (
          <Alert
            severity='error'
            onClose={() => seterrorAlert(false)}
          >
            {failalertMessage}
          </Alert>
        ) : null}
      </div>
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={12} md={5}>
          <Card variant='outlined'>
            <ValidatorForm ref={form} onSubmit={(event) => handleSubmit()}>
              <CardContent>
                <Typography variant='subtitle1' gutterBottom>
                  Revoke Access
                </Typography>
                <div style={{
                  paddingTop: 10
                }}
                >
                  <RadioButtons
                    radio={['Users', 'Groups']}
                    selectedvalue={radioValue}
                    onChange={(value) => changeOption(value)}
                  />
                  {radioValue === 'Users' ? (
                    <FormControl className={classes.formControl}>
                      <Autocomplete
                        id='choose-user-auto-complete'
                        value={formData.chooseUser}
                        className={classes.inputDisplay}
                        options={allUsers}
                        getOptionLabel={
                          (option) => (option.name
                            ? `${option.name} - ${option.displayname}`
                            : option)
                        }
                        loading={allUsers.length === 0 && loadingbar}
                        getOptionSelected={
                          (option, userValue) => option.name === userValue.name
                        }
                        filterOptions={(options, state) => options}
                        onChange={(event, userValue) => changeUser(userValue)}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            name='chooseUser'
                            onChange={(event) => getUsers(event.target.value)}
                            value={formData.chooseUser}
                            label='Choose a user to revoke'
                            validators={['required']}
                            errorMessages={['This field is required']}
                          />
                        )}
                      />
                    </FormControl>
                  ) : null}
                  <FormControl className={classes.formControl}>
                    <SelectValidator
                      label='Tool'
                      name='tools'
                      required
                      className={classes.inputDisplay}
                      value={formData.tools ? formData.tools : ''}
                      onChange={changeTool}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    >
                      {alltools.map((value, index) => (
                        <MenuItem value={value} key={value.name}>
                          {value.name}
                        </MenuItem>
                      ))}
                    </SelectValidator>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id='select-project'
                      disabled={formData.tools === ''}
                      options={allProjects}
                      value={formData.projectkey ? formData.projectkey : ''}
                      getOptionLabel={
                        (option) => (option.key
                          ? `${option.key} - ${option.key_app_name}`
                          : option)
                      }
                      loading={allProjects.length === 0 && loadingbar}
                      className={classes.inputDisplay}
                      getOptionSelected={
                        (option, value) => option.name === value.name
                      }
                      onChange={(event, value) => changeProjectKey(value)}
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          label='Project or Team *'
                          name='projectkey'
                          onChange={
                            (event) => getProjects(formData.tools,
                              event.target.value)
                          }
                          value={formData.projectkey}
                          validators={['required']}
                          errorMessages={['This field is required']}
                        />
                      )}
                    />
                  </FormControl>
                  {radioValue === 'Groups' ? (
                    <FormControl className={classes.formControl}>
                      <SelectValidator
                        label='Requested Role'
                        name='rolename'
                        className={classes.inputDisplay}
                        disabled={
                          formData.tools === '' || formData.projectkey === ''
                        }
                        required
                        value={formData.rolename ? formData.rolename : ''}
                        onChange={(e) => changeRole(e)}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      >
                        {allRoles.map((value, index) => (
                          <MenuItem value={value.name} key={value.name}>
                            {value.name}
                          </MenuItem>
                        ))}
                      </SelectValidator>
                    </FormControl>
                  ) : null}
                  <FormControl className={classes.formControl}>
                    <TextValidator
                      label='Reason for Revoking Access*'
                      name='comments'
                      onChange={changeComment}
                      value={formData.comments}
                      multiline
                      fullWidth
                      rows={1}
                      rowsMax={4}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />
                  </FormControl>
                </div>
              </CardContent>

              <CardActions className={classes.footer}>
                <Button
                  variant='contained'
                  size='small'
                  onClick={resetForm}
                >
                  Reset
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  size='small'
                  disabled={loading || user.switch_user}
                  onClick={setType.bind(this, 'addtocart')}
                >
                  Add to Cart
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  type='submit'
                  size='small'
                  disabled={loading || user.switch_user}
                  onClick={setType.bind(this, 'submitnow')}
                >
                  Submit Now
                </Button>
              </CardActions>
            </ValidatorForm>
          </Card>
        </Grid>
        {radioValue === 'Users' ? (
          <Grid item xs={12} md={7}>
            {responseUsers.length
              ? (
                <TeamsTable
                  rowsData={responseUsers}
                  headCells={headCells1}
                  switchToggle={switchToggle}
                  setTextAreaField={setTextAreaField}
                  revokeAll={revokeAll}
                />
              )
              : null}
            {!responseUsers.length && !loading && formData.projectkey && (
              <div className={classes.alerttext}>
                User is not a member of any groups in the selected project
              </div>
            )}
          </Grid>
        ) : null}
        {radioValue === 'Groups' ? (
          <Grid item xs={12} md={7}>
            {responseGroups.length ? (
              <TeamsTable
                rowsData={responseGroups}
                headCells={headCells2}
                switchToggle={switchToggle}
                setTextAreaField={setTextAreaField}
                revokeAll={revokeAll}
              />
            )
              : null}
            {!responseGroups.length && !loading && formData.rolename && (
              <div className={classes.alerttext}>
                There are no members present in the selected group
              </div>
            )}
          </Grid>
        ) : null}
      </Grid>
      <AlertDialog
        handleClose={handleAlertClose}
        alertopen={alertbox}
        message={alertboxMessage}
        key='alert2'
        title='Alert'
        okbuttonalert
      />
    </div>
  )
}
