import React from 'react'
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
  Input,
  FormControl,
  InputLabel,
  Typography
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20
  },
  textfield: {
    width: '80%'
  },
  bold: {
    fontWeight: 600,
    marginTop: 20
  }
}))

export default function GithubCopilotAdminComponent ({
  configdata, handleChange
}) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography
            variant='subtitle1'
            gutterBottom
            className={classes.bold}
          >
            Azure Token
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            label='Azure Token URI'
            onChange={(e) => handleChange('githubCopilotActivation', e)}
            name='uri'
            value={configdata.githubCopilotActivation.azureToken.uri}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            label='Grant Type'
            onChange={(e) => handleChange('githubCopilotActivation', e)}
            name='uri'
            value={configdata.githubCopilotActivation.azureToken.grant_type}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            label='Client ID'
            onChange={(e) => handleChange('githubCopilotActivation', e)}
            name='uri'
            value={configdata.githubCopilotActivation.azureToken.client_id}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            label='Scope'
            onChange={(e) => handleChange('githubCopilotActivation', e)}
            name='uri'
            value={configdata.githubCopilotActivation.azureToken.scope}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            label='Client Secret'
            onChange={(e) => handleChange('githubCopilotActivation', e)}
            name='uri'
            value={configdata.githubCopilotActivation.azureToken.client_secret}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography
            variant='subtitle1'
            gutterBottom
            className={classes.bold}
          >
            Azure Group
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            label='Azure Group ID URI'
            onChange={(e) => handleChange('githubCopilotActivation', e)}
            name='uri'
            value={configdata.githubCopilotActivation.azureGroupID.uri}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography
            variant='subtitle1'
            gutterBottom
            className={classes.bold}
          >
            App Role Assignment
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            label='Azure App Role Assigment URI'
            onChange={(e) => handleChange('githubCopilotActivation', e)}
            name='uri'
            value={configdata.githubCopilotActivation.appRoleAssignment.uri}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            label='Azure App Role Assigment Role ID'
            onChange={(e) => handleChange('githubCopilotActivation', e)}
            name='uri'
            value={
              configdata.githubCopilotActivation.appRoleAssignment.appRoleId
            }
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            label='Azure App Role Assigment Resource ID'
            onChange={(e) => handleChange('githubCopilotActivation', e)}
            name='uri'
            value={
              configdata.githubCopilotActivation.appRoleAssignment.resourceId
            }
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography
            variant='subtitle1'
            gutterBottom
            className={classes.bold}
          >
            Github
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            label='Github Token'
            onChange={(e) => handleChange('githubCopilotActivation', e)}
            name='uri'
            value={
              configdata.githubCopilotActivation.github.gitHubtoken
            }
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label='Github User'
            onChange={(e) => handleChange('githubCopilotActivation', e)}
            name='uri'
            value={
              configdata.githubCopilotActivation.github.gitHubUser
            }
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Create Team URI'
            onChange={(e) => handleChange('githubCopilotActivation', e)}
            name='uri'
            value={
              configdata.githubCopilotActivation.github.createTeam.uri
            }
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Remove User From Team URI'
            onChange={(e) => handleChange('githubCopilotActivation', e)}
            name='uri'
            value={
              configdata.githubCopilotActivation.github.removeUserFromTeam.uri
            }
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='List Groups In Orgs URI'
            onChange={(e) => handleChange('githubCopilotActivation', e)}
            name='uri'
            value={
              configdata.githubCopilotActivation.github.listGroupsInOrg.uri
            }
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Link IDMS Group To Team URI'
            onChange={(e) => handleChange('githubCopilotActivation', e)}
            name='uri'
            value={
              configdata.githubCopilotActivation.github.linkIDMSGrpToTeam.uri
            }
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Assigning Copilot Seats URI'
            onChange={(e) => handleChange('githubCopilotActivation', e)}
            name='uri'
            value={
              configdata.githubCopilotActivation
                .github.assigningCopilotSeats.uri
            }
            className={classes.textfield}
          />
        </Grid>
      </Grid>
    </div>
  )
}