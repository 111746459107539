/* Common function to use Autocomplete */
import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import PropTypes from 'prop-types'

export default function ComboBox ({
  inputValue, loading, allProjects, name, label, changeOption, getProjects
}) {
  const change = (value) => {
    changeOption(value)
  }
  return (
    <Autocomplete
      id='combo-box-demo'
      options={allProjects}
      value={inputValue}
      getOptionLabel={
        (option) => (option.key
          ? `${option.key} - ${option.key_app_name}`
          : option.name ? option.name : option)
      }
      onChange={(event, value) => change(value)}
      loading={allProjects.length === 0 && loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          name={name}
          onChange={(event) => getProjects(event.target.value)}
        />
      )}
    />
  )
}
ComboBox.propTypes = {
  allProjects: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  changeOption: PropTypes.func.isRequired,
  getProjects: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  inputValue: PropTypes.string.isRequired
}
