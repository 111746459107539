import React, {
  useRef, useContext
} from 'react'
import {
  LinearProgress,
  makeStyles,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  FormControl
} from '@material-ui/core'
import {
  TextValidator, ValidatorForm
} from 'react-material-ui-form-validator'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import UserContext from '../contexts/UserContext'
import RadioButtons from '../inputs/radio'
import EnhancedTable from '../sections/enhancedTable'
import OwnershipServices from '../../services/api/ownershipServices'
import AlertDialog from '../alertbox/alertboxComponent'

const useStyle = makeStyles((theme) => ({
  inputDisplay: {
    width: '100%'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  }
}))

const headCells = [
  {
    id: 'toolname',
    numeric: false,
    label: 'Tool',
    width: '20%'
  },
  {
    id: 'key_app_name',
    numeric: false,
    label: 'Project name',
    width: '30%'
  },
  {
    id: 'group_name',
    numeric: false,
    label: 'Group',
    width: '30%'
  },
  {
    id: 'owner_username',
    numeric: false,
    label: 'Owner',
    width: '20%'
  }
]

export default function ChangeOwnerComponent () {
  const classes = useStyle()
  const form = useRef(null)
  const {
    user
  } = useContext(UserContext)
  const [loading, setLoading] = React.useState(false)
  const [loadingbar, setLoadingBar] = React.useState(false)
  const [radioValue, setRadioValue] = React.useState('Key')
  const [responseKey, setResponseKey] = React.useState([])
  const [allProjects, setAllProjects] = React.useState([])
  const [allUsers, setAllUsers] = React.useState([])
  const [allRoles, setAllRoles] = React.useState([])
  const [successAlert, setsuccessAlert] = React.useState(false)
  const [successalertMessage, setSuccessalertMessage] = React.useState(null)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [failalertMessage, setFailalertMessage] = React.useState(null)
  const [alertbox, setAlerbox] = React.useState(false)
  const [alertboxMessage, setAlertboxMessage] = React.useState(false)
  const [projectKey, setProjectKey] = React.useState(null)
  const [newOwner, setNewOwner] = React.useState(null)
  const [roleName, setRoleName] = React.useState(null)
  const [showGroupOwner, setShowGroupOwner] = React.useState(false)
  const [existingOwner, setExistingOwner] = React.useState(null)
  const [formData, setFormData] = React.useState({
    projectKey: '',
    newOwner: '',
    roleName: '',
    newOwnerDisplay: '',
    key: ''
  })
  /* to change radio option */
  const changeOption = (value) => {
    setRadioValue(value)
    resetForm()
  }

  /* reset form function */
  const resetForm = () => {
    setFormData({
      projectKey: '',
      newOwner: '',
      roleName: '',
      newOwnerDisplay: '',
      key: ''
    })
    setNewOwner(null)
    setShowGroupOwner(false)
    setExistingOwner(null)
    setAllProjects([])
    setResponseKey([])
    setAllRoles([])
    setProjectKey(null)
    setNewOwner(null)
    setRoleName(null)
    seterrorAlert(false)
  }

  /** Close alert */
  const handleAlertClose = () => {
    setAlerbox(false)
  }

  /** Close alert */
  const confirmEditAlert = () => {
    setAlerbox(false)

    if (radioValue === 'Key') {
      const input = {
        key: formData.projectKey,
        username: formData.newOwner
      }
      setLoading(true)
      OwnershipServices.setKeyOwnership(input).then((response) => {
        setLoading(false)
        resetForm()
        setsuccessAlert(true)
        const successMsg = response.data ===
          'success' ? 'Owner changed successfully!' : response.data
        setSuccessalertMessage(successMsg)
        setTimeout(() => {
          setsuccessAlert(false)
        }, 20000)
      },
      (error) => {
        resetForm()
        setLoading(false)
        if (error) {
          setFailalertMessage(`
            Something went wrong. Contact system administrator!
          `)
          seterrorAlert(true)
        }
      })
    } else {
      const input = {
        key: formData.key,
        username: formData.newOwner,
        groupname: formData.roleName
      }
      setLoading(true)
      OwnershipServices.setGroupOwnership(input).then((response) => {
        setLoading(false)
        resetForm()
        setsuccessAlert(true)
        const successMsg = response.data ===
          'success' ? 'Owner changed successfully!' : response.data
        setSuccessalertMessage(successMsg)
        setTimeout(() => {
          setsuccessAlert(false)
        }, 20000)
      },
      (error) => {
        resetForm()
        setLoading(false)
        if (error) {
          setFailalertMessage(`
            Something went wrong. Contact system administrator!
          `)
          seterrorAlert(true)
        }
      })
    }
  }

  /* Get Alert Message */
  const getAlertMessageON = () => {
    if (radioValue === 'Key') {
      setAlertboxMessage(`Are you sure want to save the new owner name 
        ${newOwner} for ${projectKey} ?`)
    } else {
      setAlertboxMessage(`Are you sure want to save the new owner name 
        ${newOwner} for ${roleName} ?`)
    }
    setAlerbox(true)
  }

  /* function to get the projects list based on the search key */
  const getProjects = (value) => {
    setLoadingBar(true)
    OwnershipServices.getAllProjects(value).then((response) => {
      setAllProjects(response.data)
      setLoadingBar(false)
    })
  }

  /* To get Access of projects for selected project */
  const changeProject = (value) => {
    if (value) {
      setResponseKey([])
      setLoading(true)
      OwnershipServices.getkeyroles(value.key).then((response) => {
        setResponseKey(response.data)
        setLoading(false)
        setProjectKey(value.key)
        setFormData({
          ...formData,
          projectKey: value.key
        })
      })
    }
  }

  /* function to get the new owner list based on the search key */
  const getOwnersList = (value) => {
    setLoadingBar(true)
    setFormData({
      ...formData,
      searchkey: value
    })
    OwnershipServices.getallusersforautocomplete(value).then((response) => {
      setAllUsers(response.data)
      setLoadingBar(false)
    })
  }

  /* Change the new owner */
  const changeNewOwner = (value) => {
    if (value) {
      setLoading(true)
      //  OwnershipServices.checkifusernameexists(value.name).then((response) => {
      // setResponseKey(response.data)
      setLoading(false)
      setNewOwner(`${value.name} - ${value.displayname}`)
      setFormData({
        ...formData,
        newOwner: value.name,
        newOwnerDisplay: `${value.name} - ${value.displayname}`
      })
      if (radioValue === 'Group') {
        setShowGroupOwner(true)
      }
      //  })
    }
  }

  /* function to get the new owner list based on the search key */
  const getRole = (value) => {
    setLoadingBar(true)
    OwnershipServices.getGroupsbySearchkey(value).then((response) => {
      setAllRoles(response.data)
      setLoadingBar(false)
    })
  }

  /* Change the new owner */
  const changeRole = (value) => {
    if (value) {
      setLoading(true)
      OwnershipServices.checkifusernameexists(value.name).then((response) => {
        // setResponseKey(response.data)
        setLoading(false)
        setRoleName(value.group_name)
        setExistingOwner(value.owner_username)
        setFormData({
          ...formData,
          roleName: value.group_name,
          key: value.key
        })
      })
    }
  }

  return (
    <div>
      {loading ? <LinearProgress /> : null}
      <div className={classes.alertroot}>
        {' '}
        {successAlert ? (
          <Alert severity='success'>{successalertMessage}</Alert>
        ) : null}
        {errorAlert ? (
          <Alert
            severity='error'
            onClose={() => seterrorAlert(false)}
          >
            {failalertMessage}
          </Alert>
        ) : null}
      </div>
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={12} md={5}>
          <Card variant='outlined'>
            <ValidatorForm
              ref={form}
              onSubmit={(event) => getAlertMessageON(event)}
            >
              <CardContent>
                <Typography variant='subtitle1' gutterBottom>
                  Change Ownership
                </Typography>
                <div style={{
                  paddingTop: 10
                }}
                >
                  <RadioButtons
                    radio={['Key', 'Group']}
                    selectedvalue={radioValue}
                    onChange={(value) => changeOption(value)}
                  />
                  {radioValue === 'Key' ? (
                    <div>
                      <FormControl className={classes.formControl}>
                        <Autocomplete
                          id='combo-box-key'
                          options={allProjects}
                          value={formData.projectKey}
                          getOptionLabel={
                            (option) => (option.key ? option.key : option)
                          }
                          getOptionSelected={
                            (option, value) => option.name === value.name
                          }
                          onChange={(event, value) => changeProject(value)}
                          loading={allProjects.length === 0 && loadingbar}
                          renderInput={(params) => (
                            <TextValidator
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...params}
                              label='Select Project'
                              name='jira-project-key'
                              value={formData.projectKey}
                              onChange={
                                (event) => getProjects(event.target.value)
                              }
                              validators={['required']}
                              errorMessages={['Please select any project']}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  ) : (
                    <div>
                      <FormControl className={classes.formControl}>
                        <Autocomplete
                          id='combo-box-role'
                          options={allRoles}
                          value={formData.roleName}
                          getOptionLabel={(option) => (option.group_name
                            ? option.group_name : option)}
                          getOptionSelected={
                            (option, value) => option.name === value.name
                          }
                          onChange={(event, value) => changeRole(value)}
                          loading={allRoles.length === 0 && loadingbar}
                          renderInput={(params) => (
                            <TextValidator
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...params}
                              label='Select group'
                              value={formData.roleName}
                              name='jira-project-role'
                              onChange={
                                (event) => getRole(event.target.value)
                              }
                              validators={['required']}
                              errorMessages={['Please select any group']}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id='combo-box-user'
                      options={allUsers}
                      value={formData.newOwnerDisplay}
                      getOptionLabel={
                        (option) => (option.name
                          ? `${option.name} - ${option.displayname}`
                          : option)
                      }
                      getOptionSelected={
                        (option, value) => option.name === value.name
                      }
                      onChange={(event, value) => changeNewOwner(value)}
                      loading={allUsers.length === 0 && loadingbar}
                      renderInput={(params) => (
                        <TextValidator
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          label='Choose a new owner'
                          value={formData.newOwner}
                          name='new-project-owner'
                          onChange={
                            (event) => getOwnersList(event.target.value)
                          }
                          validators={['required']}
                          errorMessages={['This field is required']}
                        />
                      )}
                    />
                  </FormControl>
                  {showGroupOwner ? (
                    <div>
                      <br />
                      <br />
                      <strong>Existing Owner : </strong>
                      <span>{existingOwner}</span>
                    </div>
                  ) : null}
                </div>
              </CardContent>
              <CardActions className={classes.footer}>
                <Button
                  variant='contained'
                  size='small'
                  onClick={resetForm}
                >
                  Reset
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  size='small'
                  disabled={loading || user.switch_user}
                // onClick={getAlertMessageON}
                >
                  Save
                </Button>
              </CardActions>
            </ValidatorForm>
          </Card>
        </Grid>
        {responseKey.length && radioValue === 'Key' ? (
          <Grid item xs={12} md={7}>
            <EnhancedTable
              rowsData={responseKey}
              headCells={headCells}
            />
          </Grid>
        ) : null}
      </Grid>
      <AlertDialog
        handleClose={handleAlertClose}
        alertopen={alertbox}
        message={alertboxMessage}
        key='alert2'
        title='Alert'
        confirmbutton={confirmEditAlert}
        okbuttonalert={false}
      />
    </div>
  )
}
