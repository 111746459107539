import React, {
  useContext, useRef, useEffect
} from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  MenuItem,
  makeStyles,
  FormControl,
  Chip,
  Switch,
  LinearProgress
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import {
  useParams
} from 'react-router-dom'
import RadioButtons from '../inputs/radio'
import ToolsService from '../../services/api/toolsService'
import requestAccess from '../../services/api/requestaccessService'
import TransferList from '../sections/transferList'
import TemplateService from '../../services/api/templateService'
import UserContext from '../contexts/UserContext'
import PathContext from '../contexts/PathContext'
import MyTemplatesComponent from '../mytemplates/myTemplatesComponent'
import OtherTemplatesComponent from '../othertemplates/otherTemplatesComponent'
import AlertDialog from '../alertbox/alertboxComponent'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  }
}))

export default function TemplateDetailsComponent (props) {
  const [alltools, setAlltools] = React.useState([])
  const [allProjects, setAllprojects] = React.useState([])
  const [sourceGroups, setSourceGroups] = React.useState([])
  const [targetGroups, setTargetGroups] = React.useState([])
  const [allUsers, setAllusers] = React.useState([])
  const [loadingbar, setLoadingbar] = React.useState(false)
  const [radiotype, setRadioType] = React.useState('Project Key')
  const [active, setActive] = React.useState(true)
  const [errorAlert, setErrorAlert] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [edit, setEdit] = React.useState(false)
  const [originalTitle, setOriginalTitle] = React.useState(null)
  const [alert, setAlert] = React.useState(false)
  const classes = useStyles()
  const form = useRef(null)
  const {
    user
  } = useContext(UserContext)
  const {
    id
  } = useParams()
  const {
    path, setPath
  } = useContext(PathContext)

  // form data declaration
  const [formData, setFormData] = React.useState({
  })

  /* Based on template id, loading the template date */
  useEffect(() => {
    if (id !== 'new') {
      setLoading(true)
      TemplateService.accessTemplate(id).then((tempresponse) => {
        const {
          template, templateusers
        } = tempresponse.data
        const templateusersdata = []
        const templateownersdata = []
        templateusers.map((item) => {
          if (item.type === 2) {
            templateusersdata.push({
              name: item.username,
              displayname: item.displayname
            })
          } else {
            if (item.username.toLowerCase() === user.name.toLowerCase()) {
              setEdit(true)
            }
            templateownersdata.push({
              name: item.username,
              displayname: item.displayname
            })
          }
        })
        setOriginalTitle(template[0].name)
        setFormData({
          toolname: '',
          projectkey: '',
          project: null,
          username: '',
          templatename: template[0].name,
          privacy: template[0].privacy,
          templateusers: templateusersdata,
          templateowners: templateownersdata,
          usernameDisplay: ''
        })
        setActive(template[0].active === 'y')
        TemplateService.getTemplateGroups({
          templateid: id
        }).then((templateGroups) => {
          templateGroups.data.map((item) => {
            targetGroups.push(item.group_name)
          })
          setTargetGroups(targetGroups)
          setLoading(false)
        })
      })
    } else {
      setEdit(true)
      setLoading(false)
      setFormData({
        templatename: '',
        privacy: 'Public',
        toolname: '',
        projectkey: '',
        project: null,
        username: '',
        templateusers: [],
        templateowners: [{
          name: user.name
        }],
        usernameDisplay: ''
      })
    }
    ToolsService.getAvailableTools('idms').then((response) => {
      if (response.data) {
        setAlltools(response.data)
      }
    })
  }, [])
  useEffect(() => {
    const getDataPromise = (value) => new Promise((resolve, reject) => {
      TemplateService.checkTemplateNameExist(value).then((response) => {
        resolve(response.data)
      })
    })
    ValidatorForm.addValidationRule('checkNameExist', async (value) => {
      if (value) {
        let result = true
        // const result = await getDataPromise(value)
        if ((id !== 'new' && originalTitle && value !== originalTitle) ||
        id === 'new') {
          result = await getDataPromise(value)
        }
        return result
      }
      return true
    })
  })
  /* function to get the projects list based on the search key */
  const getProjects = (toolname, value) => {
    if (toolname.is_key) {
      setLoadingbar(true)
      requestAccess.getprojects(toolname.name, value).then((response) => {
        setAllprojects(response.data)
        setLoadingbar(false)
      })
    }
  }
  /* function to get groups based on input */
  function getSourceGroups (input) {
    setSourceGroups([])
    TemplateService.getSourceGroups(input)
      .then((response) => {
        const sourcegroups = []
        response.data.map((item) => {
          if (targetGroups.indexOf(item.group_name) === -1) {
            sourcegroups.push(item.group_name)
          }
        })
        setSourceGroups(sourcegroups)
      })
  }
  /* get Roles based on project key selection */
  const changeKey = (value) => {
    if (value) {
      setFormData({
        ...formData,
        projectkey: `${value.key} - ${value.key_app_name}`,
        project: value
      })
      const input = {
        project: value.key,
        toolname: formData.toolname.name
      }
      getSourceGroups(input)
    }
  }
  // set form values function
  const handleChange = (e) => {
    if (e.target.name === 'toolname' && formData.projectkey) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        projectkey: ''
      })
      setSourceGroups([])
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      })
    }
  }
  const changeUser = (value, field) => {
    if (value && value.length) {
      setFormData({
        ...formData,
        [field]: value
      })
    }
  }
  const changeUsername = (value) => {
    if (value) {
      setFormData({
        ...formData,
        username: value.name,
        usernameDisplay: `${value.name} - ${value.displayname}`
      })
      const input = {
        username: value.name
      }
      getSourceGroups(input)
    }
  }
  /* function to get the users list based on the search key */
  const getUsers = (value) => {
    setFormData({
      ...formData,
      searchkey: value
    })
    setLoadingbar(true)
    requestAccess.getallusersforautocomplete(value).then((response) => {
      setAllusers(response.data)
      setLoadingbar(false)
    })
  }
  const setTargetData = (value) => {
    setTargetGroups(value)
  }
  const setSourceData = (value) => {
    setSourceGroups(value)
  }
  // submit function
  const handleSubmit = () => {
    if (!targetGroups.length) {
      setAlert(true)
    } else {
      const templateusers = []
      formData.templateowners.map((item) => {
        templateusers.push({
          username: item.name,
          id: id === 'new' ? 0 : id,
          type: 1
        })
      })
      if (formData.privacy === 'Private') {
        formData.templateusers.map((item) => {
          templateusers.push({
            username: item.name,
            id: id === 'new' ? 0 : id,
            type: 2
          })
        })
      }
      const input = {
        templateusers,
        id: id === 'new' ? '0' : id.toString(),
        templategroups: targetGroups,
        name: formData.templatename,
        created_by: user.name,
        privacy: formData.privacy,
        active: active ? 'y' : 'n'
      }
      TemplateService.saveTemplate(input).then((response) => {
        setOriginalTitle(formData.templatename)
        cancelFunc()
      },
      (error) => {
        if (error) {
          setErrorAlert(true)
        }
      })
    }
  }

  const cancelFunc = () => {
    props.history.push(edit ? '/mytemplates' : '/othertemplates')
    setPath({
      pathname: edit ? '/mytemplates' : '/othertemplates',
      component: edit ? MyTemplatesComponent : OtherTemplatesComponent
    })
  }

  const setValuenull = (value) => {
    value.splice(0, 1)
    value = []
  }
  return (
    <div>

      {errorAlert ? (
        <Alert severity='error' onClose={() => setErrorAlert(false)}>
          Something went wrong. Contact system administrator!
        </Alert>
      ) : null}
      {loading ? (<LinearProgress />) : (
        <Grid container spacing={1} className={classes.root}>
          <Grid item xs={12} md={6}>
            <Card variant='outlined'>
              <ValidatorForm
                ref={form}
                onSubmit={(event) => handleSubmit()}
              >
                <CardContent>
                  <Typography variant='subtitle1' gutterBottom>
                    Access Template
                    <Switch
                      checked={active}
                      onChange={() => setActive(!active)}
                      disabled={!edit}
                    />
                    Active
                  </Typography>
                  <Grid container spacing={2} className={classes.root}>
                    <Grid item xs={12} md={6}>
                      <FormControl className={classes.formControl}>

                        <TextValidator
                          label='Template Name*'
                          onChange={handleChange}
                          name='templatename'
                          fullWidth
                          validators={['required', 'checkNameExist']}
                          errorMessages={
                            ['This field is required',
                              'This name is already exist.']
                          }
                          value={formData.templatename
                            ? formData.templatename
                            : ''}
                          disabled={!edit}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl className={classes.formControl}>
                        <SelectValidator
                          label='Privacy'
                          name='privacy'
                          value={formData.privacy ? formData.privacy : 'Public'}
                          onChange={handleChange}
                          disabled={!edit}
                          fullWidth
                        >
                          <MenuItem value='Private' key='private'>
                            Private
                          </MenuItem>
                          <MenuItem value='Public' key='public'>
                            Public
                          </MenuItem>

                        </SelectValidator>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {formData.privacy === 'Private' && formData.templateusers ? (
                    <FormControl className={classes.formControl}>
                      <Autocomplete
                        id='combo-box-demo-users'
                        multiple
                        options={allUsers}
                        defaultValue={formData.templateusers}
                        getOptionLabel={(option) => (option.name
                          ? `${option.name} - ${option.displayname}`
                          : option)}
                        disabled={!edit}
                        loading={allUsers.length === 0 && loadingbar}
                        getOptionSelected={
                          (option, value) => option.name === value.name
                        }
                        filterOptions={(options, state) => options}
                        renderTags={(value, getTagProps) => value.map((option,
                          index) => (formData.templateusers.length ? (
                          <Chip
                            label={
                              option.name
                                ? option.name
                                : option
                            }
                            {...getTagProps({
                              index
                            })}
                          />
                        ) : (
                          setValuenull(value)
                        )))}
                        onChange={(event, value) => changeUser(value,
                          'templateusers')}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            label='Template Users*'
                            name='templateusers'
                            onChange={(event) => getUsers(event.target.value)}
                            value={formData.templateusers}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            disabled={!edit}
                          />
                        )}
                      />
                    </FormControl>
                  ) : null}
                  {formData.templateowners ? (
                    <FormControl className={classes.formControl}>
                      <Autocomplete
                        id='combo-box-owner'
                        multiple
                        options={allUsers}
                        defaultValue={formData.templateowners}
                        disabled={!edit}
                        getOptionLabel={(option) => (option.name
                          ? `${option.name} - ${option.displayname}`
                          : option)}
                        loading={allUsers.length === 0 && loadingbar}
                        getOptionSelected={(option,
                          value) => option.name === value.name}
                        renderTags={(value, getTagProps) => value.map((option,
                          index) => (formData.templateowners.length ? (
                          <Chip
                            label={
                              option.name
                                ? option.name
                                : option
                            }
                            {...getTagProps({
                              index
                            })}
                          />
                        ) : (
                          setValuenull(value)
                        )))}
                        filterOptions={(options, state) => options}
                        onChange={(event, value) => changeUser(value,
                          'templateowners')}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            label='Template Owners*'
                            name='templateowners'
                            onChange={(event) => getUsers(event.target.value)}
                            value={formData.templateowners}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            disabled={!edit}
                          />
                        )}
                      />
                    </FormControl>
                  ) : null}
                  <FormControl className={classes.formControl}>
                    <span>Choose by:</span>
                    <RadioButtons
                      radio={['Project Key', 'User']}
                      selectedvalue={radiotype}
                      onChange={(value) => setRadioType(value)}
                      disabled={!edit}
                    />
                  </FormControl>
                  {radiotype === 'Project Key' ? (
                    <Grid container spacing={1} className={classes.root}>
                      <Grid item xs={12} md={6}>
                        <FormControl className={classes.formControl}>
                          <SelectValidator
                            label='Tool'
                            name='toolname'
                            fullWidth
                            value={formData.toolname ? formData.toolname : ''}
                            onChange={handleChange}
                            disabled={!edit}
                          >
                            {alltools.map((value, index) => (
                              <MenuItem value={value} key={value.name}>
                                {value.name}
                              </MenuItem>
                            ))}
                          </SelectValidator>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl className={classes.formControl}>
                          <Autocomplete
                            id='combo-box-demo'
                            options={allProjects}
                            disabled={formData.toolname === ''}
                            value={formData.projectkey
                              ? formData.projectkey
                              : ''}
                            getOptionLabel={(option) => (option.key
                              ? `${option.key} - ${option.key_app_name}`
                              : option)}
                            loading={allProjects.length === 0 && loadingbar}
                            getOptionSelected={(option,
                              value) => option.name === value.name}
                            onChange={(event, value) => changeKey(value)}
                            renderInput={(params) => (
                              <TextValidator
                                {...params}
                                label='Project or Team'
                                name='projectkey'
                                onChange={
                                  (event) => getProjects(formData.toolname,
                                    event.target.value)
                                }
                                value={formData.projectkey}
                                disabled={!edit}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  ) : (
                    <FormControl className={classes.formControl}>
                      <Autocomplete
                        id='combo-box-demo-user'
                        options={allUsers}
                        value={formData.usernameDisplay}
                        getOptionLabel={(option) => (option.name
                          ? `${option.name} - ${option.displayname}`
                          : option)}
                        loading={
                          allUsers.length === 0 &&
                        loadingbar
                        }
                        filterOptions={(options, state) => options}
                        getOptionSelected={
                          (option, value) => option.name === value.name
                        }
                        onChange={(event, value) => changeUsername(value)}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            label='Choose a User'
                            name='username'
                            onChange={(event) => getUsers(event.target.value)}
                            value={formData.usernameDisplay}
                            disabled={!edit}
                          />
                        )}
                      />
                    </FormControl>
                  )}
                </CardContent>
                <CardActions className={classes.footer}>
                  <Button
                    variant='contained'
                    size='small'
                    style={{
                      marginRight: 20
                    }}
                    onClick={() => cancelFunc()}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    size='small'
                    disabled={!edit || user.switch_user}
                  >
                    {id === 'new' ? 'Create Template' : 'Save Template'}
                  </Button>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card variant='outlined'>
              <CardContent>
                {' '}
                <Typography
                  variant='caption'
                  gutterBottom
                  color='textSecondary'
                >
                  Select items from &quotSelect a Group&quot and Move them into
                   &quotGroups to include&quot in Template
                </Typography>
                <TransferList
                  sourceData={sourceGroups}
                  targetData={targetGroups}
                  setSourceData={setSourceData}
                  setTargetData={setTargetData}
                  disabled={!edit}
                  selectedTool={formData.toolname}
                  selectedProject={formData.project}
                />
              </CardContent>
            </Card>
          </Grid>

        </Grid>

      )}
      <AlertDialog
        handleClose={() => setAlert(false)}
        alertopen={alert}
        key='alert-template'
        message='Select at least one group to be include in Template'
        okbuttonalert
        title='Alert'
      />
    </div>
  )
}
