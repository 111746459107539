import axiosInstance from '../../middleware/Interceptor'

function searchSplunkMetrics (data) {
  return axiosInstance.post('/api/v1/splunk/search-splunk', data)
}

const splunk = {
  searchSplunkMetrics

}

export default splunk