import React, {
  useEffect
} from 'react'
import clsx from 'clsx'
import {
  makeStyles
} from '@material-ui/core/styles'
import {
  LinearProgress,
  Typography,
  IconButton,
  Button,
  DialogActions,
  FormControl,
  TextField
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import Drawer from '@material-ui/core/Drawer'
import RichTextEditor from './RichTextEditor'


const useStyles = makeStyles({
  list: {
    width: 'auto',
    padding: 20
  },
  fullList: {
    width: 'auto'
  },
  title: {
    marginBottom: 10,
    width: '100%'
  }
})

export default function powerBiConfig ({
  openDialog, currentConfiguration, close, widgetErrorMessage,
  handleConfiguraitonClose, modalLoading, checkMandatoryFields, showDetails,
  changePowerBiWidgetName, changeSummaryEditorValue, changeiFrameWidgetName
}) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [exampleValue, setExampleValue] = React.useState('')

  useEffect(() => {
    setExampleValue('<iframe id="inlineFrameExample" \n title="Inline Frame Example" style="position: absolute; width: 100%; height: 90%; border: none" \n src="https://url-to-embed"> \n</iframe>')
    setOpen(openDialog)
  }, [openDialog])

  const closeDialog = (event) => {
    if (event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    close()
  }

  const onChangeMethod = (e, currentConfiguration) => {
    setExampleValue(e.target.value)
    changePowerBiWidgetName(e, currentConfiguration)
  }

  const changeEditorValue = (value) => {
    changeSummaryEditorValue(value)
  }

  const list = (anchor) => (
    <>
      {modalLoading ? <LinearProgress /> : null}
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === 'top' || anchor === 'bottom'
        })}
        role='presentation'
      >
        <Typography variant='h6'>
          {currentConfiguration.name}
          {' '}
              - Configuration
        </Typography>
        <IconButton
          aria-label='close'
          className='widgetCloseButton'
          onClick={closeDialog}
        >
          <CloseIcon />
        </IconButton>
        <ValidatorForm>
          <div className='modelDialog'>
            <FormControl className={`${classes.title} widgetFormControl`}>
              <TextValidator
                value={currentConfiguration.displayname}
                name='displayname'
                label='Title'
                onChange={(e) => changeiFrameWidgetName(e,
                  currentConfiguration)}
              />
              <TextField
                value={currentConfiguration.value || exampleValue}
                name='value'
                label='iFrame Content'
                multiline
                onChange={(e) => onChangeMethod(e,
                  currentConfiguration)}
              />
            </FormControl>
          </div>
          <DialogActions className='dialogAction'>
            <Button
              disabled={modalLoading}
              autoFocus
              onClick={() => handleConfiguraitonClose()}
            >
                  Close
            </Button>
            <Button
              type='button'
              disabled={checkMandatoryFields()}
              autoFocus
              onClick={() => showDetails()}
              color='primary'
            >
                  Save changes
            </Button>
          </DialogActions>
        </ValidatorForm>
      </div>
    </>
  )

  return (
    <div>
      <Drawer
        anchor='right'
        open={openDialog}
        onClose={closeDialog}
      >
        {list('right')}
      </Drawer>
      {/* ))} */}
    </div>
  )
}