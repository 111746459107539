import React, {
  useRef,
  useContext
} from 'react'
import {
  Typography,
  Grid,
  makeStyles,
  TextField,
  IconButton,
  FormControl,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Tabs,
  Tab,
  Box,
  Paper,
  MenuItem
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import AlertDialog from '../alertbox/alertboxComponent'
import adminConfigService from '../../services/api/adminConfigService'
import RadioButtons from '../inputs/radio'
import UserContext from '../contexts/UserContext'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  cardmargin: {
    marginTop: 20
  },
  apicardmargin: {
    marginTop: 20,
    background: 'rgba(0,0,0,.03)'
  },
  backbtn: {
    float: 'right',
    marginRight: 20
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%',
    minWidth: 150
  }
}))

export default function ToolProvisioningComponent ({
  setLoading, Cancel
}) {
  const classes = useStyles()
  const {
    user
  } = useContext(UserContext)
  const form = useRef(null)
  const [tools, setTools] = React.useState([])
  const [deletealert, setDeleteAlert] = React.useState(false)
  const [createalert, setCreateAlert] = React.useState(false)
  const [createtype, setCreateType] = React.useState(null)
  const [apiFunctions, setApiFunctions] = React.useState([])
  const [currentField, setCurrentField] = React.useState(null)
  const [value, setValue] = React.useState(0)
  const [deleteID, setDeleteID] = React.useState(null)
  const [toolname, setToolname] = React.useState(null)
  const [remainingfunctions, setRemainingFunctions] = React.useState([])
  const [httpmethods, setHttpMethods] = React.useState([])
  const [toolFormData, setToolFormData] = React.useState({
    toolname: '',
    type: 'idms',
    is_key: true,
    active: true,
    enable: true,
    application_url: ''
  })
  const [apiFormData, setApiFormData] = React.useState({
    apiFuncName: '',
    http_method_id: '',
    tp_id: ''
  })
  /* get available tools */
  function getTools () {
    setLoading(true)
    adminConfigService.getToolsAndProvisioning().then((response) => {
      const array = response.data
      array.map((data, index) => {
        array[index].header = array[index].header
          ? JSON.stringify(data.header)
          : null
      })
      setTools(array)
      setLoading(false)
    })
  }
  // It will be called when the page gets loaded, similer to componentDidMount
  React.useEffect(() => {
    window.scrollTo(0, 0)
    getTools()
  }, [])
  function TabPanel (props) {
    const {
      children, value, index, ...other
    } = props

    return (
      <Typography
        component='div'
        role='tabpanel'
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    )
  }
  /* change function to set provisioning type */
  const changeRadiotype = (type, index) => {
    const rows = tools
    tools[index].provisioning = type.toLowerCase()
    setTools([...rows])
  }
  /* change function for switches */
  const handleChecked = (e, index) => {
    const rows = tools
    tools[index][e.target.name] = e.target.checked
    setTools([...rows])
  }
  /* get api functions for given api tool */
  function getApiFunctions (name) {
    adminConfigService.getToolFunctions(name).then((response) => {
      const array = response.data
      array.map((data, index) => {
        array[index].request_body = array[index].request_body
          ? JSON.stringify(data.request_body)
          : null
        array[index].response_body = array[index].response_body
          ? JSON.stringify(data.request_body)
          : null
      })
      setApiFunctions(array)
    })
  }
  /* edit function to make the tool editable */
  const handleEdit = (name) => {
    tools.map((item, i) => {
      if (item.name === name) {
        tools[i].edit = !tools[i].edit
        if (item.provisioning === 'api') {
          setApiFunctions([])
          getApiFunctions(item.name)
          setToolname(item)
        }
      } else {
        tools[i].edit = false
      }
    })
    setTools([...tools])
  }
  /* edit function to make the api function editable */
  const handleApiEdit = (id) => {
    apiFunctions.map((item, i) => {
      if (item.id === id) {
        apiFunctions[i].edit = !apiFunctions[i].edit
      } else {
        apiFunctions[i].edit = false
      }
    })
    setApiFunctions([...apiFunctions])
  }
  /* change function for all tool textfields */
  const handleChange = (e, index) => {
    const rows = tools
    setCurrentField(e.target.name)
    rows[index][e.target.name] = e.target.value
    setTools([...rows])
  }
  /* change function for all api function textfields */
  const handleApiChange = (e, index) => {
    const rows = apiFunctions
    setCurrentField(e.target.name)
    rows[index][e.target.name] = e.target.value
    setApiFunctions([...rows])
  }
  /* Save function for particular tool */
  const handleSave = (item) => {
    setLoading(true)
    adminConfigService.updateToolProvisioningDetail(item).then((response) => {
      setLoading(false)
      getTools()
    })
  }
  /* Save function for particular api fucntion */
  const handleApiSave = (item) => {
    setLoading(true)
    adminConfigService.updateApiFunctions(item).then((response) => {
      setLoading(false)
      getApiFunctions(toolname.name)
    })
  }
  /* Delete  function for particular tool */
  const handleDelete = (item) => {
    setDeleteID({
      id: item.id,
      type: item.provisioning
    })
    setDeleteAlert(true)
  }
  /* Delete  function for particular Api function */
  const handleApiDelete = (item) => {
    setDeleteID({
      id: item.id
    })
    setDeleteAlert(true)
  }
  /* Confirm delete function which will cal when click ok button on alert */
  const confirmDelete = () => {
    setDeleteAlert(false)
    const URL = deleteID.type ? 'removeTool' : 'removeApiFunction'
    setLoading(true)
    adminConfigService[URL](deleteID).then((response) => {
      setLoading(false)
      setDeleteID(null)
      deleteID.type ? getTools() : getApiFunctions(toolname.name)
    })
  }
  /* create function for new tool or new api function */
  const handleCreate = () => {
    setCreateAlert(false)
    const url = createtype === 'function'
      ? 'addApiFunctionForTool'
      : 'addNewTool'
    const input = createtype === 'function'
      ? apiFormData
      : toolFormData
    if (createtype === 'function') {
      input.apiFuncName = apiFormData.apiFuncName.name
      input.http_method_id = apiFormData.http_method_id.id.toString()
    }
    setLoading(true)
    adminConfigService[url](input).then((response) => {
      setLoading(false)
      createtype === 'function' ? getApiFunctions(toolname.name) : getTools()
    })
  }
  /* function to open dialog box for create tool or create api function */
  const addNewTool = (type) => {
    setCreateType(type)
    setCreateAlert(true)
    if (type === 'function') {
      setApiFormData({
        ...apiFormData,
        tp_id: toolname.prov_id.toString()
      })
      adminConfigService.getRemainingApiFunctions(toolname.id)
        .then((response) => {
          setRemainingFunctions(response.data)
          adminConfigService.getHttpMethod().then((httpresponse) => {
            setHttpMethods(httpresponse.data)
          })
        })
    }
  }
  /* function for changing tabs for api tool */
  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} />
        <Grid item xs={6} md={6}>

          <Button
            variant='contained'
            color='primary'
            size='small'
            onClick={() => addNewTool('tool')}
            id='add-new-tool'
            className='pull-right'
            disabled={user.switch_user}
          >
            Add New Tool
          </Button>
          <Button
            variant='contained'
            size='small'
            onClick={Cancel}
            id='cancel'
            className={classes.backbtn}
          >
            Back
          </Button>
        </Grid>
      </Grid>
      <div>
        {tools.map((item, index) => (
          <Card
            key={item.name}
            variant='outlined'
            className={classes.cardmargin}
          >
            <CardContent>
              <Grid container spacing={2}>

                <Grid item xs={6} md={2}>
                  <Typography variant='subtitle1'>
                    {' '}
                    {item.name}
                  </Typography>

                </Grid>
                <Grid item xs={6} md={2}>
                  <FormControlLabel
                    control={(
                      <Switch
                        name='is_key'
                        color='primary'
                        checked={item.is_key}
                        disabled={!item.edit}
                        id='is_key'
                        onChange={(e) => handleChecked(e, index)}
                      />
                    )}
                    label='Is Key'
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  <FormControlLabel
                    control={(
                      <Switch
                        name='active'
                        color='primary'
                        checked={item.active}
                        id='active'
                        disabled={!item.edit}
                        onChange={(e) => handleChecked(e, index)}
                      />
                    )}
                    label='Active'
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  <FormControlLabel
                    control={(
                      <Switch
                        name='enable'
                        color='primary'
                        id='enable'
                        checked={item.enable}
                        disabled={!item.edit}
                        onChange={(e) => handleChecked(e, index)}
                      />
                    )}
                    label='Enable'
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  <RadioButtons
                    radio={['Idms', 'Api']}
                    selectedvalue={item.provisioning === 'idms'
                      ? 'Idms'
                      : 'Api'}
                    onChange={(value) => changeRadiotype(value, index)}
                    disabled={!item.edit}
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  {item.edit ? (
                    <>
                      <IconButton
                        className='pull-right'
                        id={`${item.name}-close-icon`}
                        onClick={() => handleEdit(item.name)}
                      >
                        {' '}
                        <CloseIcon color='secondary' />
                        {' '}
                      </IconButton>
                      <IconButton
                        className='pull-right'
                        id={`${item.name}-save-icon`}
                        onClick={() => handleSave(item)}
                      >
                        {' '}
                        <CheckIcon color='primary' />
                        {' '}
                      </IconButton>

                    </>
                  ) : (
                    <>
                      <IconButton
                        aria-label='delete'
                        className='pull-right'
                        onClick={() => handleDelete(item)}
                        id={`${item.name}-delete-icon`}
                        disabled={user.switch_user}
                      >
                        <DeleteIcon color='secondary' />
                      </IconButton>
                      <IconButton
                        className='pull-right'
                        onClick={() => handleEdit(item.name)}
                        disabled={user.switch_user}
                        id={`${item.name}-edit-icon`}
                      >
                        <EditIcon color='primary' fontSize='small' />
                      </IconButton>

                    </>
                  )}

                </Grid>

                {item.edit && item.provisioning === 'api' ? (
                  <Grid item xs={12} md={12}>
                    <Paper square>
                      <Tabs
                        value={value}
                        onChange={handleTabChange}
                        variant='scrollable'
                        scrollButtons='on'
                        indicatorColor='primary'
                        textColor='primary'
                        aria-label='scrollable force tabs example'
                      >
                        <Tab label='Details' title='Details' />
                        <Tab
                          label='Api functions'
                          title='Api functions'
                        />
                      </Tabs>
                    </Paper>
                    <TabPanel value={value} index={0}>
                      <Grid item xs={12} md={8}>
                        <TextField
                          autoFocus={currentField === 'application_url'}
                          label='Application URL'
                          name='application_url'
                          value={item.application_url}
                          onChange={(e) => handleChange(e, index)}
                          className={classes.formControl}
                        />
                      </Grid>
                      <Grid item xs={12} md={8} key={`${item.name}uri`}>
                        <TextField
                          autoFocus={currentField === 'uri'}
                          label='URI'
                          name='uri'
                          value={item.uri}
                          className={classes.formControl}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </Grid>
                      <Grid item xs={12} md={8} key={`${item.name}header`}>
                        <TextField
                          autoFocus={currentField === 'header'}
                          label='Header'
                          name='header'
                          value={item.header}
                          className={classes.formControl}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </Grid>
                      <Grid item xs={12} md={8} key={`${item.name}pre_auth`}>
                        <TextField
                          autoFocus={currentField === 'pre_auth'}
                          label='Pre Auth'
                          name='pre_auth'
                          value={item.pre_auth}
                          className={classes.formControl}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={8}
                        key={`${item.name}pre_auth_path`}
                      >
                        <TextField
                          autoFocus={currentField === 'pre_auth_path'}
                          label='Pre Auth Path'
                          name='pre_auth_path'
                          value={item.pre_auth_path}
                          className={classes.formControl}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </Grid>
                      <Grid item xs={12} md={8} key={`${item.name}auth_type`}>
                        <TextField
                          autoFocus={currentField === 'auth_type'}
                          label='Auth Type'
                          name='auth_type'
                          value={item.auth_type}
                          className={classes.formControl}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </Grid>
                      <Grid item xs={12} md={8} key={`${item.name}username`}>
                        <TextField
                          autoFocus={currentField === 'username'}
                          label='Username'
                          name='username'
                          value={item.username}
                          className={classes.formControl}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </Grid>
                      <Grid item xs={12} md={8} key={`${item.name}pass_token`}>
                        <TextField
                          autoFocus={currentField === 'pass_token'}
                          label='Password'
                          name='pass_token'
                          value={item.pass_token}
                          className={classes.formControl}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </Grid>
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>

                          <Button
                            color='primary'
                            size='small'
                            onClick={() => addNewTool('function')}
                            className='pull-right'
                            id='add-new-func'
                          >
                            Add new function
                            {' '}
                          </Button>
                        </Grid>
                      </Grid>
                      {apiFunctions.map((data, i) => (
                        <Card
                          key={data.id}
                          variant='outlined'
                          className={classes.apicardmargin}
                        >
                          <CardContent>
                            <Typography variant='subtitle1'>
                              {data.edit ? (
                                <>
                                  <IconButton
                                    className='pull-right'
                                    onClick={() => handleApiEdit(data.id)}
                                    id='api-close'
                                  >
                                    {' '}
                                    <CloseIcon color='secondary' />
                                    {' '}
                                  </IconButton>
                                  <IconButton
                                    className='pull-right'
                                    onClick={() => handleApiSave(data)}
                                    id='api-save'
                                  >
                                    {' '}
                                    <CheckIcon color='primary' />
                                    {' '}
                                  </IconButton>

                                </>
                              ) : (
                                <>
                                  <IconButton
                                    aria-label='delete'
                                    className='pull-right'
                                    id='api-delete'
                                    onClick={() => handleApiDelete(data)}
                                  >
                                    <DeleteIcon color='secondary' />
                                  </IconButton>
                                  <IconButton
                                    className='pull-right'
                                    id='api-edit'
                                    onClick={() => handleApiEdit(data.id)}
                                  >
                                    <EditIcon
                                      color='primary'
                                      fontSize='small'
                                    />
                                  </IconButton>

                                </>
                              )}
                              {' '}
                              {data.api_function}
                            </Typography>
                            <Grid item xs={12} md={10}>
                              <TextField
                                label='Path'
                                autoFocus={currentField === 'path'}
                                disabled={!data.edit}
                                name='path'
                                value={data.path}
                                className={classes.formControl}
                                onChange={(e) => handleApiChange(e, i)}
                              />
                            </Grid>
                            <Grid item xs={12} md={10}>
                              <TextField
                                label='Request Body'
                                autoFocus={currentField === 'request_body'}
                                disabled={!data.edit}
                                name='request_body'
                                value={data.request_body}
                                className={classes.formControl}
                                onChange={(e) => handleApiChange(e, i)}
                              />
                            </Grid>
                            <Grid item xs={12} md={10}>
                              <TextField
                                label='Response Body'
                                autoFocus={currentField === 'response_body'}
                                disabled={!data.edit}
                                name='response_body'
                                value={data.response_body}
                                className={classes.formControl}
                                onChange={(e) => handleApiChange(e, i)}
                              />
                            </Grid>
                            <Grid item xs={12} md={10}>
                              <TextField
                                label='Response Path'
                                autoFocus={currentField === 'response_path'}
                                disabled={!data.edit}
                                name='response_path'
                                value={data.response_path}
                                className={classes.formControl}
                                onChange={(e) => handleApiChange(e, i)}
                              />
                            </Grid>
                          </CardContent>
                        </Card>
                      ))}

                    </TabPanel>
                  </Grid>
                ) : (
                  <Grid item xs={12} md={6}>
                    <TextField
                      label='Application URL'
                      name='application_url'
                      value={item.application_url}
                      style={{
                        width: '100%'
                      }}
                      disabled={!item.edit}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        ))}
      </div>
      {/* Dialog box for creating new tool / api function */}
      <Dialog
        onClose={() => setCreateAlert(false)}
        aria-labelledby='simple-dialog-title'
        open={createalert}
      >
        <ValidatorForm ref={form} onSubmit={(event) => handleCreate(event)}>
          <DialogTitle id='alert-dialog-title'>
            Add New
          </DialogTitle>
          <DialogContent>

            {createtype === 'tool' ? (
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <FormControl className={classes.formControl}>
                    <TextValidator
                      label='Tool Name*'
                      fullWidth
                      name='toolname'
                      onChange={(e) => setToolFormData({
                        ...toolFormData,
                        [e.target.name]: e.target.value
                      })}
                      value={toolFormData.toolname}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControlLabel
                    control={(
                      <Switch
                        name='is_key'
                        color='primary'
                        checked={toolFormData.is_key}
                        id='is_key'
                      />
                    )}
                    label='Is Key'
                    labelPlacement='start'
                    onChange={(e) => setToolFormData({
                      ...toolFormData,
                      [e.target.name]: e.target.checked
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControlLabel
                    control={(
                      <Switch
                        name='active'
                        color='primary'
                        id='active'
                        checked={toolFormData.active}
                      />
                    )}
                    label='Active'
                    labelPlacement='start'
                    onChange={(e) => setToolFormData({
                      ...toolFormData,
                      [e.target.name]: e.target.checked
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControlLabel
                    control={(
                      <Switch
                        name='enable'
                        color='primary'
                        id='enable'
                        checked={toolFormData.enable}
                      />
                    )}
                    label='Enable'
                    labelPlacement='start'
                    onChange={(e) => setToolFormData({
                      ...toolFormData,
                      [e.target.name]: e.target.checked
                    })}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    marginLeft: 20
                  }}
                >
                  <Typography variant='subtitle1'> Provisioning</Typography>
                  {' '}
                  <RadioButtons
                    radio={['Idms', 'Api']}
                    selectedvalue={toolFormData.type === 'idms'
                      ? 'Idms'
                      : 'Api'}
                    onChange={(value1) => setToolFormData({
                      ...toolFormData,
                      type: value1.toLowerCase()
                    })}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <FormControl className={classes.formControl}>
                    <TextValidator
                      label='Application URL'
                      name='application_url'
                      fullWidth
                      value={toolFormData.application_url}
                      onChange={(e) => setToolFormData({
                        ...toolFormData,
                        [e.target.name]: e.target.value
                      })}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <FormControl className={classes.formControl}>
                    <SelectValidator
                      label='Name'
                      name='apiFuncName'
                      required
                      value={apiFormData.apiFuncName
                        ? apiFormData.apiFuncName
                        : ''}
                      onChange={(e) => setApiFormData({
                        ...apiFormData,
                        apiFuncName: e.target.value
                      })}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    >
                      {remainingfunctions.map((func) => (
                        <MenuItem value={func} key={func.name}>
                          {func.name}
                        </MenuItem>
                      ))}
                    </SelectValidator>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl className={classes.formControl}>
                    <SelectValidator
                      label='HTTP'
                      name='apiFuncName'
                      required
                      value={apiFormData.http_method_id
                        ? apiFormData.http_method_id
                        : ''}
                      onChange={(e) => setApiFormData({
                        ...apiFormData,
                        http_method_id: e.target.value
                      })}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    >
                      {httpmethods.map((method) => (
                        <MenuItem value={method} key={method.type}>
                          {method.type}
                        </MenuItem>
                      ))}
                    </SelectValidator>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => setCreateAlert(false)}
              color='default'
              id='cancel'
            >
              Cancel
            </Button>
            <Button type='submit' color='primary' id='create-btn'>
              Create
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
      <AlertDialog
        handleClose={() => setDeleteAlert(false)}
        alertopen={deletealert}
        message={`Are you sure want to remove this ${deleteID && deleteID.type
          ? 'Tool'
          : 'Api Function'}?`}
        confirmbutton={confirmDelete}
        key='deletealert1'
        title='Alert'
        okbuttonalert={false}
      />
    </div>
  )
}
