/* It contains all the API functions which is releated to Support section */
import axiosInstance from '../../middleware/Interceptor'

// Get Max Key
function getMaxKey (userInput, endKey) {
  return axiosInstance.get(`/api/v1/admin/getmaxkey/${userInput}/${endKey}`)
}

// Checking if key exist0
function isKeyExist (projectKey) {
  return axiosInstance.get(`/api/v1/admin/doeskeyexist/${projectKey}`)
}

// Get roles for selected tools
function getRolesForTool (input) {
  return axiosInstance.post('/api/v1/admin/getrolesfortool', input)
}

// Get WWID for the selected users
function getUserWWID (username) {
  return axiosInstance.get(`/api/v1/iris/getUserWWID?username=${username}`)
}

// Create IDMS Groups
function createIDMSGroups (input, projectKey) {
  return axiosInstance.post(`/api/v2/idms/createIDMSGroups?key=
${projectKey}&type=noapi`,
  input,
  {
    responseType: 'json'
  })
}

// get curse and offensive words
function getOffensiveWords () {
  return axiosInstance.get('/api/getOffensiveWords')
}

const ProjectKeyService = {
  getMaxKey,
  isKeyExist,
  getRolesForTool,
  getUserWWID,
  createIDMSGroups,
  getOffensiveWords
}

export default ProjectKeyService
