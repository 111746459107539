/* This component will be used as common file for Alert box and it can be accessed by any component,
 * Need to import this component from the actual component and some props values should be passed.
 * props.alertopen is a boolean value to show the alert box
 * props.handleClose is the function to close the Alert box
 * props.message is the actual content
 * props.okbuttonalert is a boolean value and if it true it will show only "Ok" button
 * else will display Yes / No buttons
 * props.confirmbutton is a function should call after the "Yes" button clicked
 */

import React from 'react'
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  IconButton
} from '@material-ui/core'
import PropTypes from 'prop-types'
import GetAppIcon from '@material-ui/icons/GetApp'

export default function AlertDialog ({
  handleClose,
  alertopen,
  message,
  okbuttonalert,
  confirmbutton,
  title,
  downloadIcon
}) {
  const download = () => {
    const blob = new Blob([message], {
      type: 'text/plain'
    })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `${title}_${new Date()}.txt`
    link.click()
  }
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={alertopen}
    >
      <DialogTitle id='alert-dialog-title'>
        {title}
        {downloadIcon ? (
          <IconButton
            aria-label='download'
            className='pull-right'
            onClick={() => download()}
          >
            <GetAppIcon color='primary' />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <div style={{
          whiteSpace: 'pre-line'
        }}
        >
          {message}
        </div>
      </DialogContent>

      {okbuttonalert ? (
        <DialogActions>
          <Button onClick={handleClose} color='primary' autoFocus>
            OK
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button onClick={handleClose} color='default'>
            No
          </Button>
          <Button onClick={confirmbutton} color='primary' autoFocus>
            Yes
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
AlertDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  alertopen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  okbuttonalert: PropTypes.bool.isRequired,
  downloadIcon: PropTypes.bool,
  confirmbutton: PropTypes.func,
  title: PropTypes.string.isRequired
}
