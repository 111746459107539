
import axios from 'axios'
import axiosInstance from '../../middleware/Interceptor'


async function loadPageData (project) {
  return axiosInstance.get('api/v1/jenkinsdashboard/loaddata')
}

async function infoCheck (data) {
  return axiosInstance.post('api/v1/jenkinsdashboard/systeminfo', data)
}

async function liveCheck (data) {
  return axiosInstance.post('api/v1/jenkinsdashboard/livecheck', data)
}

async function liveCheckBulk (data) {
  return axiosInstance.post('api/v1/jenkinsdashboard/livecheckbulk', data)
}

function startJenkins (data) {
  return axiosInstance.post('api/v1/jenkinsdashboard/startjenkins', data)
}

function stopJenkins (data) {
  return axiosInstance.post('api/v1/jenkinsdashboard/stopjenkins', data)
}

const JenkinsDashboarService = {
  loadPageData,
  infoCheck,
  liveCheck,
  liveCheckBulk,
  startJenkins,
  stopJenkins
}

export default JenkinsDashboarService