import React, {
  useContext, useRef, useEffect
} from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  makeStyles,
  FormControl,
  LinearProgress
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import {
  Alert
} from '@material-ui/lab'
import UserContext from '../contexts/UserContext'
import adminConfigService from '../../services/api/adminConfigService'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  }
}))

function CreateBitbucketProjectComponent () {
  const {
    user
  } = useContext(UserContext)
  const [loading, setLoading] = React.useState(false)
  const [successAlert, setsuccessAlert] = React.useState(false)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [failmessage, setFailMessage] = React.useState(null)
  const classes = useStyles()
  const form = useRef(null)

  // form data declaration
  const [formData, setFormData] = React.useState({
    projectKey: '',
    name: '',
    description: ''
  })
  // reset form
  const resetForm = () => {
    setFormData({
      projectKey: '',
      name: '',
      description: ''
    })
    seterrorAlert(false)
  }

  useEffect(() => {
    ValidatorForm.addValidationRule('MaxLength128', (value) => {
      if (value.length > 128) {
        return false
      }
      return true
    })
  })

  /* function to create the bitbucket project */
  const handleSubmit = () => {
    setLoading(true)
    adminConfigService.createProject(formData).then((response) => {
      setLoading(false)
      resetForm()
      setsuccessAlert(true)
      setTimeout(() => {
        setsuccessAlert(false)
      }, 20000)
    },
    (error) => {
      setLoading(false)
      if (error.response.data.errors) {
        setFailMessage(error.response.data.errors[0].message)
        seterrorAlert(true)
      } else {
        setFailMessage(error.response.data)
        seterrorAlert(true)
      }
    })
  }

  // set form values function
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  return (
    <div>
      {loading ? <LinearProgress /> : null}
      {' '}
      <div className={classes.alertroot}>
        {' '}
        {successAlert ? (
          <Alert severity='success'>
            Bitbucket project has been created successfully
          </Alert>
        ) : null}
        {errorAlert ? (
          <Alert
            severity='error'
            onClose={() => seterrorAlert(false)}
          >
            {failmessage}
          </Alert>
        ) : null}
      </div>
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={12} md={8}>
          <Card variant='outlined'>
            <ValidatorForm ref={form} onSubmit={(event) => handleSubmit(event)}>
              <CardContent>
                <Typography variant='subtitle1'>
                  Create Bitbucket Project
                </Typography>
                <div>
                  <FormControl className={classes.formControl}>
                    <TextValidator
                      label='Project Key*'
                      name='projectKey'
                      fullWidth
                      onChange={handleChange}
                      value={formData.projectKey}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />

                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextValidator
                      label='Project Name*'
                      name='name'
                      fullWidth
                      onChange={handleChange}
                      value={formData.name}
                      validators={['required', 'MaxLength128']}
                      errorMessages={
                        ['This field is required',
                          'Not more than 128 characters long.']
                      }
                    />
                  </FormControl>
                  <FormControl
                    className={classes.formControl}
                    style={{
                      marginTop: 0
                    }}
                  >
                    <TextValidator
                      label='Project Description*'
                      name='description'
                      fullWidth
                      onChange={handleChange}
                      value={formData.description}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />
                  </FormControl>

                </div>
              </CardContent>
              <CardActions className={classes.footer}>
                <Button
                  variant='contained'
                  size='small'
                  onClick={() => resetForm()}
                >
                  Reset
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  disabled={loading || user.switch_user}
                  size='small'
                >
                  Create Project
                </Button>
              </CardActions>
            </ValidatorForm>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default CreateBitbucketProjectComponent
