/* eslint indent: 0 */
import React, {
  useContext
} from 'react'
import {
  Typography,
  makeStyles,
  Tabs,
  Tab,
  Box,
  LinearProgress,
  TextField,
  Grid,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core'
import PropTypes from 'prop-types'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import queryString from 'query-string'
import RadioButtons from '../inputs/radio'
import jiraIcon from '../../assets/images/jira-logo.png'
import confluenceIcon from '../../assets/images/confluence-logo.png'
import bitbucketIcon from '../../assets/images/bitbucket-logo.png'
import jenkinsartifactoryIcon from
  '../../assets/images/jenkins - artifactory-logo.png'
import accountIcon from '../../assets/images/account-logo.png'
import cloudXKubernetes from '../../assets/images/Cloudx_Kubernetes.png'
import eatjenkinsartifactoryIcon from
  '../../assets/images/eat jenkins - artifactory-logo.png'
import vpcxazrIcon from '../../assets/images/vpcx-azr-logo.png'
import vpcxawsIcon from '../../assets/images/vpcx-aws-logo.png'
import applitoolsIcon from '../../assets/images/applitools-logo.png'
import blazemeterIcon from '../../assets/images/blazemeter-logo.png'
import saucelabsIcon from '../../assets/images/saucelabs-logo.png'
import opcxsrvIcon from '../../assets/images/opcx-srv-logo.png'
import qTestIcon from '../../assets/images/qTest-Tool-Access.png'
import muleSoftIcon from '../../assets/images/mulesoft_logo.png'
import githubCoPilotIcon from '../../assets/images/github_copilot.png'
import xenaFrameWorkIcon from '../../assets/images/xena-fw-icon.png'
import UserContext from '../contexts/UserContext'
import EnhancedTable from '../sections/enhancedTable'
import ToolsService from '../../services/api/toolsService'
import requestAccess from '../../services/api/requestaccessService'

const radiooptions = ['Self', 'Other']
const headCells = [
  {
    id: 'originalkey',
    numeric: false,
    disablePadding: true,
    label: 'KEY',
    page: 'Tool Access'
  },
  {
    id: 'key_app_name',
    numeric: false,
    disablePadding: false,
    label: 'NAME'
  }
]
function TabPanel ({
  children, value, index, ...other
}) {
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function getToolimage (name) {
  switch (name) {
  case 'Jira':
    return (
      <img
        height='35'
        width='35'
        src={jiraIcon}
        alt=''
      />
    )
  case 'Confluence':
    return (
      <img
        height='35'
        width='35'
        src={confluenceIcon}
        alt=''
      />
    )
  case 'Bitbucket':
    return (
      <img
        height='35'
        width='35'
        src={bitbucketIcon}
        alt=''
      />
    )
  case 'Jenkins - Artifactory':
    return (
      <img
        height='50'
        width='120'
        src={jenkinsartifactoryIcon}
        alt=''
      />
    )
  case 'qTest':
    return (
      <img
        height='35'
        width='35'
        src={qTestIcon}
        alt=''
      />
    )
  case 'Account':
    return (
      <img
        height='35'
        width='35'
        src={accountIcon}
        alt=''
      />
    )
  case 'Cloudx Kubernetes':
    return (
      <img
        height='35'
        width='35'
        src={cloudXKubernetes}
        alt=''
      />
    )
  case 'EAT Jenkins and Artifactory':
    return (
      <img
        height='50'
        width='100'
        src={eatjenkinsartifactoryIcon}
        alt=''
      />
    )
  case 'VPCX-AWS':
    return (
      <img
        height='35'
        width='35'
        src={vpcxawsIcon}
        alt=''
      />
    )
  case 'VPCX-AZR':
    return (
      <img
        height='35'
        width='35'
        src={vpcxazrIcon}
        alt=''
      />
    )
  case 'MuleSoft':
    return (
      <img
        height='35'
        width='35'
        src={muleSoftIcon}
        alt=''
      />
    )
  case 'Applitools':
    return (
      <img
        height='35'
        width='35'
        src={applitoolsIcon}
        alt=''
      />
    )
  case 'Blazemeter':
    return (
      <img
        height='50'
        width='100'
        src={blazemeterIcon}
        alt=''
      />
    )
  case 'Saucelabs':
    return (
      <img
        height='35'
        width='35'
        src={saucelabsIcon}
        alt=''
      />
    )
  case 'OPCX-SRV':
    return (
      <img
        height='35'
        width='35'
        src={opcxsrvIcon}
        alt=''
      />
    )
  case 'GitHub Copilot':
    return (
      <img
        height='35'
        width='35'
        src={githubCoPilotIcon}
        alt=''
      />
    )
  case 'XENA Framework Tools':
    return (
      <img
        height='35'
        width='35'
        src={xenaFrameWorkIcon}
        alt=''
      />
    )
  default:
    return null
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  autocomplete: {
    width: '100%'
  },
  nodatatext: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(8),
    textAlign: 'center'
  }
}))

function ToolAccessComponent (props) {
  const queryparams = queryString.parse(props.location.search)
  const {
    user
  } = useContext(UserContext)
  const [selectedValue, setSelectedValue] = React.useState('Self')
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const [alltools, setAlltools] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [applink, setApplink] = React.useState(null)
  const [allUsers, setAllusers] = React.useState([])
  const [loadingoptions, setLoadingoptions] = React.useState(false)
  const [currentUser, setCurrentuser] = React.useState(user.name)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [failalertMessage, setFailalertMessage] = React.useState(null)
  const [currentUserDisplay, setCurrentUserDisplay] = React.useState(null)

  /* to get the available tools for tool selection dropdown when the page gets loaded */
  React.useEffect(() => {
    if (queryparams.username) {
      requestAccess.getallusersforautocomplete(queryparams.username)
        .then((response) => {
          const filtervalue = response.data
            .filter((item) => item.name.toLowerCase() ===
        queryparams.username.toLowerCase())
          if (filtervalue.length && filtervalue.length === 1) {
            setSelectedValue('Other')
            setCurrentuser(filtervalue[0].name)
            setCurrentUserDisplay(`
              ${filtervalue[0].name} - ${filtervalue[0].displayname}
            `)
            getTools(filtervalue[0].name, queryparams.tool)
          } else {
            getTools(user.name)
          }
        })
    } else {
      if (queryparams.tool) {
        getTools(user.name, queryparams.tool)
      }
      getTools(user.name)
    }
  }, [])

  /* to get tools and projects with groups */
  const getTools = (username, toolname) => {
    setValue(0)
    setLoading(true)
    ToolsService.mytools(username).then((response) => {
      if (response.data) {
        //   setAlltools(response.data)
        const applitoolsGroups = []
        ToolsService.getApiToolGroups('Applitools').then((response1) => {
          response1.data.forEach((data) => {
            const usernameinput = `${username.toLowerCase()}@its.jnj.com`
            const roles = []
            if (data.members && data.members[usernameinput]) {
              if (data.members[usernameinput].isViewer) {
                roles.push({
                  group_name: 'Viewer'
                })
              } else {
                roles.push({
                  group_name: 'Regular'
                })
              }
              applitoolsGroups.push({
                toolname: 'Applitools',
                show: false,
                key: data.name,
                originalkey: data.name,
                key_app_name: data.name,
                expandedhtml:
                <List component='nav'>
                  {roles.length
                    ? roles.map((group) => (
                      <ListItem
                        key={group.group_name}
                      >
                        <ListItemText
                          primary={group.group_name}
                        />
                      </ListItem>
                    )) : 'No data to display'}
                </List>
              })
            }
          })

          const alltools1 = response.data
          let indexvalue = null
          alltools1.forEach((tool, index) => {
            if (tool.key === 'Applitools') {
              alltools1[index].values = applitoolsGroups
            }
            if (toolname && toolname.toLowerCase() === tool.key.toLowerCase()) {
              setValue(index)
              indexvalue = index
            }
          })
          setAlltools(alltools1)
          setRows(response.data[indexvalue || 0].values)
          let url = response.data[indexvalue || 0].url
          ? response.data[indexvalue || 0].url
          : null
          if (url) {
            if (response.data[indexvalue || 0].key.toLowerCase() === 'jira' ||
            response.data[indexvalue || 0].key.toLowerCase() === 'bitbucket') {
              url += 'projects/<key>'
            } else if (response.data[indexvalue || 0]
              .key.toLowerCase() === 'confluence') {
              url += 'display/<key>'
            }
          }
          setApplink(url)
          setLoading(false)
        }, (error) => {
          if (error) {
            setLoading(false)
            setFailalertMessage(`
              Something went wrong. Contact system administrator!
            `)
            seterrorAlert(true)
          }
        })
      }
    }, (error) => {
      if (error) {
        setLoading(false)
        setFailalertMessage(`
          Something went wrong. Contact system administrator!
        `)
        seterrorAlert(true)
      }
    })
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
    let url = alltools[newValue].url
          ? alltools[newValue].url
          : null
          if (url) {
            if (alltools[newValue].key.toLowerCase() === 'jira' ||
            alltools[newValue].key.toLowerCase() === 'bitbucket') {
              url += 'projects/<key>'
            } else if (alltools[newValue]
              .key.toLowerCase() === 'confluence') {
              url += 'display/<key>'
            }
          }
    setApplink(url)
    setRows(alltools[newValue].values)
  }
  /* function to get the users list based on the search key */
  const getUsers = (username) => {
    setLoadingoptions(true)
    requestAccess.getallusersforautocomplete(username).then((response) => {
      setAllusers(response.data)
      setLoadingoptions(false)
    })
  }
  /* To get tool projects for selected user */
  const changeUser = (username) => {
    if (username) {
      setCurrentuser(username.name)
      setCurrentUserDisplay(`${username.name} - ${username.displayname}`)
      getTools(username.name)
    }
  }
  /* change funtion when clicking radio button */
  const changeOption = (option) => {
    setSelectedValue(option)
    if (option === 'Self') {
      getTools(user.name)
    } else {
      setAlltools([])
      setRows([])
    }
  }

  return (
    <div className={classes.root}>
      {loading ? <LinearProgress /> : null}
      <div className={classes.alertroot}>
        {errorAlert ? (
          <Alert
            severity='error'
            onClose={() => seterrorAlert(false)}
          >
            {failalertMessage}
          </Alert>
        ) : null}
      </div>
      <Grid container spacing={2}>
        <Grid container item xs={4} md={2}>
          <RadioButtons
            radio={radiooptions}
            selectedvalue={selectedValue}
            onChange={(option) => changeOption(option)}
          />
        </Grid>
        <Grid container item xs={6} md={3}>
          {selectedValue === 'Other' ? (

            <Autocomplete
              id='combo-box-demo'
              className={classes.autocomplete}
              options={allUsers}
              getOptionLabel={(option) => (option.name
                ? `${option.name} - ${option.displayname}`
                : option)}
              loading={
                allUsers.length === 0 &&
              loadingoptions
              }
              value={currentUserDisplay}
              getOptionSelected={(option,
                uservalue) => option.name === uservalue.name}
              onChange={(event, uservalue) => changeUser(uservalue)}
              filterOptions={(options, state) => options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Choose a user *'
                  name='username'
                  onChange={(event) => getUsers(event.target.value)}

                />
              )}
            />

          ) : null}
        </Grid>
      </Grid>

      <Tabs
        value={value}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons='on'
        indicatorColor='primary'
        textColor='primary'
        aria-label='scrollable force tabs example'
        style={{
          marginLeft: '-30px'
        }}
      >

        {alltools.map((tool) => (
          <Tab key={tool.key} icon={getToolimage(tool.key)} title={tool.key} />
        ))}
      </Tabs>
      {alltools.map((tool, index) => (
        <TabPanel value={value} index={index} key={tool.key}>
          {rows.length && !loading ? (
            <EnhancedTable
              key={tool.key}
              orderby='originalkey'
              applink={applink}
              headCells={headCells}
              rowsData={rows}
              currentUser={currentUser}
              checkbox={false}
            />
          ) : null}
          {loading === false && rows.length === 0
            ? (
              <div key={tool.key} className={classes.nodatatext}>
                No data to display.
              </div>
            ) : null}
        </TabPanel>
      ))}
    </div>
  )
}

export default ToolAccessComponent
