import React, {
  useContext
} from 'react'
import {
  Button, makeStyles, LinearProgress
} from '@material-ui/core'
import {
  Alert
} from '@material-ui/lab'
import EnhancedTable from '../sections/enhancedTable'
import UserContext from '../contexts/UserContext'
import ArchiveJiraProjectService from
'../../services/api/archiveJiraProjectService'

const headCells = [
  {
    id: 'project_key',
    numeric: false,
    label: 'Project Key'
  },
  {
    id: 'archived_by',
    numeric: false,
    label: 'Archived By'
  },
  {
    id: 'archived',
    numeric: false,
    label: 'Archived Date'
  }
]

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  nodatatext: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(8),
    textAlign: 'center'
  },
  footer: {
    '& > *': {
      margin: theme.spacing(1)
    },
    float: 'right'
  }
}))

export default function ArchivedJiraProjectComponent () {
  const {
    user, setUser
  } = useContext(UserContext)
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const [loadingbar, setLoadingbar] = React.useState(false)
  const [archivedProjectList, setArchivedProjectList] = React.useState([])
  const [alertbox, setAlerbox] = React.useState(false)
  const [successAlert, setsuccessAlert] = React.useState(false)

  /* to get list of cart items */
  function getArchivedJiraProjects () {
    setLoading(true)
    ArchiveJiraProjectService.getArchivedJiraProjects().then((response) => {
      setArchivedProjectList(response.data)
      setUser({
        ...user
      })
      setLoading(false)
    })
  }
  /* initial call when page gets loaded */
  React.useEffect(() => {
    getArchivedJiraProjects()
  }, [])

  return (
    <div className={classes.root}>
      {loadingbar ? <LinearProgress /> : null}
      {loading ? <LinearProgress /> : null}
      {archivedProjectList.length && !loading ? (
        <>
          <EnhancedTable
            key='jira-archived-projects-table'
            headCells={headCells}
            rowsData={archivedProjectList}
            checkbox={false}
            orderby='project_key'
          />
        </>
      ) : null}
      {!loading && !archivedProjectList.length ? (
        <div className={classes.nodatatext}>
          There are no archived projects.
        </div>
      ) : null}
    </div>
  )
}