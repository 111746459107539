import React, {
  useContext
} from 'react'
import {
  Button, makeStyles, LinearProgress
} from '@material-ui/core'
import {
  Alert
} from '@material-ui/lab'
import EnhancedTable from '../sections/enhancedTable'
import UserContext from '../contexts/UserContext'
import CartService from '../../services/api/cartService'
import AlertDialog from '../alertbox/alertboxComponent'
import EmailServices from '../../services/api/emailServices'
import LoginService from '../../services/api/loginService'

const headCells = [
  {
    id: 'cart_id',
    numeric: true,
    label: 'Cart Item'
  },
  {
    id: 'username',
    numeric: false,
    label: 'Requested By',
    type: 'username'
  },
  {
    id: 'req_for',
    numeric: false,
    label: 'Requested For',
    type: 'username'
  },
  {
    id: 'tool_name',
    numeric: false,
    label: 'Tool'
  },
  {
    id: 'key_name',
    numeric: false,
    label: 'Project'
  },
  {
    id: 'group_name',
    numeric: false,
    label: 'Role'
  },
  {
    id: 'action_type',
    numeric: false,
    type: 'global',
    label: 'Action Type'
  },
  {
    id: 'delete',
    numeric: false,
    type: 'button',
    label: 'Delete'
  }
]

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  nodatatext: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(8),
    textAlign: 'center'
  },
  footer: {
    '& > *': {
      margin: theme.spacing(1)
    },
    float: 'right'
  }
}))

const alertboxMessage = 'Are you sure want to remove cart items?'

export default function CartComponent () {
  const {
    user, setUser
  } = useContext(UserContext)
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const [loadingbar, setLoadingbar] = React.useState(false)
  const [cartlist, setCartlist] = React.useState([])
  const [alertbox, setAlerbox] = React.useState(false)
  const [successAlert, setsuccessAlert] = React.useState(false)

  /* to get list of cart items */
  function getCart () {
    setLoading(true)
    CartService.viewCart(user.name).then((response) => {
      setCartlist(response.data)
      setUser({
        ...user,
        cartsize: response.data.length
      })
      setLoading(false)
    })
  }
  /* initial call when page gets loaded */
  React.useEffect(() => {
    getCart()
  }, [])
  /* to delete one item from cart */
  const deleteCart = (id) => {
    setLoadingbar(true)
    CartService.deleteFromCart(id, user.name).then((response) => {
      setLoadingbar(false)
      getCart()
    })
  }
  /* to delete all requests from cart */
  const confirmbutton = () => {
    setAlerbox(false)
    setLoadingbar(true)
    CartService.emptyCart(user.name).then((response) => {
      setLoadingbar(false)
      setCartlist([])
      setUser({
        ...user,
        cartsize: 0
      })
    })
  }
  /* to submit all requests from cart */
  const submitFinalRequest = () => {
    setLoadingbar(true)
    CartService.submitFinalRequest(user.name).then((response) => {
      setLoadingbar(false)
      setsuccessAlert(true)
      setTimeout(() => {
        setsuccessAlert(false)
      }, 20000)
      setCartlist([])
      LoginService.getAdminaccess().then((approvaldata) => {
        setUser({
          ...user,
          approvalcount: approvaldata.data.approvalcount,
          cartsize: 0
        })
      })
      if (user.email && response.data.length) {
        EmailServices.sendMailtoApprover(response.data).then((respone2) => { })
      }
    })
  }
  /* to close the alert modal */
  const handleClose = () => {
    setAlerbox(false)
  }

  return (
    <div className={classes.root}>
      {successAlert ? (
        <Alert severity='success'>
          Items in Cart were successfully submitted for review!
        </Alert>
      ) : null}
      {loadingbar ? <LinearProgress /> : null}
      {loading ? <LinearProgress /> : null}
      {cartlist.length && !loading ? (
        <>
          <EnhancedTable
            key='cart-table'
            deleteItem={deleteCart}
            headCells={headCells}
            rowsData={cartlist}
            checkbox={false}
            orderby='cart_id'
          />
          <div className={classes.footer}>
            <Button
              variant='contained'
              aria-label='empty cart'
              onClick={() => setAlerbox(true)}
              disabled={user.switch_user}
            >
              Empty Cart
            </Button>
            <Button
              variant='contained'
              aria-label='submit cart'
              color='primary'
              onClick={() => submitFinalRequest()}
              disabled={user.switch_user}
            >
              Submit Request
            </Button>
          </div>
        </>
      ) : null}
      {!loading && !cartlist.length ? (
        <div className={classes.nodatatext}>
          Cart Items Empty.
        </div>
      ) : null}
      <AlertDialog
        handleClose={handleClose}
        alertopen={alertbox}
        message={alertboxMessage}
        confirmbutton={confirmbutton}
        key='alert1'
        title='Alert'
        okbuttonalert={false}
      />
    </div>
  )
}
