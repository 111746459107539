import React, {
  useContext, useRef
} from 'react'
import {
  Button,
  Typography,
  Grid,
  MenuItem,
  makeStyles,
  FormControl,
  LinearProgress,
  Chip,
  Tabs,
  Tab,
  Box,
  FormHelperText,
  Tooltip
} from '@material-ui/core'
import {
  Link
} from 'react-router-dom'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import InfoIcon from '@material-ui/icons/Info'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import {
  withStyles
} from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import {
  Autocomplete
} from '@material-ui/lab'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import StarIcon from '@material-ui/icons/Star'
import {
  yellow
} from '@material-ui/core/colors'
import moment from 'moment-timezone'
import EnhancedTable from '../sections/enhancedTable'
import AlertDialog from '../alertbox/alertboxComponent'
import UserContext from '../contexts/UserContext'
import DashboardService from '../../services/api/dashboardService'
import PathContext from '../contexts/PathContext'
import ViewDashboardComponent from './viewDashboardComponent'
import requestAccess from '../../services/api/requestaccessService'
import globalData from '../../data/globalData.json'

const headCells = [

  {
    id: 'link',
    numeric: false,
    label: 'Dashboard Name'
  },
  {
    id: 'created_by',
    numeric: false,
    label: 'Created By',
    type: 'username'
  },
  {
    id: 'created_on_sort',
    numeric: false,
    type: 'date',
    label: 'Created On'
  },
  {
    id: 'last_modified_by',
    numeric: false,
    label: 'Last Modified By',
    type: 'username'
  },
  {
    id: 'last_modified_on_sort',
    numeric: false,
    type: 'date',
    label: 'Last Modified On'
  },
  {
    id: 'dashboard_mode',
    numeric: false,
    label: 'Privacy'
  },
  {
    id: 'dashboard_owners',
    numeric: false,
    label: 'Dashboard Owners',
    type: 'list'
  },
  {
    id: 'action',
    numeric: false,
    label: 'Action'
  }
]

const timeZone = moment.tz.guess()
function TabPanel (props) {
  const {
    children, value, index, ...other
  } = props

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

const useStyles = makeStyles((theme) => ({

  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },

  root: {
    flexGrow: 1,
    width: '100%'
  },
  nodatatext: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(8),
    textAlign: 'center'
  },
  createbtn: {
    width: '95%'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  }
}))

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh'
  }
})

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions)

export default function DashboardComponent () {
  const {
    user
  } = useContext(UserContext)
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState(0)
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  // setMyTemplates
  const [alldashboards, setAllDashboards] = React.useState([])
  const [recentdashboards, setRecentDashboards] = React.useState([])
  const [favoritedashboards, setFavoriteDashboards] = React.useState([])
  const {
    path, setPath
  } = useContext(PathContext)
  const form = useRef(null)
  const [formData, setFormData] = React.useState({
  })
  const [alertStatus, setAlertStatus] = React.useState(false)
  const [currentId, setCurrentId] = React.useState([])
  const [loadingbar, setLoadingbar] = React.useState(false)
  const [allUsers, setAllusers] = React.useState([])
  const [nameError, setNameError] = React.useState(false)

  const [originalname, setOriginalName] = React.useState(null)

  const setValuenull = (value1) => {
    value1.splice(0, 1)
    value1 = []
  }

  const setMoreButton = (item) => {
    let result = []
    if (value === 0) {
      result = alldashboards
    } else if (value === 1) {
      result = recentdashboards
    } else {
      result = favoritedashboards
    }
    for (const i in result) {
      if (result[i].id === item.id) {
        result[i].moreButton = !result[i].moreButton
      }
    }

    if (value === 0) {
      setAllDashboards([...result])
    } else if (value === 1) {
      setRecentDashboards([...result])
    } else {
      setFavoriteDashboards([...result])
    }
  }

  /* change function for tab change */
  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleAlertClose = () => {
    setAlertStatus(false)
  }

  const handleClickOpen = () => {
    setFormData({
      id: 0,
      name: '',
      privacy: 'Public',
      owner: user.name,
      dashboardowners: [{
        name: user.name
      }],
      dashboardusers: []
    })
    setOpen(true)
  }

  const handelDelete = (id) => {
    setCurrentId(id)
    setAlertStatus(true)
  }

  const addToFavorite = (item) => {
    DashboardService.saveRecentAndFavoriteDB({
      id: item.id,
      username: user.name,
      type: 'favorite'
    }).then((response) => {
      getDashboards()
    })
  }

  const deleteItem = () => {
    DashboardService.deleteDashboards(currentId).then((response) => {
      setLoading(false)
      getDashboards()
      setAlertStatus(false)
    },
    (error) => {
      if (error) {
        setLoading(false)
      }
    })
  }

  const handleClose = () => {
    setOpen(false)
    setNameError(false)
  }

  function saveDashboardConfig () {
    const dashboardusers = []
    formData.dashboardowners.map((item) => {
      dashboardusers.push({
        username: item.name,
        id: formData.id,
        type: globalData.TEMPLATE_OWNER
      })
    })
    if (formData.privacy === 'Private') {
      formData.dashboardusers.map((item) => {
        dashboardusers.push({
          username: item.name,
          id: formData.id,
          type: globalData.TEMPLATE_USER
        })
      })
    }
    const input = {
      id: formData.id,
      name: formData.name,
      privacy: formData.privacy,
      owner: formData.owner,
      dashboardusers
    }
    handleClose()
    setLoading(true)
    DashboardService.saveDashboards(input).then((response) => {
      getDashboards()
      //  setLoading(false)
    },
    (error) => {
      if (error) {
        setLoading(false)
      }
    })
  }
  const handleSubmit = (event) => {
    if (formData.id === 0 ||
      (formData.name.toLowerCase() !==
        originalname.toLowerCase())) {
      DashboardService.checkDashboardNameExist(formData.name)
        .then((response) => {
          if (response.data === true) {
            setNameError(true)
          } else {
            setNameError(false)
            saveDashboardConfig()
          }
        })
    } else {
      setNameError(false)
      saveDashboardConfig()
    }
  }
  const checkNameError = (e) => {
    if (formData.id === 0 ||
      (formData.name.toLowerCase() !==
        originalname.toLowerCase())) {
      if (e.target.value) {
        DashboardService.checkDashboardNameExist(formData.name)
          .then((response) => {
            if (response.data === true) {
              setNameError(true)
            } else {
              setNameError(false)
            }
          })
      }
    } else {
      setNameError(false)
    }
  }
  const handleChange = (e) => {
    setNameError(false)
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleEdit = (value1) => {
    DashboardService.getDashboardUsers(value1.id).then((response) => {
      const {
        data
      } = response
      const dashboardusers = []
      const dashboardowners = []
      data.map((item) => {
        if (item.type === 2) {
          dashboardusers.push({
            name: item.username,
            displayname: item.displayname
          })
        } else {
          dashboardowners.push({
            name: item.username,
            displayname: item.displayname
          })
        }
      })
      value1.dashboardusers = dashboardusers
      value1.dashboardowners = dashboardowners
      populateFormData(value1)
    })
    //  myTemplates.filter((name) => name.id === id).map((filteredName) => (

    //  ))
  }

  const populateFormData = (data) => {
    setFormData({
      id: data.id,
      name: data.dashboard_name,
      privacy: data.dashboard_mode,
      owner: data.created_by,
      dashboardusers: data.dashboardusers,
      dashboardowners: data.dashboardowners
    })
    setOriginalName(data.dashboard_name)
    setNameError(false)
    setOpen(true)
  }
  const changeUser = (value1, field) => {
    setFormData({
      ...formData,
      [field]: value1
    })
  }
  /* function to get the users list based on the search key */
  const getUsers = (value1) => {
    setFormData({
      ...formData,
      searchkey: value1
    })
    setLoadingbar(true)
    requestAccess.getallusersforautocomplete(value1).then((response) => {
      setAllusers(response.data)
      setLoadingbar(false)
    })
  }

  function setTableValues (data, favarray) {
    data.map((item, i) => {
      if (favarray.includes(item.id)) {
        data[i].favorite = true
      }
      data[i].dashboard_owners = JSON.parse(item.dashboard_owners)
      data[i].link = (
        <Link
          aria-label='item.name'
          color='primary'
          to={`/dashboards/view/${item.id}`}
          onClick={() => {
            setPath({
              pathname: '/dashboards/view/:id',
              component: ViewDashboardComponent,
              supportPath: path.supportPath
            })
          }}
        >
          {item.dashboard_name}
        </Link>
      )
      if (item.isowner) {
        data[i].action = (
          <>
            <IconButton
              aria-label='favorite'
              title='Add to Favorites'
              onClick={() => addToFavorite(item)}
            >
              {item.favorite ? (
                <StarIcon style={{
                  color: yellow[500]
                }}
                />
              )
                : <StarBorderIcon color='action' />}
            </IconButton>
            <IconButton
              aria-label='delete'
              onClick={() => handleEdit(item)}
            >
              <EditIcon color='primary' />
            </IconButton>
            <IconButton
              aria-label='delete'
              onClick={() => handelDelete(item.id)}
            >
              <DeleteIcon color='secondary' />
            </IconButton>

          </>
        )
      } else {
        data[i].action = (
          <>
            <IconButton
              aria-label='favorite'
              title='Add to Favorites'
              onClick={() => addToFavorite(item)}
            >
              {item.favorite ? (
                <StarIcon style={{
                  color: yellow[500]
                }}
                />
              )
                : <StarBorderIcon color='action' />}
            </IconButton>
            <IconButton style={{
              cursor: 'none'
            }}
            >
              <Tooltip title={(
                <span style={{
                  fontSize: 12,
                  fontFamily: 'Verdana'
                }}
                >
                  Only dashboard owners can modify the dashboards
                    and its content
                </span>
              )}
              >
                <InfoIcon style={{
                  color: '#eea508'
                }}
                />
              </Tooltip>
            </IconButton>
          </>
        )
      }
    })
    return data
  }
  const getDashboards = () => {
    setAllDashboards([])
    setRecentDashboards([])
    setFavoriteDashboards([])
    setAlertStatus(false)
    setLoading(true)
    DashboardService.getDashboards(timeZone).then((response) => {
      const {
        data
      } = response
      const {
        all, recent, favorite
      } = data
      const favarray = []
      favorite.map((item) => favarray.push(item.id))
      setAllDashboards(setTableValues(all, favarray))
      setRecentDashboards(setTableValues(recent, favarray))
      setFavoriteDashboards(setTableValues(favorite, favarray))
      setFormData({
        id: 0,
        name: '',
        privacy: '',
        owner: '',
        dashboardowners: [],
        dashboardusers: []
      })
      setLoading(false)
    })
  }

  /* initial call when page gets loaded */
  React.useEffect(() => {
    setLoading(true)

    getDashboards()
  }, [])

  return (
    <div className={classes.root}>
      {loading ? <LinearProgress /> : null}
      <div className={classes.createbtn}>
        <Button
          variant='outlined'
          className='pull-right'
          color='primary'
          onClick={handleClickOpen}
        >
          Create Dashboard
        </Button>
      </div>

      <Paper square>
        <Tabs
          value={value}
          onChange={handleTabChange}
          variant='scrollable'
          scrollButtons='on'
          indicatorColor='primary'
          textColor='primary'
          aria-label='scrollable force tabs example'
        >

          <Tab
            key='all'
            label='Dashboards'
            title='Dashboards'
          />
          <Tab
            key='recents'
            label='Recent Dashboards'
            title='Recent Dashboards'
          />
          <Tab
            key='favorites'
            label='Favorites'
            title='Favorites'
          />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0} key='tab-all'>
        {!loading && alldashboards.length ? (
          <EnhancedTable
            key='alldashboards-table'
            headCells={headCells}
            rowsData={alldashboards}
            checkbox={false}
            orderby='last_modified_on_sort'
            sortorder='desc'
            rowsperpage={10}
            setMoreButton={setMoreButton}
          />
        ) : null}
        {!loading && !alldashboards.length ? (
          <div className={classes.nodatatext}>
            No data to display.
          </div>
        ) : null}
      </TabPanel>
      <TabPanel value={value} index={1} key='tab-recents'>
        {!loading && recentdashboards.length ? (
          <EnhancedTable
            key='recentdashboards-table'
            headCells={headCells}
            rowsData={recentdashboards}
            checkbox={false}
            rowsperpage={10}
            setMoreButton={setMoreButton}
          />
        ) : null}
        {!loading && !recentdashboards.length ? (
          <div className={classes.nodatatext}>
            No data to display.
          </div>
        ) : null}
      </TabPanel>
      <TabPanel value={value} index={2} key='tab-favorites'>
        {!loading && favoritedashboards.length ? (
          <EnhancedTable
            key='favoritedashboards-table'
            headCells={headCells}
            rowsData={favoritedashboards}
            checkbox={false}
            orderby='last_modified_on_sort'
            sortorder='desc'
            rowsperpage={10}
            setMoreButton={setMoreButton}
          />
        ) : null}
        {!loading && !favoritedashboards.length ? (
          <div className={classes.nodatatext}>
            No data to display.
          </div>
        ) : null}
      </TabPanel>

      <Dialog
        classes={{
          paper: classes.dialogPaper
        }}
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          {formData.id === 0 ? 'Create Dashboard' : 'Edit Dashboard'}
        </DialogTitle>
        <ValidatorForm ref={form} onSubmit={(event) => handleSubmit(event)}>
          <DialogContent dividers>

            <Grid container spacing={2} className={classes.root}>
              <Grid item xs={12} md={12}>
                <FormControl className={classes.formControl} error={nameError}>
                  {/* <TextValidator value={formData.name} onChange={handleChange} label='Title' name='name' validators={['required', 'nameExist']} errorMessages={['this field is required', 'Dashboard name is already in use']} /> */}
                  <TextValidator
                    label='Title*'
                    name='name'
                    fullWidth
                    type='text'
                    onChange={handleChange}
                    value={formData.name}
                    onMouseLeave={(e) => checkNameError(e)}
                    validators={['required']}
                    errorMessages={['This field is required']}
                  />
                  {nameError && (
                    <FormHelperText>
                      Dashboard name is already in use
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl className={classes.formControl}>
                  <SelectValidator
                    label='Privacy'
                    name='privacy'
                    fullWidth
                    value={formData.privacy ? formData.privacy : 'Public'}
                    onChange={handleChange}
                  >
                    <MenuItem value='Private' key='private'>
                      Private
                    </MenuItem>
                    <MenuItem value='Public' key='public'>
                      Public
                    </MenuItem>

                  </SelectValidator>
                </FormControl>
                {formData.privacy === 'Private' ? (
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id='combo-box-demo-users'
                      multiple
                      options={allUsers}
                      defaultValue={formData.dashboardusers}
                      getOptionLabel={(option) => (option.name
                        ? `${option.name} - ${option.displayname}`
                        : option)}
                      loading={allUsers.length === 0 && loadingbar}
                      filterOptions={(options, state) => options}
                      getOptionSelected={
                        (option, dashboardUsersValue) => option.name ===
                          dashboardUsersValue.name
                      }
                      renderTags={
                        (dashboardUsersTags,
                          getTagProps) => dashboardUsersTags.map((option,
                          index) => (formData.dashboardusers.length ? (
                          <Chip
                            label={
                              option.name
                                ? option.name
                                : option
                            }
                            {...getTagProps({
                              index
                            })}
                          />
                        ) : (
                          setValuenull(value)
                        )))
                      }
                      onChange={
                        (event, duChange) => changeUser(duChange,
                          'dashboardusers')
                      }
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          label='Dashboard Users*'
                          name='dashboardusers'
                          onChange={(event) => getUsers(event.target.value)}
                          value={formData.dashboardusers}
                          validators={['required']}
                          errorMessages={['This field is required']}
                        />
                      )}
                    />
                  </FormControl>
                ) : null}

                <FormControl className={classes.formControl}>
                  <Autocomplete
                    id='combo-box-owner'
                    multiple
                    options={allUsers}
                    defaultValue={formData.dashboardowners}
                    filterOptions={(options, state) => options}
                    getOptionLabel={(option) => (option.name
                      ? `${option.name} - ${option.displayname}`
                      : option)}
                    loading={allUsers.length === 0 && loadingbar}
                    getOptionSelected={
                      (option,
                        dashboardOwnerUserValue) => option.name ===
                          dashboardOwnerUserValue.name
                    }
                    renderTags={(dOwnerExistingUsers,
                      getTagProps) => dOwnerExistingUsers.map((option,
                      index) => (formData.dashboardowners.length ? (
                      <Chip
                        label={
                          option.name
                            ? option.name
                            : option
                        }
                        {...getTagProps({
                          index
                        })}
                      />
                    ) : (
                      setValuenull(value)
                    )))}
                    onChange={
                      (event,
                        dOChangeUser) => changeUser(dOChangeUser,
                        'dashboardowners')
                    }
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label='Dashboard Owners*'
                        name='dashboardowners'
                        onChange={(event) => getUsers(event.target.value)}
                        value={formData.dashboardowners}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      />
                    )}
                  />
                </FormControl>

              </Grid>

            </Grid>

          </DialogContent>
          <DialogActions>
            <Button type='submit' autoFocus color='primary'>
              Save changes
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>

      <AlertDialog
        handleClose={handleAlertClose}
        alertopen={alertStatus}
        message='Do You want to delete this record'
        confirmbutton={deleteItem}
        key='alert1'
        title='Alert'
        okbuttonalert={false}
      />
    </div>
  )
}
