import React from 'react'
import {
  Typography,
  Checkbox,
  LinearProgress,
  Card,
  CardActions,
  CardContent,
  Chip,
  FormControl,
  MenuItem,
  Paper,
  Tabs,
  Tab,
  Box,
  Select,
  FormGroup,
  FormControlLabel,
  Button,
  TextField,
  makeStyles,
  InputLabel
} from '@material-ui/core'
import PropTypes from 'prop-types'

import {
  Autocomplete, Alert
} from '@material-ui/lab'
import RadioButtons from '../inputs/radio'
import EnhancedTable from '../sections/enhancedTable'
import temporaryAccessService from '../../services/api/temporaryAccessService'
import requestAccess from '../../services/api/requestaccessService'

function TabPanel (props) {
  const {
    children, value, index, ...other
  } = props

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

export default function temporaryAccessComponent () {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },
    footer: {
      margin: theme.spacing(2),
      float: 'right'
    },
    formControl: {
      margin: theme.spacing(1),
      width: '98%'
    },
    components: {
      margin: theme.spacing(1),
      width: '40%'
    },
    labelDisplay: {
      margin: '10px',
      width: '100%'
    },
    nodatatext: {
      ...theme.typography.subtitle1,
      padding: theme.spacing(12),
      textAlign: 'center'
    }
  }))
  const classes = useStyles()

  const toolsArray = [
    {
      name: 'Jenkins'
    },
    {
      name: 'Bitbucket'
    },
    {
      name: 'Artifactory'
    }
  ]
  // table header for pending approvals table
  const headCells = [

    {
      id: 'id',
      numeric: true,
      label: 'ID',
      width: 3
    },
    {
      id: 'username',
      numeric: false,
      label: 'Username',
      width: 10
    },
    {
      id: 'tool',
      numeric: false,
      label: 'Tool',
      width: 7
    },
    {
      id: 'project',
      numeric: false,
      label: 'Project',
      width: 7
    },
    {
      id: 'created_on',
      numeric: false,
      type: 'date',
      label: 'Created on',
      width: 13
    },
    {
      id: 'created_by',
      numeric: false,
      label: 'Created by',
      width: 10
    },
    {
      id: 'expired_on',
      numeric: false,
      type: 'date',
      label: 'Expired on',
      width: 13
    },
    {
      id: 'expired',
      numeric: false,
      label: 'Has Expired.?',
      width: 5
    }
  ]
  const [mainLoader, setMainLoader] = React.useState(false)
  const [selectedTool, setSelectedTool] = React.useState('')
  const [allProjects, setAllProjects] = React.useState([])
  const [projectUsers, setProjectUsers] = React.useState([])
  const [userSelected, setUserSelected] = React.useState(null)
  const [loadingoptionsUser, setLoadingoptionsUser] = React.useState(false)
  const [submitError, setSubmitError] = React.useState(false)
  const [userExists, setUserExists] = React.useState(false)
  const [projectkey, setProjectkey] = React.useState({
  })
  const [clearProjErr, setClearProjErr] = React.useState(false)
  const [jenkinsVersion, setJenkinsVersion] = React.useState('')
  const [artifactoryRepo, setArtifactoryRepo] = React.useState('')
  const [artifactoryRepositories, setArtifactoryRepositories] =
    React.useState([])
  const [artifactoryError, setArtifactoryError] = React.useState(false)
  const [artifactoryErrorMessage, setArtifactoryErrorMessage] = React
    .useState('')
  const [artifactorySuccess, setArtifactorySuccess] = React
    .useState(false)
  const [artifactoryRepoError, setArtifactoryRepoError] = React.useState(false)
  const [artifactoryRepoErrorMsg, setArtifactoryRepoErrorMsg] = React
    .useState('There is no repo available. Please select different project')
  const [jenkinsVersionError, setJenkinsVersionError] = React.useState(false)
  const [jenkinsVersionSuccess, setJenkinsVersionSuccess] = React
    .useState(false)
  const [hoursActive, setHoursActive] = React.useState('')
  const [tabValue, setTabValue] = React.useState(0)
  const [historyRecord, setHistoryRecord] = React.useState([])
  const [totalProjects, setTotalProjects] = React.useState([])
  const [jenkinsInstance, setJenkinsInstance] = React.useState('Dev')

  /* get projects AS Account owner */
  React.useEffect(() => {
    resetForm()
    setMainLoader(true)
    temporaryAccessService.getProjects().then((response) => {
      if (response.data) {
        setAllProjects(response.data)
        setTotalProjects(response.data)
      }
      temporaryAccessService
        .getTemporaryAccessHistory().then((response) => {
          setHistoryRecord(response.data)
          setMainLoader(false)
        })
    })
  }, [])

  const handleToolSelection = (event) => {
    resetMessages()
    setSelectedTool(event.target.value)
    setProjectkey({
    })
    setUserSelected(null)
    setProjectUsers([])
    setArtifactoryRepo('')
    setArtifactoryRepositories([])
    const proj = allProjects
    let filtervalue = []
    if (event.target.value === 'Jenkins') {
      filtervalue = totalProjects
        .filter((item) => item.toolname.toLowerCase() ===
        'jenkins')
      setAllProjects([...filtervalue])
    } else if (event.target.value === 'Bitbucket') {
      filtervalue = totalProjects
        .filter((item) => item.toolname.toLowerCase() ===
        'bitbucket')
      setAllProjects([...filtervalue])
    } else if (event.target.value === 'Artifactory') {
      filtervalue = totalProjects
        .filter((item) => item.toolname.toLowerCase() ===
        'artifactory')
      setAllProjects([...filtervalue])
    }

    if (!filtervalue.length) {
      setClearProjErr(true)
    } else {
      setClearProjErr(false)
    }
  }

  const handleInstanceChange = (value) => {
    resetMessages()
    setJenkinsInstance(value)
  }

  const handleRepoChange = (value) => {
    resetMessages()
    setArtifactoryRepo(value.target.value)
  }

  const submitRequestSave = () => {
    setJenkinsVersionError(false)
    setArtifactoryError(false)
    setArtifactorySuccess(false)
    const inputForm = {
      tools: selectedTool,
      users: userSelected,
      projectKey: projectkey,
      hoursActive,
      instance: jenkinsInstance,
      repo_key: artifactoryRepo
    }
    setMainLoader(true)
    temporaryAccessService.checkUserAccess(inputForm).then((response) => {
      setMainLoader(false)
      setUserExists(response.data)
      if (!response.data) {
        setMainLoader(true)
        temporaryAccessService.postTemporyAccess(inputForm).then((response) => {
          if (selectedTool === 'Artifactory') {
            setMainLoader(false)
            if (response.data.error &&
              response.data.error.toString().toLowerCase() === 'true') {
              setArtifactoryError(true)
              setArtifactoryErrorMessage(response.data.message)
            } else {
              setArtifactorySuccess(true)
              resetForm()
            }
          } else if (response.data.error &&
              response.data.error.toString().toLowerCase() === 'true') {
            setMainLoader(false)
            setJenkinsVersionError(true)
            setJenkinsVersion(response.data)
            resetForm()
          } else {
            temporaryAccessService
              .getTemporaryAccessHistory().then((response) => {
                setHistoryRecord(response.data)
                setMainLoader(false)
                setJenkinsVersionSuccess(true)
                resetForm()
              })
            setLoadingoptionsUser(false)
          }
        }, (error) => {
          if (selectedTool === 'Artifactory') {
            setMainLoader(false)
            if (error.response.data.error &&
              error.response.data.error.toString().toLowerCase() === 'true') {
              setArtifactoryError(true)
              setArtifactoryErrorMessage(error.response.data.message)
            } else {
              setArtifactorySuccess(true)
              resetForm()
            }
          } else {
            setMainLoader(false)
            setJenkinsVersionError(true)
            const err = error.response.data && error.response.data.message
              ? error.response.data.message
              : error.response.data
            setJenkinsVersion(err)
            resetForm()
          }
        })
      }
    })
  }

  /* function to get the users list based on the search key */
  // const getUsers = (value) => {
  //   setLoadingoptionsUser(true)
  //   // eslint-disable-next-line max-len
  //   temporaryAccessService.getProjectUsers(value.key).then((response) => {
  //     setProjectUsers(response.data)
  //     setLoadingoptionsUser(false)
  //   })
  // }

  /* Handle change user */
  const changeUser = (value) => {
    resetMessages()
    setUserSelected(value)
  }

  /* function to get the users list based on the search key */
  const getUsers = (value) => {
    setLoadingoptionsUser(true)
    requestAccess.getallusersforautocomplete(value).then((response) => {
      setProjectUsers(response.data)
      setLoadingoptionsUser(false)
    })
  }


  const setValuenull = (value) => {
    value.splice(0, 1)
    value = []
  }

  /* select project key */
  const changeKey = (value) => {
    resetMessages()
    setProjectkey(value)
    // getUsers(value)

    if (selectedTool === 'Artifactory') {
      setMainLoader(true)
      temporaryAccessService.getArtifactoryRepository(value.key)
        .then((response) => {
          setMainLoader(false)
          if (!response.data.body.length) {
            setArtifactoryRepoError(true)
            setArtifactoryRepositories([])
          } else {
            setArtifactoryRepoError(false)
            setArtifactoryRepositories(response.data.body)
          }
        }, (error) => {
          setMainLoader(false)
          setArtifactoryRepoError(true)
          setArtifactoryRepoErrorMsg(error.response.data.message)
        })
    }
  }

  /* change function for tab change */
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  async function fetchWithTimeout (resource, options = {

  }) {
    const {
      timeout = 300000
    } = options
    const controller = new AbortController()
    const id = setTimeout(() => controller.abort(), timeout)
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal
    })
    clearTimeout(id)
    return response
  }

  /* Create new temporary access - form submit */
  const saveForm = () => {
    if (!selectedTool || selectedTool === '' ||
    !userSelected || userSelected === '' ||
    !projectkey || projectkey === {
    } || (selectedTool === 'Artifactory' && (!artifactoryRepo ||
    artifactoryRepo === ''))) {
      setTimeout(() => {
        setSubmitError(true)
      }, 2000)
    } else if (selectedTool === 'Jenkins') {
      setJenkinsVersionError(false)
      setJenkinsVersionSuccess(false)
      setMainLoader(true)
      temporaryAccessService.versionCheckFromFile(projectkey.key,
        jenkinsInstance).then((response) => {
        setMainLoader(false)
        const tempMess = response.data.actualMessage
          ? `${response.data.message}\n
          ${response.data.actualMessage}`
          : response.data.message
        setJenkinsVersion(tempMess)
        if (response.data.status === false) {
          setJenkinsVersionError(true)
          // setSelectedTool('')
          setSubmitError(false)
          // setProjectkey({
          // })
          // setUserSelected('')
        } else {
          submitRequestSave()
        }
      }, (error) => {
        if (error) {
          const tempMess = error.response.data.actualMessage
            ? `${error.response.data.message}\n
            ${error.response.data.actualMessage}`
            : error.response.data.message
          setJenkinsVersion(tempMess)
          setMainLoader(false)
          setJenkinsVersionError(true)
          setSubmitError(false)
        }
      })
    } else {
      submitRequestSave()
    }
  }


  const resetAll = () => {
    setMainLoader(false)
    setSelectedTool('')
    setSubmitError(false)
    setUserExists(false)
    setProjectUsers([])
    setLoadingoptionsUser(false)
    setProjectkey({
    })
    setUserSelected('')
    setHoursActive('')
    setJenkinsVersion('')
    setJenkinsVersionError(false)
    setArtifactoryRepo('')
    setArtifactoryError(false)
    setArtifactorySuccess(false)
    setArtifactoryRepoError(false)
    setArtifactoryErrorMessage('')
    setClearProjErr(false)
    setJenkinsVersionSuccess(false)
  }

  const resetForm = () => {
    setClearProjErr(false)
    setMainLoader(false)
    setSelectedTool('')
    setProjectUsers([])
    setProjectkey({
    })
    setUserSelected('')
    setHoursActive('')
    setJenkinsVersion('')
    setArtifactoryRepo('')
  }

  const resetMessages = () => {
    setArtifactoryErrorMessage('')
    setSubmitError(false)
    setUserExists(false)
    setJenkinsVersionError(false)
    setArtifactoryRepoError(false)
    setArtifactoryError(false)
    setClearProjErr(false)
    setArtifactorySuccess(false)
    setJenkinsVersionSuccess(false)
  }

  return (
    <div>
      {mainLoader ? <LinearProgress /> : null}
      {submitError ? (
        <Alert
          severity='error'
          onClose={() => setSubmitError(false)}
        >
          Please enter inputs and submit
        </Alert>
      ) : null}
      {userExists ? (
        <Alert
          severity='error'
          onClose={() => setUserExists(false)}
        >
          The selected user has access already.
        </Alert>
      ) : null}
      {jenkinsVersionError ? (
        <Alert
          severity='error'
          onClose={() => setJenkinsVersionError(false)}
        >
          {jenkinsVersion}
        </Alert>
      ) : null}
      {artifactoryRepoError ? (
        <Alert
          severity='error'
          onClose={() => setArtifactoryRepoError(false)}
        >
          {artifactoryRepoErrorMsg}
        </Alert>
      ) : null}
      {artifactoryError ? (
        <Alert
          severity='error'
          onClose={() => setArtifactoryError(false)}
        >
          {artifactoryErrorMessage}
        </Alert>
      ) : null}
      {clearProjErr ? (
        <Alert
          severity='error'
          onClose={() => setClearProjErr(false)}
        >
          There is no project to select for
          {' '}
          {selectedTool}
        </Alert>
      ) : null}
      {artifactorySuccess ? (
        <Alert
          severity='success'
          onClose={() => setArtifactorySuccess(false)}
        >
          Request submitted successfully
        </Alert>
      ) : null}
      {jenkinsVersionSuccess ? (
        <Alert
          severity='success'
          onClose={() => setJenkinsVersionSuccess(false)}
        >
          Request submitted successfully
        </Alert>
      ) : null}
      <Paper square>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant='scrollable'
          scrollButtons='on'
          indicatorColor='primary'
          textColor='primary'
          aria-label='scrollable force tabs example'
        >
          <Tab
            key='formpage'
            label='Access Request'
            title='Access Request'
          />
          <Tab
            key='historypage'
            label='History'
            title='History'
          />
        </Tabs>
      </Paper>
      <TabPanel value={tabValue} index={0} key='tab-formpage'>
        <Typography
          htmlFor='needBitbucketLabel'
          className={classes.labelDisplay}
        >
          This form allows EAT Account/Project Owners to grant Temporary
          {' '}
          Access to Bitbucket, Jenkins or Artifactory for the
          {' '}
          troubleshooting and XENA DevOps Community assistance
          {' '}
          purposes. The Temporary access can be granted
          {' '}
          to a active Johnson and Johnson user for the period of 24
          {' '}
          hours and will be automatically revoked.
          {/* <span className={classes.error}> *</span> */}
        </Typography>
        {allProjects.length > 0 || totalProjects.length > 0 ? (
          <div>
            <FormControl className={classes.components}>
              <InputLabel>
                Tool
              </InputLabel>
              <Select
                label='Tool'
                name='toolname'
                required
                value={selectedTool}
                onChange={handleToolSelection}
              >
                {toolsArray.map((value, index) => (
                  <MenuItem value={value.name} key={value.name}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <FormControl className={classes.components}>
              <Autocomplete
                id='combo-box-demo'
                options={allProjects}
                disabled={selectedTool.length === 0}
                value={
                  projectkey && projectkey.key ? projectkey.key : ''
                }
                getOptionLabel={(option) => (option.key
                  ? `${option.key} - ${option.key_app_name}`
                  : option.name
                    ? option.name
                    : option)}
                // loading={allProjects.length === 0 && loadingbar}
                getOptionSelected={
                  (option, value) => option.name === value.name
                }
                onChange={(event, value) => changeKey(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Project or Team *'
                    name='projectkey'
                    value={projectkey.key}
                  />
                )}
              />
            </FormControl>
            <br />
            {selectedTool === 'Jenkins' ? (
              <div>
                <FormControl className={classes.components}>
                  <Typography
                    htmlFor='needAccountOwnerLabel'
                    className={classes.labelDisplay}
                  >
                    Jenkins instance
                  </Typography>
                  <RadioButtons
                    name='needAccountOwnerInProject'
                    className={classes.inputDisplay}
                    radio={['Dev', 'Release']}
                    selectedvalue={jenkinsInstance}
                    onChange={(value) => handleInstanceChange(value)}
                  />
                </FormControl>
                <br />
              </div>
            ) : null}
            {selectedTool === 'Artifactory' ? (
              <div>
                <FormControl
                  variant='standard'
                  className={classes.components}
                  id='standard-basic'
                >
                  {/* <TextField
                    variant='standard'
                    id='standard-basic'
                    label='Repo Name'
                    style={{
                      width: '100%'
                    }}
                    name='adminADGroup'
                    onChange={(e) => handleRepoChange(e)}
                    value={artifactoryRepo}
                  /> */}
                  <InputLabel id='repo-simple-select-label'>
                    Select Repo
                  </InputLabel>
                  <Select
                    labelId='repo-simple-select-label'
                    id='repo-simple-select'
                    value={artifactoryRepo}
                    onChange={handleRepoChange}
                  >
                    {artifactoryRepositories.length
                      ? artifactoryRepositories.map((value, index) => (
                        <MenuItem value={value.key} key={value.key}>
                          {value.key}
                        </MenuItem>
                      )) : null}
                  </Select>
                </FormControl>
                <br />
              </div>
            ) : null}
            {/* <FormControl className={classes.components}>
              <Autocomplete
                id='combo-box-demo'
                multiple
                options={projectUsers}
                getOptionLabel={(option) => (option.username
                  ? `${option.username} - ${option.displayname}`
                  : option)}
                disabled={
                  selectedTool.length === 0 ||
                  projectkey === ''
                  // accessType === ''
                }
                loading={projectUsers.length === 0 && loadingoptionsUser}
                filterOptions={(options, state) => options}
                getOptionSelected={
                  (option, value) => option.username === value.username
                }
                renderTags={
                  (value, getTagProps) => value
                    .map((option, index) => (userSelected.length ? (
                      <Chip
                        label={
                          option.username
                            ? `${option.username} - ${option.displayname}`
                            : option
                        }
                        {...getTagProps({
                          index
                        })}
                      />
                    ) : (
                      setValuenull(value)
                    )))
                }
                onChange={(event, value) => changeUser(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Username *'
                    name='userSelected'
                    value={userSelected}
                  />
                )}
              />
            </FormControl> */}
            <FormControl className={classes.components}>
              <Autocomplete
                // style={{
                //   width: '35%'
                // }}
                id='combo-box-demo'
                disabled={selectedTool.length === 0 &&
                  Object.keys(projectkey).length === 0}
                options={projectUsers}
                value={userSelected || ''}
                getOptionLabel={(option) => (option.name
                  ? `${option.name} - ${option.displayname}`
                  : option)}
                loading={
                  projectUsers.length === 0 && loadingoptionsUser
                }
                getOptionSelected={
                  (option, value) => option.name === value.name
                }
                // filterOptions={(options, state) => options}
                onChange={(event, value) => changeUser(value)}
                renderTags={
                  (value, getTagProps) => value
                    .map((option, index) => (userSelected.length ? (
                      <Chip
                        label={
                          option.name
                            ? `${option.name} - ${option.displayname}`
                            : option
                        }
                        {...getTagProps({
                          index
                        })}
                      />
                    ) : (
                      setValuenull(value)
                    )))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Choose a user *'
                    name='username'
                    onChange={
                      (event) => getUsers(event.target.value)
                    }
                    value={userSelected}
                    size='small'
                    // validators={['required']}
                    // errorMessages={['This field is required']}
                  />
                )}
              />
            </FormControl>
            {/* <br />
            <div>
              <p>
                The access is granted for 24 hours, and will be
                {' '}
                automatically revoked.
              </p>
            </div>
            <FormControl className={classes.components}>
              <TextField
                type='number'
                max={24}
                value={hoursActive}
                label='Valid hours'
                onChange={handleChangeHours}
                // eslint-disable-next-line max-len
                placeholder='Number of hours which accept less then or equals 24 hours only'
              />
            </FormControl> */}
            <br />
            <CardActions className={classes.footer}>
              <Button
                variant='contained'
                size='small'
                onClick={resetAll}
              >
                Reset
              </Button>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                size='small'
                disabled={!selectedTool &&
                  Object.keys(userSelected).length === 0 &&
                  Object.keys(projectkey).length}
                onClick={saveForm}
              >
                Submit
              </Button>
            </CardActions>
          </div>
        )
          : (allProjects.length === 0 && totalProjects.length === 0) &&
            !mainLoader ? (
              <div className={classes.nodatatext}>
                You do not have ACCOUNT OWNER (or) ACCOUNT APPROVER
                {' '}
                access to any EAT projects/teams.
              </div>
            ) : null}
      </TabPanel>
      <TabPanel value={tabValue} index={1} key='tab-historypage'>
        {historyRecord.length ? (
          <EnhancedTable
            key='history-table'
            orderby='action_date_sort'
            sortorder='desc'
            headCells={headCells}
            rowsData={historyRecord}
          />
        ) : null}
        {!mainLoader && !historyRecord.length ? (
          <div className={classes.nodatatext}>No data to display.</div>
        ) : null}
      </TabPanel>
    </div>
  )
}