import React, {
  useContext, useRef, useEffect
} from 'react'
import {
  makeStyles,
  LinearProgress,
  Typography,
  Grid,
  FormControl,
  Button,
  Card,
  CardContent
} from '@material-ui/core'
import {
  ValidatorForm, TextValidator
} from 'react-material-ui-form-validator'
import {
  Autocomplete
} from '@material-ui/lab'
import moment from 'moment-timezone'
import requestAccess from '../../services/api/requestaccessService'
import rtmReportService from '../../services/api/rtmRepotService'
import reportService from '../../services/api/reportServices'
import toolsService from '../../services/api/toolsService'
import UserContext from '../contexts/UserContext'

const timeZone = moment.tz.guess()

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  },
  cucumberwarning: {
    color: 'red',
    fontFamily: 'Tahoma'
  },
  inputDisplay: {
    width: '95%'
  },
  btnmargin: {
    float: 'right',
    right: 50
  },
  btnmargin2: {
    marginRight: '20px',
    float: 'right',
    right: 50
  }
}))

export default function RtmReportComponent () {
  const classes = useStyles()
  const form = useRef(null)
  const [formData, setFormData] = React.useState({
    projectkey: '',
    key: '',
    key_app_name: '',
    version: '',
    testexecutionid: '',
    testexecutionname: '',
    testcaseid: '',
    testcasename: '',
    testexecutionphrase: '',
    testcasephrase: '',
    testcasestatus: '',
    testexecutionstatus: '',
    testcasedescription: ''
  })
  const [allProjects, setAllprojects] = React.useState([])
  const [allVersions, setAllVersions] = React.useState([])
  const [allTestExecution, setAllTestExecution] = React.useState([])
  const [allTestCase, setAllTestCase] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [loadingbar, setLoadingBar] = React.useState(false)
  const [testType, setTestType] = React.useState(null)
  const {
    user
  } = useContext(UserContext)

  useEffect(() => {
    const getDataPromise = (value) => new Promise((resolve, reject) => {
      reportService.checkTestCaseType(value).then((response) => {
        setTestType(response.data[0].testtype.toLowerCase())
        if (response.data[0].testtype.toLowerCase() === 'generic') {
          resolve(false)
        } else {
          resolve(true)
        }
      })
    })
    ValidatorForm.addValidationRule('checkGenericTestcase', async (value) => {
      if (value) {
        const result = await getDataPromise(value)
        return result
      }
      return true
    })
  }, [])

  const resetForm = () => {
    setFormData({
      projectkey: '',
      key: '',
      key_app_name: '',
      version: '',
      testexecutionid: '',
      testexecutionname: '',
      testcaseid: '',
      testcasename: '',
      testexecutionphrase: '',
      testcasephrase: '',
      testcasestatus: '',
      testexecutionstatus: '',
      testcasedescription: ''
    })

    setAllprojects([])
    setAllVersions([])
    setAllTestExecution([])
    setAllTestCase([])
    setTestType(null)
  }

  const handleSubmit = () => {
    const input = {
      tsid: formData.testexecutionid,
      tcid: formData.testcaseid,
      version: formData.version,
      key: formData.key,
      projectname: formData.key_app_name,
      keyName: formData.projectkey,
      tcstatus: formData.testcasestatus,
      tsstatus: formData.testexecutionstatus,
      tcdescription: formData.testcasedescription,
      tcname: formData.testcasename,
      tsname: formData.testexecutionname
    }
    setLoading(true)
    reportService.exportTestExecutionReport(input,
      timeZone).then((response) => {
      setLoading(false)
      const blob = new Blob([response.data], {
        type: 'application/pdf'
      })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.className = 'hidden'
      link.download = `XRAY_TEST_EXECUTION_REPORT_${new Date()}.pdf`
      document.body.appendChild(link)
      link.click()
    })
  }

  /* function to get the projects list based on the search key */
  const getProjects = (value) => {
    if (value) {
      setLoadingBar(true)
      if (user.isAdmin) {
        requestAccess.getprojects('jira', value).then((response) => {
          setAllprojects(response.data)
          setLoadingBar(false)
        })
      } else {
        toolsService.mytools(user.name).then((response) => {
          const jsonarray = response.data

          const myprojectsresult = jsonarray
            .filter((item) => item.key.toLowerCase() === 'jira')[0].values

          setAllprojects(myprojectsresult)
          setLoadingBar(false)
        })
      }
    }
  }

  /** Function to change the project key */
  const changeProjectKey = (value) => {
    if (value) {
      setFormData({
        ...formData,
        projectkey: value.key
          ? `${value.key} - ${value.key_app_name}`
          : value.name,
        key: value.key
      })
      setLoading(true)
      rtmReportService.getVersionForJiraProject(value.key,
        null).then((versionresponse) => {
        const array = versionresponse.data
        array.splice(0, 0, {
          version_name: 'All Version',
          id: '0',
          description: 'All Version'
        })
        setLoading(false)
        setAllVersions(array)
      })
      setFormData({
        ...formData,
        version: 'All Version',
        projectkey: value.key
          ? `${value.key} - ${value.key_app_name}`
          : value.name,
        key: value.key,
        key_app_name: value.key_app_name,
        testexecutionid: '',
        testexecutionname: '',
        testcaseid: '',
        testcasename: '',
        testexecutionphrase: '',
        testcasephrase: '',
        testcasestatus: '',
        testexecutionstatus: '',
        testcasedescription: ''
      })
    }
  }

  /** Get the version for specific project */
  const getVersion = (value) => {
    if (value) {
      setLoading(true)
      rtmReportService.getVersionForJiraProject(formData.key,
        value).then((versionresponse) => {
        const array = versionresponse.data
        array.splice(0, 0, {
          version_name: 'All Version',
          id: '0',
          description: 'All Version'
        })
        setLoading(false)
        setAllVersions(array)
      })
    }
  }

  /** Function to change the version of the selected key */
  const changeVersion = (value) => {
    if (value) {
      setFormData({
        ...formData,
        version: value.version_name,
        testexecutionid: '',
        testexecutionname: '',
        testcaseid: '',
        testcasename: '',
        testexecutionphrase: '',
        testcasephrase: '',
        testcasestatus: '',
        testexecutionstatus: '',
        testcasedescription: ''
      })
    }
  }

  /** Function to get the al test execution based on the given project and its version */
  const getTestExecution = (value) => {
    if (value && value.length > 2) {
      setLoading(true)
      reportService.getTestExecution(formData.key, formData.version,
        value).then((testexecutionResponse) => {
        setAllTestExecution(testexecutionResponse.data)
        setLoading(false)
      })
    }
  }

  /** Function to change the version of the selected key */
  const changeTestExecution = (value) => {
    if (value) {
      setTestType(null)
      setFormData({
        ...formData,
        testexecutionid: value.testexecutionid,
        testexecutionname: value.testexecutionname,
        testexecutionphrase: `${value.testexecutionid} -
         ${value.testexecutionname}`,
        testexecutionstatus: value.testexecutionstatus,
        testcaseid: '',
        testcasename: '',
        testcasephrase: '',
        testcasestatus: '',
        testcasedescription: ''
      })

      setLoading(true)
      reportService.getTestsForExecution(value.testexecutionid)
        .then((testcaseResponse) => {
          setAllTestCase(testcaseResponse.data)
          setLoading(false)
        })
    }
  }

  const changeTestcase = (value) => {
    if (value) {
      setFormData({
        ...formData,
        testcaseid: value.testcaseid,
        testcasename: value.testcasename,
        testcasephrase: `${value.testcaseid} - ${value.testcasename}`,
        testcasestatus: value.testcasestatus,
        testcasedescription: value.testcasedescription

      })
    }
  }

  return (
    <div>
      {loading ? <LinearProgress /> : null}

      <ValidatorForm ref={form} onSubmit={() => handleSubmit()}>

        <Card variant='outlined'>
          <CardContent>
            <Typography variant='subtitle1' gutterBottom>
              Test Run Report
            </Typography>
            <Button
              className={classes.btnmargin}
              variant='contained'
              color='primary'
              type='submit'
              size='small'
              disabled={loading}
            >
              Generate Report
            </Button>
            {' '}
            <span> </span>
            <Button
              className={classes.btnmargin2}
              variant='contained'
              size='small'
              onClick={resetForm}
            >
              Reset
            </Button>
            <Grid container spacing={1} item>
              <Grid item xs={12} md={6}>
                {/* <Grid item xs={4} md={3}> */}
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    id='select-project'
                    options={allProjects}
                    value={formData.projectkey ? formData.projectkey : ''}
                    getOptionLabel={
                      (option) => (option.key
                        ? `${option.key} - ${option.key_app_name}`
                        : option)
                    }
                    loading={allProjects.length === 0 && loadingbar}
                    className={classes.inputDisplay}
                    getOptionSelected={(option,
                      value) => option.name === value.name}
                    onChange={(event, value) => changeProjectKey(value)}
                    renderInput={(params) => (
                      <TextValidator
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label='Select a Jira Project *'
                        name='projectkey'
                        onChange={(event) => getProjects(event.target.value)}
                        value={formData.projectkey}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      />
                    )}
                  />
                </FormControl>
                {/* </Grid>
          <Grid item xs={4} md={2}> */}
                {formData.key !== '' ? (
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id='select-project'
                      options={allVersions}
                      defaultValue={formData.version ? formData.version : ''}
                      value={formData.version ? formData.version : ''}
                      getOptionLabel={(option) => (
                        option.version_name ? option.version_name : option
                      )}
                      loading={allVersions.length === 0 && loadingbar}
                      className={classes.inputDisplay}
                      getOptionSelected={(option,
                        value) => option.name === value.name}
                      onChange={(event, value) => changeVersion(value)}
                      renderInput={(params) => (
                        <TextValidator
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          label='Change the version by typing its name *'
                          name='version'
                          onChange={(event) => getVersion(event.target.value)}
                          value={formData.version}
                          validators={['required']}
                          errorMessages={['This field is required']}
                        />
                      )}
                    />
                  </FormControl>
                ) : null}
                {/* </Grid>

          <Grid item xs={4} md={2}> */}
                {formData.key !== '' && formData.version !== '' ? (
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id='select-testexecution'
                      options={allTestExecution}
                      value={formData.testexecutionphrase
                        ? formData.testexecutionphrase
                        : ''}
                      getOptionLabel={
                        (option) => (option.testexecutionid
                          ? `${option.testexecutionid} -
                           ${option.testexecutionname}`
                          : option)
                      }
                      loading={allTestExecution.length === 0 && loadingbar}
                      className={classes.inputDisplay}
                      getOptionSelected={(option,
                        value) => option.name === value.testexecutionid}
                      onChange={(event, value) => changeTestExecution(value)}
                      renderInput={(params) => (
                        <TextValidator
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          label='Select Test Execution *'
                          name='testexecutionid'
                          onChange={
                            (event) => getTestExecution(event.target.value)
                          }
                          value={formData.testexecutionphrase}
                          validators={['required']}
                          errorMessages={['This field is required']}
                        />
                      )}
                    />
                  </FormControl>
                ) : null}
                {/* </Grid>

          <Grid item xs={4} md={2}> */}
                {formData.key !== '' &&
                  formData.version !== '' &&
                  formData.testexecutionid !== '' ? (
                    <FormControl className={classes.formControl}>
                      <Autocomplete
                        id='select-test'
                        options={allTestCase}
                        value={formData.testcasephrase
                          ? formData.testcasephrase
                          : ''}
                        getOptionLabel={
                          (option) => (option.testcaseid
                            ? `${option.testcaseid} - ${option.testcasename}`
                            : option)
                        }
                        loading={allTestCase.length === 0 && loadingbar}
                        className={classes.inputDisplay}
                        getOptionSelected={(option,
                          value) => option.name === value.testcaseid}
                        onChange={(event, value) => changeTestcase(value)}
                        renderInput={(params) => (
                          <TextValidator
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label='Select Test *'
                            name='testcaseid'
                            value={formData.testcaseid}
                            validators={['required', 'checkGenericTestcase']}
                            errorMessages={
                              [
                                'This field is required',
                                `Test run report supports only Manual and
                                Cucumber tests. You have selected
                                 a Generic test`
                              ]
                            }
                          />
                        )}
                      />
                    </FormControl>
                  ) : null}
                {formData.key !== '' &&
                  formData.version !== '' &&
                  formData.testexecutionid !== '' &&
                  testType === 'cucumber'
                  ? (
                    <p className={classes.cucumberwarning}>
                      You have selected a Test which is a Cucumber Test type.
                       When you Generate a report, the report contains only
                        the suite level evidences and not the scenario level
                         evidences
                    </p>
                  ) : null}
                {/* </Grid> */}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </ValidatorForm>
    </div>
  )
}
