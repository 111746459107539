import React, {
  useContext
} from 'react'
import {
  Typography,
  makeStyles,
  LinearProgress
} from '@material-ui/core'
import UserContext from '../contexts/UserContext'
import TeamsTable from '../sections/mordernTable'
import TeamNameService from '../../services/api/teamNameService'
import AlertDialog from '../alertbox/alertboxComponent'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  nodatatext: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(12),
    textAlign: 'center'
  }
}))
const headCells = [
  {
    id: 'key',
    label: 'Key',
    width: '20%'
  },
  {
    id: 'name',
    label: 'Name',
    type: 'input',
    width: '60%'
  },
  {
    id: 'action',
    type: 'edit',
    label: 'Action',
    width: '20%'
  }
]
export default function TeamNamesComponent () {
  const [loading, setLoading] = React.useState(false)
  const {
    user
  } = useContext(UserContext)
  const [teamnames, setTeamNames] = React.useState([])
  const [alertopen, setAlertopen] = React.useState(false)
  const [inputvalue, setInputValue] = React.useState(null)
  const [error, setError] = React.useState(false)
  const classes = useStyles()

  /* initial call when page gets loaded */
  React.useEffect(() => {
    setLoading(true)
    TeamNameService.getTeamNames(user.name).then((response) => {
      setTeamNames(response.data)
      setLoading(false)
    })
  }, [])

  const handleSave = (value, rows) => {
    const url = value.id ? 'saveTeamname' : 'createNewTeamKey'
    const input = value
    input.name = inputvalue || input.name
    if (input.name) {
      setError(false)
      TeamNameService[url](input).then((response) => {
        const data = rows
        data.map((item, index) => {
          if (item.key === value.key) {
            data[index].edit = false
            data[index].name = inputvalue || input.name
          }
        })
        setTeamNames([...data])
        setLoading(false)
      })
    } else {
      setError(true)
    }
  }

  const ConfirmSave = () => {
    setAlertopen(false)
    const [value] = teamnames.filter((item) => item.edit)
    handleSave(value, teamnames)
  }
  const setInputField = (e) => {
    if (e.target.value.length) {
      setError(false)
    } else {
      setError(true)
    }
    setInputValue(e.target.value)
  }
  const handleEdit = (value, rows) => {
    const data = rows
    setInputValue(null)
    const checkEdit = data.filter((item) => item.edit)
    if (checkEdit.length) {
      setAlertopen(true)
      setInputValue(inputvalue)
    } else {
      data.map((item, index) => {
        if (item.key === value.key) {
          data[index].edit = true
        }
      })
      setTeamNames([...data])
    }
  }
  const handleCancel = (value, rows) => {
    setError(false)
    const data = rows
    data.map((item, index) => {
      if (item.key === value.key) {
        data[index].edit = false
      }
    })
    setTeamNames([...data])
  }
  // function to close the alert modal box
  const alertClose = (event) => {
    setAlertopen(false)
    const [value] = teamnames.filter((item) => item.edit)
    handleCancel(value, teamnames)
  }

  return (
    <div className={classes.root}>
      {loading ? <LinearProgress /> : null}
      <Typography variant='subtitle2' gutterBottom>
        Team Names
      </Typography>
      <Typography variant='caption' color='textSecondary' gutterBottom>
        You can view the Jenkins/Artifactory projects in
        which you are the member of account owner group.
      </Typography>
      {!loading && !teamnames.length ? (
        <div className={classes.nodatatext}>
          You are not in a account owner role for any of
           the Jenkins/Artifactory project
        </div>
      ) : null}
      {teamnames.length && !loading ? (
        <>
          <TeamsTable
            key='teamnames-table'
            headCells={headCells}
            rowsData={teamnames}
            orderby=''
            handleSave={handleSave}
            handleEdit={handleEdit}
            handleCancel={handleCancel}
            setInputField={setInputField}
            error={error}
          />
        </>
      ) : null}
      <AlertDialog
        handleClose={alertClose}
        alertopen={alertopen}
        key='alert-teams'
        message='You already have unsaved record. Do you want to save ?'
        confirmbutton={ConfirmSave}
        okbuttonalert={false}
        title='Alert'
      />
    </div>
  )
}
