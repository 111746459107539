/* It contains all the API functions which is releated to My requests section */
import axiosInstance from '../../middleware/Interceptor'
// to get pending requests and request history details for given user
function myAccessRequest (username) {
  return axiosInstance.get(`/api/v1/myrequests/myaccessrequest?username=
${username}`)
}
// to get approver details for requested group
function getAccountApproverDetails (group) {
  return axiosInstance.get(`/api/v1/myrequests/getAccountApproverDetails?group=
${group}`)
}
// to delete pending request
function deleteMyRequest (id) {
  return axiosInstance.get(`/api/v1/myrequests/deleteMyRequest?id=${id}`)
}
// to get the hierarchy for given username
function getUserHierarchy (username) {
  return axiosInstance.get(`/api/v1/user/getUserHierarchy/${username}`)
}
// to get owner and approver details for requested group
function getOwnerAndApproversForGroup (group) {
  return axiosInstance.get(`/api/v1/mytools/getOwnerAndApproversForGroup/
${group}`)
}
// to get the display name for given username
function getDisplayname (username) {
  return axiosInstance.get(`/api/v1/getDisplayname?username=${username}`)
}

const MyRequestService = {
  myAccessRequest,
  getAccountApproverDetails,
  deleteMyRequest,
  getUserHierarchy,
  getOwnerAndApproversForGroup,
  getDisplayname
}

export default MyRequestService
