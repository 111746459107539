import React, {
  useContext, useRef
} from 'react'
import {
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  makeStyles,
  FormControl,
  LinearProgress,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import EnhancedTable from '../sections/enhancedTable'
import OwnershipServices from '../../services/api/ownershipServices'
import ChangeApproverServices from '../../services/api/changeApproverService'

const useStyles = makeStyles((theme) => ({
  inputDisplay: {
    width: '100%'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  }
}))

// Header for the table
const headCells = [
  {
    id: 'group_name',
    numeric: false,
    label: 'Group',
    width: '40%'
  },
  {
    id: 'approver_group',
    numeric: false,
    label: 'Approver Group',
    width: '40%'
  }
]

export default function ChangeApproverComponent () {
  const [loading, setLoading] = React.useState(false)
  const [alertLoading, setAlertLoading] = React.useState(false)
  const [successAlert, setsuccessAlert] = React.useState(false)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [failalertMessage, setFailalertMessage] = React.useState(null)
  const [successalertMessage, setSuccessalertMessage] = React.useState(null)
  const [allProjects, setAllProjects] = React.useState([])
  const [projectApproverGroups, setProjectApproverGroups] = React.useState([])
  const [approverGroups, setApproverGroups] = React.useState([])
  const [loadingOptions, setLoadingOptions] = React.useState(false)
  const classes = useStyles()
  const form = useRef(null)
  const [alertOpen, setAlertOpen] = React.useState(false)

  const [formData, setFormData] = React.useState({
    projectKey: '',
    approverProjectKey: '',
    approverGroups: '',
    selectedGroups: []
  })

  // Get all projects irrespective of tools
  const getProjects = (value) => {
    setLoadingOptions(true)
    OwnershipServices.getAllProjects(value).then((response) => {
      setAllProjects(response.data)
      setLoadingOptions(false)
    })
  }

  // Handles when project value changes
  const changeProject = (value) => {
    if (value) {
      setProjectApproverGroups([])
      setLoading(true)
      ChangeApproverServices.getGroupsWithApproverGroup(value.key)
        .then((response) => {
          setLoading(false)
          setProjectApproverGroups(response.data)
          setFormData({
            ...formData,
            projectKey: value.key
          })
          setAllProjects([])
        })
    }
  }

  // Handles when approver project value changes
  const changeApproverProject = (value) => {
    if (value) {
      setAlertLoading(true)
      ChangeApproverServices.getGroupsWithApproverGroup(value.key)
        .then((response) => {
          setAlertLoading(false)
          setApproverGroups(response.data)
          setFormData({
            ...formData,
            approverProjectKey: value.key
          })
          setAllProjects([])
        })
    }
  }

  // Handles when change button got triggered in the table
  const handleChangeApproverClick = (type, values) => {
    if (type === 'reset') {
      setProjectApproverGroups([])
      setAllProjects([])
      setFormData({
        ...formData,
        projectKey: '',
        approverProjectKey: ''
      })
    } else if (type === 'change') {
      setFormData({
        ...formData,
        selectedGroups: values
      })
      setAlertOpen(true)
    }
  }

  // Handle Alert box close
  const handleClose = () => {
    setAlertOpen(false)
    setFormData({
      ...formData,
      approverProjectKey: '',
      approverGroups: ''
    })
    setApproverGroups([])
  }

  // Handles when value change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  // Changing approver for the selected groups
  const changeApprover = (event) => {
    let input = {
    }
    input = {
      id: formData.selectedGroups,
      approver_id: formData.approverGroups.id
    }
    setAlertLoading(true)
    ChangeApproverServices.updateApproverGroup(input).then((response) => {
      setAlertLoading(false)
      if (response.status === 200) {
        setAlertOpen(false)
        setLoading(true)
        setProjectApproverGroups([])
        ChangeApproverServices.getGroupsWithApproverGroup(formData.projectKey)
          .then((updatedGroupResponse) => {
            setLoading(false)
            setProjectApproverGroups(updatedGroupResponse.data)
            setFormData({
              ...formData,
              approverProjectKey: '',
              approverGroups: ''
            })
            setSuccessalertMessage('Approver Group Changed Successfully')
            setsuccessAlert(true)
            setTimeout(() => {
              setsuccessAlert(false)
            }, 20000)
          })
      } else {
        setLoading(false)
        setFailalertMessage(`
          Something went wrong. Contact system administrator!
        `)
        seterrorAlert(true)
      }
    }, (error) => {
      setAlertLoading(false)
      if (error) {
        setAlertOpen(false)
        setFailalertMessage(`
          Something went wrong. Contact system administrator!
        `)
        seterrorAlert(true)
      }
    })
  }

  // Handles Submit functions
  const handleSubmit = (event) => {
    event.preventDefault()
  }

  return (
    <div>
      {loading ? <LinearProgress /> : null}
      {' '}
      <div className={classes.alertroot}>
        {' '}
        {successAlert ? (
          <Alert severity='success'>{successalertMessage}</Alert>
        ) : null}
        {errorAlert ? (
          <Alert
            severity='error'
            onClose={() => seterrorAlert(false)}
          >
            {failalertMessage}
          </Alert>
        ) : null}
      </div>
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={12} md={5}>
          <Card variant='outlined'>
            <ValidatorForm ref={form} onSubmit={(event) => handleSubmit(event)}>
              <CardContent>
                <Typography variant='subtitle1' gutterBottom>
                  Change Approver Group
                </Typography>
                <div style={{
                  paddingTop: 10
                }}
                >
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id='combo-box-key'
                      options={allProjects}
                      value={formData.projectKey}
                      getOptionLabel={
                        (option) => (option.key ? option.key : option)
                      }
                      getOptionSelected={
                        (option, value) => option.name === value.name
                      }
                      onChange={(event, value) => changeProject(value)}
                      loading={allProjects.length === 0 && loadingOptions}
                      renderInput={(params) => (
                        <TextValidator
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          label='Project or Team'
                          name='project-key'
                          value={formData.projectKey}
                          onChange={(event) => getProjects(event.target.value)}
                          validators={['required']}
                          errorMessages={['Please select any project']}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </CardContent>
            </ValidatorForm>
          </Card>
        </Grid>
        {projectApproverGroups.length ? (
          <Grid item xs={12} md={7}>
            <EnhancedTable
              rowsData={projectApproverGroups}
              headCells={headCells}
              checkbox
              showButton='Change Approver'
              handleChangeApproverClick={handleChangeApproverClick}
              rowsperpage={25}
            />
          </Grid>
        ) : null}
      </Grid>
      {/* Dialog Box for Change Approver */}
      <Dialog
        open={alertOpen}
        onClose={handleClose}
        maxWidth='xs'
        fullWidth='xs'
      >
        {alertLoading ? <LinearProgress /> : null}
        <ValidatorForm
          ref={form}
          onSubmit={(event) => changeApprover(event)}
        >
          <DialogTitle id='alert-dialog-title'>
            Change Approver Group
          </DialogTitle>
          <DialogContent>
            <div style={{
              paddingTop: 10
            }}
            >
              <FormControl className={classes.formControl}>
                <Autocomplete
                  id='combo-box-key'
                  options={allProjects}
                  value={formData.approverProjectKey}
                  getOptionLabel={
                    (option) => (option.key ? option.key : option)
                  }
                  getOptionSelected={
                    (option, value) => option.name === value.name
                  }
                  onChange={
                    (event, value) => changeApproverProject(value)
                  }
                  loading={allProjects.length === 0 && loadingOptions}
                  renderInput={(params) => (
                    <TextValidator
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      label='Project or Team'
                      name='approver-project-key'
                      value={formData.approverProjectKey}
                      onChange={(event) => getProjects(event.target.value)}
                      validators={['required']}
                      errorMessages={['Please select any project']}
                    />
                  )}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <SelectValidator
                  className={classes.inputDisplay}
                  name='approverGroups'
                  label='Groups'
                  value={formData.approverGroups}
                  onChange={handleChange}
                  validators={['required']}
                  errorMessages={['This field is required']}
                >
                  {approverGroups.map((value, index) => (
                    <MenuItem value={value} key={value.group_name}>
                      {value.group_name}
                    </MenuItem>
                  ))}
                </SelectValidator>
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='contained' color='primary' type='submit'>
              Change
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </div>
  )
}
