import React from 'react'
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
  Input,
  FormControl,
  InputLabel,
  Typography
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
// import RichTextEditor from '../../common/RichTextEditor/index'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20
  },
  textfield: {
    width: '80%'
  },
  bold: {
    fontWeight: 600,
    marginTop: 20
  }
}))

export default function JenkinsAdminComponent ({
  configdata, handleChange
}) {
  const classes = useStyles()
  const [showPassword, setShowPassword] = React.useState(false)
  const [taShowPassword, setTaShowPassword] = React.useState(false)

  const handlePassword = (type, e) => {
    handleChange(type, e)
  }

  const taHandlePassword = (type, e, where) => {
    handleChange(type, e, where)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleChangeHours = (event) => {
    const d = '24'
    if (parseInt(event.target.value) > 25) {
      event.target.value = d
    }
    handleChange('jenkins', event, 'temporary_accesss')
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label='System admin'
            name='admin_username'
            onChange={(e) => handleChange('jenkins', e)}
            value={configdata.jenkins.admin_username}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.textfield}>
            <InputLabel htmlFor='standard-adornment-password'>
              Password
            </InputLabel>
            <Input
              label='Password'
              onChange={(e) => handlePassword('jenkins', e)}
              name='password'
              value={configdata.jenkins.password}
              className={classes.textfield}
              type={showPassword ? 'text' : 'password'}
              endAdornment={(
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography
            variant='subtitle1'
            gutterBottom
            className={classes.bold}
          >
            Temporary access settings
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Access URL'
            name='access_url'
            rows={3}
            onChange={(e) => handleChange('jenkins', e, 'temporary_accesss')}
            value={configdata.jenkins.temporary_accesss.access_url}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='version check URL'
            name='version_check_url'
            onChange={(e) => handleChange('jenkins', e, 'temporary_accesss')}
            value={configdata.jenkins.temporary_accesss.version_check_url}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Crumb API'
            name='crumb_api'
            onChange={(e) => handleChange('jenkins', e, 'temporary_accesss')}
            value={configdata.jenkins.temporary_accesss.crumb_api}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label='API Token'
            name='api_token'
            onChange={(e) => handleChange('jenkins', e, 'temporary_accesss')}
            value={configdata.jenkins.temporary_accesss.api_token}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label='Role To Access'
            name='role_to_access'
            onChange={(e) => handleChange('jenkins', e, 'temporary_accesss')}
            value={configdata.jenkins.temporary_accesss.role_to_access}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label='Build project'
            name='build_project'
            onChange={(e) => handleChange('jenkins', e, 'temporary_accesss')}
            value={configdata.jenkins.temporary_accesss.build_project}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label='Service Accunt'
            name='service_account'
            onChange={(e) => handleChange('jenkins', e, 'temporary_accesss')}
            value={configdata.jenkins.temporary_accesss.service_account}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.textfield}>
            <InputLabel htmlFor='standard-adornment-password'>
              Password
            </InputLabel>
            <Input
              label='Password'
              onChange={(e) => taHandlePassword('jenkins', e,
                'temporary_accesss')}
              name='sa_password'
              value={configdata.jenkins.temporary_accesss.sa_password}
              className={classes.textfield}
              type={taShowPassword ? 'text' : 'password'}
              endAdornment={(
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setTaShowPassword(!taShowPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {taShowPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type='number'
            name='hours'
            // max={24}
            value={configdata.jenkins.temporary_accesss.hours}
            label='Valid hours'
            onChange={(e) => handleChangeHours(e)}
            // eslint-disable-next-line max-len
            placeholder='Number of hours which accept less then or equals 24 hours only'
          />
        </Grid>
        {/* <Grid item xs={12} md={12}>
          <Typography
            variant='subtitle1'
            gutterBottom
            className={classes.bold}
          >
            Jenkins Dashboard Banner
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <RichTextEditor
            editorValue={configdata.jenkins.dashboard}
            handleEditorChange={(e) => handleChange('jenkins', e,
              'dashboard', true)}
            name='comments'
            showTablesModule='false'
            showTextModule='true'
          />
        </Grid> */}
      </Grid>
    </div>
  )
}
