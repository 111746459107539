import React from 'react'
import './App.css'
import {
  withRouter
} from 'react-router-dom'
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider
} from '@material-ui/core/styles'
import NavbarComponent from './components/navigation/navigationComponent'
import SignInSide from './components/login/loginComponent'
import {
  UserProvider
} from './components/contexts/UserContext'
import {
  PathProvider
} from './components/contexts/PathContext'
import {
  SupportProvider
} from './components/contexts/SupportContext'
import ErrorPageComponent from './components/errorpage/errorPageComponent'

let theme = createMuiTheme({
  typography: {
    fontSize: 12
  }
})
theme = responsiveFontSizes(theme)
class App extends React.Component {
  render () {
    const user = {
      name: '',
      loggedIn: false,
      token: ''
    }
    const path = {
      pathname: '',
      component: ''
    }
    const support = {
      success: false,
      message: ''
    }
    const {
      pathname
    } = this.props.location

    if (pathname === '/login' || pathname === '/') {
      return (
        <ThemeProvider theme={theme}>
          <UserProvider value={user}>
            <SignInSide />
          </UserProvider>
        </ThemeProvider>
      )
    } if (pathname === '/errorpage' || pathname === '/timeout') {
      return <ErrorPageComponent data={pathname} />
    }
    return (
      <ThemeProvider theme={theme}>
        <UserProvider value={user}>
          <PathProvider value={path}>
            <SupportProvider value={support}>
              <NavbarComponent />
            </SupportProvider>
          </PathProvider>
        </UserProvider>
      </ThemeProvider>
    )
  }
}

export default withRouter(App)
