import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import {
  BrowserRouter, Route, Redirect, Switch
} from 'react-router-dom'
import App from './App'

const routes = (
  <BrowserRouter>
    <Switch>
      <Route path='/' component={App} />
      <Redirect from='/' to='/' />
    </Switch>
  </BrowserRouter>
)

ReactDOM.render(routes, document.getElementById('root'))
