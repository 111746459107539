
/* It contains all the API functions which is releated to RTM report section */

import axios from 'axios'
import axiosInstance from '../../middleware/Interceptor'

const {
  CancelToken
} = axios
let cancel
/* To get issue types of given project */
function getIssueType (project) {
  return axiosInstance.get(`/api/v1/iris/getIssueType?projectKey=
${project}&type=rtm`)
}
/* To get versions of jira project */
function getVersionForJiraProject (project, value) {
  if (cancel !== undefined) {
    cancel()
  }
  return axiosInstance.get(`/api/v1/reports/getVersionForJiraProject
?projectkey=${project}&searchstring=${value}`,
  {
    cancelToken: new CancelToken((c) => {
      cancel = c
    })
  })
}
/* To get all Epic Issues available of given project */
function getEpicForProject (project) {
  return axiosInstance.get(`/api/v1/reports/getEpicForProject?projectkey=
${project}`)
}

function getRTMData (input) {
  return axiosInstance.post('/api/v1/reports/getRTMData', input)
}

function exportRTM (input, timezone) {
  axiosInstance.defaults.headers.get.Accept = 'application/pdf'
  return axiosInstance.post(`/api/v1/reports/exportRTMData?timezone=
${timezone}`, input, {
    responseType: 'arraybuffer'
  })
}

function exportRTMExcel (input, timezone) {
  axiosInstance.defaults.headers.get.Accept = `application/vnd
    .openxmlformats-officedocument.spreadsheetml.sheet`
  return axiosInstance.post(`/api/v1/reports/exportRTMExcel?timezone=
${timezone}`, input, {
    responseType: 'arraybuffer'
  })
}

const RtmReportService = {
  getIssueType,
  getVersionForJiraProject,
  getEpicForProject,
  getRTMData,
  exportRTM,
  exportRTMExcel
}

export default RtmReportService
