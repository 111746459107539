import React from 'react'
import {
  Typography,
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
  Input,
  FormControl,
  InputLabel
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20
  },
  textfield: {
    width: '80%'
  },
  bold: {
    fontWeight: 600,
    marginTop: 20
  }
}))

export default function ToolConfigComponent ({
  page, configdata, handleChange, groupChange, updateCursiveWords
}) {
  const classes = useStyles()
  const [showadpassword, setShowAdPassword] = React.useState(false)
  const [showidmspassword, setShowIdmsPassword] = React.useState(false)
  const [showcrowdpassword, setShowCrowdPassword] = React.useState(false)
  const [showjirapassword, setShowJiraPassword] = React.useState(false)
  const [showbbpassword, setShowBBPassword] = React.useState(false)

  const handlePassword = (type, e) => {
    handleChange(type, e)
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const getDetailContent = () => {
    switch (page) {
    case 'Crowd':
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label='Host'
                onChange={(e) => handleChange('Crowd', e)}
                name='hostName'
                value={configdata.Crowd.hostName}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='App Link Name'
                onChange={(e) => handleChange('Crowd', e)}
                name='appName'
                value={configdata.Crowd.appName}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.textfield}>
                <InputLabel htmlFor='standard-adornment-password'>
                  Password
                </InputLabel>
                <Input
                  label='Password'
                  onChange={(e) => handlePassword('Crowd', e)}
                  name='password'
                  value={configdata.Crowd.password}
                  className={classes.textfield}
                  type={showcrowdpassword ? 'text' : 'password'}
                  endAdornment={(
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={
                          () => setShowCrowdPassword(!showcrowdpassword)
                        }
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showcrowdpassword
                          ? <Visibility />
                          : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>
      )
    case 'Active Directory':
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label='URL'
                onChange={(e) => handleChange('idms', e)}
                name='url'
                value={configdata.idms.url}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Username'
                onChange={(e) => handleChange('idms', e)}
                name='userName'
                value={configdata.idms.userName}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.textfield}>
                <InputLabel htmlFor='standard-adornment-password'>
                  Password
                </InputLabel>
                <Input
                  label='Password'
                  onChange={(e) => handlePassword('idms', e)}
                  name='password'
                  value={configdata.idms.password}
                  className={classes.textfield}
                  type={showadpassword ? 'text' : 'password'}
                  endAdornment={(
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowAdPassword(!showadpassword)}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showadpassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>
      )
    case 'Jira':
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                label='URL'
                onChange={(e) => handleChange('jira', e)}
                name='uri'
                value={configdata.jira.uri}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label='Username'
                onChange={(e) => handleChange('jira', e)}
                name='userName'
                value={configdata.jira.userName}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl className={classes.textfield}>
                <InputLabel htmlFor='standard-adornment-password'>
                  Password
                </InputLabel>
                <Input
                  label='Password'
                  onChange={(e) => handlePassword('jira', e)}
                  name='password'
                  value={configdata.jira.password}
                  className={classes.textfield}
                  type={showjirapassword ? 'text' : 'password'}
                  endAdornment={(
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={
                          () => setShowJiraPassword(!showjirapassword)
                        }
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showjirapassword
                          ? <Visibility />
                          : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label={`Xray Test 
                execution API Field Check values 
                - (Please enter valid JSON Object)`}
                onChange={(e) => handleChange('jira', e)}
                name='xrayTestExecutionApiFieldCheck'
                value={configdata.jira.xrayTestExecutionApiFieldCheck}
                className={classes.textfield}
                multiline
              />
            </Grid>
          </Grid>
        </div>
      )
    case 'Confluence':
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TextField
                label='URL'
                onChange={(e) => handleChange('confluence', e)}
                name='uri'
                value={configdata.confluence.uri}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label='Host'
                onChange={(e) => handleChange('confluence', e)}
                name='host'
                value={configdata.confluence.host}
                className={classes.textfield}
              />
            </Grid>
          </Grid>
        </div>
      )
    case 'Bitbucket':
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label='URL'
                onChange={(e) => handleChange('bitbucket', e)}
                name='uri'
                value={configdata.bitbucket.uri}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Username'
                onChange={(e) => handleChange('bitbucket', e)}
                name='userName'
                value={configdata.bitbucket.userName}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.textfield}>
                <InputLabel htmlFor='standard-adornment-password'>
                  Password
                </InputLabel>
                <Input
                  label='Password'
                  onChange={(e) => handlePassword('bitbucket', e)}
                  name='password'
                  value={configdata.bitbucket.password}
                  className={classes.textfield}
                  type={showbbpassword ? 'text' : 'password'}
                  endAdornment={(
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowBBPassword(!showbbpassword)}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showbbpassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </FormControl>
            </Grid>
            <br />
            <Grid item xs={12} md={12}>
              <Typography
                variant='subtitle1'
                gutterBottom
                className={classes.bold}
              >
                Temporary access settings
              </Typography>
              <TextField
                label='Temporary access hours'
                style={{
                  width: '15%'
                }}
                onChange={(e) => handleChange('bitbucket', e)}
                name='temporary_access_hours'
                value={configdata.bitbucket.temporary_access_hours}
                className={classes.textfield}
              />
              <br />
              <TextField
                label='Temporary access url'
                onChange={(e) => handleChange('bitbucket', e)}
                name='temporary_access_url'
                value={configdata.bitbucket.temporary_access_url}
                className={classes.textfield}
              />
              <br />
              <TextField
                label='Temporary revoke access url'
                onChange={(e) => handleChange('bitbucket', e)}
                name='temporary_revokeaccess_url'
                value={configdata.bitbucket.temporary_revokeaccess_url}
                className={classes.textfield}
              />
            </Grid>
          </Grid>
        </div>
      )
    case 'Sonar':
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label='URL'
                onChange={(e) => handleChange('sonar', e)}
                name='uri'
                value={configdata.sonar.uri}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Api Key'
                onChange={(e) => handleChange('sonar', e)}
                name='apiKey'
                value={configdata.sonar.apiKey}
                className={classes.textfield}
              />
            </Grid>
          </Grid>
        </div>
      )
    case 'IDMS Group Creation':
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TextField
                label='IDMS Group Creation URI'
                onChange={(e) => handleChange('idmsGroups', e)}
                name='uri'
                value={configdata.idmsGroups.uri}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant='subtitle1'
                gutterBottom
                className={classes.bold}
              >
                IDMS Groups Token Validation
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label='URI'
                onChange={
                  (e) => handleChange('idmsGroups', e, 'token_validation')
                }
                name='uri'
                value={configdata.idmsGroups.token_validation.uri}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Request Body'
                name='request_body'
                multiline
                rows='6'
                onChange={
                  (e) => handleChange('idmsGroups', e, 'token_validation')
                }
                value={configdata.idmsGroups.token_validation.request_body}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant='subtitle1'
                gutterBottom
                className={classes.bold}
              >
                Auto Build Credentials
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Username'
                onChange={(e) => handleChange('idmsGroups', e)}
                name='autobuild_user'
                value={configdata.idmsGroups.autobuild_user}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.textfield}>
                <InputLabel htmlFor='standard-adornment-password'>
                  Password
                </InputLabel>
                <Input
                  label='Password'
                  onChange={(e) => handlePassword('idmsGroups', e)}
                  name='autobuild_pw'
                  value={configdata.idmsGroups.autobuild_pw}
                  className={classes.textfield}
                  type={showidmspassword ? 'text' : 'password'}
                  endAdornment={(
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={
                          () => setShowIdmsPassword(!showidmspassword)
                        }
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showidmspassword
                          ? <Visibility />
                          : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant='subtitle1'
                gutterBottom
                className={classes.bold}
              >
                Curse or Offensive Words
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                multiline
                rows='3'
                onChange={(e) => handleChange('idmsGroups', e)}
                name='curseOrOffensiveWords'
                value={configdata.idmsGroups.curseOrOffensiveWords}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant='subtitle1'
                gutterBottom
                className={classes.bold}
              >
                Groups Allowed to Create
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                multiline
                rows='2'
                onChange={(e) => handleChange('idms', e)}
                name='groupsAllowedToCreate'
                value={configdata.idms.groupsAllowedToCreate.toString()}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant='subtitle1'
                gutterBottom
                className={classes.bold}
              >
                Groups Description
              </Typography>
            </Grid>
          </Grid>
          {configdata.idmsGroups.group_description.map((item, i) => (
            <Grid container spacing={3} key={`groupname${i}`}>
              <Grid item xs={6} md={4}>
                <TextField
                  label='Role Name'
                  onChange={
                    (e) => groupChange(i, e, 'idmsGroups', 'group_description')
                  }
                  name='role_name'
                  value={item.role_name}
                  className={classes.textfield}
                />

              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  label='Group Description'
                  onChange={
                    (e) => groupChange(i, e, 'idmsGroups', 'group_description')
                  }
                  name='description'
                  value={item.description}
                  className={classes.textfield}
                />
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  label='Organization Unit'
                  onChange={
                    (e) => groupChange(i, e, 'idmsGroups', 'group_description')
                  }
                  name='ou'
                  value={item.ou}
                  className={classes.textfield}
                />
              </Grid>
            </Grid>
          ))}

        </div>
      )
    default:
      return null
    }
  }
  return (
    getDetailContent()
  )
}
