import React from 'react'
import {
  Input,
  MenuItem,
  ListItemText,
  Select,
  Checkbox
} from '@material-ui/core'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

export default function MultipleSelect ({
  handleChange, selectedValue, options
}) {
  return (
    <div style={{
      width: '70%'
    }}
    >
      <Select
        style={{
          width: '100%'
        }}
        labelId='demo-mutiple-checkbox-label'
        id='demo-mutiple-checkbox'
        multiple
        value={selectedValue}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        {options.map((name) => (
          <MenuItem key={name.value} value={name.value}>
            <Checkbox checked={selectedValue.indexOf(name.value) > -1} />
            <ListItemText primary={name.value} />
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}
