import React, {
  useContext, useRef
} from 'react'
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  makeStyles,
  FormControl
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import PropTypes from 'prop-types'
import UserContext from '../contexts/UserContext'
import MyApprovalsService from '../../services/api/myApprovalsService'
import EmailServices from '../../services/api/emailServices'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  },
  footer: {
    padding: 20
  },
  error: {
    color: '#f44336'
  }
}))

function ESignComponent ({
  handleClose,
  alertopen,
  seletedRequests,
  title,
  handleComplete,
  mailSelectedRequests
}) {
  const {
    user
  } = useContext(UserContext)
  const classes = useStyles()
  const [errorAlert, setErrorAlert] = React.useState(false)
  const [loading, setLoading] = React.useState()
  // form data declaration
  const [formData, setFormData] = React.useState({
    username: user.name,
    password: '',
    comments: ''
  })
  const form = useRef(null)

  const approveOrReject = () => {
    formData.requestIds = seletedRequests
    formData.action = title === 'Approve' ? 'Approved' : 'Rejected'
    setLoading(true)
    MyApprovalsService.ApproveOrRejectRequest(formData).then((response) => {
      setLoading(false)
      handleComplete()
      if (user.email) {
        EmailServices.sendMailtoSender(formData.comments,
          title, mailSelectedRequests).then((respone2) => { })
      }
      setFormData({
        ...formData,
        password: '',
        comments: ''
      })
    },
    (error) => {
      if (error) {
        setLoading(false)
        setErrorAlert(true)
      }
      setLoading(false)
      setErrorAlert(true)
    })
  }
  const handleChange = (e) => {
    setErrorAlert(false)
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }
  const alertclose = () => {
    setFormData({
      ...formData,
      password: '',
      comments: ''
    })
    handleClose()
  }
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={alertopen}
    >
      <ValidatorForm ref={form} onSubmit={(event) => approveOrReject()}>
        <DialogTitle id='alert-dialog-title'>{`${title} Request`}</DialogTitle>
        <DialogContent>

          <div>
            <FormControl className={classes.formControl}>
              <TextValidator
                label='Username'
                name='username'
                fullWidth
                value={formData.username}
                disabled
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextValidator
                label='Password *'
                name='password'
                onChange={handleChange}
                fullWidth
                value={formData.password}
                type='password'
                validators={['required']}
                errorMessages={['Please enter password']}
                autoFocus
              />
              {errorAlert ? (
                <p className={classes.error}>
                  Password Incorrect
                </p>
              ) : null}
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextValidator
                label={title === 'Reject' ? 'Comments *' : 'Comments'}
                name='comments'
                onChange={handleChange}
                value={formData.comments ? formData.comments : ''}
                multiline
                rows={1}
                fullWidth
                rowsMax={4}
                validators={title === 'Reject' ? ['required'] : null}
                errorMessages={['Please enter comments']}
              />

            </FormControl>
          </div>

        </DialogContent>

        <DialogActions className={classes.footer}>
          <Button
            variant='contained'
            size='small'
            onClick={() => alertclose()}
            style={{
              marginRight: 20
            }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color={title === 'Approve' ? 'primary' : 'secondary'}
            type='submit'
            size='small'
            disabled={loading}
          >
            {title}
          </Button>
        </DialogActions>
      </ValidatorForm>

    </Dialog>
  )
}
ESignComponent.propTypes = {
  handleClose: PropTypes.func.isRequired,
  alertopen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleComplete: PropTypes.func.isRequired,
  seletedRequests: PropTypes.array.isRequired,
  mailSelectedRequests: PropTypes.array.isRequired
}

export default ESignComponent
