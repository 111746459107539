import React, {
  useEffect
} from 'react'
import clsx from 'clsx'
import {
  makeStyles
} from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import {
  ListItem, Menu,
  ListItemText, TextField,
  Button,
  Typography,
  Grid,
  MenuItem,
  withStyles,
  Icon,
  DialogActions,
  FormControl,
  List,
  LinearProgress,
  Tooltip as TtoolTip,
  Input,
  InputLabel,
  IconButton
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import RadioGroup from '@material-ui/core/RadioGroup'
import Alert from '@material-ui/lab/Alert'
import {
  ValidatorForm,
  SelectValidator,
  TextValidator
} from 'react-material-ui-form-validator'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CloseIcon from '@material-ui/icons/Close'
import RadioButtons from '../inputs/radio'
import RichTextEditor from './RichTextEditor'

const useStyles = makeStyles({
  list: {
    width: 'auto',
    padding: 20
  },
  fullList: {
    width: 'auto'
  },
  title: {
    marginBottom: 10
  }
})

export default function TemporaryDrawer ({
  openDialog, currentConfiguration, close, formData, widgetErrorMessage,
  changeChartType, changeRereshInterval, handleChange, switchToAdvance,
  handleConfiguraitonClose, modalLoading, checkMandatoryFields, showDetails,
  loadingbar, changeWidgetName, changeEditorValue, changeFormValue
}) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    setOpen(openDialog)
  }, [openDialog])

  const closeDialog = (event) => {
    if (event.type === 'keydown' &&
    (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    close()
  }

  const list = (anchor) => (
    <>
      {modalLoading ? <LinearProgress /> : null}
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === 'top' || anchor === 'bottom'
        })}
        role='presentation'
      >
        <Typography variant='h6'>
          {currentConfiguration.name}
          {' '}
              - Configuration
        </Typography>
        <IconButton
          aria-label='close'
          className='widgetCloseButton'
          onClick={closeDialog}
        >
          <CloseIcon />
        </IconButton>
        <ValidatorForm>
          <div className='modelDialog'>
            {' '}
            {widgetErrorMessage ? (
              <Alert severity='error'>{widgetErrorMessage}</Alert>

            ) : null}
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <FormControl className={`${classes.title} widgetFormControl`}>
                  <TextValidator
                    value={currentConfiguration.displayname}
                    name='displayname'
                    label='Title'
                    style={{
                      width: '100%'
                    }}
                    onChange={(e) => changeWidgetName(e, currentConfiguration)}
                  />
                </FormControl>
                {formData.chartType === 'html' ? (
                  <div>
                    {currentConfiguration.value}
                  </div>
                ) : null}
                {formData.chartType === 'richtext' ? (
                  <div>
                    <RichTextEditor
                      editorValue={currentConfiguration.value}
                      handleEditorChange={changeEditorValue}
                      showTablesModule='false'
                      showTextModule='true'
                    />
                  </div>
                ) : (
                  <>
                    <FormControl className='widgetFormControl'>
                      <SelectValidator
                        value={formData.chartType}
                        label='Chart Type'
                        id='chart_type'
                        name='chartType'
                        style={{
                          width: '100%'
                        }}
                        onChange={
                          (e) => changeChartType(e, currentConfiguration)
                        }
                      >
                        {(currentConfiguration.name === 'Commits By User' ||
                          currentConfiguration
                            .name === 'Pull Requests By User' ||
                              formData.chartType === 'bar') && (
                          <MenuItem
                            value='bar'
                            key='bar'
                          >
                                Bar
                          </MenuItem>
                        )}
                        {(formData.chartType === 'SimpleLineChart') && (
                          <MenuItem
                            value='SimpleLineChart'
                            key='SimpleLineChart'
                          >
                                Line
                          </MenuItem>
                        )}
                        {currentConfiguration.name ===
                              'Sprint Committed Vs Completed' && (
                          <MenuItem
                            value='barChartWithMinHeight'
                            key='barChartWithMinHeight'
                          >
                                Multi Bar
                          </MenuItem>
                        )}
                        {(currentConfiguration.name === 'Backlog Health' ||
                            currentConfiguration.name ===
                            'No. of Story Points in Ready Status' ||
                            currentConfiguration.name ===
                            'Cycle Time On Issues Transitions' ||
                            currentConfiguration.name ===
                            'Universal Ratio Widget' ||
                            currentConfiguration
                              .name === 'Number Of Issues') && (
                          <MenuItem
                            value='numberGadget'
                            key='numberGadget'
                          >
                                Number Gadget
                          </MenuItem>
                        )}
                        {
                          (currentConfiguration
                            .name === 'Commits per Project' ||
                                currentConfiguration.name ===
                                'Pull Requests per Project') && (
                            <MenuItem
                              value='stackedbar'
                              key='stackedbar'
                            >
                                  Stacked Bar
                            </MenuItem>
                          )
                        }
                        {(currentConfiguration.name === 'Issues from JQL' ||
                            currentConfiguration.name === 'Page Views') && (
                          <MenuItem
                            value='table'
                            key='table'
                          >
                                Table
                          </MenuItem>
                        )}
                      </SelectValidator>

                    </FormControl>
                  </>
                )}
                {
                  currentConfiguration.data_service &&
                        currentConfiguration.data_service
                          .refresh_interval_values ? (

                      <FormControl className='widgetFormControl'>
                        <SelectValidator
                          value={formData.refreshInterval}
                          label='Refresh Interval'
                          id='chart_type'
                          style={{
                            width: '100%'
                          }}
                          name='refreshInterval'
                          onChange={
                            (e) => changeRereshInterval(e,
                              currentConfiguration)
                          }
                        >
                          {
                            currentConfiguration.data_service
                              .refresh_interval_values.map((val, idx) => (
                                <MenuItem
                                  value={val.value}
                                  key='numberGadget'
                                >
                                  {val.label}
                                </MenuItem>
                              ))
                          }
                        </SelectValidator>
                      </FormControl>
                    ) : null
                }

              </Grid>
            </Grid>

            {

              Object.keys(currentConfiguration).map((val, idx) => {
                if (val === 'data_service' &&
                  currentConfiguration[val] &&
                  currentConfiguration[val].properties &&
                  currentConfiguration[val].properties.filters) {
                  return (
                    <div>
                      {

                        currentConfiguration[val].properties.filters
                          .map((property, idx1) => (
                            // eslint-disable-next-line react/jsx-key
                            <Grid container spacing={2}>
                              {' '}
                              <Grid item xs={12} md={12}>
                                {' '}
                                {property.name === 'type'
                                  ? (
                                    <RadioButtons
                                      radio={property.value}
                                      selectedvalue={formData.type}
                                      onChange={
                                        (value) => handleChange(property,
                                          value, property.name)
                                      }
                                    />
                                  )
                                  // eslint-disable-next-line max-len
                                  : property.name === 'jql' || property.name === 'jql1' || property.name === 'jql2' ? (
                                    <FormControl
                                      className='widgetFormControl'
                                    >
                                      {currentConfiguration.name ===
                                        'Cycle Time On Issues Transitions' ? (
                                          <>
                                            <InputLabel>
                                              Enter a valid JQL without Project
                                                and Issuetype (Optional)
                                            </InputLabel>
                                            <Input
                                              id='jql'
                                              name='jql'
                                              value={formData.jql}
                                              // eslint-disable-next-line max-len
                                              onChange={(e) => handleChange(property,
                                                // eslint-disable-next-line max-len
                                                e.target.value, property.name)}
                                            />
                                          </>
                                        )
                                        : (currentConfiguration.name ===
                                          'Number Of Issues' ||
                                          currentConfiguration.name ===
                                          'Issues from JQL') ? (
                                            <>
                                              <InputLabel>
                                              Enter a valid JQL
                                              </InputLabel>
                                              <Input
                                                id='jql'
                                                name='jql'
                                                value={formData.jql}
                                                // eslint-disable-next-line max-len
                                                onChange={(e) => handleChange(property,
                                                  // eslint-disable-next-line max-len
                                                  e.target.value, property.name)}
                                              />
                                            </>
                                          ) : (currentConfiguration.name ===
                                            'Universal Ratio Widget'
                                            ? (
                                              <>
                                                <InputLabel>
                                                  {property.label}
                                                </InputLabel>
                                                <Input
                                                  id='jql'
                                                  name={property.name}
                                                  // eslint-disable-next-line max-len
                                                  value={property.name === 'jql1' ? formData.jql1 : formData.jql2}
                                                  // eslint-disable-next-line max-len
                                                  onChange={(e) => handleChange(property,
                                                    // eslint-disable-next-line max-len
                                                    e.target.value, property.name)}
                                                />

                                              </>
                                            ) : null)}

                                    </FormControl>
                                  ) : (currentConfiguration.name ===
                                      'No. of Story Points in Ready Status' &&
                                          formData.type === 'Project' &&
                                          property.name === 'name'
                                    ? null : (
                                      <FormControl
                                        className='widgetFormControl'
                                      >
                                        {property.name === 'page_title' &&
                                            formData.isWildCard && (
                                          <>
                                            <RadioGroup
                                              row
                                              aria-label='position'
                                              name='position'
                                              value='wildCard'
                                              onChange={
                                                () => (
                                                  switchToAdvance(formData)
                                                )
                                              }
                                            >
                                              <FormControlLabel
                                                value='Select'
                                                control={
                                                  <Radio />
                                                }
                                                label='Pages'
                                              />
                                              <FormControlLabel
                                                value='wildCard'
                                                control={
                                                  <Radio />
                                                }
                                                label='Wildcard'
                                              />
                                            </RadioGroup>

                                            <Input
                                              id={property.id}
                                              name={property.name}
                                              value={
                                                formData[property.name]
                                                  ? formData[property.name]
                                                  : ''
                                              }
                                              onChange={
                                                (e) => (
                                                  handleChange(property,
                                                    e.target.value,
                                                    property.name)
                                                )
                                              }
                                            />
                                          </>
                                        )}


                                        {property.name === 'page_title' &&
                                            !formData.isWildCard && (
                                          <>
                                            <RadioGroup
                                              row
                                              aria-label='position'
                                              name='position'
                                              value='Select'
                                              onChange={
                                                () => (
                                                  switchToAdvance(formData)
                                                )
                                              }
                                            >
                                              <FormControlLabel
                                                value='Select'
                                                control={<Radio />}
                                                label='Pages'
                                              />
                                              <FormControlLabel
                                                value='wildCard'
                                                control={<Radio />}
                                                label='Wildcard'
                                              />
                                            </RadioGroup>
                                            <Autocomplete
                                              id={property.id}
                                              options={property.value}
                                              value={
                                                formData[property.name]
                                                  ? formData[property.name]
                                                  : ''
                                              }
                                              getOptionLabel={
                                                (option) => option
                                              }
                                              loading={
                                                (!formData[property.name] &&
                                                  loadingbar) ||
                                                    (formData[property.name] &&
                                                        formData[property.name]
                                                          .length === 0 &&
                                                      loadingbar)
                                              }
                                              onChange={
                                                (event,
                                                  value) => (
                                                  handleChange(property,
                                                    value,
                                                    property.name)
                                                )
                                              }
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label={property.label}
                                                  name={property.name}
                                                />
                                              )}
                                            />
                                          </>
                                        )}
                                        {property.name !== 'page_title' && (
                                          <Autocomplete
                                            id={property.id}
                                            options={property.value}
                                            value={
                                              formData[property.name]
                                                ? formData[property.name]
                                                : ''
                                            }
                                            getOptionLabel={
                                              (option) => option
                                            }
                                            loading={
                                              (!formData[property.name] &&
                                                  loadingbar) ||
                                                    (formData[property.name] &&
                                                        formData[property.name]
                                                          .length === 0 &&
                                                      loadingbar)
                                            }
                                            onChange={
                                              (event,
                                                value) => (
                                                handleChange(property,
                                                  value, property.name)
                                              )
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label={property.label}
                                                name={property.name}
                                              />
                                            )}
                                          />
                                        )}
                                        {}
                                      </FormControl>
                                    )
                                  )}
                              </Grid>
                            </Grid>
                          ))
                      }
                      {currentConfiguration.name === 'Average Velocity' && (
                        <FormControl className='widgetFormControl'>
                          <SelectValidator
                            value={formData.timeScale}
                            label='Time Scale'
                            id='time-scale'
                            name='timeScale'
                            style={{
                              marginTop: 10,
                              width: '100%'
                            }}
                            onChange={
                              (e) => changeFormValue(e, currentConfiguration)
                            }
                          >
                            <MenuItem
                              value='sprint'
                              key='sprint'
                            >
                                  By Sprint
                            </MenuItem>
                            <MenuItem
                              value='month'
                              key='month'
                            >
                                  By Month
                            </MenuItem>
                            <MenuItem
                              value='quarter'
                              key='quarter'
                            >
                                  By Quarter
                            </MenuItem>
                          </SelectValidator>
                        </FormControl>
                      )}
                      {(currentConfiguration.name === 'Average Velocity' ||
                      currentConfiguration.name === 'Universal Ratio Widget' ||
                      currentConfiguration.name === 'Backlog Health') && (
                        <FormControl className='widgetFormControl'>
                          <TextValidator
                            value={formData.target}
                            name='target'
                            label='Target'
                            type='number'
                            style={{
                              marginTop: 10,
                              width: '100%'
                            }}
                            onChange={(e) => changeFormValue(e,
                              currentConfiguration)}
                          />
                        </FormControl>
                      )}
                    </div>
                  )
                }
              })
            }
          </div>
          <DialogActions className='dialogAction'>
            <Button
              disabled={modalLoading}
              autoFocus
              onClick={() => handleConfiguraitonClose()}
            >
                  Close
            </Button>
            <Button
              type='button'
              disabled={checkMandatoryFields()}
              autoFocus
              onClick={() => showDetails()}
              color='primary'
            >
                  Save changes
            </Button>
          </DialogActions>
        </ValidatorForm>
      </div>
    </>
  )

  return (
    <div>
      <Drawer
        anchor='right'
        open={openDialog}
        onClose={closeDialog}
      >
        {list('right')}
      </Drawer>
      {/* ))} */}
    </div>
  )
}
