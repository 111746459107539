import React, {
  useState, useEffect, useRef
} from 'react'
import {
  MenuItem,
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  FormControl,
  Select,
  makeStyles,
  LinearProgress
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import {
  Alert
} from '@material-ui/lab'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import kubeConfigService from '../../services/api/kubeConfigService'

export default function KubeeConfigComponent () {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },
    footer: {
      margin: theme.spacing(1),
      float: 'right'
    },
    formControl: {
      margin: theme.spacing(1),
      width: '90%'
    },
    components: {
      margin: theme.spacing(1),
      width: '40%'
    },
    labelDisplay: {
      margin: '10px',
      width: '100%'
    },
    nodatatext: {
      ...theme.typography.subtitle1,
      padding: theme.spacing(12),
      textAlign: 'center'
    }
  }))
  const classes = useStyles()


  const [loading, setLoading] = React.useState(false)
  const [awsLoading, setAwsLoading] = React.useState(false)
  const [clusterLoading, setClusterLoading] = React.useState(false)
  const [kubeConfigLoading, setKubeConfigLoading] = React.useState(false)
  const [awsAccounts, setAccounts] = useState([])
  const [selectedCluster, setClusterlist] = useState([])
  const [awsValue, setAWS] = useState('')
  const [region, setRegion] = useState('')
  const [awsType, setAwsType] = useState('EKS')
  const [cluster, setCluster] = useState('')
  const [clusterError, setClusterError] = useState(false)
  const [clusterErrorMsg, setClusterErrorMsg] = useState('')
  const [config, setConfig] = useState(null)
  const [copyAlert, setCopyAlert] = useState(false)
  const [configRes, setconfigRes] = useState(false)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [failalertMessage, setFailalertMessage] = React.useState(null)
  const form = useRef(null)

  const handleAwsSelection = (event) => {
    setLoading(true)
    setAWS(event.target.value)
    setCluster('')
    setClusterLoading(true)
    kubeConfigService.getClusterList(event.target.value,
      region).then((response) => {
      setLoading(false)
      setClusterLoading(false)
      setClusterlist(response.data.clusters ? response.data.clusters : [])
    },
    (error) => {
      if (error) {
        setLoading(false)
        setClusterLoading(false)
        const msg = `Cannot fetch cluster.  
        ${error.response.data.errMessage ? error.response.data.errMessage : ''}
        . Please check with your admin`
        setClusterError(true)
        setClusterErrorMsg(msg)
      }
    })
  }
  const handleTypeChange = (event) => {
    setAwsType(event.target.value)
  }
  const handleClusterSelection = (event) => {
    setCluster(event.target.value)
  }
  const handleRegionSelection = (event) => {
    setRegion(event.target.value)
    if (awsValue) {
      setAWS('')
    }
    setCluster('')
  }
  const getText = (config) => `${config}`
  const copyText = () => {
    if (document.selection) {
      // console.log('document selection ')
      const range = document.body.createTextRange()
      range.moveToElementText(document.getElementById('config'))
      range.select().createTextRange()
      document.execCommand('copy')
    } else if (window.getSelection) {
      // console.log('window selection ')
      const selection = window.getSelection()
      const range = document.createRange()
      range.selectNode(document.getElementById('config'))
      selection.removeAllRanges()
      window.getSelection().addRange(range)
      document.execCommand('copy')
      setCopyAlert(true)
      setTimeout(() => {
        setCopyAlert(false)
      }, 3000)
    }
  }
  const regionList = ['us-east-1', 'eu-west-1', 'ap-southeast-1',
    'eu-central-1', 'us-east-2']
  const typeList = ['EKS']
  useEffect(() => {
    setLoading(true)
    setAwsLoading(true)
    kubeConfigService.getAWSList().then((response) => {
      setLoading(false)
      setAwsLoading(false)
      // console.log('response status ', response.status)
      if (response.data) {
        setAccounts(response.data)
        setCluster('')
      }
    })
  }, [])
  const resetForm = () => {
    setAWS('')
    setCluster('')
    setAwsType('EKS')
    setLoading(false)
    setAwsLoading(false)
    setClusterLoading(false)
    setKubeConfigLoading(false)
    setClusterError(false)
    setClusterErrorMsg('')
    setRegion('')
    setConfig(null)
    setconfigRes(false)
    seterrorAlert(false)
    setFailalertMessage(null)
  }
  const submitRequest = () => {
    setconfigRes(true)
    setLoading(true)
    setKubeConfigLoading(true)
    kubeConfigService.getKubeConfig(awsValue, cluster, region, awsType)
      .then((response) => {
        setLoading(false)
        setKubeConfigLoading(false)
        // console.log('kubeconfig response ', response.data.message)
        if (response.data.errMessage || response.data.error) {
          setFailalertMessage(response.data.errMessage)
          seterrorAlert(true)
          setconfigRes(false)
        } else {
          resetForm()
          setConfig(JSON.stringify(response.data))
          const blob = new Blob([response.data], {
            type: 'application/x-yaml'
          })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.className = 'hidden'
          link.download = `${cluster}.yaml`
          document.body.appendChild(link)
          link.click()
        }
      },
      (error) => {
        if (error) {
          setKubeConfigLoading(false)
          setLoading(false)
          seterrorAlert(true)

          if (error.response.data && error.response.data.errMessage) {
            setFailalertMessage(error.response.data.errMessage)
          } else {
            const enc = new TextDecoder('utf-8')
            const arr = new Uint8Array(error.response.data)
            const d = enc.decode(arr)

            setFailalertMessage(JSON.parse(d).errMessage)
          }
        }
      })
  }
  return (


    <>
      {awsLoading
        ? (
          <Grid container spacing={0}>
            <Grid item xs={3}>
              <b>
                Fetching your CLOUDx account. Please wait
              </b>
            </Grid>
            <Grid item xs={9}>
              <LinearProgress />
            </Grid>
          </Grid>
        ) : null}
      {clusterLoading
        ? (
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <b>
                Fetching clusters for the account
                {' '}
                {awsValue}
                {'. '}
                Please wait
              </b>
            </Grid>
            <Grid item xs={8}>
              <LinearProgress />
            </Grid>
          </Grid>
        ) : null}
      {kubeConfigLoading
        ? (
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <b>Fetching the configs. Please wait</b>
            </Grid>
            <Grid item xs={10}>
              <LinearProgress />
            </Grid>
          </Grid>
        ) : null}
      {' '}
      {loading
        ? (
          <div style={{
            paddingTop: '35px'
          }}
          >
            {' '}
          </div>
        ) : null}
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={6} md={6}>
          <Typography variant='h5' component='h2'>
            kubeconfig
          </Typography>
          <Card variant='outlined'>
            {clusterError
              ? (
                <Alert severity='error' onClose={() => setClusterError(false)}>
                  {clusterErrorMsg}
                </Alert>
              ) : null}
            {errorAlert
              ? (
                <Alert severity='error' onClose={() => seterrorAlert(false)}>
                  {failalertMessage}
                </Alert>
              ) : null}
            <ValidatorForm ref={form} onSubmit={(event) => submitRequest()}>
              <CardContent>
                <Typography variant='subtitle1' gutterBottom>
                Download kubeconfig file by selecting region,
                CLOUDx account and cluster in order
                </Typography>
                <FormControl className={classes.formControl}>
                  <SelectValidator
                    label='Type'
                    name='type'
                    fullWidth
                    required
                    onChange={handleTypeChange}
                    value={awsType}
                    validators={['required']}
                    errorMessages={['This field is required']}
                  >
                    {typeList.map((value, index) => (
                      <MenuItem value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <SelectValidator
                    label='Region'
                    name='region'
                    required
                    fullWidth
                    onChange={handleRegionSelection}
                    value={region}
                    validators={['required']}
                    errorMessages={['This field is required']}
                  >
                    {regionList.map((value, index) => (
                      <MenuItem value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <SelectValidator
                    label='Account'
                    name='awsaccount'
                    required
                    fullWidth
                    value={awsValue}
                    onChange={handleAwsSelection}
                    validators={['required']}
                    errorMessages={['This field is required']}
                  >
                    {awsAccounts.map((value, index) => (
                      <MenuItem value={value.key}>
                        {value.key}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <SelectValidator
                    label='Cluster Name'
                    name='clustername'
                    required
                    fullWidth
                    onChange={handleClusterSelection}
                    value={cluster}
                    validators={['required']}
                    errorMessages={['This field is required']}
                  >
                    {selectedCluster.map((value, index) => (
                      <MenuItem value={value.cluster_name}>
                        {value.cluster_name}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </FormControl>
              </CardContent>
              <CardActions className={classes.footer}>
                <Button
                  variant='contained'
                  size='small'
                  onClick={resetForm}
                >
                Reset
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  type='submit'
                  disabled={!awsValue || !cluster || !region || loading}
                  size='small'
                  disableRipple
                  // onClick={submitRequest}
                >
                Download
                </Button>
              </CardActions>
            </ValidatorForm>
          </Card>
        </Grid>
        {/* {
          configRes ? (
            <Grid item xs={6} md={5}>
              <Card variant='outlined'>
                <CardContent>
                  <Typography variant='subtitle1' gutterBottom>
                Configuration File
                  </Typography>
                  {copyAlert && (
                    <span
                      style={{
                        color: '#00b300',
                        marginLeft: 10
                      }}
                      className='copyalert'
                    >
Configuration Copied
                    </span>
                  )}
                  <div
                    className='pull-right'
                    onClick={copyText}
                    title='Copy Configuration'
                    style={{
                      cursor: 'pointer'
                    }}
                  >
                    <FileCopyOutlinedIcon />
                  </div>
                  <Typography
                    variant='subtitle2'
                    id='config'
                    gutterBottom
                    style={{
                      wordBreak: 'break-word',
                      marginTop: 10,
                      padding: 10
                    }}
                  >
                    {!config ? 'Loading...' : (
                      <div>
                        {getText(config)}
                      </div>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ) : null
        } */}
      </Grid>

    </>

  )
}
