import React, {
  useContext, useEffect
} from 'react'
import {
  DragDropContext, Droppable, Draggable
} from 'react-beautiful-dnd'
import {
  BarChart, Bar, AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid,
  ResponsiveContainer, PieChart, Pie, Legend,
  LineChart, Line, Label, Text
} from 'recharts'
import Alert from '@material-ui/lab/Alert'
import {
  Link
} from 'react-router-dom'
import {
  ValidatorForm,
  SelectValidator
} from 'react-material-ui-form-validator'
import parse from 'html-react-parser'
import cloneDeep from 'lodash/cloneDeep'
import {
  ListItem, Menu,
  ListItemText, TextField,
  Button,
  Typography,
  Grid,
  MenuItem,
  makeStyles,
  withStyles,
  Icon,
  InputBase,
  FormControl,
  List,
  LinearProgress,
  Tooltip as TtoolTip,
  Input,
  InputLabel,
  Paper,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@material-ui/core'
import moment from 'moment'
import {
  Resizable, ResizableBox
} from 'react-resizable'
import DragResizeContainer from 'react-drag-resize'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  ContactSupportOutlined,
  Done,
  Edit
} from '@material-ui/icons'
import _ from 'lodash'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import InfoIcon from '@material-ui/icons/Info'
import PublishIcon from '@material-ui/icons/Publish'
import SettingsIcon from '@material-ui/icons/Settings'
import RGL, {
  WidthProvider
} from 'react-grid-layout'
import IconButton from '@material-ui/core/IconButton'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioButtons from '../inputs/radio'
import DashboardComponent from './dashboardComponent'
import TemporaryDrawer from './configurationDrawer'
import SummaryConfig from './summaryConfiguration'
import EmbedIframeWidget from './embedIframe'
import UserContext from '../contexts/UserContext'
import PathContext from '../contexts/PathContext'
import globalData from '../../data/globalData.json'
import '../../../node_modules/react-grid-layout/css/styles.css'
import '../../../node_modules/react-resizable/css/styles.css'
import '../../App.css'
import DashboardService from '../../services/api/dashboardService'
import EnhancedTable from '../sections/enhancedTable'
import AlertDialog from '../alertbox/alertboxComponent'
import rsslogo from '../../assets/images/rss.jpg'

const useStyles = makeStyles((theme) => ({
  numberGadgets: {
    backgroundColor: '#FFF'
  },
  root: {
    flexGrow: 1
  },
  padding: {
    padding: theme.spacing(3)
  },
  error: {
    color: 'red',
    'text-align': 'center'
  },
  imageContainer: {
    display: 'flex'
  },
  imageSource: {
    height: '16px',
    width: '16px',
    margin: '5px'
  },
  tickvalues: {
    whiteSpace: 'nowrap',
    width: 50,
    overflow: 'hidden',
    'text-overflow': 'ellipsis'
  },
  image: {
    cursor: 'pointer',
    width: 20,
    height: 20
  }
}))
const ReactGridLayout = WidthProvider(RGL)
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
    width: '500px'
  },
  menuIcon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 15,
    color: '#7f7f7f',
    fontSize: '1.2rem'
  }

})
const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100
  }
]
const velocityColors = {
  Average: 'rgb(234,11,34)',
  Target: 'rgb(73, 175, 81)',
  Actual: 'rgb(32, 128, 182)'
}

const headCells = [
  {
    id: 'key',
    numeric: false,
    label: 'Key',
    width: 5
  },
  {
    id: 'summary',
    numeric: false,
    label: 'Summary',
    width: 15
  },
  {
    id: 'assignee',
    numeric: false,
    label: 'Assignee',
    width: 5,
    type: 'username'
  },
  {
    id: 'priority',
    numeric: false,
    label: 'Priority',
    width: 1
  }
]

const pageViewHeader = [
  {
    id: 'page',
    numeric: false,
    label: 'Page Title',
    width: 25
  },
  {
    id: 'pageViews',
    numeric: false,
    label: 'Page Views',
    width: 1
  },
  {
    id: 'uPageviews',
    numeric: false,
    label: 'Unique Page Views',
    width: 1

  },
  {
    id: 'avgTime',
    numeric: false,
    label: 'Avg. Time on Page (Sec)',
    width: 5
  }
]

const EditNameIcon = withStyles({
  root: {
    width: '14px',
    height: '14px',
    marginLeft: '5px',
    '& svg': {
      width: '14px',
      height: '14px'
    }
  }
})(Icon)

const EditableInputField = withStyles({
  root: {
    fontSize: 'inherit',
    color: '#fff',
    display: 'inherit',
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    letterSpacing: 'inherit',
    width: '100%',
    padding: '0px 20px',
    '& input': {
      fontSize: 'inherit',
      padding: '0px',
      fontWeight: 'inherit',
      lineHeight: 'inherit',
      textAlign: 'center',
      height: 'inherit'
    }
  }
})(InputBase)

export default function ViewDashboardComponent (props) {
  const cols = 12
  const [initalLoad, setInitialLoad] = React.useState(true)

  const [formData, setFormData] = React.useState({
    name: '',
    project_key: '',
    chartType: '',
    branch_name: '',
    type: 'Project',
    refreshInterval: 0,
    issuetype: '',
    jql: '',
    jql1: '',
    jql2: '',
    fromstatus: '',
    tostatus: '',
    sprint_name: '',
    page_title: '',
    space_key: ''
  })
  const [open, setOpen] = React.useState(false)

  let currentItems = []

  const intervals = []

  const [editOpen, setEditOpen] = React.useState(false)
  const [summaryeditOpen, setSummaryEditOpen] = React.useState(false)
  const [powerBiOpen, setPowerBiOpen] = React.useState(false)
  const [owner, setOwner] = React.useState(false)
  const [currentConfiguration, setcurrentConfiguration] = React.useState({
    data: 'test',
    chartType: ''
  })
  const [items, setItems] = React.useState([])
  const [newCounter, setNewCounter] = React.useState(0)
  const [dashboardTitle, setDashboardTitle] = React.useState('')
  const [widgetErrorMessage, setwidgetErrorMessage] = React.useState('')
  const [displayFields, setdisplayFields] = React.useState({
    name: 'Repos',
    branch_name: 'Branch',
    project_key: 'Key'
  })
  const [widgets, setWidgets] = React.useState(null)
  const [currentWidget, setcurrentWidget] = React.useState(null)
  const [layout, setLayout] = React.useState([])
  const {
    user
  } = useContext(UserContext)
  const {
    setPath
  } = useContext(PathContext)
  const [loading, setLoading] = React.useState(false)
  const [tabloading, setTabLoading] = React.useState(false)
  const [loadingbar, setLoadingBar] = React.useState(false)
  const [modalLoading, setModalLoading] = React.useState(false)
  const [tools, setTools] = React.useState([])
  const [tabs, setTabs] = React.useState([])
  const [currentTab, setCurrentTab] = React.useState(null)
  const [editTabIndex, setEditTabIndex] = React.useState(null)
  const [newItem, setNewItem] = React.useState(null)
  const [state, setState] = React.useState({

  })
  const [newTabName, setNewTabName] = React.useState(null)
  const [radioValue, setRadioValue] = React.useState('Projects')
  const classes = useStyles()
  const [nameModal, setNameModal] = React.useState(false)
  const [tabDeleteAlert, setTabDeleteAlert] = React.useState(false)
  const [deleteWarning, setDeleteWarning] = React.useState(false)
  const [maxWidth, setMaxWidth] = React.useState('md')
  const [publishAlert, setPublishAlert] = React.useState(false)
  const [openPublishLink, setOpenPublishLink] = React.useState(false)
  const [publishWidget, setPublishWidget] = React.useState(null)
  const [rssLink, setRssLink] = React.useState(null)

  const colors = [
    '#48b4e0',
    '#AA96C7',
    '#64D8A3',
    '#D65827',
    '#A3C2B4',
    '#F1A4A5',
    '#D4D1C0',
    '#F06360',
    '#A35F5F',
    '#E5CE21',
    '#5DC8E0',
    '#ABA576',
    '#F8F7D2'
  ]

  useEffect(() => {
    setFormData({
      name: '',
      project_key: '',
      chartType: '',
      branch_name: '',
      type: 'Project',
      issuetype: '',
      jql: '',
      jql1: '',
      jql2: '',
      fromstatus: '',
      tostatus: '',
      sprint_name: '',
      page_title: '',
      space_key: '',
      displayname: ''
    })
    DashboardService.getDashboardUsers(props.match.params.id)
      .then((response) => {
        const dashboardUsersData = response.data
        const dashboardownerscheck = []
        dashboardUsersData.map((item) => {
          if (item.type === globalData.TEMPLATE_OWNER) {
            dashboardownerscheck.push(item.username.toLowerCase())
          }
        })
        if (dashboardownerscheck.includes(user.name.toLowerCase()) ||
          user.isAdmin) {
          setOwner(true)
        }
        loadSingleDashboard('initial')
      })
    // to save the id into recent dashboards
    DashboardService.saveRecentAndFavoriteDB({
      id: props.match.params.id,
      username: user.name,
      type: 'recent'
    })
  }, [])

  const changeDrag = (dragLayout) => {
    if (initalLoad) {
      setInitialLoad(false)
    }
  }
  const onAddItem = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const parseHtml = (htmlMessage) => {
    let returndata = 'null'
    if (htmlMessage) {
      returndata = parse(htmlMessage)
    }
    return returndata
  }
  const CustomXAxisTick = (props) => (
    <Text
      {...props}
      width={100}
      className={classes.tickvalues}
    >
      {props.payload.value}
    </Text>
  )
  const grid = 8
  const reorder = (list, startIndex, endIndex) => {
    list = tabs
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    paddingRight: grid * 1,
    // margin: `0 ${grid}px 0 0`,
    // change background colour if dragging
    // background: isDragging ? 'lightgreen' : 'grey',
    // styles we need to apply on draggables
    ...draggableStyle
  })
  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? 'lightblue' : 'lightgrey',
    display: 'flex',
    padding: grid,
    overflow: 'auto'
  })
  function onDragEnd (result) {
    if (!result.destination) {
      return
    }

    // if (result.destination.index === result.source.index) {
    //   return
    // }
    const [items] = tabs
    const sorted = reorder(items,
      result.source.index,
      result.destination.index)
    setTabs([
      ...sorted
    ])
    const tabs1 = cloneDeep(sorted)
    for (let i = 0; i < tabs1.length; i++) {
      delete tabs1[i].edit
      for (let j = 0; j < tabs1[i].widgets.length; j++) {
      //  layout[i].chartData = items[i].chartData
        delete tabs1[i].widgets[j].chartData
        const widgetdata = tabs1[i].widgets[j].widgetData
        if (widgetdata.data_service.properties && widgetdata.data_service
          .properties.filters && widgetdata.data_service.properties
          .filters.length) {
        //     widgetdata.data_service.properties.filters.map((d1, i1) => {
          widgetdata.data_service.properties.filters[0].value = []
          //     })
        }
        tabs1[i].widgets[j].widgetData = widgetdata
      }
    }
    const formData = {
      id: props.match.params.id,
      widget: tabs1
    }
    DashboardService.saveLayoutToDataBase(formData).then((response) => {
    })
  }

  const selectTabs = (tab) => {
    setCurrentTab(tab)
    setItems([])
    if (tab.widgets.length) {
      currentItems = tab.widgets
      getchartdata(currentItems)
      setItems(tab.widgets)
      setLayout(tab.widgets)
    } else {
      setItems(tab.widgets)
    }
  }
  const onRemoveItem = (itemid) => {
    for (const item of items) {
      if (item.i === itemid && item.widgetData.currentInterval) {
        window.clearInterval(item.widgetData.currentInterval)
      }
    }

    const tempItems = _.reject(items, (num) => num.i === itemid)
    tempItems.map((item) => {
      if (item.widgetData.currentInterval) {
        window.clearInterval(item.widgetData.currentInterval)
      }
      if (item.widgetData.data_service.refreshInterval &&
          item.widgetData.data_service.refreshInterval !== '0') {
        const interval =
          parseInt(item.widgetData.data_service.refreshInterval) * 60000

        const currentInterval = window
          .setInterval(() => getChartSingleChartData(item, tempItems), interval)
        item.widgetData.currentInterval = currentInterval
      }
    })

    currentItems = []
    currentItems = tempItems
    // if (!tempItems.length) {
    saveLayoutToDataBase(tempItems, 'admin')
    // }
    setLayout(tempItems)
    setItems(tempItems)
  }
  const setEditName = (el, i) => {
    el.isEditname = true
    for (const j in items) {
      if (items[j].i === i) {
        items[j].isEditname = true
        if (!items[j].displayname) {
          items[j].displayname = items[j].widgetTitle
        }
      }
    }
    setItems([...items])
  }
  const [anchorEl, setAnchorEl] = React.useState(null)
  const openMenu = Boolean(anchorEl)
  const handleClick = (event, i) => {
    setAnchorEl(event.currentTarget)
    setEditTabIndex(i)
  }
  const changeSummaryWidgetName = (e, configdata, widget) => {
    const tempData = configdata || currentConfiguration
    tempData[widget].displayname = e.target.value

    const tempData1 = formData
    Object.keys(formData).map((val1, idx1) => {
      if (val1 === e.target.name) {
        formData[val1] = e.target.value
      }
    })
    setcurrentConfiguration(tempData)
    if (configdata) {
      setFormData({
        ...tempData1
      })
    }
  }
  const changeiFrameWidgetName = (e, configdata) => {
    const tempData = configdata || currentConfiguration
    tempData.displayname = e.target.value

    const tempData1 = formData
    Object.keys(formData).map((val1, idx1) => {
      if (val1 === e.target.name) {
        formData[val1] = e.target.value
      }
    })
    setcurrentConfiguration(tempData)
    if (configdata) {
      setFormData({
        ...tempData1
      })
    }
  }
  const changePowerBiWidgetName = (e, configdata) => {
    const tempData = configdata || currentConfiguration
    tempData.value = e.target.value

    const tempData1 = formData
    Object.keys(formData).map((val1, idx1) => {
      if (val1 === e.target.name) {
        formData[val1] = e.target.value
      }
    })
    setcurrentConfiguration(tempData)
    if (configdata) {
      setFormData({
        ...tempData1
      })
    }
  }
  const changeWidgetName = (e, configdata) => {
    const tempData = configdata || currentConfiguration
    tempData.displayname = e.target.value

    const tempData1 = formData
    Object.keys(formData).map((val1, idx1) => {
      if (val1 === e.target.name) {
        formData[val1] = e.target.value
      }
    })
    setcurrentConfiguration(tempData)
    if (configdata) {
      setFormData({
        ...tempData1
      })
    }
  }
  const handlePublishWidget = (widgetdata) => {
    setPublishWidget(widgetdata)
    setPublishAlert(true)
  }
  const checkSummaryExist = (tabs1) => {
    let result = false
    tabs1.map((item) => {
      item.widgets.map((widget) => {
        if (widget.widgetTitle === 'Summary') {
          result = true
        }
      })
    })
    return result
  }
  const confirmPublishWidget = () => {
    setPublishAlert(false)
    DashboardService.getRssLink(publishWidget, props.match.params.id,
      dashboardTitle)
      .then((response) => {
        setOpenPublishLink(true)
        setRssLink(response.data)
        const tabs1 = cloneDeep(tabs)
        for (let i = 0; i < tabs1.length; i++) {
          delete tabs1[i].edit
          for (let j = 0; j < tabs1[i].widgets.length; j++) {
          //  layout[i].chartData = items[i].chartData
            delete tabs1[i].widgets[j].chartData
            const widgetdata = tabs1[i].widgets[j].widgetData
            if (widgetdata.data_service.properties && widgetdata.data_service
              .properties.filters && widgetdata.data_service.properties
              .filters.length) {
            //     widgetdata.data_service.properties.filters.map((d1, i1) => {
              widgetdata.data_service.properties.filters[0].value = []
              //     })
            }
            tabs1[i].widgets[j].widgetData = widgetdata
          }
        }
        tabs1[currentTab.index].widgets.map((item) => {
          if (item.widgetTitle === 'Summary') {
            item.published_date = new Date()
          }
        })
        const formData = {
          id: props.match.params.id,
          widget: tabs1
        }
        DashboardService.saveLayoutToDataBase(formData).then((response) => {
          setNameModal(false)
          setEditTabIndex(null)
        })
        // window.open(response.data)
      })
  }
  const handleRssClose = () => {
    setOpenPublishLink(false)
    setRssLink(null)
  }
  const saveWidgetName = (el) => {
    for (const j in items) {
      if (items[j].i === el.i) {
        delete items[j].isEditname
      }
    }
    // setItems([...items])
    saveLayoutToDataBase(items)
  }
  // async function getchartdata (widgets) {
  const getchartdata = async (widgets) => {
    setLoading(true)
    setTabLoading(true)
    widgets.map((item) => {
      item.chartData = []
      item.loading = true
    })
    setLoading(false)
    await Promise.all(widgets.map(async (item, i) => {
      item.chartData = await getDataPromise({
        d: item.widgetData.data_service
      }, i, widgets)
      if (item.widgetTitle === 'Average Velocity') {
        item.chartData.map((chart) => {
          chart.Average = Math.round(chart.Average)
          chart.Target = item.widgetData.data_service.target
        })
      }
      // setItems(widgets)
      // setLoading(false)
      //  setItems(widgets)
    }))
    setTabLoading(false)
    await Promise.all(widgets.map(async (item) => {
      if (item.widgetData.data_service.refreshInterval &&
          item.widgetData.data_service.refreshInterval !== '0') {
        const interval =
          parseInt(item.widgetData.data_service.refreshInterval) * 60000

        const currentInterval = window
          .setInterval(() => getChartSingleChartData(item, widgets), interval)
        item.widgetData.currentInterval = currentInterval
      }
    }))
  }
  const getChartSingleChartData = async (widget, widgets) => {
    setLoading(true)
    const tempData = await getDataPromise({
      d: widget.widgetData.data_service
    })

    widgets.map((item) => {
      if (item.i === widget.i) {
        item.chartData = tempData
      }
      //  setItems(widgets)
    })
    const tempItems = widgets
    setItems([...tempItems])

    setLoading(false)
  }

  const getDataPromise = (value, i, widgets) => new Promise((resolve,
    reject) => {
    // if (value.d.parameters.name || value.d.parameters.board_name) {
    if (Object.keys(value.d.parameters).length !== 0) {
      if (Object.keys(value.d.parameters).length === 1 &&
        value.d.parameters.type) {
        if (widgets) {
          widgets[i].chartData = []
          widgets[i].loading = false
          setItems([...widgets])
        }
        resolve([])
      } else {
        DashboardService.getChartData(value).then((response) => {
          if (value.d.chartType === 'table' && !value.d.chartType.message) {
            const data = setHyperLinks(response.data)
            if (widgets) {
              widgets[i].chartData = data
              widgets[i].loading = false
              setItems([...widgets])
            }
            resolve(data)
          } else {
            if (widgets) {
              widgets[i].chartData = response.data
              widgets[i].loading = false
              setItems([...widgets])
            }
            resolve(response.data)
          }
        })
      }
    } else if (!value.d.config) {
      DashboardService.getChartData(value).then((response) => {
        if (widgets) {
          widgets[i].chartData = response.data
          widgets[i].loading = false
          setItems([...widgets])
        }
        resolve(response.data)
      })
    } else {
      if (widgets) {
        widgets[i].chartData = []
        widgets[i].loading = false
        setItems([...widgets])
      }
      resolve([])
    }
  })
  const loadSingleDashboard = (initial) => {
    DashboardService.loadSingleDashboard(props.match.params.id)
      .then((response) => {
        const widgets = response.data[0].widget
        let tabs = []
        if (widgets && widgets.length) {
          if (!widgets[0].widgets) {
            tabs.push({
              tabname: 'Tab1',
              widgets,
              index: 0
            })
          } else {
            tabs = widgets
          }
          setTabs(tabs)
          setCurrentTab(tabs[0])
          currentItems = tabs[0].widgets
          getchartdata(currentItems)
        } else {
          const tabs = []
          tabs.push({
            tabname: 'Tab1',
            widgets: [],
            index: 0
          })
          setTabs(tabs)
          setCurrentTab(tabs[0])
          setItems(widgets)
        }
        if (initial) {
          loadAllWdigets(tabs)
        }
        setDashboardTitle(response.data[0].dashboard_name)
      })
  }

  const handleToggle = (value) => {
    const widget = cloneDeep(value)
    let newwidget
    let ivalue
    if (!items) {
      setFormData({
        name: '',
        project_key: '',
        chartType: '',
        branch_name: '',
        type: 'Project',
        issuetype: '',
        jql: '',
        jql1: '',
        jql2: '',
        fromstatus: '',
        tostatus: '',
        sprint_name: '',
        page_title: '',
        space_key: '',
        displayname: ''
      })
      ivalue = `n${newCounter}`
      let width = 3
      let height = 2
      if (widget.data_service.chartType === 'table') {
        width = 6
        height = 5
      } else if (widget.name === 'Summary') {
        width = 6
        height = 4
      } else if (widget.name === 'Embed Iframe Widget') {
        width = 10
        height = 4
      }
      newwidget = {
        i: `n${newCounter}`,
        x: (1 * 3) % (cols || 12),
        y: 0, // puts it at the bottom
        w: width,
        h: height,
        widgetData: widget,
        tab_name: currentTab.tabname,
        chartData: []
      }
      // setItems([{
      //   i: `n${newCounter}`,
      //   x: (1 * 3) % (cols || 12),
      //   y: 0, // puts it at the bottom
      //   w: widget.data_service.chartType === 'table' ? 6 : 3,
      //   h: widget.data_service.chartType === 'table' ? 5 : 2,
      //   widgetData: widget,
      //   tab_name: currentTab.tabname,
      //   chartData: []
      // }])
      setNewItem(newwidget)
      setdisplayFields({
        name: 'Repos',
        branch_name: 'Branch',
        project_key: 'Key'
      })
      setNewCounter(newCounter + 1)
      openEditMenu(ivalue, widget)
    } else {
      let counter = newCounter
      if (counter === 0) {
        counter += 1
      }
      counter = items.length
      setFormData({
        name: '',
        project_key: '',
        chartType: '',
        branch_name: '',
        type: 'Project',
        issuetype: '',
        jql: '',
        jql1: '',
        jql2: '',
        fromstatus: '',
        tostatus: '',
        sprint_name: '',
        page_title: '',
        space_key: '',
        displayname: ''
      })

      // const itemsAfterContact = items.concat({
      //   i: `n${counter}`,
      //   x: (items.length * 3) % (cols || 12),
      //   y: 0, // puts it at the bottom
      //   w: widget.data_service.chartType === 'table' ? 6 : 3,
      //   h: widget.data_service.chartType === 'table' ? 5 : 2,
      //   widgetData: widget,
      //   tab_name: currentTab.tabname,
      //   chartData: []
      // })
      ivalue = `n${counter}`
      let width = 3
      let height = 2
      if (widget.data_service.chartType === 'table') {
        width = 6
        height = 5
      } else if (widget.name === 'Summary') {
        width = 6
        height = 4
      } else if (widget.name === 'Embed Iframe Widget') {
        width = 10
        height = 4
      }
      newwidget = {
        i: `n${counter}`,
        x: (items.length * 3) % (cols || 12),
        y: 0, // puts it at the bottom
        w: width,
        h: height,
        widgetData: widget,
        tab_name: currentTab.tabname,
        chartData: []
      }
      setNewItem(newwidget)
      // setItems([...itemsAfterContact])
      setdisplayFields({
        name: 'Repos',
        branch_name: 'Branch',
        project_key: 'Key'
      })
      setNewCounter(newCounter + 1)
      if (!widget.data_service.config) {
        const object = new Object()
        object.d = widget.data_service
        DashboardService.getChartData(object).then((response) => {
          const tempData = items.concat({
            i: `n${counter}`,
            x: (items.length * 3) % (cols || 12),
            y: 0, // puts it at the bottom
            w: 3,
            h: 2,
            widgetData: widget,
            chartData: response.data,
            widgetTitle: widget.name,
            chartType: widget.data_service.chartType
          })

          tempData.map((item) => {
            if (item.widgetData.currentInterval) {
              window.clearInterval(item.widgetData.currentInterval)
            }
            if (item.widgetData.data_service.refreshInterval &&
                item.widgetData.data_service.refreshInterval !== '0') {
              const interval =
                parseInt(item.widgetData.data_service.refreshInterval) * 60000

              const currentInterval = window
                .setInterval(() => getChartSingleChartData(item,
                  tempData), interval)
              item.widgetData.currentInterval = currentInterval
            }
          })

          setItems([...tempData])
          saveLayoutToDataBase(tempData, 'admin')
          handleConfiguraitonClose()
        })
      } else {
        openEditMenu(ivalue, widget)
      }

      // itemsAfterContact.map((item) => {
      //   if (item.widgetData.currentInterval) {
      //     window.clearInterval(item.widgetData.currentInterval)
      //   }
      //   if (item.widgetData.data_service.refreshInterval &&
      //       item.widgetData.data_service.refreshInterval !== '0') {
      //     const interval =
      //       parseInt(item.widgetData.data_service.refreshInterval) * 60000

      //     const currentInterval = window
      //       .setInterval(() => getChartSingleChartData(item,
      //         itemsAfterContact), interval)
      //     item.widgetData.currentInterval = currentInterval
      //   }
      // })
    }
  }

  const loadAllWdigets = (tabs1) => {
    DashboardService.getAllWidgets().then((response) => {
      const allWidgetsData = response.data
      const tools1 = tools
      const data1 = []
      allWidgetsData.map((item) => {
        if (!tools1.includes(item.data_service.tool_name) &&
          ((!user.isAdmin && !item.data_service.admin) || (user.isAdmin))) {
          if (item.data_service.tool_name.toLowerCase() === 'confluence') {
            tools.splice(1, 0, item.data_service.tool_name)
          } else {
            tools.push(item.data_service.tool_name)
          }
        }
        if (!user.isAdmin && !item.data_service.admin) {
          if (item.name !== 'Summary') {
            data1.push(item)
          } else if (item.name === 'Summary' && !checkSummaryExist(tabs1)) {
            data1.push(item)
          }
        } else if (user.isAdmin) {
          if (item.name !== 'Summary') {
            data1.push(item)
          } else if (item.name === 'Summary' && !checkSummaryExist(tabs1)) {
            data1.push(item)
          }
        }
      })
      setTools(tools)
      setWidgets(data1)
    })
  }

  const loadInitialFilter = () => {
    const currentFilterData = currentConfiguration
      .data_service.properties.filters[0]
    const object = new Object()
    currentFilterData.parameter_value = object
    DashboardService.loadChildData(currentFilterData).then((response) => {
      currentFilterData.value = response.data
      const currentConfiguration1 = currentConfiguration
      currentConfiguration1.data_service.properties.filters[0].value =
        response.data
      setcurrentConfiguration(currentConfiguration1)
      setEditOpen(true)
    })
  }
  const renderCustomizedLabel = (props) => {
    const {
      x, y, width, height, value
    } = props
    const radius = 10

    return (
      <g>
        <circle cx={x + width / 2} cy={y - radius} r={radius} fill='#8884d8' />
        <text
          x={x + width / 2}
          y={y - radius}
          fill='#fff'
          textAnchor='middle'
          dominantBaseline='middle'
        >
          {value.split(' ')[1]}
        </text>
      </g>
    )
  }
  const openEditMenu = (i, widget) => {
    setwidgetErrorMessage(null)
    const formData = {
      name: '',
      project_key: '',
      chartType: '',
      branch_name: '',
      fromstatus: '',
      tostatus: '',
      refreshInterval: ''
    }

    const currentData = []
    const currentWidget1 = []
    if (widget) {
      handleClose()
      widget.displayname = widget.name
      currentData.push(widget)
      currentWidget1.push(i)
    } else {
      items.filter((item) => item.i === i).map((filteredName) => {
        /* eslint no-sequences: 0 */
        currentData.push(filteredName.widgetData)
        currentData[0].displayname = filteredName.displayname || filteredName
          .widgetTitle
        currentWidget1.push(i)
      })
    }
    currentData[0].id = i
    const tempData1 = formData
    tempData1.name = currentData[0].name
    if (typeof currentData[0].data_service.parameters.isWildCard ===
      'undefined') {
      tempData1.isWildCard = currentData[0].data_service.parameters.isWildCard
    }
    if (currentData[0].data_service.refresh_enable) {
      tempData1.refresh_interval_values =
        currentData[0].data_service.refresh_interval_values
    }
    tempData1.chartType = currentData[0].data_service.chartType
    if (currentData[0].data_service.timeScale) {
      tempData1.timeScale = currentData[0].data_service.timeScale
    }
    if (currentData[0].data_service.target) {
      tempData1.target = currentData[0].data_service.target
    }
    if (currentData[0].data_service.value) {
      tempData1.value = currentData[0].data_service.value
    }
    if (currentData[0].data_service.refreshInterval) {
      tempData1.refreshInterval = currentData[0].data_service.refreshInterval
    }
    if (Object.keys(currentData[0].data_service.parameters).length !== 0) {
      //  tempData1.chartType = currentData[0].chartType
      Object.keys(currentData[0].data_service.parameters).map((val1, idx1) => {
        tempData1[val1] = currentData[0].data_service.parameters[val1]
      })
      setFormData(tempData1)
    } else {
      setFormData({
        name: '',
        project_key: '',
        chartType: tempData1.chartType,
        branch_name: '',
        type: 'Project',
        issuetype: '',
        jql: '',
        jql1: '',
        jql2: '',
        fromstatus: '',
        tostatus: '',
        sprint_name: '',
        page_title: '',
        space_key: '',
        displayname: ''
      })
    }
    // whichData concept came due to the chart No of story point per project or board have Radio button to select
    if (currentData[0].data_service &&
      currentData[0].data_service.properties &&
      currentData[0].data_service.properties.filters) {
      let whichData = 0
      whichData = currentData[0].data_service
        .properties.filters[0].service_name !== '' ? 0 : 1
      const currentFilterData = currentData[0].data_service
        .properties.filters[whichData]
      const object = new Object()
      //  object[e.target.name] = e.target.value;
      currentFilterData.parameter_value = object
      if (currentData[0].data_service.properties.filters[0].name !== 'jql' &&
      currentData[0].data_service.properties.filters[0].name !== 'jql1') {
        DashboardService.loadChildData(currentFilterData).then((response) => {
          currentData[0].data_service.properties.filters[whichData].value =
            response.data
          currentData[0].chartType = tempData1.chartType
          if (currentData[0].name === 'Summary' && !currentData[0].widget1) {
            currentData[0].widget1 = {
              displayname: '',
              value: ''
            }
            currentData[0].widget2 = {
              displayname: '',
              value: ''
            }
            currentData[0].widget3 = {
              displayname: '',
              value: ''
            }
            currentData[0].widget4 = {
              displayname: '',
              value: ''
            }
          }
          const data1 = cloneDeep(currentData[0])
          setcurrentConfiguration({
            ...data1
          })
          setcurrentWidget(currentWidget1[0])
          // setFormData(tempData1)
          if (currentData[0].name === 'Summary') {
            setSummaryEditOpen(true)
          } else if (currentData[0].name === 'Embed Iframe Widget') {
            setPowerBiOpen(true)
          } else {
            setEditOpen(true)
          }
        })
      } else {
        currentData[0].data_service.properties.filters[whichData].value = []
        currentData[0].chartType = tempData1.chartType
        if (currentData[0].name === 'Summary' && !currentData[0].widget1) {
          currentData[0].widget1 = {
            displayname: '',
            value: ''
          }
          currentData[0].widget2 = {
            displayname: '',
            value: ''
          }
          currentData[0].widget3 = {
            displayname: '',
            value: ''
          }
          currentData[0].widget4 = {
            displayname: '',
            value: ''
          }
        }
        const data1 = cloneDeep(currentData[0])
        setcurrentConfiguration({
          ...data1
        })
        setcurrentWidget(currentWidget1[0])
        // setFormData(tempData1)
        if (currentData[0].name === 'Summary') {
          setSummaryEditOpen(true)
        } else if (currentData[0].name === 'Embed Iframe Widget') {
          setPowerBiOpen(true)
        } else {
          setEditOpen(true)
        }
      }
    } else {
      currentData[0].chartType = tempData1.chartType
      if (tempData1.value) {
        currentData[0].value = tempData1.value
      }
      if (currentData[0].name === 'Summary' && !currentData[0].widget1) {
        currentData[0].widget1 = {
          displayname: 'title1',
          value: ''
        }
        currentData[0].widget2 = {
          displayname: 'title2',
          value: ''
        }
        currentData[0].widget3 = {
          displayname: 'title3',
          value: ''
        }
        currentData[0].widget4 = {
          displayname: 'title4',
          value: ''
        }
      }
      const data1 = cloneDeep(currentData[0])
      setcurrentConfiguration({
        ...data1
      })
      setcurrentWidget(currentWidget1[0])
      // setFormData(tempData1)
      if (currentData[0].name === 'Summary') {
        setSummaryEditOpen(true)
      } else if (currentData[0].name === 'Embed Iframe Widget') {
        setPowerBiOpen(true)
      } else {
        setEditOpen(true)
      }
    }
  }

  const addTabs = () => {
    tabs.push({
      tabname: `Tab${tabs.length + 1}`,
      index: tabs.length,
      widgets: []
    })
    setTabs([...tabs])
    const tabs1 = cloneDeep(tabs)
    for (let i = 0; i < tabs1.length; i++) {
      delete tabs1[i].edit
      for (let j = 0; j < tabs1[i].widgets.length; j++) {
      //  layout[i].chartData = items[i].chartData
        delete tabs1[i].widgets[j].chartData
        const widgetdata = tabs1[i].widgets[j].widgetData
        if (widgetdata.data_service.properties && widgetdata.data_service
          .properties.filters && widgetdata.data_service.properties
          .filters.length) {
        //     widgetdata.data_service.properties.filters.map((d1, i1) => {
          widgetdata.data_service.properties.filters[0].value = []
          //     })
        }
        tabs1[i].widgets[j].widgetData = widgetdata
      }
    }
    const formData = {
      id: props.match.params.id,
      widget: tabs1
    }
    DashboardService.saveLayoutToDataBase(formData).then((response) => {
    })
  }

  const selectTab = (tab) => {
    setCurrentTab(tab)
    setItems([])
    if (tab.widgets.length) {
      currentItems = tab.widgets
      getchartdata(currentItems)
      setItems(tab.widgets)
      setLayout(tab.widgets)
    } else {
      setLayout([])
      setItems(tab.widgets)
    }
  }
  const editTabName = () => {
    setNameModal(true)
    tabs[editTabIndex].edit = true
    setNewTabName(tabs[editTabIndex].tabname)
    setTabs([...tabs])
    handleTabClose()
  }
  const deleteTab = () => {
    tabs.splice(editTabIndex, 1)
    handleTabClose()
    tabs.map((tab, i) => {
      if (
        tabs[i].tabname === `Tab${i + 2}`) {
        tabs[i].tabname = `Tab${i + 1}`
      }
      tabs[i].index = i
    })
    setTabs([...tabs])
    if (currentTab.index === editTabIndex) {
      selectTab(tabs[0])
    }
    setTabDeleteAlert(false)
    const tabs1 = cloneDeep(tabs)
    for (let i = 0; i < tabs1.length; i++) {
      delete tabs1[i].edit
      if (tabs1[i] &&
        tabs1[i].tab_name === `Tab${i + 2}`) {
        tabs1[i].tab_name = `Tab${i + 1}`
      }
      for (let j = 0; j < tabs1[i].widgets.length; j++) {
        //  layout[i].chartData = items[i].chartData
        delete tabs1[i].widgets[j].chartData
        const widgetdata = tabs1[i].widgets[j].widgetData
        if (widgetdata.data_service.properties) {
          //     widgetdata.data_service.properties.filters.map((d1, i1) => {
          widgetdata.data_service.properties.filters[0].value = []
          //     })
        }
        tabs1[i].widgets[j].widgetData = widgetdata
      }
    }
    const formData = {
      id: props.match.params.id,
      widget: tabs1
    }
    DashboardService.saveLayoutToDataBase(formData).then((response) => {
      setEditTabIndex(null)
    })
  }
  const handleTabClose = () => {
    setAnchorEl(null)
  }

  const saveTabName = (value) => {
    // items.map((value) => {
    //   if(value.tabname && value.tabname === tabs[i].tabname){
    //     value.tabname = e.target.value
    //   }

    // })

    if (currentTab.index === tabs[editTabIndex].index) {
      setCurrentTab({
        ...currentTab,
        tabname: value
      })
    }
    tabs[editTabIndex].edit = false
    tabs[editTabIndex].tabname = value
    setTabs([...tabs])
    const tabs1 = cloneDeep(tabs)
    for (let i = 0; i < tabs1.length; i++) {
      delete tabs1[i].edit
      for (let j = 0; j < tabs1[i].widgets.length; j++) {
      //  layout[i].chartData = items[i].chartData
        delete tabs1[i].widgets[j].chartData
        const widgetdata = tabs1[i].widgets[j].widgetData
        if (widgetdata.data_service.properties && widgetdata.data_service
          .properties.filters && widgetdata.data_service.properties
          .filters.length) {
        //     widgetdata.data_service.properties.filters.map((d1, i1) => {
          widgetdata.data_service.properties.filters[0].value = []
          //     })
        }
        tabs1[i].widgets[j].widgetData = widgetdata
      }
    }
    const formData = {
      id: props.match.params.id,
      widget: tabs1
    }
    DashboardService.saveLayoutToDataBase(formData).then((response) => {
      setNameModal(false)
      setEditTabIndex(null)
    })
  //  setItems([...items])
  }
  const createElementList = () => {
    if (widgets) {
      return (
        <Grid container spacing={2} direction='row'>
          {tools.map((toolname) => (
            <Grid item xs={12 / tools.length}>
              <Typography display='block' gutterBottom>{toolname}</Typography>
              <Paper>
                <List>
                  {widgets.map((value) => {
                    const labelId = `checkbox-list-label-${value.name}`
                    return (
                      (value.data_service.tool_name === toolname ? (
                        <ListItem
                          key={value.name}
                          role={undefined}
                          dense
                          button
                          onClick={() => handleToggle(value)}
                        >

                          <ListItemText
                            id={labelId}
                            primary={(
                              <div>
                                {' '}
                                {value.name}
                                {value.daydiff < 30 &&
                                  <sup><b><i>&nbsp;&nbsp;New</i></b></sup>}

                              </div>
                            )}
                          />
                          <TtoolTip title={(
                            <span style={{
                              fontSize: 12,
                              fontFamily: 'Verdana'
                            }}
                            >
                              {value.data_service.tooltip}
                            </span>
                          )}
                          >
                            <InfoIcon
                              fontSize='small'
                              className='pull-left'
                              style={value.data_service.admin ? {
                                color: 'rgb(100, 216, 163)'
                              } : {
                                color: '#eea508'
                              }}
                            />
                          </TtoolTip>
                        </ListItem>
                      ) : null)
                    )
                  })}
                </List>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )
    }
    return (<h4>no widgets</h4>)
  }

  const getMaxWidth = (i) => {
    const isCollapbled1 = document.querySelector(`div.widgetBody.item-${i}`)
    if (isCollapbled1 && isCollapbled1.clientWidth) {
      return isCollapbled1.clientWidth - 100
    }
  }
  const getMaxHeight = (id) => {
    const container = document.querySelector(`div.${id}`)
    if (container && container.clientHeight) {
      return container.clientHeight - 50
    }
  }
  const CustomTooltip = ({
    active, payload, label
  }) => {
    if (active) {
      const value = Math.round(payload[0].value)
      const date = moment(label).format('ll')
      return (
        <div className='custom-tooltip'>
          {payload.map((item, index) => <p className='name'>{item.name}</p>)}
          {payload.map((item, index) => (
            <p className='label'>
              {date}
              {' '}
              :
              {' '}
              {Math.round(item.value)}
            </p>
          ))}

        </div>
      )
    }

    return null
  }

  const createElement = (el) => {
    const removeStyle = {
      position: 'absolute',

      right: '2px',
      top: 0,
      cursor: 'pointer'
    }
    const i = el.add ? '+' : el.i
    const valuearray = []
    if (el.widgetTitle === 'Page Views') {
      if (typeof el.widgetData.data_service.parameters.isWildCard ===
          'undefined') {
        el.widgetData.data_service.parameters.isWildCard = true
      }
    }
    if (el.chartData && el.chartData.length) {
      el.chartData.map((item) => (
        valuearray.push(item.value)
      ))
      el.chartData.maxvalue = Math.max(...valuearray)
    }
    const object = {
    }
    if (el.widgetData.data_service.properties && el.widgetData
      .data_service.properties.filters) {
      el.widgetData.data_service.properties.filters.map((item) => {
        object[item.name] = item.label
      })
    }
    if (object.name === 'Boards' && !object.type) {
      delete object.project_key
    }
    if (object.type) {
      delete object.type
    }
    //  el.chartData.displayFields = { name: 'Repos', branch_name: 'Branch', project_key: 'Key' }
    if (el.chartData !== null) {
      el.chartData.displayFields = object
    }
    if (el.chartData && el.chartType !== 'richtext' &&
    el.chartData && el.chartType !== 'html' && el.chartData
      .length === 0) {
      return (
        <div className={`item widgetBody item${i}`} key={i} data-grid={el}>
          <div className='widgetHeader'>
            {/* </Grid> */}
            {owner && (
              // <Grid item xs={3}>
              <span>
                <IconButton
                  className='pull-right widgetButton'
                  aria-label='close'
                  onMouseDown={() => onRemoveItem(i)}
                  style={{
                    float: 'right'
                  }}
                >
                  <CloseIcon style={removeStyle} fontSize='small' />
                </IconButton>
                {el.widgetData.data_service.config && (
                  <IconButton
                    className='pull-right widgetButton'
                    aria-label='close'
                    onMouseDown={() => openEditMenu(i)}
                    style={{
                      float: 'right'
                    }}
                  >
                    <SettingsIcon style={removeStyle} fontSize='small' />
                  </IconButton>
                )}
              </span>
              // </Grid>
            )}
            <Typography
              variant='caption'
              className='widgetTitle'
              title={el.displayname || el.widgetTitle}
              style={{
                width: getMaxWidth(i)
              }}
              fontSize='small'
              component='div'
            >
              {el.widgetData.name}
            </Typography>
          </div>
          <ResponsiveContainer>
            <h4 className='widgetNoData'>
              {el.loading ? 'Loading...' : 'No Data to Display'}
            </h4>
          </ResponsiveContainer>
        </div>
      )
    } if (el.widgetTitle === 'Summary') {
      return (
        <div className={`item widgetBody item-${i}`} key={i} data-grid={el}>
          {/* <Grid container spacing={3}>
            <Grid item xs={9}> */}
          <div
            className='widgetHeader'
            style={{
              height: 40
            }}
          >
            {owner && (
              // <Grid item xs={3}>
              <span>
                <IconButton
                  className='pull-right widgetButton'
                  aria-label='close'
                  onMouseDown={() => onRemoveItem(i)}
                  style={{
                    float: 'right'
                  }}
                >
                  <CloseIcon style={removeStyle} fontSize='small' />
                </IconButton>
                {el.widgetData.data_service.config && (
                  <IconButton
                    className='pull-right widgetButton'
                    aria-label='close'
                    onMouseDown={() => openEditMenu(i)}
                    style={{
                      float: 'right'
                    }}
                  >
                    <SettingsIcon style={removeStyle} fontSize='small' />
                  </IconButton>
                )}
              </span>
              // </Grid>
            )}
            <TtoolTip
              title={(
                <span style={{
                  fontSize: 12,
                  fontFamily: 'Verdana'
                }}
                >
                  {el.widgetData.data_service.tooltip}
                </span>
              )}
              className='pull-right widgetButton'
            >
              <InfoIcon
                fontSize='small'
                style={{
                  color: '#eea508'
                }}
              />
            </TtoolTip>
            <TtoolTip
              title={(
                <span style={{
                  fontSize: 12,
                  fontFamily: 'Verdana'
                }}
                >
                  Publish to RSS Feed
                </span>
              )}
              className='pull-right widgetButton'
            >
              {/* <PublishIcon
                fontSize='small'
                color='primary'
                onClick={() => handlePublishWidget(el.widgetData)}
              /> */}
              <span onClick={() => handlePublishWidget(el.widgetData)}>
                <img
                  src={rsslogo}
                  alt=''
                  className={classes.image}
                />
              </span>
            </TtoolTip>
            <Typography
              variant='caption'
              className='widgetTitle'
              style={{
                width: getMaxWidth(i) - 20
              }}
              title={el.displayname || el.widgetTitle}
              fontSize='small'
              component='div'
            >
              {el.displayname || el.widgetTitle}
            </Typography>
            {el.published_date ? (
              <div
                className='widgetSubTitle'
                fontSize='small'
                style={{
                  marginTop: -10
                }}
              >
                <span>
                Last published on
                  {' '}
                  {moment(el.published_date).format('MMMM Do YYYY, h:mm:ss a')}
                </span>
              </div>
            ) : null}
            {/* </Grid> */}
          </div>
          {/* </Grid> */}
          <ResponsiveContainer>
            <Grid container spacing={2} className='summary-container'>
              <Grid
                item
                xs={6}
                style={{
                  height: '50%'
                }}
              >
                <b style={{
                  marginLeft: 15
                }}
                >
                  {el.widgetData && el.widgetData.widget1 &&
                  el.widgetData.widget1.displayname}
                </b>
                <div className='ql-editor widgeteditor summary-widget'>
                  {el.widgetData && el.widgetData.widget1 &&
                  parseHtml(el.widgetData.widget1.value)}
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  height: '50%'
                }}
              >
                <b style={{
                  marginLeft: 15
                }}
                >
                  {el.widgetData && el.widgetData.widget2 &&
                  el.widgetData.widget2.displayname}
                </b>
                <div className='ql-editor widgeteditor summary-widget'>
                  {parseHtml(el.widgetData && el.widgetData.widget2 &&
                    el.widgetData.widget2.value)}
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  height: '50%',
                  marginTop: 10
                }}
              >
                <b style={{
                  marginLeft: 15
                }}
                >
                  {el.widgetData && el.widgetData.widget3 &&
                  el.widgetData.widget3.displayname}
                </b>
                <div className='ql-editor widgeteditor summary-widget'>
                  {parseHtml(el.widgetData && el.widgetData.widget3 &&
                    el.widgetData.widget3.value)}
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  height: '50%',
                  marginTop: 10
                }}
              >
                <b style={{
                  marginLeft: 15
                }}
                >
                  {el.widgetData && el.widgetData.widget4 &&
                  el.widgetData.widget4.displayname}
                </b>
                <div className='ql-editor widgeteditor summary-widget'>
                  {parseHtml(el.widgetData && el.widgetData.widget4 &&
                    el.widgetData.widget4.value)}
                </div>
              </Grid>
            </Grid>
            {/* <div className='summary-container'>
              <div className='col-md-6 ql-editor widgeteditor summary-widget'>
                {parseHtml(el.widgetData.value)}
              </div>
              <div className='col-md-6 ql-editor widgeteditor summary-widget'>
                {parseHtml(el.widgetData.value)}
              </div>
              <div className='col-md-6 ql-editor widgeteditor summary-widget'>
                {parseHtml(el.widgetData.value)}
              </div>
              <div className='col-md-6 ql-editor widgeteditor summary-widget'>
                {parseHtml(el.widgetData.value)}
              </div>
            </div> */}
          </ResponsiveContainer>

        </div>
      )
    } if (el.chartType === 'html') {
      return (
        <div className={`item widgetBody item-${i}`} key={i} data-grid={el}>
          {/* <Grid container spacing={3}>
            <Grid item xs={9}> */}
          <div className='widgetHeader'>
            {owner && (
              // <Grid item xs={3}>
              <span>
                <IconButton
                  className='pull-right widgetButton'
                  aria-label='close'
                  onMouseDown={() => onRemoveItem(i)}
                  style={{
                    float: 'right'
                  }}
                >
                  <CloseIcon style={removeStyle} fontSize='small' />
                </IconButton>
                {el.widgetData.data_service.config && (
                  <IconButton
                    className='pull-right widgetButton'
                    aria-label='close'
                    onMouseDown={() => openEditMenu(i)}
                    style={{
                      float: 'right'
                    }}
                  >
                    <SettingsIcon style={removeStyle} fontSize='small' />
                  </IconButton>
                )}
              </span>
              // </Grid>
            )}
            <TtoolTip
              title={(
                <span style={{
                  fontSize: 12,
                  fontFamily: 'Verdana'
                }}
                >
                  {el.widgetData.data_service.tooltip}
                </span>
              )}
              className='pull-right widgetButton'
            >
              <InfoIcon
                fontSize='small'
                style={{
                  color: '#eea508'
                }}
              />
            </TtoolTip>
            <Typography
              variant='caption'
              className='widgetTitle'
              style={{
                width: getMaxWidth(i)
              }}
              title={el.displayname || el.widgetTitle}
              fontSize='small'
              component='div'
            >
              {el.displayname || el.widgetTitle}
            </Typography>
            {/* </Grid> */}
          </div>
          {/* </Grid> */}
          <ResponsiveContainer>

            <div>
              {parseHtml(el.widgetData.value)}
            </div>
          </ResponsiveContainer>

        </div>
      )
    }
    if (el.chartType === 'richtext') {
      return (
        <div className={`item widgetBody item-${i}`} key={i} data-grid={el}>
          {/* <Grid container spacing={3}>
            <Grid item xs={9}> */}
          <div className='widgetHeader'>
            {owner && (
              // <Grid item xs={3}>
              <span>
                <IconButton
                  className='pull-right widgetButton'
                  aria-label='close'
                  onMouseDown={() => onRemoveItem(i)}
                  style={{
                    float: 'right'
                  }}
                >
                  <CloseIcon style={removeStyle} fontSize='small' />
                </IconButton>
                {el.widgetData.data_service.config && (
                  <IconButton
                    className='pull-right widgetButton'
                    aria-label='close'
                    onMouseDown={() => openEditMenu(i)}
                    style={{
                      float: 'right'
                    }}
                  >
                    <SettingsIcon style={removeStyle} fontSize='small' />
                  </IconButton>
                )}
              </span>
              // </Grid>
            )}
            <TtoolTip
              title={(
                <span style={{
                  fontSize: 12,
                  fontFamily: 'Verdana'
                }}
                >
                  {el.widgetData.data_service.tooltip}
                </span>
              )}
              className='pull-right widgetButton'
            >
              <InfoIcon
                fontSize='small'
                style={{
                  color: '#eea508'
                }}
              />
            </TtoolTip>
            <Typography
              variant='caption'
              className='widgetTitle'
              style={{
                width: getMaxWidth(i)
              }}
              title={el.displayname || el.widgetTitle}
              fontSize='small'
              component='div'
            >
              {el.displayname || el.widgetTitle}
            </Typography>
            {/* </Grid> */}
          </div>
          {/* </Grid> */}
          <ResponsiveContainer>

            <div className='ql-editor widgeteditor'>
              {parseHtml(el.widgetData.value)}
            </div>
          </ResponsiveContainer>

        </div>
      )
    } if (el.chartType === 'bar' && el.chartData.length) {
      return (
        <div className={`item widgetBody item-${i}`} key={i} data-grid={el}>
          {/* <Grid container spacing={3}>
            <Grid item xs={9}> */}
          <div className='widgetHeader'>
            {owner && (
              // <Grid item xs={3}>
              <span>
                <IconButton
                  className='pull-right widgetButton'
                  aria-label='close'
                  onMouseDown={() => onRemoveItem(i)}
                  style={{
                    float: 'right'
                  }}
                >
                  <CloseIcon style={removeStyle} fontSize='small' />
                </IconButton>
                {el.widgetData.data_service.config && (
                  <IconButton
                    className='pull-right widgetButton'
                    aria-label='close'
                    onMouseDown={() => openEditMenu(i)}
                    style={{
                      float: 'right'
                    }}
                  >
                    <SettingsIcon style={removeStyle} fontSize='small' />
                  </IconButton>
                )}
              </span>
              // </Grid>
            )}
            <TtoolTip
              title={(
                <span style={{
                  fontSize: 12,
                  fontFamily: 'Verdana'
                }}
                >
                  {el.widgetData.data_service.tooltip}
                </span>
              )}
              className='pull-right widgetButton'
            >
              <InfoIcon
                fontSize='small'
                style={{
                  color: '#eea508'
                }}
              />
            </TtoolTip>
            <Typography
              variant='caption'
              className='widgetTitle'
              title={el.displayname || el.widgetTitle}
              style={{
                width: getMaxWidth(i)
              }}
              fontSize='small'
              component='div'
            >
              {el.displayname || el.widgetTitle}
            </Typography>
            {/* </Grid> */}
          </div>
          {/* </Grid> */}
          <div className='widgetSubTitle' fontSize='small'>
            {
              el.widgetData.data_service.properties &&
              el.widgetData.data_service.properties
                .filters.map((item) => (Object.keys(el.widgetData.data_service
                  .parameters).includes(item.name)
                  ? (
                    <span>
                      <strong>
                        {el.chartData.displayFields[item.name]}
                      </strong>
                      {el.chartData.displayFields[item.name] &&
                      ` : ${el.widgetData.data_service.parameters[item.name]}`}
                      {' '}
                    </span>
                  )

                  : null))

            }

          </div>
          <ResponsiveContainer>

            <BarChart
              data={el.chartData}
              margin={{
                top: 10,
                right: 30,
                left: 30,
                bottom: 40
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name'>
                <Label
                  value={el.widgetData.data_service.label.x_axis}
                  position='bottom'
                />
              </XAxis>
              <YAxis
                domain={[0, el.chartData.maxvalue]}
                label={{
                  value: el.widgetData.data_service.label.y_axis,
                  angle: -90,
                  position: 'insideBottomLeft'
                }}
              />
              <Tooltip />
              <Bar
                type='monotone'
                dataKey='value'
                stroke='#8884d8'
                fill='#8884d8'
              />
            </BarChart>
          </ResponsiveContainer>

        </div>
      )
    } if (el.chartType === 'barChartWithMinHeight') {
      return (
        <div className={`item widgetBody item-${i}`} key={i} data-grid={el}>
          {/* <Grid container spacing={3}>
            <Grid item xs={9}> */}
          <div className='widgetHeader'>
            {owner && (
              // <Grid item xs={3}>
              <>
                <IconButton
                  className='pull-right widgetButton'
                  aria-label='close'
                  onMouseDown={() => onRemoveItem(i)}
                  style={{
                    float: 'right'
                  }}
                >
                  <CloseIcon style={removeStyle} fontSize='small' />
                </IconButton>
                {el.widgetData.data_service.config && (
                  <IconButton
                    className='pull-right widgetButton'
                    aria-label='close'
                    onMouseDown={() => openEditMenu(i)}
                    style={{
                      float: 'right'
                    }}
                  >
                    <SettingsIcon style={removeStyle} fontSize='small' />
                  </IconButton>
                )}
              </>
              // </Grid>
            )}
            <TtoolTip
              title={(
                <span style={{
                  fontSize: 12,
                  fontFamily: 'Verdana'
                }}
                >
                  {el.widgetData.data_service.tooltip}
                </span>
              )}
              className='pull-right widgetButton'
            >
              <InfoIcon
                fontSize='small'
                style={{
                  color: '#eea508'
                }}
              />
            </TtoolTip>
            <Typography
              variant='caption'
              className='widgetTitle'
              title={el.displayname || el.widgetTitle}
              style={{
                width: getMaxWidth(i)
              }}
              fontSize='small'
              component='div'
            >
              {el.displayname || el.widgetTitle}

            </Typography>
            {/* </Grid> */}
          </div>
          {/* </Grid> */}
          <div className='widgetSubTitle' fontSize='small'>
            {
              el.widgetData.data_service.properties &&
              el.widgetData.data_service.properties.filters
                .map((item) => (Object.keys(el.widgetData.data_service
                  .parameters)
                  .includes(item.name)
                  ? (
                    <span>
                      <strong>{el.chartData.displayFields[item.name]}</strong>
                      {el.chartData.displayFields[item.name] &&
                        ` : ${
                          el.widgetData.data_service.parameters[item.name]
                        }`}
                      {' '}
                    </span>
                  )
                  : null))

            }

          </div>
          <ResponsiveContainer>

            <BarChart
              width={600}
              height={300}
              data={el.chartData}
              margin={{
                top: 5,
                right: 30,
                left: 10,
                bottom: 40
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name' tick={<CustomXAxisTick />}>
                <Label
                  value={el.widgetData.data_service.label.x_axis}
                  position='bottom'
                />
              </XAxis>
              <YAxis label={{
                value: el.widgetData.data_service.label.y_axis,
                angle: -90,
                position: 'insideLeft'
              }}
              />
              <Tooltip />
              <Legend verticalAlign='top' height={24} />
              <Bar
                dataKey={el.chartData[0].key1}
                fill='#8884d8'
                minPointSize={5}
              />
              <Bar
                dataKey={el.chartData[0].key2}
                fill='#82ca9d'
                minPointSize={5}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )
    } if (el.chartType === 'SimpleLineChart') {
      return (
        <div className={`item widgetBody item-${i}`} key={i} data-grid={el}>

          {/* <Grid container spacing={3}>
            <Grid item xs={9}> */}
          <div className='widgetHeader'>
            {owner && (
              // <Grid item xs={3}>
              <>
                <IconButton
                  className='pull-right widgetButton'
                  aria-label='close'
                  onMouseDown={() => onRemoveItem(i)}
                  style={{
                    float: 'right'
                  }}
                >
                  <CloseIcon style={removeStyle} fontSize='small' />
                </IconButton>
                {el.widgetData.data_service.config && (
                  <IconButton
                    className='pull-right widgetButton'
                    aria-label='close'
                    onMouseDown={() => openEditMenu(i)}
                    style={{
                      float: 'right'
                    }}
                  >
                    <SettingsIcon style={removeStyle} fontSize='small' />
                  </IconButton>
                )}
              </>
              // </Grid>
            )}
            <TtoolTip
              title={(
                <span style={{
                  fontSize: 12,
                  fontFamily: 'Verdana'
                }}
                >
                  {el.widgetData.data_service.tooltip}
                </span>
              )}
              className='pull-right widgetButton'
            >
              <InfoIcon
                fontSize='small'
                style={{
                  color: '#eea508'
                }}
              />
            </TtoolTip>
            <Typography
              variant='caption'
              className='widgetTitle'
              title={el.displayname || el.widgetTitle}
              style={{
                width: getMaxWidth(i)
              }}
              fontSize='small'
              component='div'
            >
              {el.displayname || el.widgetTitle}
            </Typography>
            {/* </Grid> */}
          </div>
          {/* </Grid> */}

          <div className='widgetSubTitle' fontSize='small'>
            {
              el.widgetData.data_service.properties &&
                el.widgetData.data_service.properties.filters
                  .map((item) => (Object.keys(el.widgetData.data_service
                    .parameters).includes(item.name)
                    ? (
                      <span>
                        <strong>
                          {el.chartData.displayFields[item.name]}
                        </strong>
                        {el.chartData.displayFields[item.name] &&
                        `
                          : ${el.widgetData.data_service.parameters[item.name]}
                        `}
                        {' '}
                      </span>
                    )
                    : null))

            }

          </div>
          <ResponsiveContainer className={el
            .widgetTitle === 'Average Velocity' ? 'linechart' : ''}
          >
            {el.widgetTitle === 'Sprint Burndown Chart'
              ? (
                <LineChart
                  data={el.chartData}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 10,
                    bottom: 40
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis
                    domain={
                      [el.chartData[3].startDate, el.chartData[3].endDate]
                    }
                    dataKey='category'
                    type='number'
                    tickFormatter={
                      (unixTime) => moment(unixTime).format('ll')
                    }
                    allowDuplicatedCategory={false}
                  >
                    <Label
                      value={el.widgetData.data_service.label.x_axis}
                      position='bottom'
                    />
                  </XAxis>
                  <YAxis label={{
                    value: el.widgetData.data_service.label.y_axis,
                    angle: -90,
                    position: 'insideLeft'
                  }}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend verticalAlign='top' height={24} />
                  <Line
                    stroke='#d04437'
                    dot={false}
                    dataKey='value'
                    data={el.chartData[1].data}
                    name={el.chartData[1].name}
                    key={el.chartData[1].name}
                  />
                  <Line
                    stroke='#999999'
                    dot={false}
                    dataKey='value'
                    data={el.chartData[0].data}
                    name={el.chartData[0].name}
                    key={el.chartData[0].name}
                  />
                </LineChart>
              )
              : (
                <LineChart
                  data={el.chartData}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 10,
                    bottom: 40
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='name'>
                    <Label
                      value={el
                        .widgetTitle === 'Average Velocity'
                        ? el.widgetData.data_service.timeScale
                        : el.widgetData.data_service.label.x_axis}
                      position='bottom'
                    />
                  </XAxis>
                  <YAxis label={{
                    value: el.widgetData.data_service.label.y_axis,
                    angle: -90,
                    position: 'insideLeft'
                  }}
                  />
                  <Tooltip />
                  <Legend verticalAlign='top' height={24} />
                  {Object.keys(el.chartData[0]).filter((key) => key !== 'name')
                    .map((item, index) => (
                      <Line
                        type='monotone'
                        dataKey={item}
                        strokeWidth={2}
                        stroke={el.widgetTitle ===
                          'Average Velocity' ? velocityColors[item]
                          : colors[index]}
                        fill={el.widgetTitle ===
                          'Average Velocity' ? velocityColors[item]
                          : colors[index]}
                        activeDot={{
                          r: 8
                        }}
                      />
                    ))}
                </LineChart>
              )}
          </ResponsiveContainer>
          {el.widgetTitle === 'Average Velocity' && (
            <table
              align='center'
              width='70%'
              style={{
                marginTop: getMaxHeight(`item-${i}`) < 300 ? -20 : 0
              }}
            >
              <tr>
                <th align='center'>Target</th>
                <th align='center'>Average</th>
                <th align='center'>Difference</th>
              </tr>
              <tr>
                <td align='center'>
                  <b style={{
                    color: velocityColors.Target,
                    fontSize: getMaxHeight(`item-${i}`) < 300 ? 12 : 20
                  }}
                  >
                    {el.chartData[0].Target}
                     sp
                  </b>
                </td>
                <td align='center'>
                  <b style={{
                    color: velocityColors.Average,
                    fontSize: getMaxHeight(`item-${i}`) < 300 ? 12 : 20
                  }}
                  >
                    {el.chartData[0].Average}
                     sp
                  </b>
                </td>
                <td align='center'>
                  <b style={{
                    color: '#FFAC1C',
                    fontSize: getMaxHeight(`item-${i}`) < 300 ? 12 : 20
                  }}
                  >
                    {Math.round(((el.chartData[0].Average - el.chartData[0]
                      .Target) / el.chartData[0].Target) * 100)}
                     %
                  </b>
                </td>
              </tr>
            </table>
          )}
        </div>
      )
    }

    if (!el.widgetData.data_service.parameters.isWildCard &&
      el.widgetData.data_service.parameters.page_title !== 'All' &&
      el.widgetTitle === 'Page Views') {
      return (
        <div className={`item widgetBody item-${i}`} key={i} data-grid={el}>
          {/* <Grid container spacing={3}>
            <Grid item xs={9}> */}
          <div className='widgetHeader'>
            {owner && (
              // <Grid item xs={3}>
              <>
                <IconButton
                  className='pull-right widgetButton'
                  aria-label='close'
                  onMouseDown={() => onRemoveItem(i)}
                  style={{
                    float: 'right'
                  }}
                >
                  <CloseIcon style={removeStyle} fontSize='small' />
                </IconButton>
                {el.widgetData.data_service.config && (
                  <IconButton
                    className='pull-right widgetButton'
                    aria-label='close'
                    onMouseDown={() => openEditMenu(i)}
                    style={{
                      float: 'right'
                    }}
                  >
                    <SettingsIcon style={removeStyle} fontSize='small' />
                  </IconButton>
                )}
              </>
              // </Grid>
            )}
            <TtoolTip
              title={(
                <span style={{
                  fontSize: 12,
                  fontFamily: 'Verdana'
                }}
                >
                  {el.widgetData.data_service.tooltip}
                </span>
              )}
              className='pull-right widgetButton'
            >
              <InfoIcon
                fontSize='small'
                style={{
                  color: '#eea508'
                }}
              />
            </TtoolTip>
            <Typography
              variant='caption'
              className='widgetTitle'
              title={el.displayname || el.widgetTitle}
              style={{
                width: getMaxWidth(i)
              }}
              fontSize='small'
              component='div'
            >
              {el.displayname || el.widgetTitle}

            </Typography>
            {/* </Grid> */}
          </div>
          {/* </Grid> */}

          <div className='widgetSubTitle' fontSize='small'>
            {
              Object.keys(el.widgetData.data_service.parameters)
                .map((keyName, keyIndex) => (
                  <span>
                    <strong>{el.chartData.displayFields[keyName]}</strong>
                    {el.chartData.displayFields[keyName] &&
                      ` : ${el.widgetData.data_service.parameters[keyName]}`}
                    {' '}
                  </span>
                ))
            }

          </div>
          <ResponsiveContainer>

            <LineChart
              data={el.chartData}
              margin={{
                top: 10,
                right: 30,
                left: 10,
                bottom: 40
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />

              <XAxis dataKey='name' />

              <Tooltip />
              <Legend verticalAlign='top' height={24} />
              <Line
                type='monotone'
                dataKey='Page Views'
                stroke='#8884d8'
                activeDot={{
                  r: 8
                }}
              />
              <Line
                type='monotone'
                dataKey='Unique Page View'
                stroke='#82ca9d'
              />

            </LineChart>

          </ResponsiveContainer>

        </div>
      )
    }

    if (el.chartType === 'pie') {
      return (
        <div className={`item widgetBody item-${i}`} key={i} data-grid={el}>
          {/* <Grid container spacing={3}>
            <Grid item xs={9}> */}
          <div className='widgetHeader'>
            {owner && (
              // <Grid item xs={3}>
              <>
                <IconButton
                  className='pull-right widgetButton'
                  aria-label='close'
                  onMouseDown={() => onRemoveItem(i)}
                >
                  <CloseIcon style={removeStyle} fontSize='small' />
                </IconButton>
                {el.widgetData.data_service.config && (
                  <IconButton
                    className='pull-right widgetButton'
                    aria-label='close'
                    onMouseDown={() => openEditMenu(i)}
                  >
                    <SettingsIcon style={removeStyle} fontSize='small' />
                  </IconButton>
                )}
              </>
              /* </Grid> */
            )}
            <TtoolTip
              title={(
                <span style={{
                  fontSize: 12,
                  fontFamily: 'Verdana'
                }}
                >
                  {el.widgetData.data_service.tooltip}
                </span>
              )}
              className='pull-right widgetButton'
            >
              <InfoIcon
                fontSize='small'
                style={{
                  color: '#eea508'
                }}
              />
            </TtoolTip>
            <Typography
              variant='caption'
              className='widgetTitle'
              title={el.displayname || el.widgetTitle}
              style={{
                width: getMaxWidth(i)
              }}
              fontSize='small'
              component='div'
            >
              {el.displayname || el.widgetTitle}

            </Typography>
            {/* </Grid> */}
          </div>
          {/* </Grid> */}
          <br />
          <ResponsiveContainer>
            <PieChart width={800} height={400}>
              <Pie
                label
                data={el.chartData}
                cx={200}
                cy={200}
                outerRadius={60}
                fill='#8884d8'
              />
            </PieChart>
          </ResponsiveContainer>

        </div>
      )
    }
    if (el.chartType === 'numberGadget') {
      return (
        <div className={`item widgetBody item-${i}`} key={i} data-grid={el}>
          {/* <Grid container spacing={3}>
            <Grid item xs={10}> */}
          <div className='widgetHeader'>
            {owner && (
              // <Grid item xs={2}>
              <>
                <IconButton
                  aria-label='close'
                  onMouseDown={() => onRemoveItem(i)}
                  style={{
                    float: 'right'
                  }}
                  className='pull-right widgetButton'
                >
                  <CloseIcon style={removeStyle} fontSize='small' />
                </IconButton>
                {el.widgetData.data_service.config && (
                  <IconButton
                    aria-label='close'
                    onMouseDown={() => openEditMenu(i)}
                    style={{
                      float: 'right'
                    }}
                    className='pull-right widgetButton'
                  >
                    <SettingsIcon style={removeStyle} fontSize='small' />
                  </IconButton>
                )}
              </>
              // </Grid>
            )}
            <TtoolTip
              title={(
                <span style={{
                  fontSize: 12,
                  fontFamily: 'Verdana'
                }}
                >
                  {el.widgetData.data_service.tooltip}
                </span>
              )}
              className='pull-right widgetButton'
            >
              <InfoIcon
                fontSize='small'
                style={{
                  color: '#eea508'
                }}
              />
            </TtoolTip>
            <Typography
              variant='caption'
              className='widgetTitle'
              title={el.displayname || el.widgetTitle}
              style={{
                width: getMaxWidth(i)
              }}
              component='div'
              fontSize='small'
            >
              {el.displayname || el.widgetTitle}
            </Typography>
            {/* </Grid> */}
          </div>
          {/* </Grid> */}
          <div className='widgetSubTitle' fontSize='small'>
            {
              el.widgetData.data_service.properties &&
              el.widgetData.data_service.properties.filters
                .map((item) => (Object.keys(el.widgetData.data_service
                  .parameters).includes(item.name)
                  ? (
                    <span>
                      <strong>{el.chartData.displayFields[item.name]}</strong>
                      {el.chartData.displayFields[item.name] &&
                      ` : ${el.widgetData.data_service.parameters[item.name]}`}
                      {' '}
                    </span>
                  )
                  : null))

            }

          </div>
          {/* <div variant='caption' className='widgetSubTitle' fontSize='small'>
            {
              Object.keys(el.widgetData.data_service.parameters).map((keyName, keyIndex) => (
                <span key='keyhead'>
                  <strong>{keyName === 'project_key' ? 'Key' : null}</strong>
                  <strong>{keyName === 'type' ? '' : null}</strong>
                  <strong>{keyName === 'name' ? 'Board' : null}</strong>
                  {' '}
                  <span>{keyName !=== 'type' ? ':' : null}</span>
                  {' '}
                  {keyName !=== 'type' ? el.widgetData.data_service.parameters[keyName] : null}
                  {' '}
                </span>
              ))

            }

          </div> */}
          <ResponsiveContainer>
            <div style={{
              paddingTop: 20
            }}
            >
              <div id='ghx-remaining-content'>
                <div className='ghx-remaining-box ghx-remaining-green'>
                  {el.widgetData.name === 'Backlog Health' && (
                    <div className='ghx-remaining-value'>
                      {el.chartData.sprints === null
                        ? 0
                        : el.chartData.sprints}
                    </div>
                  )}
                  {el.widgetData.name ===
                    'No. of Story Points in Ready Status' && (
                    <div className='ghx-remaining-value'>
                      {el.chartData.storypoints === null
                        ? 0
                        : el.chartData.storypoints}
                    </div>
                  )}
                  <div className='ghx-remaining-text'>
                    {el.widgetData.name === 'Backlog Health' && (
                      <strong>
                        Sprints ready in Backlog
                      </strong>
                    )}
                    {el.widgetData.name ===
                      'No. of Story Points in Ready Status' && (
                      <strong>
                        Story points ready in
                        {' '}
                        {el.widgetData.data_service.parameters.type}
                      </strong>
                    )}
                    {el.widgetData.name ===
                      'Cycle Time On Issues Transitions' && (
                      <div style={{
                        align: 'center'
                      }}
                      >
                        <div className='ghx-remaining-value'>
                          {el.chartData.avg.days
                            ? el.chartData.avg.days
                            : 0}
                        </div>
                        <span style={{
                          align: 'center',
                          fontSize: 14
                        }}
                        >
                          Days
                        </span>
                        <br />
                        {' '}
                        <div style={{
                          align: 'center',
                          fontSize: 14
                        }}
                        >
                          <span>
                            {el.chartData.avg.hours
                              ? el.chartData.avg.hours
                              : 0}
                          </span>
                      &nbsp;h :
                          <span>
                            {el.chartData.avg.minutes
                              ? el.chartData.avg.minutes
                              : 0}
                          </span>
                      &nbsp;m :
                          <span>
                            {el.chartData.avg.seconds
                              ? el.chartData.avg.seconds
                              : 0}
                          </span>
                      &nbsp;s
                        </div>
                      </div>
                    )}
                    {el.widgetData.name === 'Number Of Issues' && (
                      <div className='ghx-remaining-value'>
                        {el.chartData.total}
                      </div>
                    )}
                    {el.widgetData.name === 'Number Of Issues' && (
                      <strong>Issues</strong>
                    )}
                    {el.widgetData.name === 'Universal Ratio Widget' && (
                      <>
                        <div
                          className='ghx-remaining-value'
                        >
                          {
                            // eslint-disable-next-line max-len
                            parseFloat((el.chartData.JQL1Total / el.chartData.JQL2Total).toFixed(3))
                          }

                        </div>
                      </>
                    )}
                    {el.widgetData.name === 'Universal Ratio Widget' && (
                      <strong>Ratio</strong>
                    )}
                  </div>
                </div>
              </div>
              {el.widgetData.name === 'Universal Ratio Widget' && (
                <div
                  className='ghx-remaining-value'
                  style={{
                    fontSize: '1.5em',
                    textAlign: 'center',
                    fontFamily: 'Roboto',
                    fontWeight: 900,
                    color: '#3f51b5'
                  }}
                >
                  Target :
                  {el.widgetData.target}
                </div>
              )}
              {el.widgetTitle === 'Backlog Health' &&
              el.chartData.storypoints && el.chartData.storypoints !== 0 && (
                <table
                  align='center'
                  width='70%'
                  style={{
                    marginTop: 10
                  }}
                >
                  <tr>
                    <th align='center'>Target</th>
                    <th align='center'>Average</th>
                    <th align='center'>Difference</th>
                  </tr>
                  <tr>
                    <td align='center'>
                      <b style={{
                        color: velocityColors.Target
                      }}
                      >
                        {el.widgetData.data_service.target}
                      </b>
                    </td>
                    <td align='center'>
                      <b style={{
                        color: velocityColors.Average
                      }}
                      >
                        {el.chartData.storypoints === null
                          ? 0
                          : el.chartData.storypoints || 0}
                      </b>
                    </td>
                    <td align='center'>
                      <b style={{
                        color: '#FFAC1C',
                        fontSize: getMaxHeight(`item-${i}`) < 300 ? 12 : 20
                      }}
                      >
                        {el.widgetData.data_service.target &&
                        Math.round(((el.chartData.storypoints === null
                          ? 0
                          : el.chartData.storypoints - el.widgetData
                            .data_service.target) / el.widgetData
                          .data_service.target) * 100)}
                        %
                      </b>
                    </td>
                  </tr>
                </table>
              )}
            </div>
            {/* <div
              className={classes.numberGadgets}
            >
              <div style={{ fontSize: 20, color: 'green' }}>{el.chartData.sprints}</div>

            </div> */}
          </ResponsiveContainer>

        </div>
      )
    } if (el.chartType === 'stackedbar') {
      return (

        <div className={`item widgetBody item-${i}`} key={i} data-grid={el}>
          {/* <Grid container spacing={3}>
      <Grid item xs={9}> */}
          <div className='widgetHeader'>
            {owner && (
              // <Grid item xs={3}>
              <>
                <IconButton
                  className='pull-right widgetButton'
                  aria-label='close'
                  onMouseDown={() => onRemoveItem(i)}
                  style={{
                    float: 'right'
                  }}
                >
                  <CloseIcon style={removeStyle} fontSize='small' />
                </IconButton>
                {el.widgetData.data_service.config && (
                  <IconButton
                    className='pull-right widgetButton'
                    aria-label='close'
                    onMouseDown={() => openEditMenu(i)}
                    style={{
                      float: 'right'
                    }}
                  >
                    <SettingsIcon style={removeStyle} fontSize='small' />
                  </IconButton>
                )}
              </>
              // </Grid>
            )}
            <TtoolTip
              title={(
                <span style={{
                  fontSize: 12,
                  fontFamily: 'Verdana'
                }}
                >
                  {el.widgetData.data_service.tooltip}
                </span>
              )}
              className='pull-right widgetButton'
            >
              <InfoIcon
                fontSize='small'
                style={{
                  color: '#eea508'
                }}
              />
            </TtoolTip>
            <Typography
              variant='caption'
              className='widgetTitle'
              title={el.displayname || el.widgetTitle}
              style={{
                width: getMaxWidth(i)
              }}
              fontSize='small'
              component='div'
            >
              {el.displayname || el.widgetTitle}
            </Typography>
            {/* </Grid> */}
          </div>
          {/* </Grid> */}
          <div className='widgetSubTitle' fontSize='small'>
            {
              el.widgetData.data_service.properties &&
                el.widgetData.data_service.properties.filters
                  .map((item) => (Object.keys(el.widgetData.data_service
                    .parameters).includes(item.name)
                    ? (
                      <span>
                        <strong>
                          {el.chartData.displayFields[item.name]}
                        </strong>
                        {el.chartData.displayFields[item.name] &&
                          ` : ${
                            el.widgetData.data_service.parameters[item.name]
                          }`}
                        {' '}
                      </span>
                    )
                    : null))
            }

          </div>
          <ResponsiveContainer>
            <BarChart
              width={500}
              height={300}
              data={el.chartData}
              margin={{
                top: 10,
                right: 30,
                left: 10,
                bottom: 40
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name'>
                <Label
                  value={el.widgetData.data_service.label.x_axis}
                  position='bottom'
                />
              </XAxis>
              <YAxis
                domain={[0, el.chartData.maxvalue]}
                label={{
                  value: el.widgetData.data_service.label.y_axis,
                  angle: -90,
                  position: 'insideBottomLeft'
                }}
              />

              <Tooltip />
              {el.chartData[0].labels.map((item, i1) => (

                item !== 'name' && (
                  <Bar
                    dataKey={item}
                    stackId='a'
                    fill={
                      i1 > 10 ? colors[i1 % 10] : colors[i1]
                    }
                  />
                )
              ))}

            </BarChart>
          </ResponsiveContainer>
        </div>

      )
    } if (el.chartType === 'table' && el.widgetTitle === 'Issues from JQL') {
      return (
        <div className={`item widgetBody item-${i}`} key={i} data-grid={el}>
          <div className='widgetHeader'>
            {owner && (
              <>
                <IconButton
                  aria-label='close'
                  onMouseDown={() => onRemoveItem(i)}
                  style={{
                    float: 'right'
                  }}
                  className='widgetButton'
                >
                  <CloseIcon style={removeStyle} fontSize='small' />
                </IconButton>
                <IconButton
                  aria-label='close'
                  onMouseDown={() => openEditMenu(i)}
                  style={{
                    float: 'right'
                  }}
                  className='widgetButton'
                >
                  <SettingsIcon style={removeStyle} fontSize='small' />
                </IconButton>
              </>
            )}
            <TtoolTip
              title={(
                <span style={{
                  fontSize: 12,
                  fontFamily: 'Verdana'
                }}
                >
                  {el.widgetData.data_service.tooltip}
                </span>
              )}
              className='pull-right widgetButton'
            >
              <InfoIcon
                fontSize='small'
                style={{
                  color: '#eea508'
                }}
              />
            </TtoolTip>
            <Typography
              variant='caption'
              className='widgetTitle'
              style={{
                width: getMaxWidth(i)
              }}
              title={el.displayname || el.widgetTitle}
              component='div'
              fontSize='small'
            >
              {el.displayname || el.widgetTitle}

            </Typography>
          </div>
          <div className='widgetSubTitle' fontSize='small'>
            {
              el.widgetData.data_service.properties &&
                el.widgetData.data_service.properties.filters
                  .map((item) => (Object.keys(el.widgetData.data_service
                    .parameters).includes(item.name)
                    ? (
                      <span>
                        <strong>
                          {el.chartData.displayFields[item.name]}
                        </strong>
                        {el.chartData.displayFields[item.name] &&
                        ` : ${
                          el.widgetData.data_service.parameters[item.name]
                        }`}
                        {' '}
                      </span>
                    )
                    : null))
            }
          </div>
          <ResponsiveContainer>
            <div style={{
              overflow: 'auto',
              maxHeight: getMaxHeight(`item-${i}`)
            }}
            >
              <div className={classes.error}>
                {el.chartData.length === 100 && el.chartData[0].jiraURL ? (
                  <div>
                    This JQL have more than 100 issues. Click
                    <a
                      href={el.chartData[0].jiraURL}
                      target='_blank'
                      rel='noreferrer'
                    >
                      here
                    </a>
                    {' '}
                    to view all issues
                  </div>
                ) : null}
              </div>
              {/* {el.chartData.retIssues.map((values, index) => ( */}
              {el.chartData.length > 0 ? (
                <EnhancedTable
                  key='issues-table'
                  headCells={headCells}
                  rowsData={el.chartData}
                  showButton='dashboard'
                />
              ) : (
                <div style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: 14,
                  marginTop: 20
                }}
                >
                  {el.chartData.message}
                </div>
              )}
              {/* ))} */}

            </div>
          </ResponsiveContainer>
        </div>
      )
    }
    if (!el.widgetData.data_service.parameters.isWildCard &&
        el.chartType === 'table' &&
        el.widgetData.data_service.parameters.page_title === 'All' &&
        el.widgetTitle === 'Page Views') {
      return (
        <div className={`item widgetBody item-${i}`} key={i} data-grid={el}>
          <div className='widgetHeader'>
            {owner && (
              <>
                <IconButton
                  aria-label='close'
                  onMouseDown={() => onRemoveItem(i)}
                  style={{
                    float: 'right'
                  }}
                  className='widgetButton'
                >
                  <CloseIcon style={removeStyle} fontSize='small' />
                </IconButton>
                <IconButton
                  aria-label='close'
                  onMouseDown={() => openEditMenu(i)}
                  style={{
                    float: 'right'
                  }}
                  className='widgetButton'
                >
                  <SettingsIcon style={removeStyle} fontSize='small' />
                </IconButton>
              </>
            )}
            <TtoolTip
              title={(
                <span style={{
                  fontSize: 12,
                  fontFamily: 'Verdana'
                }}
                >
                  {el.widgetData.data_service.tooltip}
                </span>
              )}
              className='pull-right widgetButton'
            >
              <InfoIcon
                fontSize='small'
                style={{
                  color: '#eea508'
                }}
              />
            </TtoolTip>
            <Typography
              variant='caption'
              className='widgetTitle'
              style={{
                width: getMaxWidth(i)
              }}
              title={el.displayname || `${el.widgetTitle}(Last 30 Days)`}
              component='div'
              fontSize='small'
            >
              {el.displayname || `${el.widgetTitle}(Last 30 Days)`}

            </Typography>
          </div>
          <div className='widgetSubTitle' fontSize='small'>
            {
              Object.keys(el.widgetData.data_service.parameters)
                .map((keyName, keyIndex) => (
                  <span>
                    <strong>
                      {el.chartData.displayFields[keyName]}
                    </strong>
                    {el.chartData.displayFields[keyName] &&
                    ` : ${el.widgetData.data_service.parameters[keyName]}`}
                    {' '}
                  </span>
                ))
            }
          </div>
          <ResponsiveContainer>
            <div>

              {/* {el.chartData.retIssues.map((values, index) => ( */}
              {el.chartData.length > 0 ? (
                <EnhancedTable
                  key='issues-table'
                  headCells={pageViewHeader}
                  rowsData={el.chartData}
                  showButton='dashboard'
                />
              ) : null}


              {/* ))} */}

            </div>
          </ResponsiveContainer>
        </div>
      )
    }
    if (el.widgetData.data_service.parameters.isWildCard &&
      el.chartType === 'table' &&
      el.widgetTitle === 'Page Views') {
      return (
        <div className={`item widgetBody item-${i}`} key={i} data-grid={el}>
          <div className='widgetHeader'>
            {owner && (
              <>
                <IconButton
                  aria-label='close'
                  onMouseDown={() => onRemoveItem(i)}
                  style={{
                    float: 'right'
                  }}
                  className='widgetButton'
                >
                  <CloseIcon style={removeStyle} fontSize='small' />
                </IconButton>
                <IconButton
                  aria-label='close'
                  onMouseDown={() => openEditMenu(i)}
                  style={{
                    float: 'right'
                  }}
                  className='widgetButton'
                >
                  <SettingsIcon style={removeStyle} fontSize='small' />
                </IconButton>
              </>
            )}
            <TtoolTip
              title={(
                <span style={{
                  fontSize: 12,
                  fontFamily: 'Verdana'
                }}
                >
                  {el.widgetData.data_service.tooltip}
                </span>
              )}
              className='pull-right widgetButton'
            >
              <InfoIcon
                fontSize='small'
                style={{
                  color: '#eea508'
                }}
              />
            </TtoolTip>
            <Typography
              variant='caption'
              className='widgetTitle'
              style={{
                width: getMaxWidth(i)
              }}
              title={el.displayname || `${el.widgetTitle}(Last 30 days)`}
              component='div'
              fontSize='small'
            >
              {el.displayname || `${el.widgetTitle}(Last 30 days)`}
            </Typography>
          </div>
          <div className='widgetSubTitle' fontSize='small'>
            {
              Object.keys(el.widgetData.data_service.parameters)
                .map((keyName, keyIndex) => (
                  <span>
                    <strong>
                      {el.chartData.displayFields[keyName]}
                    </strong>
                    {el.chartData.displayFields[keyName] &&
                      ` : ${el.widgetData.data_service.parameters[keyName]}`}
                    {' '}
                  </span>
                ))
            }
          </div>
          <ResponsiveContainer>
            <div>

              {/* {el.chartData.retIssues.map((values, index) => ( */}
              {el.chartData.length > 0 ? (
                <EnhancedTable
                  key='issues-table'
                  headCells={pageViewHeader}
                  rowsData={el.chartData}
                  showButton='dashboard'
                />
              ) : null}


              {/* ))} */}

            </div>
          </ResponsiveContainer>
        </div>
      )
    }
  }

  const generateDOM = () => _.map(_.range(items), (i) => (
    <div className='item' key={i}>
      <ResponsiveContainer>
        <AreaChart
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis />
          <Tooltip />
          <Area type='monotone' dataKey='uv' stroke='#8884d8' fill='#8884d8' />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  ))

  // generateLayout() {
  //   const p = this.props;

  // }

  const onLayoutChange = (layout, data) => {
    /* eslint no-console: 0 */
    saveLayoutToDataBase(layout, 'user', data)
    setLayout(layout)
  }

  const saveLayoutToDataBase = (layout, admin, originalLayout) => {
    let items1 = items
    if (admin && admin === 'admin') {
      items1 = layout
    }
    if ((!initalLoad || (admin && admin === 'admin')) && owner) {
      let layoutdata = layout
      if (
        originalLayout &&
        (originalLayout.length === layout.length || !layout.length)
      ) {
        layoutdata = originalLayout
      }
      const layout1 = cloneDeep(layoutdata)
      /* eslint no-plusplus: 0 */
      for (let i = 0; i < layout1.length; i++) {
        delete layout1[i].chartData
        const widgetdata = items1[i].widgetData
        if (widgetdata.data_service.properties && widgetdata.data_service
          .properties.filters) {
          //     widgetdata.data_service.properties.filters.map((d1, i1) => {
          widgetdata.data_service.properties.filters[0].value = []
          //     })
        }
        layout1[i].chartType = items1[i].chartType
        if (items1[i].value) {
          layout1[i].value = items1[i].value
        }
        layout1[i].widgetData = widgetdata
        layout1[i].widgetTitle = items1[i].widgetTitle
        if (items1[i].displayname) {
          layout1[i].displayname = items1[i].displayname
        }
      }
      const tabs1 = tabs
      tabs1.map((item) => {
        if (item.index === currentTab.index) {
          item.widgets = layout1
        }
      })
      const formData = {
        id: props.match.params.id,
        widget: tabs1
      }
      DashboardService.saveLayoutToDataBase(formData).then((response) => {
        items1.map((item, i1) => {
          items1[i1].i = `n${i1}`
          delete items1[i1].isEditname
          layout1.map((test, i2) => {
            if (item.i === test.i) {
              items1[i1].w = test.w
            }
          })
        })
        setItems([...items1])
        setLayout([...items1])
      })
    }
  }

  const handleChange = (property, value, name) => {
    if (typeof value !== 'undefined') {
      const tempData1 = formData
      Object.keys(currentConfiguration).map((val, idx) => {
        if (val === 'data_service') {
          currentConfiguration[val].properties.filters
            .map((readinProp, idx1) => {
              currentConfiguration[val].parameters[name] = value
              Object.keys(formData).map((val1, index1) => {
                if (val1 === name) {
                  tempData1[val1] = value
                }
              })

              if (readinProp.depends_on &&
                readinProp.depends_on === property.name) {
                const currentFilterData = currentConfiguration[val]
                  .properties.filters[idx1]
                tempData1[currentFilterData.name] = ''
                const object = new Object()
                object[name] = value
                if (name === 'issuetype') {
                  object.project_key = formData.project_key
                }
                if (name === 'fromstatus') {
                  object.project_key = formData.project_key
                  object.issuetype = formData.issuetype
                }
                if (name === 'tostatus') {
                  object.project_key = formData.project_key
                  object.issuetype = formData.issuetype
                  object.fromstatus = formData.fromstatus
                }
                currentFilterData.parameter_value = object
                const tempData = currentConfiguration
                tempData[val].properties.filters[idx1].value = []
                setcurrentConfiguration({
                  ...tempData
                })
                setLoadingBar(true)
                DashboardService.loadChildData(currentFilterData)
                  .then((response) => {
                    tempData[val].properties.filters[idx1].value = response.data
                    setcurrentConfiguration({
                      ...tempData
                    })
                    setLoadingBar(false)
                  })
              }
            })
        }
      })
      setFormData({
        ...tempData1
      })
    } else if (name === 'jql') {
      const tempData1 = formData
      Object.keys(currentConfiguration).map((val, idx) => {
        if (val === 'data_service') {
          currentConfiguration[val].properties.filters
            .map((readinProp, idx1) => {
              currentConfiguration[val].parameters[name] = value
              Object.keys(formData).map((val1, idx1) => {
                if (val1 === name) {
                  tempData1[val1] = value
                }
              })
              const tempData = currentConfiguration
              if (tempData[val].properties.filters[idx1].name === 'jql' ||
              tempData[val].properties.filters[idx1].name === 'jql1' ||
              tempData[val].properties.filters[idx1].name === 'jql2') {
                tempData[val].properties.filters[idx1].value = ''
                setcurrentConfiguration({
                  ...tempData
                })
              }
            // }
            })
        }
      })
      setFormData({
        ...tempData1
      })
    }
  }

  const handleConfiguraitonClose = () => {
    setEditOpen(false)
    setSummaryEditOpen(false)
    setPowerBiOpen(false)
  }

  const checkMandatoryFields = () => {
    if (currentConfiguration.data_service &&
        currentConfiguration.data_service.properties &&
        currentConfiguration.data_service.properties.filters) {
      const result = currentConfiguration.data_service.properties.filters
        .filter((field, idx) => (!field.optional &&
          !currentConfiguration.data_service.parameters.type &&
          (!currentConfiguration.data_service.parameters[field.name] ||
            currentConfiguration.data_service.parameters[field.name] === '')) ||
        (currentConfiguration.data_service.parameters.type === 'Board' &&
          (!currentConfiguration.data_service.parameters.name ||
            currentConfiguration.data_service.parameters.name === '')) ||
        (currentConfiguration.data_service.parameters.type === 'Project' &&
          (!currentConfiguration.data_service.parameters.project_key ||
            currentConfiguration.data_service.parameters.project_key === '')))

      if (result.length) {
        return true
      }
    } else {
      return modalLoading
    }
  }
  const showDetailsSubMethod = (response) => {
    let items1 = []
    if (newItem) {
      if (items === null || !items.length) {
        items1.push(newItem)
      } else {
        items1 = items.concat(newItem)
      }
    } else {
      items1 = items
    }
    const tempData = items1
    tempData.filter((item) => item.i ===
      currentWidget).map((filteredName, index) => {
      filteredName.chartData = response ? response.data : null
      filteredName.widgetTitle = currentConfiguration.name
      filteredName.widgetData = currentConfiguration
      filteredName.chartType = currentConfiguration.chartType
      if (currentConfiguration.value) {
        filteredName.value = currentConfiguration.value
      }
      if (newItem && filteredName.widgetData.currentInterval) {
        window.clearInterval(filteredName.widgetData.currentInterval)
      }
      if (filteredName.widgetData.data_service.refreshInterval &&
          filteredName.widgetData.data_service.refreshInterval !== '0') {
        const interval = parseInt(filteredName.widgetData
          .data_service.refreshInterval) * 60000

        const currentInterval = window
          .setInterval(() => getChartSingleChartData(filteredName,
            tempData), interval)
        filteredName.widgetData.currentInterval = currentInterval
      }
    })
    if (currentConfiguration.chartType === 'table') {
      tempData.filter((item) => item.i ===
        currentWidget).map((filteredName, index) => {
        filteredName.chartData = []
        setItems([...tempData])
      })
    }
    tempData.filter((item) => item.i ===
      currentWidget).map((filteredName, index) => {
      if (currentConfiguration.chartType === 'table' &&
        !currentConfiguration.chartType.message) {
        const chartData = setHyperLinks(response.data)
        filteredName.chartData = chartData
      } else {
        filteredName.chartData = response ? response.data : null
      }
      filteredName.widgetTitle = currentConfiguration.name
      filteredName.displayname = currentConfiguration.displayname
      filteredName.chartType = currentConfiguration.chartType
      if (currentConfiguration.value) {
        filteredName.value = currentConfiguration.value
      }
    })
    setItems([...tempData])
    setLayout([...tempData])
    // if (!response) {
    saveLayoutToDataBase(items1, 'admin')
    // }
    handleConfiguraitonClose()
    setModalLoading(false)
    setNewItem(null)
  }

  const showDetails = () => {
    // chartData
    const object = new Object()
    object.d = cloneDeep(currentConfiguration.data_service)
    if (object.d && object.d.properties && object.d.properties.filters) {
      for (let i = 0; i < object.d.properties.filters.length; i++) {
        object.d.properties.filters[i].value = []
      }
    }
    if (formData.timeScale) {
      object.d.parameters.timeScale = formData.timeScale
      currentConfiguration.data_service.parameters
        .timeScale = formData.timeScale
    }
    if (formData.isWildCard) {
      object.d.parameters.isWildCard = formData.isWildCard
      currentConfiguration.data_service.parameters
        .isWildCard = formData.isWildCard
    } else if (!formData.isWildCard) {
      object.d.parameters.isWildCard = formData.isWildCard
      currentConfiguration.data_service.parameters
        .isWildCard = formData.isWildCard
    }

    if (currentConfiguration.currentInterval) {
      window.clearInterval(currentConfiguration.currentInterval)
    }
    if (formData.chartType !== 'richtext' && formData.chartType !== 'html') {
      setModalLoading(true)
      setwidgetErrorMessage(null)
      DashboardService.getChartData(object).then((response) => {
        setModalLoading(false)
        if (response.data.statusCode && response.data.statusCode > 300 &&
          response.data.errorMessages) {
          setwidgetErrorMessage(Array.isArray(response.data.errorMessages)
            ? response.data.errorMessages[0]
            : response.data.errorMessages.toString())
        } else {
          if (response.data.length === 0) {
            setwidgetErrorMessage('No Data Found for this selection')
          } else {
            setwidgetErrorMessage(null)
          }

          if ((currentConfiguration
            .name === 'Cycle Time On Issues Transitions' ||
            currentConfiguration.name === 'Number Of Issues' ||
            currentConfiguration.name === 'Issues from JQL' ||
            currentConfiguration.name === 'Universal Ratio Widget') &&
              response.data.statusCode === 500) {
            setwidgetErrorMessage(response.data.message)
          } else {
            if (currentConfiguration.name === 'Average Velocity') {
              response.data.map((item) => {
                item.Average = Math.round(item.Average)
                item.Target = formData.target || currentConfiguration.target
              })
            }
            showDetailsSubMethod(response)
          }
        }
      })
    } else {
      showDetailsSubMethod()
    }
  }

  const setHyperLinks = (data) => {
    if (!data.message) {
      data.map((value, i) => {
        data[i].key = (
          <div className={classes.imageContainer}>
            <img
              src={data[i].issueTypeIcon}
              className={classes.imageSource}
              alt='Issue Type'
            />
            <a
              href={`${data[0].baseJiraURL}/browse/${data[i].key}`}
              target='_blank'
              rel='noreferrer'
            >
              <ListItemText primary={`${data[i].key}`} />
            </a>
          </div>
        )
        data[i].priority = (
          <>
            <TtoolTip title={data[i].priority}>
              <img
                src={data[i].priorityIcon}
                width='15'
                height='30'
                alt='Issue Priority'
              />
            </TtoolTip>
          </>
        )
      })
      return data
    }
    return data
  }

  const switchToAdvance = (formData, name) => {
    if (formData.isWildCard) {
      formData.isWildCard = false
    } else {
      formData.isWildCard = true
    }
    formData.page_title = ''
    setFormData({
      ...formData
    })
  }
  const changeSummaryEditorValue = (value, widget) => {
    const tempData = currentConfiguration
    tempData[widget].value = value

    setcurrentConfiguration(tempData)
  }

  const changeEditorValue = (value) => {
    const tempData = currentConfiguration
    tempData.value = value

    setcurrentConfiguration(tempData)
  }
  const changeChartType = (e, configdata) => {
    const tempData = configdata || currentConfiguration
    tempData.chartType = e.target.value
    tempData.data_service.chartType = e.target.value

    const tempData1 = formData
    Object.keys(formData).map((val1, idx1) => {
      if (val1 === e.target.name) {
        formData[val1] = e.target.value
      }
    })
    setcurrentConfiguration(tempData)
    if (configdata) {
      setFormData({
        ...tempData1
      })
    }
  }
  const changeFormValue = (e, configdata) => {
    const tempData = configdata || currentConfiguration
    tempData[e.target.name] = e.target.value
    tempData.data_service[e.target.name] = e.target.value

    const tempData1 = formData
    Object.keys(formData).map((val1, idx1) => {
      if (val1 === e.target.name) {
        formData[val1] = e.target.value
      }
    })
    if (e.target.name === 'timeScale' && !formData.timeScale) {
      tempData1.timeScale = e.target.value
    }
    setcurrentConfiguration(tempData)
    if (configdata) {
      setFormData({
        ...tempData1
      })
    }
  }

  const changeRereshInterval = (e, configdata) => {
    const tempData = configdata || currentConfiguration
    tempData.refreshInterval = e.target.value
    formData.refreshInterval = e.target.value
    tempData.data_service.refreshInterval = e.target.value
    const tempData1 = formData

    setcurrentConfiguration(tempData)
    if (configdata) {
      setFormData({
        ...tempData1
      })
    }
  }
  const changeRadioOption = (value) => {
    setRadioValue(value)
    setFormData({
      ...formData,
      name: '',
      project_key: ''
    })
  }

  return (
    <div className='widgetContainer'>
      {loading ? <LinearProgress /> : null}
      <Grid container spacing={3}>
        <Grid item xs={9}>

          <div>
            <div style={{
              padding: '00px 00px 00px 10px',
              minHeight: '40px',
              display: 'flex',
              alignItems: 'center'
            }}
            >
              <h4
                className='dashboardTitle'
                style={{
                  marginRight: 10
                }}
              >
                {dashboardTitle}
              </h4>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable' direction='horizontal'>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      {currentTab && tabs.map((tab, i) => (
                        <>
                          <Draggable
                            key={tab.index}
                            draggableId={tab.tabname}
                            index={i}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(snapshot.isDragging,
                                  provided.draggableProps.style)}
                              >
                                <div
                                  title={tab.tabname}
                                  style={{
                                    backgroundColor: currentTab.index === tab
                                      .index ? '#3f51b5' : '#E0E0E0',
                                    height: '30px',
                                    // minWidth: '110px',
                                    width: tab.tabname
                                      .length > 25 ? 200 : 'auto',
                                    padding: '6px 0 0 2px',
                                    display: 'flex',
                                    borderRadius: '3px',
                                    marginRight: 10,
                                    textAlign: 'center'
                                    // paddingRight: 0
                                  }}
                                  // variant='contained'
                                  disabled={tabloading}
                                >
                                  {
                                    <div
                                      // eslint-disable-next-line max-len, no-undef
                                      onClick={tabloading || loading || loadingbar || modalLoading ? null : (e) => selectTab(tab)}
                                      style={{
                                        'white-space': 'nowrap',
                                        minWidth: '90px',
                                        paddingLeft: 10,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        // overflow: 'hidden',
                                        // textOverflow: 'ellipsis',
                                        color: currentTab.index === tab
                                          .index ? 'white' : 'black'
                                      }}
                                    >
                                      {/* {!isTabNameTyping && <EditNameIcon style={styles.menuIcon} children={<EditIcon />} />} */}
                                      {tab.tabname}
                                    </div>
                                  }
                                  {owner && (
                                    <MoreVertIcon
                                      id='basic-button'
                                      aria-controls='basic-menu'
                                      aria-haspopup='true'
                                      aria-expanded={open ? 'true'
                                        : undefined}
                                      fontSize='default'
                                      style={{
                                        // marginTop: tab.tabname.length >
                                        // 25 ? '5px' : '6px',
                                        marginLeft: 5,
                                        marginRight: 5,
                                        color: tab.index === currentTab.index
                                          ? '#fff' : 'rgb(47, 58, 69)',
                                        float: 'right',
                                        fontSize: 'medium'
                                      }}
                                      onClick={(e) => handleClick(e, i)}
                                    />
                                  )}
                                  <Menu
                                    id='basic-menu'
                                    anchorEl={anchorEl}
                                    onClose={handleTabClose}
                                    open={openMenu}
                                    MenuListProps={{
                                      'aria-labelledby': 'basic-button'
                                    }}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'left'
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'left'
                                    }}
                                  >
                                    <MenuItem onClick={editTabName}>
                                    Rename
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                      tabs.length === 1
                                        ? setDeleteWarning(true)
                                        : setTabDeleteAlert(true)
                                      setAnchorEl(null)
                                    }}
                                    >
                        Delete
                                    </MenuItem>
                                  </Menu>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        </>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {nameModal ? (
                <Dialog
                  aria-labelledby='customized-dialog-title'
                  open={nameModal}
                  maxWidth='sm'
                  fullWidth
                >
                  <MuiDialogTitle disableTypography>
                    <Typography variant='h6'>Rename</Typography>
                    <IconButton
                      aria-label='close'
                      className='widgetCloseButton pull-right'
                      onClick={() => setNameModal(false)}
                    >
                      <CloseIcon />
                    </IconButton>

                  </MuiDialogTitle>
                  <MuiDialogContent dividers className={styles.modalContent}>
                    <TextField
                      fullWidth
                      value={newTabName}
                      onChange={(e) => setNewTabName(e.target.value)}
                    />
                  </MuiDialogContent>
                  <MuiDialogActions>
                    <Button
                      autoFocus
                      disabled={!newTabName || newTabName === ''}
                      onClick={() => saveTabName(newTabName)}
                      color='primary'
                    >
            Save
                    </Button>
                  </MuiDialogActions>

                </Dialog>
              ) : null}
              {tabs.length && owner ? (
                <AddIcon
                  color='inherit'
                  onClick={addTabs}
                  fontSize='small'
                  style={{
                    margin: 10
                  }}
                />
              ) : null}
            </div>
          </div>
        </Grid>
        <Grid item xs={3}>

          {(owner && !loading) ? (
            <Button
              variant='outlined'
              className='pull-right'
              color='primary'
              onClick={() => onAddItem()}
            >
              Add Widget
            </Button>
          ) : null}
          {!loading ? (
            <Button
              className='pull-right'
              style={{
                marginRight: 20
              }}
              variant='outlined'
              component={Link}
              to='/dashboards'
              onClick={() => setPath({
                path: '/dashboards',
                component: DashboardComponent
              })}
            >
              Back
            </Button>
          ) : null}
        </Grid>
      </Grid>

      <hr className='dashboardHr' />
      {!loading ? (
        <ReactGridLayout
          layout={layout}
          onLayoutChange={(testdata) => onLayoutChange(layout, testdata)}
          onDragStart={
            owner && owner === true ? () => changeDrag(layout) : null
          }
          onResizeStart={
            owner && owner === true ? () => changeDrag(layout) : null
          }
        >
          {_.map(items, (el) => createElement(el))}
        </ReactGridLayout>
      ) : null}
      <Dialog
        aria-labelledby='customized-dialog-title'
        open={open}
        maxWidth='md'
        fullWidth
      >
        <MuiDialogTitle disableTypography>
          <Typography variant='h6'>Widgets</Typography>

          <IconButton
            aria-label='close'
            className='widgetCloseButton pull-right'
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>

        </MuiDialogTitle>
        <MuiDialogContent dividers className={styles.modalContent}>
          {/* <div className='modelDialog'> */}
          {createElementList()}
          {/* </div> */}
        </MuiDialogContent>
        <MuiDialogActions>
          <Button
            type='submit'
            autoFocus
            onClick={() => handleClose()}
            color='primary'
          >
            Close
          </Button>
        </MuiDialogActions>

      </Dialog>
      <TemporaryDrawer
        openDialog={editOpen}
        currentConfiguration={currentConfiguration}
        close={() => (setEditOpen(false), setNewItem(null))}
        formData={formData}
        widgetErrorMessage={widgetErrorMessage}
        changeChartType={changeChartType}
        changeFormValue={changeFormValue}
        changeRereshInterval={changeRereshInterval}
        handleChange={handleChange}
        switchToAdvance={switchToAdvance}
        handleConfiguraitonClose={handleConfiguraitonClose}
        modalLoading={modalLoading}
        checkMandatoryFields={checkMandatoryFields}
        showDetails={showDetails}
        loadingbar={loadingbar}
        changeWidgetName={changeWidgetName}
        changeEditorValue={changeEditorValue}
      />
      <SummaryConfig
        openDialog={summaryeditOpen}
        currentConfiguration={currentConfiguration}
        close={() => (setEditOpen(false), setNewItem(null),
        setSummaryEditOpen(false))}
        formData={formData}
        widgetErrorMessage={widgetErrorMessage}
        changeChartType={changeChartType}
        changeFormValue={changeFormValue}
        changeRereshInterval={changeRereshInterval}
        handleChange={handleChange}
        switchToAdvance={switchToAdvance}
        handleConfiguraitonClose={handleConfiguraitonClose}
        modalLoading={modalLoading}
        checkMandatoryFields={checkMandatoryFields}
        showDetails={showDetails}
        loadingbar={loadingbar}
        changeSummaryWidgetName={changeSummaryWidgetName}
        changeSummaryEditorValue={changeSummaryEditorValue}
      />
      <EmbedIframeWidget
        openDialog={powerBiOpen}
        currentConfiguration={currentConfiguration}
        close={() => (setEditOpen(false), setNewItem(null),
        setPowerBiOpen(false))}
        widgetErrorMessage={widgetErrorMessage}
        handleConfiguraitonClose={handleConfiguraitonClose}
        modalLoading={modalLoading}
        checkMandatoryFields={checkMandatoryFields}
        showDetails={showDetails}
        changePowerBiWidgetName={changePowerBiWidgetName}
        changeiFrameWidgetName={changeiFrameWidgetName}
        changeSummaryEditorValue={changeSummaryEditorValue}
      />
      {/* <Dialog aria-labelledby='edit-options-dialog-title' open={editOpen}>
        {modalLoading ? <LinearProgress /> : null}
        <MuiDialogTitle disableTypography>
          <Typography variant='h6'>
            {currentConfiguration.name}
            {' '}
            - Configuration
          </Typography>

          <IconButton
            aria-label='close'
            disabled={modalLoading}
            className='widgetCloseButton'
            onClick={() => setEditOpen(false)}
          >
            <CloseIcon />
          </IconButton>

        </MuiDialogTitle>

        <ValidatorForm>

          <MuiDialogContent dividers className={styles.modalContent}>
            <div className='modelDialog'>
              {' '}
              {widgetErrorMessage ? (
                <Alert severity='error'>{widgetErrorMessage}</Alert>

              ) : null}
              <br />
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <FormControl className='widgetFormControl'>
                    <SelectValidator
                      value={formData.chartType}
                      label='Chart Type'
                      id='chart_type'
                      name='chartType'
                      onChange={
                        (e) => changeChartType(e, currentConfiguration)
                      }
                    >
                      {(currentConfiguration.name === 'Commits By User' ||
                        currentConfiguration.name === 'Pull Requests By User' ||
                        formData.chartType === 'bar') && (
                        <MenuItem
                          value='bar'
                          key='bar'
                        >
                          Bar
                        </MenuItem>
                      )}
                      {(currentConfiguration.name ===
                        'Sprint Committed Vs Completed' ||
                        currentConfiguration.name ===
                        'Sprint Burndown Chart') && (
                        <MenuItem
                          value='SimpleLineChart'
                          key='SimpleLineChart'
                        >
                          Line
                        </MenuItem>
                      )}
                      {currentConfiguration.name ===
                        'Sprint Committed Vs Completed' && (
                        <MenuItem
                          value='barChartWithMinHeight'
                          key='barChartWithMinHeight'
                        >
                          Multi Bar
                        </MenuItem>
                      )}
                      {(currentConfiguration.name === 'Backlog Health' ||
                        currentConfiguration.name ===
                        'No. of Story Points in Ready Status' ||
                        currentConfiguration.name ===
                        'Cycle Time On Issues Transitions' ||
                        currentConfiguration.name === 'Number Of Issues') && (
                        <MenuItem
                          value='numberGadget'
                          key='numberGadget'
                        >
                          Number Gadget
                        </MenuItem>
                      )}
                      {
                        (currentConfiguration.name === 'Commits per Project' ||
                          currentConfiguration.name ===
                          'Pull Requests per Project') && (
                          <MenuItem
                            value='stackedbar'
                            key='stackedbar'
                          >
                            Stacked Bar
                          </MenuItem>
                        )
                      }
                      {(currentConfiguration.name === 'Issues from JQL' ||
                      currentConfiguration.name === 'Page Views') && (
                        <MenuItem
                          value='table'
                          key='table'
                        >
                          Table
                        </MenuItem>
                      )}
                    </SelectValidator>

                  </FormControl>
                  {
                    currentConfiguration.data_service &&
                      currentConfiguration.data_service
                        .refresh_interval_values ? (

                        <FormControl className='widgetFormControl'>
                          <SelectValidator
                            value={formData.refreshInterval}
                            label='Refresh Interval'
                            id='chart_type'
                            name='refreshInterval'
                            onChange={
                              (e) => changeRereshInterval(e,
                                currentConfiguration)
                            }
                          >
                            {
                              currentConfiguration.data_service
                                .refresh_interval_values.map((val, idx) => (
                                  <MenuItem
                                    value={val.value}
                                    key='numberGadget'
                                  >
                                    {val.label}
                                  </MenuItem>
                                ))
                            }
                          </SelectValidator>
                        </FormControl>
                      ) : null
                  }

                </Grid>
              </Grid>

              {

                Object.keys(currentConfiguration).map((val, idx) => {
                  if (val === 'data_service') {
                    return (
                      <div>
                        {

                          currentConfiguration[val].properties.filters
                            .map((property, idx1) => (
                            // eslint-disable-next-line react/jsx-key
                              <Grid container spacing={2}>
                                {' '}
                                <Grid item xs={12} md={12}>
                                  {' '}
                                  {property.name === 'type'
                                    ? (
                                      <RadioButtons
                                        radio={property.value}
                                        selectedvalue={formData.type}
                                        onChange={
                                          (value) => handleChange(property,
                                            value, property.name)
                                        }
                                      />
                                    )
                                    : property.name === 'jql' ? (
                                      <FormControl
                                        className='widgetFormControl'
                                      >
                                        {currentConfiguration.name ===
                                          'Cycle Time On Issues Transitions' ? (
                                            <InputLabel>
                                              Enter a valid JQL without Project
                                              and Issuetype (Optional)
                                            </InputLabel>
                                          )
                                          : (currentConfiguration.name ===
                                            'Number Of Issues' ||
                                            currentConfiguration.name ===
                                            'Issues from JQL') ? (
                                              <InputLabel>
                                                Enter a valid JQL
                                              </InputLabel>
                                            ) : null}

                                        <Input
                                          id='jql'
                                          name='jql'
                                          value={formData.jql}
                                          onChange={
                                            (e) => handleChange(property,
                                              e.target.value, property.name)
                                          }
                                        />
                                      </FormControl>
                                    ) : (
                                      currentConfiguration.name ===
                                        'No. of Story Points in Ready Status' &&
                                        formData.type === 'Project' &&
                                        property.name === 'name'
                                        ? null : (
                                          <FormControl
                                            className='widgetFormControl'
                                          >
                                            {property.name === 'page_title' &&
                                            formData.isWildCard && (
                                              <>
                                                <RadioGroup
                                                  row
                                                  aria-label='position'
                                                  name='position'
                                                  value='wildCard'
                                                  onChange={
                                                    () => (
                                                      switchToAdvance(formData)
                                                    )
                                                  }
                                                >
                                                  <FormControlLabel
                                                    value='Select'
                                                    control={
                                                      <Radio />
                                                    }
                                                    label='Pages'
                                                  />
                                                  <FormControlLabel
                                                    value='wildCard'
                                                    control={
                                                      <Radio />
                                                    }
                                                    label='Wildcard'
                                                  />
                                                </RadioGroup>

                                                <Input
                                                  id={property.id}
                                                  name={property.name}
                                                  value={
                                                    formData[property.name]
                                                      ? formData[property.name]
                                                      : ''
                                                  }
                                                  onChange={
                                                    (e) => (
                                                      handleChange(property,
                                                        e.target.value,
                                                        property.name)
                                                    )
                                                  }
                                                />
                                              </>
                                            )}


                                            {property.name === 'page_title' &&
                                            !formData.isWildCard && (
                                              <>
                                                <RadioGroup
                                                  row
                                                  aria-label='position'
                                                  name='position'
                                                  value='Select'
                                                  onChange={
                                                    () => (
                                                      switchToAdvance(formData)
                                                    )
                                                  }
                                                >
                                                  <FormControlLabel
                                                    value='Select'
                                                    control={<Radio />}
                                                    label='Pages'
                                                  />
                                                  <FormControlLabel
                                                    value='wildCard'
                                                    control={<Radio />}
                                                    label='Wildcard'
                                                  />
                                                </RadioGroup>
                                                <Autocomplete
                                                  id={property.id}
                                                  options={property.value}
                                                  value={
                                                    formData[property.name]
                                                      ? formData[property.name]
                                                      : ''
                                                  }
                                                  getOptionLabel={
                                                    (option) => option
                                                  }
                                                  loading={
                                                    formData[property.name] &&
                                                    formData[property.name]
                                                      .length === 0 &&
                                                    loadingbar
                                                  }
                                                  onChange={
                                                    (event,
                                                      value) => (
                                                      handleChange(property,
                                                        value,
                                                        property.name)
                                                    )
                                                  }
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      label={property.label}
                                                      name={property.name}
                                                    />
                                                  )}
                                                />
                                              </>
                                            )}
                                            {property.name !== 'page_title' && (
                                              <Autocomplete
                                                id={property.id}
                                                options={property.value}
                                                value={
                                                  formData[property.name]
                                                    ? formData[property.name]
                                                    : ''
                                                }
                                                getOptionLabel={
                                                  (option) => option
                                                }
                                                loading={
                                                  formData[property.name] &&
                                                  formData[property.name]
                                                    .length === 0 &&
                                                  loadingbar
                                                }
                                                onChange={
                                                  (event,
                                                    value) => (
                                                    handleChange(property,
                                                      value, property.name)
                                                  )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label={property.label}
                                                    name={property.name}
                                                  />
                                                )}
                                              />
                                            )}
                                            {}
                                          </FormControl>
                                        )
                                    )}
                                </Grid>
                              </Grid>
                            ))
                        }
                      </div>
                    )
                  }
                })
              }
            </div>

          </MuiDialogContent>
          <MuiDialogActions>
            <Button
              disabled={modalLoading}
              autoFocus
              onClick={() => handleConfiguraitonClose()}
            >
              Close
            </Button>
            <Button
              type='button'
              disabled={checkMandatoryFields()}
              autoFocus
              onClick={() => showDetails()}
              color='primary'
            >
              Save changes
            </Button>
          </MuiDialogActions>
        </ValidatorForm>
      </Dialog> */}
      <AlertDialog
        handleClose={() => setDeleteWarning(false)}
        alertopen={deleteWarning}
        message='Dashboard should have at least one tab'
        key='alert1'
        title='Alert'
        okbuttonalert
      />
      <AlertDialog
        handleClose={() => setTabDeleteAlert(false)}
        alertopen={tabDeleteAlert}
        confirmbutton={deleteTab}
        key='alert2'
        message='Are you sure want to delete the tab with widgets?'
        okbuttonalert={false}
        title='Alert'
      />
      <AlertDialog
        handleClose={() => setPublishAlert(false)}
        alertopen={publishAlert}
        confirmbutton={confirmPublishWidget}
        key='alert3'
        message='Are you sure want to publish this widget to RSS feed?'
        okbuttonalert={false}
        title='Alert'
      />
      <Dialog
        onClose={handleRssClose}
        aria-labelledby='simple-dialog-title'
        open={openPublishLink}
      >
        <DialogTitle id='alert-dialog-title'>
          RSS Link
        </DialogTitle>
        <DialogContent>
          {rssLink}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRssClose} color='default' autoFocus>
            Close
          </Button>
          <Button
            onClick={() => {
              window.open(rssLink)
            }}
            color='primary'
          >
            Open Link
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
