import React, {
  useContext, useRef
} from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  MenuItem,
  createStyles,
  makeStyles,
  FormControl,
  Chip,
  List,
  ListItem,
  LinearProgress,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import queryString from 'query-string'
import parse from 'html-react-parser'
import ToolsService from '../../services/api/toolsService'
import requestAccess from '../../services/api/requestaccessService'
import CartService from '../../services/api/cartService'
import AlertDialog from '../alertbox/alertboxComponent'
import UserContext from '../contexts/UserContext'
import EmailServices from '../../services/api/emailServices'
import LoginService from '../../services/api/loginService'
import ApigeeService from '../../services/api/apigeeService'
import SurveyService from '../../services/api/surveyService'
import RadioButtons from '../inputs/radio'

const useStyles = makeStyles((theme) => createStyles({
  root: {
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  },
  columnDisplay: {
    display: 'flex',
    flexDirection: 'row'
  },
  labelDisplay: {
    margin: '10px',
    width: '60%'
  },
  inputDisplay: {
    width: '40%'
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  success: {
    backgroundColor: theme.palette.success.light
  },
  pending: {
    backgroundColor: '#fff9c4'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  userlist: {
    height: 500,
    overflow: 'auto'
  }
}))

function ByRoleComponent (props) {
  const [loading, setLoading] = React.useState(false)
  const [alertLoading, setAlertLoading] = React.useState(false)
  const [githubCopilotAlert, setGithubCopilotAlert] = React.useState(false)
  const [successAlert, setsuccessAlert] = React.useState(false)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [failalertMessage, setFailalertMessage] = React.useState(null)
  const [successalertMessage, setSuccessalertMessage] = React.useState(null)
  const [alltools, setAlltools] = React.useState([])
  const [allProjects, setAllprojects] = React.useState([])
  const [clusterArray, setClusterArray] = React.useState([])
  const [allRoles, setAllroles] = React.useState([])
  const [allUsers, setAllusers] = React.useState([])
  const [apigeeOrgs, setApigeeOrgs] = React.useState([])
  const [apigeeOrgTeamsAndRoles, setApigeeOrgTeamsAndRoles] = React.useState([])
  const [loadingbar, setLoadingbar] = React.useState(false)
  const [loadingoptionsUser, setLoadingoptionsUser] = React.useState(false)
  const [existingUsers, setExistingUsers] = React.useState([])
  const [alertbox, Setalertbox] = React.useState(false)
  const [alertwithok, setAlertwithok] = React.useState(false)
  const [alertboxMessage, setalertboxMessage] = React.useState(null)
  const [surveyDialog, setSurveyDialog] = React.useState(false)
  const [surveyData, setSurveyData] = React.useState({

  })
  const [surveyValueText, setSurveyValueText] =
    React.useState('XENA Teams Channel, XENA confluence page ...')
  const [surveyValueRadio, setSurveyValueRadio] = React.useState('')
  const [surveyJpmAlert, setSurveyJpmAlert] = React.useState(false)
  const [surveyJpmAlertMessage, setSurveyJpmAlertMessage] = React.useState('')
  const [button, setButton] = React.useState(null)
  const classes = useStyles()
  const form = useRef(null)
  const {
    user, setUser
  } = useContext(UserContext)

  /* to get the available tools for tool selection dropdown when the page gets loaded */
  React.useEffect(() => {
    const values = queryString.parse(props.location.search)
    ToolsService.getAvailableTools('all').then((response) => {
      if (response.data) {
        setAlltools(response.data)
        if (values.tool) {
          const filtervalue = response.data
            .filter((item) => item.name.toLowerCase() ===
            values.tool.toLowerCase())
          if (filtervalue.length && filtervalue.length === 1) {
            setFormData({
              ...formData,
              toolname: filtervalue[0]
            })
            if (values.project) {
              requestAccess.getprojects(filtervalue[0].name, values.project)
                .then((response1) => {
                //  this.setState({ allProjects: response.data, loadingoptions: false })
                  const filtervalue1 = response1.data
                    .filter((item) => item.key.toLowerCase() ===
                      values.project.toLowerCase())
                  if (filtervalue1.length && filtervalue1.length === 1) {
                    if (values.role) {
                      changeKey(filtervalue1[0], filtervalue[0], values.role)
                    } else {
                      changeKey(filtervalue1[0], filtervalue[0])
                    }
                  }
                })
            }
          }
        }
      }
    })
  }, [])

  // form data declaration
  const [formData, setFormData] = React.useState({
    toolname: '',
    projectkey: '',
    project: null,
    comments: '',
    username: '',
    rolename: '',
    usernameDisplay: '',
    clustor: '',
    apigeeOrgs: [],
    apigeeOrgTeamsAndRoles: [],
    apigeeTeam: '',
    org: ''
  })
  // reset form
  const resetForm = () => {
    setFormData({
      toolname: '',
      projectkey: '',
      project: null,
      comments: '',
      username: [],
      rolename: '',
      searchkey: '',
      clustor: '',
      apigeeOrgs: [],
      apigeeOrgTeamsAndRoles: [],
      apigeeTeam: '',
      org: ''
    })
    setAllusers([])
    setAllprojects([])
    setAllroles([])
    setExistingUsers([])
    seterrorAlert(false)
    setClusterArray([])
    setSurveyDialog(false)
    setSurveyData({

    })
    setSurveyValueRadio('')
    setSurveyJpmAlert(false)
    setSurveyJpmAlertMessage('')
  }
  // submit function
  const handleSubmit = () => {
    const usernames = []
    formData.username.forEach((username) => {
      usernames.push(username.name)
    })

    const input = {
      requestedBy: user.name,
      crowdUsers: usernames,
      requestType: formData.toolname.name,
      userRoles: formData.rolename,
      comments: formData.comments,
      action_type: 1,
      projects: formData.project.key
        ? formData.project.key
        : formData.project.name || formData.apigeeTeam || formData.project
    }

    submitRequest(input)
  }
  /* function to submit the request */
  const submitRequest = (input) => {
    let url = ''
    if (button === 'addtocart') {
      url = 'addTocart'
    } else if (button === 'submitnow') {
      url = 'submitRequestNow'
    }
    setLoading(true)
    requestAccess[url](input).then((response) => {
      setLoading(false)
      resetForm()
      if (button === 'addtocart') {
        setSuccessalertMessage('Added to cart successfully')
        CartService.cartSize(user.name).then((cartdata) => {
          setUser({
            ...user,
            cartsize: cartdata.data.cartSize
          })
        })
      } else {
        setSuccessalertMessage('Request Submitted Successfully')
        LoginService.getAdminaccess().then((approvaldata) => {
          setUser({
            ...user,
            approvalcount: approvaldata.data.approvalcount
          })
        })
      }
      setsuccessAlert(true)
      setTimeout(() => {
        setsuccessAlert(false)
      }, 20000)
      if (response.data.type === 'GitHub Copilot') {
        setGithubCopilotAlert(true)
        setTimeout(() => {
          setGithubCopilotAlert(false)
        }, 20000)
      }

      if (button === 'submitnow' && user.email && response.data.data.length) {
        EmailServices.sendMailtoApprover(response.data.data)
          .then((respone2) => { })
      }
    },
    (error) => {
      if (error) { }
      setLoading(false)
      setFailalertMessage(`
        Something went wrong. Contact system administrator!
      `)
      seterrorAlert(true)
    })
  }
  /* function to get the projects list based on the search key */
  const getProjects = (toolname, value) => {
    if (toolname.is_key) {
      setLoadingbar(true)
      requestAccess.getprojects(toolname.name, value).then((response) => {
        setAllprojects(response.data)
        setLoadingbar(false)
      })
    }
  }
  // Change clustor
  const changeClustor = (value) => {
    setFormData({
      ...formData,
      clustor: value.target.value
    })

    setLoading(true)
    setExistingUsers([])
    requestAccess
      .getRolesBasedOnClustor(formData.toolname.name,
        formData.project.key, formData.toolname.is_key, value.target.value)
      .then((response) => {
        setLoading(false)
        setAllroles(response.data)
      })
  }
  // set form values function
  const handleChange = (e) => {
    if (e.target.name === 'toolname') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        rolename: '',
        project: '',
        projectkey: '',
        org: '',
        apigeeTeam: '',
        username: []
      })
      setExistingUsers([])
      setAllroles([])
      if (!e.target.value.is_key && e.target.value.name !== 'Apigee') {
        ToolsService.getApiToolGroups(e.target.value.name).then((response) => {
          setAllprojects(response.data)
          if (response.data.length === 0) {
            changeKey({
              name: 'N/A'
            }, e.target.value)
          }
        }, (error) => {
          if (error) {
            setLoading(false)
            setFailalertMessage(`
              Something went wrong. Contact system administrator!
            `)
            seterrorAlert(true)
          }
        })
      }
      if (
        e.target.name === 'toolname' &&
        e.target.value.name === 'Apigee'
      ) {
        ApigeeService.getApigeeOrgs().then((response) => {
          setApigeeOrgs(response.data)
        })
      }
      if (
        e.target.name === 'toolname' &&
        e.target.value.name === 'XENA Framework Tools'
      ) {
        changeKey({
          'key': 'XENA',
          'key_app_name': 'XENA',
          'key_prefix': 'asx'
        }, undefined, undefined, e.target.value)
        setFormData({
          ...formData,
          project: 'XENA',
          projectkey: 'XENA',
          [e.target.name]: e.target.value
        })
      }
    } else if (
      e.target.name === 'org' &&
      formData.toolname.name === 'Apigee'
    ) {
      ApigeeService.getTeamsForOrgs(e.target.value).then((response) => {
        setApigeeOrgTeamsAndRoles(response.data)
        setFormData({
          ...formData,
          apigeeTeam: '',
          rolename: '',
          username: [],
          [e.target.name]: e.target.value
        })
        setAllroles([])
      })
    } else if (
      e.target.name === 'apigeeTeam' &&
      formData.toolname.name === 'Apigee'
    ) {
      setFormData({
        ...formData,
        rolename: '',
        username: [],
        [e.target.name]: e.target.value
      })
      const teamsAndRoles = apigeeOrgTeamsAndRoles
      for (const teamAndRoles of teamsAndRoles) {
        if (teamAndRoles.name === e.target.value) {
          setAllroles(teamAndRoles.roles)
        }
      }
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      })
    }
  }
  /* get Roles based on project key selection */
  const changeKey = (value, tool, role, XENA) => {
    if (value) {
      const key = value.key ? value.key : value.name

      setFormData({
        ...formData,
        toolname: tool || formData.toolname,
        projectkey: value.key
          ? `${value.key} - ${value.key_app_name}`
          : value.name,
        project: value,
        rolename: ''
      })
      if (formData.toolname.name === 'Cloudx Kubernetes') {
        requestAccess
          .getClustor(key)
          .then((response) => {
            setClusterArray(response.data)
          })
      } else {
        setExistingUsers([])
        setLoading(true)
        let toolName = tool ? tool.name : formData.toolname.name
        let pkey = key
        let isKey = tool ? tool.is_key : formData.toolname.is_key
        if (XENA && XENA.name && XENA.name === 'XENA Framework Tools') {
          toolName = 'XENA Framework Tools'
          pkey = 'XENA'
          isKey = true
        }
        requestAccess
          .getProjectRoles(toolName, pkey, isKey)
          .then((response) => {
            setLoading(false)
            setAllroles(response.data)
            if (role) {
              const filtervalue3 = response.data
                .filter((item) => item.name.toLowerCase() ===
                  role.toLowerCase())
              if (filtervalue3.length && filtervalue3.length === 1) {
                const object = new Object()
                object.target = {
                  value: filtervalue3[0].name
                }
                changeRole(object, tool, value)
              }
            }
            if (response.data.length === 1) {
              setFormData({
                ...formData,
                toolname: tool || formData.toolname,
                projectkey: value.key
                  ? `${value.key} - ${value.key_app_name}`
                  : value.name,
                project: value,
                rolename: response.data[0].name,
                username: []
              })
            }
          })
      }
    }
  }

  /* get available users for the selected role */
  const changeRole = (e, tool, project) => {
    setFormData({
      ...formData,
      rolename: e.target.value,
      projectkey: project
        ? project.key
          ? `${project.key} - ${project.key_app_name}`
          : project.name
        : formData.projectkey,
      project: project || formData.project,
      toolname: tool || formData.toolname,
      username: []
    })
    setLoading(true)
    requestAccess.getuserbasedonrole(e.target.value).then((response) => {
      const getSurveyData = {
        toolid: formData.toolname.toolid,
        role_name: e.target.value,
        project: formData.project
      }
      SurveyService.getSurveyQuestion(getSurveyData)
        .then((surveyResponse) => {
          setExistingUsers(response.data)
          if (surveyResponse.data.status === 200 &&
              surveyResponse.data.data &&
              typeof surveyResponse.data.data !== 'string') {
            setSurveyData({
              ...surveyResponse.data
            })
            setSurveyValueText('XENA Teams Channel, XENA confluence page ...')
            setSurveyDialog(true)
          }
          setLoading(false)
        })
    })
  }
  const changeUser = (value) => {
    if (value && value.length) {
      setFormData({
        ...formData,
        username: value
      })

      if (formData.toolname.is_key) {
        requestAccess
          .canAccountRequestAccessToGroup(formData.toolname.name,
            formData.project ? formData.project.key_prefix
              : formData.org,
            formData.rolename,
            value[value.length - 1].name,
            formData.project ? formData.project.key
              : formData.apigeeTeam)
          .then((response) => {
            if (response.data[0].check) {
              validationAlert(value[value.length - 1].name, value)
            } else {
              const message =
                response.data[0] ? (
                  <div>
                    <Typography variant='subtitle2' gutterBottom>
                      Only users that belong to any one of the following
                      organization unit can request for this group
                    </Typography>
                    <ul>
                      {response.data[0].roles.map((role) => (
                        <li>{role.ou}</li>
                      ))}
                    </ul>

                    <Typography variant='subtitle2' gutterBottom>
                      This account is a member of following Organization Unit:
                    </Typography>
                    <ul>
                      <li>{response.data[0].user_roles}</li>
                    </ul>
                  </div>
                ) : null
              value.splice(value.length - 1, 1)
              setAlertwithok(true)
              setalertboxMessage(message)
            }
          })
      } else {
        validationAlert(value[value.length - 1].name, value)
      }
    }
  }

  const validationAlert = (username, value) => {
    let key
    if (formData.toolname.is_key === true) {
      key = formData.project.key
    } else {
      key = formData.project.name || formData.apigeeTeam
    }
    setLoading(true)
    requestAccess
      .GetRolesForProjectsValidation(username, formData.toolname.name, key, 1)
      .then((response) => {
        setLoading(false)
        const result = response.data
        let warningMsg = ''
        result.forEach((currentValue, idx, array) => {
          if (currentValue.cart_requests &&
            currentValue.cart_requests.toLowerCase() ===
              formData.rolename.toLowerCase()) {
            warningMsg = `Selected user with role already added to the cart by
              ${currentValue.cart_user}`
          } else if (currentValue.pending_requests &&
            currentValue.pending_requests.toLowerCase() ===
              formData.rolename.toLowerCase()) {
            warningMsg = 'Selected user with role is pending for approval.'
          } else if (currentValue.already_access &&
            currentValue.already_access.toLowerCase() ===
              formData.rolename.toLowerCase()) {
            warningMsg = 'Selected user already has this role.'
          } else if (currentValue.processing_request &&
            currentValue.processing_request.toLowerCase() ===
              formData.rolename.toLowerCase()) {
            warningMsg = 'Selected user with role is pending for approval.'
          }
          if (formData.toolname.name.toLowerCase() === 'qtest' && (
            (currentValue.cart_requests &&
              currentValue.cart_requests.toLowerCase()
                .indexOf(formData.project.key.toLowerCase()) > -1 &&
              currentValue.cart_requests.toLowerCase()
                .indexOf(formData.toolname.name.toLowerCase()) > -1) ||
            (currentValue.pending_requests &&
              currentValue.pending_requests.toLowerCase()
                .indexOf(formData.project.key.toLowerCase()) > -1 &&
              currentValue.pending_requests.toLowerCase()
                .indexOf(formData.toolname.name.toLowerCase()) > -1) ||
            (currentValue.already_access &&
              currentValue.already_access.toLowerCase()
                .indexOf(formData.project.key.toLowerCase()) > -1 &&
              currentValue.already_access.toLowerCase()
                .indexOf(formData.toolname.name.toLowerCase()) > -1) ||
            (currentValue.processing_request &&
              currentValue.processing_request.toLowerCase()
                .indexOf(formData.project.key.toLowerCase()) > -1 &&
              currentValue.processing_request.toLowerCase()
                .indexOf(formData.toolname.name.toLowerCase()) > -1)
          )) {
            let role = ''
            if (currentValue.cart_requests &&
              currentValue.cart_requests.toLowerCase()
                .indexOf(formData.project.key.toLowerCase()) > -1) {
              role = `Access request for user ${username} for 
                ${currentValue.cart_requests} is already in cart.`
            } else if (currentValue.pending_requests &&
              currentValue.pending_requests.toLowerCase()
                .indexOf(formData.project.key.toLowerCase()) > -1) {
              role = `Access reqeust for user ${username} for 
                ${currentValue.pending_requests} is in pending reqeust.`
            } else if (currentValue.already_access &&
              currentValue.already_access.toLowerCase()
                .indexOf(formData.project.key.toLowerCase()) > -1) {
              role = `User ${username} is already part of this project 
                and present in the group ${currentValue.already_access}.`
            } else if (currentValue.processing_request &&
              currentValue.processing_request.toLowerCase()
                .indexOf(formData.project.key.toLowerCase()) > -1) {
              role = `Access request for user ${username} for 
                ${currentValue.processing_request} is in processing.`
            }

            warningMsg = `${role}`
          }
          if (idx === array.length - 1) {
            if (warningMsg !== '') {
              value.splice(value.length - 1, 1)
              setAlertwithok(true)
              setalertboxMessage(warningMsg)
            } else if (formData.toolname.is_key === true) {
              const roles = []
              roles.push(formData.rolename)
              const input = {
                crowdUsers: username,
                requestType: formData.toolname.name,
                projects: formData.project.key ? formData.project.key
                  : formData.project,
                userRoles: roles
              }
              setLoading(true)
              requestAccess.checkGroupHierarchy(input).then((response1) => {
                const {
                  data
                } = response1
                setLoading(false)
                if (data) {
                } else {
                  warningMsg = data
                  Setalertbox(true)
                  setalertboxMessage(warningMsg)
                }
              })
            }
          }
        })
        if (formData.toolname.name === 'Apigee') {
          ApigeeService.getUsersForRole(formData.org, formData.rolename)
            .then((response) => {
              const username1 = `${username.toLowerCase()}@its.jnj.com`
              if (response.data.length &&
                response.data.toString().toLowerCase().includes(username1)) {
                warningMsg = 'Selected user already has this role.'
                value.splice(value.length - 1, 1)
                setAlertwithok(true)
                setalertboxMessage(warningMsg)
              }
            })
        }
        if (formData.toolname.name.toLowerCase() === 'applitools') {
          const username1 = `${username.toLowerCase()}@its.jnj.com`

          if (formData.project.members && formData.project.members[username1]) {
            if (
              formData.rolename.toLowerCase() === 'viewer' &&
              formData.project.members[username1].isViewer
            ) {
              warningMsg = 'Selected user already has this role.'
              value.splice(value.length - 1, 1)
              setAlertwithok(true)
              setalertboxMessage(warningMsg)
            } else if (formData.rolename.toLowerCase() === 'regular') {
              warningMsg = 'Selected user already has this role.'
              value.splice(value.length - 1, 1)
              setAlertwithok(true)
              setalertboxMessage(warningMsg)
            }
          }
        } else if (formData.toolname.name.toLowerCase() === 'blazemeter') {
          setLoading(true)
          requestAccess
            .checkUseraccessforAPItoolgroup(formData.toolname.name,
              username,
              formData.project.id)
            .then((response2) => {
              setLoading(false)
              const {
                data
              } = response2
              if (data.length && data[0].roles) {
                if (
                  data[0].roles.indexOf(formData.rolename.toLowerCase()) > -1
                ) {
                  warningMsg = 'Selected user already has this role.'
                  value.splice(value.length - 1, 1)
                  setAlertwithok(true)
                  setalertboxMessage(warningMsg)
                }
              }
            }, (error) => {
              if (error) {
                setLoading(false)
                setFailalertMessage(`
                  Something went wrong. Contact system administrator!
                `)
                seterrorAlert(true)
              }
            })
        } else if (formData.toolname.name.toLowerCase() === 'saucelabs') {
          setLoading(true)
          requestAccess
            .checkUseraccessforAPItool(formData.toolname.name, username)
            .then((response3) => {
              setLoading(false)
              const {
                data
              } = response3
              if (data.length && data[0].username) {
                warningMsg = 'Selected user already has this role.'
                value.splice(value.length - 1, 1)
                setAlertwithok(true)
                setalertboxMessage(warningMsg)
              }
            }, (error) => {
              if (error) {
                setLoading(false)
                setFailalertMessage(`
                  Something went wrong. Contact system administrator!
                `)
                seterrorAlert(true)
              }
            })
        }
      })
  }
  /* function to get the users list based on the search key */
  const getUsers = (value) => {
    setFormData({
      ...formData,
      searchkey: value
    })
    setLoadingoptionsUser(true)
    requestAccess.getallusersforautocomplete(value).then((response) => {
      setAllusers(response.data)
      setLoadingoptionsUser(false)
    })
  }
  /* to close the alert box */
  const handleClose = () => {
    Setalertbox(false)
    setAlertwithok(false)
  }
  const parseHtml = (htmlMessage) => parse(htmlMessage)
  const surveyJpmAlertClose = () => {
    setSurveyJpmAlert(false)
  }
  const handleSurveyValueChange = (type, value) => {
    if (type === 'text') {
      setSurveyValueText(value)
    } else if (type === 'radio') {
      setSurveyValueRadio(value)
      if (value.toLowerCase() === 'no') {
        SurveyService.getJpmDetails().then((storedSurveyReponse) => {
          setSurveyJpmAlertMessage(storedSurveyReponse.data.data)
        })
      }
    }
  }

  const captureSurveyDetails = (event) => {
    event.preventDefault()
    if (surveyValueRadio.toLowerCase() === 'no') {
      setSurveyJpmAlert(true)
    }
    const dataToStore = {
      surveyResponse: [
        {
          surveyId: surveyData.data[0].survey,
          surveyQuestion: surveyData.data[0].id,
          surveyResponse: surveyValueRadio
        }
      ]
    }
    if (surveyValueRadio === 'Yes') {
      dataToStore.surveyResponse.push({
        surveyId: surveyData.data[1].survey,
        surveyQuestion: surveyData.data[1].id,
        surveyResponse: surveyValueText
      })
    }
    setLoading(true)
    SurveyService.storeSurveyResponse(dataToStore)
      .then((storedSurveyReponse) => {
        setSurveyDialog(false)
        setLoading(false)
        setSurveyData({

        })
        setSurveyValueRadio('')
        if (storedSurveyReponse.data.status === 'success') {
          setsuccessAlert(true)
          setSuccessalertMessage('Survey Response is saved, ' +
            'Please proceed with the request')
        } else if (storedSurveyReponse.data.status === 'failed') {
          seterrorAlert(true)
          setFailalertMessage('Survey Response is not saved. ' +
            'Please inform the request details to your adimistrator ' +
            'before proceed with this request')
        } else {
          seterrorAlert(true)
          setFailalertMessage('Something went wrong. Please contact system ' +
            'administrator with the request details')
        }
      })
  }

  /* function to set the clicked button event as addtocart or submitnow */
  const setType = (type, e) => {
    setButton(type)
  }
  const setValuenull = (value) => {
    value.splice(0, 1)
    value = []
  }
  return (
    <div>
      {loading ? <LinearProgress /> : null}
      {' '}
      <div className={classes.alertroot}>
        {' '}
        {successAlert ? (
          <Alert
            severity='success'
            onClose={() => setsuccessAlert(false)}
          >
            {successalertMessage}
          </Alert>
        ) : null}
        {errorAlert ? (
          <Alert
            severity='error'
            onClose={() => seterrorAlert(false)}
          >
            {failalertMessage}
          </Alert>
        ) : null}
        {githubCopilotAlert ? (
          <Alert severity='info'>
            This request once approved will activate GitHub Copilot license.
            For further onboarding details please refer to
            <a href='https://confluence.jnj.com/display/ABES/XENA+AI+Assisted+Software+Development'>
               XENA AI Assisted Software Development page
            </a>
          </Alert>
        ) : null}
      </div>
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={12} md={7}>
          <Card variant='outlined'>
            <ValidatorForm ref={form} onSubmit={(event) => handleSubmit(event)}>
              <CardContent>
                <Typography variant='subtitle1' gutterBottom>
                  Role Based Access Request
                </Typography>
                <div style={{
                  paddingTop: 10
                }}
                >
                  <FormControl className={classes.formControl}>
                    <SelectValidator
                      label='Tool'
                      name='toolname'
                      required
                      fullWidth
                      value={formData.toolname ? formData.toolname : ''}
                      onChange={handleChange}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    >
                      {alltools.map((value, index) => (
                        <MenuItem value={value} key={value.name}>
                          {value.name}
                        </MenuItem>
                      ))}
                    </SelectValidator>
                  </FormControl>
                  {formData.toolname.name === 'Apigee' ? (
                    <FormControl className={classes.formControl}>
                      <SelectValidator
                        label='Org'
                        name='org'
                        required
                        onChange={handleChange}
                        value={formData.org}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      >
                        {apigeeOrgs.map((value, index) => (
                          <MenuItem value={value} key={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </SelectValidator>
                    </FormControl>
                  ) : null}
                  {formData.toolname.name === 'Apigee' ? (
                    <FormControl className={classes.formControl}>
                      <SelectValidator
                        label='Apigee Team'
                        name='apigeeTeam'
                        required
                        onChange={handleChange}
                        value={formData.apigeeTeam}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      >
                        {apigeeOrgTeamsAndRoles.map((value,
                          index) => (
                          <MenuItem value={value.name} key={value.name}>
                            {value.name}
                          </MenuItem>
                        ))}
                      </SelectValidator>
                    </FormControl>
                  ) : null}
                  {formData.projectkey !== 'N/A' &&
                  formData.toolname.name !== 'Apigee' &&
                    formData.toolname !== 'Saucelabs' ? (
                      <FormControl className={classes.formControl}>
                        <Autocomplete
                          id='combo-box-demo'
                          options={allProjects}
                          disabled={formData.toolname === ''}
                          value={
                            formData.projectkey ? formData.projectkey : ''
                          }
                          getOptionLabel={(option) => (option.key
                            ? `${option.key} - ${option.key_app_name}`
                            : option.name
                              ? option.name
                              : option)}
                          loading={allProjects.length === 0 && loadingbar}
                          getOptionSelected={
                            (option, value) => option.name === value.name
                          }
                          onChange={(event, value) => changeKey(value)}
                          renderInput={(params) => (
                            <TextValidator
                              {...params}
                              label='Project or Team *'
                              name='projectkey'
                              onChange={
                                (event) => getProjects(formData.toolname,
                                  event.target.value)
                              }
                              value={formData.projectkey}
                              validators={['required']}
                              errorMessages={['This field is required']}
                            />
                          )}
                        />
                      </FormControl>
                    ) : null}
                  {formData.toolname.name === 'Cloudx Kubernetes' &&
                    clusterArray.length > 0
                    ? (
                      <FormControl className={classes.formControl}>
                        <SelectValidator
                          label='Cluster'
                          name='clustor'
                          value={formData.clustor ? formData.clustor : ''}
                          onChange={changeClustor}
                          validators={['required']}
                          errorMessages={['This field is required']}
                        >
                          {clusterArray.map((value, index) => (
                            <MenuItem value={value.clustor} key={value.clustor}>
                              {value.clustor}
                            </MenuItem>
                          ))}
                        </SelectValidator>
                      </FormControl>
                    )
                    : null}
                  <FormControl className={classes.formControl}>
                    <SelectValidator
                      label='Requested Role'
                      name='rolename'
                      fullWidth
                      // disabled={
                      // formData.toolname === '' || formData.projectkey === ''
                      // }
                      required
                      value={formData.rolename ? formData.rolename : ''}
                      onChange={(e) => changeRole(e)}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    >
                      {allRoles.map((value, index) => (
                        <MenuItem
                          value={value.name || value}
                          key={value.name || value}
                        >
                          {value.name ? value.name : value}
                        </MenuItem>
                      ))}
                    </SelectValidator>
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id='combo-box-demo'
                      multiple
                      options={allUsers}
                      getOptionLabel={(option) => (option.name
                        ? `${option.name} - ${option.displayname}`
                        : option)}
                      disabled={
                        formData.toolname === '' ||
                        (formData.toolname &&
                        formData.toolname.name !== 'Apigee' &&
                        (formData.projectkey === '' ||
                        formData.rolename === '')) ||
                        (formData.toolname.name === 'Apigee' &&
                        formData.apigeeTeam === '')
                      }
                      loading={allUsers.length === 0 && loadingoptionsUser}
                      filterOptions={(options, state) => options}
                      getOptionSelected={
                        (option, value) => option.name === value.name
                      }
                      renderTags={
                        (value, getTagProps) => value
                          .map((option, index) => (formData.username.length ? (
                            <Chip
                              label={
                                option.name
                                  ? `${option.name} - ${option.displayname}`
                                  : option
                              }
                              {...getTagProps({
                                index
                              })}
                            />
                          ) : (
                            setValuenull(value)
                          )))
                      }
                      onChange={(event, value) => changeUser(value)}
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          label='Choose a user *'
                          name='username'
                          onChange={(event) => getUsers(event.target.value)}
                          value={formData.username}
                          validators={['required']}
                          errorMessages={['This field is required']}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextValidator
                      label='Reason for Requesting Access*'
                      name='comments'
                      onChange={handleChange}
                      value={formData.comments ? formData.comments : ''}
                      multiline
                      fullWidth
                      rows={1}
                      rowsMax={4}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />
                  </FormControl>
                </div>
              </CardContent>
              <CardActions className={classes.footer}>
                <Button
                  variant='contained'
                  size='small'
                  onClick={resetForm.bind(this)}
                >
                  Reset
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  size='small'
                  disabled={loading || user.switch_user}
                  onClick={setType.bind(this, 'addtocart')}
                >
                  Add to Cart
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  type='submit'
                  disabled={loading || user.switch_user}
                  size='small'
                  onClick={setType.bind(this, 'submitnow')}
                >
                  Submit Now
                </Button>
              </CardActions>
            </ValidatorForm>
          </Card>
        </Grid>
        {existingUsers.length ? (
          <Grid item xs={12} md={5}>
            <Card variant='outlined'>
              <CardContent>
                {' '}
                <Typography variant='subtitle1' gutterBottom>
                  Existing users
                </Typography>
                <List
                  component='nav'
                  aria-label='main mailbox folders'
                  className={classes.userlist}
                >
                  {existingUsers.map((oneUser) => (
                    <ListItem button key={oneUser.username}>
                      {oneUser.displayname}
                      ,
                      {oneUser.username}
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        ) : null}
        <AlertDialog
          handleClose={handleClose}
          alertopen={alertwithok}
          key='alert2'
          message={alertboxMessage}
          okbuttonalert
          title='Alert'
        />
        <AlertDialog
          handleClose={handleClose}
          alertopen={alertbox}
          message={alertboxMessage}
          confirmbutton={handleClose}
          key='alert1'
          okbuttonalert={false}
          title='Alert'
        />
        <AlertDialog
          handleClose={surveyJpmAlertClose}
          alertopen={surveyJpmAlert}
          key='alert3'
          message={parseHtml(surveyJpmAlertMessage)}
          okbuttonalert
          title='JPM Info'
        />
      </Grid>

      {/* Dialog Box for Change Approver */}
      <Dialog
        open={surveyDialog}
        onClose={handleClose}
        maxWidth='xs'
        fullWidth='xs'
      >
        {alertLoading ? <LinearProgress /> : null}
        <ValidatorForm
          ref={form}
          onSubmit={(event) => captureSurveyDetails(event)}
        >
          <DialogTitle id='alert-dialog-title'>
            Survey
          </DialogTitle>
          <DialogContent>
            <div style={{
              paddingTop: 10
            }}
            >
              {surveyData && surveyData.data && surveyData.data.length > 0 ? (
                <FormControl className={`${classes.formControl}`}>
                  <div>
                    <Typography
                      htmlFor='workflowTypeLabel'
                    >
                      {surveyData &&
                      surveyData.data &&
                      surveyData.data.length > 0 &&
                      surveyData.data[0].survey_question}
                    </Typography>
                  </div>
                  <div>
                    <RadioButtons
                      radio={['Yes', 'No']}
                      selectedvalue={surveyValueRadio}
                      onChange={
                        (value) => handleSurveyValueChange('radio', value)

                      }
                    />
                  </div>
                </FormControl>
              ) : null}
              {surveyData && surveyData.data && surveyData.data.length > 1 &&
                surveyValueRadio === 'Yes' &&
                surveyValueRadio !== '' ? (
                  <FormControl
                    className={`${classes.formControl}`}
                  >
                    <Typography
                      htmlFor='workflowTypeLabel'
                      className={classes.labelDisplay}
                    >
                      {surveyData &&
                        surveyData.data && surveyData.data.length &&
                        surveyData.data.length > 1 &&
                        surveyData.data[1].survey_question}
                    </Typography>
                    <FormControl className={classes.formControl}>
                      <TextValidator
                        label='Place your answers*'
                        name='survey1_answers'
                        onChange={(e) => handleSurveyValueChange('text',
                          e.target.value)}
                        value={surveyValueText}
                        multiline
                        rows={2}
                        fullWidth
                        rowsMax={10}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      />
                    </FormControl>
                  </FormControl>
                ) : null}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              disabled={
                (loading || user.switch_user) ||
                (surveyValueRadio === 'Yes' && surveyValueText === '') ||
                (surveyValueRadio === '')
              }
            >
              Submit
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>

    </div>
  )
}

export default ByRoleComponent
