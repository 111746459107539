import React, {
  useContext
} from 'react'
import {
  LinearProgress, Grid, makeStyles, TextField, Button
} from '@material-ui/core'
import AdminServices from '../../services/api/adminConfigService'
import UserContext from '../contexts/UserContext'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  textfield: {
    width: '65%'
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  backbtn: {
    float: 'right',
    marginRight: 20
  }
}))

export default function AdminGroupComponent ({
  configdata, handleChange
}) {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const [adminGroupJson, setAdminGroupJson] = React.useState({
  })
  const {
    user
  } = useContext(UserContext)

  /* initial method will be called when the page gets loaded */
  React.useEffect(() => {
    setLoading(true)
    // AdminServices.getAdminGroupForBitbucketPrefix('idms').then((result) => {
    //   setLoading(false)
    //   setAdminGroupJson(result.data[0].json_object_agg)
    // })
  }, [])

  const handleBBGroupChange = (key, event) => {
    // const newGroup = event.target.value
    adminGroupJson[key] = event.target.value
    setAdminGroupJson({
      ...adminGroupJson
    })
  }

  const saveConfig = () => {

  }

  return (
    <div className={classes.root}>

      <br />
      {Object.keys(configdata.createBitbucket).map((keyName, keyIndex) => (
        <Grid container spacing={3}>

          <Grid item xs={2}>
            <strong>{keyName.toUpperCase()}</strong>
          </Grid>
          <Grid item xs={10}>
            <TextField
              value={configdata.createBitbucket[keyName]}
              name={keyName}
              onChange={(e) => handleChange('createBitbucket', e)}
              className={classes.textfield}
            />
          </Grid>

        </Grid>
      ))}
      <div style={{
        marginTop: 20
      }}
      >
        <strong>Note : </strong>
        <span>
          Multiple admin groups can be provided by using comma separated vaues
        </span>
      </div>
    </div>
  )
}
