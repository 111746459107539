import React, {
  useContext
} from 'react'
import {
  LinearProgress,
  makeStyles,
  Tabs,
  Tab,
  Grid,
  Box,
  Typography,
  TextField,
  Button
} from '@material-ui/core'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import {
  Alert
} from '@material-ui/lab'
import adminServices from '../../services/api/adminConfigService'
import UserContext from '../contexts/UserContext'

function TabPanel (props) {
  const {
    children, value, index, ...other
  } = props

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  }
}))

export default function EmailContentComponent () {
  const classes = useStyle()
  const {
    user
  } = useContext(UserContext)
  const [loading, setLoading] = React.useState(false)
  const [value, setValue] = React.useState(0)
  const [responseData, setResponseData] = React.useState([])
  const [successAlert, setsuccessAlert] = React.useState(false)
  const [successalertMessage, setSuccessalertMessage] = React.useState(null)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [failalertMessage, setFailalertMessage] = React.useState(null)

  /* Get the email HTML content when email page loads */
  React.useEffect(() => {
    getEmailContent()
  }, [])

  // Get email content when page loads
  const getEmailContent = () => {
    let tempArray = []
    setLoading(true)
    adminServices.getEmailcontent().then((response) => {
      setLoading(false)
      if (response.data) {
        tempArray = response.data
        tempArray.map((jsValue, ind) => {
          switch (jsValue.name) {
          case 'rejected': tempArray[ind].tabname = 'Reject'
            break
          case 'submitted': tempArray[ind].tabname = 'Submit'
            break
          case 'approved': tempArray[ind].tabname = 'Approve'
            break
          case 'reminder': tempArray[ind].tabname = 'Reminder'
            break
          case 'keyexpired': tempArray[ind].tabname = 'KeyExpired'
            break
          default: tempArray[ind].tabname = tempArray[ind].name
            break
          }
        })
        setResponseData(tempArray)
      }
    })
  }

  // Set tab change values
  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }

  // Set tab change values
  const parseHtml = (htmlMessage) => parse(htmlMessage)

  // Handling changing subject and mail content
  const changeEmailContent = (event, i) => {
    const tempArray = responseData
    tempArray[i][event.target.name] = event.target.value
    setResponseData([...tempArray])
  }

  // Saving email content
  const saveEmailContent = () => {
    setLoading(true)
    adminServices.saveEmailcontent(responseData).then((response) => {
      getEmailContent()
      setLoading(false)
      setSuccessalertMessage('Email configuration saved successfully')
      setsuccessAlert(true)
      setTimeout(() => {
        setsuccessAlert(false)
      }, 20000)
    }, (error) => {
      if (error) {
        setLoading(false)
        setFailalertMessage(`
          Something went wrong. Contact system administrator!
        `)
        seterrorAlert(true)
      }
    })
  }

  return (
    <div>
      {loading ? <LinearProgress /> : null}
      <div className={classes.alertroot}>
        {' '}
        {successAlert ? (
          <Alert severity='success'>{successalertMessage}</Alert>
        ) : null}
        {errorAlert ? (
          <Alert
            severity='error'
            onClose={() => seterrorAlert(false)}
          >
            {failalertMessage}
          </Alert>
        ) : null}
      </div>
      <Button
        variant='contained'
        className='pull-right'
        disabled={loading || user.switch_user}
        color='primary'
        aria-label='saveemail'
        onClick={() => saveEmailContent()}
      >
        Save
      </Button>
      <Tabs
        value={value}
        onChange={handleTabChange}
        variant='scrolhandleTabChangelable'
        scrollButtons='on'
        indicatorColor='primary'
        textColor='primary'
        aria-label='scrollable force tabs example'
        style={{
          marginLeft: '-30px'
        }}
      >
        {responseData.map((json, ind) => (
          <Tab key={responseData[ind].name} label={responseData[ind].tabname} />
        ))}
      </Tabs>
      {responseData.map((json, ind) => (
        <TabPanel index={ind} value={value} key={responseData[ind].name}>
          <div>
            <Grid container spacing={1} className={classes.root}>
              <Grid item xs={12} md={6}>
                <TextField
                  id='standard-basic'
                  label='Standard'
                  name='subject'
                  value={responseData[ind].subject}
                  className={classes.formControl}
                  onChange={(event) => changeEmailContent(event, ind)}
                />
                <br />
                <br />
                <TextField
                  aria-label='minimum height'
                  multiline
                  rows={17}
                  name='message'
                  placeholder='Message'
                  value={responseData[ind].message}
                  className={classes.formControl}
                  onChange={(event) => changeEmailContent(event, ind)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <div>{parseHtml(responseData[ind].message)}</div>
              </Grid>
            </Grid>
          </div>
        </TabPanel>
      ))}
    </div>
  )
}
