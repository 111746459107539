import React, {
  useContext, useState, useRef, useEffect
} from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  makeStyles,
  FormControl,
  LinearProgress,
  Checkbox,
  Tooltip,
  FormControlLabel,
  List,
  MenuItem,
  ListItem,
  InputAdornment
} from '@material-ui/core'
import {
  useParams, Link
} from 'react-router-dom'
import {
  TextValidator, ValidatorForm, SelectValidator
} from 'react-material-ui-form-validator'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import InfoIcon from '@material-ui/icons/Info'
import queryString from 'query-string'
import RadioButtons from '../inputs/radio'
import ApigeeService from '../../services/api/apigeeService'
import UserContext from '../contexts/UserContext'
import AlertDialog from '../alertbox/alertboxComponent'
import SupportContext from '../contexts/SupportContext'
import PathContext from '../contexts/PathContext'
import SupportComponent from './supportComponent'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  pullRight: {
    float: 'right'
  },
  columnDisplay: {
    display: 'flex',
    flexDirection: 'row'
  },
  labelDisplay: {
    margin: '10px',
    width: '25%'
  },
  inputDisplay: {
    width: '72%'
  },
  inputDisplayQTId: {
    width: '42%'
  },
  inputDisplayQTOId: {
    paddingLeft: '2%',
    width: '30%'
  },
  error: {
    color: 'red'
  },
  errorMessage: {
    paddingLeft: '27%',
    width: '70%'
  },
  centerText: {
    textAlign: 'center',
    fontSize: '14px',
    color: '#737373'
  },
  hr: {
    display: 'block',
    marginTop: '0.5em',
    marginBottom: '0.5em',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderStyle: 'inset',
    borderWidth: '1px',
    borderColor: '#737373'
  },
  dashedSeparator: {
    border: '1px dashed #737373 !important'
  },
  rolesList: {
    paddingTop: 0
  },
  rolesListItem: {
    paddingTop: 0
  },
  rolesLabel: {
    '& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
      width: '20vw'
    }
  },
  rolesListLabel: {
    marginRight: '0'
  },
  rolesListEnvs: {
    '&::after': {
      content: '"*"',
      color: 'red'
    }
  },
  extendedInput: {
    width: '55vw'
  }
}))

export default function SupportApigeeSelfServiceComponent (props) {
  const queryparams = queryString.parse(props.location.search)
  const {
    setPath
  } = useContext(PathContext)
  const {
    user
  } = useContext(UserContext)
  const {
    support, setSupport
  } = useContext(SupportContext)
  const {
    type
  } = useParams()
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const form = useRef(null)
  const [allOrgs, setAllOrgs] = useState([])
  const [teamsAndRolesForOrg, setTeamsAndRolesForOrg] = useState([])
  const [targetServersForOrgAndEnv, setTargetServersForOrgAndEnv] = useState([])
  const [allRoles, setAllRoles] = React.useState([])
  const [allEnvs, setEnvs] = useState([])
  const [allCompanies, setCompanies] = useState([])
  const [allProducts, setProducts] = useState([])
  // eslint-disable-next-line max-len
  const [allTypes, setAllTypes] = useState(['Proxy', 'Shared Flow', 'KVM', 'Target Server', 'API Product', 'Company App', 'Cache', 'Cloudflare DNS Mapping'])
  const [allZones, setAllZones] = useState([
    'jnj.apigee.jnj.com', 'jnj-prerelease.apigee.jnj.com',
    'jnj-internal.apigee.jnj.com', 'jnj-internal-prerelease.apigee.jnj.com',
    'jnj-global.apigee.jnj.com', 'jnj-global-prerelease.apigee.jnj.com'])
  const [alertwithok, setAlertwithok] = React.useState(false)
  const [alertboxMessage, setalertboxMessage] = React.useState('')
  const [submitLoader, setSubmitLoader] = useState(false)

  // Returns alert and it's message
  const showAlert = () => {
    if (support.success === true && support.message !== '') {
      return (
        <Alert
          severity='success'
          onClose={() => setSupport({
            success: false,
            message: ''
          })}
        >
          {support.message}
        </Alert>
      )
    }
    if (support.success === false && support.message !== '') {
      return (
        <Alert
          severity='error'
          onClose={() => setSupport({
            success: false,
            message: ''
          })}
        >
          {support.message}
        </Alert>
      )
    }
  }

  useEffect(() => {
    setLoading(true)
    ApigeeService.getApigeeOrgs().then((response) => {
      setLoading(false)
      setAllOrgs(response.data)
    })
  }, [])

  // Initializing form data
  const [formData, setFormData] = useState({
    orgName: '',
    teamName: '',
    roles: [],
    type: '',
    envs: [],
    resourceName: '',
    zoneName: '',
    companyName: '',
    productName: '',
    domain: '',
    targetServer: ''
  })

  const handleSubmit = (event) => {
    event.preventDefault()
    submitRequest()
  }

  const resetFormData = () => {
    setFormData({
      orgName: '',
      teamName: '',
      roles: [],
      type: '',
      envs: [],
      resourceName: '',
      zoneName: '',
      companyName: '',
      productName: '',
      domain: '',
      targetServer: ''
    })
  }

  const submitRequest = () => {
    setSubmitLoader(true)
    if (formData.type === 'KVM') {
      ApigeeService.createKVM({
        name: `${formData.teamName}_${formData.resourceName}`,
        org: formData.orgName,
        envs: formData.envs,
        roles: formData.roles
      }).then((response) => {
        setSubmitLoader(false)
        if (response.status === 200) {
          setSupport({
            success: true,
            // eslint-disable-next-line max-len
            message: `KVM ${formData.teamName}_${formData.resourceName} successfully created`
          })
          resetFormData()
        } else {
          setSupport({
            success: false,
            message: 'Something went wrong. Contact system administrator!'
          })
        }
        resetFormData()
      }, (error) => {
        setLoading(false)
        setSubmitLoader(false)
        if (error) {
          setSupport({
            success: false,
            message: [error.response.data ? error.response.data
              : 'Something went wrong. Contact system administrator!']
          })
        }
      })
    } else if (formData.type === 'Proxy') {
      ApigeeService.createProxy({
        name: `${formData.teamName}_${formData.resourceName}`,
        org: formData.orgName,
        roles: formData.roles
      }).then((response) => {
        setSubmitLoader(false)
        if (response.status === 200) {
          setSupport({
            success: true,
            // eslint-disable-next-line max-len
            message: `Proxy ${formData.teamName}_${formData.resourceName} successfully created`
          })
        } else {
          setSupport({
            success: false,
            message: 'Something went wrong. Contact system administrator!'
          })
        }
        resetFormData()
      }, (error) => {
        setLoading(false)
        setSubmitLoader(false)
        if (error) {
          setSupport({
            success: false,
            message: [error.response.data ? error.response.data
              : 'Something went wrong. Contact system administrator!']
          })
        }
      })
    } else if (formData.type === 'Target Server') {
      ApigeeService.createTargetServer({
        name: `${formData.teamName}_${formData.resourceName}`,
        envs: formData.envs,
        org: formData.orgName,
        roles: formData.roles
      }).then((response) => {
        setSubmitLoader(false)
        if (response.status === 200) {
          setSupport({
            success: true,
            // eslint-disable-next-line max-len
            message: `Target Server ${formData.teamName}_${formData.resourceName} successfully created`
          })
        } else {
          setSupport({
            success: false,
            message: 'Something went wrong. Contact system administrator!'
          })
        }
        resetFormData()
      }, (error) => {
        setLoading(false)
        setSubmitLoader(false)
        if (error) {
          setSupport({
            success: false,
            message: [error.response.data ? error.response.data
              : 'Something went wrong. Contact system administrator!']
          })
        }
      })
    } else if (formData.type === 'API Product') {
      ApigeeService.createApiProduct({
        name: `${formData.teamName}_${formData.resourceName}`,
        org: formData.orgName,
        envs: formData.envs,
        roles: formData.roles
      }).then((response) => {
        setSubmitLoader(false)
        if (response.status === 200) {
          setSupport({
            success: true,
            // eslint-disable-next-line max-len
            message: `API Product ${formData.teamName}_${formData.resourceName} successfully created`
          })
        } else {
          setSupport({
            success: false,
            message: 'Something went wrong. Contact system administrator!'
          })
        }
        resetFormData()
      }, (error) => {
        setLoading(false)
        setSubmitLoader(false)
        if (error) {
          setSupport({
            success: false,
            message: [error.response.data ? error.response.data
              : 'Something went wrong. Contact system administrator!']
          })
        }
      })
    } else if (formData.type === 'Shared Flow') {
      ApigeeService.createSharedFlow({
        name: `${formData.teamName}_${formData.resourceName}`,
        org: formData.orgName,
        roles: formData.roles
      }).then((response) => {
        setSubmitLoader(false)
        if (response.status === 200) {
          setSupport({
            success: true,
            // eslint-disable-next-line max-len
            message: `Shared Flow ${formData.teamName}_${formData.resourceName} successfully created`
          })
        } else {
          setSupport({
            success: false,
            message: 'Something went wrong. Contact system administrator!'
          })
        }
        resetFormData()
      }, (error) => {
        setLoading(false)
        setSubmitLoader(false)
        if (error) {
          setSupport({
            success: false,
            message: [error.response.data ? error.response.data
              : 'Something went wrong. Contact system administrator!']
          })
        }
      })
    } else if (formData.type === 'Company App') {
      ApigeeService.createCompanyApp({
        name: `${formData.teamName}_${formData.resourceName}`,
        org: formData.orgName,
        company: formData.companyName,
        apiproduct: formData.productName,
        roles: formData.roles
      }).then((response) => {
        setSubmitLoader(false)
        if (response.status === 200) {
          setSupport({
            success: true,
            // eslint-disable-next-line max-len
            message: `Company App ${formData.teamName}_${formData.resourceName} successfully created. Get API key by going to selected API Product and clicking on the newly created App at the bottom in Apigee.`
          })
        } else {
          setSupport({
            success: false,
            message: 'Something went wrong. Contact system administrator!'
          })
        }
        resetFormData()
      }, (error) => {
        setLoading(false)
        setSubmitLoader(false)
        if (error) {
          setSupport({
            success: false,
            message: [error.response.data ? error.response.data
              : 'Something went wrong. Contact system administrator!']
          })
        }
      })
    } else if (formData.type === 'Cloudflare DNS Mapping') {
      ApigeeService.createDNSMapping({
        env: formData.envs[0],
        zone: formData.zoneName,
        org: formData.orgName,
        domain: formData.domain,
        targetServer: formData.targetServer
      }).then((response) => {
        setSubmitLoader(false)
        if (response.status === 200) {
          setSupport({
            success: true,
            // eslint-disable-next-line max-len
            message: `Cloudflare DNS Mapping  ${formData.targetServer.replace('_', '--')}--${formData.envs[0]}.${formData.zoneName} successfully created`
          })
        } else {
          setSupport({
            success: false,
            message: 'Something went wrong. Contact system administrator!'
          })
        }
        resetFormData()
      }, (error) => {
        setLoading(false)
        setSubmitLoader(false)
        if (error) {
          setSupport({
            success: false,
            message: [error.response.data ? error.response.data
              : 'Something went wrong. Contact system administrator!']
          })
        }
      })
    } else if (formData.type === 'Cache') {
      ApigeeService.createCache({
        name: `${formData.teamName}_${formData.resourceName}`,
        org: formData.orgName,
        envs: formData.envs,
        roles: formData.roles
      }).then((response) => {
        setSubmitLoader(false)
        if (response.status === 200) {
          setSupport({
            success: true,
            // eslint-disable-next-line max-len
            message: `Cache ${formData.teamName}_${formData.resourceName} successfully created`
          })
        } else {
          setSupport({
            success: false,
            message: 'Something went wrong. Contact system administrator!'
          })
        }
        resetFormData()
      }, (error) => {
        setLoading(false)
        setSubmitLoader(false)
        if (error) {
          setSupport({
            success: false,
            message: [error.response.data ? error.response.data
              : 'Something went wrong. Contact system administrator!']
          })
        }
      })
    }
  }

  const handleChange = (e) => {
    if (e.target.name === 'orgName') {
      // Reset rest of form if orgName previously exists but is being replaced
      if (formData.orgName && formData.orgName !== e.target.value) {
        setTeamsAndRolesForOrg([])
        setFormData({
          [e.target.name]: e.target.value,
          teamName: '',
          roles: [],
          type: '',
          envs: [],
          resourceName: '',
          zoneName: '',
          companyName: '',
          productName: '',
          domain: '',
          targetServer: ''
        })
      } else {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        })
      }
      ApigeeService.getAdminTeamsForOrgs(e.target.value).then((response) => {
        setTeamsAndRolesForOrg(response.data)
      })
    } else if (e.target.name === 'teamName') {
      // Reset rest of form if teamName previously exists but is being replaced
      if (formData.teamName && formData.teamName !== e.target.value) {
        const org = formData.orgName
        setFormData({
          orgName: org,
          [e.target.name]: e.target.value,
          roles: [],
          type: '',
          envs: [],
          resourceName: '',
          zoneName: '',
          companyName: '',
          productName: '',
          domain: '',
          targetServer: ''
        })
      } else {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        })
      }
      const teamsAndRoles = teamsAndRolesForOrg
      for (const teamAndRoles of teamsAndRoles) {
        if (teamAndRoles.name === e.target.value) {
          setAllRoles(teamAndRoles.roles)
        }
      }
      ApigeeService.getEnvs(formData.orgName).then((response) => {
        setEnvs(response.data.filter((env) => (env !== 'portal')))
      })
      ApigeeService.getCompanies(formData.orgName).then((response) => {
        setCompanies(response.data)
      })
    } else if (e.target.name === 'resourceEnvName') {
      const selectedEnv = e.target.value
      let tempEnvs = formData.envs
      let found = false
      for (const env of tempEnvs) {
        if (selectedEnv === env) {
          found = true
        }
      }
      if (found) {
        tempEnvs = tempEnvs
          .filter((env) => env !== selectedEnv)
      } else {
        tempEnvs.push(selectedEnv)
      }
      if (!tempEnvs.length) {
        setFormData({
          ...formData,
          envs: tempEnvs,
          resourceName: '',
          roles: [],
          zoneName: '',
          domain: '',
          targetServer: ''
        })
      } else {
        setFormData({
          ...formData,
          envs: tempEnvs
        })
      }
      if (e.target.resourceName === 'Cloudflare DNS Mapping') {
        // eslint-disable-next-line max-len
        ApigeeService.getTargetServers(formData.orgName, formData.envs[0]).then((response) => {
          setTargetServersForOrgAndEnv(response.data)
        })
      }
    } else if (e.target.name === 'resourceName') {
      let role = formData.roles
      let zone = formData.zoneName
      let domainName = formData.domain
      if (!e.target.value.length) {
        role = []
        zone = ''
        domainName = ''
      }
      setFormData({
        ...formData,
        roles: role,
        zoneName: zone,
        domain: domainName,
        [e.target.name]: e.target.value
      })
    } else if (e.target.name === 'roleName') {
      const role = e.target.value
      let tempRoles = formData.roles
      let found = false
      for (const roles of tempRoles) {
        if (roles.role === role) {
          found = true
        }
      }
      if (found) {
        tempRoles = tempRoles
          .filter((roleObj) => roleObj.role !== role)
      } else {
        tempRoles.push({
          'role': role,
          'envs': []
        })
      }
      setFormData({
        ...formData,
        roles: tempRoles
      })
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      })
    }
  }

  const handleEnvsForRole = (role, e) => {
    const tempRoles = formData.roles
    const env = e.target.value
    for (const roles of tempRoles) {
      if (roles.role === role) {
        if (roles.envs.includes(env)) {
          roles.envs = roles.envs
            .filter((roleEnv) => roleEnv !== env)
        } else {
          roles.envs.push(env)
        }
      }
    }
    setFormData({
      ...formData,
      roles: tempRoles
    })
  }

  const handleTypeChange = (type) => {
    // see if type is Company App
    if (type === 'Company App') {
      ApigeeService.getProducts(formData.orgName, formData.teamName)
        .then((response) => {
          setProducts(response.data)
        })
    }
    // Reset rest of form on a type change
    setFormData({
      ...formData,
      resourceName: '',
      envs: [],
      roles: [],
      zoneName: '',
      domain: '',
      targetServer: '',
      companyName: '',
      productName: '',
      type
    })
  }

  const handleDNSEnvChange = (env) => {
    setFormData({
      ...formData,
      envs: [env],
      zoneName: '',
      targetServer: '',
      domain: ''
    })
    // eslint-disable-next-line max-len
    ApigeeService.getTargetServers(formData.orgName, formData.teamName, env).then((response) => {
      setTargetServersForOrgAndEnv(response.data)
    })
  }

  /* to close the alert box */
  const handleClose = () => {
    setAlertwithok(false)
    setalertboxMessage('')
  }

  const envsNotSelected = () => {
    if (formData.type === 'KVM' || formData.type === 'Target Server' ||
      formData.type === 'API Product' ||
      formData.type === 'Cloudflare DNS Mapping') {
      if (formData.envs.length === 0) {
        return true
      }
    }
    return false
  }

  const rolesAreMissingEnvs = () => {
    if (formData.type !== 'API Product' &&
    formData.type !== 'Company App' &&
    formData.type !== 'Cloudflare DNS Mapping') {
      for (const role of formData.roles) {
        if (role.envs.length === 0) {
          return true
        }
      }
    }
    return false
  }

  // returns the page
  return (
    <div>
      <div>
        {showAlert()}
      </div>
      <div>
        {loading || submitLoader ? <LinearProgress /> : null}
        {' '}
        <Grid container spacing={1} className={classes.root}>
          <Grid item xs={12} md={12}>
            <Card variant='outlined'>
              <ValidatorForm
                ref={form}
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardContent>
                  <Typography
                    variant='h6'
                    gutterBottom
                    style={{
                      display: 'flow-root'
                    }}
                  >
                    Apigee Self Service Tool
                  </Typography>
                  <CardActions className={classes.pullRight}>
                    <Button
                      variant='contained'
                      component={Link}
                      to='/Support'
                      onClick={() => setPath({
                        path: '/Support',
                        component: SupportComponent
                      })}
                    >
                    Back
                    </Button>
                  </CardActions>
                  <div style={{
                    paddingTop: 10
                  }}
                  >
                    <FormControl
                      className={`
                        ${classes.formControl} ${classes.columnDisplay}
                      `}
                    >
                      <Typography
                        htmlFor='orgLabel'
                        className={classes.labelDisplay}
                      >
                        Org
                        <span className={classes.error}>*</span>
                        <Tooltip title={(
                          <span style={{
                            fontSize: 11,
                            fontFamily: 'Verdana'
                          }}
                          >
Select an Apigee Org.
                          </span>
                        )}
                        >
                          <InfoIcon
                            fontSize='small'
                            style={{
                              color: '#eea508'
                            }}
                          />
                        </Tooltip>
                      </Typography>
                      <div style={{
                        width: '72%'
                      }}
                      >
                        <SelectValidator
                          fullWidth
                          name='orgName'
                          onChange={handleChange}
                          validators={['required']}
                          errorMessages={['This field is required']}
                          value={formData.orgName
                            ? formData.orgName
                            : ''}
                        >
                          {allOrgs.map((value, index) => (
                            <MenuItem value={value} key={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </SelectValidator>
                      </div>
                    </FormControl>
                    {formData.orgName ? (
                      <FormControl
                        className={`
                          ${classes.formControl} ${classes.columnDisplay}
                        `}
                      >
                        <Typography
                          htmlFor='teamLabel'
                          className={classes.labelDisplay}
                        >
                          Team
                          <span className={classes.error}>*</span>
                          <Tooltip title={(
                            <span style={{
                              fontSize: 11,
                              fontFamily: 'Verdana'
                            }}
                            >
Select the team to apply access permissions
for the resource that you want to create.
                            </span>
                          )}
                          >
                            <InfoIcon
                              fontSize='small'
                              style={{
                                color: '#eea508'
                              }}
                            />
                          </Tooltip>
                        </Typography>
                        <div style={{
                          width: '72%'
                        }}
                        >
                          <SelectValidator
                            fullWidth
                            name='teamName'
                            onChange={handleChange}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            value={formData.teamName
                              ? formData.teamName
                              : ''}
                          >
                            {teamsAndRolesForOrg.map((value, index) => (
                              <MenuItem value={value.name} key={value.name}>
                                {value.name}
                              </MenuItem>
                            ))}
                          </SelectValidator>
                        </div>
                      </FormControl>
                    ) : null}
                    {formData.teamName ? (
                      <>
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='typeLabel'
                            className={classes.labelDisplay}
                          >
                            Resource Types
                            <span className={classes.error}>*</span>
                            <Tooltip
                              title={(
                                <span style={{
                                  fontSize: 11,
                                  fontFamily: 'Verdana'
                                }}
                                >
Select the type of Apigee resource to create.
                                </span>
                              )}
                            >
                              <InfoIcon
                                style={{
                                  color: '#eea508'
                                }}
                                fontSize='small'
                              />
                            </Tooltip>
                          </Typography>
                          <RadioButtons
                            radio={allTypes}
                            selectedvalue={formData.type}
                            onChange={(e) => handleTypeChange(e)}
                          />
                        </FormControl>
                      </>
                    ) : null}
                    {formData.type === 'Company App' ? (
                      <FormControl
                        className={`
                          ${classes.formControl} ${classes.columnDisplay}
                        `}
                      >
                        <Typography
                          htmlFor='companyLabel'
                          className={classes.labelDisplay}
                        >
                          Company
                          <span className={classes.error}>*</span>
                          <Tooltip
                            title={(
                              <span style={{
                                fontSize: 11,
                                fontFamily: 'Verdana'
                              }}
                              >
Select the sector that is associated with your project. If none are
appropriate, please reach out to the J&J API Strategy team for support.
                              </span>
                            )}
                          >
                            <InfoIcon
                              style={{
                                color: '#eea508'
                              }}
                              fontSize='small'
                            />
                          </Tooltip>
                        </Typography>
                        <div style={{
                          width: '72%'
                        }}
                        >
                          <SelectValidator
                            fullWidth
                            name='companyName'
                            onChange={handleChange}
                            validator={['required']}
                            errorMessages={['This field is required']}
                            value={formData.companyName
                              ? formData.companyName
                              : ''}
                          >
                            {allCompanies.map((value, index) => (
                              <MenuItem value={value} key={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </SelectValidator>
                        </div>
                      </FormControl>
                    ) : null}
                    {(formData.type === 'Target Server' ||
                      formData.type === 'KVM' ||
                      formData.type === 'API Product' ||
                      formData.type === 'Cache') ? (
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='resourceEnvLabel'
                            className={classes.labelDisplay}
                          >
                            Envs
                            <span className={classes.error}>*</span>
                            <Tooltip title={(
                              <span style={{
                                fontSize: 11,
                                fontFamily: 'Verdana'
                              }}
                              >
Select all the Environments applicable. For KVMs and Target Servers, these are
the environments that instances will be created in. For API Products, these are
the environments that the API Product will allow access to.
                              </span>
                            )}
                            >
                              <InfoIcon
                                fontSize='small'
                                style={{
                                  color: '#eea508'
                                }}
                              />
                            </Tooltip>
                          </Typography>
                          {allEnvs.map((env) => (
                            <FormControlLabel
                              key={env}
                              control={(
                                <Checkbox
                                  name='resourceEnvName'
                                  value={env}
                                  onChange={
                                    (e) => handleChange(e)
                                  }
                                  checked={formData.envs.includes(env)}
                                />
                              )}
                              label={env}
                            />
                          ))}
                        </FormControl>
                      ) : null}
                    {(formData.type === 'Cloudflare DNS Mapping') ? (
                      <FormControl
                        className={`
                          ${classes.formControl} ${classes.columnDisplay}
                        `}
                      >
                        <Typography
                          htmlFor='resourceEnvLabel'
                          className={classes.labelDisplay}
                        >
                          Env
                          <span className={classes.error}>*</span>
                          <Tooltip title={(
                            <span style={{
                              fontSize: 11,
                              fontFamily: 'Verdana'
                            }}
                            >
Select an environment that your mapping will be associated with. This will be
used as a postfix in the name for your CNAME record and will be automatically
set in your selected Target Server under the specified environment.
                            </span>
                          )}
                          >
                            <InfoIcon
                              fontSize='small'
                              style={{
                                color: '#eea508'
                              }}
                            />
                          </Tooltip>
                        </Typography>
                        <RadioButtons
                          radio={allEnvs}
                          selectedvalue={formData.envs[0]}
                          onChange={(e) => handleDNSEnvChange(e)}
                        />
                      </FormControl>
                    ) : null}
                    {formData.type === 'Company App' && formData.companyName ? (
                      <FormControl
                        className={`
                          ${classes.formControl} ${classes.columnDisplay}
                        `}
                      >
                        <Typography
                          htmlFor='apiProductLabel'
                          className={classes.labelDisplay}
                        >
                          API Product
                          <span className={classes.error}>*</span>
                          <Tooltip title={(
                            <span style={{
                              fontSize: 11,
                              fontFamily: 'Verdana'
                            }}
                            >
Select the api product that your company app should grant access to. You can add
additional API products after creation via the Apigee Edge UI.
                            </span>
                          )}
                          >
                            <InfoIcon
                              fontSize='small'
                              style={{
                                color: '#eea508'
                              }}
                            />
                          </Tooltip>
                        </Typography>
                        <div style={{
                          width: '72%'
                        }}
                        >
                          <SelectValidator
                            fullWidth
                            name='productName'
                            onChange={handleChange}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            value={formData.productName
                              ? formData.productName
                              : ''}
                          >
                            {allProducts.map((value, index) => (
                              <MenuItem value={value} key={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </SelectValidator>
                        </div>
                      </FormControl>
                    ) : null}
                    {formData.type && !envsNotSelected() &&
                    (formData.type === 'Company App'
                      ? formData.productName : true) &&
                      formData.type !== 'Cloudflare DNS Mapping' &&
                    (formData.type === 'Cache'
                      ? formData.envs.length : true) ? (
                        <>
                          <FormControl
                            className={`
                              ${classes.formControl} ${classes.columnDisplay}
                            `}
                          >
                            <Typography
                              htmlFor='nameLabel'
                              className={classes.labelDisplay}
                            >
                              Name
                              <span className={classes.error}>*</span>
                              <Tooltip
                                title={(
                                  <span style={{
                                    fontSize: 11,
                                    fontFamily: 'Verdana'
                                  }}
                                  >
Enter a name for your resource. The name you choose will be prefixed with your
team name followed by a hyphen. (Ex. your-team_your-proxy-name-v1) This is used
for organization and access control.
                                  </span>
                                )}
                              >
                                <InfoIcon
                                  style={{
                                    color: '#eea508'
                                  }}
                                  fontSize='small'
                                />
                              </Tooltip>
                            </Typography>
                            <TextValidator
                              name='resourceName'
                              onChange={(e) => handleChange(e)}
                              value={formData.resourceName}
                              errorMessages={['This field is required']}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position='start'>
                                    {`${formData.teamName}_`}
                                  </InputAdornment>),
                                // eslint-disable-next-line
                                ...(formData.type === 'Cloudflare DNS Mapping' && {
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      {`_${formData.envs[0]}`}
                                    </InputAdornment>)
                                })
                              }}
                            />
                          </FormControl>
                        </>
                      ) : null}
                    {formData.resourceName && !envsNotSelected() &&
                      formData.type !== 'Cloudflare DNS Mapping' ? (
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='roleLabel'
                            className={classes.labelDisplay}
                          >
                            Roles
                            <span className={classes.error}>*</span>
                            <Tooltip title={(
                              <span style={{
                                fontSize: 11,
                                fontFamily: 'Verdana'
                              }}
                              >
Select the individual roles to add access permissions to for your new resource.
For each selected role, if applicable for the resource type, use the check
boxes next to the role to select the environments to add permissions for. For
example, a developer role should only receive access to dev and test
environments, where as prod-ops should receive access to staging and prod, and
cicd should receive access to all environments. You should avoid giving
permissions to admin roles as they should only be used for team management
unless you decide that this approach works for your team.
                              </span>
                            )}
                            >
                              <InfoIcon
                                fontSize='small'
                                style={{
                                  color: '#eea508'
                                }}
                              />
                            </Tooltip>
                          </Typography>
                          <List
                            component='nav'
                            aria-label='main mailbox folders'
                            className={classes.rolesList}
                          >
                            {allRoles.map((role) => (
                              <ListItem
                                key={role.name ? role.name : role}
                                disableGutters
                                className={classes.rolesListItem}
                              >
                                <FormControlLabel
                                  key={role.name ? role.name : role}
                                  control={(
                                    <Checkbox
                                      name='roleName'
                                      value={role.name}
                                      onChange={(e) => handleChange(e)}
                                      // eslint-disable-next-line max-len
                                      checked={formData.roles.some((r) => r.role === role.name)}
                                    />
                                  )}
                                  label={role.name}
                                  className={`
                                    ${classes.rolesLabel} 
                                    ${classes.rolesListLabel}
                                  `}
                                />
                                {(formData.type !== 'API Product' &&
                                formData.type !== 'Company App') ? (
                                    <>
                                      <Typography
                                        key={role.name ? role.name : role}
                                        htmlFor='roleLabel'
                                        className={`
                                          ${classes.labelDisplay} 
                                          ${classes.rolesListEnvs}
                                        `}
                                      >
                                        Envs
                                      </Typography>
                                      {allEnvs.map((env) => (
                                        <>
                                          <FormControl
                                            key={env}
                                            className={`
                                              ${classes.formControl} 
                                              ${classes.columnDisplay}
                                            `}
                                          >
                                            <FormControlLabel
                                              key={env}
                                              control={(
                                                <Checkbox
                                                  name='envName'
                                                  value={env}
                                                  onChange={
                                                    // eslint-disable-next-line max-len
                                                    (e) => handleEnvsForRole(role.name, e)
                                                  }
                                                  // eslint-disable-next-line max-len
                                                  disabled={!formData.roles.some((r) => r.role === role.name)}
                                                  // eslint-disable-next-line max-len
                                                  checked={formData.roles.some((r) => r.role === role.name && r.envs.includes(env))}
                                                />
                                              )}
                                              label={env}
                                            />
                                          </FormControl>
                                        </>
                                      ))}
                                    </>
                                  ) : null}
                              </ListItem>
                            ))}
                          </List>
                        </FormControl>
                      ) : null}
                    {!envsNotSelected() &&
                      formData.type === 'Cloudflare DNS Mapping' ? (
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='zoneLabel'
                            className={classes.labelDisplay}
                          >
                            Zone
                            <span className={classes.error}>*</span>
                            <Tooltip
                              title={(
                                <span style={{
                                  fontSize: 11,
                                  fontFamily: 'Verdana'
                                }}
                                >
Select the Cloudflare zone appropriate
for your org and environment. For non-prod
environments, select the zone with the appropriate
org and &rdquo;prerelease&rdquo;. For prod environments, select
the zone with the appropriate org
and without prerelease.
                                </span>
                              )}
                            >
                              <InfoIcon
                                style={{
                                  color: '#eea508'
                                }}
                                fontSize='small'
                              />
                            </Tooltip>
                          </Typography>
                          <div style={{
                            width: '72%'
                          }}
                          >
                            <SelectValidator
                              fullWidth
                              name='zoneName'
                              onChange={handleChange}
                              validators={['required']}
                              errorMessages={['This field is required']}
                              value={formData.zoneName
                                ? formData.zoneName
                                : ''}
                            >
                              {allZones
                                .filter((zone) => (
                                  // eslint-disable-next-line max-len
                                  zone.startsWith(`${formData.orgName + (formData.envs[0].startsWith('prod') ? '.' : '-prerelease.')}`)
                                ))
                                .map((zone) => (
                                  <MenuItem value={zone} key={zone}>
                                    {zone}
                                  </MenuItem>
                                ))}
                            </SelectValidator>
                          </div>
                        </FormControl>
                      ) : null}
                    {formData.zoneName.length > 0 ? (
                      <>
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='nameLabel'
                            className={classes.labelDisplay}
                          >
                            Target Server Name
                            <span className={classes.error}>*</span>
                            <Tooltip
                              title={(
                                <span style={{
                                  fontSize: 11,
                                  fontFamily: 'Verdana'
                                }}
                                >
Select the associated Apigee Target Server. The generated CNAME mapping name
will be automatically placed as the target in this Target Server. (Target
Server name must exist and begin with your team/project name to be selectable.
This can be achieved by creating a Target Server for this team/project using
this Self-Service Tool).
                                </span>
                              )}
                            >
                              <InfoIcon
                                style={{
                                  color: '#eea508'
                                }}
                                fontSize='small'
                              />
                            </Tooltip>
                          </Typography>
                          <div style={{
                            width: '72%'
                          }}
                          >
                            <SelectValidator
                              fullWidth
                              name='targetServer'
                              onChange={handleChange}
                              validator={['required']}
                              errorMessages={['This field is required']}
                              value={formData.targetServer
                                ? formData.targetServer
                                : ''}
                            >
                              {targetServersForOrgAndEnv.map((value, index) => (
                                <MenuItem value={value} key={value}>
                                  {value}
                                </MenuItem>
                              ))}
                            </SelectValidator>
                          </div>
                        </FormControl>
                      </>
                    ) : null}
                    {formData.targetServer.length > 0 ? (
                      <>
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='nameLabel'
                            className={classes.labelDisplay}
                          >
                            Name
                            <span className={classes.error}>*</span>
                            <Tooltip
                              title={(
                                <span style={{
                                  fontSize: 11,
                                  fontFamily: 'Verdana'
                                }}
                                >
The name for your resource.
                                </span>
                              )}
                            >
                              <InfoIcon
                                style={{
                                  color: '#eea508'
                                }}
                                fontSize='small'
                              />
                            </Tooltip>
                          </Typography>
                          <TextValidator
                            name='resourceName'
                            onChange={(e) => handleChange(e)}
                            value={formData.resourceName}
                            errorMessages={['This field is required']}
                            disabled
                            className={classes.extendedInput}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  {/* eslint-disable-next-line max-len */}
                                  {`${formData.targetServer.replace('_', '--')}--${formData.envs[0]}.${formData.zoneName} (Given)`}
                                </InputAdornment>)
                            }}
                          />
                        </FormControl>
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='nameLabel'
                            className={classes.labelDisplay}
                          >
                            CNAME Target Domain
                            <span className={classes.error}>*</span>
                            <Tooltip
                              title={(
                                <span style={{
                                  fontSize: 11,
                                  fontFamily: 'Verdana'
                                }}
                                >
The target domain for the CNAME record to map to.
                                </span>
                              )}
                            >
                              <InfoIcon
                                style={{
                                  color: '#eea508'
                                }}
                                fontSize='small'
                              />
                            </Tooltip>
                          </Typography>
                          <TextValidator
                            name='domain'
                            onChange={(e) => handleChange(e)}
                            value={formData.domain}
                            errorMessages={['This field is required']}
                            className={classes.extendedInput}
                          />
                        </FormControl>
                      </>
                    ) : null}
                    {(formData.roles.length > 0 &&
                      !rolesAreMissingEnvs() && !envsNotSelected()) ||
                      formData.domain.length > 0
                      ? (
                        <CardActions className={classes.pullRight}>
                          <Button
                            variant='contained'
                            color='primary'
                            type='submit'
                            disabled={loading || submitLoader ||
                              user.switch_user}
                          >
                            Submit Request
                          </Button>
                        </CardActions>
                      ) : null}
                  </div>
                </CardContent>
              </ValidatorForm>
            </Card>
            <AlertDialog
              handleClose={handleClose}
              alertopen={alertwithok}
              key='alert'
              message={alertboxMessage}
              okbuttonalert
              title='Alert'
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}