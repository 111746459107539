import axiosInstance from '../../middleware/Interceptor'

function getAWSList () {
  return axiosInstance
    .get('/api/v1/k8s/accountList')
}

function getClusterList (accountID, region) {
  return axiosInstance
    .get(`/api/v1/k8s/clusterlist?accountID=${
      accountID}&region=${region}`)
}

function getKubeConfig (awsValue, cluster, region, awsType) {
  axiosInstance.defaults.headers.get.Accept = 'application/x-yaml'
  const awsCluster = `${awsValue}-${cluster}`
  return axiosInstance.get(`/api/v1/k8s/getkubeconfig
?clusterName=${cluster.toLowerCase()}&region=
${region.toLowerCase()}&accountID=${awsValue.toLowerCase()}&type=
${awsType}`, {
  },
  {
    responseType: 'arraybuffer'
  })
}

const KubeConfigService = {
  getAWSList,
  getClusterList,
  getKubeConfig
}
export default KubeConfigService