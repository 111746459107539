/* It contains all the API functions which is releated to Support section */
import axiosInstance from '../../middleware/Interceptor'

// download template
function downloadRATemplate () {
  return axiosInstance.post('/api/v1/reports/downloadRiskAssessmentTemplate',
    {
    },
    {
      responseType: 'arraybuffer'
    })
}

// Import Risk Assessment
function importRiskAssessment (project, input) {
  return axiosInstance.post(`/api/v1/jira/importRiskAssessment?
project=${project}`, input)
}

const RiskAssessmentService = {
  downloadRATemplate,
  importRiskAssessment
}

export default RiskAssessmentService
