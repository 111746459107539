import React, {
  useContext
} from 'react'
import {
  makeStyles, Typography, Box, Grid
} from '@material-ui/core'
import ListAltIcon from '@material-ui/icons/ListAlt'
import GroupIcon from '@material-ui/icons/Group'
import HowToRegIcon from '@material-ui/icons/HowToReg'
import HelpIcon from '@material-ui/icons/Help'
import AccessibilityIcon from '@material-ui/icons/Accessibility'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import MyApprovalsComponent from '../myapprovals/myapprovalsComponent'
import ByUserComponent from '../byuser/byuserComponent'
import MyRequestsComponent from '../myrequests/myrequestsComponent'
import SupportComponent from '../support/supportComponent'
import ToolAccessComponent from '../toolaccess/toolaccessComponent'
import ProjectAccessComponent from '../projectaccess/projectaccessComponent'
import CardComponent from '../sections/mediaCard'
import PathContext from '../contexts/PathContext'
import UserContext from '../contexts/UserContext'
import ToolsService from '../../services/api/toolsService'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  margintop: {
    marginTop: theme.spacing(4)
  },
  approvetext: {
    marginTop: theme.spacing(1),
    fontWeight: 500
  }
}))

export default function HomePageComponent (props) {
  const {
    user
  } = useContext(UserContext)
  const {
    setPath
  } = useContext(PathContext)

  const classes = useStyles()
  const [realData, setRealData] = React.useState(null)
  const realData1 = {
    row1: [
      {
        title: 'Access to Projects',
        description:
          'To get access to projects in Enterprise Agile Tools.',
        route: '/byuser',
        component: ByUserComponent,
        icon: (
          <GroupIcon
            color='action'
            style={{
              fontSize: `${4}rem`,
              margin: 40
            }}
          />
        )
      },
      {
        title: 'Approve/Reject Requests',
        route: '/myapprovals',
        component: MyApprovalsComponent,
        description:
  <div>
    To Approve or Reject the requests which are waiting for your approval.
    {user.approvalcount && user.approvalcount > 0 ? (
      <Typography component='div' className={classes.approvetext}>
        <Box color='success.main'>
          {' '}
          {user.approvalcount}
          {' '}
          requests are pending for your approval
        </Box>

      </Typography>
    ) : null}
  </div>,
        icon: (
          <HowToRegIcon
            color='action'
            style={{
              fontSize: `${4}rem`,
              margin: 40
            }}
          />
        )
      },
      {
        title: 'My Pending Requests',
        route: '/myrequests',
        component: MyRequestsComponent,
        description:
          'To check the status and approvers list of your submitted requests.',
        icon: (
          <AssignmentIndIcon
            color='action'
            style={{
              fontSize: `${4}rem`,
              margin: 40
            }}
          />
        )
      }
    ],
    row2: [
      {
        title: 'Submit Request/ Incident',
        route: '/Support',
        component: SupportComponent,
        description:
          `To submit support request for new project creation, 
          updates to existing projects, incidents, 
          any support required from the Agile tools team.`,
        icon: (
          <HelpIcon
            color='action'
            style={{
              fontSize: `${4}rem`,
              margin: 40
            }}
          />
        )
      },
      {
        title: 'Existing Tool Access',
        route: '/toolaccess',
        component: ToolAccessComponent,
        description: 'To check access of any individual to the tools.',
        icon: (
          <AccessibilityIcon
            color='action'
            style={{
              fontSize: `${4}rem`,
              margin: 40
            }}
          />
        )
      },
      {
        title: 'Reports',
        route: '/projectaccess',
        component: ProjectAccessComponent,
        description:
          `To review project access reports. 
          Displays the users who are part of a project groups.`,
        icon: (
          <ListAltIcon
            style={{
              fontSize: `${4}rem`,
              margin: 40
            }}
            color='action'
          />
        )
      }
    ]
  }
  React.useEffect(() => {
    ToolsService.getEnabledTools().then((response) => {
      if (!response.data.find((item) => item.name.toLowerCase() === 'iris')) {
        realData1.row2.splice(0, 1)
      }
      setRealData(realData1)
    })
  }, [])

  const redirectPage = (route) => {
    props.history.push(route.route)
    setPath({
      pathname: route.route,
      component: route.component
    })
  }

  const FormRow1 = () => (
    <>
      {realData.row1.map((value) => (
        <Grid item xs={12} md={4} key={value.title}>
          <CardComponent
            key={value.title}
            title={value.title}
            icon={value.icon}
            description={value.description}
            value={value}
            redirectPage={() => redirectPage(value)}
          />
        </Grid>
      ))}
    </>
  )
  const FormRow2 = () => (
    <>
      {realData.row2.map((value) => (
        <Grid item xs={12} md={4} key={value.title}>
          <CardComponent
            key={value.title}
            title={value.title}
            icon={value.icon}
            description={value.description}
            value={value}
            redirectPage={() => redirectPage(value)}
          />

        </Grid>
      ))}
    </>
  )
  return (
    <div className={classes.root}>
      {realData && (
        <Grid container spacing={1} className={classes.margintop}>
          <Grid container item xs={12} spacing={3}>
            <FormRow1 />
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={3}
            className={classes.margintop}
          >
            <FormRow2 />
          </Grid>
        </Grid>
      )}
    </div>
  )
}
