/* Common function to use radio button */

import React from 'react'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import PropTypes from 'prop-types'

export default function RadioButtons ({
  onChange, radio, selectedvalue, disabled
}) {
  const handleChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <div>
      {radio.map((input) => (
        <span key={input}>
          <FormControlLabel
            control={<Radio color='secondary' />}
            checked={selectedvalue === input}
            onChange={handleChange}
            value={input}
            disabled={disabled}
            name='radio-button-demo'
            label={input}
            key={input}
            labelPlacement='end'
          />
        </span>
      ))}
    </div>
  )
}
RadioButtons.propTypes = {
  onChange: PropTypes.func.isRequired,
  radio: PropTypes.array.isRequired,
  selectedvalue: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}
