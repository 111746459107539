import React, {
  useContext, useState, useRef, useEffect
} from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  makeStyles,
  FormControl,
  LinearProgress,
  IconButton,
  Checkbox,
  Tooltip,
  FormControlLabel,
  MenuItem
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  useParams, Link
} from 'react-router-dom'
import {
  TextValidator, ValidatorForm, SelectValidator
} from 'react-material-ui-form-validator'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import {
  FileUploader, FileManager
} from 'reactjs-file-uploader'
import InfoIcon from '@material-ui/icons/Info'
import queryString from 'query-string'
import RadioButtons from '../inputs/radio'
import MultipleSelect from '../inputs/multipleselectbox'
import SupportService from '../../services/api/supportService'
import requestAccess from '../../services/api/requestaccessService'
import UserContext from '../contexts/UserContext'
import AlertDialog from '../alertbox/alertboxComponent'
import SupportContext from '../contexts/SupportContext'
import PathContext from '../contexts/PathContext'
import SupportComponent from './supportComponent'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  pullRight: {
    float: 'right'
  },
  columnDisplay: {
    display: 'flex',
    flexDirection: 'row'
  },
  labelDisplay: {
    margin: '10px',
    width: '25%'
  },
  inputDisplay: {
    width: '70%'
  },
  attachmentColDisplay: {
    display: 'flex',
    flexDirection: 'columns'
  },
  attachmentDisplay: {
    width: '35%'
  },
  templateColDisplay: {
    margin: '10px',
    width: '50%'
  },
  error: {
    color: 'red'
  },
  here: {
    color: '#007bff',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}))

export default function SupportServiceRequestComponent (props) {
  const queryparams = queryString.parse(props.location.search)
  const {
    setPath
  } = useContext(PathContext)
  const {
    user
  } = useContext(UserContext)
  const {
    support, setSupport
  } = useContext(SupportContext)
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const form = useRef(null)
  const [allUsers, setAllUsers] = useState([])
  const [allTools, setAllTools] = useState([])
  const [allProjects, setAllProjects] = useState([])
  const [loadingOptions, setLoadingOptions] = useState(false)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [files, setFiles] = useState([])
  const [alertAttachment, setAlertAttachment] = useState(false)

  const [formData, setFormData] = useState({
    requestFor: user.name.toUpperCase(),
    requestForName: user.name,
    toolName: '',
    projectKey: '',
    description: '',
    attachmentAnchorTag: '',
    maxFileSize: 10000000
  })

  // Returns alert and it's message
  const showAlert = () => {
    if (support.success === false && support.message !== '') {
      return (
        <Alert
          severity='error'
          onClose={() => setSupport({
            success: false,
            message: ''
          })}
        >
          {support.message}
        </Alert>
      )
    }
  }

  useEffect(() => {
    setLoading(true)
    SupportService.getTools('general').then((response) => {
      setLoading(false)
      setAllTools(response.data)
      if (queryparams.tool) {
        const filtervalue1 = response.data
          .filter((item) => item.name.toLowerCase() ===
          queryparams.tool.toLowerCase())
        if (filtervalue1.length && filtervalue1.length === 1) {
          if (queryparams.project) {
            requestAccess.getprojects(filtervalue1[0].name,
              queryparams.project).then((response1) => {
              const filtervalue2 = response1.data
                .filter((item) => item.key.toLowerCase() ===
                queryparams.project.toLowerCase())
              if (filtervalue2.length && filtervalue2.length === 1) {
                setFormData({
                  ...formData,
                  projectKey: `${filtervalue2[0].key} - ${filtervalue2[0]
                    .key_app_name}`,
                  toolName: filtervalue1[0].name,
                  toolNameDisplayValue: filtervalue1[0]
                })
              }
            })
          } else {
            setFormData({
              ...formData,
              toolName: filtervalue1[0].name,
              toolNameDisplayValue: filtervalue1[0]
            })
          }
        }
      }
    })
  }, [])

  // Get Users for auto complete
  const getUsers = (username) => {
    setFormData({
      ...formData,
      searchkey: username
    })
    setLoadingOptions(true)
    requestAccess.getallusersforautocomplete(username).then((response) => {
      setLoadingOptions(false)
      setAllUsers(response.data)
    })
  }

  // Handling change for the user name in fields
  const changeUser = (value) => {
    if (value) {
      setFormData({
        ...formData,
        requestFor: `${value.name} - ${value.displayname
          ? value.displayname
          : null}`,
        requestForName: value.name
      })
    }
    setAllUsers([])
  }

  // get project for autocomplete
  const getProjects = (toolname, value) => {
    setLoadingOptions(true)
    setFormData({
      ...formData,
      searchkey: value
    })
    if (toolname !== 'Jenkins - Artifactory') {
      requestAccess.getprojects(toolname, value).then((response) => {
        setLoadingOptions(false)
        setAllProjects(response.data)
      })
    } else {
      requestAccess.getprojects(toolname, value, 'needEKS').then((response) => {
        setLoadingOptions(false)
        setAllProjects(response.data)
      })
    }
  }

  const changeProject = (value) => {
    if (value) {
      setFormData({
        ...formData,
        projectKey: `${value.key} - ${value.key_app_name}`
      })
    }
  }

  const handleChange = (e) => {
    if (e.target.name === 'toolName') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.name,
        toolNameDisplayValue: e.target.value
      })
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      })
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    submitRequest()
  }

  // remove attachment
  const removeAttachment = (file) => {
    if (file) {
      files.map((fileitem, i) => {
        if (fileitem.name === file.name) {
          SupportService.removeFileUpload('ticket', {
            filename: file.originalname
          }).then((response) => {
            files.splice(i, 1)
            setFiles([...files])
          })
        }
      })
    }
  }

  const removeEntry = () => {
    setAlertAttachment(true)
    setTimeout(() => {
      setAlertAttachment(false)
    }, 20000)
    files.map((item, i) => {
      if (item.size > formData.maxFileSize) {
        files.splice(i, 1)
        setFiles([...files])
      }
    })
  }

  // set original name for file
  const setFileOriginalName = (file, response) => {
    files.map((fileitem, i) => {
      if (fileitem.name === file.name) {
        files[i].originalname = response.filename
        setFiles(files)
      }
    })
  }

  // Upload Files
  const uploadFiles = (uploadedFiles) => uploadedFiles.map(uploadFile)

  // Upload a single file
  const uploadFile = (file) => (
    <FileUploader
      key={file.key}
      file={file}
      url='/api/fileupload?page=ticket'
      formData={{
        file,
        upload_preset: 'public',
        tags: 'vanilla'
      }}
      readFile
      autoUpload={file.size < formData.maxFileSize}
    >
      {fileProgress}
    </FileUploader>
  )

  // show file progress
  const fileProgress = ({
    uploadReady,
    downloadComplete,
    /*
    Response text Object (JSON)
     */
    response,
    file
  }) => (
    <div>
      {downloadComplete && response && setFileOriginalName(file, response)}
      {downloadComplete && file.size < formData.maxFileSize ? (
        <Grid
          container
          spacing={1}
          style={{
            marginBottom: 20
          }}
        >
          <Grid
            item
            xs={4}
            md={3}
            style={{
              marginLeft: 30
            }}
          >
            {file.name}
          </Grid>
          <Grid item xs={6} md={4}>
            <LinearProgress
              style={{
                width: '80%'
              }}
              variant='determinate'
              value={100}
            />
            <Typography component='div' variant='caption'>
              100% upload completed
            </Typography>
          </Grid>
          <Grid item xs={2} md={2}>
            <IconButton
              aria-label='delete'
              style={{
                padding: 0
              }}
            >
              <DeleteIcon
                color='secondary'
                onClick={() => removeAttachment(file)}
              />
            </IconButton>
          </Grid>
        </Grid>
      ) : null}
      {uploadReady && file.size > formData.maxFileSize
        ? (removeEntry())
        : (null)}
    </div>
  )

  const submitRequest = () => {
    const date = new Date()
    let input = {
    }
    let reqDetails = {
    }

    formData.attachmentAnchorTag = ''
    if (files.length > 0) {
      formData.attachmentAnchorTag = '<br>Attachments :'
      files.map((item, i) => {
        if (item.originalname !== undefined) {
          formData.attachmentAnchorTag = `${formData.attachmentAnchorTag} </br><a href='https://${document.location.hostname}/api/uploads/${item.originalname}'>https://${document.location.hostname}/api/uploads/${item.originalname}</a>`
        }
      })
    }

    reqDetails = {
      Type: 'General Questions',
      Application: formData.toolName,
      'Requested Date': date.toString(),
      'Created By': user.name.toUpperCase(),
      Description: `${formData.description} ${formData.attachmentAnchorTag}`
    }

    if (formData.projectKey) {
      reqDetails['Project Key'] = formData.projectKey
    } else {
      reqDetails['Project Key'] = 'NA'
    }

    input = {
      request_for: formData.requestForName,
      assgn_grp: 'AS FRAD TOOLS L2',
      task_srt: 'General Questions',
      req_det: reqDetails,
      sla: '5',
      application: formData.toolName
    }

    setSubmitLoader(true)
    SupportService.ticketCreation(input).then((response) => {
      setSubmitLoader(false)
      if (response.data.body.result.statusCode === 200 ||
          response.data.body.result.statusMessage === 'Success') {
        setSupport({
          success: true,
          message: `IRIS request submitted successfully and your
           request number is ${response.data.body.result
    .returnResponse.requestNumber} - ${response.data.body.result
  .returnResponse.requestedItem}`
        })
      } else {
        setSupport({
          success: false,
          message: 'Something went wrong. Contact system administrator!'
        })
      }
      setPath({
        path: '/Support',
        component: SupportComponent
      })
      props.history.push('/Support')
    }, (error) => {
      setLoading(false)
      if (error) {
        setSupport({
          success: false,
          message: 'Something went wrong. Contact system administrator!'
        })
      }
    })
  }

  return (
    <div>
      <div>
        {showAlert()}
      </div>
      <div>
        {loading || submitLoader ? <LinearProgress /> : null}
        {' '}
        <Grid container spacing={1} className={classes.root}>
          <Grid item xs={12} md={12}>
            <Card variant='outlined'>
              <ValidatorForm
                ref={form}
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardContent>
                  <Typography variant='h6' gutterBottom display='block'>
                    Service Request / General Questions
                  </Typography>
                  <CardActions className={classes.pullRight}>
                    <Button
                      variant='contained'
                      component={Link}
                      to='/Support'
                      onClick={() => setPath({
                        path: '/Support',
                        component: SupportComponent
                      })}
                    >
                      Back
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      type='submit'
                      disabled={loading || submitLoader || user.switch_user}
                    >
                      Submit Request
                    </Button>
                  </CardActions>
                  <div style={{
                    paddingTop: 10
                  }}
                  >
                    <FormControl
                      className={`
                        ${classes.formControl} ${classes.columnDisplay}
                      `}
                    >
                      <Typography
                        htmlFor='requestForLabel'
                        variant='subtitle1'
                        display='block'
                        gutterBottom
                        className={classes.labelDisplay}
                      >
                        Request For
                        <span className={classes.error}> *</span>
                      </Typography>
                      <Autocomplete
                        id='request-for-auto-complete'
                        defaultValue={formData.requestFor}
                        className={classes.inputDisplay}
                        options={allUsers}
                        getOptionLabel={
                          (option) => (option.name
                            ? `${option.name} - ${option.displayname}`
                            : option)
                        }
                        loading={allUsers.length === 0 && loadingOptions}
                        getOptionSelected={(option,
                          userValue) => option.name === userValue.name}
                        onChange={(event, userValue) => changeUser(userValue)}
                        filterOptions={(options, state) => options}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            name='requestFor'
                            onChange={(event) => getUsers(event.target.value)}
                            value={formData.requestFor}
                            validators={['required']}
                            errorMessages={['This field is required']}
                          />
                        )}
                      />
                    </FormControl>

                    <FormControl
                      className={`
                        ${classes.formControl} ${classes.columnDisplay}
                      `}
                    >
                      <Typography
                        htmlFor='toolLabel'
                        className={classes.labelDisplay}
                      >
                        Tool
                        <span className={classes.error}>*</span>
                      </Typography>
                      <div style={{
                        width: '70%'
                      }}
                      >
                        <SelectValidator
                          fullWidth
                          name='toolName'
                          onChange={handleChange}
                          value={formData.toolNameDisplayValue
                            ? formData.toolNameDisplayValue
                            : ''}
                          validators={['required']}
                          errorMessages={['This field is required']}
                        >
                          {allTools.map((value, index) => (
                            <MenuItem value={value} key={value.name}>
                              {value.name}
                            </MenuItem>
                          ))}
                        </SelectValidator>
                      </div>
                    </FormControl>

                    <FormControl
                      className={`
                        ${classes.formControl} ${classes.columnDisplay}
                      `}
                    >
                      <Typography
                        htmlFor='projectLabel'
                        className={classes.labelDisplay}
                      >
                        Project Key
                      </Typography>
                      <Autocomplete
                        id='project-auto-complete'
                        className={classes.inputDisplay}
                        options={allProjects}
                        getOptionLabel={
                          (option) => (option.key
                            ? `${option.key} - ${option.key_app_name}`
                            : option)
                        }
                        loading={allProjects.length === 0 && loadingOptions}
                        getOptionSelected={(option,
                          userValue) => option.key === userValue.key}
                        onChange={(event,
                          userValue) => changeProject(userValue)}
                        value={formData.projectKey}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            name='projectKey'
                            onChange={(event) => getProjects(formData.toolName,
                              event.target.value)}
                            value={formData.projectKey}
                          />
                        )}
                      />
                    </FormControl>

                    <FormControl
                      className={`
                        ${classes.formControl} ${classes.columnDisplay}
                      `}
                    >
                      <Typography
                        htmlFor='descriptionLabel'
                        className={classes.labelDisplay}
                      >
                        Request Details
                        <span className={classes.error}>*</span>
                      </Typography>
                      <div style={{
                        width: '70%'
                      }}
                      >
                        <TextValidator
                          name='description'
                          onChange={handleChange}
                          value={formData.description}
                          multiline
                          rows={1}
                          rowsMax={4}
                          fullWidth
                          validators={['required']}
                          errorMessages={['This field is required']}
                        />
                      </div>
                    </FormControl>

                    <FormControl
                      className={`
                        ${classes.formControl} ${classes.attachmentColDisplay}
                      `}
                    >
                      <Typography
                        variant='subtitle1'
                        className={classes.labelDisplay}
                      >
                        Attachments
                        {' '}
                        <small>
                          (
                          <i>File size should not exceed 10 MB </i>
                          )
                        </small>
                      </Typography>
                      <br />
                      <input
                        type='file'
                        name='fileUplaod'
                        id='fileUpload'
                        multiple
                        className={classes.labelDisplay}
                        // renderInput={(params) => params.join(', ')}
                        onChange={(event) => setFiles(files
                          .concat(Array.from(event.target.files)))}
                      />
                      {alertAttachment === true ? (
                        <span className={classes.error}>
                          Please attach file smaller than 10 MB.
                        </span>
                      ) : null}
                      <FileManager files={files}>
                        {uploadFiles}
                      </FileManager>
                    </FormControl>

                  </div>
                </CardContent>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
