import React from 'react'
import {
  Typography, Grid, makeStyles, TextField
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20
  },
  textfield: {
    width: '80%'
  }
}))

export default function ApigeeAdminComponent ({
  configdata, handleChange
}) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography
            variant='subtitle1'
            gutterBottom
            className={classes.bold}
          >
            Apigee Details
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='URI'
            onChange={
              (e) => handleChange('apigeeDetails', e, 'uri')
            }
            name='uri'
            value={configdata.apigeeDetails.uri}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label='Authentication Token'
            name='auth_token'
            multiline
            rows='7'
            onChange={
              (e) => handleChange('apigeeDetails', e, 'auth_token')
            }
            value={configdata.apigeeDetails.auth_token}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label='User Information'
            name='create_user'
            multiline
            rows='7'
            onChange={
              (e) => handleChange('apigeeDetails', e, 'create_user')
            }
            value={configdata.apigeeDetails.create_user}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography
            variant='subtitle1'
            gutterBottom
            className={classes.bold}
          >
            Apigee Orgs
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            multiline
            rows='3'
            onChange={(e) => handleChange('apigeeDetails', e)}
            name='apigeeOrgs'
            value={configdata.apigeeDetails.apigeeOrgs}
            className={classes.textfield}
          />
        </Grid>
      </Grid>
    </div>
  )
}