import React from 'react'
import {
  makeStyles
} from '@material-ui/core/styles'
import {
  Card, CardContent, Typography, Hidden
} from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    cursor: 'pointer',
    '&:hover': {
      background: '#eceff1'
    }
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  description: {
    marginTop: theme.spacing(1)
  }
}))

export default function MediaControlCard ({
  description, icon, redirectPage, title
}) {
  const classes = useStyles()

  return (
    <Card className={classes.root} onClick={redirectPage}>
      <Hidden smDown>
        {icon}
      </Hidden>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component='h6' variant='h6'>
            {title}
          </Typography>
          <Typography
            variant='subtitle1'
            color='textSecondary'
            className={classes.description}
          >
            {description}
          </Typography>
        </CardContent>
      </div>
    </Card>
  )
}

MediaControlCard.propTypes = {
  description: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  redirectPage: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
}
