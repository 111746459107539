/* eslint indent: 0 */
import React, {
  useContext
} from 'react'
import {
  Grid,
  makeStyles,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  LinearProgress,
  Hidden
} from '@material-ui/core'
import {
  Link
} from 'react-router-dom'
import NewReleasesOutlinedIcon from '@material-ui/icons/NewReleasesOutlined'
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined'
import {
  Alert
} from '@material-ui/lab'
import PathContext from '../contexts/PathContext'
import UserContext from '../contexts/UserContext'
import CreationTicketComponent from './supportCreationTicketComponent'
import SupportApigeeSelfServiceComponent from
  './supportApigeeSelfServiceComponent'
import SupportViewSubmittedRequestComponent from
  './supportViewSubmittedRequestsComponent'
import UpdateTicketComponent from './supportUpdateTicketComponent'
import ServiceRequestComponent from './supportServiceRequestComponent'
import IncidentComponent from './supportIncidentComponent'
import SupportContext from '../contexts/SupportContext'
import SupportServices from '../../services/api/supportService'
import SplunkMetrics from '../splunkmetrics/SplunkMetrics'
import ToolsService from '../../services/api/toolsService'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  },
  cardroot: {
    height: '100%',
    cursor: 'default',
    display: 'flex'

  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  content: {
    flex: '1 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'primary'
  },
  margintop: {
    marginTop: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3)
  },
  floatRight: {
    width: '95%'
  },
  pullRightBtn: {
    float: 'right'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  }
}))

const data1 = [
  {
    name: 'New Project Creation',
    icon: (
      <NewReleasesOutlinedIcon
        color='disabled'
        style={{
          fontSize: `${7}rem`,
          margin: '3rem'
        }}
      />
    ),
    type: [
      {
        value: 'Enterprise Agile Tools Project Creation',
        uri: '/createticket/projectcreation',
        target: '_self',
        component: CreationTicketComponent,
        name: 'projectcreation',
        route: '/createticket/:projectcreation'
      }
    ]
  },
  {
    name: 'Updates to Existing Project',
    icon: (
      <UpdateOutlinedIcon
        color='disabled'
        style={{
          fontSize: `${7}rem`,
          margin: 40
        }}
      />
    ),
    type: [
      {
        value: 'Enable Xray on Jira project',
        uri: '/updateproject/enablexray',
        target: '_self',
        component: UpdateTicketComponent,
        name: 'xray',
        route: '/updateproject/:type',
        tool: 'jira'
      },
      {
        value: 'Enable Risk Assessment in Jira project',
        uri: '/updateproject/enablera',
        target: '_self',
        component: UpdateTicketComponent,
        name: 'ra',
        route: '/updateproject/:type',
        tool: 'jira'
      },
      {
        value: 'Enable Structure in Jira project',
        uri: '/updateproject/enablestructure',
        target: '_self',
        component: UpdateTicketComponent,
        name: 'structure',
        route: '/updateproject/:type',
        tool: 'jira'
      },
      {
        value: 'Update Existing Project for all Tools',
        uri: '/updateproject/updateproject',
        target: '_self',
        component: UpdateTicketComponent,
        name: 'updateproject',
        route: '/updateproject/:type'
      }
    ]
  },
  {
    name: 'User Access',
    icon: (
      <LockOpenOutlinedIcon
        color='disabled'
        style={{
          fontSize: `${7}rem`,
          margin: 40
        }}
      />
    ),
    type: [
      {
        value: 'Need access to project',
        uri:
          'https://confluence.jnj.com/pages/viewpage.action?pageId=129717833',
        target: '_blank'
      },
      {
        value: 'Reset Captcha for Bitbucket',
        uri: '',
        target: '_self',
        tool: 'bitbucket'
      },
      {
        value: 'Reset Captcha for Jira',
        uri: '',
        target: '_self',
        tool: 'jira'
      },
      {
        value: 'Unlock Artifactory Account',
        uri: '',
        target: '_self',
        tool: 'artifactory'
      },
      {
        value: 'Failed Logins',
        uri: '/useraccess/failedLogins',
        target: '_self',
        component: SplunkMetrics,
        name: 'failedLogins',
        route: '/useraccess/:type'
      }
    ]
  },
  {
    name: 'Other Support',
    icon: (
      <HelpOutlineOutlinedIcon
        color='disabled'
        style={{
          fontSize: `${7}rem`,
          margin: 40
        }}
      />
    ),
    type: [
      {
        value: 'Create an incident ticket',
        uri: '/othersupport/createincident',
        target: '_self',
        component: IncidentComponent,
        name: 'incident',
        route: '/othersupport/:type'
      },
      {
        value: 'Service Request / General Questions',
        uri: '/othersupport/servicerequest',
        target: '_self',
        component: ServiceRequestComponent,
        name: 'servicerequest',
        route: '/othersupport/:type'
      },
      {
        value: 'Apigee Self Service Tool',
        uri: '/othersupport/apigeeselfservice',
        target: '_self',
        component: SupportApigeeSelfServiceComponent,
        name: 'apigeeselfservice',
        route: '/othersupport/:type'
      }
    ]
  }
]

export default function SupportComponent () {
  const {
    setPath
  } = useContext(PathContext)
  const classes = useStyles()
  const {
    support, setSupport
  } = useContext(SupportContext)
  const [successAlert, setSuccessAlert] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState(null)
  const [failureAlert, setFailureAlert] = React.useState(false)
  const [failureMessage, setFailureMessage] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState([])
  const {
    user
  } = useContext(UserContext)
  const [tools, setTools] = React.useState([])

  React.useEffect(() => {
    ToolsService.getEnabledTools().then((response) => {
      setTools(response.data.map((item) => item.name))
      data1.map((item, i) => {
        data1[i].type = item.type.filter((item1) => !item1.tool ||
        (item1.tool &&
          response.data.filter((tool) => tool.name.toLowerCase().includes(item1
            .tool.toLowerCase()))
          .length))
      })
      setData([...data1])
    })
  }, [])
  /* Reset bitbucket captcha and unlock artifactory account */
  const resetAndUnlock = (field) => {
    if (field === 'Reset Captcha for Bitbucket') {
      setLoading(true)
      SupportServices.clearCaptchaForBitbucket().then((response) => {
        setLoading(false)
        setSuccessAlert(true)
        setSuccessMessage('Captcha cleared successfully. Try to login now')
        setTimeout(() => {
          setSuccessAlert(false)
        }, 20000)
      },
      (error) => {
        if (error) {
          setFailureMessage(`
            Something went wrong. Contact system administrator!
          `)
          setLoading(false)
          setFailureAlert(true)
        }
      })
    } else if (field === 'Unlock Artifactory Account') {
      setLoading(true)
      SupportServices.unlockArtifactory().then((response) => {
        setLoading(false)
        setSuccessAlert(true)
        setSuccessMessage(response.data.body
          ? response.data.body
          : response.data)
        setTimeout(() => {
          setSuccessAlert(false)
        }, 20000)
      },
      (error) => {
        if (error) {
          setFailureMessage(`
            Something went wrong. Contact system administrator!
          `)
          setLoading(false)
          setFailureAlert(true)
        }
      })
    } else if (field === 'Reset Captcha for Jira') {
      setLoading(true)
      SupportServices.clearCaptchaForJira().then((response) => {
        setLoading(false)
        setSuccessAlert(true)
        setSuccessMessage('Captcha cleared successfully. Try to login now')
        setTimeout(() => {
          setSuccessAlert(false)
        }, 20000)
      },
      (error) => {
        if (error) {
          setFailureMessage(`
            Something went wrong. Contact system administrator!
          `)
          setLoading(false)
          setFailureAlert(true)
        }
      })
    }
  }

  /* Get List items for the support page section */
  const getListItems = (item) => {
    switch (item.value) {
    case 'Need access to project': return (
      <ListItem
        key='access-link'
        style={{
          paddingTop: 0
        }}
      >
        <a
          style={{
            textDecoration: 'none'
          }}
          href={user.request_access_page}
          target='_blank'
          rel='noreferrer'
        >
          <ListItemText primary={item.value} />
        </a>
      </ListItem>

    )
    case 'Reset Captcha for Bitbucket': return (
      <ListItem
        key={item.uri}
        style={{
          paddingTop: 0
        }}
        component={Link}
        onClick={() => { resetAndUnlock(item.value) }}
      >
        <ListItemText primary={item.value} />
      </ListItem>
    )
    case 'Unlock Artifactory Account': return (
      <ListItem
        key={item.uri}
        style={{
          paddingTop: 0
        }}
        component={Link}
        onClick={() => { resetAndUnlock(item.value) }}
      >
        <ListItemText primary={item.value} />
      </ListItem>
    )
    case 'Reset Captcha for Jira': return (
      <ListItem
        key={item.uri}
        style={{
          paddingTop: 0
        }}
        component={Link}
        onClick={() => { resetAndUnlock(item.value) }}
      >
        <ListItemText primary={item.value} />
      </ListItem>
    )
    default: return (
      <ListItem
        key={item.uri}
        style={{
          paddingTop: 0
        }}
        component={Link}
        to={item.uri}
        target={item.target}
        onClick={() => {
          setPath({
            pathname: item.route,
            component: item.component
          })
        }}
      >
        <ListItemText primary={item.value} />
      </ListItem>
    )
    }
  }

  const SupportItems = () => (
    <>
      {data.map((value) => (
        <Grid
          item
          xs={12}
          md={5}
          key={value.name}
          style={{
            marginRight: '5%',
            marginLeft: '2%'
          }}
        >
          <Card className={classes.cardroot}>
            <Hidden smDown>
              <div>
                <Typography
                  variant='subtitle1'
                  className={classes.margin}
                  gutterBottom
                >
                  {value.name}
                </Typography>
                {value.icon}
              </div>
            </Hidden>
            <div className={classes.details}>
              <Hidden mdUp>
                <Typography
                  variant='subtitle1'
                  className={classes.margin}
                  gutterBottom
                >
                  {value.name}
                </Typography>
              </Hidden>
              <CardContent className={classes.content}>
                <List>
                  {value.type.map((item) => (
                    getListItems(item)
                  ))}
                </List>
              </CardContent>
            </div>
          </Card>
        </Grid>
      ))}
    </>
  )

  // Returns alert type and it's message
  const showAlert = () => {
    if (support.success === true && support.message !== '') {
      return (
        <Alert
          severity='success'
          onClose={() => setSupport({
            success: false,
            message: ''
          })}
        >
          {support.message}
        </Alert>
      )
    } if (support.success === false && support.message !== '') {
      return (
        <Alert
          severity='error'
          onClose={() => setSupport({
            success: false,
            message: ''
          })}
        >
          {support.message}
        </Alert>
      )
    }
  }

  // return "Support Page";
  return (
    <div>
      <div className={classes.alertroot}>
        {showAlert()}
      </div>
      {loading ? <LinearProgress /> : null}
      <div className={classes.alertroot}>
        {' '}
        {successAlert ? (
          <Alert severity='success'>{successMessage}</Alert>
        ) : null}
        {failureAlert ? (
          <Alert
            severity='error'
            onClose={() => setFailureAlert(false)}
          >
            {failureMessage}
          </Alert>
        ) : null}
      </div>
      <div className={classes.root}>
        <Grid container spacing={1}>
          <div className={classes.floatRight}>
            <Button
              color='primary'
              className={classes.pullRightBtn}
              component={Link}
              to='/myIrisRequests'
              target='_self'
              onClick={() => {
                setPath({
                  pathname: '/myIrisRequests',
                  component: SupportViewSubmittedRequestComponent
                })
              }}
            >
              View Submitted Iris Requests
            </Button>
          </div>
          <Grid container item xs={12} spacing={2}>
            <SupportItems />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
