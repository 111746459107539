import axiosInstance from '../../middleware/Interceptor'

/* to get available groups for selected user */
function getUserToModel (input) {
  return axiosInstance.post('/api/v1/requestaccess/getusertomodel', input)
}
/* to check the validation for given username */
function getUserRoleValidation (username) {
  return axiosInstance.get(`/api/v1/requestaccess/
GetRolesForProjectsValidation?username=${username}`)
}
/* to get the UserDN from AD for given username */
function getUserEmployeeType (username) {
  return axiosInstance.get(`/api/v1/auth/
getUserEmployeeType?username=${username}`)
}
/* to add requests to cart from Model User page */
function modelUserAddToCart (input) {
  return axiosInstance.post('/api/v1/requestaccess/modelUserAddToCart', input)
}
/* get available templates for selected user */
function getModelTemplates (username) {
  return axiosInstance.get(`/api/v1/requestaccess/modeluseraccess?username=
${username}`)
}
/* get available groups for selected template */
function getTemplateGroups (input) {
  return axiosInstance.post('/api/v1/requestaccess/gettemplatetomodel', input)
}
const modelUserService = {
  getUserToModel,
  getUserRoleValidation,
  getUserEmployeeType,
  modelUserAddToCart,
  getModelTemplates,
  getTemplateGroups
}

export default modelUserService
