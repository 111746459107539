import React from 'react'
import {
  Typography, Grid, Switch, makeStyles, TextField, Chip
} from '@material-ui/core'
import {
  Autocomplete
} from '@material-ui/lab'
import requestaccess from '../../services/api/requestaccessService'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20
  },
  textfield: {
    width: '80%'
  },
  admingroup: {
    width: '50%',
    padding: 20
  }
}))

export default function AdminGroupAndUsersComponent ({
  page, configdata, handleChange, switchToggle, changeUser
}) {
  const classes = useStyles()
  const [allUsers, setAllUsers] = React.useState([])
  const [loadingOptionsUser, setLoadingOptionsUser] = React.useState(false)

  // API call to get users for auto complete text box
  const getUsers = (value) => {
    setLoadingOptionsUser(true)
    requestaccess.getallusersforautocomplete(value).then((response) => {
      setAllUsers(response.data)
      setLoadingOptionsUser(false)
    })
  }

  const setValuenull = (value) => {
    value.splice(0, 1)
    value = []
  }

  const getDetailContent = () => {
    switch (page) {
    case 'Admin Group':
      return (
        <div className={classes.admingroup}>
          <TextField
            style={{
              width: '50%'
            }}
            label='Admin AD Group'
            name='adminADGroup'
            onChange={(e) => handleChange('admin', e)}
            value={configdata.admin.adminADGroup}
          />
        </div>
      )
    case 'Account Owner Exception': return (
      <div className={classes.admingroup}>
        <Autocomplete
          id='combo-box-demo'
          multiple
          options={allUsers}
          defaultValue={configdata.admin.account_owner_exception}
          getOptionLabel={(option) => (option.name
            ? `${option.name} - ${option.displayname}`
            : option)}

          loading={allUsers.length === 0 && loadingOptionsUser}
          filterOptions={(options, state) => options}
          getOptionSelected={(option, value) => option.name === value.name}
          renderTags={
            (value, getTagProps) => value.map((option,
              index) => (configdata.admin.account_owner_exception.length > 0
              ? (
                <Chip
                  label={
                    option.name
                      ? `${option.name} - ${option.displayname}`
                      : option
                  }
                  {...getTagProps({
                    index
                  })}
                />
              ) : (
                setValuenull(value)
              )))
          }
          onChange={(event, value) => changeUser(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Choose a user *'
              name='username'
              onChange={(event) => getUsers(event.target.value)}
              value={configdata.admin.account_owner_exception}

            />
          )}
        />
      </div>
    )
    default:
      return null
    }
  }
  return (
    getDetailContent()
  )
}
