/* It contains all the API functions which is releated to login page */

import axiosInstance from '../../middleware/Interceptor'

// to get archived jira projects
function getArchivedJiraProjects () {
  return axiosInstance
    .get('/api/v1/archiverestore/getAllArchivedJiraProjects')
}

const ArchiveJiraProjectService = {
  getArchivedJiraProjects
}

export default ArchiveJiraProjectService