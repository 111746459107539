
/* It contains all the API functions which is releated to SSH and GPG keys section */

import axiosInstance from '../../middleware/Interceptor'
/* To check the user having access to bitbucket */
function checkBitbucketUser (username) {
  return axiosInstance.get(`/api/v1/auth/checkBitbucketUser?
username=${username}`)
}
/* To get ssh keys for user */
function sshGetUserKey () {
  return axiosInstance.get('/api/v1/auth/sshGetUserKey')
}

/* To add ssh key */
function sshAddUserKey () {
  return axiosInstance.get('/api/v1/auth/sshAddUserKey')
}
/* To delete ssh key */
function deletePublicSSHKey (id) {
  return axiosInstance.delete(`/api/v1/auth/deletePublicSSHKey?id=${id}`)
}
/* To get gpg keys for user */
function getuserGPGKey () {
  return axiosInstance.get('/api/v1/auth/gpgGetUserKey')
}
/* To add gpg key */
function addGPGKey (input) {
  return axiosInstance.post('/api/v1/auth/gpgAddUserKey', input)
}
/* To delete gpg key */
function deleteGPGKey (id) {
  return axiosInstance.delete(`/api/v1/auth/deleteGPGKey?id=${id}`)
}

function getUsernameFromGPGID (gpgID) {
  return axiosInstance.get(`/api/v1/bitbucket/getUsernameFromGPGID?
gpgID=${gpgID}`)
}

const SshAndGpgKeyService = {
  checkBitbucketUser,
  sshGetUserKey,
  sshAddUserKey,
  deletePublicSSHKey,
  getuserGPGKey,
  addGPGKey,
  deleteGPGKey,
  getUsernameFromGPGID
}

export default SshAndGpgKeyService
