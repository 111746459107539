import axiosInstance from '../../middleware/Interceptor'

// Service method to Get IDMS groups
function getIDMSGroups (group) {
  return axiosInstance.get(`/api/v1/reports/getGroups?group=${group}`)
}

// Service method to Get projects for selected IDMS group and tool
function getGroupAccess (group, tool) {
  return axiosInstance.get(`/api/v1/reports/getGroupAccess?group=
${group}&toolname=${tool}`)
}

const groupAccessService = {
  getIDMSGroups,
  getGroupAccess
}

export default groupAccessService
