import React from 'react'
import {
  Typography, Grid, makeStyles, TextField
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20
  },
  textfield: {
    width: '80%'
  },
  bold: {
    fontWeight: 600,
    marginTop: 20
  }
}))

export default function IrisConfigComponent ({
  page, configdata, handleChange, groupChange
}) {
  const classes = useStyles()
  const getDetailContent = () => {
    switch (page) {
    case 'Service Task':
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TextField
                label='IRIS Ticket URL'
                onChange={(e) => handleChange('iris', e)}
                name='uri'
                value={configdata.iris.uri}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant='subtitle1'
                gutterBottom
                className={classes.bold}
              >
                Token Validation
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                label='URI'
                onChange={(e) => handleChange('iris', e, 'token_validation')}
                name='uri'
                value={configdata.iris.token_validation.uri}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label='Request Body'
                name='request_body'
                multiline
                rows='6'
                onChange={(e) => handleChange('iris', e, 'token_validation')}
                value={configdata.iris.token_validation.request_body}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant='subtitle1'
                gutterBottom
                className={classes.bold}
              >
                Alert Messages
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label='GxP Alert Message'
                onChange={(e) => handleChange('iris', e, 'alertMessages')}
                name='gxp_alert'
                value={configdata.iris.alertMessages.gxp_alert}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label='Xray Alert Message'
                name='xray_alert'
                onChange={(e) => handleChange('iris', e, 'alertMessages')}
                value={configdata.iris.alertMessages.xray_alert}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label='qTest Alert Message'
                name='qtest_alert'
                onChange={(e) => handleChange('iris', e, 'alertMessages')}
                value={configdata.iris.alertMessages.qtest_alert}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant='subtitle1'
                gutterBottom
                className={classes.bold}
              >
                Group To Create Bug
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                label='Group to create bug'
                onChange={(e) => handleChange('iris', e)}
                name='groupToCreateBug'
                value={configdata.iris.groupToCreateBug}
                className={classes.textfield}
              />
            </Grid>
          </Grid>
        </div>
      )
    case 'Incident':
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography
                variant='subtitle1'
                gutterBottom
                className={classes.bold}
              >
                Configuration Item
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label='Username'
                multiline
                rows='4'
                onChange={(e) => handleChange('iris', e)}
                name='configuration_item'
                value={configdata.iris.configuration_item}
                className={classes.textfield}
              />
            </Grid>

          </Grid>
        </div>
      )
    case 'Risk Assessment':
      return (
        <div className={classes.root}>
          <Grid item xs={12} md={12}>
            <Typography
              variant='subtitle1'
              gutterBottom
              className={classes.bold}
            >
              GxP Compliance Risk
            </Typography>
          </Grid>

          <Grid container spacing={3}>
            {configdata.iris.risk_assessment.gxp_compliance_risk.map((item,
              i) => (
              <Grid item xs={6} md={4} key={`risk${i}`}>
                <TextField
                  onChange={
                    (e) => groupChange(i, e, 'iris',
                      'risk_assessment', 'gxp_compliance_risk')
                  }
                  name='name'
                  value={item.name}
                  className={classes.textfield}
                />
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography
              variant='subtitle1'
              gutterBottom
              className={classes.bold}
            >
              Supplier Audit Assessment
            </Typography>
          </Grid>

          <Grid container spacing={3}>
            {configdata.iris.risk_assessment
              .supplier_audit_assessment.map((item, i) => (
                <Grid item xs={6} md={4} key={`supplier${i}`}>
                  <TextField
                    onChange={
                      (e) => groupChange(i, e, 'iris',
                        'risk_assessment', 'supplier_audit_assessment')
                    }
                    name='name'
                    value={item.name}
                    className={classes.textfield}
                  />
                </Grid>
              ))}
          </Grid>

        </div>
      )
    case 'Artifactory':
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TextField
                label='URL'
                onChange={(e) => handleChange('artifactory', e)}
                name='uri'
                value={configdata.artifactory.uri}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant='subtitle1'
                gutterBottom
                className={classes.bold}
              >
                Artifactory Packages
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                multiline
                rows='4'
                onChange={(e) => handleChange('iris', e)}
                name='packages'
                value={configdata.iris.packages}
                className={classes.textfield}
              />
            </Grid>
          </Grid>
        </div>
      )

    default:
      return null
    }
  }
  return (
    getDetailContent()
  )
}
