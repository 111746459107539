/* It contains all the API functions which is releated to login page */

import axios from 'axios'
import axiosInstance from '../../middleware/Interceptor'

// login post call
function login (username, password) {
  return axios.post('/api/login', {
    username,
    password
  })
}

// logout API
function logout () {
  return axiosInstance.get('/api/logout')
}
// API to check the current user is valid
function checkValidation () {
  return axiosInstance.get('/api/v1/auth/checkValidation')
}
// to get details of current username, admin access, approval count of user
function getAdminaccess () {
  return axiosInstance.get('/api/getAdminaccess')
}
// to get the git details
function getGitdetails () {
  return axios.get('/api/getGitdetails')
}
// to get the git details
const {
  CancelToken
} = axios
let cancel
function getConfHelpPages (searchkey) {
  if (cancel !== undefined) {
    cancel()
  }
  return axiosInstance.get(`/api/v1/confluence/getConfHelpPages?searchString=
${searchkey}`,
  {
    cancelToken: new CancelToken((c) => {
      cancel = c
    })
  })
}

// check user is admin or not
function checkAdmin (input) {
  return axiosInstance.post('/api/checkAdmin', input)
}
// set switch user
function setSwitchUser (username) {
  return axiosInstance.post(`/api/setSwitchUser?switch_user=${username}`, {
  })
}
// Delete switch user
function deleteSwitchUser (username) {
  return axios.get('/api/deleteSwitchUser')
}
// Get Banner Data
function getBannerData () {
  return axios.get('/api/v1/auth/getBannerData')
}
// Get ADP Menus
function getActiveADPMenus () {
  return axiosInstance.get('/api/v1/menu/menu')
}
const LoginService = {
  login,
  logout,
  checkValidation,
  getAdminaccess,
  getGitdetails,
  getConfHelpPages,
  checkAdmin,
  setSwitchUser,
  deleteSwitchUser,
  getBannerData,
  getActiveADPMenus
}

export default LoginService
