import React, {
  useContext
} from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import Switch from '@material-ui/core/Switch'
import DeleteIcon from '@material-ui/icons/Delete'
import FilterListIcon from '@material-ui/icons/FilterList'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import Collapse from '@material-ui/core/Collapse'
import Box from '@material-ui/core/Box'
import moment from 'moment'
import {

  Card,

  Grid,
  MenuItem,
  Select,
  InputLabel,
  Button,
  LinearProgress
} from '@material-ui/core'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import {
  withStyles
} from '@material-ui/core/styles'
import {
  green
} from '@material-ui/core/colors'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import requestAccess from '../../services/api/requestaccessService'

import splunkService from '../../services/api/splunkService'

import Row from './Row'
import EnhancedTable from '../sections/enhancedTable'
import Pagination from './Pagination'
import ToolsService from '../../services/api/toolsService'

const styles = (theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  success: {
    backgroundColor: theme.palette.success.light
  },
  pending: {
    backgroundColor: '#fff9c4'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  }
})

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600]
    }
  },
  checked: {
  }
})((props) => <Radio color='default' {...props} />)


let headCells = [

  {
    id: '_time',
    numeric: false,
    label: 'Time'
  },
  {
    id: 'jira_username',
    numeric: false,
    label: 'User Name',
    type: 'username'
  },
  {
    id: 'source_ip',
    numeric: false,
    type: 'ip',
    label: 'Ip Address'
  }

]


class SplunkMetrics extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      filterData: {
        selectedValue: 'self',
        usernameDisplay: '',
        username: '',
        timeRane: '',
        tool: ''
      },
      allUsers: [],
      loadingoptions: false,
      loadingoptions_user: false,
      loading: false,
      rows: [],
      open: false,
      page: 0,
      rowsPerPage: 5,
      tools: []
    }
    this.changeUser = this.changeUser.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.toggleRow = this.toggleRow.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
  }

  componentDidMount () {
    ToolsService.getEnabledTools().then((response) => {
      const tools = []
      response.data.map((item) => {
        if (item.name === 'Jira') {
          tools.push('Jira')
        }
        if (item.name === 'Bitbucket') {
          tools.push('Bitbucket')
        }
      })
      this.setState({
        tools
      })
    })
  }

     handleChangePage = (event, newPage) => {
       this.setState({
         page: newPage
       })
     };

    handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value)
      })
      this.setState({
        page: 0
      })
    };

    getUsers = (value) => {
      this.setState({
        loadingoptions_user: true
      })
      requestAccess.getallusersforautocomplete(value).then((response) => {
        this.setState({
          allUsers: response.data,
          loadingoptions_user: false
        })
      })
    }

      handleChange = (event) => {
        const filterData = {
          selectedValue: event.target.value
        }
        this.setState({
          filterData
        })

        // setSelectedValue(event.target.value);
      };

    changeUser =(value) => {
      if (value) {
        const {
          filterData
        } = this.state

        filterData.username = value.name
        filterData.usernameDisplay = `${value.name} - ${value.displayname}`
        this.setState({
          filterData
        })
        this.forceUpdate()
      }
    }

    handleChangeTimeChange =(value) => {
      if (value) {
        const {
          filterData
        } = this.state
        let object = new Object()
        object = filterData
        object.timeRane = value.target.value
        this.setState((state) => ({
          ...state,
          filterData: object
        }))
        this.forceUpdate()
        //   this.setState({ filterData: filterData })
      }
    }

    handleChangeTool = (value) => {
      if (value) {
        const {
          filterData
        } = this.state
        let object = new Object()
        object = filterData
        object.tool = value.target.value
        this.setState((state) => ({
          ...state,
          filterData: object
        }))
        this.forceUpdate()
        //   this.setState({ filterData: filterData })
      }
    }

    handleSubmit =(data) => {
      const {
        filterData
      } = this.state

      this.setState((state, props) => ({
        loading: true
      }))


      const object = new Object()
      object.userName = this.state.filterData.username
      object.timeRange = this.state.filterData.timeRane
      object.tool = this.state.filterData.tool
      if (this.state.filterData.tool === 'bitbucket') {
        headCells = []
        headCells = [

          {
            id: '_time',
            numeric: false,
            label: 'Time'
          },
          {
            id: 'adp_usr_bb_authFevt',
            numeric: false,
            label: 'User Name',
            type: 'username'
          },
          {
            id: 'adp_ip_bb_authFevt',
            numeric: false,
            type: 'ip',
            label: 'Ip Address'
          }

        ]
      }
      splunkService.searchSplunkMetrics(object).then((response) => {
        const rows = []
        if (response.data) {
          for (const result of response.data.results) {
            if (this.state.filterData.tool === 'bitbucket') {
              /* eslint no-underscore-dangle: ["error", { "allow": ["_time", "_raw"] }]*/
              const date = moment(result._time)
                .format('MMMM Do YYYY, h:mm:ss a')
              rows.push({
                time: `${date} EST`,
                username: result.adp_usr_bb_authFevt,
                ipaddress: result.adp_ip_bb_authFevt,
                raw: result._raw
              })
            } else {
              const date = moment(result._time)
                .format('MMMM Do YYYY, h:mm:ss a')
              rows.push({
                time: `${date} EST`,
                username: result.jira_username,
                ipaddress: result.source_ip,
                raw: result._raw
              })
            }
          }
        }
        this.setState((state, props) => ({
          rows
        }))
        this.setState((state, props) => ({
          loading: false
        }))
      })
    }

    toggleRow () {
      this.setState((state, props) => ({
        open: !state.open
      }))
    }


    renderForm =() => {
      const {
        classes
      } = this.props
      const {
        page, rowsPerPage
      } = this.state
      return (
        <div className={classes.root}>
          {this.state.loading ? <LinearProgress /> : null}
          <Grid container spacing={1} className={classes.root}>

            <Grid item xs={12} md={12}>
              <Card variant='outlined'>


                <ValidatorForm
                  ref='form'
                >


                  <Typography
                    style={{
                      padding: '15px'
                    }}
                    variant='subtitle1'
                    gutterBottom
                  >
                    User Failed Login Events
                  </Typography>
                  <div style={{
                    paddingTop: 10
                  }}
                  >
                    <FormControlLabel
                      value='self'
                      control={(
                        <Radio
                          checked={
                            this.state.filterData.selectedValue === 'self'
                          }
                          onChange={this.handleChange}
                          value='self'
                          name='selfChooser'
                          inputProps={{
                            'aria-label': 'Self'
                          }}
                        />
                      )}
                      label='Self'
                      labelPlacement='start'
                    />

                    <FormControlLabel
                      value='others'
                      control={(
                        <Radio
                          checked={
                            this.state.filterData.selectedValue === 'user'
                          }
                          onChange={this.handleChange}
                          value='user'
                          name='selfChooser'
                          inputProps={{
                            'aria-label': 'User'
                          }}
                        />
                      )}
                      label='Others'
                      labelPlacement='start'
                    />

                    {this.state.filterData.selectedValue !== 'self' && (
                      <FormControl style={{
                        width: '200px',
                        marginLeft: '14px',
                        marginTop: '-10px'
                      }}
                      >
                        <Autocomplete
                          id='combo-box-demo'
                          options={this.state.allUsers}
                          value={this.state.filterData.usernameDisplay}
                          onChange={(event, value) => this.changeUser(value)}
                          filterOptions={(options, state) => options}
                          getOptionLabel={(option) => (option.name
                            ? `${option.name} - ${option.displayname}`
                            : option)}
                          loading={
                            this.state.allUsers.length === 0 &&
                            this.state.loadingoptions_user
                          }
                          getOptionSelected={
                            (option, value) => option.name === value.name
                          }

                          renderInput={(params) => (
                            <TextValidator
                              {...params}
                              label='Choose a user *'
                              name='username'
                              onChange={
                                (event) => this.getUsers(event.target.value)
                              }
                              value={this.state.filterData.usernameDisplay}
                              size='small'
                              validators={['required']}
                              errorMessages={['This field is required']}
                            />
                          )}
                        />
                      </FormControl>
                    )}

                    <FormControl
                      className={classes.formControl}
                      style={{
                        width: '200px',
                        marginLeft: '14px',
                        marginTop: '-10px'
                      }}
                    >
                      <InputLabel id='demo-simple-select-label'>
                        Tool
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        value={this.state.filterData.tool}
                        onChange={this.handleChangeTool.bind(this)}
                      >
                        {this.state.tools.map((item) => (
                          <MenuItem value={item.toLowerCase()}>{item}</MenuItem>
                        ))}

                      </Select>
                    </FormControl>

                    <Button
                      variant='contained'
                      onClick={this.handleSubmit}
                      color='primary'
                    >
                      Show
                    </Button>

                  </div>
                </ValidatorForm>


                <div>

                  {this.state.rows.length > 0 && (
                    <>
                      <TableContainer component={Paper}>
                        <Table aria-label='collapsible table'>
                          <TableHead>
                            <TableRow>
                              <TableCell />
                              <TableCell>Time</TableCell>
                              <TableCell align='right'>UserName</TableCell>
                              <TableCell align='right'>Ip Address</TableCell>

                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.rows
                              .slice(page * rowsPerPage, page *
                                rowsPerPage + rowsPerPage)
                              .map((row, index) => (
                                <Row row={row} index={index} />
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        component='div'
                        count={this.state.rows.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      />
                    </>
                  )}
                </div>

              </Card>
            </Grid>

          </Grid>
        </div>
      )
    }


    render () {
      return (

        this.renderForm()

      )
    }
}
export default withStyles(styles)(SplunkMetrics)
