import React, {
  Component
} from 'react'
import Quill from 'quill'
import ReactQuill from './ReactQuill/index'

import 'quill/dist/quill.snow.css'
import './QuillMentions/quill.mention.css'
import './index.css'
import QuillMention from './QuillMentions/quill.mention'

Quill.register({
  'modules/mention': QuillMention
})


let boardId = ''

class RichTextEditor extends Component {
  constructor (props) {
    super(props)
    this.state = {
      theme: 'snow'
    }
    this.handleEditor = this.handleEditor.bind(this)
  }

  componentDidUpdate () {
    boardId = this.props.boardid
  }

  componentWillUnmount () {
    boardId = ''
  }

  handleEditor (html, delta, source, editor) {
    if (source === 'user') {
      this.props.handleEditorChange(html)
    }
  }

  render () {
    return (
      <div>
        {this.props.showTablesModule !== 'true' && (
          <ReactQuill
            theme={this.state.theme}
            onChange={this.handleEditor}
            defaultValue={this.props.editorValue}
            value={this.props.editorValue}
            modules={(this.props.showTextModule &&
              this.props.showTextModule === 'true')
              ? RichTextEditor.modulesForText : RichTextEditor.modules}
            formats={RichTextEditor.formats}
            placeholder=''
            showTablesModule={this.props.showTablesModule}
          />
        )}

        {this.props.showTablesModule === 'true' && (
          <ReactQuill
            theme={this.state.theme}
            onChange={this.handleEditor}
            defaultValue={this.props.editorValue}
            value={this.props.editorValue}
            modules={RichTextEditor.modulesWithTables}
            formats={RichTextEditor.formats}
            placeholder=''
            showTablesModule={this.props.showTablesModule}
          />
        )}

      </div>
    )
  }
}

RichTextEditor.modules = {
  table: true,
  toolbar: [
    [{
      header: [1, 2, 3, 4, 5, 6, false]
    }],
    ['bold', 'italic', 'underline', 'strike'],
    [{
      color: []
    },
    {
      background: []
    }],
    [{
      'list': 'ordered'
    }, {
      'list': 'bullet'
    }],
    [{
      'indent': '-1'
    }, {
      'indent': '+1'
    }],
    [{
      'align': []
    }],
    ['blockquote', 'link', 'image']


  ]
  // mention: {
  //   allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
  //   mentionDenotationChars: ['@', '#'],
  //   async source (searchTerm, renderItem, mentionChar) {
  //     let values
  //     const matchedPeople = await suggestPeople(searchTerm)
  //     renderItem(matchedPeople)
  //   }
  // }


}

RichTextEditor.modulesWithTables = {
  table: true,
  toolbar: [
    [{
      header: [1, 2, 3, 4, 5, 6, false]
    }],
    ['bold', 'italic', 'underline', 'strike'],
    [{
      color: []
    },
    {
      background: []
    }],
    [{
      'list': 'ordered'
    }, {
      'list': 'bullet'
    }],
    [{
      'indent': '-1'
    }, {
      'indent': '+1'
    }],
    [{
      'align': []
    }],
    ['blockquote', 'link', 'image'],
    ['table', 'column-left', 'column-right', 'row-above',
      'row-below', 'row-remove', 'column-remove', 'delete-table']

  ]
  // mention: {
  //   allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
  //   mentionDenotationChars: ['@', '#'],
  //   async source (searchTerm, renderItem, mentionChar) {
  //     let values
  //     const matchedPeople = await suggestPeople(searchTerm)
  //     renderItem(matchedPeople)
  //   }
  // }
}

RichTextEditor.modulesForText = {
  table: true,
  toolbar: [
    [{
      header: [1, 2, 3, 4, 5, 6, false]
    }],
    ['bold', 'italic', 'underline', 'strike'],
    [{
      color: []
    },
    {
      background: []
    }],
    [{
      'list': 'ordered'
    }, {
      'list': 'bullet'
    }],
    [{
      'indent': '-1'
    }, {
      'indent': '+1'
    }],
    [{
      'align': []
    }]
  ]
  // mention: {
  //   allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
  //   mentionDenotationChars: ['@', '#'],
  //   async source (searchTerm, renderItem, mentionChar) {
  //     let values
  //     const matchedPeople = await suggestPeople(searchTerm)
  //     renderItem(matchedPeople)
  //   }
  // }
}

// async function suggestPeople (searchTerm, boardid) {
//   if (searchTerm !== '') {
//     const usesrs = await boardSettingsService
//       .getBoardUsersForComment(boardId, searchTerm).then((data) => {
//         const tempUsers = data.data.map((item) => {
//           const object = new Object()
//           object.id = item.displayname
//           object.value = item.displayname
//           return object
//         })
//         return tempUsers
//       })
//     return usesrs
//   }
// }

RichTextEditor.formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'align',
  'indent',
  'link',
  'color',
  'background'

]

export default RichTextEditor
