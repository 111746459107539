import React from 'react'
import InfoIcon from '@material-ui/icons/Info'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  MenuItem,
  withStyles,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  Checkbox,
  LinearProgress,
  Tooltip,
  Radio,
  Link,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import PropTypes from 'prop-types'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import queryString from 'query-string'
import parse from 'html-react-parser'
import RadioButtons from '../inputs/radio'
import ToolsService from '../../services/api/toolsService'
import AlertDialog from '../alertbox/alertboxComponent'
import requestAccess from '../../services/api/requestaccessService'
import UserContext from '../contexts/UserContext'
import CartService from '../../services/api/cartService'
import EmailServices from '../../services/api/emailServices'
import LoginService from '../../services/api/loginService'
import ApigeeService from '../../services/api/apigeeService'
import SurveyService from '../../services/api/surveyService'

const styles = (theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  success: {
    backgroundColor: theme.palette.success.light
  },
  pending: {
    backgroundColor: '#fff9c4'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  }
})
const parseHtml = (htmlMessage) => parse(htmlMessage)

class ByUserComponent extends React.Component {
  static contextType = UserContext

  constructor (props) {
    super(props)
    this.state = {
      formData: {
        email: '',
        password: '',
        toolname: '',
        projectkey: '',
        project: null,
        comments: '',
        username: '',
        usernameDisplay: '',
        clustor: '',
        org: '',
        apigeeTeam: ''
      },
      alert: false,
      alertbox: false,
      alertboxMessage: '',
      ou: null,
      buttontype: null,
      successalertMessage: '',
      failalertMessage: '',
      successAlert: false,
      errorAlert: false,
      allProjects: [],
      allUsers: [],
      allRoles: [],
      alltools: [],
      selectedGroups: [],
      radio: ['Self', 'Other'],
      selectedvalue: 'Self',
      loadingoptions: false,
      loadingoptions_user: false,
      loadingbar: false,
      clustorArray: [],
      disableCheckBoxForQTest: false,
      apigeeOrgs: [],
      apigeeOrgTeamsAndRoles: [],
      githubAlert: false,
      surveyValueRadio: '',
      surveyValueText: 'XENA Teams Channel, XENA confluence page ...',
      surveyData: [],
      surveyDialog: false,
      surveyJpmAlert: false,
      surveyJpmAlertMessage: ''
    }
  }

  /* function will be called when the user name selected from the options */
  changeUser = (value) => {
    if (value) {
      /*  to clear the values of other fields when username is changed */
      this.setState({
        allRoles: []
      })
      this.setState({
        allProjects: []
      })
      this.setState({
        selectedGroups: []
      })
      const {
        formData
      } = this.state
      formData.projectkey = ''
      formData.project = ''
      formData.toolname = ''
      formData.clustor = ''
      formData.org = ''
      formData.apigeeTeam = ''
      formData.username = value.name
      formData.usernameDisplay = `${value.name} - ${value.displayname}`
      this.setState({
        formData
      })
    }
  }

  checkSwitchUser = () => {
    const {
      user
    } = this.context
    return !!user.switch_user
  }

  /* function to change the clustor */
  changeClustor = (value) => {
    let username
    if (this.state.selectedvalue === 'Other') {
      username = this.state.formData.username
    } else {
      const {
        user
      } = this.context
      username = user.name
    }

    const {
      formData
    } = this.state

    formData.clustor = value.target.value

    this.setState({
      loadingbar: true
    })
    requestAccess
      .getRolesBasedOnClustor(this.state.formData.toolname.name,
        this.state.formData.project.key,
        this.state.formData.toolname.is_key,
        this.state.formData.clustor.clustor)
      .then((response) => {
        const allRoles = response.data
        requestAccess
          .GetRolesForProjectsValidation(username,
            this.state.formData.toolname.name,
            this.state.formData.project.key,
            1)
          .then((response1) => {
            this.setState({
              loadingbar: false
            })
            const validationdata = response1.data
            allRoles.forEach((role, index) => {
              if (validationdata.length) {
                validationdata.forEach((validation) => {
                  if (validation.already_access &&
                    role.name.toLowerCase() ===
                      validation.already_access.toLowerCase()) {
                    allRoles[index].status = 'access'
                    allRoles[index].message = `Selected user already 
                      granted this role`
                  } else if (validation.cart_requests &&
                    role.name.toLowerCase() ===
                      validation.cart_requests.toLowerCase()) {
                    allRoles[index].status = 'cart'
                    allRoles[index].message = `Selected role with user already 
                      added to the cart by ${validation.cart_user}`
                  } else if (validation.pending_requests &&
                    role.name.toLowerCase() ===
                      validation.pending_requests.toLowerCase()) {
                    allRoles[index].status = 'pending'
                    allRoles[index].message = `Selected role with user 
                      is pending for approval`
                  } else if (validation.processing_request &&
                    role.name.toLowerCase() ===
                      validation.processing_request.toLowerCase()) {
                    allRoles[index].status = 'pending'
                    allRoles[index].message = `Selected role with user 
                      is processing in IDMS`
                  }
                })
              }
            })
            this.setState({
              allRoles
            })
          })

        this.setState({
          formData
        })
      })
  }

  /* function will be called to get available roles when the project key selected from the options */
  changeKey = (value) => {
    if (value) {
      let username
      if (this.state.selectedvalue === 'Other') {
        username = this.state.formData.username
      } else {
        const {
          user
        } = this.context
        username = user.name
      }
      const key = value.key ? value.key : value.name
      const {
        formData
      } = this.state
      formData.projectkey = value.key
        ? `${value.key} - ${value.key_app_name}`
        : value.name
      formData.project = value
      if (this.state.formData.toolname.name === 'Cloudx Kubernetes') {
        requestAccess
          .getClustor(key)
          .then((response) => {
            this.setState({
              clustorArray: response.data
            })
          })
      } else {
        this.setState({
          loadingbar: true
        })
        requestAccess
          .getProjectRoles(this.state.formData.toolname.name,
            key,
            this.state.formData.toolname.is_key)
          .then((response) => {
            const allRoles = response.data
            requestAccess
              .GetRolesForProjectsValidation(username,
                this.state.formData.toolname.name,
                key,
                1)
              .then((response1) => {
                this.setState({
                  loadingbar: false
                })
                const validationdata = response1.data
                this.setState({
                  disableCheckBoxForQTest: false
                })
                allRoles.forEach((role, index) => {
                  if (validationdata.length) {
                    validationdata.forEach((validation) => {
                      if (validation.already_access &&
                        role.name.toLowerCase() ===
                          validation.already_access.toLowerCase()) {
                        allRoles[index].status = 'access'
                        allRoles[index].message = `Selected user already 
                          granted this role`
                      } else if (validation.cart_requests &&
                        role.name.toLowerCase() ===
                          validation.cart_requests.toLowerCase()) {
                        allRoles[index].status = 'cart'
                        allRoles[index].message = `Selected role with user 
                          already added to the cart by ${validation.cart_user}`
                      } else if (validation.pending_requests &&
                        role.name.toLowerCase() ===
                          validation.pending_requests.toLowerCase()) {
                        allRoles[index].status = 'pending'
                        allRoles[index].message = `Selected role with user 
                          is pending for approval`
                      } else if (validation.processing_request &&
                        role.name.toLowerCase() ===
                          validation.processing_request.toLowerCase()) {
                        allRoles[index].status = 'pending'
                        allRoles[index].message = `Selected role with user 
                          is processing in IDMS`
                      }
                      if (this.state.formData.toolname.name.toLowerCase() ===
                        'qtest' &&
                      ((validation.cart_requests &&
                        role.name.toLowerCase() ===
                          validation.cart_requests.toLowerCase()) ||
                      (validation.already_access &&
                        role.name.toLowerCase() ===
                          validation.already_access.toLowerCase()) ||
                      (validation.pending_requests &&
                        role.name.toLowerCase() ===
                          validation.pending_requests.toLowerCase()) ||
                      (validation.processing_request &&
                        role.name.toLowerCase() ===
                          validation.processing_request.toLowerCase()))) {
                        this.setState({
                          disableCheckBoxForQTest: true
                        })
                      }
                    })
                  }
                })
                this.setState({
                  allRoles
                })
              })
            if (!this.state.formData.toolname.is_key) {
              if (
                this.state.formData.toolname.name.toLowerCase() === 'applitools'
              ) {
                const username1 = `${username.toLowerCase()}@its.jnj.com`
                if (
                  this.state.formData.project.members &&
                this.state.formData.project.members[username1]
                ) {
                  allRoles.forEach((role, index) => {
                    if (
                      role.name.toLowerCase() === 'viewer' &&
                    this.state.formData.project.members[username1].isViewer
                    ) {
                      allRoles[index].status = 'access'
                      allRoles[index].message = `Selected user 
                        already granted access`
                    } else if (role.name.toLowerCase() === 'regular') {
                      allRoles[index].status = 'access'
                      allRoles[index].message = `Selected user 
                        already granted access`
                    }
                  })
                }
                this.setState({
                  allRoles
                })
              } else if (
                this.state.formData.toolname.name.toLowerCase() === 'blazemeter'
              ) {
                requestAccess
                  .checkUseraccessforAPItoolgroup(this.state.formData
                    .toolname.name,
                  username,
                  value.id)
                  .then((accessResponseForAPIToolGroup) => {
                    const {
                      data
                    } = accessResponseForAPIToolGroup
                    if (data.length && data[0].roles) {
                      allRoles.forEach((role, index) => {
                        if (
                          data[0].roles.indexOf(role.name.toLowerCase()) >
                        -1
                        ) {
                          allRoles[index].status = 'access'
                          allRoles[index].message =
                          'Selected user already granted access'
                        }
                      })
                    }
                    this.setState({
                      allRoles
                    })
                  },
                  (error) => {
                    if (error) {}
                    this.setState({
                      loadingbar: false,
                      failalertMessage:
                        'Something went wrong. Contact system administrator!',
                      errorAlert: true
                    })
                  })
              } else if (
                this.state.formData.toolname.name.toLowerCase() === 'saucelabs'
              ) {
                requestAccess
                  .checkUseraccessforAPItool(this.state.formData.toolname.name,
                    username)
                  .then((accessResponseAPITool) => {
                    const {
                      data
                    } = accessResponseAPITool
                    if (data.length && data[0].username) {
                      allRoles[0].status = 'access'
                      allRoles[0].message = `Selected user 
                        already granted access`
                    }
                    this.setState({
                      allRoles
                    })
                  },
                  (error) => {
                    if (error) {}
                    this.setState({
                      loadingbar: false,
                      failalertMessage:
                        'Something went wrong. Contact system administrator!',
                      errorAlert: true
                    })
                  })
              }
            }
            this.setState({
              formData
            })
          })
      }
    }
  }

  /* function will be called when form fields getting changed and used to update state with new values */
  handleChange = async (event) => {
    const {
      formData
    } = this.state
    formData[event.target.name] = event.target.value
    if (event.target.name === 'toolname') {
      /*  to clear the values of other fields when toolname is changed */
      formData.projectkey = ''
      formData.project = ''
      formData.org = ''
      formData.apigeeTeam = ''
      this.setState({
        allRoles: [],
        allProjects: [],
        selectedGroups: [],
        apigeeOrgs: [],
        apigeeOrgTeamsAndRoles: []
      })
      if (event.target.value.name === 'XENA Framework Tools') {
        this.changeKey({
          'key': 'XENA',
          'key_app_name': 'XENA',
          'key_prefix': 'asx'
        })
      }
    }
    this.setState({
      formData
    })

    if (
      event.target.name === 'toolname' &&
      this.state.formData.toolname.is_key === false &&
      this.state.formData.toolname.name !== 'Apigee'
    ) {
      /*    If the selected tool is API tool then the API will be called to get groups for pre populate */
      ToolsService
        .getApiToolGroups(this.state.formData.toolname.name)
        .then((response) => {
          this.setState({
            allProjects: response.data
          })
          if (response.data.length === 0) {
            this.changeKey({
              name: 'N/A'
            })
          }
        })
    }
    if (
      event.target.name === 'toolname' &&
      this.state.formData.toolname.name === 'Apigee'
    ) {
      ApigeeService.getApigeeOrgs().then((response) => {
        this.setState({
          apigeeOrgs: response.data
        })
      })
    }
    if (
      event.target.name === 'org' &&
      this.state.formData.toolname.name === 'Apigee'
    ) {
      ApigeeService.getTeamsForOrgs(event.target.value).then((response) => {
        formData.apigeeTeam = ''
        this.setState({
          apigeeOrgTeamsAndRoles: response.data,
          allRoles: [],
          formData
        })
      })
    }
    if (
      event.target.name === 'apigeeTeam' &&
      this.state.formData.toolname.name === 'Apigee'
    ) {
      let username
      if (this.state.selectedvalue === 'Other') {
        username = this.state.formData.username
      } else {
        const {
          user
        } = this.context
        username = user.name
      }
      const apigeeTeam = formData.apigeeTeam
      const teamsAndRoles = this.state.apigeeOrgTeamsAndRoles
      requestAccess
        .GetRolesForProjectsValidation(username,
          this.state.formData.toolname.name,
          apigeeTeam,
          1)
        .then((response1) => {
          for (const teamAndRoles of teamsAndRoles) {
            if (teamAndRoles.name === apigeeTeam) {
              const allRoles = teamAndRoles.roles
              const validationdata = response1.data
              allRoles.forEach((role, index) => {
                if (validationdata.length) {
                  validationdata.forEach((validation) => {
                    if (validation.already_access &&
                      role.name.toLowerCase() ===
                        validation.already_access.toLowerCase()) {
                      allRoles[index].status = 'access'
                      allRoles[index].message = `Selected user already 
                        granted this role`
                    } else if (validation.cart_requests &&
                      role.name.toLowerCase() ===
                        validation.cart_requests.toLowerCase()) {
                      allRoles[index].status = 'cart'
                      allRoles[index].message = `Selected role with user already
                         added to the cart by ${validation.cart_user}`
                    } else if (validation.pending_requests &&
                      role.name.toLowerCase() ===
                        validation.pending_requests.toLowerCase()) {
                      allRoles[index].status = 'pending'
                      allRoles[index].message = `Selected role with user 
                        is pending for approval`
                    } else if (validation.processing_request &&
                      role.name.toLowerCase() ===
                        validation.processing_request.toLowerCase()) {
                      allRoles[index].status = 'pending'
                      allRoles[index].message = `Selected role with user 
                        is processing in IDMS`
                    }
                  })
                }
              })
              allRoles.map(async (item, index) => {
                const result = await ApigeeService.getUsersForRole(this.state
                  .formData.org, item.name)
                const username1 = `${username.toLowerCase()}@its.jnj.com`
                if (result.data.length &&
                  result.data.toString().toLowerCase().includes(username1)) {
                  allRoles[index].status = 'access'
                  allRoles[index].message = `Selected user already 
                    granted this role`
                }
                this.setState({
                  allRoles
                })
              })
            }
          }
        })
    }
  }

  /* function to close the alert box  */
  alertClose = (event) => {
    this.setState({
      alert: false
    })
  }

  surveyJpmAlertClose = (event) => {
    this.setState({
      surveyJpmAlert: false,
      surveyJpmAlertMessage: ''
    })
  }

  errorAlertClose = () => {
    this.setState({
      errorAlert: false
    })
  }

  /* function to submit the request */
  submitRequest = (input) => {
    const {
      user, setUser
    } = this.context
    let url = ''
    if (this.state.buttontype === 'addtocart') {
      url = 'addTocart'
    } else if (this.state.buttontype === 'submitnow') {
      url = 'submitRequestNow'
    }
    this.setState({
      loadingbar: true
    })
    requestAccess[url](input).then((response) => {
      if (url === 'addTocart') {
        CartService.cartSize(user.name).then((cartdata) => {
          setUser({
            ...user,
            cartsize: cartdata.data.cartSize
          })
        })
      } else {
        LoginService.getAdminaccess().then((approvaldata) => {
          setUser({
            ...user,
            approvalcount: approvaldata.data.approvalcount
          })
        })
      }
      this.setState({
        loadingbar: false
      })
      this.resetForm()

      if (this.state.buttontype === 'addtocart') {
        this.setState({
          successalertMessage: 'Added to cart successfully'
        })
      } else {
        this.setState({
          successalertMessage: 'Request Submitted Successfully'
        })
      }
      if (response.data.type === 'GitHub Copilot') {
        this.setState({
          githubAlert: true
          // githubCoPilotInfo: `This request once approved will activate
          //   GitHub Copilot license.
          //   For further onboarding details please refer to
          //   <a href="https://confluence.jnj.com/display/ABES/XENA+AI+Assisted+Software+Development"
          //     target=_blank> XENA AI Assisted Software Development page
          //   </a>.`
        }, () => {
          setTimeout(() => this.setState({
            githubAlert: false
          }), 20000)
        })
      }
      this.setState({
        successAlert: true
      }, () => {
        setTimeout(() => this.setState({
          successAlert: false
        }), 20000)
      })
      if (this.state.buttontype === 'submitnow' &&
        user.email && response.data.data.length) {
        EmailServices.sendMailtoApprover(response.data.data)
          .then((respone2) => { })
      }
    },
    (error) => {
      if (error) {}
      this.setState({
        loadingbar: false,
        failalertMessage:
            'Something went wrong. Contact system administrator!',
        errorAlert: true
      })
    })
  }

  /* function to reset the form */
  resetForm = (e) => {
    const formData = {
      email: '',
      password: '',
      toolname: '',
      projectkey: '',
      project: null,
      comments: '',
      username: '',
      usernameDisplay: '',
      clustor: ''
    }
    this.setState({
      formData,
      selectedGroups: [],
      allRoles: [],
      allProjects: [],
      errorAlert: false,
      clustorArray: [],
      disableCheckBoxForQTest: false,
      surveyValueRadio: '',
      surveyData: [],
      surveyDialog: false,
      surveyJpmAlert: false,
      surveyJpmAlertMessage: ''
    })
  }

  /* function to call after the alert box closed by clicking "Yes" button */
  confirmbutton = () => {
    const {
      user
    } = this.context
    this.setState({
      alertbox: false
    })
    let username
    if (this.state.selectedvalue === 'Other') {
      username = this.state.formData.username
    } else {
      username = user.name
    }
    const input = {
      requestedBy: user.name,
      crowdUsers: username,
      requestType: this.state.formData.toolname.name,
      userRoles: this.state.selectedGroups,
      comments: this.state.formData.comments,
      action_type: 1,
      projects: this.state.formData.project.key
        ? this.state.formData.project.key
        : this.state.formData.project.name
    }
    this.submitRequest(input)
  }

  /* to be called when the action buttons clicked */
  handleSubmit = (e) => {
    const {
      user
    } = this.context

    if (this.state.selectedGroups.length) {
      let username
      if (this.state.selectedvalue === 'Other') {
        username = this.state.formData.username
      } else {
        username = user.name
      }
      const input = {
        requestedBy: user.name,
        crowdUsers: username,
        requestType: this.state.formData.toolname.name,
        userRoles: this.state.selectedGroups,
        comments: this.state.formData.comments,
        action_type: 1,
        projects: this.state.formData.project.key
          ? this.state.formData.project.key
          : this.state.formData.project.name ||
          this.state.formData.apigeeTeam
      }

      requestAccess.checkGroupHierarchy(input).then((response) => {
        if (response.data === true) {
          this.submitRequest(input)
        } else {
          this.setState({
            alertboxMessage: response.data,
            alertbox: true
          })
        }
      })
    } else {
      this.setState({
        alertboxMessage: 'Please select at least one role',
        alert: true
      })
    }
  }

  /* function to get the projects list based on the search key */
  getProjects = (toolname, value) => {
    if (toolname.is_key) {
      this.setState({
        loadingoptions: true
      })
      requestAccess.getprojects(toolname.name, value).then((response) => {
        this.setState({
          allProjects: response.data,
          loadingoptions: false
        })
      })
    }
  }

  /* function to get the users list based on the search key */
  getUsers = (value) => {
    this.setState({
      loadingoptions_user: true
    })
    requestAccess.getallusersforautocomplete(value).then((response) => {
      this.setState({
        allUsers: response.data,
        loadingoptions_user: false
      })
    })
  }

  /* function to set the clicked button event as addtocart or submitnow */
  setType = (type, e) => {
    this.setState({
      buttontype: type
    })
  }

  /* to close the alert box */
  handleClose = () => {
    this.setState({
      alertbox: false
    })
  }

  handleSurveyValueChange = (type, value) => {
    if (type === 'text') {
      this.setState({
        surveyValueText: value
      })
    } else if (type === 'radio' && value && value.toLowerCase() === 'no') {
      this.setState({
        surveyValueRadio: value
      })
      SurveyService.getJpmDetails().then((storedSurveyReponse) => {
        this.setState({
          surveyJpmAlertMessage: storedSurveyReponse.data.data
        })
      })
    } else if (type === 'radio') {
      this.setState({
        surveyValueRadio: value
      })
    }
  }

  captureSurveyDetails = (event) => {
    event.preventDefault()
    if (this.state.surveyValueRadio.toLowerCase() === 'no') {
      this.setState({
        surveyJpmAlert: true
      })
    }
    const dataToStore = {
      surveyResponse: [
        {
          surveyId: this.state.surveyData.data[0].survey,
          surveyQuestion: this.state.surveyData.data[0].id,
          surveyResponse: this.state.surveyValueRadio
        }
      ]
    }
    if (this.state.surveyValueRadio === 'Yes') {
      dataToStore.surveyResponse.push({
        surveyId: this.state.surveyData.data[1].survey,
        surveyQuestion: this.state.surveyData.data[1].id,
        surveyResponse: this.state.surveyValueText
      })
    }
    this.setState({
      loadingbar: true
    })
    SurveyService.storeSurveyResponse(dataToStore)
      .then((storedSurveyReponse) => {
        this.setState({
          surveyDialog: false,
          loadingbar: false,
          surveyValueRadio: '',
          surveyData: []
        })
        if (storedSurveyReponse.data.status === 'success') {
          this.setState({
            successalertMessage: 'Survey Response is saved, ' +
            'Please proceed with the request',
            successAlert: true
          })
        } else if (storedSurveyReponse.data.status === 'failed') {
          this.setState({
            failalertMessage: 'Survey Response is not saved. ' +
            'Please inform the request details to your adimistrator ' +
            'before proceed with this request',
            errorAlert: true
          })
        } else {
          this.setState({
            failalertMessage: 'Something went wrong. Please contact system ' +
            'administrator with the request details',
            errorAlert: true
          })
        }
      })
  }

  /* function will be called when the roles selected by user to check whether the user can have access to raise request for this role */
  selectGroups = (role) => {
    const {
      selectedGroups
    } = this.state
    const idx = selectedGroups.indexOf(role.name)
    if (selectedGroups.indexOf(role.name) > -1) {
      selectedGroups.splice(idx, 1)
      this.setState({
        selectedGroups
      })
    } else if (this.state.formData.toolname.is_key) {
      const {
        user
      } = this.context
      let username
      if (this.state.selectedvalue === 'Other') {
        username = this.state.formData.username
      } else {
        username = user.name
      }

      const allRolesTemp1 = this.state.allRoles
      allRolesTemp1.map((role) => {
        if (role.name) {
          role.disableForSurvey = true
        }
      })
      this.setState({
        loadingbar: true,
        allRoles: allRolesTemp1
      })
      requestAccess
        .canAccountRequestAccessToGroup(this.state.formData.toolname.name,
          this.state.formData.project
            ? this.state.formData.project.key_prefix
            : this.state.formData.org,
          role.name,
          username,
          this.state.formData.project
            ? this.state.formData.project.key
            : this.state.formData.apigeeTeam)
        .then((response) => {
          this.setState({
            loadingbar: false
          })
          if (response.data[0].check) {
            selectedGroups.push(role.name)
            this.setState({
              loadingbar: true
            })
            const getSurveyData = {
              toolid: this.state.formData.toolname.toolid,
              role_name: role.name,
              project: this.state.formData.project
            }
            SurveyService.getSurveyQuestion(getSurveyData)
              .then((surveyResponse) => {
                if (
                  surveyResponse.data.status === 200 &&
                  surveyResponse.data.data &&
                  typeof surveyResponse.data.data !== 'string'
                ) {
                  this.setState({
                    surveyData: surveyResponse.data,
                    surveyDialog: true,
                    surveyValueText:
                      'XENA Teams Channel, XENA confluence page ...'
                  })
                }
                const allRolesTemp1 = this.state.allRoles
                allRolesTemp1.map((role) => {
                  if (role.name) {
                    role.disableForSurvey = false
                  }
                })
                this.setState({
                  loadingbar: false,
                  allRoles: allRolesTemp1
                })
              })
          } else {
            this.setState({
              ou: response.data[0]
            })
            const message =
                this.state.ou !== null ? (
                  <div>
                    <Typography variant='subtitle2' gutterBottom>
                      Only users that belong to any one of the following
                      organization unit can request for this group
                    </Typography>
                    <ul>
                      {this.state.ou.roles.map((roleToShow) => (
                        <li>{roleToShow.ou}</li>
                      ))}
                    </ul>

                    <Typography variant='subtitle2' gutterBottom>
                      This account is a member of following Organization Unit:
                    </Typography>
                    <ul>
                      <li>{this.state.ou.user_roles}</li>
                    </ul>
                  </div>
                ) : null
            this.setState({
              alertboxMessage: message,
              alert: true
            })
          }
          this.setState({
            selectedGroups
          })
          if (this.state.formData.toolname.name.toLowerCase() === 'qtest' &&
            this.state.selectedGroups.length === 1) {
            this.setState({
              disableCheckBoxForQTest: true
            })
          }
        })
    } else {
      selectedGroups.push(role.name)
      this.setState({
        selectedGroups
      })
    }
  }

  /* to change the value of radio button for Self and Other */
  changeRadiotype = (value) => {
    this.resetForm()
    this.setState({
      selectedvalue: value
    })
  }

  selectQTestGroup = (value) => {
    this.setState({
      selectedGroups: [value.name]
    })
  }

  /* to get the available tools for tool selection dropdown when the page gets loaded */
  componentDidMount () {
    const values = queryString.parse(this.props.location.search)
    ToolsService.getAvailableTools('all').then((response) => {
      if (response.data) {
        const {
          formData
        } = this.state
        this.setState({
          alltools: response.data
        })
        if (values.tool) {
          const filtervalue = response.data
            .filter((item) => item.name.toLowerCase() ===
              values.tool.toLowerCase())
          if (filtervalue.length && filtervalue.length === 1) {
            /* eslint prefer-destructuring: ["error", {"array": false}] */
            formData.toolname = filtervalue[0]
            this.setState({
              formData
            })
            if (values.project) {
              requestAccess.getprojects(filtervalue[0].name, values.project)
                .then((projectResponse) => {
                //  this.setState({ allProjects: response.data, loadingoptions: false })
                  if (projectResponse.data.length &&
                    projectResponse.data.length === 1) {
                    this.changeKey(projectResponse.data[0])
                  }
                })
            }
          }
        }
      }
    })
  }

  render () {
    const {
      formData, loadingbar
    } = this.state
    const {
      classes
    } = this.props
    function getClassname (status) {
      switch (status) {
      case 'access':
        return classes.success
      case 'pending':
      case 'cart':
        return classes.pending
      default:
        return null
      }
    }
    return (
      <div>
        {this.state.loadingbar ? <LinearProgress /> : null}
        {' '}
        <div className={classes.alertroot}>
          {' '}
          {this.state.successAlert ? (
            <Alert
              severity='success'
              onClose={() => this.setState({
                successAlert: false
              })}
            >
              {this.state.successalertMessage}
            </Alert>
          ) : null}
          {this.state.errorAlert ? (
            <Alert
              severity='error'
              onClose={() => this.errorAlertClose()}
            >
              {this.state.failalertMessage}
            </Alert>
          ) : null}
          {this.state.githubAlert ? (
            <Alert severity='info'>
              This request once approved will activate GitHub Copilot license.
              For further onboarding details please refer to
              <a href='https://confluence.jnj.com/display/ABES/XENA+AI+Assisted+Software+Development'>
                 XENA AI Assisted Software Development page
              </a>
            </Alert>
          ) : null}
        </div>
        <Grid container spacing={1} className={classes.root}>
          <Grid item xs={12} md={7}>
            <Card variant='outlined'>
              <ValidatorForm
                ref='form'
                onSubmit={(event) => this.handleSubmit(event)}
              >
                <CardContent>
                  <Typography variant='subtitle1' gutterBottom>
                    User Access Request
                  </Typography>
                  <div style={{
                    paddingTop: 10
                  }}
                  >
                    <RadioButtons
                      radio={this.state.radio}
                      selectedvalue={this.state.selectedvalue}
                      onChange={(value) => this.changeRadiotype(value)}
                    />
                    {this.state.selectedvalue === 'Other' ? (
                      <FormControl className={classes.formControl}>
                        <Autocomplete
                          id='combo-box-demo'
                          options={this.state.allUsers}
                          value={formData.usernameDisplay}
                          getOptionLabel={(option) => (option.name
                            ? `${option.name} - ${option.displayname}`
                            : option)}
                          loading={
                            this.state.allUsers.length === 0 &&
                            this.state.loadingoptions_user
                          }
                          getOptionSelected={
                            (option, value) => option.name === value.name
                          }
                          filterOptions={(options, state) => options}
                          onChange={(event, value) => this.changeUser(value)}
                          renderInput={(params) => (
                            <TextValidator
                              {...params}
                              label='Choose a user *'
                              name='username'
                              onChange={
                                (event) => this.getUsers(event.target.value)
                              }
                              value={formData.usernameDisplay}
                              size='small'
                              validators={['required']}
                              errorMessages={['This field is required']}
                            />
                          )}
                        />
                      </FormControl>
                    ) : null}
                    <FormControl className={classes.formControl}>
                      <SelectValidator
                        label='Tool'
                        name='toolname'
                        fullWidth
                        required
                        value={formData.toolname}
                        onChange={this.handleChange}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      >
                        {this.state.alltools.map((value, index) => (
                          <MenuItem value={value} key={value.name}>
                            {value.name}
                          </MenuItem>
                        ))}
                      </SelectValidator>
                    </FormControl>
                    {formData.toolname.name === 'Apigee' ? (
                      <FormControl className={classes.formControl}>
                        <SelectValidator
                          label='Org'
                          name='org'
                          fullWidth
                          required
                          onChange={this.handleChange}
                          value={formData.org}
                          validators={['required']}
                          errorMessages={['This field is required']}
                        >
                          {this.state.apigeeOrgs.map((value, index) => (
                            <MenuItem value={value} key={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </SelectValidator>
                      </FormControl>
                    ) : null}
                    {formData.toolname.name === 'Apigee' ? (
                      <FormControl className={classes.formControl}>
                        <SelectValidator
                          label='Apigee Team'
                          name='apigeeTeam'
                          required
                          fullWidth
                          onChange={this.handleChange}
                          value={formData.apigeeTeam}
                          validators={['required']}
                          errorMessages={['This field is required']}
                        >
                          {this.state.apigeeOrgTeamsAndRoles.map((value,
                            index) => (
                            <MenuItem value={value.name} key={value.name}>
                              {value.name}
                            </MenuItem>
                          ))}
                        </SelectValidator>
                      </FormControl>
                    ) : null}
                    {formData.projectkey !== 'N/A' &&
                    formData.toolname.name !== 'Apigee' &&
                      formData.toolname !== 'Saucelabs' ? (
                        <FormControl className={classes.formControl}>
                          <Autocomplete
                            id='combo-box-demo'
                            options={this.state.allProjects}
                            value={formData.projectkey}
                            getOptionLabel={(option) => (option.key
                              ? `${option.key} - ${option.key_app_name}`
                              : option.name
                                ? option.name
                                : option)}
                            loading={
                              this.state.allProjects.length === 0 &&
                              this.state.loadingoptions
                            }
                            getOptionSelected={
                              (option, value) => option.name === value.name
                            }
                            onChange={
                              (event, value) => this.changeKey(value)
                            }
                            renderInput={(params) => (
                              <TextValidator
                                {...params}
                                label='Project or Team *'
                                name='projectkey'
                                onChange={
                                  (event) => this.getProjects(this.state
                                    .formData.toolname,
                                  event.target.value)
                                }
                                value={formData.projectkey}
                                validators={['required']}
                                errorMessages={['This field is required']}
                              />
                            )}
                          />
                        </FormControl>
                      ) : null}
                    {this.state.formData.toolname.name ===
                      'Cloudx Kubernetes' &&
                      this.state.clustorArray.length === 0 &&
                      this.state.formData.project.key
                      ? (
                        <span style={{
                          color: 'red'
                        }}
                        >
                          This project do not have any cluster
                        </span>
                      ) : null}
                    {this.state.formData.toolname.name ===
                      'Cloudx Kubernetes' &&
                      this.state.clustorArray.length > 0
                      ? (
                        <FormControl className={classes.formControl}>
                          <SelectValidator
                            label='Cluster'
                            name='clustor'
                            fullWidth
                            value={formData.clustor}
                            onChange={this.changeClustor}
                            validators={['required']}
                            errorMessages={['This field is required']}
                          >
                            {this.state.clustorArray.map((value, index) => (
                              <MenuItem value={value} key={value.clustor}>
                                {value.clustor}
                              </MenuItem>
                            ))}
                          </SelectValidator>
                        </FormControl>
                      )
                      : null}

                    <FormControl className={classes.formControl}>
                      <TextValidator
                        label='Reason for Requesting Access*'
                        name='comments'
                        fullWidth
                        onChange={this.handleChange}
                        value={formData.comments}
                        multiline
                        rows={1}
                        rowsMax={4}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      />
                    </FormControl>
                  </div>
                </CardContent>
                <CardActions className={classes.footer}>
                  <Button
                    variant='contained'
                    size='small'
                    onClick={this.resetForm.bind(this)}
                  >
                    Reset
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    size='small'
                    disabled={loadingbar || this.checkSwitchUser()}
                    onClick={this.setType.bind(this, 'addtocart')}
                  >
                    Add to Cart
                  </Button>
                  <Button
                    variant='contained'
                    color='secondary'
                    type='submit'
                    disabled={loadingbar || this.checkSwitchUser()}
                    size='small'
                    disableRipple
                    onClick={this.setType.bind(this, 'submitnow')}
                  >
                    Submit Now
                  </Button>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
          {this.state.allRoles.length ? (
            <Grid item xs={12} md={5}>
              <Card variant='outlined'>
                <CardContent>
                  {' '}
                  <Typography variant='subtitle1' gutterBottom>
                    Select Roles
                  </Typography>
                  <List component='nav' aria-label='main mailbox folders'>
                    {this.state.allRoles.map((role) => (
                      <ListItem
                        key={role.name ? role.name : role}
                        className={getClassname(role.status)}
                      >
                        <FormControlLabel
                          control={
                            this.state.formData
                              .toolname.name.toLowerCase() === 'qtest'
                              ? (
                                <Radio
                                  value={role}
                                  label={role}
                                  key={role}
                                  onChange={
                                    this.selectQTestGroup.bind(this, role)
                                  }
                                  disabled={
                                    (
                                      role.status === 'access' ||
                                  role.status === 'pending' ||
                                  role.status === 'cart' ||
                                  role.disableForSurvey
                                    ) || (
                                      this.state.disableCheckBoxForQTest
                                    )
                                  }
                                  checked={
                                    role.status
                                      ? true
                                      : this.state.selectedGroups
                                        .indexOf(role.name) > -1
                                  }
                                />
                              )
                              : (
                                <Checkbox
                                  name='checkedA'
                                  disabled={
                                    (
                                      role.status === 'access' ||
                                  role.status === 'pending' ||
                                  role.status === 'cart' ||
                                  role.disableForSurvey
                                    ) || (
                                      this.state.disableCheckBoxForQTest
                                    )
                                  }
                                  value={role}
                                  checked={
                                    role.status
                                      ? true
                                      : this.state.selectedGroups
                                        .indexOf(role.name) > -1
                                  }
                                  onChange={this.selectGroups.bind(this, role)}
                                />
                              )
                          }
                          label={role.name}
                        />
                        {role.status === 'access' ||
                          role.status === 'pending' ||
                          role.status === 'cart' ? (
                            <Tooltip
                              title={(
                                <span style={{
                                  fontSize: 12,
                                  fontFamily: 'Verdana'
                                }}
                                >
                                  {role.message}
                                </span>
                              )}
                            >
                              <InfoIcon color='action' fontSize='small' />
                            </Tooltip>
                          ) : null}
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          ) : null}
          <AlertDialog
            handleClose={this.handleClose}
            alertopen={this.state.alertbox}
            message={this.state.alertboxMessage}
            confirmbutton={this.confirmbutton}
            key='alert1'
            title='Alert'
            okbuttonalert={false}
          />
          <AlertDialog
            handleClose={this.alertClose}
            alertopen={this.state.alert}
            key='alert2'
            message={this.state.alertboxMessage}
            okbuttonalert
            title='Alert'
          />
          <AlertDialog
            handleClose={this.surveyJpmAlertClose}
            alertopen={this.state.surveyJpmAlert}
            key='alert3'
            message={parseHtml(this.state.surveyJpmAlertMessage)}
            okbuttonalert
            title='JPM Info'
          />
        </Grid>
        {/* Dialog Box for Change Approver */}
        <Dialog
          open={this.state.surveyDialog}
          maxWidth='xs'
          fullWidth='xs'
        >
          {this.state.alertLoading ? <LinearProgress /> : null}
          <ValidatorForm
            ref='form'
            onSubmit={(event) => this.captureSurveyDetails(event)}
          >
            <DialogTitle id='alert-dialog-title'>Survey</DialogTitle>
            <DialogContent>
              <div
                style={{
                  paddingTop: 10
                }}
              >
                {this.state.surveyData &&
                this.state.surveyData.data &&
                this.state.surveyData.data.length > 0 ? (
                    <FormControl
                      className={`${classes.formControl}
                          ${classes.columnDisplay}`}
                    >
                      <Typography
                        htmlFor='workflowTypeLabel'
                        className={classes.labelDisplay}
                      >
                        {this.state.surveyData &&
                          this.state.surveyData.data &&
                          this.state.surveyData.data.length > 0 &&
                          this.state.surveyData.data[0].survey_question}
                      </Typography>
                      <RadioButtons
                        className={classes.inputDisplay}
                        radio={['Yes', 'No']}
                        selectedvalue={this.state.surveyValueRadio}
                        onChange={(value) => this
                          .handleSurveyValueChange('radio', value)}
                      />
                    </FormControl>
                  ) : null}
                {this.state.surveyData &&
                this.state.surveyData.data &&
                this.state.surveyData.data.length > 1 &&
                this.state.surveyValueRadio === 'Yes' &&
                this.state.surveyValueRadio !== '' ? (
                    <FormControl
                      className={`${classes.formControl}
                      ${classes.columnDisplay}`}
                    >
                      <Typography
                        htmlFor='workflowTypeLabel'
                        className={classes.labelDisplay}
                      >
                        {this.state.surveyData &&
                          this.state.surveyData.data &&
                          this.state.surveyData.data.length &&
                          this.state.surveyData.data.length > 1 &&
                          this.state.surveyData.data[1].survey_question}
                      </Typography>
                      <FormControl className={classes.formControl}>
                        <TextValidator
                          label='Place your answers*'
                          name='survey1_answers'
                          onChange={(e) => this
                            .handleSurveyValueChange('text', e.target.value)}
                          value={this.state.surveyValueText}
                          multiline
                          rows={2}
                          fullWidth
                          rowsMax={10}
                          validators={['required']}
                          errorMessages={['This field is required']}
                        />
                      </FormControl>
                    </FormControl>
                  ) : null}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                disabled={
                  this.state.loadingbar ||
                  this.checkSwitchUser() ||
                  (this.state.surveyValueRadio === 'Yes' &&
                    this.state.surveyValueText === '') ||
                  this.state.surveyValueRadio === ''
                }
              >
                Submit
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      </div>
    )
  }
}
ByUserComponent.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ByUserComponent)
