import React, {
  useRef, useState, useEffect
} from 'react'
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  makeStyles,
  FormControl,
  LinearProgress,
  FormHelperText,
  Chip,
  List,
  ListItem,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Input,
  InputLabel,
  InputAdornment
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import SearchIcon from '@material-ui/icons/Search'
import RadioButtons from '../inputs/radio'
import ToolServices from '../../services/api/toolsService'
import BulkMailServices from '../../services/api/bulkMailService'
import RequestAccess from '../../services/api/requestaccessService'

const useStyles = makeStyles((theme) => ({
  inputDisplay: {
    width: '100%'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  }
}))

export default function BulkMailComponent () {
  const [loading, setLoading] = useState(false)
  const [loadingOptions, setLoadingOptions] = useState(false)
  const [successAlert, setsuccessAlert] = useState(false)
  const [errorAlert, seterrorAlert] = useState(false)
  const [failalertMessage, setFailalertMessage] = useState(null)
  const [successalertMessage, setSuccessalertMessage] = useState(null)
  const [refBulkMailOption, setRefBulkMailOption] = useState('Tool')
  const [allTools, setAllTools] = useState([])
  const [allUsersCount, setAllUsersCount] = useState(null)
  const [allMailUsers, setAllMailUsers] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [allProjects, setAllProjects] = useState([])
  const [projectRoles, setProjectRoles] = useState([])
  const classes = useStyles()
  const form = useRef(null)

  const [formData, setFormData] = useState({
    selectedProject: '',
    username: '',
    subject: '',
    emailContent: '',
    rolesToBeMailSentTo: [],
    wildCardSearchTerm: ''
  })

  useEffect(() => {
    setLoading(true)
    ToolServices.getAvailableTools('all').then((response) => {
      setLoading(false)
      setAllTools(response.data)
    })
  }, [])

  // Change Bulk Mail options
  const changeBulkMailOption = (value) => {
    setFormData({
      selectedTool: null,
      username: '',
      subject: '',
      emailContent: '',
      wildCardSearchTerm: '',
      rolesToBeMailSentTo: []
    })
    setRefBulkMailOption(value)
    setAllUsersCount(null)
    setAllMailUsers([])
    setAllProjects([])
  }

  // Changing Tool
  const changeTool = (event) => {
    setFormData({
      ...formData,
      selectedTool: event.target.value
    })
    setLoading(true)
    if (refBulkMailOption === 'Tool') {
      BulkMailServices.getallUsersByTool(event.target.value.name)
        .then((allUsersResponse) => {
          setLoading(false)
          if (allUsersResponse.data) {
            setAllUsersCount(allUsersResponse.data.length)
            setAllMailUsers(allUsersResponse.data)
          }
        })
    } else {
      setLoading(false)
    }
  }

  // Setting value null
  const setValuenull = (value) => {
    value.splice(0, 1)
    value = []
  }

  /* function to get the users list based on the search key */
  const getUsers = (value) => {
    setFormData({
      ...formData,
      searchkey: value
    })
    setLoadingOptions(true)
    RequestAccess.getallusersforautocomplete(value).then((response) => {
      setAllUsers(response.data)
      setLoadingOptions(false)
    })
  }

  // Changing user
  const changeUser = (value) => {
    if (value && value.length) {
      setFormData({
        ...formData,
        username: value
      })
    }
  }

  // Get all projects
  const getProjects = (value) => {
    setLoadingOptions(true)
    BulkMailServices.getAllProjects(value).then((response) => {
      setLoadingOptions(false)
      setAllProjects(response.data)
    })
  }

  // Changing project
  const changeProject = (value) => {
    if (value) {
      if (refBulkMailOption === 'Project Key') {
        setLoading(true)
        BulkMailServices.getUsersByKey(value.key)
          .then((allUsersByKeyResponse) => {
            setLoading(false)
            setAllUsersCount(allUsersByKeyResponse.data.length)
            setAllMailUsers(allUsersByKeyResponse.data)
            setFormData({
              ...formData,
              selectedProject: value
            })
          })
      } else if (refBulkMailOption === 'Groups') {
        setLoading(true)
        RequestAccess.getProjectRoles(formData.selectedTool.name, value.key,
          formData.selectedTool.is_key).then((response) => {
          setLoading(false)
          setProjectRoles(response.data)
          setFormData({
            ...formData,
            selectedProject: value
          })
        })
      }
    }
  }

  // Handling change for CC and email Body
  const handleChange = (e) => {
    if (e.target.checked === true) {
      formData.rolesToBeMailSentTo.push(e.target.value)
    } else if (e.target.checked === false) {
      const index = formData.rolesToBeMailSentTo.indexOf(e.target.value)
      if (index > -1) {
        formData.rolesToBeMailSentTo.splice(index, 1)
      }
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
    if (refBulkMailOption === 'Groups') {
      BulkMailServices.getUsersByGroup(formData.rolesToBeMailSentTo)
        .then((response) => {
          setAllUsersCount(response.data.length)
          setAllMailUsers(response.data)
        })
    }
  }

  // Handling serach function
  const handleSearch = () => {
    let input = {
    }
    input = {
      search_string: formData.wildCardSearchTerm
    }
    setLoading(true)
    BulkMailServices.getUsersBasedOnWildCardSearch(input).then((response) => {
      setLoading(false)
      setAllUsersCount(response.data.length)
      setAllMailUsers(response.data)
    })
  }

  // Handling reset
  const handleReset = () => {
    setFormData({
      selectedTool: null,
      username: '',
      subject: '',
      emailContent: '',
      wildCardSearchTerm: '',
      rolesToBeMailSentTo: [],
      selectedProject: ''
    })
    // setRefBulkMailOption('Tool')
    setAllUsersCount(null)
    setAllMailUsers([])
    setAllProjects([])
    setProjectRoles([])
  }

  // Handling submit
  const handleSubmit = () => {
    let input = {
    }
    const usersInTo = []
    const usersInCC = []

    for (const i in allMailUsers) {
      if (allMailUsers[i].username) {
        usersInTo.push(allMailUsers[i].username)
      }
    }

    for (const j in formData.username) {
      if (formData.username[j].name) {
        usersInCC.push(formData.username[j].name)
      }
    }

    input = {
      users_cc: usersInCC,
      users: usersInTo,
      message: formData.emailContent,
      subject: formData.subject
    }

    setLoading(true)
    BulkMailServices.sendMailToUsers(input).then((response) => {
      setLoading(false)
      handleReset()
      if (response.data === 'Message %s sent: %s') {
        setSuccessalertMessage('Mail Sent Successfully')
        setsuccessAlert(true)
        setTimeout(() => {
          setsuccessAlert(false)
        }, 20000)
      } else {
        setFailalertMessage(`
          Something went wrong. Contact system administrator!
        `)
        seterrorAlert(true)
      }
    }, (error) => {
      if (error) {
        setLoading(false)
        setFailalertMessage(`
          Something went wrong. Contact system administrator!
        `)
        seterrorAlert(true)
      }
    })
  }

  return (
    <div>
      {loading ? <LinearProgress /> : null}
      {' '}
      <div className={classes.alertroot}>
        {' '}
        {successAlert ? (
          <Alert severity='success'>{successalertMessage}</Alert>
        ) : null}
        {errorAlert ? (
          <Alert
            severity='error'
            onClose={() => seterrorAlert(false)}
          >
            {failalertMessage}
          </Alert>
        ) : null}
      </div>
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={12} md={8}>
          <Card variant='outlined'>
            <ValidatorForm ref={form} onSubmit={(event) => handleSubmit(event)}>
              <CardContent>
                <Typography variant='subtitle1' gutterBottom>
                  Bulk Mail
                </Typography>
                <div style={{
                  paddingTop: 10
                }}
                >
                  <FormControl
                    className={`
                      ${classes.formControl} ${classes.columnDisplay}
                    `}
                  >
                    <RadioButtons
                      className={classes.inputDisplay}
                      radio={['Tool', 'Project Key', 'Groups', 'Wild Card']}
                      selectedvalue={refBulkMailOption}
                      onChange={(value) => changeBulkMailOption(value)}
                    />
                  </FormControl>

                  {refBulkMailOption === 'Tool' ||
                    refBulkMailOption === 'Groups' ? (
                      <FormControl className={classes.formControl}>
                        <SelectValidator
                          label='Tool'
                          name='tools'
                          className={classes.inputDisplay}
                          value={formData.selectedTool
                            ? formData.selectedTool
                            : ''}
                          onChange={changeTool}
                        >
                          {allTools.map((value, index) => (
                            <MenuItem value={value} key={value.name}>
                              {value.name}
                            </MenuItem>
                          ))}
                        </SelectValidator>
                        {allUsersCount !== null &&
                          refBulkMailOption !== 'Groups' ? (
                            <FormHelperText>
                              {allUsersCount}
                              {' '}
                              users are present in this tool
                            </FormHelperText>
                          ) : null}
                      </FormControl>
                    ) : null}

                  {refBulkMailOption === 'Project Key' ||
                    refBulkMailOption === 'Groups' ? (
                      <FormControl className={classes.formControl}>
                        <Autocomplete
                          id='reference-project-auto-complete'
                          label='Project key'
                          className={classes.inputDisplay}
                          options={allProjects}
                          value={formData.selectedProject
                            ? formData.selectedProject
                            : ''}
                          getOptionLabel={(option) => (option.key
                            ? option.key
                            : option)}
                          loading={allProjects.length === 0 && loadingOptions}
                          getOptionSelected={
                            (option, userValue) => option.key === userValue.key
                          }
                          onChange={
                            (event, userValue) => changeProject(userValue)
                          }
                          renderInput={(params) => (
                            <TextValidator
                              {...params}
                              label='Project Key *'
                              name='selectedProject'
                              onChange={
                                (event) => getProjects(event.target.value)
                              }
                              value={formData.selectedProject}
                              validators={['required']}
                              errorMessages={['This field is required']}
                            />
                          )}
                        />
                        {allUsersCount !== null &&
                          refBulkMailOption !== 'Groups' ? (
                            <FormHelperText>
                              {allUsersCount}
                              {' '}
                              users are present in this project
                            </FormHelperText>
                          ) : null}
                        {allUsersCount !== null &&
                          refBulkMailOption === 'Groups' ? (
                            <FormHelperText>
                              {allUsersCount}
                              {' '}
                              users are present in selected roles
                            </FormHelperText>
                          ) : null}
                      </FormControl>
                    ) : null}

                  {refBulkMailOption === 'Wild Card' ? (
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        htmlFor='wildCardSearchTermLabel'
                      >
                        Search Tool/Key/Group and click search icon at the end *
                      </InputLabel>
                      <Input
                        id='standard-adornment-weight'
                        name='wildCardSearchTerm'
                        value={formData.wildCardSearchTerm}
                        onChange={handleChange}
                        endAdornment={(
                          <InputAdornment
                            position='end'
                            onClick={handleSearch}
                            style={{
                              cursor: 'pointer'
                            }}
                          >
                            <SearchIcon />
                          </InputAdornment>
                        )}
                        aria-describedby='wild-card-search-term'
                        inputProps={{
                          'aria-label': 'wild card search term'
                        }}
                      />
                      {allUsersCount !== null ? (
                        <FormHelperText>
                          {allUsersCount}
                          {' '}
                          users are present
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  ) : null}

                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id='combo-box-demo'
                      multiple
                      options={allUsers}
                      getOptionLabel={(option) => (option.name
                        ? `${option.name} - ${option.displayname}`
                        : option)}
                      loading={allUsers.length === 0 && loadingOptions}
                      filterOptions={(options, state) => options}
                      getOptionSelected={
                        (option, value) => option.name === value.name
                      }
                      renderTags={
                        (value, getTagProps) => value.map((option,
                          index) => (formData.username.length ? (
                          <Chip
                            label={
                              option.name
                                ? `${option.name} - ${option.displayname}`
                                : option
                            }
                            {...getTagProps({
                              index
                            })}
                          />
                        ) : (
                          setValuenull(value)
                        )))
                      }
                      onChange={(event, value) => changeUser(value)}
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          label='Add CC here'
                          name='username'
                          onChange={(event) => getUsers(event.target.value)}
                          value={formData.username}
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl
                    className={
                      `${classes.formControl} ${classes.columnDisplay}`
                    }
                  >
                    <TextValidator
                      label='Subject of the email *'
                      name='subject'
                      onChange={handleChange}
                      value={formData.subject}
                      multiline
                      rows={1}
                      rowsMax={4}
                      className={classes.inputDisplay}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />
                  </FormControl>

                  <FormControl
                    className={
                      `${classes.formControl} ${classes.columnDisplay}`
                    }
                  >
                    <TextValidator
                      label='Type the email content *'
                      name='emailContent'
                      onChange={handleChange}
                      value={formData.emailContent}
                      multiline
                      rows={1}
                      rowsMax={10}
                      className={classes.inputDisplay}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />
                  </FormControl>

                </div>
              </CardContent>
              <CardActions className={classes.footer}>
                <Button
                  variant='contained'
                  onClick={handleReset}
                >
                  Reset
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  disabled={loading}
                >
                  Send Mail
                </Button>
              </CardActions>
            </ValidatorForm>
          </Card>
        </Grid>
        {projectRoles.length ? (
          <Grid item xs={12} md={4}>
            <Card variant='outlined'>
              <CardContent>
                {' '}
                <Typography variant='subtitle1' gutterBottom>
                  Select Roles
                </Typography>
                <List component='nav' aria-label='main mailbox folders'>
                  {projectRoles.map((role) => (
                    <ListItem
                      key={role.name}
                    >
                      <FormControlLabel
                        control={(
                          <Checkbox
                            name='selectedRole'
                            value={role.name}
                            onChange={handleChange}
                            checked={
                              formData.rolesToBeMailSentTo
                                .indexOf(role.name) > -1
                            }
                          />
                        )}
                        label={role.name}
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        ) : null}
      </Grid>
    </div>
  )
}
