import axiosInstance from '../../middleware/Interceptor'

// Service method to Get table data for Access history tab
function getExportData (projectKey, typeOfReport, groupName, username,
  timeZone, startDate, endDate, key, toolname) {
  return axiosInstance.get(`/api/v1/reports/getExportData?project_key=
${projectKey}&typeOfReport=${typeOfReport}&group_name=${groupName}&username=
${username}&timezone=${timeZone}&start_date=${startDate}&end_date=
${endDate}&key=${key}&toolname=${toolname}`)
}

// Export a pdf.
function exportReport (projectKey, typeOfReport, groupName, username,
  timeZone, startDate, endDate, key, toolname, searchkey) {
  axiosInstance.defaults.headers.get.Accept = 'application/pdf'
  return axiosInstance.post(`/api/v1/reports/export?project_key=
${projectKey}&typeOfReport=${typeOfReport}&group_name=${groupName}&username=
${username}&timezone=${timeZone}&start_date=${startDate}&end_date=
${endDate}&key=${key}&toolname=${toolname}&searchkey=${searchkey}`, {
  },
  {
    responseType: 'arraybuffer'
  })
}

// Get Groups for Project Access with owners and approvers
function getGroupsWithOwnerAndApproverGroup (key, toolname) {
  return axiosInstance.get(`/api/v1/reports/getGroupsWithOwnerAndApproverGroup
?key=${key}&toolname=${toolname}`)
}

// Get project for all tools
function getAllToolsAndKeysWithName (key) {
  return axiosInstance.get(`/api/v1/reports/getAllToolsAndKeysWithName?key=
${key}`)
}

// Get Test execution
function getTestExecution (key, version, id) {
  return axiosInstance.get(`/api/v1/reports/getTestExecution?key=
${key}&version=${version}&id=${id}`)
}

// Get Test  for selected test execution
function getTestsForExecution (testexecutionid) {
  return axiosInstance.get(`/api/v1/reports/getTestsForExecution
?testexecutionid=${testexecutionid}`)
}

// Check the type of test
function checkTestCaseType (tcid) {
  return axiosInstance.get(`/api/v1/reports/checkTestCaseType?tcid=${tcid}`)
}

function exportTestExecutionReport (input, timezone) {
  axiosInstance.defaults.headers.get.Accept = 'application/pdf'
  return axiosInstance.post(`/api/v1/reports/exportTestExecutionReport
?timezone=${timezone}`, input, {
    responseType: 'arraybuffer'
  })
}
// Api for bannned words
function bannedWords (timeZone, switchUser, switchedUser) {
  axiosInstance.defaults.headers.get.timezone = timeZone
  return axiosInstance.get(`/api/v1/confluence/getBannedWords?switch_user=
${switchUser}&switched_user=${switchedUser}`)
}

function exceptionWords (timeZone, switchUser, switchedUser) {
  axiosInstance.defaults.headers.get.timezone = timeZone
  return axiosInstance.get(`/api/v1/confluence/getExceptions?switch_user=
${switchUser}&switched_user=${switchedUser}`)
}

function addErrorWords (input) {
  return axiosInstance.post('/api/v1/confluence/putExceptions', input)
}

function deleteException (id) {
  return axiosInstance.delete(`/api/v1/confluence/deleteException?id=${id}`)
}

const reportServices = {
  getExportData,
  exportReport,
  getGroupsWithOwnerAndApproverGroup,
  getAllToolsAndKeysWithName,
  getTestExecution,
  getTestsForExecution,
  checkTestCaseType,
  exportTestExecutionReport,
  bannedWords,
  exceptionWords,
  addErrorWords,
  deleteException
}

export default reportServices
