import React, {
  useContext
} from 'react'
import {
  Paper,
  Typography,
  makeStyles,
  Tabs,
  Tab,
  Box,
  LinearProgress,
  IconButton
} from '@material-ui/core'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import {
  Link
} from 'react-router-dom'
import UserContext from '../contexts/UserContext'
import EnhancedTable from '../sections/enhancedTable'
import MyApprovalsService from '../../services/api/myApprovalsService'
import DelegateComponent from '../delegate/delegateComponent'
import PathContext from '../contexts/PathContext'
import globalData from '../../data/globalData.json'

const timeZone = moment.tz.guess()
// table header for pending approvals table
const headCells1 = [

  {
    id: 'id',
    numeric: true,
    label: 'ID',
    width: 5
  },
  {
    id: 'requested_by',
    numeric: false,
    label: 'Requested By',
    icon: 'organization',
    width: 10
  },
  {
    id: 'requested_for',
    numeric: false,
    label: 'Requested For',
    icon: 'organization',
    width: 10
  },
  {
    id: 'created_sort',
    type: 'date',
    numeric: false,
    label: 'Requested Date',
    width: 15
  },
  {
    id: 'ad_group',
    numeric: false,
    label: 'Role',
    icon: 'approvers',
    width: 20
  },
  {
    id: 'projectname',
    numeric: false,
    label: 'Project Name',
    width: 10
  },
  {
    id: 'action_type',
    numeric: false,
    label: 'Action Type',
    width: 10
  },
  {
    id: 'comment',
    numeric: false,
    label: 'Comments',
    width: 20
  }
]
// table header for completed request table
const headCells2 = [
  {
    id: 'requested_by',
    numeric: false,
    label: 'Requested By',
    icon: 'organization'
  },
  {
    id: 'requested_for',
    numeric: false,
    label: 'Requested For',
    icon: 'organization'
  },
  {
    id: 'ad_group',
    numeric: false,
    label: 'Role'
  },
  {
    id: 'projectname',
    numeric: false,
    label: 'Project Name'
  },
  {
    id: 'action_owner',
    numeric: false,
    label: 'Action Owner',
    icon: 'organization'
  },
  {
    id: 'action_date_sort',
    type: 'date',
    numeric: false,
    label: 'Action Date'
  },
  {
    id: 'created_sort',
    type: 'date',
    numeric: false,
    label: 'Requested Date'
  },
  {
    id: 'action_type',
    numeric: false,
    type: 'global',
    label: 'Action Type'
  },
  {
    id: 'comment',
    numeric: false,
    label: 'Comments'
  },
  {
    id: 'action_status',
    numeric: false,
    type: 'global',
    label: 'Status'
  },
  {
    id: 'agent_status',
    numeric: false,
    type: 'global',
    label: 'Provisioning Status'
  }
]

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  autocomplete: {
    width: '100%'
  },
  nodatatext: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(8),
    textAlign: 'center'
  },
  delegatebtn: {
    paddingTop: 10,
    fontSize: '1rem'
  }
}))

function TabPanel (props) {
  const {
    children, value, index, ...other
  } = props

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}
export default function MyApprovalsComponent () {
  const {
    user, setUser
  } = useContext(UserContext)
  const {
    setPath
  } = useContext(PathContext)
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const [value, setValue] = React.useState(0)
  const [pendingApprovals, setPendingApprovals] = React.useState([])
  const [completedRequests, setCompletedRequests] = React.useState([])
  const [delegates, setDelegates] = React.useState([])

  /* initial method will be called when the page gets loaded */
  React.useEffect(() => {
    initMethod()
  }, [])
  /* change function for tab change */
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  /* function to get the all request details to serve for pendinf request and history tables */
  function initMethod () {
    setPendingApprovals([])
    setLoading(true)
    MyApprovalsService.myPendingRequest(user.name, timeZone)
      .then((response) => {
        setLoading(false)
        const {
          pendingData, actionData, delegated
        } = response.data
        pendingData.forEach((obj) => {
          obj.action_type = globalData.ACTION_NAME[obj.action_type] || 'NA'
        })
        setPendingApprovals(pendingData)
        setUser({
          ...user,
          approvalcount: pendingData.length
        })
        setCompletedRequests(actionData)
        if (delegated.length) {
        /* To display only 5 delegated users in UI and will add "More" button at the end to redirect
        the user into delegate page to see all the delegated users.
        Filtering first 5 users in below filter method. */
          const filteredusers = delegated.filter((data, i) => i < 5)
          const delegateElement = (
            <div style={{
              marginLeft: 20,
              color: '#f50057',
              textAlign: 'center'
            }}
            >
              You are the active delegate for
              {' '}
              {/* The below map method is used to append a comma for each
                user except the 0th position user */}
              {filteredusers.map((data, i) => (
                i !== 0 ? (`, ${data.username}`) : data.username
              ))}
              {delegated.length > 4 ? (
                <span>
                  {' '}
                  and
                  <IconButton
                    aria-label='cart size'
                    color='primary'
                    className={classes.delegatebtn}
                    component={Link}
                    to='/delegate'
                    onClick={() => setPath({
                      pathname: '/delegate',
                      component: DelegateComponent
                    })}
                  >
                    more
                  </IconButton>
                </span>
              ) : null}
            </div>
          )
          setDelegates(delegateElement)
        }
      })
  }
  const handleComplete = () => {
    initMethod()
  }

  return (
    <div className={classes.root}>
      {loading ? <LinearProgress /> : null}
      <Paper square>
        <Tabs
          value={value}
          onChange={handleChange}
          variant='scrollable'
          scrollButtons='on'
          indicatorColor='primary'
          textColor='primary'
          aria-label='scrollable force tabs example'
        >
          <Tab
            key='pendingrequests'
            label='Pending Requests'
            title='Pending Requests'
          />
          <Tab
            key='completedrequest'
            label='Completed Request'
            title='Completed Request'
          />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0} key='tab-myrequest'>
        {delegates}
        {pendingApprovals.length ? (
          <EnhancedTable
            key='request-table'
            orderby='created_sort'
            headCells={headCells1}
            rowsData={pendingApprovals}
            sortorder='desc'
            checkbox
            handleCompleteRequest={handleComplete}
            isApproval
          />
        ) : null}
        {!loading && !pendingApprovals.length ? (
          <div className={classes.nodatatext}>No data to display.</div>
        ) : null}
      </TabPanel>

      <TabPanel value={value} index={1} key='tab-history'>
        {completedRequests.length ? (
          <EnhancedTable
            key='history-table'
            orderby='action_date_sort'
            sortorder='desc'
            headCells={headCells2}
            rowsData={completedRequests}
            isApproval
          />
        ) : null}
        {!loading && !completedRequests.length ? (
          <div className={classes.nodatatext}>No data to display.</div>
        ) : null}
      </TabPanel>

    </div>
  )
}
