import React, {
  useContext
} from 'react'
import {
  Typography,
  makeStyles,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Button,
  Grid
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  Alert
} from '@material-ui/lab'
import UserContext from '../contexts/UserContext'
import AlertDialog from '../alertbox/alertboxComponent'
import SshAndGpgKeyService from '../../services/api/sshAndGpgKeyService'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  nodatatext: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(12),
    textAlign: 'center'
  },
  keyCell: {
    whiteSpace: 'nowrap',
    maxWidth: '500px',
    width: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  table: {
    width: '70%'
  },
  btnmargin: {
    marginTop: 20
  },
  padding20: {
    padding: 20
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  padding0: {
    padding: 0
  }
}))

export default function SSHKeysComponent () {
  const [loading, setLoading] = React.useState(false)
  const [loadingbar, setLoadingBar] = React.useState(false)
  const {
    user
  } = useContext(UserContext)
  const [bitbucketuser, setbitbucketUser] = React.useState(null)
  const [sshKeys, setSshKeys] = React.useState([])
  const [deleteid, setDeleteId] = React.useState(null)
  const [deletealert, setDeleteAlert] = React.useState(null)
  const [alertopen, setAlertopen] = React.useState(false)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [alertBoxMessage, setAlertBoxMessage] = React.useState(null)
  const classes = useStyles()

  function getSSHKey () {
    setLoading(true)
    SshAndGpgKeyService.sshGetUserKey().then((keyresponse) => {
      setSshKeys(keyresponse.data.values)
      setLoading(false)
    },
    (error) => {
      if (error) {
        setLoading(false)
        seterrorAlert(true)
      }
    })
  }
  /* initial call when page gets loaded */
  React.useEffect(() => {
    setLoading(true)
    SshAndGpgKeyService.checkBitbucketUser(user.name).then((response) => {
      setbitbucketUser(response.data[0].bitbucket_count)
      setLoading(false)
      if (response.data[0].bitbucket_count !== '0') {
        getSSHKey()
      }
    })
  }, [])

  // function to close the alert modal box
  const alertClose = (event) => {
    setAlertopen(false)
    setDeleteAlert(false)
  }
  // add ssh key
  const addKey = () => {
    setLoadingBar(true)
    SshAndGpgKeyService.sshAddUserKey().then((response) => {
      setLoadingBar(false)
      setAlertBoxMessage(response.data)
      setAlertopen(true)
      getSSHKey()
    },
    (error) => {
      if (error) {
        setLoadingBar(false)
        seterrorAlert(true)
      }
    })
  }
  // delete ssh key
  const deleteKey = (id) => {
    setDeleteId(id)
    setDeleteAlert(true)
  }
  const confirmDelete = () => {
    setDeleteAlert(false)
    setLoadingBar(true)
    SshAndGpgKeyService.deletePublicSSHKey(deleteid).then((response) => {
      setLoadingBar(false)
      getSSHKey()
    },
    (error) => {
      if (error) {
        setLoadingBar(false)
        seterrorAlert(true)
      }
    })
  }

  return (
    <div className={classes.root}>
      {loading || loadingbar ? <LinearProgress /> : null}
      <div className={classes.alertroot}>
        {errorAlert
          ? (
            <Alert severity='error' onClose={() => seterrorAlert(false)}>
              Something went wrong. Contact system
              administrator!
            </Alert>
          ) : null}
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={9}>
          <Paper className={classes.padding20}>
            <Typography variant='subtitle2' gutterBottom>
              SSH Keys
              {!loading && bitbucketuser !== '0' && sshKeys.length < 2 ? (
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  className='pull-right'
                  disabled={loadingbar || user.switch_user}
                  onClick={() => addKey()}
                >
                  Add Key
                </Button>
              ) : null}
            </Typography>
            <Typography variant='caption' color='textSecondary' gutterBottom>
              Use SSH keys to connect simply and safely to repositories
            </Typography>

            {!loading && bitbucketuser === '0' ? (
              <div className={classes.nodatatext}>
                You cannot add keys since you dont have access
                 to any bitbucket project.
              </div>
            ) : null}
            {!loading && bitbucketuser !== '0' && !sshKeys.length ? (
              <div className={classes.nodatatext}>
                No SSH keys have been added
              </div>
            ) : null}
            {sshKeys.length && !loading ? (
              <TableContainer className={classes.btnmargin}>
                <Table aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Label</TableCell>
                      <TableCell>Key</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sshKeys.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell>
                          {row.label}
                        </TableCell>
                        <Tooltip title={(
                          <span style={{
                            fontSize: 12,
                            fontFamily: 'Verdana'
                          }}
                          >
                            {row.text}
                          </span>
                        )}
                        >
                          <TableCell className={classes.keyCell}>
                            {row.text}
                          </TableCell>
                        </Tooltip>

                        <TableCell className={classes.padding0}>
                          <IconButton
                            aria-label='delete'
                            disabled={user.switch_user}
                          >
                            <DeleteIcon
                              color='secondary'
                              onClick={() => deleteKey(row.id)}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
            <AlertDialog
              handleClose={alertClose}
              alertopen={alertopen}
              key='alert-teams'
              message={alertBoxMessage}
              okbuttonalert
              title='SSH Key'
              downloadIcon
            />
            <AlertDialog
              handleClose={alertClose}
              alertopen={deletealert}
              message='Do you want to delete the ssh key ?'
              confirmbutton={confirmDelete}
              key='alert-delete'
              okbuttonalert={false}
              title='Alert'
            />
          </Paper>
        </Grid>
      </Grid>

    </div>
  )
}
