import axiosInstance from '../../middleware/Interceptor'

// Service method to Get table data for Delegations tab
function getDelegates (username, timeZone) {
  axiosInstance.defaults.headers.get.timezone = timeZone
  return axiosInstance.get(`/api/v1/delegate/getdelegates/${username}`)
}

// API call to save my delegates
function updateDelegate (requestbody) {
  return axiosInstance.post('/api/v1/delegate/updatedelegation', requestbody)
}

// API to get direct reports for the logged in users
function getUserDirects (username) {
  return axiosInstance.get(`/api/v1/user/getUserDirects/${username}`)
}

const DelegateServices = {
  getDelegates,
  updateDelegate,
  getUserDirects
}

export default DelegateServices
