import React, {
  useContext, useRef
} from 'react'
import {
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  makeStyles,
  FormControl,
  LinearProgress,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import UserContext from '../contexts/UserContext'
import EnhancedTable from '../sections/enhancedTable'
import groupOwnershipService from '../../services/api/groupOwnershipService'
import SupportService from '../../services/api/supportService'
import requestAccess from '../../services/api/requestaccessService'

const useStyles = makeStyles((theme) => ({
  inputDisplay: {
    width: '100%'
  },
  nodatatext: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(12),
    textAlign: 'center'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  }
}))

// Header for the table
const headCells = [
  {
    id: 'group_name',
    numeric: false,
    label: 'Group',
    width: '40%'
  }
]

export default function GroupOwnershipComponent () {
  const {
    user
  } = useContext(UserContext)
  const [loading, setLoading] = React.useState(false)
  const [ownerAlert, setOwnerAlert] = React.useState(false)
  const [successAlert, setsuccessAlert] = React.useState(false)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [failalertMessage, setFailalertMessage] = React.useState(null)
  const [successalertMessage, setSuccessalertMessage] = React.useState(null)
  const [ownerGroups, setOwnerGroups] = React.useState([])
  const [owner, setOwner] = React.useState('')
  const [loadingbar, setLoadingbar] = React.useState(false)
  const [allUsers, setAllUsers] = React.useState([])
  const classes = useStyles()
  const form = useRef(null)
  const [selectedgroups, setSelectedGroups] = React.useState([])

  const [formData, setFormData] = React.useState({
    username: ''
  })

  /* Get the email HTML content when email page loads */
  React.useEffect(() => {
    const getDataPromise = (value) => new Promise((resolve, reject) => {
      SupportService.checkJnJEmployee(value.name ? value.name : value)
        .then((response) => {
          resolve(response.data)
        })
    })
    ValidatorForm.addValidationRule('checkJnJEmployee', async (value) => {
      if (value) {
        const result = await getDataPromise(value)
        return result
      }
      return true
    })
    setLoading(true)
    groupOwnershipService.getOwnerGroups(user.name).then((response) => {
      setOwnerGroups(response.data)
      setLoading(false)
    })
  }, [])
  const handleChangeOwner = (selected) => {
    setSelectedGroups(selected)
    setOwnerAlert(true)
  }
  const changeOwner = () => {
    const input = {
      newowner: owner.name,
      groups: selectedgroups
    }
    setOwnerAlert(false)
    setLoading(true)
    groupOwnershipService.transferOwnership(input).then((response) => {
      setLoading(false)
      setsuccessAlert(true)
      const successMsg = response.data ===
        'success'
        ? 'Owner changed successfully!'
        : response.data
      setSuccessalertMessage(successMsg)
      setOwnerGroups([])
      groupOwnershipService.getOwnerGroups(user.name).then((response1) => {
        setOwnerGroups(response1.data)
      })
      setTimeout(() => {
        setsuccessAlert(false)
      }, 20000)
    },
    (error) => {
      setLoading(false)
      if (error) {
        setFailalertMessage(`
          Something went wrong. Contact system administrator!
        `)
        seterrorAlert(true)
      }
    })
  }
  const getUsers = (username) => {
    setLoadingbar(true)
    requestAccess.getallusersforautocomplete(username).then((response) => {
      setLoadingbar(false)
      setAllUsers(response.data)
    })
  }
  return (
    <div>
      {loading ? <LinearProgress /> : null}
      {' '}
      <div className={classes.alertroot}>
        {' '}
        {successAlert ? (
          <Alert severity='success'>{successalertMessage}</Alert>
        ) : null}
        {errorAlert ? (
          <Alert
            severity='error'
            onClose={() => seterrorAlert(false)}
          >
            {failalertMessage}
          </Alert>
        ) : null}
      </div>
      {!loading && !ownerGroups.length ? (
        <div className={classes.nodatatext}>
          You are not a owner for any of the group
        </div>
      ) : null}
      <Grid container spacing={1} className={classes.root}>

        {ownerGroups.length ? (
          <Grid item xs={12} md={7}>
            <Card variant='outlined'>
              {/* <ValidatorForm ref={form} onSubmit={(event) => handleSubmit(event)}> */}
              <CardContent>
                <Typography variant='subtitle1' gutterBottom>
                  Group Ownership
                </Typography>
                <Typography
                  variant='caption'
                  color='textSecondary'
                  gutterBottom
                >
                  Groups that you are listed as owner
                </Typography>
                <div style={{
                  paddingTop: 10
                }}
                >

                  <EnhancedTable
                    rowsData={ownerGroups}
                    headCells={headCells}
                    checkbox
                    showButton='Change Owner'
                    rowsperpage={25}
                    handleChangeOwner={handleChangeOwner}
                  />

                </div>
              </CardContent>

            </Card>
          </Grid>
        ) : null}
      </Grid>
      {/* Dialog Box for Change Approver */}
      <Dialog
        open={ownerAlert}
        maxWidth='xs'
        fullWidth='xs'
        onClose={() => setOwnerAlert(false)}
      >
        <ValidatorForm ref={form} onSubmit={(event) => changeOwner()}>
          <DialogTitle id='alert-dialog-title'>Select New Owner</DialogTitle>
          <DialogContent>
            <div style={{
              paddingTop: 10
            }}
            >
              <FormControl className={classes.formControl}>
                <Autocomplete
                  id='switch-user-auto-complete'
                  className={classes.inputDisplay}
                  options={allUsers}
                  getOptionLabel={
                    (option) => (option.name
                      ? `${option.name} - ${option.displayname}`
                      : option)
                  }
                  loading={allUsers.length === 0 && loadingbar}
                  filterOptions={(options, state) => options}
                  getOptionSelected={
                    (option, userValue) => option.name === userValue.name
                  }
                  onChange={(event, userValue) => setOwner(userValue)}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name='switchuser'
                      onChange={(event) => getUsers(event.target.value)}
                      value={owner}
                      validators={['required', 'checkJnJEmployee']}
                      errorMessages={[
                        'This field is required',
                        'User needs to be a JNJ employee'
                      ]}
                      label='Owner name*'
                    />
                  )}
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOwnerAlert(false)}>
              Cancel
            </Button>
            <Button color='primary' type='submit'>
              Change
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </div>
  )
}
