
/* It contains all the API functions which is releated to admin config section */

import axiosInstance from '../../middleware/Interceptor'

function getApigeeOrgs () {
  return axiosInstance.get('/api/v1/apigee/getOrgs')
}

function getTeamsForOrgs (org) {
  return axiosInstance.get(`/api/v1/apigee/orgTeams/${org}`)
}

function getAdminTeamsForOrgs (org) {
  return axiosInstance.get(`/api/v1/apigee/orgAdminTeams/${org}`)
}

function getTargetServers (org, team, env) {
  // eslint-disable-next-line max-len
  return axiosInstance.get(`/api/v1/apigee/orgs/${org}/teams/${team}/target-servers?env=${env}`)
}

function getEnvs (org) {
  return axiosInstance.get(`/api/v1/apigee/envs/${org}`)
}

function getCompanies (org) {
  return axiosInstance.get(`/api/v1/apigee/companies/${org}`)
}

function getProducts (org, team) {
  return axiosInstance.get(`/api/v1/apigee/products/${org}/${team}`)
}

function getTeamApprovers (org, team) {
  // eslint-disable-next-line max-len
  return axiosInstance.get(`/api/v1/apigee/team/approvers?org=${org}&team=${team}`)
}

function getUsersForRole (org, role) {
  // eslint-disable-next-line max-len
  return axiosInstance.get(`/api/v1/apigee/team/getusersforrole?org=${org}&role=${role}`)
}

function createKVM (input) {
  return axiosInstance.post('/api/v1/apigee/kvm', input)
}

function createProxy (input) {
  return axiosInstance.post('/api/v1/apigee/proxy', input)
}

function createTargetServer (input) {
  return axiosInstance.post('/api/v1/apigee/target-server', input)
}

function createApiProduct (input) {
  return axiosInstance.post('/api/v1/apigee/api-product', input)
}

function createSharedFlow (input) {
  return axiosInstance.post('/api/v1/apigee/shared-flow', input)
}

function createCompanyApp (input) {
  return axiosInstance.post('/api/v1/apigee/app', input)
}

function createDNSMapping (input) {
  return axiosInstance.post('/api/v1/apigee/dns-mapping', input)
}

function createCache (input) {
  return axiosInstance.post('/api/v1/apigee/cache', input)
}

const apigeeService = {
  getApigeeOrgs,
  getTeamsForOrgs,
  getAdminTeamsForOrgs,
  getTeamApprovers,
  getUsersForRole,
  getEnvs,
  getTargetServers,
  getCompanies,
  getProducts,
  createKVM,
  createProxy,
  createTargetServer,
  createApiProduct,
  createSharedFlow,
  createCompanyApp,
  createDNSMapping,
  createCache
}

export default apigeeService
