import React, {
  Component
} from 'react'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import {
  withStyles
} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import {
  Dialog,
  Tooltip,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from '@material-ui/core'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import InfoIcon from '@material-ui/icons/Info'
import InsertChartIcon from '@material-ui/icons/InsertChart'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import LinearProgress from '@material-ui/core/LinearProgress'
import jiraImg from '../../assets/images/jira.png'
import confImg from '../../assets/images/confluence.png'
import bitbucketImg from '../../assets/images/bitbucket.png'
import jenkinsImg from '../../assets/images/jenkins.png'
import artifactImg from '../../assets/images/artifactory.png'
import kanbanImg from '../../assets/images/kanban.png'
import qTestImg from '../../assets/images/qTest-Application-Link.png'
import sonarImg from '../../assets/images/sonarqube.png'
import apigeeImg from '../../assets/images/apigee.png'
import mulesoftImg from '../../assets/images/mulesoft.png'
import ToolsService from '../../services/api/toolsService'
import UserContext from '../contexts/UserContext'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex'
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  margintop: {
    marginTop: theme.spacing(4)
  },
  cardroot: {
    minHeight: 150,
    cursor: 'pointer',
    minWidth: 120
  }
})
const applinks = [
  {
    src: jiraImg,
    url: 'https://jira.jnj.com',
    title: 'jira'
  },
  {
    src: confImg,
    url: 'https://confluence.jnj.com',
    title: 'confluence'
  },
  {
    src: bitbucketImg,
    url: 'https://sourcecode.jnj.com',
    title: 'bitbucket'
  },
  {
    src: jenkinsImg,
    title: 'jenkins'
  },
  {
    src: artifactImg,
    title: 'artifactory'
  },
  {
    src: kanbanImg,
    url: 'https://kanban.jnj.com',
    title: 'jjkanban'
  },
  {
    src: qTestImg,
    url: 'https://manager.qtest.jnj.com',
    title: 'qtest'
  },
  {
    src: sonarImg,
    title: 'sonarqube'
  },
  {
    src: mulesoftImg,
    url: 'https://anypoint.mulesoft.com/login/domain/jnj1',
    title: 'mulesoft'
  },
  {
    src: apigeeImg,
    url: 'https://johnson-johnson.apigee.com/',
    title: 'apigee'
  }
]

class AppLinksComponent extends Component {
  static contextType = UserContext
  constructor (props) {
    super(props)
    this.state = {
      modal: false,
      jenkinsApps: [],
      // jenkinsurl: '',
      eksJenkinsUrl: '',
      toolname: '',
      isLoader: false,
      applinkdata: []
    }
  }

  componentDidMount () {
    ToolsService.getEnabledAppLinks().then((response) => {
      const linkdata = response.data
      const applinksdata = []
      applinks.map((app) => {
        const data = linkdata.find((item) => item.name
          .toLowerCase().includes(app.title))
        if (data) {
          app.url = app.title.toLowerCase().includes('jenkins') ||
          app.title.toLowerCase().includes('artifactory') ? null
            : data.url
          applinksdata.push(app)
        }
      })
      this.setState({
        applinkdata: applinksdata
      })
    })
  }

  handleClose = () => {
    this.setState({
      modal: false
    })
  }

  handleOpen = (title) => {
    const {
      user
    } = this.context
    this.setState({
      isLoader: true
    })
    ToolsService.mytools(user.name).then((response) => {
      if (response.data) {
        let jenkinsApps = []
        let jenkinsurl = ''
        let eksJenkinsUrl = ''
        for (const i in response.data) {
          if (response.data[i].key === 'Jenkins - Artifactory') {
            jenkinsApps = response.data[i].values
            jenkinsurl = response.data[i].url
          } else if (response.data[i].key === 'EAT Jenkins and Artifactory') {
            eksJenkinsUrl = response.data[i].url
            response.data[i].values.map((item) => (
              jenkinsApps.push(item)
            ))
          }
        }
        this.setState({
          jenkinsApps,
          // jenkinsurl,
          eksJenkinsUrl,
          toolname: title,
          isLoader: false,
          modal: true
        })
      }
    })
  }

  render () {
    const {
      classes
    } = this.props
    /* const FormRow1 = () => (
      <>
        {this.state.row1.map((value) => (
          <Grid item xs={12} md={4} key={value.title}>
            <Card
              className={classes.cardroot}
              value={value}
              onClick={value.title.toLowerCase().includes('jenkins') ||
                value.title.toLowerCase().includes('artifactory')
                ? () => this.handleOpen(value.title) : null}
            >
              {' '}
              <a href={value.url} target='_blank' rel='noreferrer'>
                <img
                  src={value.src}
                  height='100em'
                  style={{
                    padding: '10px 0 0 10px',
                    marginLeft: 50,
                    marginTop: 10,
                    width: '70%'
                  }}
                  alt=''
                />
              </a>
            </Card>
          </Grid>
        ))}
      </>
    )
    const FormRow2 = () => (
      <>
        {this.state.row2.map((value) => (
          <Grid item xs={12} md={4} key={value.title}>
            <Card
              className={classes.cardroot}
              value={value}
              onClick={value.url ? null : () => this.handleOpen(value.title)}
            >
              {' '}
              <a href={value.url} target='_blank' rel='noreferrer'>
                <img
                  src={value.src}
                  height={value.title === 'kanban' ? '80em' : '100em'}
                  style={value.title === 'kanban' ? {
                    padding: '10px 0 0 10px',
                    marginLeft: 60,
                    marginTop: 20,
                    width: '70%'
                  } : {
                    padding: '10px 0 0 10px',
                    marginLeft: 50,
                    marginTop: 10,
                    width: '70%'
                  }}
                  alt=''
                />
              </a>
            </Card>
          </Grid>
        ))}
      </>
    )

    const FormRow3 = () => (
      <>
        {this.state.row3.map((value) => (
          <Grid item xs={12} md={4} key={value.title}>
            <Card
              className={classes.cardroot}
              value={value}
              onClick={value.url ? null : () => this.handleOpen(value.title)}
            >
              {' '}
              <a href={value.url} target='_blank' rel='noreferrer'>
                <img
                  src={value.src}
                  height='100em'
                  style={{
                    padding: '10px 0 0 10px',
                    marginLeft: 50,
                    marginTop: 10,
                    width: '70%'
                  }}
                  alt=''
                />
              </a>
            </Card>
          </Grid>
        ))}
      </>
    ) */

    return (
      <div className={classes.root}>
        {this.state.isLoader ? <LinearProgress /> : null}
        {' '}
        <Grid container spacing={1} className={classes.margintop}>
          {/* <Grid container item xs={12} spacing={3}>
            <FormRow1 />
          </Grid> */}
          <Grid
            container
            item
            xs={12}
            spacing={3}
          >
            {this.state.applinkdata.map((value) => (
              <Grid
                item
                xs={12}
                md={4}
                key={value.title}
                style={
                  {
                    marginBottom: 30
                  }
                }
              >
                <Card
                  className={classes.cardroot}
                  value={value}
                  onClick={value.url ? null
                    : () => this.handleOpen(value.title)}
                >
                  {' '}
                  <a href={value.url} target='_blank' rel='noreferrer'>
                    <img
                      src={value.src}
                      height={value.title === 'jjkanban' ? '80em' : '100em'}
                      style={{
                        padding: '10px 0 0 10px',
                        marginLeft: value.title === 'jjkanban' ? 60 : 50,
                        marginTop: value.title === 'jjkanban' ? 20 : 10,
                        width: '70%'
                      }}
                      alt=''
                    />
                  </a>
                </Card>
              </Grid>
            ))}
          </Grid>
          {/* <Grid
            container
            item
            xs={12}
            spacing={3}
            className={classes.margintop}
          >
            <FormRow3 />
          </Grid> */}
        </Grid>
        <Dialog
          open={this.state.modal}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
          fullWidth
          maxWidth='sm'
        >
          <DialogTitle id='form-dialog-title'>Select Project</DialogTitle>
          <DialogContent style={{
            paddingTop: 0
          }}
          >
            {this.state.toolname === 'jenkins' && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Project
                    </TableCell>
                    <TableCell>
                      Open
                    </TableCell>
                    <TableCell>
                      Status Page
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.jenkinsApps.sort((a, b) => a.key_app_name
                    .localeCompare(b.key_app_name))
                    .map((data, index) => (
                      data.toolname === 'EAT Jenkins and Artifactory' && (
                        <>
                          <TableRow
                            key={data.key_app_name}
                          >
                            <TableCell
                              component='th'
                            >
                              {data.key_app_name}
                              (
                              {data.key}
                              ) - dev
                            </TableCell>
                            <TableCell>
                              <a
                                href={
                                  // eslint-disable-next-line max-len
                                  `${this.state.eksJenkinsUrl}${data.key.toLowerCase()}-dev`
                                }
                                target='_blank'
                                rel='noreferrer'
                              >
                                <OpenInNewIcon
                                  fontSize='small'
                                  color='primary'
                                />
                              </a>
                            </TableCell>
                            <TableCell align='center'>
                              <Tooltip
                                title={(
                                  <span style={{
                                    fontSize: 12,
                                    fontFamily: 'Verdana'
                                  }}
                                  >
                                    {`Click "Sign in with Azure AD"
                                    if you see login page`}
                                  </span>
                                )}
                              >
                                <a
                                  href={
                                    // eslint-disable-next-line max-len
                                    `https://status.jenkins.eat.jnj.com/${data.key.toLowerCase()}-dev/grafana`
                                  }
                                  target='_blank'
                                  rel='noreferrer'
                                >
                                  <InsertChartIcon
                                    fontSize='default'
                                    style={{
                                      color: '#eea508'
                                    }}
                                  />
                                </a>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            key={data.key_app_name}
                          >
                            <TableCell
                              component='th'
                            >
                              {data.key_app_name}
                              (
                              {data.key}
                              ) - release
                            </TableCell>
                            <TableCell
                              style={{
                                width: 1
                              }}
                            >
                              <a
                                href={
                                  // eslint-disable-next-line max-len
                                  `${this.state.eksJenkinsUrl}${data.key.toLowerCase()}-release`
                                }
                                target='_blank'
                                rel='noreferrer'
                              >
                                <OpenInNewIcon
                                  fontSize='small'
                                  color='primary'
                                />
                              </a>
                            </TableCell>
                            <TableCell align='center'>
                              <Tooltip
                                title={(
                                  <span style={{
                                    fontSize: 12,
                                    fontFamily: 'Verdana'
                                  }}
                                  >
                                    {`Click "Sign in with Azure AD"
                                    if you see login page`}
                                  </span>
                                )}
                              >
                                <a
                                  href={
                                    // eslint-disable-next-line max-len
                                    `https://status.jenkins.eat.jnj.com/${data.key.toLowerCase()}-release/grafana`
                                  }
                                  target='_blank'
                                  rel='noreferrer'
                                >
                                  <InsertChartIcon
                                    fontSize='default'
                                    style={{
                                      color: '#eea508'
                                    }}
                                  />
                                </a>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        </>
                      )
                    ))}
                </TableBody>
              </Table>
            )}
            {this.state.jenkinsApps.sort((a, b) => a.key_app_name
              .localeCompare(b.key_app_name))
              .map((data, index) => (
                this.state.toolname === 'artifactory' && (
                  <div key={data.key_app_name}>
                    <ListItem key={data.key}>
                      <a
                        href={
                          `https://artifactrepo.jnj.com/artifactory/webapp/#/artifacts/browse/tree/General/${
                            data.key.toLowerCase()}`
                        }
                        target='_blank'
                        rel='noreferrer'
                      >
                        <ListItemText
                          primary={
                            data.teamname
                              ? data.teamname
                              : `(${data.key}) -Artifactory`
                          }
                        />
                      </a>
                    </ListItem>
                  </div>
                )))}
            {/* {this.state.jenkinsApps.sort((a, b) => a.key_app_name
              .localeCompare(b.key_app_name))
              .map((data, index) => (this.state.toolname === 'jenkins' ? (
                <div key={data.key_app_name}>
                  <ListItem key={data.key}>
                    {data.toolname === 'Jenkins - Artifactory' && (
                      <a
                        href={
                          // eslint-disable-next-line max-len
                          `${this.state.jenkinsurl}${data.key.toLowerCase()}-ci-build`
                        }
                        target='_blank'
                        rel='noreferrer'
                      >
                        <ListItemText
                          primary={`${data.key_app_name}
                          ( ${data.key} ) -ci-build`}
                        />
                      </a>
                    )}
                    {data.toolname === 'EAT Jenkins and Artifactory' && (
                      <div>
                        <table>
                          <tr>
                            <th>
                            Project
                            </th>
                            <th>
                            Open
                            </th>
                            <th>
                            Status Page
                            </th>
                          </tr>
                          <tr>
                            <td>
                              {this.state.eksJenkinsUrl}
                              {data.key.toLowerCase()}
                              -dev
                            </td>
                            <td>
                              <OpenInNewIcon
                                fontSize='small'
                              />
                            </td>
                            <td>
                              <InsertChartIcon
                                fontSize='default'
                                className='pull-right'
                                style={{
                                  color: '#eea508'
                                }}
                              />
                            </td>
                          </tr>
                        </table>
                        <a
                          href={
                            // eslint-disable-next-line max-len
                            `${this.state.eksJenkinsUrl}${data.key.toLowerCase()}-dev`
                          }
                          target='_blank'
                          rel='noreferrer'
                          style={{
                            marginRight: 10
                          }}
                        >
                          <ListItemText
                            primary={`${data.key_app_name}( ${data.key} ) -dev`}
                          />
                        </a>
                        <Tooltip
                          title={(
                            <span style={{
                              fontSize: 12,
                              fontFamily: 'Verdana'
                            }}
                            >
                              Status Page
                            </span>
                          )}
                          style={{
                            float: 'right'
                          }}
                        >
                          <a
                            href={
                              // eslint-disable-next-line max-len
                              `https://status.jenkins.eat.jnj.com/${data.key.toLowerCase()}-dev/grafana`
                            }
                            target='_blank'
                            rel='noreferrer'
                            style={{
                              marginTop: 5
                            }}
                          >
                            <InsertChartIcon
                              fontSize='default'
                              className='pull-right'
                              style={{
                                color: '#eea508'
                              }}
                            />
                          </a>
                        </Tooltip>
                      </div>
                    )}
                  </ListItem>
                  <ListItem key={data.key}>
                    {data.toolname === 'Jenkins - Artifactory' && (
                      <a
                        // eslint-disable-next-line max-len
                        href={`${this.state.jenkinsurl}${data.key.toLowerCase()}-cd-deploy`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <ListItemText
                          primary={
                            `${data.key_app_name} (${data.key}) -cd-deploy`
                          }
                        />
                      </a>
                    )}
                    {data.toolname === 'EAT Jenkins and Artifactory' && (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex'
                        }}
                      >
                        <a
                          // eslint-disable-next-line max-len
                          href={`${this.state.eksJenkinsUrl}${data.key.toLowerCase()}-release`}
                          target='_blank'
                          rel='noreferrer'
                          style={{
                            marginRight: 10
                          }}
                        >
                          <ListItemText
                            primary={
                              `${data.key_app_name} (${data.key}) -release`
                            }
                          />
                        </a>
                        <Tooltip
                          title={(
                            <span style={{
                              fontSize: 12,
                              fontFamily: 'Verdana'
                            }}
                            >
                              Status Page
                            </span>
                          )}
                          style={{
                            float: 'right'
                          }}
                        >
                          <a
                            href={
                              // eslint-disable-next-line max-len
                              `https://status.jenkins.eat.jnj.com/${data.key.toLowerCase()}-release/grafana`
                            }
                            target='_blank'
                            rel='noreferrer'
                            style={{
                              marginTop: 5
                            }}
                          >
                            <InsertChartIcon
                              fontSize='default'
                              className='pull-right'
                              style={{
                                color: '#eea508'
                              }}
                            />
                          </a>
                        </Tooltip>
                      </div>
                    )}
                  </ListItem>
                </div>
              ) : this.state.toolname === 'artifactory' ? (
                <div key={data.key_app_name}>
                  <ListItem key={data.key}>
                    <a
                      href={
                        `https://artifactrepo.jnj.com/artifactory/webapp/#/artifacts/browse/tree/General/${
                          data.key.toLowerCase()}`
                      }
                      target='_blank'
                      rel='noreferrer'
                    >
                      <ListItemText
                        primary={
                          data.teamname
                            ? data.teamname
                            : `(${data.key}) -Artifactory`
                        }
                      />
                    </a>
                  </ListItem>
                </div>
              ) : null))} */}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
AppLinksComponent.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AppLinksComponent)
