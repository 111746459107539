import axiosInstance from '../../middleware/Interceptor'

// Service method to Get IDMS groups
function getOwnerGroups (username) {
  return axiosInstance.get(`/api/v1/user/getOwnerGroups?username=${username}`)
}
function transferOwnership (input) {
  return axiosInstance.post('/api/v1/user/transferOwnership', input)
}
const groupOwnershipService = {
  getOwnerGroups,
  transferOwnership
}

export default groupOwnershipService
