import React, {
  Component
} from 'react'

const SupportContext = React.createContext()

class SupportProvider extends Component {
  constructor (props) {
    super(props)
    this.state = {
      support: {
        success: false,
        message: ''
      }
    }
  }

    setSupport = (support) => {
      this.setState((prevState) => ({
        support
      }))
    }

    render () {
      const {
        children
      } = this.props
      const {
        support
      } = this.state
      const {
        setSupport
      } = this

      return (
        <SupportContext.Provider
          value={{
            support,
            setSupport
          }}
        >
          {children}
        </SupportContext.Provider>
      )
    }
}

export default SupportContext

export {
  SupportProvider
}