import React, {
  useContext, useRef, useEffect
} from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  MenuItem,
  makeStyles,
  FormControl,
  LinearProgress,
  Input,
  Checkbox,
  ListItemText,
  FormControlLabel,
  FormHelperText,
  Select,
  InputLabel,
  TextField,
  IconButton,
  Chip,
  Tooltip,
  FormLabel,
  FormGroup,
  Tabs,
  Tab,
  Box
} from '@material-ui/core'
import PropTypes from 'prop-types'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import InfoIcon from '@material-ui/icons/Info'
import UserContext from '../contexts/UserContext'
import requestAccess from '../../services/api/requestaccessService'
import CloneRepoService from '../../services/api/cloneRepoService'
import helpicon from '../../assets/images/knowledgebase.jpg'
import AlertDialog from '../alertbox/alertboxComponent'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  paramfield: {
    marginLeft: 10,
    width: '80%'
  },
  repoText: {
    fontWeight: 700,
    color: '#00A36C'
  }
}))

function TabPanel (props) {
  const {
    children, value, index, ...other
  } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{
          p: 3
        }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

function CloneRepoComponent () {
  const {
    user
  } = useContext(UserContext)
  const [loading, setLoading] = React.useState(false)
  const [successAlert, setsuccessAlert] = React.useState(false)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [failmessage, setFailMessage] = React.useState(null)
  const [sourceRepos, setSourceRepos] = React.useState([])
  const [allProjects, setAllprojects] = React.useState([])
  const [repoerror, setRepoError] = React.useState(false)
  const [loadingbar, setLoadingbar] = React.useState(false)
  const [users, setUsers] = React.useState([])
  const [params, setParams] = React.useState([{
    from: '',
    to: ''
  }])
  const [paramsError, setParamsError] = React.useState(false)
  const classes = useStyles()
  const form = useRef(null)
  const [repoBranches, setBranches] = React.useState([])
  const [branchModels, setBranchModels] = React.useState(null)
  const [branchNames, setBranchNames] = React.useState([])
  const [branchAlert, setBranchAlert] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState('')
  const [copySettings, setCopySettings] = React.useState(false)
  const [
    branchScaffoldingSettings, setBranchScaffoldingSettings
  ] = React.useState(false)
  const [
    scaffoldingBranches, setScaffoldingBranches
  ] = React.useState([])
  const [value, setValue] = React.useState(0)
  const [branchNamePermission, setBranchNamePermission] = React.useState([
    {
      'matcher': {
        'id': '',
        'displayId': '',
        'type': {
          id: 'BRANCH',
          name: 'Branch'
        }
      },

      'type': 'read-only',
      'users': [],
      'groups': []
    },
    {
      'matcher': {
        'id': '',
        'displayId': '',
        'type': {
          id: 'BRANCH',
          name: 'Branch'
        }
      },

      'type': 'no-deletes',
      'users': [],
      'groups': []
    },
    {
      'matcher': {
        'id': '',
        'displayId': '',
        'type': {
          id: 'BRANCH',
          name: 'Branch'
        }
      },

      'type': 'fast-forward-only',
      'users': [],
      'groups': []
    },
    {
      'matcher': {
        'id': '',
        'displayId': '',
        'type': {
          id: 'BRANCH',
          name: 'Branch'
        }
      },

      'type': 'pull-request-only',
      'users': [],
      'groups': []
    }
  ])
  const [branchModelPermission, setBranchModelPermission] = React.useState([
    {
      'matcher': {
        'id': '',
        'displayId': '',
        'type': {
          id: 'MODEL_CATEGORY'
        }
      },

      'type': 'read-only',
      'users': [],
      'groups': []
    },
    {
      'matcher': {
        'id': '',
        'displayId': '',
        'type': {
          id: 'MODEL_CATEGORY'
        }
      },

      'type': 'no-deletes',
      'users': [],
      'groups': []
    },
    {
      'matcher': {
        'id': '',
        'displayId': '',
        'type': {
          id: 'MODEL_CATEGORY'
        }
      },

      'type': 'fast-forward-only',
      'users': [],
      'groups': []
    },
    {
      'matcher': {
        'id': '',
        'displayId': '',
        'type': {
          id: 'MODEL_CATEGORY'
        }
      },

      'type': 'pull-request-only',
      'users': [],
      'groups': []
    }
  ])
  const [branchPatternPermission, setBranchPatternPermission] = React.useState([
    {
      'matcher': {
        'id': '',
        'displayId': '',
        'type': {
          'id': 'PATTERN'
        }
      },
      'type': 'read-only',
      'users': [],
      'groups': []
    },
    {
      'matcher': {
        'id': '',
        'displayId': '',
        'type': {
          'id': 'PATTERN'
        }
      },

      'type': 'no-deletes',
      'users': [],
      'groups': []
    },
    {
      'matcher': {
        'id': '',
        'displayId': '',
        'type': {
          'id': 'PATTERN'
        }
      },

      'type': 'fast-forward-only',
      'users': [],
      'groups': []
    },
    {
      'matcher': {
        'id': '',
        'displayId': '',
        'type': {
          'id': 'PATTERN'
        }
      },

      'type': 'pull-request-only',
      'users': [],
      'groups': []
    }
  ])

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  }
  // form data declaration
  const [formData, setFormData] = React.useState({
    toolname: '',
    sourceProjectKey: '',
    sourceRepoSlugs: [],
    branches: '',
    scaffoldingBranches: [],
    targetProjectKey: '',
    targetRepoSlug: '',
    sourceRepoSettings: '',
    replaceParam: false,
    filepath: '',
    branchPattern: '',
    branchPermission: [],
    enableBranchPermission: false,
    needBranchScaffolding: false,
    branchOption: ['Branch Name']
  })
  const resetBranchPermission = () => {
    setBranchNamePermission([
      {
        'matcher': {
          'id': '',
          'displayId': '',
          'type': {
            id: 'BRANCH',
            name: 'Branch'
          }
        },

        'type': 'read-only',
        'users': [],
        'groups': []
      },
      {
        'matcher': {
          'id': '',
          'displayId': '',
          'type': {
            id: 'BRANCH',
            name: 'Branch'
          }
        },

        'type': 'no-deletes',
        'users': [],
        'groups': []
      },
      {
        'matcher': {
          'id': '',
          'displayId': '',
          'type': {
            id: 'BRANCH',
            name: 'Branch'
          }
        },

        'type': 'fast-forward-only',
        'users': [],
        'groups': []
      },
      {
        'matcher': {
          'id': '',
          'displayId': '',
          'type': {
            id: 'BRANCH',
            name: 'Branch'
          }
        },

        'type': 'pull-request-only',
        'users': [],
        'groups': []
      }
    ])
    setBranchModelPermission([
      {
        'matcher': {
          'id': '',
          'displayId': '',
          'type': {
            id: 'MODEL_CATEGORY'
          }
        },

        'type': 'read-only',
        'users': [],
        'groups': []
      },
      {
        'matcher': {
          'id': '',
          'displayId': '',
          'type': {
            id: 'MODEL_CATEGORY'
          }
        },

        'type': 'no-deletes',
        'users': [],
        'groups': []
      },
      {
        'matcher': {
          'id': '',
          'displayId': '',
          'type': {
            id: 'MODEL_CATEGORY'
          }
        },

        'type': 'fast-forward-only',
        'users': [],
        'groups': []
      },
      {
        'matcher': {
          'id': '',
          'displayId': '',
          'type': {
            id: 'MODEL_CATEGORY'
          }
        },

        'type': 'pull-request-only',
        'users': [],
        'groups': []
      }
    ])
    setBranchPatternPermission([
      {
        'matcher': {
          'id': '',
          'displayId': '',
          'type': {
            'id': 'PATTERN'
          }
        },
        'type': 'read-only',
        'users': [],
        'groups': []
      },
      {
        'matcher': {
          'id': '',
          'displayId': '',
          'type': {
            'id': 'PATTERN'
          }
        },

        'type': 'no-deletes',
        'users': [],
        'groups': []
      },
      {
        'matcher': {
          'id': '',
          'displayId': '',
          'type': {
            'id': 'PATTERN'
          }
        },

        'type': 'fast-forward-only',
        'users': [],
        'groups': []
      },
      {
        'matcher': {
          'id': '',
          'displayId': '',
          'type': {
            'id': 'PATTERN'
          }
        },

        'type': 'pull-request-only',
        'users': [],
        'groups': []
      }
    ])
  }
  // reset form
  const resetForm = () => {
    setFormData({
      toolname: '',
      sourceProjectKey: '',
      sourceProjectKeyName: '',
      sourceRepoSlugs: [],
      branches: '',
      scaffoldingBranches: [],
      targetProjectKey: '',
      targetProjectKeyName: '',
      targetRepoSlug: '',
      sourceRepoSettings: '',
      replaceParam: false,
      filepath: '',
      branchPattern: '',
      enableBranchPermission: false
    })
    setAllprojects([])
    setBranches([])
    setScaffoldingBranches([])
    setBranchScaffoldingSettings(false)
    setCopySettings(false)
    setParams([{
      from: '',
      to: ''
    }])
    seterrorAlert(false)
    resetBranchPermission()
  }

  useEffect(() => {
    ValidatorForm.addValidationRule('repoNameCharacterCount', (value) => {
      if (value.length > 22) {
        if (value.includes('-') || value.includes('_')) {
          return true
        }
        return false
      }
      return true
    })
  })
  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }
  /* get Repos based on project key selection */
  const changeKey = (value, type) => {
    if (value) {
      if (type === 'sourceProjectKey') {
        setFormData({
          ...formData,
          sourceProjectKeyName: value.key
            ? `${value.key} - ${value.key_app_name}`
            : value.name,
          sourceProjectKey: value.key
        })
        setLoading(true)
        CloneRepoService
          .getRepos4Project(value.key)
          .then((response) => {
            setLoading(false)
            setSourceRepos(Array.isArray(response.data) ? response.data : [])
          })
      } else {
        getBBUsersAndGroups(value)

        setFormData({
          ...formData,
          targetProjectKeyName: value.key
            ? `${value.key} - ${value.key_app_name}`
            : value.name,
          targetProjectKey: value.key
        })
        setAllprojects([])
      }
    }
  }

  const checkBBranchValidation = () => {
    let result = true
    const filteredArray1 = branchNamePermission
      .filter((item) => item.selected && item.matcher.id)
    if (filteredArray1.length) {
      filteredArray1.map((item) => {
        if (!item.users.length && !item.groups.length) {
          setFailMessage(`
            Please select alteast one group/user for Branch Name tab`)
          seterrorAlert(true)
          result = false
        }
      })
    }
    const filteredArray2 = branchPatternPermission
      .filter((item) => item.selected && item.matcher.id)
    if (filteredArray2.length) {
      filteredArray2.map((item) => {
        if (!item.users.length && !item.groups.length) {
          setFailMessage(`
            Please select alteast one group/user for Branch Pattern tab`)
          seterrorAlert(true)
          result = false
        }
      })
    }
    const filteredArray3 = branchModelPermission
      .filter((item) => item.selected && item.matcher.id)
    if (filteredArray3.length) {
      filteredArray3.map((item) => {
        if (!item.users.length && !item.groups.length) {
          setFailMessage(`
            Please select alteast one group/user for Branching Model tab`)
          seterrorAlert(true)
          result = false
        }
      })
    }
    return result
  }
  /* function to create the repo */
  const handleSubmit = (input) => {
    if (!repoerror) {
      if (checkBBranchValidation() === true) {
        const finalBranhPermissions = []
        for (const singleBranchNamePermission of branchNamePermission) {
          if (singleBranchNamePermission.selected &&
            singleBranchNamePermission.matcher.id) {
            finalBranhPermissions.push(singleBranchNamePermission)
          }
        }
        for (const singleBranchPatternPermission of branchPatternPermission) {
          if (singleBranchPatternPermission.selected &&
            singleBranchPatternPermission.matcher.id) {
            finalBranhPermissions.push(singleBranchPatternPermission)
          }
        }
        for (const singelBranchModelPermission of branchModelPermission) {
          if (singelBranchModelPermission.selected &&
            singelBranchModelPermission.matcher.id) {
            finalBranhPermissions.push(singelBranchModelPermission)
          }
        }


        if (formData.replaceParam && (
          params[params.length - 1].from === '' ||
        params[params.length - 1].to === '')) {
          setParamsError(true)
        } else {
          setParamsError(false)
          const inputData = {
            sourceProjectKey: formData.sourceProjectKey,
            sourceRepoSlugs: formData.sourceRepoSlugs,
            targetProjectKey: formData.targetProjectKey,
            targetRepoSlug: formData.targetRepoSlug,
            branches: formData.branches,
            scaffoldingBranches: formData.scaffoldingBranches,
            options: {
            },
            branchPermission: finalBranhPermissions
          }
          if (copySettings) {
            inputData.sourceRepoSettings = formData.sourceRepoSettings
          }
          if (formData.replaceParam) {
            inputData.options = {
              replace: {
                files: formData.filepath.split(','),
                params
              }
            }
          }
          setLoading(true)
          CloneRepoService.createRepo(inputData).then((response) => {
            setLoading(false)
            resetForm()
            setsuccessAlert(true)
            setTimeout(() => {
              setsuccessAlert(false)
            }, 20000)
          },
          (error) => {
            setFailMessage(error.response.data)
            setLoading(false)
            seterrorAlert(true)
          })
        }
      }
    }
  }
  /* function to get the projects list based on the search key */
  const getProjects = (toolname, value) => {
    setLoadingbar(true)
    requestAccess.getprojects(toolname, value).then((response) => {
      setAllprojects(response.data)
      setLoadingbar(false)
    })
  }

  const getBBUsersAndGroups = (value) => {
    const data = {
      projectKey: value
    }
    CloneRepoService.getBBUsersAndGroups(data).then((response) => {
      setUsers(response.data)
      setLoadingbar(false)
    })
  }
  const handleSettingsChange = (e) => {
    if (e.target.name === 'reposettings') {
      setCopySettings(e.target.checked)
      if (!e.target.checked) {
        setFormData({
          ...formData,
          enableBranchPermission: false
        })
        resetBranchPermission()
      }
    } else {
      setBranchScaffoldingSettings(e.target.checked)
      if (!e.target.checked) {
        setFormData({
          ...formData,
          needBranchScaffolding: false
        })
      }
      const tempBranchArray = []
      tempBranchArray.push(scaffoldingBranches
        .filter((item) => item.displayId !== formData.branches))
      setScaffoldingBranches(tempBranchArray[0])
    }
  }

  const handleBranchChange = (value, name) => {
    if (name === 'branchName') {
      branchNamePermission.map((item) => {
        item.matcher.id = value.id
        item.matcher.displayId = value.displayId
      })
      setBranchNamePermission([...branchNamePermission])
    } else if (name === 'Branch Pattern') {
      branchPatternPermission.map((item) => {
        item.matcher.id = value
        item.matcher.displayId = value
      })
      setBranchPatternPermission([...branchPatternPermission])
    } else if (name === 'Branching Model') {
      branchModelPermission.map((item) => {
        item.matcher.id = value
        item.matcher.displayId = value
      })
      setBranchModelPermission([...branchModelPermission])
    }
  }
  // set form values function
  const handleChange = (e) => {
    setAllprojects([])

    if (e.target.name === 'sourceRepoSlugs' &&
      e.target.value.length && e.target.value.length === 1) {
      CloneRepoService.getRepoBranches(formData.sourceProjectKey,
        e.target.value[0]).then((response) => {
        const branches1 = response.data

        let defaultBranch = ''
        defaultBranch = branches1
          .filter((item) => item.isDefault)[0].displayId
        setBranches(branches1)
        setScaffoldingBranches(branches1)
        setFormData({
          ...formData,
          [e.target.name]: e.target.checked
            ? e.target.checked
            : e.target.value,
          branches: defaultBranch
        })
      })
    } else {
      if (e.target.name === 'enableBranchPermission' &&
        e.target.checked === true) {
        CloneRepoService.getBranchModels(formData.sourceProjectKey,
          formData.sourceRepoSettings).then((response) => {
          const data = {
            'development': {
              'id': 'refs/heads/master',
              'displayId': 'master',
              'type': 'BRANCH',
              'latestCommit': '4992364cca36e55e27c839a0a24871cef34086d0',
              'latestChangeset': '4992364cca36e55e27c839a0a24871cef34086d0',
              'isDefault': true
            },
            'types': [
              {
                'id': 'BUGFIX',
                'displayName': 'Bugfix',
                'prefix': 'bugfix/'
              },
              {
                'id': 'FEATURE',
                'displayName': 'Feature',
                'prefix': 'feature/'
              },
              {
                'id': 'HOTFIX',
                'displayName': 'Hotfix',
                'prefix': 'hotfix/'
              },
              {
                'id': 'RELEASE',
                'displayName': 'Release',
                'prefix': 'release/'
              }
            ]
          }
          setBranchModels(data)
        })
        CloneRepoService.getRepoBranches(formData.sourceProjectKey,
          formData.sourceRepoSettings).then((response) => {
          setBranchNames(response.data)
        })
      }
      if (e.target.name === 'sourceRepoSlugs' && e.target.value.length &&
        e.target.value.length > 5) {
        setBranchAlert(true)
        setAlertMessage('You cannot select more than 5 repositories')
      } else {
        setFormData({
          ...formData,
          [e.target.name]: e.target.checked
            ? e.target.checked
            : e.target.value
        })
      }
    }
  }
  const checkSourceRepo = () => {
    if (!formData.sourceRepoSlugs.length) {
      setRepoError(true)
    } else {
      setRepoError(false)
    }
  }
  const setParam = (index, value, field) => {
    const paramsdata = params
    paramsdata[index][field] = value
    setParams([...paramsdata])
  }
  const addParams = () => {
    if (params[params.length - 1].from === '' ||
    params[params.length - 1].to === '') {
      setParamsError(true)
    } else {
      setParamsError(false)
      params.push({
        from: '',
        to: ''
      })
      setParams(params)
    }
  }
  const removeParams = (i) => {
    const paramsdata = params
    paramsdata.splice(i, 1)
    setParams([...paramsdata])
  }
  const setValuenull = (value) => {
    value.splice(0, 1)
    value = []
  }

  const handleChangeForCheckBox = (event) => {
    let index = 0
    if (event.target.value === 'preventAllChanges') {
      //  setPreventAllCHanges(!preventAllChanges)
      index = 0
    }

    if (event.target.value === 'preventDeletion') {
      index = 1
      //   setPreventDeletion(!preventDeletion)
    }

    if (event.target.value === 'preventRewritingHistory') {
      index = 2
      //   setPreventRewritingHistory(!preventRewritingHistory)
    }
    if (event.target.value === 'preventChangesWIthoutpullRequest') {
      index = 3
      // setPreventChangesWIthoutpullRequest(!preventChangesWIthoutpullRequest)
    }
    if (value === 0) {
      branchNamePermission[index].selected = event.target.checked
      setBranchNamePermission([...branchNamePermission])
    } else if (value === 1) {
      branchPatternPermission[index].selected = event.target.checked
      setBranchPatternPermission([...branchPatternPermission])
    } else if (value === 2) {
      branchModelPermission[index].selected = event.target.checked
      setBranchModelPermission([...branchModelPermission])
    }
  }

  const handleBranchChangeEvent = (value1, name) => {
    const groups = value1.filter((value) => value.type === 'group')
      .map((value) => value.value)
    const users = value1.filter((value) => value.type === 'user')
      .map((value) => value.value)
    if (name === 'preventChangesWIthoutpullRequest') {
      //    setPreventChangesWIthoutpullRequestForm(value);
      if (value === 0) {
        branchNamePermission[3].preventChangesWIthoutpullRequestForm = value1
        branchNamePermission[3].users = users
        branchNamePermission[3].groups = groups
        setBranchNamePermission([...branchNamePermission])
      } else if (value === 1) {
        branchPatternPermission[3].preventChangesWIthoutpullRequestForm = value1
        branchPatternPermission[3].users = users
        branchPatternPermission[3].groups = groups
        setBranchPatternPermission([...branchPatternPermission])
      } else if (value === 2) {
        branchModelPermission[3].preventChangesWIthoutpullRequestForm = value1
        branchModelPermission[3].users = users
        branchModelPermission[3].groups = groups
        setBranchModelPermission([...branchModelPermission])
      }
    }
    if (name === 'preventDeletion') {
    //  setPreventDeletionForm(value1);
      if (value === 0) {
        branchNamePermission[1].preventDeletionForm = value1
        branchNamePermission[1].users = users
        branchNamePermission[1].groups = groups
        setBranchNamePermission([...branchNamePermission])
      } else if (value === 1) {
        branchPatternPermission[1].preventDeletionForm = value1
        branchPatternPermission[1].users = users
        branchPatternPermission[1].groups = groups
        setBranchPatternPermission([...branchPatternPermission])
      } else if (value === 2) {
        branchModelPermission[1].preventDeletionForm = value1
        branchModelPermission[1].users = users
        branchModelPermission[1].groups = groups
        setBranchModelPermission([...branchModelPermission])
      }
    }
    if (name === 'preventRewritingHistory') {
      // setPreventRewritingForm(value1);
      if (value === 0) {
        branchNamePermission[2].preventRewritingHistoryForm = value1
        branchNamePermission[2].users = users
        branchNamePermission[2].groups = groups
        setBranchNamePermission([...branchNamePermission])
      } else if (value === 1) {
        branchPatternPermission[2].preventRewritingHistoryForm = value1
        branchPatternPermission[2].users = users
        branchPatternPermission[2].groups = groups
        setBranchPatternPermission([...branchPatternPermission])
      } else if (value === 2) {
        branchModelPermission[2].preventRewritingHistoryForm = value1
        branchModelPermission[2].users = users
        branchModelPermission[2].groups = groups
        setBranchModelPermission([...branchModelPermission])
      }
    }
    if (name === 'preventAllChanges') {
    //  setPreventAllChangesForm(value1);
      if (value === 0) {
        branchNamePermission[0].preventAllChangesForm = value1
        branchNamePermission[0].users = users
        branchNamePermission[0].groups = groups
        setBranchNamePermission([...branchNamePermission])
      } else if (value === 1) {
        branchPatternPermission[0].preventAllChangesForm = value1
        branchPatternPermission[0].users = users
        branchPatternPermission[0].groups = groups
        setBranchPatternPermission([...branchPatternPermission])
      } else if (value === 2) {
        branchModelPermission[0].preventAllChangesForm = value1
        branchModelPermission[0].users = users
        branchModelPermission[0].groups = groups
        setBranchModelPermission([...branchModelPermission])
      }
    }
  }

  const getGridItem = () => {
    if (formData.enableBranchPermission) {
      return 6
    }
    return 12
  }

  return (
    <div>
      {loading ? <LinearProgress /> : null}
      {' '}
      <div className={classes.alertroot}>
        {' '}
        {successAlert ? (
          <Alert severity='success'>
           Repository has been created successfully
          </Alert>
        ) : null}
        {errorAlert ? (
          <Alert severity='error' onClose={() => seterrorAlert(false)}>
            {failmessage}
          </Alert>
        ) : null}
      </div>
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={12} md={12}>
          <Card variant='outlined'>
            <ValidatorForm ref={form} onSubmit={(event) => handleSubmit(event)}>
              <CardContent>
                <Typography
                  variant='subtitle1'
                  gutterBottom
                  style={{
                    marginLeft: 10
                  }}
                >
                  Create Repo From Template
                  <a
                    href={user.clone_repo_page}
                    target='_blank'
                    className='pull-right'
                    rel='noreferrer'
                  >
                    <img
                      src={helpicon}
                      height='60em'
                      width='60em'
                      alt=''
                    />
                  </a>
                </Typography>
                <Grid container spacing={1} className={classes.root}>

                  <Grid item xs={getGridItem()} md={getGridItem()}>


                    <div style={{
                      paddingTop: 10
                    }}
                    >
                      <FormControl className={classes.formControl}>
                        <Autocomplete
                          id='combo-box-demo1'
                          options={allProjects}
                          getOptionLabel={(option) => (option.key
                            ? `${option.key} - ${option.key_app_name}`
                            : option)}
                          value={formData.sourceProjectKeyName
                            ? formData.sourceProjectKeyName
                            : ''}
                          loading={allProjects.length === 0 && loadingbar}
                          getOptionSelected={
                            (option, value) => option.name === value.name
                          }
                          onChange={
                            (event, value) => changeKey(value,
                              'sourceProjectKey')
                          }
                          renderInput={(params) => (
                            <TextValidator
                              {...params}
                              label='Source Project Key *'
                              name='sourceProjectKeyName'
                              onChange={(event) => getProjects('bitbucket',
                                event.target.value)}
                              value={formData.sourceProjectKeyName}
                              validators={['required']}
                              errorMessages={['This field is required']}
                            />
                          )}
                        />
                      </FormControl>
                      <FormControl
                        className={classes.formControl}
                        size='small'
                        error={repoerror && !formData.sourceRepoSlugs.length}
                      >
                        <InputLabel id='demo-simple-select-error-label'>
                            Source Repo *
                        </InputLabel>
                        <Select
                          labelId='demo-simple-select-error-label'
                          multiple
                          value={formData.sourceRepoSlugs}
                          name='sourceRepoSlugs'
                          onChange={handleChange}
                          renderValue={(selected) => selected.join(', ')}
                        >
                          {sourceRepos.map((name) => (
                            <MenuItem key={name.value} value={name.name}>
                              <Checkbox
                                checked={formData.sourceRepoSlugs
                                  .indexOf(name.name) > -1}
                              />
                              <ListItemText primary={name.name} />
                            </MenuItem>
                          ))}
                        </Select>
                        {repoerror && !formData.sourceRepoSlugs.length ? (
                          <FormHelperText>
                            This field is required
                          </FormHelperText>
                        ) : null}

                      </FormControl>
                      {formData.sourceRepoSlugs.length &&
                          formData.sourceRepoSlugs.length > 1 ? (
                          <FormControl
                            className={classes.formControl}
                            style={{
                              marginBottom: 0
                            }}
                          >
                            <FormLabel
                              className={classes.repoText}
                              component='legend'
                            >
                              Default branches from the selected repo will be
                              copied
                            </FormLabel>
                          </FormControl>
                        )
                        : null}
                      {formData.sourceRepoSlugs.length &&
                          formData.sourceRepoSlugs.length === 1 ? (
                          <FormControl
                            className={classes.formControl}
                            size='small'
                          >
                            <SelectValidator
                              label='Branch'
                              value={formData.branches}
                              name='branches'
                              onChange={handleChange}
                              fullWidth
                            >
                              {repoBranches.map((name) => (
                                <MenuItem
                                  key={name.displayId}
                                  value={name.displayId}
                                >
                                  {name.displayId}
                                </MenuItem>
                              ))}
                            </SelectValidator>

                          </FormControl>
                        )
                        : null}
                      {formData.sourceRepoSlugs.length &&
                        formData.sourceRepoSlugs.length === 1 ? (
                          <FormControl style={{
                            marginBottom: 0,
                            marginLeft: 8,
                            display: 'flow-root'
                          }}
                          >
                            <FormControlLabel
                              name='needBranchScaffolding'
                              control={(
                                <Checkbox
                                  name='needBranchScaffolding'
                                  checked={branchScaffoldingSettings}
                                  onChange={handleSettingsChange}
                                />
                              )}
                              label='Need Additional Branches'
                            />
                          </FormControl>
                        ) : null}
                      {branchScaffoldingSettings && (
                        <FormControl
                          className={classes.formControl}
                          size='small'
                        >
                          <InputLabel id='demo-simple-select-error-label'>
                            Additional Branch
                          </InputLabel>
                          <Select
                            labelId='branch'
                            multiple
                            value={formData.scaffoldingBranches}
                            name='scaffoldingBranches'
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(', ')}
                          >
                            {scaffoldingBranches.map((name) => (
                              <MenuItem
                                key={name.displayId}
                                value={name.displayId}
                              >
                                <Checkbox
                                  checked={
                                    formData.scaffoldingBranches
                                      .indexOf(name.displayId) > -1
                                  }
                                />
                                <ListItemText primary={name.displayId} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                      <FormControl style={{
                        marginBottom: 0,
                        marginLeft: 8,
                        display: 'flow-root'
                      }}
                      >
                        <FormControlLabel
                          name='reposettings'
                          control={(
                            <Checkbox
                              name='reposettings'
                              checked={copySettings}
                              onChange={handleSettingsChange}
                            />
                          )}
                          label='Copy Repo Settings'
                        />
                        <Tooltip
                          title={(
                            <span style={{
                              fontSize: 12,
                              fontFamily: 'Verdana'
                            }}
                            >
                              It will copy merge strategies and
                                default reviewers settings.
                            </span>
                          )}
                        >
                          <InfoIcon
                            fontSize='small'
                            style={{
                              color: '#eea508'
                            }}
                          />
                        </Tooltip>
                      </FormControl>
                      {copySettings && (
                        <FormControl className={classes.formControl}>
                          <SelectValidator
                            label='Repo for Settings'
                            name='sourceRepoSettings'
                            fullWidth
                            disabled={
                              formData.sourceProjectKeyName === ''
                            }

                            value={formData.sourceRepoSettings}
                            onChange={handleChange}
                          >
                            {sourceRepos.map((value, index) => (
                              <MenuItem value={value.name} key={value.name}>
                                {value.name}
                              </MenuItem>
                            ))}
                          </SelectValidator>
                        </FormControl>
                      )}
                      <FormControl className={classes.formControl}>
                        <Autocomplete
                          id='combo-box-demo'
                          options={allProjects}
                          getOptionLabel={(option) => (option.key
                            ? `${option.key} - ${option.key_app_name}`
                            : option)}
                          value={
                            formData.targetProjectKeyName
                              ? formData.targetProjectKeyName
                              : ''
                          }
                          loading={allProjects.length === 0 && loadingbar}
                          getOptionSelected={
                            (option, value) => option.name === value.name
                          }
                          onChange={
                            (event, value) => changeKey(value,
                              'targetProjectKey')
                          }

                          renderInput={(params) => (
                            <TextValidator
                              {...params}
                              label='Target Project Key *'
                              name='targetProjectKeyName'
                              onChange={
                                (event) => getProjects('bitbucket',
                                  event.target.value)
                              }
                              value={formData.targetProjectKeyName}
                              validators={['required']}
                              errorMessages={['This field is required']}
                            />
                          )}
                        />
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <TextValidator
                          label='Target Repo'
                          name='targetRepoSlug'
                          onChange={handleChange}
                          fullWidth
                          value={formData.targetRepoSlug}
                          validators={
                            [
                              'required',
                              'matchRegexp:^[a-z0-9_-]*$',
                              'repoNameCharacterCount'
                            ]
                          }
                          errorMessages={
                            ['This field is required',
                              `Repository name MUST be lower case and 
                            alpha-numeric (without spaces or special 
                            characters)`,
                              `Only 22 characters allowed. 
                            To use more characters use '_' or '-'`]
                          }
                        />

                        <FormHelperText>
                          Repository name MUST be lower case and alpha-numeric
                          (without spaces or special characters )
                        </FormHelperText>
                      </FormControl>
                      <FormControl
                        className={classes.formControl}
                        style={{
                          marginBottom: 0
                        }}
                      >
                        <FormControlLabel
                          name='replaceParam'
                          control={(
                            <Checkbox
                              name='replaceParam'
                              checked={formData.replaceParam}
                              onChange={handleChange}
                            />
                          )}
                          label='Replace parameters while cloning'
                        />

                      </FormControl>
                      {formData.replaceParam ? (
                        <div>
                          <FormControl
                            className={classes.formControl}
                            style={{
                              marginTop: 0
                            }}
                          >
                            <TextValidator
                              label='File Path *'
                              name='filepath'
                              onChange={handleChange}
                              fullWidth
                              value={formData.filepath}
                              validators={['required']}
                              errorMessages={['This field is required']}
                            />
                            <FormHelperText>
                                Provide multiple file path seperated by comma
                            </FormHelperText>
                          </FormControl>
                          <Typography
                            component='body1'
                            className={classes.formControl}
                          >
                              Add Parameters
                          </Typography>

                          {params.map((item, index) => (
                            <Grid
                              container
                              spacing={1}
                              className={classes.root}
                            >
                              <Grid item xs={6} md={6}>
                                <TextField
                                  label='From'
                                  className={classes.paramfield}
                                  value={item.from}
                                  onChange={
                                    (e) => setParam(index, e.target.value,
                                      'from')
                                  }
                                />
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <TextField
                                  className={classes.paramfield}
                                  label='To'
                                  value={item.to}
                                  onChange={
                                    (e) => setParam(index, e.target.value,
                                      'to')
                                  }
                                />

                                {index === (params.length - 1) ? (
                                  <IconButton onClick={addParams}>
                                    {' '}
                                    <AddIcon color='primary' />
                                    {' '}
                                  </IconButton>
                                ) : null}
                                {params.length > 1
                                  ? (
                                    <IconButton
                                      onClick={() => removeParams(index)}
                                    >
                                      {' '}
                                      <CloseIcon color='secondary' />
                                      {' '}
                                    </IconButton>
                                  ) : null}
                              </Grid>
                            </Grid>
                          ))}
                          {paramsError

                            ? (
                              <Typography
                                component='div'
                                style={{
                                  color: 'red',
                                  margin: 10
                                }}
                              >
                                Please enter values for From and To
                              </Typography>
                            ) : null}
                        </div>
                      ) : null}
                      {copySettings && (
                        <FormControl
                          className={classes.formControl}
                          style={{
                            marginBottom: 0
                          }}
                        >
                          <FormControlLabel
                            name='replaceParam'
                            control={(
                              <Checkbox
                                name='enableBranchPermission'
                                checked={formData.enableBranchPermission}
                                onChange={handleChange}
                              />
                            )}
                            label='Enable Branch Permissions'
                          />

                        </FormControl>
                      )}


                    </div>

                  </Grid>

                  {formData.enableBranchPermission && (
                    <Grid item xs={6} md={6}>
                      {users.length === 0 && (
                        <Alert severity='info'>
                          Please Select Target Repo to populate users
                            or groups
                        </Alert>
                      )}
                      <div style={{
                        paddingTop: 10
                      }}
                      >
                        <Tabs
                          value={value}
                          onChange={handleTabChange}
                          aria-label='basic tabs example'
                        >
                          <Tab label='Branch Name' {...a11yProps(0)} />
                          <Tab label='Branch Pattern' {...a11yProps(1)} />
                          <Tab label='Branching Model' {...a11yProps(2)} />
                        </Tabs>

                        <TabPanel value={value} index={0}>
                          {branchModels
                            ? (
                              <>
                                <FormControl className={classes.formControl}>
                                  <Autocomplete
                                    id='combo-box-demo-branch-name'
                                    options={branchNames}
                                    getOptionLabel={(option) => (option
                                      .displayId
                                      ? `${option.displayId}`
                                      : option)}
                                    value={
                                      branchNamePermission[0].matcher.displayId
                                        ? branchNamePermission[0]
                                          .matcher.displayId
                                        : ''
                                    }
                                    getOptionSelected={
                                      (option, value) => option.displayId ===
                                      value.displayId
                                    }
                                    onChange={
                                      (event,
                                        value) => handleBranchChange(value,
                                        'branchName')
                                    }

                                    renderInput={(params) => (
                                      <TextValidator
                                        {...params}
                                        label='Branch Name'
                                        name='branchName'
                                        required
                                      />
                                    )}
                                  />
                                </FormControl>
                                <Typography
                                  variant='subtitle2'
                                  gutterBottom
                                  style={{
                                    marginTop: 10
                                  }}
                                >
                  Restrictions
                                </Typography>

                                <FormControl className={classes.formControl}>


                                  <FormControlLabel
                                    control={(
                                      <Checkbox
                                        checked={branchNamePermission[0]
                                          .selected}
                                        value='preventAllChanges'
                                        onChange={handleChangeForCheckBox}
                                        name='preventAllChanges'
                                      />
                                    )}
                                    label='Prevent all changes, except by:'
                                  />

                                  {branchNamePermission[0].selected && (
                                    <Autocomplete
                                      id='combo-box-demo'
                                      multiple
                                      options={users}
                                      getOptionLabel={(option) => option.label}
                                      value={branchNamePermission[0]
                                        .preventAllChangesForm || []}
                                      style={{
                                        width: 300
                                      }}
                                      renderTags={(value, getTagProps) => value
                                        .map((option, index) => (
                                          <Chip
                                            variant='outlined'
                                            label={option.label}
                                            size='small'
                                            {...getTagProps({
                                              index
                                            })}
                                          />
                                        ))}
                                      onChange={
                                        (event,
                                          value) => {
                                          handleBranchChangeEvent(value,
                                            'preventAllChanges')
                                        }
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label='Enter username or groups'
                                          variant='standard'
                                        />
                                      )}
                                    />
                                  )}


                                </FormControl>

                                <FormControl className={classes.formControl}>
                                  <FormControlLabel
                                    control={(
                                      <Checkbox
                                        checked={branchNamePermission[3]
                                          .selected}
                                        value='preventDeletion'
                                        onChange={handleChangeForCheckBox}
                                        name='preventDeletion'
                                      />
                                    )}
                                    label='Prevent deletion'
                                  />

                                  {branchNamePermission[1].selected && (
                                    <Autocomplete
                                      id='combo-box-demo'
                                      multiple
                                      options={users}
                                      getOptionLabel={(option) => option.label}
                                      value={branchNamePermission[1]
                                        .preventDeletionForm || []}
                                      style={{
                                        width: 300
                                      }}
                                      renderTags={(value, getTagProps) => value
                                        .map((option, index) => (
                                          <Chip
                                            variant='outlined'
                                            label={option.label}
                                            size='small'
                                            {...getTagProps({
                                              index
                                            })}
                                          />
                                        ))}
                                      onChange={(event, value) => {
                                        handleBranchChangeEvent(value,
                                          'preventDeletion')
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label='Enter username or groups'
                                          variant='standard'
                                        />
                                      )}
                                    />
                                  )}

                                </FormControl>


                                <FormControl className={classes.formControl}>
                                  <FormControlLabel
                                    control={(
                                      <Checkbox
                                        checked={branchNamePermission[2]
                                          .selected}
                                        value='preventRewritingHistory'
                                        onChange={handleChangeForCheckBox}
                                        name='preventRewritingHistory'
                                      />
                                    )}
                                    label='Prevent rewriting history'
                                  />

                                  {branchNamePermission[2].selected && (
                                    <Autocomplete
                                      id='combo-box-demo'
                                      multiple
                                      options={users}
                                      getOptionLabel={(option) => option.label}
                                      value={branchNamePermission[2]
                                        .preventRewritingHistoryForm || []}
                                      style={{
                                        width: 300
                                      }}
                                      renderTags={(value, getTagProps) => value
                                        .map((option, index) => (
                                          <Chip
                                            variant='outlined'
                                            label={option.label}
                                            size='small'
                                            {...getTagProps({
                                              index
                                            })}
                                          />
                                        ))}
                                      onChange={(event, value) => {
                                        handleBranchChangeEvent(value,
                                          'preventRewritingHistory')
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label='Enter username or groups'
                                          variant='standard'
                                        />
                                      )}
                                    />
                                  )}

                                </FormControl>

                                <FormControl className={classes.formControl}>
                                  <FormControlLabel
                                    control={(
                                      <Checkbox
                                        checked={branchNamePermission[3]
                                          .selected}
                                        value='preventChangesWIthoutpullRequest'
                                        onChange={handleChangeForCheckBox}
                                        name='preventChangesWIthoutpullRequest'
                                      />
                                    )}
                                    label='Prevent changes without
                                     a pull request'
                                  />

                                  {branchNamePermission[3].selected && (
                                    <Autocomplete
                                      id='combo-box-demo'
                                      multiple
                                      options={users}
                                      getOptionLabel={(option) => option.label}
                                      value={branchNamePermission[3]
                                        .preventChangesWIthoutpullRequestForm ||
                                        []}
                                      style={{
                                        width: 300
                                      }}
                                      onChange={(event, value) => {
                                        handleBranchChangeEvent(value,
                                          'preventChangesWIthoutpullRequest')
                                      }}
                                      renderTags={(value, getTagProps) => value
                                        .map((option, index) => (
                                          <Chip
                                            variant='outlined'
                                            label={option.label}
                                            size='small'
                                            {...getTagProps({
                                              index
                                            })}
                                          />
                                        ))}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label='Enter username or groups'
                                          variant='standard'
                                        />
                                      )}
                                    />
                                  )}

                                </FormControl>
                              </>
                            ) : null}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          {branchModels
                            ? (
                              <>
                                <FormControl className={classes.formControl}>
                                  <TextValidator
                                    label='Branch pattern'
                                    name='branchPattern'
                                    onChange={
                                      (e) => handleBranchChange(e.target.value,
                                        'Branch Pattern')
                                    }
                                    value={branchPatternPermission[0]
                                      .matcher.id}
                                    required
                                  />

                                </FormControl>
                                <Typography
                                  variant='subtitle2'
                                  gutterBottom
                                  style={{
                                    marginTop: 10
                                  }}
                                >
                  Restrictions
                                </Typography>

                                <FormControl className={classes.formControl}>


                                  <FormControlLabel
                                    control={(
                                      <Checkbox
                                        value='preventAllChanges'
                                        checked={branchPatternPermission[0]
                                          .selected}
                                        onChange={handleChangeForCheckBox}
                                        name='preventAllChanges'
                                      />
                                    )}
                                    label='Prevent all changes, except by:'
                                  />

                                  {branchPatternPermission[0].selected && (
                                    <Autocomplete
                                      id='combo-box-demo'
                                      multiple
                                      options={users}
                                      getOptionLabel={(option) => option.label}
                                      value={branchPatternPermission[0]
                                        .preventAllChangesForm || []}
                                      style={{
                                        width: 300
                                      }}
                                      renderTags={(value, getTagProps) => value
                                        .map((option, index) => (
                                          <Chip
                                            variant='outlined'
                                            label={option.label}
                                            size='small'
                                            {...getTagProps({
                                              index
                                            })}
                                          />
                                        ))}
                                      onChange={(event, value) => {
                                        handleBranchChangeEvent(value,
                                          'preventAllChanges')
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label='Enter username or groups'
                                          variant='standard'
                                        />
                                      )}
                                    />
                                  )}


                                </FormControl>

                                <FormControl className={classes.formControl}>
                                  <FormControlLabel
                                    control={(
                                      <Checkbox
                                        checked={branchPatternPermission[1]
                                          .selected}
                                        value='preventDeletion'
                                        onChange={handleChangeForCheckBox}
                                        name='preventDeletion'
                                      />
                                    )}
                                    label='Prevent deletion'
                                  />

                                  {branchPatternPermission[1].selected && (
                                    <Autocomplete
                                      id='combo-box-demo'
                                      multiple
                                      options={users}
                                      getOptionLabel={(option) => option.label}
                                      value={branchPatternPermission[1]
                                        .preventDeletionForm || []}
                                      style={{
                                        width: 300
                                      }}
                                      renderTags={(value, getTagProps) => value
                                        .map((option, index) => (
                                          <Chip
                                            variant='outlined'
                                            label={option.label}
                                            size='small'
                                            {...getTagProps({
                                              index
                                            })}
                                          />
                                        ))}
                                      onChange={(event, value) => {
                                        handleBranchChangeEvent(value,
                                          'preventDeletion')
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label='Enter username or groups'
                                          variant='standard'
                                        />
                                      )}
                                    />
                                  )}

                                </FormControl>


                                <FormControl className={classes.formControl}>
                                  <FormControlLabel
                                    control={(
                                      <Checkbox
                                        checked={branchPatternPermission[2]
                                          .selected}
                                        value='preventRewritingHistory'
                                        onChange={handleChangeForCheckBox}
                                        name='preventRewritingHistory'
                                      />
                                    )}
                                    label='Prevent rewriting history'
                                  />

                                  {branchPatternPermission[2].selected && (
                                    <Autocomplete
                                      id='combo-box-demo'
                                      multiple
                                      options={users}
                                      getOptionLabel={(option) => option.label}
                                      value={branchPatternPermission[2]
                                        .preventRewritingHistoryForm || []}
                                      style={{
                                        width: 300
                                      }}
                                      renderTags={(value, getTagProps) => value
                                        .map((option, index) => (
                                          <Chip
                                            variant='outlined'
                                            label={option.label}
                                            size='small'
                                            {...getTagProps({
                                              index
                                            })}
                                          />
                                        ))}
                                      onChange={(event, value) => {
                                        handleBranchChangeEvent(value,
                                          'preventRewritingHistory')
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label='Enter username or groups'
                                          variant='standard'
                                        />
                                      )}
                                    />
                                  )}

                                </FormControl>

                                <FormControl className={classes.formControl}>
                                  <FormControlLabel
                                    control={(
                                      <Checkbox
                                        checked={branchPatternPermission[3]
                                          .selected}
                                        value='preventChangesWIthoutpullRequest'
                                        onChange={handleChangeForCheckBox}
                                        name='preventChangesWIthoutpullRequest'
                                      />
                                    )}
                                    label='Prevent changes without a
                                     pull request'
                                  />

                                  {branchPatternPermission[3].selected && (
                                    <Autocomplete
                                      id='combo-box-demo'
                                      multiple
                                      options={users}
                                      getOptionLabel={(option) => option.label}
                                      value={branchPatternPermission[3]
                                        .preventChangesWIthoutpullRequestForm ||
                                        []}
                                      style={{
                                        width: 300
                                      }}
                                      onChange={(event, value) => {
                                        handleBranchChangeEvent(value,
                                          'preventChangesWIthoutpullRequest')
                                      }}
                                      renderTags={(value, getTagProps) => value
                                        .map((option, index) => (
                                          <Chip
                                            variant='outlined'
                                            label={option.label}
                                            size='small'
                                            {...getTagProps({
                                              index
                                            })}
                                          />
                                        ))}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label='Enter username or groups'
                                          variant='standard'
                                        />
                                      )}
                                    />
                                  )}

                                </FormControl>
                              </>
                            ) : null}
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                          {branchModels
                            ? (
                              <>
                                <FormControl className={classes.formControl}>
                                  <SelectValidator
                                    label='Select Branch Type'
                                    name='branchModel'
                                    value={branchModelPermission[0].matcher.id}
                                    onChange={
                                      (e) => handleBranchChange(e.target.value,
                                        'Branching Model')
                                    }
                                    required
                                  >
                                    <MenuItem
                                      value={
                                        branchModels.development.displayId
                                      }
                                      key='development'
                                    >
                                      {`Development (
                                        ${branchModels.development.displayId})`}
                                    </MenuItem>
                                    {branchModels.types.map((value, index) => (
                                      <MenuItem value={value.id} key={value.id}>
                                        {`${value.displayName} (
                                          ${value.prefix})`}
                                      </MenuItem>
                                    ))}
                                  </SelectValidator>
                                </FormControl>
                                <Typography
                                  variant='subtitle2'
                                  gutterBottom
                                  style={{
                                    marginTop: 10
                                  }}
                                >
                  Restrictions
                                </Typography>

                                <FormControl className={classes.formControl}>


                                  <FormControlLabel
                                    control={(
                                      <Checkbox
                                        value='preventAllChanges'
                                        checked={branchModelPermission[0]
                                          .selected}
                                        onChange={handleChangeForCheckBox}
                                        name='preventAllChanges'
                                      />
                                    )}
                                    label='Prevent all changes, except by:'
                                  />

                                  {branchModelPermission[0].selected && (
                                    <Autocomplete
                                      id='combo-box-demo'
                                      multiple
                                      options={users}
                                      getOptionLabel={(option) => option.label}
                                      value={branchModelPermission[0]
                                        .preventAllChangesForm || []}
                                      style={{
                                        width: 300
                                      }}
                                      renderTags={(value, getTagProps) => value
                                        .map((option, index) => (
                                          <Chip
                                            variant='outlined'
                                            label={option.label}
                                            size='small'
                                            {...getTagProps({
                                              index
                                            })}
                                          />
                                        ))}
                                      onChange={(event, value) => {
                                        handleBranchChangeEvent(value,
                                          'preventAllChanges')
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label='Enter username or groups'
                                          variant='standard'
                                        />
                                      )}
                                    />
                                  )}


                                </FormControl>

                                <FormControl className={classes.formControl}>
                                  <FormControlLabel
                                    control={(
                                      <Checkbox
                                        value='preventDeletion'
                                        onChange={handleChangeForCheckBox}
                                        name='preventDeletion'
                                        checked={branchModelPermission[1]
                                          .selected}
                                      />
                                    )}
                                    label='Prevent deletion'
                                  />

                                  {branchModelPermission[1].selected && (
                                    <Autocomplete
                                      id='combo-box-demo'
                                      multiple
                                      options={users}
                                      getOptionLabel={(option) => option.label}
                                      value={branchModelPermission[1]
                                        .preventDeletionForm || []}
                                      style={{
                                        width: 300
                                      }}
                                      renderTags={(value, getTagProps) => value
                                        .map((option, index) => (
                                          <Chip
                                            variant='outlined'
                                            label={option.label}
                                            size='small'
                                            {...getTagProps({
                                              index
                                            })}
                                          />
                                        ))}
                                      onChange={(event, value) => {
                                        handleBranchChangeEvent(value,
                                          'preventDeletion')
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label='Enter username or groups'
                                          variant='standard'
                                        />
                                      )}
                                    />
                                  )}

                                </FormControl>


                                <FormControl className={classes.formControl}>
                                  <FormControlLabel
                                    control={(
                                      <Checkbox
                                        value='preventRewritingHistory'
                                        onChange={handleChangeForCheckBox}
                                        name='preventRewritingHistory'
                                        checked={branchModelPermission[2]
                                          .selected}
                                      />
                                    )}
                                    label='Prevent rewriting history'
                                  />

                                  {branchModelPermission[2].selected && (
                                    <Autocomplete
                                      id='combo-box-demo'
                                      multiple
                                      options={users}
                                      getOptionLabel={(option) => option.label}
                                      value={branchModelPermission[2]
                                        .preventRewritingHistoryForm || []}
                                      style={{
                                        width: 300
                                      }}
                                      renderTags={(value, getTagProps) => value
                                        .map((option, index) => (
                                          <Chip
                                            variant='outlined'
                                            label={option.label}
                                            size='small'
                                            {...getTagProps({
                                              index
                                            })}
                                          />
                                        ))}
                                      onChange={(event, value) => {
                                        handleBranchChangeEvent(value,
                                          'preventRewritingHistory')
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label='Enter username or groups'
                                          variant='standard'
                                        />
                                      )}
                                    />
                                  )}

                                </FormControl>

                                <FormControl className={classes.formControl}>
                                  <FormControlLabel
                                    control={(
                                      <Checkbox
                                        value='preventChangesWIthoutpullRequest'
                                        checked={branchModelPermission[3]
                                          .selected}
                                        onChange={handleChangeForCheckBox}
                                        name='preventChangesWIthoutpullRequest'
                                      />
                                    )}
                                    label='Prevent changes without
                                     a pull request'
                                  />

                                  {branchModelPermission[3].selected && (
                                    <Autocomplete
                                      id='combo-box-demo'
                                      multiple
                                      options={users}
                                      getOptionLabel={(option) => option.label}
                                      value={branchModelPermission[3]
                                        .preventChangesWIthoutpullRequestForm ||
                                         []}
                                      style={{
                                        width: 300
                                      }}
                                      onChange={(event, value) => {
                                        handleBranchChangeEvent(value,
                                          'preventChangesWIthoutpullRequest')
                                      }}
                                      renderTags={(value, getTagProps) => value
                                        .map((option, index) => (
                                          <Chip
                                            variant='outlined'
                                            label={option.label}
                                            size='small'
                                            {...getTagProps({
                                              index
                                            })}
                                          />
                                        ))}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label='Enter username or groups'
                                          variant='standard'
                                        />
                                      )}
                                    />
                                  )}

                                </FormControl>
                              </>
                            ) : null}
                        </TabPanel>


                      </div>

                    </Grid>
                  )}


                  <CardActions className={classes.footer}>
                    <Button
                      variant='contained'
                      size='small'
                      onClick={resetForm.bind(this)}
                    >
                  Reset
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      type='submit'
                      onClick={() => checkSourceRepo()}
                      disabled={loading || user.switch_user}
                      size='small'
                    >
                  Create
                    </Button>
                  </CardActions>
                </Grid>
              </CardContent>
            </ValidatorForm>
          </Card>
        </Grid>


      </Grid>

      <AlertDialog
        handleClose={() => setBranchAlert(false)}
        alertopen={branchAlert}
        key='alert2'
        message={alertMessage}
        okbuttonalert
        title='Alert'
      />
    </div>
  )
}

export default CloneRepoComponent