
/* It contains all the API functions which is releated to approval workflow section */

import axiosInstance from '../../middleware/Interceptor'
/* To get approval workflow data for given project */
function getApprovalWorkflow (project) {
  return axiosInstance.get(`/api/v1/jira/getProjectApprovalWorkflows/
${project}`)
}
/* To check the given is owner of selected project */
function checkOwnerOfJiraProject (username, project) {
  return axiosInstance.get(`/api/v1/jira/checkOwnerOfJiraProject?username=
${username}&key=${project}`)
}
/* To get approver list of given project */
function getApproversInProject (project) {
  return axiosInstance.get(`/api/v1/jira/getApproversInProject?key=${project}`)
}
/* To update approval workflow data for given project */
function addUsersToApprovalGroups (project, input) {
  return axiosInstance.post(`/api/v1/jira/
addUsersToApprovalGroupsInApprovalCategory?key=${project}`, input)
}

const approvalWorkflowService = {
  getApprovalWorkflow,
  checkOwnerOfJiraProject,
  getApproversInProject,
  addUsersToApprovalGroups
}

export default approvalWorkflowService
