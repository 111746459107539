import React from 'react'
import {
  LinearProgress,
  makeStyles,
  Typography,
  Select,
  MenuItem,
  Grid,
  Button,
  FormControl,
  InputLabel
} from '@material-ui/core'
import EnhancedTable from '../sections/enhancedTable'
import groupAccessService from '../../services/api/groupAccessService'
import ComboBox from '../inputs/autocomplete'
import ToolsService from '../../services/api/toolsService'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  width: {
    width: '90%'
  },
  nodatatext: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(8),
    textAlign: 'center'
  },
  btnmargin: {
    float: 'right',
    right: 50
  }
}))

export default function GroupAccessComponent () {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const [loadingbar, setLoadingBar] = React.useState(false)
  const [availableTools, setAvailableTools] = React.useState([])
  const [allRoles, setAllRoles] = React.useState([])
  const [tableData, setTableData] = React.useState([])
  const [tool, setTool] = React.useState(null)
  const [groupname, setGroupName] = React.useState(null)
  const [headCells, setHeadCells] = React.useState([])

  /* initial method will be called when the page gets loaded */
  React.useEffect(() => {
    setLoading(true)
    ToolsService.getAvailableTools('idms').then((result) => {
      setLoading(false)
      setAvailableTools(result.data.filter((item) => item.name === 'Jira' ||
        item.name === 'Confluence' || item.name === 'Bitbucket'))
    })
  }, [])

  /* function to get the groups list based on the search key */
  const getIDMSGroups = (value) => {
    if (value && value.length > 3) {
      setLoadingBar(true)
      groupAccessService.getIDMSGroups(value).then((response) => {
        setAllRoles(response.data)
        setLoadingBar(false)
      })
    }
  }

  /* To get Role Access data for selected tool */
  const changeTool = (e) => {
    setTool(e.target.value)
    if (e.target.value.name === 'Confluence') {
      setHeadCells([
        {
          id: 'key',
          numeric: false,
          label: 'Key'
        },
        {
          id: 'projectname',
          numeric: false,
          label: 'Space Name'
        }
      ])
    } else {
      setHeadCells([
        {
          id: 'key',
          numeric: false,
          label: 'Key'
        },
        {
          id: 'projectname',
          numeric: false,
          label: 'Project Name'
        }
      ])
    }
    setLoading(true)
    setTableData([])
    groupAccessService.getGroupAccess(groupname,
      e.target.value.name).then((response) => {
      setTableData(response.data)
      setLoading(false)
    })
  }
  const changeRole = (value) => {
    if (value) {
      setGroupName(value.name)
      if (tool) {
        setLoading(true)
        setTableData([])
        groupAccessService.getGroupAccess(value.name, tool.name)
          .then((response) => {
            setTableData(response.data)
            setLoading(false)
          })
      }
    }
  }
  // Reset data
  const resetForm = () => {
    setTool(null)
    setAllRoles([])
    setTableData([])
    setGroupName(null)
  }

  return (
    <div className={classes.root}>
      {loading ? <LinearProgress /> : null}
      <Typography variant='subtitle1' gutterBottom>
        Group Access
        <Button
          variant='contained'
          onClick={resetForm}
          className={classes.btnmargin}
        >
          Reset
        </Button>
      </Typography>
      <Grid container spacing={1} item>
        <Grid item xs={6} md={4}>
          <FormControl className={classes.width}>
            <ComboBox
              allProjects={allRoles}
              name='jira-project-role'
              label='Select Group'
              changeOption={changeRole}
              getProjects={getIDMSGroups}
              loading={loadingbar}
              inputValue={groupname}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} md={2}>
          <FormControl className={classes.width}>
            <InputLabel id='demo-simple-select-label'>Select Tool</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              value={tool || ''}
              onChange={changeTool}
              disabled={!groupname}
            >
              {availableTools.map((value, i) => (
                <MenuItem value={value} key={value.name}>
                  {value.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

      </Grid>
      {tableData.length ? (
        <EnhancedTable
          key='history-table'
          headCells={headCells}
          rowsData={tableData}
        />
      ) : null}
      {!loading && !tableData.length ? (
        <div className={classes.nodatatext}>No data to display.</div>
      ) : null}
    </div>
  )
}
