import React, {
  useContext, useRef
} from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  MenuItem,
  createStyles,
  makeStyles,
  FormControl,
  FormControlLabel,
  Checkbox,
  LinearProgress,
  Link
} from '@material-ui/core'
import {
  ValidatorForm,
  SelectValidator
} from 'react-material-ui-form-validator'
import {
  Alert
} from '@material-ui/lab'
import UserContext from '../contexts/UserContext'
import GitHubCopilotActivationService from
'../../services/api/githubCopilotActivationService'

const useStyles = makeStyles((theme) => createStyles({
  root: {
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  success: {
    backgroundColor: theme.palette.success.light
  },
  pending: {
    backgroundColor: '#fff9c4'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  userlist: {
    height: 500,
    overflow: 'auto'
  },
  labelDisplay: {
    margin: '10px',
    width: '100%'
  },
  padding: {
    paddingLeft: '3%'
  },
  nodatatext: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(4),
    textAlign: 'center'
  }
}))

export default function GitHubCopilotActivationComponent () {
  const [loading, setLoading] = React.useState(false)
  const [loadingbar, setLoadingBar] = React.useState(false)
  const {
    user, setUser
  } = useContext(UserContext)
  const [bitbucketuser, setbitbucketUser] = React.useState(null)
  const [sshKeys, setSshKeys] = React.useState([])
  const [deleteid, setDeleteId] = React.useState(null)
  const [deletealert, setDeleteAlert] = React.useState(null)
  const [alertopen, setAlertopen] = React.useState(false)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [successAlert, setSuccessAlert] = React.useState(false)
  const [successAlertMessage, setSuccessAlertMessage] = React.useState(null)
  const [failAlertMessage, setFailAlertMessage] = React.useState(null)
  const [alertBoxMessage, setAlertBoxMessage] = React.useState(null)
  const [projectList, setProjectList] = React.useState([])
  const [submitBtnEnalbe, setSubmitBtnEnable] = React.useState(true)
  const [acceptChargesCheck, setAcceptChargesCheck] = React.useState(false)
  const [showChargesMessage, setShowChargesMessage] = React.useState(false)
  const [selectedProject, setSelectedProject] = React.useState(null)
  const classes = useStyles()
  const form = useRef(null)

  React.useEffect(() => {
    getBBProjects4LoggedInUserIsOwner()
  }, [])

  function getBBProjects4LoggedInUserIsOwner () {
    setLoading(true)
    GitHubCopilotActivationService.getBBProjects4LoggedInUserIsOwner()
      .then((response) => {
        setProjectList(response.data)
        setUser({
          ...user
        })
        setLoading(false)
      })
  }

  function checkProjectAlreadyActivatedInGitHubCopilot (value) {
    setLoading(true)
    GitHubCopilotActivationService
      .checkProjectAlreadyActivatedInGitHubCopilot(value.key)
      .then((response) => {
        if (!response.data[0].exists === true) {
          setSelectedProject(value)
          setShowChargesMessage(true)
          setLoading(false)
        } else {
          seterrorAlert(true)
          setFailAlertMessage(`Github Copilot Group is already created
            for the selected project ${value.key}`)
          setLoading(false)
        }
      })
  }

  const handleChange = (event) => {
    if (event.target.name === 'project') {
      checkProjectAlreadyActivatedInGitHubCopilot(event.target.value)
    } else if (event.target.checked === true) {
      setAcceptChargesCheck(true)
      setSubmitBtnEnable(false)
    } else if (event.target.checked === false) {
      setAcceptChargesCheck(false)
      setSubmitBtnEnable(true)
    }
  }

  const resetForm = () => {
    setAcceptChargesCheck(false)
    setShowChargesMessage(false)
    setSubmitBtnEnable(true)
    setSelectedProject(null)
  }

  const handleSubmit = () => {
    const input = {
      project: selectedProject,
      acceptsCharge: acceptChargesCheck
    }
    setLoading(true)
    GitHubCopilotActivationService.submitRequest(input).then((response) => {
      if (response.data.status === 200) {
        setSuccessAlert(true)
        setSuccessAlertMessage(response.data.message)
        setLoading(false)
        resetForm()
      } else {
        seterrorAlert(true)
        setFailAlertMessage(response.data.message)
        setLoading(false)
        resetForm()
      }
    })
  }

  return (
    <div>
      {loading ? <LinearProgress /> : null}
      {' '}
      <div className={classes.alertroot}>
        {' '}
        {successAlert ? (
          <Alert
            severity='success'
            onClose={() => setSuccessAlert(false)}
          >
            {successAlertMessage}
          </Alert>
        ) : null}
        {errorAlert ? (
          <Alert
            severity='error'
            onClose={() => seterrorAlert(false)}
          >
            {failAlertMessage}
          </Alert>
        ) : null}
      </div>
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={12} md={7}>
          <Card variant='outlined'>
            <ValidatorForm ref={form} onSubmit={(event) => handleSubmit(event)}>
              <CardContent>
                <Typography variant='subtitle1' gutterBottom>
                  GitHub Copilot Service Enablement Request
                </Typography>
                <div style={{
                  paddingTop: 10
                }}
                >
                  <Typography
                    className={classes.labelDisplay}
                  >
                  This page is intended for the J&J Business Project owners
                    {' '}
                  requesting enablement of GitHub Copilot Business for
                    {' '}
                  their software product teams.
                  </Typography>
                  <Typography
                    className={classes.labelDisplay}
                  >
                  The GitHub Copilot Business is a paid service which is
                    {' '}
                  activated per request for a given EAT Account.
                  </Typography>
                  <Typography
                    className={classes.labelDisplay}
                  >
                  Teams
                    {' '}
                  requesting access will be billed quarterly based on number
                    {' '}
                  of licenses used.
                  </Typography>
                  <Typography
                    className={classes.labelDisplay}
                  >
                    J&J Business owners are asked to
                    {' '}
                  acknowledge accountability for the charges related to usage
                    {' '}
                  of GitHub Copilot for their teams.
                  </Typography>
                  <Typography
                    className={classes.labelDisplay}
                  >
                  J&J Business owners are
                    {' '}
                  also accountable for ensuring all users approved for GitHub
                    {' '}
                  Copilot Business have completed the
                    {' '}
                    <Link href='https://jnj.csod.com/ui/lms-learning-details/app/course/021ca3fe-7e3f-4c6e-896f-a00b897f5891' target='_blank' rel='noopener'>
                  &ldquo;An Introduction to Generative AI at
                      {' '}
                  Johnson & Johnson&rdquo;
                    </Link>
                    {' '}
                  training in J&J Learn (Summit)
                    {' '}
                  For additional details please refer to
                    {' '}
                    <Link href='https://confluence.jnj.com/x/VukLH' target='_blank' rel='noopener'>
                      XENA AI Assisted Software Development.
                    </Link>
                  </Typography>
                  <Typography
                    className={classes.labelDisplay}
                  >
                  Please select a project from the below list:
                  </Typography>
                  {projectList.length > 0 ? (
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                    >
                      <SelectValidator
                        label='Project to Select'
                        name='project'
                        required
                        style={{
                          width: '100%'
                        }}
                        value={selectedProject}
                        fullWidth
                        // selectedValue={selectedProject}
                        onChange={handleChange}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      >
                        {projectList.map((value, index) => (
                          <MenuItem value={value} key={value.key}>
                            {value.key}
                          </MenuItem>
                        ))}
                      </SelectValidator>
                    </FormControl>
                  ) : (
                    <div className={classes.nodatatext}>
                    You are not Account Owner of any Bitbucket project.
                    </div>
                  )}
                  {showChargesMessage ? (
                    <FormControlLabel
                      name='acceptChargesCheck'
                      control={(
                        <Checkbox
                          name='acceptChargesCheck'
                          checked={acceptChargesCheck}
                          onChange={handleChange}
                          className={classes.padding}
                        />
                      )}
                      label='I accept financial charges for the GitHub Copilot
                        user licenses and related compliance training
                        responsibilities.'
                      className={classes.inputDisplay}
                    />
                  ) : null}
                </div>
              </CardContent>
              <CardActions className={classes.footer}>
                <Button
                  variant='contained'
                  size='small'
                  onClick={resetForm}
                >
                  Reset
                </Button>
                {/* <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  size='small'
                  disabled={loading || user.switch_user}
                >
                  Add to Cart
                </Button> */}
                <Button
                  variant='contained'
                  color='secondary'
                  type='submit'
                  disabled={loading || user.switch_user || submitBtnEnalbe}
                >
                  Submit Now
                </Button>
              </CardActions>
            </ValidatorForm>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}
