import React, {
  useContext, useState, useRef, useEffect
} from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  makeStyles,
  FormControl,
  LinearProgress,
  IconButton,
  MenuItem
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  useParams, Link
} from 'react-router-dom'
import {
  TextValidator, ValidatorForm, SelectValidator
} from 'react-material-ui-form-validator'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import {
  FileUploader, FileManager
} from 'reactjs-file-uploader'
import queryString from 'query-string'
import SupportService from '../../services/api/supportService'
import requestAccess from '../../services/api/requestaccessService'
import ToolsService from '../../services/api/toolsService'
import UserContext from '../contexts/UserContext'
import SupportContext from '../contexts/SupportContext'
import PathContext from '../contexts/PathContext'
import SupportComponent from './supportComponent'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  pullRight: {
    float: 'right'
  },
  columnDisplay: {
    display: 'flex',
    flexDirection: 'row'
  },
  labelDisplay: {
    margin: '10px',
    width: '25%'
  },
  inputDisplay: {
    width: '70%'
  },
  attachmentColDisplay: {
    display: 'flex',
    flexDirection: 'columns'
  },
  attachmentDisplay: {
    width: '35%'
  },
  templateColDisplay: {
    margin: '10px',
    width: '50%'
  },
  error: {
    color: 'red'
  },
  here: {
    color: '#007bff',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}))

export default function SupportIncidentComponent (props) {
  const queryparams = queryString.parse(props.location.search)
  const {
    setPath
  } = useContext(PathContext)
  const {
    user
  } = useContext(UserContext)
  const {
    support, setSupport
  } = useContext(SupportContext)
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const form = useRef(null)
  const [allUsers, setAllUsers] = useState([])
  const [allTools, setAllTools] = useState([])
  const [loadingOptions, setLoadingOptions] = useState(false)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [files, setFiles] = useState([])
  const [alertAttachment, setAlertAttachment] = useState(false)

  const [formData, setFormData] = useState({
    requestFor: user.name.toUpperCase(),
    requestForName: user.name,
    toolName: '',
    location: '',
    shortDesc: '',
    description: '',
    attachmentAnchorTag: '',
    contact_type: 'Event Management',
    category: 'Software',
    sub_category: 'Website',
    impact: '',
    urgency: '',
    priority: '',
    maxFileSize: 10000000
  })

  // Returns alert and it's message
  const showAlert = () => {
    if (support.success === false && support.message !== '') {
      return (
        <Alert
          severity='error'
          onClose={() => setSupport({
            success: false,
            message: ''
          })}
        >
          {support.message}
        </Alert>
      )
    }
  }

  useEffect(() => {
    setLoading(true)
    SupportService.getConfigurationItem().then((configItemResponse) => {
      setLoading(false)
      ToolsService.getEnabledTools().then((response) => {
        const toolsresponse = []
        configItemResponse.data.map((item1) => {
          if (response.data.find((item) => item.name
            .includes(item1.toolname)) || item1.toolname === 'Appdevtools') {
            toolsresponse.push(item1)
          }
        })
        setAllTools(toolsresponse)
        if (queryparams.tool) {
          const filtervalue1 = toolsresponse
            .filter((item) => item.toolname.toLowerCase() ===
            queryparams.tool.toLowerCase())
          if (filtervalue1.length && filtervalue1.length === 1) {
            setFormData({
              ...formData,
              toolName: filtervalue1[0].toolname,
              toolNameDisplayValue: filtervalue1[0],
              toolCIName: filtervalue1[0].ciname
            })
          }
        }
      })
    })
  }, [])

  // Handling change for the user name in fields
  const changeUser = (value) => {
    if (value) {
      setFormData({
        ...formData,
        requestFor: `${value.name} - ${value.displayname
          ? value.displayname
          : null}`,
        requestForName: value.name
      })
    }
    setAllUsers([])
  }

  // Get Users for auto complete
  const getUsers = (username) => {
    setFormData({
      ...formData,
      searchkey: username
    })
    setLoadingOptions(true)
    requestAccess.getallusersforautocomplete(username).then((response) => {
      setLoadingOptions(false)
      setAllUsers(response.data)
    })
  }

  const handleChange = (e) => {
    if (e.target.name === 'toolName') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.toolname,
        toolNameDisplayValue: e.target.value,
        toolCIName: e.target.value.ciname
      })
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      })
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    submitRequest()
  }

  // remove attachment
  const removeAttachment = (file) => {
    if (file) {
      files.map((fileitem, i) => {
        if (fileitem.name === file.name) {
          SupportService.removeFileUpload('ticket', {
            filename: file.originalname
          }).then((response) => {
            files.splice(i, 1)
            setFiles([...files])
          })
        }
      })
    }
  }

  const removeEntry = () => {
    setAlertAttachment(true)
    setTimeout(() => {
      setAlertAttachment(false)
    }, 20000)
    files.map((item, i) => {
      if (item.size > formData.maxFileSize) {
        files.splice(i, 1)
        setFiles([...files])
      }
    })
  }

  // set original name for file
  const setFileOriginalName = (file, response) => {
    files.map((fileitem, i) => {
      if (fileitem.name === file.name) {
        files[i].originalname = response.filename
        setFiles(files)
      }
    })
  }

  // Upload Files
  const uploadFiles = (uploadedFiles) => uploadedFiles.map(uploadFile)

  // Upload a single file
  const uploadFile = (file) => (
    <FileUploader
      key={file.key}
      file={file}
      url='/api/fileupload?page=ticket'
      formData={{
        file,
        upload_preset: 'public',
        tags: 'vanilla'
      }}
      readFile
      autoUpload={file.size < formData.maxFileSize}
    >
      {fileProgress}
    </FileUploader>
  )

  // show file progress
  const fileProgress = ({
    uploadReady,
    downloadComplete,
    /*
    Response text Object (JSON)
     */
    response,
    file
  }) => (
    <div>
      {/* {downloadComplete && file.size > formData.maxFileSize ? '10 mb is the limit' : 'can upload'} */}
      {downloadComplete && response && setFileOriginalName(file, response)}
      {downloadComplete && file.size < formData.maxFileSize ? (
        <Grid
          container
          spacing={1}
          style={{
            marginBottom: 20
          }}
        >
          <Grid
            item
            xs={4}
            md={3}
            style={{
              marginLeft: 30
            }}
          >
            {file.name}
          </Grid>
          <Grid item xs={6} md={4}>
            <LinearProgress
              style={{
                width: '80%'
              }}
              variant='determinate'
              value={100}
            />
            <Typography component='div' variant='caption'>
              100% upload completed
            </Typography>
          </Grid>
          <Grid item xs={2} md={2}>
            <IconButton
              aria-label='delete'
              style={{
                padding: 0
              }}
            >
              <DeleteIcon
                color='secondary'
                onClick={() => removeAttachment(file)}
              />
            </IconButton>
          </Grid>
        </Grid>
      ) : null}
      {uploadReady && file.size > formData.maxFileSize
        ? (removeEntry())
        : (null)}
    </div>
  )

  const submitRequest = () => {
    let input = {
    }

    formData.attachmentAnchorTag = `${String.fromCharCode(10)}Created By:
       ${user.name.toUpperCase()}`
    if (files.length > 0) {
      formData.attachmentAnchorTag = `${formData.attachmentAnchorTag}
         ${String.fromCharCode(10)}Attachments :`
      files.map((item, i) => {
        if (item.originalname !== undefined) {
          formData.attachmentAnchorTag = `${formData.attachmentAnchorTag} ${String.fromCharCode(10)}https://${document.location.hostname}/api/uploads/${item.originalname}`
        }
      })
    }

    input = {
      request_for: formData.requestForName,
      assgn_grp: 'AS FRAD TOOLS L2',
      shortDesc: formData.shortDesc,
      location: 2172,
      description: `${formData.description} ${formData.attachmentAnchorTag}`,
      cmdbCI: formData.toolCIName,
      category: formData.category,
      subcategory: formData.sub_category,
      sourceSystemName: formData.toolName.toLowerCase(),
      created_by: user.name.toUpperCase()
    }

    if (formData.toolName.toLowerCase() === 'jenkins' ||
      formData.toolName.toLowerCase() === 'artifactory') {
      input.application = 'Jenkins - Artifactory'
    } else {
      input.application = formData.toolName
    }

    setSubmitLoader(true)
    SupportService.createIncident(input).then((response) => {
      setSubmitLoader(false)
      if (response.data.body.result.status_code === 200 ||
          response.data.body.result.status_message === 'Success') {
        setSupport({
          success: true,
          message: `Iris Request Submitted Successfully and your
           request number is  ${response.data.body.result
    .return_response.number}`
        })
      } else {
        setSupport({
          success: false,
          message: 'Something went wrong. Contact system administrator!'
        })
      }
      setPath({
        path: '/Support',
        component: SupportComponent
      })
      props.history.push('/Support')
    }, (error) => {
      setLoading(false)
      if (error) {
        setSupport({
          success: false,
          message: 'Something went wrong. Contact system administrator!'
        })
      }
    })
  }

  return (
    <div>
      <div>
        {showAlert()}
      </div>
      <div>
        {loading || submitLoader ? <LinearProgress /> : null}
        {' '}
        <Grid container spacing={1} className={classes.root}>
          <Grid item xs={12} md={12}>
            <Card variant='outlined'>
              <ValidatorForm
                ref={form}
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardContent>
                  <Typography variant='h6' gutterBottom display='block'>
                    Report an issue
                  </Typography>
                  <CardActions className={classes.pullRight}>
                    <Button
                      variant='contained'
                      component={Link}
                      to='/Support'
                      onClick={() => setPath({
                        path: '/Support',
                        component: SupportComponent
                      })}
                    >
                      Back
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      type='submit'
                      disabled={loading || submitLoader || user.switch_user}
                    >
                      Submit Request
                    </Button>
                  </CardActions>
                  <div style={{
                    paddingTop: 10
                  }}
                  >
                    <FormControl
                      className={`
                        ${classes.formControl} ${classes.columnDisplay}
                      `}
                    >
                      <Typography
                        htmlFor='requestForLabel'
                        variant='subtitle1'
                        display='block'
                        gutterBottom
                        className={classes.labelDisplay}
                      >
                        Request For
                        <span className={classes.error}> *</span>
                      </Typography>
                      <Autocomplete
                        id='request-for-auto-complete'
                        defaultValue={formData.requestFor}
                        className={classes.inputDisplay}
                        options={allUsers}
                        getOptionLabel={
                          (option) => (option.name
                            ? `${option.name} - ${option.displayname}`
                            : option)
                        }
                        loading={allUsers.length === 0 && loadingOptions}
                        getOptionSelected={(option,
                          userValue) => option.name === userValue.name}
                        onChange={(event, userValue) => changeUser(userValue)}
                        filterOptions={(options, state) => options}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            name='requestFor'
                            onChange={(event) => getUsers(event.target.value)}
                            value={formData.requestFor}
                            validators={['required']}
                            errorMessages={['This field is required']}
                          />
                        )}
                      />
                    </FormControl>

                    <FormControl
                      className={`
                        ${classes.formControl} ${classes.columnDisplay}
                      `}
                    >
                      <Typography
                        htmlFor='toolLabel'
                        className={classes.labelDisplay}
                      >
                        Tool
                        <span className={classes.error}> *</span>
                      </Typography>
                      <div style={{
                        width: '72%'
                      }}
                      >
                        <SelectValidator
                          fullWidth
                          name='toolName'
                          onChange={handleChange}
                          validators={['required']}
                          errorMessages={['This field is required']}
                          value={formData.toolNameDisplayValue
                            ? formData.toolNameDisplayValue
                            : ''}
                        >
                          {allTools.map((value, index) => (
                            <MenuItem value={value} key={value.toolname}>
                              {value.toolname}
                            </MenuItem>
                          ))}
                        </SelectValidator>
                      </div>
                    </FormControl>

                    <FormControl
                      className={`
                        ${classes.formControl} ${classes.columnDisplay}
                      `}
                    >
                      <Typography
                        htmlFor='shortDescriptionLabel'
                        className={classes.labelDisplay}
                      >
                        Short Description
                        <span className={classes.error}>*</span>
                      </Typography>
                      <div style={{
                        width: '72%'
                      }}
                      >
                        <TextValidator
                          name='shortDesc'
                          onChange={handleChange}
                          value={formData.shortDesc}
                          fullWidth
                          validators={['required']}
                          errorMessages={['This field is required']}
                        />
                      </div>
                    </FormControl>

                    <FormControl
                      className={`
                        ${classes.formControl} ${classes.columnDisplay}
                      `}
                    >
                      <Typography
                        htmlFor='descriptionLabel'
                        className={classes.labelDisplay}
                      >
                        Incident Details
                        <span className={classes.error}>*</span>
                      </Typography>
                      <div style={{
                        width: '72%'
                      }}
                      >
                        <TextValidator
                          name='description'
                          onChange={handleChange}
                          value={formData.description}
                          multiline
                          rows={1}
                          rowsMax={4}
                          fullWidth
                          validators={['required']}
                          errorMessages={['This field is required']}
                        />
                      </div>
                    </FormControl>

                    <FormControl
                      className={`
                        ${classes.formControl} ${classes.attachmentColDisplay}
                      `}
                    >
                      <Typography
                        variant='subtitle1'
                        className={classes.labelDisplay}
                      >
                        Attachments
                        {' '}
                        <small>
                          (
                          <i>File size should not exceed 10 MB </i>
                          )
                        </small>
                      </Typography>
                      <br />
                      <input
                        type='file'
                        name='fileUplaod'
                        id='fileUpload'
                        multiple
                        className={classes.labelDisplay}
                        // renderInput={(params) => params.join(', ')}
                        onChange={(event) => setFiles(files
                          .concat(Array.from(event.target.files)))}
                      />
                      {alertAttachment === true ? (
                        <span className={classes.error}>
                          Please attach file smaller than 10 MB.
                        </span>
                      ) : null}
                      <FileManager files={files}>
                        {uploadFiles}
                      </FileManager>
                    </FormControl>
                  </div>
                </CardContent>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
