import axiosInstance from '../../middleware/Interceptor'

function getProjects (value) {
  return axiosInstance
    .get('/api/v1/tempAccess/projects')
}
function versionCheckFromFile (project, instance) {
  return axiosInstance
    .get(`/api/v1/tempAccess/checkJenkinsVersion?project=${
      project}&instance=${instance}`)
}
function getTemporaryAccessHistory () {
  return axiosInstance
    .get('/api/v1/tempAccess/history')
}
function postTemporyAccess (value) {
  return axiosInstance
    .post('/api/v1/tempAccess/submit', value)
}
function getArtifactoryRepository (project) {
  return axiosInstance
    .get(`/api/v1/tempAccess/artifactoryrepo?project=${project}`)
}
function checkUserAccess (value) {
  return axiosInstance
    .post('/api/v1/tempAccess/checkuser', value)
}
const TemporayAccessServices = {
  getProjects,
  versionCheckFromFile,
  getTemporaryAccessHistory,
  postTemporyAccess,
  getArtifactoryRepository,
  checkUserAccess
}
export default TemporayAccessServices