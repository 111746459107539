import React, {
  useDebugValue
} from 'react'
import {
  makeStyles
} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import {
  Typography
} from '@material-ui/core'
import PropTypes from 'prop-types'


const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto'
  },
  paper: {
    width: '15vw',
    height: '72vh',
    overflow: 'auto',
    border: '1px dashed #ccc',
    'border-radius': '4px'
  },
  button: {
    margin: theme.spacing(0.5, 0)
  },
  nodata: {
    margin: 30,
    opacity: 0.4,
    paddingTop: '50%'
  }
}))

function not (a, b) {
  return a.filter((value) => !b.includes(value))
}

function intersection (a, b) {
  return a.filter((value) => b.includes(value))
}

export default function TransferList ({
  sourceData,
  targetData,
  setTargetData,
  setSourceData,
  disabled,
  selectedTool,
  selectedProject
}) {
  const classes = useStyles()
  const [checked, setChecked] = React.useState([])
  const leftChecked = intersection(checked, sourceData)
  const rightChecked = intersection(checked, targetData)

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleAllRight = () => {
    setTargetData(targetData.concat(sourceData))
    setSourceData([])
  }

  const handleCheckedRight = () => {
    setTargetData(targetData.concat(leftChecked))
    setSourceData(not(sourceData, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    setSourceData(sourceData.concat(rightChecked))
    setTargetData(not(targetData, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  const handleAllLeft = () => {
    setSourceData(sourceData.concat(targetData))
    setTargetData([])
  }

  const checkProjectKey = (value, selectedProject) => {
    let result = false
    const selectedProjectKey = selectedProject.key.toLowerCase()
    if (selectedTool.name.toLowerCase() === 'qtest' &&
    value.toLowerCase().indexOf('qtest') > -1) {
      const valueKey = value.split('-')[2].toLowerCase()
      if (valueKey === selectedProjectKey) {
        targetData.forEach((element) => {
          if (element.toLowerCase().indexOf(valueKey) > -1) {
            result = true
          }
        })
      }
    }
    return result
  }

  const customList = (items, left) => (
    <Paper className={classes.paper}>
      <List dense component='div' role='list'>
        {!items.length && left ? (
          <div className={classes.nodata}>
            Select project or user to select groups
          </div>
        ) : null}
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`

          return (
            <ListItem
              key={value}
              role='listitem'
              button
              disabled={disabled ||
                (selectedTool.name &&
                  selectedTool.name.toLowerCase() === 'qtest' &&
                  (checked.length && !checked.includes(value) &&
                  !checkProjectKey(value, selectedProject))
                ) || (selectedTool.name &&
                selectedTool.name.toLowerCase() === 'qtest' &&
                (targetData.length && !targetData.includes(value) &&
                checkProjectKey(value, selectedProject)))}
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.includes(value)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId
                  }}
                  disabled={disabled ||
                    (selectedTool.name &&
                      selectedTool.name.toLowerCase() === 'qtest' &&
                      (checked.length && !checked.includes(value) &&
                      !checkProjectKey(value, selectedProject))
                    ) || (selectedTool.name &&
                    selectedTool.name.toLowerCase() === 'qtest' &&
                    (targetData.length && !targetData.includes(value) &&
                    checkProjectKey(value, selectedProject)))}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          )
        })}
        <ListItem />
      </List>
    </Paper>
  )

  return (
    <Grid
      container
      spacing={2}
      justify='center'
      alignItems='center'
      className={classes.root}
    >
      <Grid item>
        <Typography variant='subtitle1' gutterBottom>Select a Group</Typography>
        {customList(sourceData, 'left')}
      </Grid>
      <Grid item>
        <Grid container direction='column' alignItems='center'>
          <Button
            variant='outlined'
            size='small'
            className={classes.button}
            onClick={handleAllRight}
            disabled={!sourceData.length ||
              (selectedTool.name &&
                selectedTool.name.toLowerCase() === 'qtest')}
            aria-label='move all right'
          >
            ≫
          </Button>
          <Button
            variant='outlined'
            size='small'
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={!leftChecked.length}
            aria-label='move selected right'
          >
            &gt;
          </Button>
          <Button
            variant='outlined'
            size='small'
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={!rightChecked.length}
            aria-label='move selected left'
          >
            &lt;
          </Button>
          <Button
            variant='outlined'
            size='small'
            className={classes.button}
            onClick={handleAllLeft}
            disabled={!targetData.length ||
              disabled ||
              (selectedTool.name &&
                selectedTool.name.toLowerCase() === 'qtest')}
            aria-label='move all left'
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant='subtitle1' gutterBottom>
          Groups to Include
        </Typography>
        {customList(targetData)}
      </Grid>
    </Grid>
  )
}

TransferList.propTypes = {
  sourceData: PropTypes.array.isRequired,
  targetData: PropTypes.array.isRequired,
  setSourceData: PropTypes.array.isRequired,
  setTargetData: PropTypes.array.isRequired,
  disabled: PropTypes.bool
}
