/* It contains all the API functions which is releated to login page */
import axios from 'axios'
import axiosInstance from '../../middleware/Interceptor'

const {
  CancelToken
} = axios
let cancel
// to get cart size of current user
function getDashboards (timeZone) {
  axiosInstance.defaults.headers.get.timezone = timeZone
  return axiosInstance.get('/api/v1/charts/getAvailableDashboards')
}
function saveDashboards (data) {
  return axiosInstance.post('/api/v1/charts/saveDashboards', data)
}
function deleteDashboards (id) {
  return axiosInstance.get(`/api/v1/charts/deleteDashboard?id=${id}`)
}
function getAllWidgets () {
  return axiosInstance.get('/api/v1/charts/loadAllWdigets')
}

function loadChildData (data) {
  return axiosInstance.post('/api/v1/charts/loadChildData', data)
}
function getChartData (data) {
  return axiosInstance.post('/api/v1/charts/getChartData', data)
}
function saveLayoutToDataBase (data) {
  if (cancel !== undefined) {
    cancel()
  }
  return axiosInstance.post('/api/v1/charts/updateDashboard', data,
    {
      cancelToken: new CancelToken((c) => {
        cancel = c
      })
    })
}
function loadSingleDashboard (id) {
  return axiosInstance.get(`/api/v1/charts/loadSingleDashboard?id=${id}`)
}
function getDashboardUsers (id) {
  return axiosInstance.get(`/api/v1/charts/getDashboardUsers?id=${id}`)
}
function saveRecentAndFavoriteDB (data) {
  return axiosInstance.post('/api/v1/charts/saveRecentAndFavoriteDB', data)
}

function checkDashboardNameExist (name) {
  return axiosInstance.get(`/api/v1/charts/checkDashboardNameExist?name=
${name}`)
}
function getRssLink (data, id, name) {
  data.dashboardname = name
  return axiosInstance.post(`/api/v2/rss/getRSSFeedLink?id=${id}`, data)
}


const DashboardService = {
  getDashboards,
  saveDashboards,
  deleteDashboards,
  getAllWidgets,
  loadChildData,
  getChartData,
  saveLayoutToDataBase,
  loadSingleDashboard,
  getDashboardUsers,
  saveRecentAndFavoriteDB,
  checkDashboardNameExist,
  getRssLink
}

export default DashboardService
