import 'date-fns'
import React, {
  useContext
} from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  TextField,
  Chip
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import {
  Autocomplete
} from '@material-ui/lab'
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import requestaccess from '../../services/api/requestaccessService'
import AlertDialog from '../alertbox/alertboxComponent'
import UserContext from '../contexts/UserContext'

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  active_row: {
    backgroundColor: '#d9ffcc'
  },
  bodycell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  tablehead: {
    fontWeight: 'bold'

  },
  cellWarning: {
    color: 'red'
  }
})

function DelegateTable ({
  rowsData, headCells, editDelegate, saveDelegate, cancelDelegate,
  editIdx, changeUser, handleDateChange, tabValue, directReports, changeUser1
}) {
  const classes = useStyles()
  const [rows, setRows] = React.useState(rowsData)
  const [loadingOptionsUser, setLoadingOptionsUser] = React.useState(false)
  const [allUsers, setAllUsers] = React.useState([])
  const [alertbox, setAlerbox] = React.useState(false)
  const [editAlertbox, setEditAlerbox] = React.useState(false)
  const [validationError, setValidationError] = React.useState(false)
  const [datevalidationerror, setDateValidationError] = React.useState(false)
  const {
    user
  } = useContext(UserContext)

  const editAlertMessage = `You already have unsaved delegate record. 
    Do you want to save?`
  const alertboxMessage = `Before delegating the authority, please verify 
    the delegate user has the necessary experience and training on performing 
    the activities associated with granting access`

  // Cancel for Delegation Alert
  const handleClose = () => {
    setAlerbox(false)
  }

  // Clear start date and end date modle while cancelling
  const handleCancelDelegate = (editidx) => {
    cancelDelegate(editIdx)
    setValidationError(false)
  }

  // Cancel for already edited row Alert
  const handleEditAlertClose = () => {
    setEditAlerbox(false)
    handleCancelDelegate(editIdx)
  }

  // Show alert when editing a row if already a row is in Edit mode
  const confirmEditAlert = () => {
    setEditAlerbox(false)
    handleSaveDelegate()
  }

  const handleSaveDelegate = () => {
    if (tabValue !== 2) {
      rows[editIdx].username = user.name
      setRows(rows)
    }

    if (rows[editIdx].start_date &&
      rows[editIdx].end_date &&
      rows[editIdx].delegates.length &&
      rows[editIdx].username && !datevalidationerror) {
      setValidationError(false)
      setAlerbox(true)
    } else if (!datevalidationerror) {
      setValidationError(true)
    }
  }

  // Save the edited Row when edit another row
  const confirmbutton = () => {
    setAlerbox(false)
    saveDelegate(editIdx)
  }

  // Show Alert When editing a row OR Directly allow user to edit if there is no row is in edit
  const editAlert = (rowsArray, index) => {
    if (editIdx != null) {
      setEditAlerbox(true)
    } else {
      editDelegate(index)
    }
  }

  const setValuenull = (value) => {
    value.splice(0, 1)
    value = []
  }

  // Get Icon in UI whether Edit Icon or Action Icons
  const getIcons = (rowsArray, index) => {
    if (editIdx === index) {
      return (
        <div>
          <IconButton onClick={() => handleSaveDelegate()}>
            {' '}
            <CheckIcon color='primary' />
            {' '}
          </IconButton>
          <IconButton onClick={() => handleCancelDelegate(index)}>
            {' '}
            <CloseIcon color='primary' />
            {' '}
          </IconButton>
        </div>
      )
    }

    return (
      <IconButton onClick={() => editAlert(rowsArray, index)}>
        {' '}
        <EditIcon color='primary' />
        {' '}
      </IconButton>
    )
  }

  // API call to get users for auto complete text box
  const getUsers = (value) => {
    setLoadingOptionsUser(true)
    requestaccess.getallusersforautocomplete(value).then((response) => {
      setAllUsers(response.data)
      setLoadingOptionsUser(false)
    })
  }
  const checkError = (error, value) => {
    if (error !== '') {
      setDateValidationError(true)
    } else {
      setDateValidationError(false)
    }
  }

  // UI for Delegation row when the row is in edit mode
  function getEditValuesOfEachRow (row, heading, index) {
    switch (heading.id) {
    case 'start_date_use': return (
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            value={row.start_date}
            onChange={(date) => handleDateChange(date, index, heading.id)}
            disablePast
            maxDate={row.end_date}
            format='yyyy/MM/dd hh:mm a'
            showTodayButton
            onError={(error, value) => checkError(error, value)}
          />
        </MuiPickersUtilsProvider>
        <br />
        {validationError && !row.start_date
          ? (
            <span className={classes.cellWarning}>
              Start date is required
            </span>
          ) : null}
      </div>
    )
    case 'end_date_use': return (
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            value={row.end_date}
            onChange={(date) => handleDateChange(date, index, heading.id)}
            minDate={row.start_date}
            format='yyyy/MM/dd hh:mm a'
            showTodayButton
          />
        </MuiPickersUtilsProvider>
        <br />
        {
          validationError && !row.end_date
            ? (
              <span className={classes.cellWarning}>
                End date is required
              </span>
            ) : null
        }
      </div>
    )
    case 'username': return (
      <div>
        <Autocomplete
          id='combo-box-demo-modeluser'
          value={row.username}
          options={directReports}
          getOptionLabel={(option) => option}
          onChange={(event, value) => changeUser1(value, index)}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Choose a user'
              name='usertomodel'

            />
          )}
        />
        <br />
        {validationError && !row.username
          ? (
            <span className={classes.cellWarning}>
              Select any of your direct reports
            </span>
          ) : null}
      </div>
    )
    case 'delegates': return (
      <div>
        <Autocomplete
          id='combo-box-demo'
          multiple
          options={allUsers}
          defaultValue={row.delegates}
          getOptionLabel={(option) => (option.name
            ? option.name
            : option)}

          loading={allUsers.length === 0 && loadingOptionsUser}
          getOptionSelected={(option, value) => option.name === value.name}
          filterOptions={(options, state) => options}
          renderTags={
            (value, getTagProps) => value.map((option,
              delagateUserIndex) => (row.delegates.length > 0 ? (
              <Chip
                label={
                  option.name
                    ? option.name
                    : option
                }
                {...getTagProps({
                  delagateUserIndex
                })}
              />
            ) : (
              setValuenull(value)
            )))
          }
          onChange={(event, value) => changeUser(value, index)}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Choose a user *'
              name='username'
              onChange={(event) => getUsers(event.target.value)}
              value={row.delegates}

            />
          )}
        />
        {validationError && !row.delegates.length
          ? (
            <span className={classes.cellWarning}>
              Select a minimum of one user
            </span>
          ) : null}
      </div>
    )
    default: return (
      <div>{row[heading.id]}</div>
    )
    }
  }
  function getValuesOfEachRow (row, heading) {
    return heading.id === 'delegates' ? row.delegatesdisplay : row[heading.id]
  }
  // UI to show the editable row OR Non editable row in Delegates table
  const getRowItems = (row, heading, index) => (
    <div>
      {editIdx === index ? getEditValuesOfEachRow(row, heading, index)
        : getValuesOfEachRow(row, heading)}
    </div>
  )

  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              {headCells.map((head) => (
                <TableCell
                  key={head.label}
                  align='left'
                  className={classes.tablehead}
                >
                  {head.label}
                </TableCell>
              ))}

            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={row.name}
                className={
                  row.status && row.status === 'Active'
                    ? classes.active_row
                    : null
                }
              >
                {headCells.map((heading) => (
                  <TableCell
                    key={heading.label}
                    align='left'
                    className={
                      heading.edit ? classes.bodycell : null
                    }
                  >

                    {heading.edit
                      ? getIcons(rows, index)
                      : getRowItems(row, heading, index)}
                  </TableCell>
                ))}

              </TableRow>
            ))}
          </TableBody>
        </Table>
        <AlertDialog
          handleClose={handleClose}
          alertopen={alertbox}
          message={alertboxMessage}
          confirmbutton={confirmbutton}
          key='alert1'
          title='Alert'
          okbuttonalert={false}
        />
        <AlertDialog
          handleClose={handleEditAlertClose}
          alertopen={editAlertbox}
          message={editAlertMessage}
          confirmbutton={confirmEditAlert}
          key='alert2'
          title='Alert'
          okbuttonalert={false}
        />
      </TableContainer>
    </div>
  )
}

DelegateTable.protoTypes = {
  editIdx: PropTypes.string,
  editDelegate: PropTypes.func,
  cancelDelegate: PropTypes.func,
  saveDelegate: PropTypes.func,
  rowsData: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
  handleDateChange: PropTypes.func,
  changeUser: PropTypes.func,
  tabValue: PropTypes.any,
  directReports: PropTypes.array,
  changeUser1: PropTypes.func
}

export default DelegateTable
