import axiosInstance from '../../middleware/Interceptor'

// Service method to Get table data for Delegations tab
function sendMailtoApprover (input, requesttype) {
  let endpoint = '/api/sendMailtoApprover'
  if (requesttype) {
    endpoint += `?requesttype=${requesttype}`
  }
  return axiosInstance.post(endpoint, input)
}

// Service method to Get table data for Delegations tab
function sendMailtoSender (comments, action, input) {
  return axiosInstance.post(`/api/sendMailtoSender?action=
${action}&comments=${comments}`, input)
}

const EmailServices = {
  sendMailtoApprover,
  sendMailtoSender
}

export default EmailServices
