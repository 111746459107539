import React, {
  useContext
} from 'react'
import {
  Avatar,
  Button,
  TextField,
  Typography,
  Container,
  LinearProgress,
  Box,
  CssBaseline,
  makeStyles
} from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import {
  withRouter
} from 'react-router-dom'
import parse from 'html-react-parser'
import cookies from 'js-cookie'
import logo from '../../assets/images/AppDev_Full_Logo.png'
import LoginService from '../../services/api/loginService'
import UserContext from '../contexts/UserContext'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    backgroundColor: '#fff'
  },
  image: {
    backgroundColor: theme.palette.primary.dark,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  heading: {
    paddingTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginBottom: 0
  },
  subheading: {
    marginLeft: theme.spacing(7)
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  loader_root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}))

function LoginComponent (props) {
  const {
    user, setUser
  } = useContext(UserContext)
  const classes = useStyles()
  const [username, setusername] = React.useState(null)
  const [password, setpassword] = React.useState(null)
  const [loader, setloader] = React.useState(false)
  const [error, seterror] = React.useState(false)

  React.useEffect(() => {
    LoginService.getGitdetails().then((response) => {
      LoginService.getBannerData().then((response1) => {
        setUser({
          ...user,
          gitdetails: response.data.gitcommit,
          helpurl: response.data.help_page,
          clone_repo_page: response.data.clone_repo_page,
          project_creation: response.data.project_creation,
          request_access_page: response.data.request_access_page,
          bannerdata: response1.data[0],
          riskurl: response.data.risk_assessment_page
        })
      })
    })

    if (cookies.get('token') && cookies.get('username')) {
      LoginService.checkValidation().then((response) => {
        props.history.push('/home')
      })
    }
  }, [])
  // Set tab change values
  const parseHtml = (htmlMessage) => parse(htmlMessage)

  const handleSubmit = (event) => {
    event.preventDefault()
    let from
    if (cookies.get('returnUrl') &&
      cookies.get('returnUrl') !== '/login' &&
      cookies.get('returnUrl') !== '/') {
      from = cookies.get('returnUrl')
    } else {
      from = '/home'
    }
    setloader(true)
    LoginService.login(username.trim(), password).then((response) => {
      setloader(false)
      seterror(false)
      cookies.set('token', response.data.token)
      cookies.set('username', username)
      setUser({
        ...user,
        token: response.data.token,
        name: username
      })
      props.history.push(from)
    },
    (loginError) => {
      if (loginError) {
        setloader(false)
        seterror(true)
      }
    })
  }
  return (
    <div className={classes.root}>
      {user.bannerdata &&
        user.bannerdata.visibility &&
        <div>{parseHtml(user.bannerdata.message)}</div>}
      {loader ? <LinearProgress /> : null}
      {' '}
      <Typography variant='h4' gutterBottom className={classes.heading}>
        <img
          src={logo}
          width='300'
          height='100'
          alt=''
        />
      </Typography>
      <Typography
        variant='subtitle2'
        gutterBottom
        className={classes.subheading}
      >
        Gain access to the Agile application development platform.
        {' '}
        <a
          className={classes.helptag}
          target='_blank'
          href={user.helpurl}
          rel='noreferrer'
        >
          Learn More
        </a>
      </Typography>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />

        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            {' '}
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='username'
              label='Username'
              name='username'
              autoComplete='username'
              value={username}
              onChange={(event) => setusername(event.target.value)}
              autoFocus
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              value={password}
              onChange={(event) => setpassword(event.target.value)}
              autoComplete='current-password'
            />
            {error === true ? (
              <span style={{
                color: 'red'
              }}
              >
                Username or Password are Incorrect.
              </span>
            ) : null}
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              disabled={loader}
            >
              Log In
            </Button>
            <Box mt={12} align='center'>
              <Typography variant='caption' color='textSecondary'>
                {user.gitdetails}
              </Typography>
            </Box>
          </form>
        </div>
      </Container>
    </div>
  )
}
export default withRouter(LoginComponent)
