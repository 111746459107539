import React, {
  useRef
} from 'react'
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  makeStyles,
  FormControl,
  LinearProgress,
  List,
  ListItem,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import RadioButtons from '../inputs/radio'
import OwnershipServices from '../../services/api/ownershipServices'
import requestAccess from '../../services/api/requestaccessService'
import AlertDialog from '../alertbox/alertboxComponent'

const useStyles = makeStyles((theme) => ({
  inputDisplay: {
    width: '100%'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  },
  uitable: {
    maxHeight: 300,
    overflow: 'auto'
  },
  formControl1: {
    margin: theme.spacing(1),
    width: 500
  }
}))

export default function ArchivedProjectUsersComponent () {
  const [loading, setLoading] = React.useState(false)
  const [successAlert, setsuccessAlert] = React.useState(false)
  const [loadingbar, setLoadingBar] = React.useState(false)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [failalertMessage, setFailalertMessage] = React.useState(null)
  const [successalertMessage, setSuccessalertMessage] = React.useState(null)
  const [allProjects, setAllProjects] = React.useState([])
  const [projectGroups, setProjectGroups] = React.useState([])
  const [loadingOptions, setLoadingOptions] = React.useState(false)
  const [allUsers, setAllUsers] = React.useState([])
  const classes = useStyles()
  const form = useRef(null)
  const [alertOpen, setAlertOpen] = React.useState(false)
  const [alert2Open, setAlert2Open] = React.useState(false)
  const [alert3Open, setAlert3Open] = React.useState(false)
  const [alertboxMessage, setalertboxMessage] = React.useState(null)
  const [newOwner, setNewOwner] = React.useState(null)
  const [selectedGroups, setSelectedGroups] = React.useState([])
  const [radioValue, setRadioValue] = React.useState('Archive')

  const [formData, setFormData] = React.useState({
    projectKey: '',
    selectedGroups: []
  })

  const resetForm = () => {
    setFormData({
      projectKey: '',
      selectedGroups: []
    })
    setAllProjects([])
    setProjectGroups([])
    setSelectedGroups([])
    seterrorAlert(false)
    setRadioValue('Archive')
  }

  // Change radio button
  const changeOption = (value) => {
    resetForm()
    setRadioValue(value)
  }
  // Get all projects irrespective of tools
  const getProjects = (value) => {
    setLoadingOptions(true)
    if (radioValue === 'Archive') {
      OwnershipServices.getAllProjects(value).then((response) => {
        setAllProjects(response.data)
        setLoadingOptions(false)
      })
    }
    if (radioValue === 'Restore') {
      OwnershipServices.getArchivedProjects(value).then((response) => {
        setAllProjects(response.data)
        setLoadingOptions(false)
      })
    }
  }

  // Handles when project value changes
  const changeProject = (value) => {
    if (value) {
      setProjectGroups([])
      setLoading(true)
      if (radioValue === 'Archive') {
        OwnershipServices.getGroupsToArchive(value.key).then((response) => {
          setLoading(false)
          if (response.status === 200) {
            setProjectGroups(response.data)
            setFormData({
              ...formData,
              projectKey: value.key
            })
            setAllProjects([])
          } else {
            setLoading(false)
            setFailalertMessage(`
              Something went wrong. Contact system administrator!
            `)
            seterrorAlert(true)
          }
        })
      }
      if (radioValue === 'Restore') {
        OwnershipServices.getArchivedGroups(value.key).then((response) => {
          setLoading(false)
          if (response.status === 200) {
            setProjectGroups(response.data)
            setFormData({
              ...formData,
              projectKey: value.key
            })
            setAllProjects([])
          } else {
            setLoading(false)
            setFailalertMessage(`
              Something went wrong. Contact system administrator!
            `)
            seterrorAlert(true)
          }
        })
      }
    }
  }

  const openrestore = () => {
    setAlertOpen(false)
    if (radioValue === 'Restore') {
      setAlert3Open(true)
    }
    if (radioValue === 'Archive') {
      handleChange()
    }
  }
  // Handles Submit functions
  const handleSubmit = (event) => {
    if (selectedGroups.length === 0) {
      setAlert2Open(true)
    } else {
      setAlertOpen(true)
      if (radioValue === 'Restore') {
        setalertboxMessage(`
          Clicking 'Yes' leads to grant the access for users in the groups
          selected. Are you sure to get access for those users?
        `)
      }
      if (radioValue === 'Archive') {
        setalertboxMessage(`
          Clicking 'Yes' leads to revoke the access for users in the groups
          selected. Want to continue still?
        `)
      }
    }
  }

  /* to close the alert box */
  const handleClose = () => {
    setAlertOpen(false)
    setAlert2Open(false)
    setAlert3Open(false)
  }
  /* get Roles based on project key selection */
  const changeUser = (value) => {
    setNewOwner(value)
  }
  /* function to get the users list based on the search key */
  const getUsers = (username) => {
    // setFormData({ ...formData, searchkey: username })
    setLoadingBar(true)
    requestAccess.getallusersforautocomplete(username).then((response) => {
      setLoadingBar(false)
      setAllUsers(response.data)
    })
  }

  const handleChange = () => {
    setAlertOpen(false)
    const array = selectedGroups
    if (array.indexOf('all_group') > -1) {
      array.splice(array.indexOf('all_group'), 1)
    }
    const input = {
      project: formData.projectKey,
      groups: selectedGroups,
      type: radioValue
    }
    setLoading(true)
    if (radioValue === 'Archive') {
      OwnershipServices.archiveUsers(input).then((response) => {
        setLoading(false)
        if (response.status === 200) {
          resetForm()
          setSuccessalertMessage(`
            Access for the users under the groups selected was revoked
            successfully. You can restore later
          `)
          setsuccessAlert(true)
          setTimeout(() => {
            setsuccessAlert(false)
          }, 20000)
        } else {
          setLoading(false)
          setFailalertMessage(`
            Something went wrong. Contact system administrator!
          `)
          seterrorAlert(true)
        }
      })
    }
    if (radioValue === 'Restore') {
      setAlert3Open(false)
      input.newOwner = newOwner.name
      OwnershipServices.restoreUsers(input).then((response) => {
        setLoading(false)
        if (response.status === 200) {
          resetForm()
          setSuccessalertMessage(`
            Initiated the request to get accessing the
            groups for users who stored when archiving the selected group
          `)
          setsuccessAlert(true)
          setTimeout(() => {
            setsuccessAlert(false)
          }, 20000)
        } else {
          setLoading(false)
          setFailalertMessage(`
            Something went wrong. Contact system administrator!
          `)
          seterrorAlert(true)
        }
      })
    }
  }

  const clickCheckbox = (role, value, index) => {
    if (role.group_name === 'all_group' && value.toString() === 'true') {
      const valuedarray = []
      valuedarray.push('all_group')
      projectGroups.map((item) => {
        valuedarray.push(item.group_name)
      })
      setSelectedGroups(valuedarray)
    } else if (role.group_name === 'all_group' &&
      value.toString() === 'false') {
      const emptyarray = []
      setSelectedGroups(emptyarray)
    } else {
      if (selectedGroups.includes(role.group_name)) {
        selectedGroups.splice(selectedGroups.indexOf(role.group_name), 1)
      } else {
        selectedGroups.push(role.group_name)
      }
      setSelectedGroups([...selectedGroups])
    }
  }

  return (
    <div>
      {loading ? <LinearProgress /> : null}
      <div className={classes.alertroot}>
        {' '}
        {successAlert ? (
          <Alert severity='success'>{successalertMessage}</Alert>
        ) : null}
        {errorAlert ? (
          <Alert
            severity='error'
            onClose={() => seterrorAlert(false)}
          >
            {failalertMessage}
          </Alert>
        ) : null}
      </div>
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={12} md={5}>
          <Card variant='outlined'>
            <ValidatorForm ref={form} onSubmit={(event) => handleSubmit(event)}>
              <CardContent>
                <Typography variant='subtitle1' gutterBottom>
                  Archive / Restore Project Users
                </Typography>
                <br />
                <RadioButtons
                  radio={['Archive', 'Restore']}
                  selectedvalue={radioValue}
                  onChange={(value) => changeOption(value)}
                />
                <div style={{
                  paddingTop: 10
                }}
                >
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id='combo-box-key'
                      options={allProjects}
                      value={formData.projectKey}
                      getOptionLabel={(option) => (option.key
                        ? option.key
                        : option)}
                      getOptionSelected={(option, value) => option.name ===
                        value.name}
                      onChange={(event, value) => changeProject(value)}
                      loading={allProjects.length === 0 && loadingOptions}
                      renderInput={(params) => (
                        <TextValidator
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          label='Project or Team'
                          name='project-key'
                          value={formData.projectKey}
                          onChange={(event) => getProjects(event.target.value)}
                          validators={['required']}
                          errorMessages={['Please select any project']}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </CardContent>
              <CardActions className={classes.footer}>
                <Button
                  variant='contained'
                  size='small'
                  onClick={resetForm}
                >
                  Reset
                </Button>

                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  disabled={loading || !projectGroups.length}
                  size='small'
                  onClick={handleSubmit}
                >
                  Submit Now
                </Button>
              </CardActions>
            </ValidatorForm>
          </Card>
        </Grid>
        {projectGroups.length ? (
          <Grid item xs={12} md={7}>
            <Card variant='outlined'>
              <CardContent>
                <Typography variant='subtitle1' gutterBottom>
                  Available Roles
                </Typography>
                {projectGroups.length
                  ? (
                    <List
                      component='nav'
                      aria-label='main mailbox folders'
                      className={classes.uitable}
                    >
                      <ListItem key='all'>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              name='checkedA'
                              value='all'
                              checked={selectedGroups.indexOf('all_group') > -1}
                              label='All Groups'
                              onChange={(event, value) => clickCheckbox({
                                group_name: 'all_group',
                                id: '0'
                              }, value, -1)}
                            />
                          )}
                        />
                        {' '}
                        All groups
                      </ListItem>
                      {projectGroups.map((role, i) => (
                        <ListItem
                          key={role.group_name}
                        >
                          <FormControlLabel
                            control={(
                              <Checkbox
                                name='checkedA'
                                value={role}
                                checked={selectedGroups
                                  .indexOf(role.group_name) > -1}
                                label={role.group_name}
                                onChange={(event, value) => clickCheckbox(role,
                                  value, i)}
                              />
                            )}
                          />
                          {role.group_name}

                        </ListItem>
                      ))}
                    </List>
                  )
                  : <p>No roles available</p>}
              </CardContent>
            </Card>
          </Grid>
        ) : null}
      </Grid>
      <AlertDialog
        handleClose={handleClose}
        alertopen={alertOpen}
        message={alertboxMessage}
        confirmbutton={openrestore}
        key='alert1'
        okbuttonalert={false}
        title='Warning'
      />
      <AlertDialog
        handleClose={handleClose}
        alertopen={alert2Open}
        message='Please select a minimum of one role'
        key='alert2'
        okbuttonalert
        title='No roles selected'
      />
      <Dialog
        onClose={handleClose}
        aria-labelledby='simple-dialog-title'
        open={alert3Open}
      >
        <ValidatorForm ref={form}>

          <DialogTitle id='alert-dialog-title'>
            Select new owner
          </DialogTitle>
          <DialogContent>

            <FormControl className={classes.formControl1}>
              <Autocomplete
                id='choose-user-auto-complete'
                value={formData.chooseUser}
                className={classes.inputDisplay}
                options={allUsers}
                getOptionLabel={(option) => (option.name
                  ? `${option.name} - ${option.displayname}`
                  : option)}
                loading={allUsers.length === 0 && loadingbar}
                filterOptions={(options, state) => options}
                getOptionSelected={(option, userValue) => option.name ===
                  userValue.name}
                onChange={(event, userValue) => changeUser(userValue)}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name='chooseUser'
                    onChange={(event) => getUsers(event.target.value)}
                    value={formData.chooseUser}
                    label='Choose a user '
                    validators={['required']}
                    errorMessages={['This field is required']}
                  />
                )}
              />
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} color='default'>
              Cancel
            </Button>
            <Button
              onClick={handleChange}
              color='primary'
              disabled={!newOwner || newOwner === ''}
            >
              Submit
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </div>
  )
}
