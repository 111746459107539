/* It contains all the API functions which is releated to login page */

import axiosInstance from '../../middleware/Interceptor'

function getBBProjects4LoggedInUserIsOwner () {
  return axiosInstance
    .get('/api/v1/githubcopilotactivation/getBBProjects4LoggedInUserIsOwner')
}

function checkProjectAlreadyActivatedInGitHubCopilot (key) {
  return axiosInstance
    .get(`/api/v1/githubcopilotactivation/checkProjectStatus?key=${key}`)
}

function submitRequest (input) {
  return axiosInstance
    .post('/api/v1/githubcopilotactivation/submitGithubGroupActivation', input)
}

const GitHubCopilotActivationService = {
  getBBProjects4LoggedInUserIsOwner,
  checkProjectAlreadyActivatedInGitHubCopilot,
  submitRequest
}

export default GitHubCopilotActivationService