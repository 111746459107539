
/* It contains all the API functions which is releated to Clone repository section */

import axiosInstance from '../../middleware/Interceptor'
/* To get the repositories for project */
function getRepos4Project (project) {
  return axiosInstance.get(`/api/v1/bitbucket/getRepos4Project/${project}`)
}
/* To get ssh keys for user */
function createRepo (input) {
  return axiosInstance.post('/api/v1/bitbucket/createRepoBasedOnTemplate',
    input)
}

function getBBUsersAndGroups (data) {
  return axiosInstance.post('/api/v1/bitbucket/bbUsersAndGroups', data)
}

function getRepoBranches (project, repo) {
  return axiosInstance.get(`/api/v1/bitbucket/getRepoBranches/
${project}/${repo}`)
}

function getBranchModels (project, repo) {
  return axiosInstance.get(`/api/v1/bitbucket/getBranchModel/
${project}/${repo}`)
}

const CloneRepoService = {
  getRepos4Project,
  createRepo,
  getBBUsersAndGroups,
  getRepoBranches,
  getBranchModels
}

export default CloneRepoService
