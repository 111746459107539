/* It contains all the API functions which is releated to Support section */
import axios from 'axios'
import axiosInstance from '../../middleware/Interceptor'

const {
  CancelToken
} = axios
let cancel

function getRoles (role) {
  if (cancel !== undefined) {
    cancel()
  }

  return axiosInstance.get(`/api/v1/requestaccess/getGroupsbySearchkey
?searchkey=${role}`, {
    cancelToken: new CancelToken((c) => {
      cancel = c
    })
  })
}

function applyPermissionForGroup (input) {
  return axiosInstance.post('/api/v1/bitbucket/repoPermissionForGroups', input,
    {
      // empty for reason
    })
}

const RepoPersmissionService = {
  applyPermissionForGroup,
  getRoles
}

export default RepoPersmissionService
