/* This file is used to check the user validation to access all pages in ADP
 * For every routing there is an API (checkValidation) will be called
  before routing to particular page.
 * If the user is not a logged in user, then the page will be redirected to login page
 */

import React, {
  Component
} from 'react'
import {
  Route
} from 'react-router-dom'
import PropTypes from 'prop-types'
import LoginService from '../services/api/loginService'

class PrivateRoute extends Component {
  constructor (props, context) {
    super(props, context)
    this.state = {
      isLoading: true,
      isLoggedIn: false
    }
  }

  /*  This method is available after the component has mounted.
  That is after the HTML from render has finished loading. */
  componentDidMount () {
    LoginService.checkValidation().then(() => {
      this.setState({
        isLoading: false,
        isLoggedIn: true
      })
    },
    () => {
      this.setState(() => ({
        isLoading: false,
        isLoggedIn: false
      }))
    })
  }

  render () {
    const {
      isLoading, isLoggedIn
    } = this.state
    const {
      component, exact, path
    } = this.props

    if (isLoading) {
      return null
    }
    if (isLoggedIn) {
      return (
        <div>
          <Route path={path} component={component} exact={exact} />
        </div>
      )
    }
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  exact: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired
}
export default PrivateRoute
