import React, {
  useContext, useRef
} from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  createStyles,
  makeStyles,
  FormControl,
  LinearProgress,
  Theme
} from '@material-ui/core'
import {
  ValidatorForm, TextValidator
} from 'react-material-ui-form-validator'
import {
  Alert
} from '@material-ui/lab'
import UserContext from '../contexts/UserContext'
import SshAndGpgKeyService from '../../services/api/sshAndGpgKeyService'

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  success: {
    backgroundColor: theme.palette.success.light
  },
  pending: {
    backgroundColor: '#fff9c4'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  }
}))

export default function GetUserFromGPGComponent () {
  const [loading, setLoading] = React.useState(false)
  const [loadingbar, setLoadingBar] = React.useState(false)
  const [successAlert, setsuccessAlert] = React.useState(false)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [failalertMessage, setFailalertMessage] = React.useState(null)
  const [successalertMessage, setSuccessalertMessage] = React.useState(null)
  const [allProjects, setAllprojects] = React.useState([])
  const [allRoles, setAllroles] = React.useState([])
  const [allUsers, setAllUsers] = React.useState([])
  const [selectedGroups, setSelectedGroups] = React.useState([])
  const [alertbox, Setalertbox] = React.useState(false)
  const [oneGroupAlert, setOneGroupAlert] = React.useState(false)
  const [ouAlertbox, SetOuAlertbox] = React.useState(false)
  const [keyPrefix, setKeyPrefix] = React.useState(null)
  const [realKey, setRealKey] = React.useState(null)
  const [ouAlertMessage, setOuAlertMessage] = React.useState(null)
  const [alertBoxMessage, setAlertBoxMessage] = React.useState(null)
  const [alertopen, setAlertopen] = React.useState(false)
  const classes = useStyles()
  const form = useRef(null)
  const {
    user
  } = useContext(UserContext)
  const [gpgUser, setGpgUser] = React.useState([])
  const [userAlert, setUserAlert] = React.useState(false)
  const [noUserAlert, setNoUserAlert] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(null)
  const [formData, setFormData] = React.useState({
    gpgKeyID: ''
  })

  // const handleSubmit = (event) => {
  // }

  const resetForm = () => {
    setFormData({
      gpgKeyID: ''
    })
    setUserAlert(false)
    setGpgUser([])
    setNoUserAlert(false)
  }

  const getUsernameForGPGID = () => {
    setLoadingBar(true)
    SshAndGpgKeyService.getUsernameFromGPGID(formData.gpgKeyID)
      .then((response) => {
        setLoadingBar(false)
        if (Array.isArray(response.data) && response.data.length > 0) {
          setUserAlert(true)
          setGpgUser(response.data[0])
        } else {
          setNoUserAlert(true)
          setErrorMessage('No data found for the GPG ID provided')
        }
      },
      (error) => {
        if (error) {
          setLoadingBar(false)
          seterrorAlert(true)
        }
      })
  }

  const handleGPGIDChange = (event) => {
    setFormData({
      ...formData,
      gpgKeyID: event.target.value
    })
  }

  return (
    <div>
      {loading ? <LinearProgress /> : null}
      {' '}
      <div className={classes.alertroot}>
        {' '}
        {successAlert ? (
          <Alert severity='success'>{successalertMessage}</Alert>
        ) : null}
        {errorAlert ? (
          <Alert
            severity='error'
            onClose={() => seterrorAlert(false)}
          >
            {failalertMessage}
          </Alert>
        ) : null}
      </div>
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={12} md={7}>
          <Card variant='outlined'>
            <ValidatorForm
              ref={form}
              onSubmit={
                (event) => getUsernameForGPGID()
              }
            >
              <CardContent>
                <Typography variant='subtitle1' gutterBottom>
                  Get Username for GPG ID
                </Typography>
                <div>
                  <FormControl className={classes.formControl}>
                    <TextValidator
                      label='Enter GPG ID *'
                      name='gpgID'
                      onChange={handleGPGIDChange}
                      value={formData.gpgKeyID}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />
                  </FormControl>
                </div>
                {userAlert ? (
                  <Typography variant='subtitle1' gutterBottom>
                    GPG ID is belongs to
                    {' '}
                    {gpgUser.username}
                    {' '}
                    (
                    {gpgUser.displayname}
                    )
                  </Typography>
                ) : null}
                {noUserAlert ? (
                  <Typography variant='subtitle1' gutterBottom>
                    {errorMessage}
                  </Typography>
                ) : null}
              </CardContent>
              <CardActions className={classes.footer}>
                <Button
                  variant='contained'
                  size='small'
                  onClick={resetForm}
                >
                  Reset
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  size='small'
                  disabled={loading || formData.gpgKeyID === ''}
                >
                  Get Username
                </Button>
              </CardActions>
            </ValidatorForm>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}