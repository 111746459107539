/* eslint indent: 0 */
import React, {
  useContext
} from 'react'
import {
  Button, makeStyles, LinearProgress
} from '@material-ui/core'
import {
  Link
} from 'react-router-dom'
import PathContext from '../contexts/PathContext'
import EnhancedTable from '../sections/enhancedTable'
import TemplateService from '../../services/api/templateService'
import TemplateDetailsComponent from
  '../templatedetails/templateDetailsComponent'
import UserContext from '../contexts/UserContext'

const headCells = [
  {
    id: 'id',
    numeric: true,
    label: 'Template ID'
  },
  {
    id: 'name',
    numeric: false,
    label: 'Template Name'
  },
  {
    id: 'created_by',
    numeric: false,
    label: 'Created By',
    type: 'username'
  },
  {
    id: 'created_on_sort',
    type: 'date',
    numeric: false,
    label: 'Created On'
  },
  {
    id: 'last_modified_by',
    numeric: false,
    label: 'Last Modified By',
    type: 'username'
  },
  {
    id: 'last_modified_on_sort',
    type: 'date',
    numeric: false,
    label: 'Last Modified On'
  },
  {
    id: 'active',
    numeric: false,
    label: 'Status'
  },
  {
    id: 'privacy',
    numeric: false,
    label: 'Privacy'
  }
]

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  nodatatext: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(8),
    textAlign: 'center'
  },
  createbtn: {
    width: '95%'
  }
}))

export default function MyTemplatesComponent () {
  const {
    user
  } = useContext(UserContext)
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const [mytemplates, setMyTemplates] = React.useState([])
  const {
    path, setPath
  } = useContext(PathContext)

  /* initial call when page gets loaded */
  React.useEffect(() => {
    setLoading(true)
    TemplateService.getTemplates(user.name, 'mytemplate').then((response) => {
      const {
        data
      } = response
      data.map((item, i) => {
        data[i].active = item.active === 'y' ? 'Active' : 'Inactive'
        data[i].name2 = data[i].name
        data[i].name = (
          <Link
            aria-label='item.name'
            color='primary'
            to={`/mytemplates/template/${item.id}`}
            onClick={() => {
              setPath({
                pathname: '/mytemplates/template/:id',
                component: TemplateDetailsComponent
              })
            }}
          >
            {item.name}
          </Link>
        )
      })
      setMyTemplates(response.data)
      setLoading(false)
    })
  }, [])

  return (
    <div className={classes.root}>
      {loading ? <LinearProgress /> : null}
      <div className={classes.createbtn}>
        <Button
          area-label='createtemplate'
          variant='contained'
          component={Link}
          to='/mytemplates/template/new'
          onClick={() => {
            setPath({
              pathname: '/mytemplates/template/:id',
              component: TemplateDetailsComponent,
              supportPath: path.supportPath
            })
          }}
          className='pull-right'
          color='primary'
          disabled={user.switch_user}
        >
          Create Template
        </Button>
      </div>
      {mytemplates.length && !loading ? (
        <>
          <EnhancedTable
            key='mytemplates-table'
            headCells={headCells}
            rowsData={mytemplates}
            checkbox={false}
            orderby='last_modified_on_sort'
            sortorder='desc'
          />
        </>
      ) : null}
      {!loading && !mytemplates.length ? (
        <div className={classes.nodatatext}>
          No records found
        </div>
      ) : null}
    </div>
  )
}
