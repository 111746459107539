/* eslint indent: 0 */
import React, {
  useContext
} from 'react'
import {
  TextField,
  Chip,
  IconButton,
  makeStyles,
  LinearProgress,
  Typography,
  List,
  ListItem,
  Collapse,
  Grid
} from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import queryString from 'query-string'
import UserContext from '../contexts/UserContext'
import AlertDialog from '../alertbox/alertboxComponent'
import ComboBox from '../inputs/autocomplete'
import requestAccess from '../../services/api/requestaccessService'
import approvalWorkflowService from
  '../../services/api/approvalWorkflowService'
import sorticon from '../../assets/images/sort.png'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  selectbox: {
    width: '40%'
  },
  headerroot: {
    flexGrow: 1,
    marginTop: 10
  },
  subcontent: {
    padding: '20px 0 0 20px'
  },
  groupname: {
    margin: '10px 0 10px 0'
  },
  editrow: {
    marginLeft: 10
  },
  editicon: {
    padding: '0 0 0 5px'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  nodatatext: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(8),
    textAlign: 'center'
  },
  evenRow: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  },
  collapseborder: {
    borderBottom: '1px solid #F5F5F5'
  },
  padding0: {
    padding: 0
  }
}))

export default function ApprovalWorkflowComponent (props) {
  const queryparams = queryString.parse(props.location.search)
  const {
    user
  } = useContext(UserContext)
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const [successAlert, setsuccessAlert] = React.useState(false)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [failalertMessage, setFailalertMessage] = React.useState(null)
  const [successalertMessage, setSuccessalertMessage] = React.useState(null)
  const [workflowdata, setWorkflowData] = React.useState([])
  const [workflowdatacopy, setWorkflowDataCopy] = React.useState([])
  const [allprojects, setAllProjects] = React.useState([])
  const [allusers, setAllUsers] = React.useState([])
  const [loadingbar, setLoadingbar] = React.useState(false)
  const [isDesc, setIsDesc] = React.useState(false)
  const [column, setColumn] = React.useState('')
  const [ownerdata, setOwnerData] = React.useState(null)
  const [approverData, setApproverData] = React.useState([])
  const [alertopen, setAlertOpen] = React.useState(false)
  const [alertboxMessage, setAlertBoxMessage] = React.useState(null)
  const [projectkey, setProjectKey] = React.useState(null)
  const [project, setProject] = React.useState(null)

  React.useEffect(() => {
    if (queryparams.project) {
      requestAccess.getprojects('jira',
        queryparams.project).then((response) => {
        const filtervalue = response.data
          .filter((item) => item.key.toLowerCase() === queryparams.project
            .toLowerCase())
        if (filtervalue.length && filtervalue.length === 1) {
          changeProject(filtervalue[0])
        }
      })
    }
  }, [])
  /* function to get the projects list based on the search key */
  const getProjects = (value) => {
    setLoadingbar(true)
    requestAccess.getprojects('jira', value).then((response) => {
      setAllProjects(response.data)
      setLoadingbar(false)
    })
  }
  /* To expand and Collapse each row */
  const handleClick = (item, index) => {
    const data = workflowdata
    data[index].open = !data[index].open
    setWorkflowData([...data])
  }
  /* To get workflow date for selected project */
  const changeProject = (value) => {
    if (value) {
      setProject(`${value.key} - ${value.key_app_name}`)
      setLoading(true)
      approvalWorkflowService
        .getApprovalWorkflow(value.key).then((response) => {
          setLoading(false)
          setWorkflowData(response.data)
          setWorkflowDataCopy(JSON.parse(JSON.stringify(response.data)))
          approvalWorkflowService.checkOwnerOfJiraProject(user.name, value.key)
            .then((ownerresponse) => {
              const owners = []
              ownerresponse.data.map((owner) => owners
                .push(owner.username.toLowerCase()))
              setOwnerData(owners)
              approvalWorkflowService.getApproversInProject(value.key)
                .then((approverresponse) => {
                  setProjectKey(value.key)
                  setApproverData(approverresponse.data)
                })
            })
        })
    }
  }
  // API call to get users for auto complete text box
  const getUsers = (value) => {
    setLoadingbar(true)
    requestAccess.getallusersforautocomplete(value).then((response) => {
      setAllUsers(response.data)
      setLoadingbar(false)
    })
  }
  /* To update the users in autocomplete */
  const changeUser = (value, index, reason, i, group) => {
    const data = workflowdata
    if (value.length && reason === 'select-option') {
      /* > data[index].values[i].fixedvalues.length approverData having users list of current project approvers and while adding users, the below
        condition will check whether the selected user is part of approver group
        If yes the selected user will be added else alert will appear */
      const username = approverData.filter((item) => item.name
        .toLowerCase() === value[value.length - 1].name.toLowerCase())
      if (username.length) {
        const users = []
        value.map((item, j) => {
          if (j !== (value.length - 1)) {
            users.push(item.name.toLowerCase())
          }
        })
        const {
          name
        } = value[value.length - 1]
        value[value.length - 1] = {
          ...group[0]
        }
        value[value.length - 1].name = name
        value[value.length - 1].userid = username[0].userid
        value.map((item, j) => {
          if (item.name.toLowerCase() === username[0].name.toLowerCase()) {
            if (users.includes(username[0].name.toLowerCase())) {
              value.splice(value.length - 1, 1)
              setAlertBoxMessage('The selected user is already added')
              setAlertOpen(true)
            }
          }
        })
        data[index].values[i].values = value
        setWorkflowData([...data])
      } else {
        const message = (
          <div>
            You cannot add this user,
            since the user is not part of at least one of the below groups

            <ul>
              <li key={`ITS-ASx-${projectkey}-Jira-Approvers`}>
                ITS-ASx-
                {projectkey}
                -Jira-Approvers
              </li>
              <li key={`ITS-ASx-${projectkey}-Jira-Product-Owner`}>
                ITS-ASx-
                {projectkey}
                -Jira-Product-Owner
              </li>
              <li key={`ITS-ASx-${projectkey}-Jira-Project-Admin`}>
                ITS-ASx-
                {projectkey}
                -Jira-Project-Admin
              </li>
              <li key={`ITS-ASx-${projectkey}-Jira-Scrum-Master`}>
                ITS-ASx-
                {projectkey}
                -Jira-Scrum-Master
              </li>

            </ul>
          </div>
        )
        setAlertBoxMessage(message)
        setAlertOpen(true)
      }
      // } else if (value.length && (value.length === data[index].values[i].fixedvalues.length || value.length < data[index].values[i].fixedvalues.length)) {
      //   data[index].values[i].values = value
      //   setWorkflowData([...data])
    } else if (value.length && reason === 'remove-option') {
      data[index].values[i].values = value
      setWorkflowData([...data])
    } else if (!value.length) {
      setAlertBoxMessage(`
        At least one member should be present in the approval group
      `)
      setAlertOpen(true)
    }
  }
  const setValuenull = (value) => {
    value.splice(0, 1)
    value = []
  }
  /* Function to save workflow with newly added users */
  const handleSave = (data, group, index, i) => {
    const selectedUsers = []
    selectedUsers.push({
      users: [],
      groupname: data[0].groupnameid.toString(),
      appovalGroupId: data[0].project_approval_groups_id.toString()
    })
    data.map((item) => {
      selectedUsers[0].users.push({
        userid: item.userid.toString(),
        groupnameid: item.groupnameid
      })
    })
    if (selectedUsers[0].users.length > 0) {
      const input = {
        issueType: data[0].issuetypeid.toString(),
        approvalCategoryId: data[0].categoryid.toString(),
        projectKey: data[0].projectid.toString(),
        stateId: data[0].statusid.toString(),
        onApprovalState: data[0].approvalstate.toString(),
        onRejectState: data[0].rejectstate.toString(),
        oldIssueTypeId: data[0].issuetypeid.toString(),
        oldStateId: data[0].statusid.toString(),
        oldApprovalCategroyId: data[0].categoryid.toString(),
        oldApprovalStateId: data[0].approvalstate.toString(),
        oldRejectionStateId: data[0].rejectstate.toString(),
        oldComments: data[0].comments,
        comments: data[0].comments,
        groups: selectedUsers,
        requriedEsig: 'yes'
      }
      setLoading(true)
      approvalWorkflowService.addUsersToApprovalGroups(projectkey, input)
        .then((response) => {
          setLoading(false)
          if (response.data.body && response.data.body.status === 'true') {
            setSuccessalertMessage('Approval workflow updated successfully')
            group.edit = false
            group.values.map((item, j) => {
              if (!item.pkey) {
                group.values[j].pkey = projectkey
              }
            })
            const data1 = workflowdata
            data1[index].values[i] = group
            const datacopy = JSON.parse(JSON.stringify(data1))
            setWorkflowData([...data1])
            setWorkflowDataCopy([...datacopy])
            setsuccessAlert(true)
            setTimeout(() => {
              setsuccessAlert(false)
            }, 20000)
          } else {
            seterrorAlert(true)
            if (response.data.body) {
              setFailalertMessage(response.data.body.data)
            } else {
              setFailalertMessage(`
                Something went wrong. Contact system administrator!
              `)
            }
          }
        },
        (error) => {
          if (error) {
            setLoading(false)
            setFailalertMessage(`
              Something went wrong. Contact system administrator!
            `)
            seterrorAlert(true)
          }
        })
    } else {
      setAlertBoxMessage('Add atleast one user to save workflow')
      setAlertOpen(true)
    }
  }
  /* Function to cancel the current edit operation */
  const handleCancel = (data, index, i) => {
    const data1 = workflowdata
    const childdata = JSON.parse(JSON
      .stringify(workflowdatacopy[index].values[i]))
    childdata.edit = false
    data1[index].values[i] = childdata
    setWorkflowData([...data1])
  }
  /* To return users list based on editable and npn-editable view */
  const getUserslist = (users, index, i, data) => {
    if (users.edit) {
      return (
        <Autocomplete
          id='combo-box-demo'
          multiple
          options={allusers}
          value={users.values}
          getOptionLabel={(option) => (option.name
            ? option.name
            : option)}
          disableClearable
          loading={allusers.length === 0 && loadingbar}
          filterOptions={(options, state) => options}
          renderTags={(value, getTagProps) => value
            .map((option, index) => (users.values.length > 0 ? (
              <Chip
                label={
                  option.name
                    ? option.name
                    : option
                }

                {...getTagProps({
                  index
                })}
                //  disabled={users.fixedvalues.indexOf(option.name) !== -1}
              />
            ) : (
              setValuenull(value)
            )))}
          onChange={
            (event, value, reason) => changeUser(value, index, reason, i, data)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              name='username'
              onChange={(event) => getUsers(event.target.value)}
              value={users.values}
              autoFocus
            />
          )}
        />
      )
    }
    const users1 = []
    users.values.map((item) => users1.push(item.name))
    return users1.toString()
  }
  /* Function to set the row editabe */
  const handleEdit = (index, i) => {
    if (ownerdata.includes(user.name.toLowerCase())) {
      const data = workflowdata
      data[index].values[i].edit = true
      if (!data[index].values[i].fixedvalues) {
        const fixedvalues = []
        data[index].values[i].values.map((item) => fixedvalues.push(item.name))
        data[index].values[i].fixedvalues = fixedvalues
      }
      setWorkflowData([...data])
    } else {
      const message = (
        <div>
          Only Account Owner can edit the workflow.
          <div className={classes.groupname}>
            <b>Following users are account owners:</b>
          </div>
          <ul>
            {ownerdata.map((owner) => (
              <li key={owner}>{owner}</li>
            ))}

          </ul>
        </div>
      )
      setAlertBoxMessage(message)
      setAlertOpen(true)
    }
  }
  /* Function to close the alert box */
  const alertClose = () => {
    setAlertOpen(false)
  }
  /* Get icon for sorting */
  const getArrowIcon = (type) => {
    switch (type) {
    case 'issuetype':
      return isDesc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
    case 'status':
      return isDesc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
    case 'category':
      return isDesc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
    default: return null
    }
  }
  // Change sort function to this:
  const sort = (property) => {
    setIsDesc(!isDesc)
    setColumn(property)
    if (isDesc) {
      const sortedData1 = [].concat(workflowdata)
        .sort((a, b) => b[property].localeCompare(a[property]))
      setWorkflowData(sortedData1)
    } else {
      const sortedData1 = [].concat(workflowdata)
        .sort((a, b) => a[property].localeCompare(b[property]))
      setWorkflowData(sortedData1)
    }
  }
  return (
    <div className={classes.root}>
      {loading ? <LinearProgress /> : null}
      <div className={classes.alertroot}>
        {' '}
        {successAlert ? (
          <Alert severity='success'>{successalertMessage}</Alert>
        ) : null}
        {errorAlert ? (
          <Alert
            severity='error'
            onClose={() => seterrorAlert(false)}
          >
            {failalertMessage}
          </Alert>
        ) : null}
      </div>
      <Typography variant='subtitle1' gutterBottom>
        Approval Workflow
      </Typography>
      <div className={classes.selectbox}>
        <ComboBox
          allProjects={allprojects}
          name='jira-project-key'
          label='Select Jira Project'
          changeOption={changeProject}
          inputValue={project}
          getProjects={getProjects}
          loading={loadingbar}
        />
      </div>
      {workflowdata.length ? (
        <>
          <Grid container spacing={1} className={classes.headerroot}>
            <Grid item xs={4} md={4} onClick={() => sort('issuetype')}>

              <Typography variant='subtitle2' gutterBottom>

                Issue Type

                {column === 'issuetype'
                  ? (
                    <IconButton className={classes.padding0}>
                      {getArrowIcon('issuetype')}
                    </IconButton>
                  ) : (
                    <img
                      src={sorticon}
                      width='13'
                      height='13'
                      alt=''
                      style={{
                        opacity: 0.4
                      }}
                    />
                  )}

              </Typography>
            </Grid>
            <Grid item xs={4} md={4} onClick={() => sort('status')}>
              <Typography variant='subtitle2' gutterBottom>
                Status
                {column === 'status' ? (
                  <IconButton className={classes.padding0}>
                    {getArrowIcon('status')}
                  </IconButton>
                ) : (
                  <img
                    src={sorticon}
                    width='13'
                    height='13'
                    alt=''
                    style={{
                      opacity: 0.4
                    }}
                  />
                )}
              </Typography>
            </Grid>
            <Grid item xs={4} md={4} onClick={() => sort('category')}>
              <Typography
                variant='subtitle2'
                gutterBottom
                style={{
                  marginLeft: '-20px'
                }}
              >
                Approval Category
                {column === 'category' ? (
                  <IconButton className={classes.padding0}>
                    {getArrowIcon('category')}
                  </IconButton>
                ) : (
                  <img
                    src={sorticon}
                    width='13'
                    height='13'
                    alt=''
                    style={{
                      opacity: 0.4
                    }}
                  />
                )}
              </Typography>
            </Grid>
          </Grid>
          <List>
            {workflowdata.map((item, index) => (
              <>
                <ListItem
                  onClick={() => handleClick(item, index)}
                  className={index % 2 === 0 ? classes.evenRow : null}
                >
                  <Grid container spacing={1} className={classes.root}>
                    <Grid item xs={4} md={4}>{item.issuetype}</Grid>
                    <Grid item xs={4} md={4}>{item.status}</Grid>
                    <Grid item xs={4} md={4}>{item.category}</Grid>
                  </Grid>
                  {item.open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={item.open}
                  timeout='auto'
                  unmountOnExit
                  className={classes.collapseborder}
                >
                  <Grid container spacing={1} className={classes.subcontent}>
                    <Grid item xs={6} md={4}>
                      <b>
                        Group Name
                      </b>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <b>
                        Users
                      </b>
                    </Grid>
                  </Grid>
                  {!item.values.length ? (
                    <div>
                      E-Signature is not enabled for this issuetype combination
                    </div>
                  )
                    : item.values.map((value, i) => (
                      <Grid
                        container
                        spacing={1}
                        key={value.group_name}
                        className={classes.editrow}
                      >
                        <Grid item xs={6} md={4}>
                          <div className={classes.groupname}>
                            <Typography variant='caption' gutterBottom>
                              {value.group_name}
                            </Typography>
                          </div>

                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div
                            key={value.group_name}
                            className={classes.groupname}
                          >
                            <Typography variant='caption' gutterBottom>
                              <div className={classes.root}>
                                {' '}
                                {getUserslist(value, index, i, value.values)}
                                {!value.edit
                                  ? (
                                    <IconButton
                                      className={classes.editicon}
                                      onClick={() => handleEdit(index, i)}
                                      disabled={user.switch_user}
                                    >
                                      <EditIcon
                                        color='primary'
                                        fontSize='small'
                                      />
                                    </IconButton>
                                  )
                                  : null}
                              </div>
                            </Typography>
                          </div>

                        </Grid>
                        <Grid item xs={12} md={2}>
                          {value.edit ? (
                            <div className={classes.groupname}>
                              <IconButton
                                className={classes.editicon}
                                onClick={
                                  () => handleSave(value.values,
                                    value, index, i)
                                }
                              >
                                {' '}
                                <CheckIcon color='primary' />
                                {' '}
                              </IconButton>
                              <IconButton
                                className={classes.editicon}
                                onClick={() => handleCancel(value, index, i)}
                              >
                                {' '}
                                <CloseIcon color='secondary' />
                                {' '}
                              </IconButton>
                            </div>
                          )
                            : null}
                        </Grid>
                      </Grid>
                    ))}
                </Collapse>
              </>
            ))}

          </List>
        </>
      ) : null}
      {!loading && projectkey && !workflowdata.length ? (
        <div className={classes.nodatatext}>
          No workflow found for this project
        </div>
      ) : null}
      {!loading && !projectkey ? (
        <div className={classes.nodatatext}>
          Select Jira project to see workflow
        </div>
      ) : null}
      <AlertDialog
        handleClose={alertClose}
        alertopen={alertopen}
        key='editalert'
        message={alertboxMessage}
        okbuttonalert
        title='Alert'
      />
    </div>
  )
}
