import React, {
  useRef, useState, useContext
} from 'react'
import {
  LinearProgress,
  makeStyles,
  Grid,
  Card,
  Button,
  CardContent,
  CardActions,
  Typography,
  FormControl,
  IconButton
} from '@material-ui/core'
import {
  Autocomplete,
  Alert
} from '@material-ui/lab'
import {
  TextValidator,
  ValidatorForm
} from 'react-material-ui-form-validator'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  FileUploader, FileManager
} from 'reactjs-file-uploader'
import AlertDialog from '../alertbox/alertboxComponent'
import SupportService from '../../services/api/supportService'
import RiskAssessmentService from '../../services/api/riskAssessmentService'
import requestAccess from '../../services/api/requestaccessService'
import UserContext from '../contexts/UserContext'

const useStyle = makeStyles((theme) => ({
  inputDisplay: {
    width: '100%'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  },
  templateColDisplay: {
    margin: '10px',
    width: '50%'
  },
  here: {
    color: '#007bff',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}))

export default function RiskAssessmentComponent () {
  const classes = useStyle()
  const form = useRef(null)
  const {
    user, setUser
  } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const [successAlert, setsuccessAlert] = useState(false)
  const [successalertMessage, setSuccessalertMessage] = useState(null)
  const [errorAlert, seterrorAlert] = useState(false)
  const [failalertMessage, setFailalertMessage] = useState(null)
  const [files, setFiles] = useState([])
  const [alertAttachment, setAlertAttachment] = useState(false)
  const [loadingbar, setLoadingbar] = React.useState(false)
  const [allProjects, setAllProjects] = React.useState([])
  const [alertBox, setAlertBox] = React.useState(false)
  const [alertboxMessage, setalertboxMessage] = React.useState(null)
  // const [alertFileType, setAlertFileType] = useState(false)

  const [formData, setFormData] = useState({
    maxFileSize: 10000000,
    attachmentAnchorTag: '',
    projectkey: ''
  })

  const getProjects = (value) => {
    setLoadingbar(true)
    requestAccess.getprojects('jira', value).then((response) => {
      setAllProjects(response.data)
      setLoadingbar(false)
    })
  }

  const changeKey = (value) => {
    if (value) {
      setFormData({
        ...formData,
        projectkey: value.key
      })
    }
  }

  const removeAttahment = (file) => {
    files.map((fileitem, i) => {
      if (fileitem.name === file.name) {
        const fName = file.originalname
        SupportService.removeFileUpload('ra', {
          filename: fName
        }).then((response) => {
          files.splice(i, 1)
          setFiles([...files])
        })
      }
    })
  }

  const removeEntry = () => {
    setAlertAttachment(true)
    setTimeout(() => {
      setAlertAttachment(false)
    }, 20000)
    files.map((item, i) => {
      if (item.size > formData.maxFileSize ||
          item.name.split(/[.]+/).pop().toLowerCase() !== 'xlsx') {
        files.splice(i, 1)
        setFiles([...files])
      }
    })
  }

  //   const removeEntryFileType = () => {
  //     setAlertFileType(true)
  //     setTimeout(() => {
  //       setAlertFileType(false)
  //     }, 20000)
  //     files.map((item, i) => {
  //       if (item.size > formData.maxFileSize && item.name.split(/[.]+/).pop().toLowerCase() === 'xlsx') {
  //         files.splice(i, 1)
  //         setFiles([...files])
  //       }
  //     })
  //   }

  // set original name for file
  const setFileOriginalName = (file, response) => {
    files.map((fileitem, i) => {
      if (fileitem.name === file.name) {
        files[i].originalname = response.filename
        files[i].path = response.path
        setFiles(files)
      }
    })
  }
  /* to close the alert box */
  const handleClose = () => {
    setAlertBox(false)
  }
  // Upload Files
  const uploadFiles = (uploadedFiles) => uploadedFiles.map(uploadFile)

  // Upload a single file
  const uploadFile = (file) => (
    <FileUploader
      key={file.key}
      file={file}
      url='/api/fileupload?page=ra'
      formData={{
        file,
        upload_preset: 'public',
        tags: 'vanilla'
      }}
      maxFileSize={10000000}
      readFile
      autoUpload={
        file.size < formData.maxFileSize &&
          file.name.split(/[.]+/).pop().toLowerCase() === 'xlsx'
      }
    >
      {fileProgress}
    </FileUploader>
  )

  // show file progress
  const fileProgress = ({
    uploadReady,
    downloadComplete,
    /*
    Response text Object (JSON)
     */
    response,
    file
  }) => (
    <div>
      {downloadComplete && response && setFileOriginalName(file, response)}
      {downloadComplete && file.size < formData.maxFileSize &&
        file.name.split(/[.]+/).pop().toLowerCase() === 'xlsx' ? (
          <Grid
            container
            spacing={1}
            style={{
              marginBottom: 20
            }}
          >
            <Grid
              item
              xs={4}
              md={3}
              style={{
                marginLeft: 30
              }}
            >
              {file.name}
            </Grid>
            <Grid item xs={6} md={4}>
              <LinearProgress
                style={{
                  width: '80%'
                }}
                variant='determinate'
                value={100}
              />
              <Typography component='div' variant='caption'>
                100% upload completed
              </Typography>
            </Grid>
            <Grid item xs={2} md={2}>
              <IconButton
                aria-label='delete'
                style={{
                  padding: 0
                }}
              >
                <DeleteIcon
                  color='secondary'
                  onClick={() => removeAttahment(file)}
                />
              </IconButton>
            </Grid>
          </Grid>
        ) : (null)}
      {
        uploadReady && (file.size > formData.maxFileSize ||
        file.name.split(/[.]+/).pop().toLowerCase() !== 'xlsx')
          ? (removeEntry())
          : (null)
      }
      {/* {uploadReady && file.name.split(/[.]+/).pop().toLowerCase() !== 'xlsx' ? (removeEntryFileType()) : (null)} */}
    </div>
  )

  // download template
  const downloadTemplate = () => {
    RiskAssessmentService.downloadRATemplate().then((response) => {
      const blob = new Blob([response.data], {
        type: `
          application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
        `
      })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Risk Assessment Template.xlsx'
      document.body.appendChild(link)

      link.click()
    })
  }

  const handleSubmit = () => {
    const fileNameForImportRA = files[0].path
    const input = {
      filename: fileNameForImportRA
    }
    setLoading(true)
    RiskAssessmentService.importRiskAssessment(formData.projectkey, input)
      .then((response) => {
        setLoading(false)
        if (response.data.statusCode === 200) {
          setSuccessalertMessage(response.data.message)
          setFormData({
            ...formData,
            projectkey: '',
            attachmentAnchorTag: ''
          })
          // setsuccessAlert(true)
          setAlertBox(true)
          setalertboxMessage(response.data.message)
          files.map((fileitem, i) => {
            SupportService.removeFileUpload('ra', {
              filename: fileitem.originalname
            }).then((response) => {
              files.splice(i, 1)
              setFiles([...files])
            })
          })
          setTimeout(() => {
            // setsuccessAlert(false)
          }, 20000)
        } else {
          setFailalertMessage(response.data)
          seterrorAlert(true)
        }
      }, (error) => {
        if (error) {
          // empty block for reason
        }
        setLoading(false)
        setFailalertMessage(`
          Something went wrong. Contact system administrator!
        `)
        seterrorAlert(true)
      })
  }

  return (
    <div>
      {loading ? <LinearProgress /> : null}
      <div className={classes.alertroot}>
        {' '}
        {successAlert ? (
          <Alert severity='success'>{successalertMessage}</Alert>
        ) : null}
        {errorAlert ? (
          <Alert
            severity='error'
            onClose={() => seterrorAlert(false)}
          >
            {failalertMessage.message.processingError && (
              <div>{failalertMessage.message.processingError}</div>
            )}
            {failalertMessage.message.headerError && (
              <li>{failalertMessage.message.headerError}</li>
            )}
            {failalertMessage.message.dataPatternError && (
              <li>{failalertMessage.message.dataPatternError}</li>
            )}
            {failalertMessage.message.dataRowError && (
              <li>{failalertMessage.message.dataRowError}</li>
            )}
            {failalertMessage.message.totalRowErrorMessage && (
              <li>{failalertMessage.message.totalRowErrorMessage}</li>
            )}
          </Alert>
        ) : null}
      </div>
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={12} md={7}>
          <Card variant='outlined'>
            <ValidatorForm ref={form} onSubmit={() => handleSubmit()}>
              <CardContent>
                <Typography variant='subtitle1' gutterBottom>
                  Import Risk Assessment to Jira
                </Typography>
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    id='select-project'
                    options={allProjects}
                    value={formData.projectkey ? formData.projectkey : ''}
                    getOptionLabel={
                      (option) => (option.key ? option.key : option)
                    }
                    loading={allProjects.length === 0 && loadingbar}
                    className={classes.inputDisplay}
                    getOptionSelected={
                      (option, value) => option.key === value.key
                    }
                    onChange={(event, value) => changeKey(value)}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label='Type a valid project key *'
                        name='projectkey'
                        onChange={(event) => getProjects(event.target.value)}
                        value={formData.projectkey}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      />
                    )}
                  />
                </FormControl>
                <FormControl
                  className={
                    `${classes.formControl} ${classes.attachmentColDisplay}`
                  }
                >
                  <Typography
                    variant='subtitle1'
                    className={classes.labelDisplay}
                  >
                    Select a file which have values for Risk Assessment
                    {' '}
                    {/* <small>
                      (
                      <i>File size should not exceed 10 MB </i>
                      )
                    </small> */}
                  </Typography>
                  <br />
                  <input
                    type='file'
                    name='fileUplaod'
                    id='fileUpload'
                    accept='application/vnd.openxmlformats-officedocument
                      .spreadsheetml.sheet'
                    className={classes.labelDisplay}
                    onChange={
                      (event) => setFiles(files
                        .concat(Array.from(event.target.files)))
                    }
                  />
                  {alertAttachment ? (
                    <span className={classes.error}>
                      Please attach file smaller than 10 MB /
                      Please select only xlsx file
                    </span>
                  ) : null}
                  <FileManager files={files}>
                    {uploadFiles}
                  </FileManager>
                </FormControl>
                <FormControl
                  className={
                    `${classes.formControl} ${classes.columnDisplay}`
                  }
                >
                  <Typography
                    variant='subtitle1'
                    className={classes.templateColDisplay}
                  >
                    Click
                    {' '}
                    <span
                      className={classes.here}
                      onClick={downloadTemplate}
                    >
                      here
                    </span>
                    {' '}
                    to download risk assessment template
                    {' '}
                    <br />
                    To learn more about the template click
                    {' '}
                    <a
                      className={classes.here}
                      target='_blank'
                      href={user.riskurl}
                      rel='noreferrer'
                    >
                      {' '}
                      here
                    </a>
                  </Typography>
                </FormControl>
              </CardContent>
              <CardActions className={classes.footer}>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  size='small'
                  disabled={files.length === 0 || formData.projectkey === ''}
                >
                  Import
                </Button>
              </CardActions>
            </ValidatorForm>
          </Card>
        </Grid>
      </Grid>
      <AlertDialog
        handleClose={handleClose}
        alertopen={alertBox}
        message={alertboxMessage}
        confirmbutton={handleClose}
        key='alert1'
        okbuttonalert
        title='Success Message'
      />
    </div>
  )
}
