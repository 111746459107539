import React from 'react'
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
  Input,
  FormControl,
  InputLabel,
  Typography
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20
  },
  textfield: {
    width: '80%'
  },
  bold: {
    fontWeight: 600,
    marginTop: 20
  }
}))

export default function KubeconfigAdminComponent ({
  configdata, handleChange
}) {
  const classes = useStyles()
  const [taShowPassword, setTaShowPassword] = React.useState(false)

  const taHandleChange = (type, e, where) => {
    handleChange(type, e, where)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography
            variant='subtitle1'
            gutterBottom
            className={classes.bold}
          >
            Kube configuration settings
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Token URI'
            name='tokenURI'
            rows={3}
            onChange={(e) => handleChange('kubeConfig', e)}
            value={configdata.kubeConfig.tokenURI}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Grant Type'
            name='grantType'
            onChange={(e) => handleChange('kubeConfig', e)}
            value={configdata.kubeConfig.grantType}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label='Admin'
            name='adminGroup'
            onChange={(e) => handleChange('kubeConfig', e)}
            value={configdata.kubeConfig.adminGroup}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label='Client ID'
            name='clientID'
            onChange={(e) => handleChange('kubeConfig', e)}
            value={configdata.kubeConfig.clientID}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl className={classes.textfield}>
            <InputLabel htmlFor='standard-adornment-password'>
            Client Secret
            </InputLabel>
            <Input
              label='Password'
              onChange={(e) => taHandleChange('kubeConfig', e)}
              name='clientSecret'
              value={configdata.kubeConfig.clientSecret}
              className={classes.textfield}
              type={taShowPassword ? 'text' : 'password'}
              endAdornment={(
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setTaShowPassword(!taShowPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {taShowPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )}
            />
          </FormControl>
        </Grid>
        <br />
        <br />
        <b style={{
          paddingTop: '35px'
        }}
        >
        Development parameters
        </b>
        <Grid item xs={12} md={12}>
          <TextField
            label='Cluster API'
            name='clusterApi'
            rows={3}
            onChange={(e) => taHandleChange('kubeConfig', e, 'dev')}
            value={configdata.kubeConfig.dev.clusterApi}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Cluster Host'
            name='clusterHost'
            rows={3}
            onChange={(e) => taHandleChange('kubeConfig', e, 'dev')}
            value={configdata.kubeConfig.dev.clusterHost}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Cluster Scope'
            name='clusterScope'
            onChange={(e) => taHandleChange('kubeConfig', e, 'dev')}
            value={configdata.kubeConfig.dev.clusterScope}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Kube Config API'
            name='kubeConfigApi'
            rows={3}
            onChange={(e) => taHandleChange('kubeConfig', e, 'dev')}
            value={configdata.kubeConfig.dev.kubeConfigApi}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Kube Config Host'
            name='kubeConfigHost'
            rows={3}
            onChange={(e) => taHandleChange('kubeConfig', e, 'dev')}
            value={configdata.kubeConfig.dev.kubeConfigHost}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Scope'
            name='kubeConfigScope'
            onChange={(e) => taHandleChange('kubeConfig', e, 'dev')}
            value={configdata.kubeConfig.dev.kubeConfigScope}
            className={classes.textfield}
          />
        </Grid>
        <br />
        <br />
        <b style={{
          paddingTop: '35px'
        }}
        >
          Production parameters
        </b>
        <Grid item xs={12} md={12}>
          <TextField
            label='Cluster API'
            name='clusterApi'
            rows={3}
            onChange={(e) => taHandleChange('kubeConfig', e, 'prod')}
            value={configdata.kubeConfig.prod.clusterApi}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Cluster Host'
            name='clusterHost'
            rows={3}
            onChange={(e) => taHandleChange('kubeConfig', e, 'prod')}
            value={configdata.kubeConfig.prod.clusterHost}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Cluster Scope'
            name='clusterScope'
            onChange={(e) => taHandleChange('kubeConfig', e, 'prod')}
            value={configdata.kubeConfig.prod.clusterScope}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Kube Config API'
            name='kubeConfigApi'
            rows={3}
            onChange={(e) => taHandleChange('kubeConfig', e, 'prod')}
            value={configdata.kubeConfig.prod.kubeConfigApi}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Kube Config Host'
            name='kubeConfigHost'
            rows={3}
            onChange={(e) => taHandleChange('kubeConfig', e, 'prod')}
            value={configdata.kubeConfig.prod.kubeConfigHost}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Scope'
            name='kubeConfigScope'
            onChange={(e) => taHandleChange('kubeConfig', e, 'prod')}
            value={configdata.kubeConfig.prod.kubeConfigScope}
            className={classes.textfield}
          />
        </Grid>
      </Grid>
    </div>
  )
}
