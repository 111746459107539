/* It contains all the API functions which is releated to my templates and other templates page */
import axiosInstance from '../../middleware/Interceptor'

// to templates for user
function getTemplates (username, filter) {
  return axiosInstance.get(`/api/v1/template/myTemplates?
username=${username}&filter=${filter}`)
}

// to get sourcegroups for template
function getSourceGroups (input) {
  return axiosInstance.post('/api/v1/template/getsourcegroups', input)
}
// to get template groups for given template id

function getTemplateGroups (input) {
  return axiosInstance.post('/api/v1/template/gettemplategroups', input)
}
// to save or create template
function saveTemplate (input) {
  return axiosInstance.post('/api/v1/template/savetemplate', input)
}
// get template details for given template id
function accessTemplate (id) {
  return axiosInstance.get(`/api/v1/template/accesstemplate?templateid=${id}`)
}

// check template name exist
function checkTemplateNameExist (id) {
  return axiosInstance.get(`/api/v1/template/checkTemplateNameExist?name=${id}`)
}

const TemplateService = {
  getTemplates,
  getSourceGroups,
  saveTemplate,
  accessTemplate,
  getTemplateGroups,
  checkTemplateNameExist
}

export default TemplateService
