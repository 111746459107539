import React, {
  useState, useEffect, useRef, useContext
} from 'react'
import {
  makeStyles,
  LinearProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Tooltip,
  Zoom,
  Slide,
  Stack,
  Icon
} from '@material-ui/core'
import {
  Alert
} from '@material-ui/lab'
import {
  Link
} from 'react-router-dom'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import BuildCircleIcon from '@material-ui/icons/Build'
import WarningIcon from '@material-ui/icons/Warning'
import EnhancedTable from '../sections/enhancedTable'
import jenkinsDashboardService from '../../services/api/jenkinsDashboardService'
import IncidentComponent from '../support/supportIncidentComponent'
import jenkinslogo from '../../assets/images/jenkins_admin_logo.png'
import PathContext from '../contexts/PathContext'

export default function JenkinsDashboardComponent () {
  const {
    path, setPath
  } = useContext(PathContext)
  const [supportBg, setSupportBg] = React.useState('#d85411')
  const generalErrMsg = 'Something went wrong. Contact system administrator!'
  const [loading, setLoading] = React.useState(false)
  const [successAlert, setsuccessAlert] = React.useState(false)
  const [successAlertData, setsuccessAlertData] = React.useState({
  })
  const [generalerrorAlert, setgeneralerrorAlert] = React.useState(false)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [errorAlertData, seterrorAlertData] = React.useState({
  })
  const [tableData, setTableData] = React.useState([])
  const [bulkCheckData, setBulkData] = React.useState([])
  const [detailsLoading, setDetailsLoading] = React.useState(false)
  const [detailsDialog, setDetailsDialog] = React.useState(false)
  const [detailsTableData, setDetailsTableData] = React.useState({
  })
  const [jenkinsAliveData, setJenkinsAliveData] = React.useState({
  })
  const [jenkinsAlive, setJenkinsAlive] = React.useState(false)
  const [adminHelpNeeded, setAdminHelpNeeded] = React.useState(false)
  const [jenkinsInfoData, setJenkinsInfoData] = React.useState({
  })
  const [aliveBg, setAliveBg] = React.useState('#d85411')
  const [aliveStr, setAliveStr] = React.useState('initStr')
  const [supportStr, setSupportStr] = React.useState('initStr')
  const [instanceAdmin, setInstanceAdmin] = React.useState(false)
  const [actionLoading, setActionLoading] = React.useState(false)
  // eslint-disable-next-line max-len
  const adminNeededMessage = 'Admin permission needed. Request Admin or higher permission to perform action.'

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },
    footer: {
      margin: theme.spacing(1),
      float: 'right'
    },
    formControl: {
      margin: theme.spacing(1),
      width: '90%'
    },
    components: {
      margin: theme.spacing(1),
      width: '40%'
    },
    labelDisplay: {
      margin: '10px',
      width: '100%'
    },
    nodatatext: {
      ...theme.typography.subtitle1,
      padding: theme.spacing(12),
      textAlign: 'center'
    },
    alertroot: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(1)
      }
    },
    infocard: {
      display: 'flex',
      backgroundColor: '#ffffff',
      flexDirection: 'row',
      textAlign: 'center'
    },
    aliveIcon: {
      color: aliveBg
    },
    supportIcon: {
      color: supportBg,
      marginLeft: '10px'
    },
    cardContent: {
      marginTop: '10px'
    }
  }))
  const classes = useStyles()
  // [{"instance":"TAJS","status":"running","owner":"Mahesh, Marc","approver":"Charles, JAnthon2","permission":"permissionvalue","ltsversion":"0.0.1","dindversion":"0.0.1","jdkversion":"0.0.1","loading":false},{"instance":"TAAD","status":"running","owner":"Robert, Marc","approver":"Charles, JAnthon2","permission":"permissionvalue","ltsversion":"0.0.1","dindversion":"0.0.1","jdkversion":"0.0.1","loading":false},{"instance":"TADU","status":"running","owner":"Kamalesh, Antony","approver":"Charles, JAnthon2","permission":"permissionvalue","ltsversion":"0.0.1","dindversion":"0.0.1","jdkversion":"0.0.1","loading":false}]
  const headCells1 = [

    {
      id: 'key',
      numeric: false,
      label: 'Key',
      width: 5
    },
    {
      id: 'teamtype',
      numeric: false,
      label: 'Team type',
      width: 5
    },
    {
      id: 'available',
      numeric: false,
      label: 'Available',
      width: 5,
      icon: 'available'
    },
    {
      id: 'jenkinsdetailsbutton',
      numeric: false,
      label: 'Details',
      width: 5,
      type: 'jenkinsbutton'
    }
  ]

  function JenkinsInfoCard ({
    infoHeader, infoContent, headerID, contentID
  }) {
    return (
      <Card
        className={classes.infocard}
      >
        <CardHeader
          title={(
            <Typography
              variant='subtitle2'
              id={headerID}
            >
              {infoHeader}
            </Typography>
          )}
        />
        <CardContent
          className={classes.cardContent}
        >
          <Typography
            variant='subtitle2'
            id={contentID}
          >
            {
              `${infoContent === undefined ? 'Unknown' : infoContent} `
            }
          </Typography>
        </CardContent>
      </Card>
    )
  }

  function JenkinsActionButton ({
    buttonStr, availableStr, disabledStr, buttonFunc, availableBool, buttonID
  }) {
    return (
      <Tooltip
        enterDelay={500}
        enterNextDelay={500}
        leaveDelay={200}
        arrow
        title={(
          <Typography
            gutterBottom
          >
            {availableBool === true ? availableStr : disabledStr}
          </Typography>
        )}
        TransitionComponent={Zoom}
      >
        <span>
          <Button
            disabled={!availableBool}
            variant='contained'
            style={{
              marginRight: '10px',
              flexGrow: '1',
              alignSelf: 'stretch'
            }}
            color='primary'
            size='md'
            onClick={() => { buttonFunc() }}
            id={buttonID}
          >
            <Typography
              variant='h6'
              gutterBottom
              style={
                {
                  textAlign: 'center',
                  margin: 'auto'
                }
              }
            >
              {buttonStr}
            </Typography>
          </Button>
        </span>
      </Tooltip>
    )
  }


  React.useEffect(() => {
    loadJenkinsDashboard()
  }, [])

  const loadJenkinsDashboard = async () => {
    let resData = []
    setLoading(true)
    await jenkinsDashboardService.loadPageData().then((response) => {
      setTableData([...response.data])
      resData = [...response.data]
    }, (error) => {
      if (error) {
        setLoading(false)
        setgeneralerrorAlert(true)
      }
    })
    let namespaces = ''
    // eslint-disable-next-line no-plusplus
    for (const element of resData) {
      // eslint-disable-next-line max-len
      namespaces = `${namespaces},${element.key.toLowerCase()}-${element.teamtype.toLowerCase()}-jenkins`
    }
    const data = {
      'namespaces': namespaces
    }
    let liveCheckData = []
    const availableData = []
    await jenkinsDashboardService.liveCheckBulk(data)
      .then((resp) => {
        liveCheckData = resp.data.status
        for (const element of resData) {
          // eslint-disable-next-line max-len
          const namespace = `${element.key.toLowerCase()}-${element.teamtype.toLowerCase()}-jenkins`
          for (const check of liveCheckData) {
            if (check.namespace.includes(namespace)) {
              if (check.status === 'Available') {
                element.available = 'Available'
                availableData.push('Available')
              } else {
                element.available = 'Unavailable'
                availableData.push('Unavailable')
              }

              break
            }
          }
        }
        setBulkData(availableData)
        setLoading(false)
      }, (error) => {
        if (error) {
          setLoading(false)
          setgeneralerrorAlert(true)
        }
      })
  }

  const jenkinsDetailsButton = async (id) => {
    setDetailsTableData(id)
    setDetailsDialog(true)
    setDetailsLoading(true)
    setInstanceAdmin(id.isadmin)
    await jenkinsDashboardService.liveCheck(id)
      .then((response) => {
        const resData = response.data
        setJenkinsAliveData(resData)
        const aliveCode = JSON.stringify(resData.liveCheck)
        const message = JSON.stringify(resData.message)
        // Alive BG color + str
        if (aliveCode === 'true') {
          setAliveBg('#32a852')
          setAliveStr('Jenkins is Available')
          setJenkinsAlive(true)
          setAdminHelpNeeded(false)
        } else {
          setAliveBg('#d85411')
          setAliveStr('Jenkins is Unavailable')
          setJenkinsAlive(false)
          seterrorAlert(true)
          if (message.includes('STS Unavailable')) {
            setAdminHelpNeeded(false)
            // eslint-disable-next-line max-len
            seterrorAlertData('Jenkins is stopped, use Manage Jenkins with admin permissions to start instance.')
          } else {
            setAdminHelpNeeded(true)
            // eslint-disable-next-line max-len
            seterrorAlertData('Jenkins is unavailable, please raise ticket for EAT Team support.')
          }
        }
      }, (error) => {
        if (error) {
          seterrorAlert(true)
          setJenkinsAlive(false)
          seterrorAlertData('Error getting Jenkins availability')
        }
      })
    await jenkinsDashboardService.infoCheck(id)
      .then((response) => {
        const resData = response.data
        setJenkinsInfoData(resData)
        const supportCode = JSON.stringify(resData.eatSupportStatus)
        // Support Code BG color
        if (supportCode === '"Supported"') {
          setSupportBg('#32a852')
          setSupportStr('Jenkins is Supported')
        // eslint-disable-next-line max-len
        } else if (supportCode === '"Soon Out of Support"') {
          setSupportBg('#d2d427')
          setSupportStr('Jenkins is Soon Out of Support')
        } else if (supportCode === '"Unsupported"') {
          setSupportBg('#d85411')
          setSupportStr('Jenkins is Unsupported')
        } else {
          setSupportBg('#949494')
          setSupportStr('Jenkins Support is Unknown')
        }
      }, (error) => {
        if (error) {
          seterrorAlert(true)
          seterrorAlertData('Error getting Jenkins info')
        }
      })
    setDetailsLoading(false)
  }

  const jenkinsStartButton = () => {
    setsuccessAlert(false)
    setsuccessAlertData(false)
    setActionLoading(true)
    jenkinsDashboardService.startJenkins(detailsTableData)
      .then((response) => {
        if (response.data.code !== 200) {
          seterrorAlert(true)
          seterrorAlertData(response.data.message)
        } else {
          setsuccessAlert(true)
          // eslint-disable-next-line max-len
          setsuccessAlertData(`${response.data.message}. Wait a few minutes for instance to become available.`)
        }
        setActionLoading(false)
      }, (error) => {
        if (error) {
          seterrorAlert(true)
          seterrorAlertData('Unable to start Jenkins instance')
        }
        setActionLoading(false)
      })
  }


  const jenkinsStopButton = () => {
    setsuccessAlert(false)
    setsuccessAlertData(false)
    setActionLoading(true)
    jenkinsDashboardService.stopJenkins(detailsTableData)
      .then((response) => {
        if (response.data.code !== 200) {
          seterrorAlert(true)
          seterrorAlertData(response.data.message)
        } else {
          setsuccessAlert(true)
          // eslint-disable-next-line max-len
          setsuccessAlertData(`${response.data.message}.`)
        }
        setActionLoading(false)
      }, (error) => {
        if (error) {
          seterrorAlert(true)
          seterrorAlertData('Unable to stop Jenkins instance')
        }
        setActionLoading(false)
      })
  }

  const handleDetailsClose = () => {
    setJenkinsInfoData({
    })
    setDetailsLoading(false)
    setDetailsDialog(false)
    seterrorAlert(false)
    setsuccessAlert(false)
  }

  const handleSuccessAlertClose = () => {
    setsuccessAlert(false)
    setsuccessAlertData({
    })
  }

  const handleErrorAlertClose = () => {
    seterrorAlert(false)
    seterrorAlertData({
    })
  }

  const handleIncidentLink = () => {
    setPath({
      pathname: '/othersupport/createincident',
      component: IncidentComponent,
      supportPath: path.supportPath
    })
  }
  return (
    <>
      {loading ? <LinearProgress /> : null}
      {' '}
      <div className={classes.alertroot}>
        {' '}
        {successAlert ? (
          <Alert
            severity='success'
            onClose={handleSuccessAlertClose}
          >
            {JSON.stringify(successAlertData)}
          </Alert>
        ) : null}
        {errorAlert ? (
          <Alert
            severity='error'
            onClose={handleErrorAlertClose}
          >
            {JSON.stringify(errorAlertData)}
          </Alert>
        ) : null}
        {generalerrorAlert ? (
          <Alert
            severity='error'
            onClose={() => seterrorAlert(false)}
          >
            {generalErrMsg}
          </Alert>
        ) : null}
      </div>
      {!loading ? (
        <div>
          <h2>
            Jenkins Dashboard
          </h2>
          {tableData.length ? (
            <EnhancedTable
              key='request-table'
              orderby='created_sort'
              headCells={headCells1}
              rowsData={tableData}
              sortorder='desc'
              checkbox={false}
              jenkinsDetailsButton={jenkinsDetailsButton}
            />
          // eslint-disable-next-line max-len
          ) : 'No Jenkins instances to display. User is not part of any AD group'}

          <Dialog
            fullWidth
            maxWidth='xl'
            TransitionComponent={Slide}
            onClose={handleDetailsClose}
            aria-labelledby='details-dialog'
            open={detailsDialog}
          >
            <div className={classes.alertroot}>
              {' '}
              {successAlert ? (
                <Alert
                  severity='success'
                  onClose={handleSuccessAlertClose}
                >
                  {JSON.stringify(successAlertData)}
                </Alert>
              ) : null}
              {errorAlert ? (
                <Alert
                  severity='error'
                  onClose={handleErrorAlertClose}
                >
                  {JSON.stringify(errorAlertData)}
                  {adminHelpNeeded
                    ? (
                      <Link
                        to={{
                          pathname: '/othersupport/createincident'
                        }}
                        // eslint-disable-next-line react/no-this-in-sfc
                        onClick={handleIncidentLink}
                      >
                        EAT Incident Request
                      </Link>
                    )
                    : null}
                </Alert>
              ) : null}
              {generalerrorAlert ? (
                <Alert
                  severity='error'
                  onClose={() => seterrorAlert(false)}
                >
                  {generalErrMsg}
                </Alert>
              ) : null}
            </div>
            <Paper
              square
              elevation={2}
              style={
                {
                  color: '#ffffff',
                  background: '#4050b5'
                }
              }
            >
              <Button
                variant='contained'
                color='primary'
                href={
                  detailsDialog
                  // eslint-disable-next-line max-len
                    ? jenkinsInfoData.url
                    : '#test'
                }
                style={
                  {
                    float: 'right',
                    marginLeft: 'auto',
                    marginTop: '10px',
                    marginRight: '10px'
                  }
                }
              >
                Login
              </Button>
              <Grid
                container
                direction='row'
                gutterBottom
              >
                <Grid
                  item
                  container
                  direction='row'
                  xs={9}
                  style={{
                    alignContent: 'center',
                    marginLeft: '10px'
                  }}
                >
                  <img
                    src={jenkinslogo}
                    alt='Jenkins Logo'
                    style={{
                      display: 'flex',
                      width: '5%',
                      marginBottom: '10px'
                    }}
                  />
                  <Typography
                    variant='h4'
                    gutterBottom
                    className={classes.bold}
                    textAlign='center'
                    style={{
                      alignContent: 'center'
                    }}
                  >
                    {detailsDialog
                      ? `${detailsTableData.key} 
                      ${detailsTableData.teamtype} 
                      Jenkins`
                      : 'Jenkins Details'}
                  </Typography>
                  <div
                    style={{
                      alignContent: 'center',
                      marginLeft: '20px'
                    }}
                  >
                    {detailsLoading ? <LinearProgress /> : null}
                    {!detailsLoading ? (
                      <div
                        direction='row'
                        style={{
                          width: '100px',
                          justifyContent: 'space-evenly',
                          margin: 'auto'
                        }}
                      >
                        <Tooltip
                          enterDelay={500}
                          enterNextDelay={500}
                          leaveDelay={200}
                          arrow
                          title={(
                            <Typography
                              gutterBottom
                            >
                              {aliveStr}
                            </Typography>
                          )}
                          TransitionComponent={Zoom}
                        >
                          {!jenkinsAlive ? (
                            <WarningIcon
                              style={{
                                transform: 'scale(1.3)'
                              }}
                              className={classes.aliveIcon}
                              id='UnavailableIcon'
                            />
                          ) : (
                            <CheckCircleIcon
                              style={{
                                transform: 'scale(1.3)'
                              }}
                              className={classes.aliveIcon}
                              id='AliveIcon'
                            />
                          )}
                        </Tooltip>
                        <Tooltip
                          enterDelay={500}
                          enterNextDelay={500}
                          leaveDelay={200}
                          arrow
                          title={(
                            <Typography
                              gutterBottom
                            >
                              {supportStr}
                            </Typography>
                          )}
                          TransitionComponent={Zoom}
                        >
                          <BuildCircleIcon
                            style={{
                              transform: 'scale(1.3)'
                            }}
                            className={classes.supportIcon}
                            id='SupportIcon'
                          />
                        </Tooltip>
                      </div>
                    ) : null}
                  </div>
                </Grid>
                <Grid
                  item
                  textAlign='center'
                  style={{
                    alignContent: 'center'
                  }}
                >
                  <Typography
                    variant='subtitle2'
                    gutterBottom
                    className={classes.bold}
                    textAlign='center'
                    style={{
                      alignContent: 'center'
                    }}
                  >
                    {
                      `My Permission: ${detailsTableData.group_name}`
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            {detailsLoading || actionLoading
              ? <LinearProgress /> : null}
            {!detailsLoading ? (
              <DialogContent>
                <Grid
                  container
                  spacing={10}
                  direction='row'
                  marginTop='auto'
                  justifyContent='space-evenly'
                  style={
                    {
                      display: 'flex',
                      alignItems: 'stretch'
                    }
                  }
                >
                  <Grid
                    item
                    xs={5}
                  >
                    <Typography
                      variant='h6'
                      gutterBottom
                      className={classes.bold}
                      style={
                        {
                          textAlign: 'center',
                          margin: 'auto'
                        }
                      }
                    >
                      Jenkins Info
                    </Typography>
                    <Paper
                      square
                      elevation={2}
                      style={
                        {
                          display: 'flex',
                          backgroundColor: '#ebebeb',
                          alignItems: 'stretch',
                          margin: 'auto'
                        }
                      }
                    >
                      <Grid
                        container
                        spacing={2}
                        direction='column'
                        style={
                          {
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'stretch',
                            justifyContent: 'space-evenly',
                            textAlign: 'center',
                            margin: 'auto'
                          }
                        }
                      >
                        <Grid
                          item
                        >
                          <Accordion defaultExpanded>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls='system-info-content'
                              id='system-info-header'
                            >
                              System Info
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid
                                container
                                spacing={2}
                                direction='column'
                                style={
                                  {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'stretch',
                                    justifyContent: 'space-evenly',
                                    textAlign: 'center',
                                    margin: 'auto'
                                  }
                                }
                              >
                                <Grid item>
                                  <JenkinsInfoCard
                                    infoHeader='EAT Version'
                                    infoContent={jenkinsInfoData.eatVersion}
                                    headerID='EATVersionHeader'
                                    contentID='EATVersion'
                                  />
                                </Grid>
                                <Grid item>
                                  <JenkinsInfoCard
                                    infoHeader='LTS Version'
                                    infoContent={jenkinsInfoData.jenkinsLTS}
                                    headerID='LTSVersionHeader'
                                    contentID='LTSVersion'
                                  />
                                </Grid>
                                <Grid item>
                                  <JenkinsInfoCard
                                    infoHeader='JDK Version'
                                    infoContent={jenkinsInfoData.jdkVersion}
                                    headerID='JDKVersionHeader'
                                    contentID='JDKVersion'
                                  />
                                </Grid>
                                <Grid item>
                                  <JenkinsInfoCard
                                    infoHeader='DIND Version'
                                    infoContent={jenkinsInfoData.dindVersion}
                                    headerID='DINDVersionHeader'
                                    contentID='DINDVersion'
                                  />
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item>
                          <Accordion defaultExpanded>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls='ownership-info-content'
                              id='ownership-info-header'
                            >
                              Ownership Info
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid
                                container
                                spacing={2}
                                direction='column'
                                style={
                                  {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'stretch',
                                    justifyContent: 'space-evenly',
                                    textAlign: 'center',
                                    margin: 'auto'
                                  }
                                }
                              >
                                <Grid item>
                                  <JenkinsInfoCard
                                    infoHeader='Owners'
                                    // eslint-disable-next-line max-len
                                    infoContent={[...new Set(detailsTableData.owners)]}
                                    headerID='OwnersHeader'
                                    contentID='Owners'
                                  />
                                </Grid>
                                <Grid item>
                                  <JenkinsInfoCard
                                    infoHeader='Approvers'
                                    // eslint-disable-next-line max-len
                                    infoContent={[...new Set(detailsTableData.approvers)]}
                                    headerID='ApproversHeader'
                                    contentID='Approvers'
                                  />
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                  >
                    <Typography
                      variant='h6'
                      gutterBottom
                      className={classes.bold}
                      style={
                        {
                          textAlign: 'center',
                          margin: 'auto'
                        }
                      }
                    >
                      Actions
                    </Typography>
                    <Paper
                      square
                      elevation={2}
                      style={
                        {
                          display: 'flex',
                          backgroundColor: '#ebebeb',
                          alignItems: 'stretch',
                          margin: 'auto'
                        }
                      }
                    >
                      <Grid
                        container
                        spacing={2}
                        direction='column'
                        style={
                          {
                            display: 'flex',
                            alignItems: 'stretch',
                            justifyContent: 'space-evenly',
                            textAlign: 'center',
                            margin: 'auto'
                          }
                        }
                      >
                        <Grid item>
                          <Tooltip
                            enterDelay={500}
                            enterNextDelay={500}
                            leaveDelay={200}
                            arrow
                            disableHoverListener={instanceAdmin}
                            title={(
                              <Typography
                                gutterBottom
                              >
                                {adminNeededMessage}
                              </Typography>
                            )}
                            TransitionComponent={Zoom}
                          >
                            <Accordion
                              disabled={!instanceAdmin}
                              defaultExpanded={instanceAdmin}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls='manage-jenkins-content'
                                id='manage-jenkins-header'
                              >
                                Manage Jenkins
                              </AccordionSummary>
                              <AccordionDetails>
                                <JenkinsActionButton
                                  buttonStr='Start'
                                  availableStr='Start Jenkins Instance'
                                  // eslint-disable-next-line max-len
                                  disabledStr={adminHelpNeeded ? 'Unable to start Jenkins' : 'Jenkins instance is already running.'}
                                  buttonFunc={jenkinsStartButton}
                                  // eslint-disable-next-line max-len
                                  availableBool={!jenkinsAlive && !adminHelpNeeded}
                                  id='StartButton'
                                />
                                <JenkinsActionButton
                                  buttonStr='Stop'
                                  availableStr='Stop Jenkins Instance'
                                  // eslint-disable-next-line max-len
                                  disabledStr={adminHelpNeeded ? 'Unable to stop Jenkins' : 'Jenkins instance is already stopped.'}
                                  buttonFunc={jenkinsStopButton}
                                  // eslint-disable-next-line max-len
                                  availableBool={jenkinsAlive && !adminHelpNeeded}
                                  id='StopButton'
                                />
                              </AccordionDetails>
                            </Accordion>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </DialogContent>
            ) : null}

            <DialogActions>
              <Button onClick={handleDetailsClose} color='default'>
                <Typography
                  variant='subtitle1'
                  gutterBottom
                  className={classes.bold}
                >
                Close
                </Typography>
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}
    </>
  )
}
