
/* It contains all the API functions which is releated to admin config section */

import axiosInstance from '../../middleware/Interceptor'
/* To get all environments */
function getAllEnvironment () {
  return axiosInstance.get('/api/v1/admin/getAllEnvironment')
}
/* To get the all admin config data */
function adminconfig (environment) {
  return axiosInstance.get(`/api/adminconfig?environment=${environment || ''}`)
}
/* To get the banner data */
function getBannerData () {
  return axiosInstance.get('/api/v1/auth/getBannerData')
}
/* To save admin config data */
function saveAdminConfig (environment, input) {
  return axiosInstance.post(`/api/writeconfig?environment=${environment}`,
    input)
}
/* To update banner data */
function updateBannerData (input) {
  return axiosInstance.post('/api/v1/admin/updateBannerData', input)
}
/* get tool provisioning details */
function getToolsAndProvisioning () {
  return axiosInstance.get('/api/v1/provision/getToolsAndProvisioning')
}
/* update tool provisioning detail */
function updateToolProvisioningDetail (input) {
  return axiosInstance.post('/api/v1/provision/updateToolProvisioningDetail',
    input)
}
/* remove tool function */
function removeTool (input) {
  return axiosInstance.post('/api/v1/provision/removeTool', input)
}
/* Add new tool function */
function addNewTool (input) {
  return axiosInstance.post('/api/v1/provision/addNewTool', input)
}
/* Get Tool Api Functions */
function getToolFunctions (toolname) {
  return axiosInstance.get(`/api/v1/provision/getToolFunctions?toolname=
${toolname}`)
}
/* Save tool api function */
function updateApiFunctions (input) {
  return axiosInstance.post('/api/v1/provision/updateApiFunctions', input)
}
/* remove tool function */
function removeApiFunction (input) {
  return axiosInstance.post('/api/v1/provision/removeApiFunction', input)
}
/* get api function options for create api function */
function getRemainingApiFunctions (toolId) {
  return axiosInstance.get(`/api/v1/provision/getRemainingApiFunctions?toolId=
${toolId}`)
}
/* get available http methods */
function getHttpMethod () {
  return axiosInstance.get('/api/v1/provision/getsHttpMethod')
}
/* to add new api function */
function addApiFunctionForTool (input) {
  return axiosInstance.post('/api/v1/provision/addApiFunctionForTool', input)
}
/* Adduser Submit */
function userToGroup (type, input) {
  return axiosInstance.post(`/api/v1/admin/userToGroup?type=${type}`, input)
}
/* Remove user check how may groups selected user have access */
function revokeAccessCheck (username, toolName, projectKey) {
  return axiosInstance.get(`api/v1/admin/revokeAccessCheck?username=
${username}&toolName=${toolName}&projectKey=${projectKey}&reqtype=1`)
}
/* get available http methods */
function getEmailcontent () {
  return axiosInstance.get('/api/v1/admin/getEmailcontent')
}
/* get available http methods */
function saveEmailcontent (input) {
  return axiosInstance.post('/api/v1/admin/saveEmailcontent', input)
}
/* create bitbucket project */
function createProject (input) {
  return axiosInstance.post('/api/v1/bitbucket/createProject', input)
}
/* Get the current Admin group for bitbucket prefix based categorization */
function getAdminGroupForBitbucketPrefix () {
  return axiosInstance.get('/api/v1/admin/getBBAdminGroup')
}
/* SAVE the current Admin group for bitbucket prefix based categorization */
function putAdminGroupForBitbucketPrefix (input) {
  return axiosInstance.post('/api/v1/admin/putBBAdminGroup', input)
}
/* GET ADMIN CONFIG WIDGETS */
function getAdminConfigWidgets (input) {
  return axiosInstance.get('/api/v1/admin/configWidgets')
}
const adminConfigService = {
  getAllEnvironment,
  adminconfig,
  getBannerData,
  saveAdminConfig,
  updateBannerData,
  getToolsAndProvisioning,
  updateToolProvisioningDetail,
  removeTool,
  addNewTool,
  getToolFunctions,
  updateApiFunctions,
  removeApiFunction,
  getHttpMethod,
  getRemainingApiFunctions,
  addApiFunctionForTool,
  userToGroup,
  revokeAccessCheck,
  getEmailcontent,
  saveEmailcontent,
  createProject,
  getAdminGroupForBitbucketPrefix,
  putAdminGroupForBitbucketPrefix,
  getAdminConfigWidgets
}

export default adminConfigService
