/* It contains all the API functions which is releated to Request access section */
import axios from 'axios'
import axiosInstance from '../../middleware/Interceptor'

const {
  CancelToken
} = axios
let cancel
function getprojects (toolname, value, page) {
  if (cancel !== undefined) {
    cancel()
  }

  return axiosInstance.get(`/api/v1/requestaccess/getProjectsBasedOnRequestType
?requestType=${
  toolname
}&term=${
  value
}&page=${
  page}`,
  {
    cancelToken: new CancelToken((c) => {
      cancel = c
    })
  })
}

function getClustor (projectkey) {
  return axiosInstance.get(`/api/v1/requestaccess/getClustor?projectKey=${
    projectkey
  }`)
}
function getRolesBasedOnClustor (toolname, projectkey, isKey, clustor) {
  return axiosInstance.get(`/api/v1/requestaccess/getRolesBasedOnClustor
?toolName=${
  toolname
}&projectKey=${
  projectkey
}&iskey=${
  isKey}&clustor=${
  clustor}`)
}
function getProjectRoles (toolname, projectkey, isKey) {
  return axiosInstance.get(`/api/v1/requestaccess/getRoles?toolName=${
    toolname
  }&projectKey=${
    projectkey
  }&iskey=${
    isKey}`)
}

function GetRolesForProjectsValidation (username,
  toolname,
  projectkey,
  reqtype) {
  return axiosInstance.get(`/api/v1/requestaccess/GetRolesForProjectsValidation
?username=${
  username
}&toolName=${
  toolname
}&projectKey=${
  projectkey
}&reqtype=${
  reqtype}`)
}
function canAccountRequestAccessToGroup (toolname, keyprefix, value, username,
  key) {
  return axiosInstance.get(`/api/v1/requestaccess/
canAccountRequestAccessToGroup?tool=${
  toolname
}&prefix=${
  keyprefix
}&group=${
  value
}&username=${
  username
}&key=${
  key
}`)
}
function checkUseraccessforAPItool (toolname, username) {
  return axiosInstance.get(`/api/v1/mytools/checkUseraccessforAPItool
?toolname=${
  toolname
}&username=${
  username}`)
}
function checkUseraccessforAPItoolgroup (toolname, username, id) {
  return axiosInstance.get(`/api/v1/mytools/checkUseraccessforAPItoolgroup
?toolname=${
  toolname
}&username=${
  username
}&id=${
  id}`)
}
function checkGroupHierarchy (input) {
  return axiosInstance.post('/api/v1/requestaccess/checkGroupHierarchy', input)
}
function addTocart (input) {
  return axiosInstance.post('/api/v1/requestaccess/addToCart', input)
}
// API to submit all requests without go cart
function submitRequestNow (input) {
  return axiosInstance.post('/api/v1/cart/submitrequestnow', input)
}

// API to get users based on user search term
function getallusersforautocomplete (value) {
  if (cancel !== undefined) {
    cancel()
  }
  return axiosInstance.get(`/api/v1/requestaccess/getallusersforautocomplete
?term=${value}`,
  {
    cancelToken: new CancelToken((c) => {
      cancel = c
    })
  })
}

// API to get user names based on rolename
function getuserbasedonrole (value) {
  return axiosInstance.get(`/api/v1/requestaccess/getuserbasedonrole?roleName=
${value}`)
}

// API get Roles With Validation For Revoke By users
function getRolesWithValidationForRevoke (username, projectKey, tools) {
  return axiosInstance.get(`/api/v1/requestaccess/
getRolesWithValidationForRevoke?username=${username}&projectKey=
${projectKey}&toolName=${tools}`)
}

const requestAccess = {
  getprojects,
  getProjectRoles,
  GetRolesForProjectsValidation,
  canAccountRequestAccessToGroup,
  checkUseraccessforAPItool,
  checkUseraccessforAPItoolgroup,
  checkGroupHierarchy,
  addTocart,
  submitRequestNow,
  getallusersforautocomplete,
  getuserbasedonrole,
  getRolesWithValidationForRevoke,
  getClustor,
  getRolesBasedOnClustor
}

export default requestAccess
