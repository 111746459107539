/* It contains all the API functions which is releated to Support section */
import axios from 'axios'
import axiosInstance from '../../middleware/Interceptor'

const {
  CancelToken
} = axios
let cancel

// Get user counts for the selected tool
function getallUsersByTool (selectedTool) {
  return axiosInstance.get(`/api/v1/bulkmailer/getAllUsersByTool?toolname=
${selectedTool}`)
}

// get users based on the project selected
function getUsersByKey (key) {
  return axiosInstance.get(`/api/v1/bulkmailer/getAllUsersByKey?keyname=${key}`)
}

// get users by group
function getUsersByGroup (input) {
  return axiosInstance.post('/api/v1/bulkmailer/getAllUsersByGroup', input)
}

// get users based on the wild search
function getUsersBasedOnWildCardSearch (input) {
  return axiosInstance.post('/api/v1/bulkmailer/featchUsersFromSearch', input)
}

// Sends mail to the users
function sendMailToUsers (input) {
  return axiosInstance.post('/api/v1/bulkmailer/BulkMailtoUsers', input, {
    responseType: 'text'
  })
}

// get all projects
function getAllProjects (input) {
  if (cancel !== undefined) {
    cancel()
  }
  return axiosInstance.get(`/api/v1/admin/getAllProjects?key=${input}`, {
    cancelToken: new CancelToken((c) => {
      cancel = c
    })
  })
}

const BulkMailService = {
  getallUsersByTool,
  getUsersByKey,
  getUsersByGroup,
  getUsersBasedOnWildCardSearch,
  sendMailToUsers,
  getAllProjects
}

export default BulkMailService
