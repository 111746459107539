/* It contains all the API functions which is releated to Support section */
import axios from 'axios'
import axiosInstance from '../../middleware/Interceptor'

const {
  CancelToken
} = axios
let cancel

// get all available worklfows for the selected type
function getWorkflows (type) {
  return axiosInstance.get(`/api/v1/iris/getAllWorkflows?
type=${type}`)
}

// get workflow for selected project
function getWorkflowForOneProject (key) {
  return axiosInstance.get(`/api/v1/iris/getWorkflowForOneProject?
projectkey=${key}`)
}

// Get investment id
function getInvestmentID (instanceid) {
  if (cancel !== undefined) {
    cancel()
  }
  return axiosInstance.get(`/api/v1/iris/getInstanceID?instanceid=
${instanceid}`,
  {
    cancelToken: new CancelToken((c) => {
      cancel = c
    })
  })
}

// Get SOW for selected investment id
function getSOW (instanceid) {
  return axiosInstance.get(`/api/v1/iris/getSOW?
instantisid=${instanceid}`)
}

// Check entered user in JnJ employee
function checkJnJEmployee (username) {
  return axiosInstance.get(`/api/v1/auth/checkJnJEmployee?
username=${username}`)
}

// Ticket Creation
function ticketCreation (input) {
  return axiosInstance.post('/api/v1/iris/createRequest', input)
}

// Get Artifactory Package
function getArtifactoryPackages () {
  return axiosInstance.get('/api/v1/iris/getArtifactoryPackages')
}
// to clear captcha
function clearCaptchaForBitbucket () {
  return axiosInstance.get('/api/v1/auth/clearCaptcha4BitbucketUser')
}

// to clear captcha
function unlockArtifactory () {
  return axiosInstance.post('/api/v1/artifactory/unlockAccount4ArtifactoryUser',
    {
      // empty for reason
    })
}
// get submitted request by logged in user
function getSupportTicketDetails (username) {
  return axiosInstance.get(`/api/v1/iris/getSupportTicketDetails/
${username}/iris`)
}
// Get IRIS Ticket detail
function getIRISTicketDetails (table, sysParamQuery,
  sysParamFields, sysParamDisplayValue) {
  const tableData = encodeURIComponent(table)
  const sysparamQueryData = encodeURIComponent(sysParamQuery)
  const sysparamFieldsData = encodeURIComponent(sysParamFields)
  const sysparamDisplayValueData = encodeURIComponent(sysParamDisplayValue)
  return axiosInstance.get(`/api/v1/iris/getIrisTableData?
table=${tableData}&sysparm_query=${sysparamQueryData}&
sysparm_fields=${sysparamFieldsData}&
sysparm_display_value=${sysparamDisplayValueData}`)
}

// get issue type for selected project
function getIssueType (projectkey) {
  return axiosInstance.get(`/api/v1/iris/getIssueType?
projectKey=${projectkey}`)
}

// check user is owner or approver
function userIsOwnerApprover (username, projectKey) {
  return axiosInstance.get(`/api/v1/iris/checkUserIsOwnerApprover?
userInput=${username}&projectInput=${projectKey}`)
}

// get Xray alert message
function getXrayAlertMessage () {
  return axiosInstance.get('/api/v1/iris/getXrayAlertMessage')
}

// get gxp alert message
function getGxpAlertMessage () {
  return axiosInstance.get('/api/v1/iris/getGxPAlertMessage')
}

// get qtest alert message
function getQTestAlertMessage () {
  return axiosInstance.get('/api/v1/iris/getQTestAlertMessage')
}

// remove attachment
function removeFileUpload (page, input) {
  return axiosInstance.post(`/api/removefileupload?page=${page}`, input)
}

// get risk assessment form data
function getRAFormData () {
  return axiosInstance.get('/api/v1/iris/getRiskAssessementFormData')
}

// get tools supported by ADP
function getTools (type) {
  return axiosInstance.get(`/api/v1/mytools/getToolsSupportedByADP?
type=${type}`)
}

// download template
function downloadTemplate () {
  return axiosInstance.post('/api/v1/reports/downloadWorkflowTemplate',
    {
    },
    {
      responseType: 'arraybuffer'
    })
}

// get configuration tools for incident
function getConfigurationItem () {
  return axiosInstance.get('/api/v1/iris/getConfigurationItemData')
}

// get location for user
function getLocationForUser (username) {
  return axiosInstance.get(`/api/v1/iris/getLocationForUser?
username=${username}`)
}

// get locations based on location
function getAllLocations (location) {
  if (cancel !== undefined) {
    cancel()
  }
  return axiosInstance.get(`/api/v1/iris/getAllLocations?
location=${location}`, {
    cancelToken: new CancelToken((c) => {
      cancel = c
    })
  })
}

function createIncident (input) {
  return axiosInstance.post('/api/v1/iris/createIncident', input)
}
// to clear captcha for Jira
function clearCaptchaForJira () {
  return axiosInstance.get('/api/v1/jira/clearCaptcha4JiraUser')
}

function getApplicationId (term) {
  return axiosInstance.get(`/api/v1/jira/getApplicationId?term=${term}`)
}

const SupportService = {
  getWorkflows,
  getWorkflowForOneProject,
  getInvestmentID,
  getSOW,
  checkJnJEmployee,
  ticketCreation,
  getArtifactoryPackages,
  getIssueType,
  userIsOwnerApprover,
  getXrayAlertMessage,
  getGxpAlertMessage,
  getQTestAlertMessage,
  clearCaptchaForBitbucket,
  unlockArtifactory,
  getSupportTicketDetails,
  getIRISTicketDetails,
  removeFileUpload,
  getRAFormData,
  getTools,
  downloadTemplate,
  getConfigurationItem,
  getLocationForUser,
  getAllLocations,
  createIncident,
  clearCaptchaForJira,
  getApplicationId
}

export default SupportService
