import React, {
  useContext, useRef
} from 'react'
import {
  Typography,
  makeStyles,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  Alert
} from '@material-ui/lab'
import WarningIcon from '@material-ui/icons/Warning'
import moment from 'moment-timezone'
import UserContext from '../contexts/UserContext'
import AlertDialog from '../alertbox/alertboxComponent'
import SshAndGpgKeyService from '../../services/api/sshAndGpgKeyService'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  nodatatext: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(12),
    textAlign: 'center'
  },
  keyCell: {
    whiteSpace: 'nowrap',
    maxWidth: '500px',
    width: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  table: {
    width: '70%'
  },
  btnmargin: {
    marginTop: 20
  },
  padding20: {
    padding: 20
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  formControl: {
    margin: theme.spacing(1),
    width: 500
  },
  padding0: {
    padding: 0
  }
}))

export default function GPGKeysComponent () {
  const [loading, setLoading] = React.useState(false)
  const [loadingbar, setLoadingBar] = React.useState(false)
  const {
    user
  } = useContext(UserContext)
  const [bitbucketuser, setbitbucketUser] = React.useState(null)
  const [gpgKeys, setGpgKeys] = React.useState([])
  const [deleteid, setDeleteId] = React.useState(null)
  const [deletealert, setDeleteAlert] = React.useState(null)
  const [alertopen, setAlertopen] = React.useState(false)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [alertpassword, setAlertPassword] = React.useState(false)
  const [passphrase, setPassphrase] = React.useState(null)
  const [alertBoxMessage, setAlertBoxMessage] = React.useState(null)
  const classes = useStyles()
  const form = useRef(null)

  function getGPGKey () {
    setLoading(true)
    SshAndGpgKeyService.getuserGPGKey().then((keyresponse) => {
      setGpgKeys(keyresponse.data.values)
      setLoading(false)
    },
    (error) => {
      if (error) {
        setLoading(false)
        seterrorAlert(true)
      }
    })
  }
  /* initial call when page gets loaded */
  React.useEffect(() => {
    setLoading(true)
    SshAndGpgKeyService.checkBitbucketUser(user.name).then((response) => {
      setbitbucketUser(response.data[0].bitbucket_count)
      setLoading(false)
      if (response.data[0].bitbucket_count !== '0') {
        getGPGKey()
      }
    })
  }, [])

  // function to close the alert modal box
  const alertClose = (event) => {
    setAlertopen(false)
    setDeleteAlert(false)
    setAlertPassword(false)
    setPassphrase(null)
  }
  // add GPG key
  const addKey = () => {
    setAlertPassword(false)
    setLoadingBar(true)
    SshAndGpgKeyService.addGPGKey({
      password: passphrase
    }).then((response) => {
      setLoadingBar(false)
      setAlertBoxMessage(response.data)
      setAlertopen(true)
      setPassphrase(null)
      getGPGKey()
    },
    (error) => {
      if (error) {
        setLoadingBar(false)
        seterrorAlert(true)
      }
    })
  }
  // delete GPG key
  const deleteKey = (id) => {
    setDeleteId(id)
    setDeleteAlert(true)
  }
  const handleChange = (event) => {
    setPassphrase(event.target.value)
  }
  const confirmDelete = () => {
    setDeleteAlert(false)
    setLoadingBar(true)
    SshAndGpgKeyService.deleteGPGKey(deleteid).then((response) => {
      setLoadingBar(false)
      getGPGKey()
    },
    (error) => {
      if (error) {
        setLoadingBar(false)
        seterrorAlert(true)
      }
    })
  }

  return (
    <div className={classes.root}>
      {loading || loadingbar ? <LinearProgress /> : null}
      <div className={classes.alertroot}>
        {errorAlert
          ? (
            <Alert severity='error' onClose={() => seterrorAlert(false)}>
              Something went wrong. Contact system
              administrator!
            </Alert>
          ) : null}
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={9}>
          <Paper className={classes.padding20}>
            <Typography variant='subtitle2' gutterBottom>
              GPG Key
              {!loading && bitbucketuser !== '0' && !gpgKeys.length ? (
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  className='pull-right'
                  disabled={loadingbar || user.switch_user}
                  onClick={() => setAlertPassword(true)}
                >
                  Add Key
                </Button>
              ) : null}
            </Typography>
            <Typography variant='caption' color='textSecondary' gutterBottom>
              Use GPG keys to allow verification of your commit signatures
            </Typography>

            {!loading && bitbucketuser === '0' ? (
              <div className={classes.nodatatext}>
                You cannot add keys since you dont have
                access to any bitbucket project.
              </div>
            ) : null}
            {!loading && bitbucketuser !== '0' && !gpgKeys.length ? (
              <div className={classes.nodatatext}>
                No GPG keys have been added
              </div>
            ) : null}

            {gpgKeys.length && !loading ? (
              <TableContainer className={classes.btnmargin}>
                <Table aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell>Key / Subkeys</TableCell>
                      <TableCell> Expiry date</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {gpgKeys.map((row) => (
                      <TableRow key={row.emailAddress}>
                        <TableCell>
                          {row.emailAddress}
                        </TableCell>

                        <TableCell>
                          {row.id}
                          {row.subKeys.map((item) => (
                            <div key={item.id}>{item.id}</div>
                          ))}
                        </TableCell>
                        <TableCell>
                          <div>Never</div>
                          {row.subKeys.map((item) => (
                            <div key={item.expiryDate}>
                              {moment(item.expiryDate).format('LL')}
                            </div>
                          ))}
                        </TableCell>
                        <TableCell className={classes.padding0}>
                          <IconButton
                            aria-label='delete'
                            disabled={user.switch_user}
                            onClick={() => deleteKey(row.id)}
                          >
                            <DeleteIcon color='secondary' />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
            {!loading
              ? (
                <div style={{
                  marginTop: 20
                }}
                >
                  <Typography
                    variant='caption'
                    color='textSecondary'
                    gutterBottom
                  >
                    <WarningIcon
                      style={{
                        color: '#eea508'
                      }}
                      fontSize='small'
                    />
                    {' '}
                    It will take approximately 30 secs to add GPG key.
                  </Typography>
                </div>
              )
              : null}
            <AlertDialog
              handleClose={alertClose}
              alertopen={alertopen}
              key='alert-teams'
              message={alertBoxMessage}
              okbuttonalert
              title='GPG Key'
              downloadIcon
            />
            <AlertDialog
              handleClose={alertClose}
              alertopen={deletealert}
              message='Do you want to delete the GPG key ?'
              confirmbutton={confirmDelete}
              key='alert-delete'
              okbuttonalert={false}
              title='Alert'
            />
            <Dialog
              onClose={alertClose}
              aria-labelledby='simple-dialog-title'
              open={alertpassword}
            >
              <ValidatorForm ref={form} onSubmit={(event) => addKey(event)}>

                <DialogTitle id='alert-dialog-title'>
                  Passphrase
                </DialogTitle>
                <DialogContent>

                  <FormControl className={classes.formControl}>
                    <TextValidator
                      label='Enter your Passphrase *'
                      name='password'
                      onChange={handleChange}
                      value={passphrase}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />
                  </FormControl>
                </DialogContent>

                <DialogActions>
                  <Button onClick={alertClose} color='default'>
                    Cancel
                  </Button>
                  <Button type='submit' color='primary' autoFocus>
                    Add
                  </Button>
                </DialogActions>
              </ValidatorForm>
            </Dialog>
          </Paper>
        </Grid>
      </Grid>

    </div>
  )
}
