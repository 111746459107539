import axios from 'axios'
import cookies from 'js-cookie'

const axiosInstance = axios.create({
})

axiosInstance.interceptors.request.use((headerConfig) => {
  const token1 = cookies.get('token')
  if (token1) {
    headerConfig.headers.Authorization = `Bearer ${token1}`
  }
  return headerConfig
})

// Add a 401 response interceptor
axiosInstance.interceptors.response.use((response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      if (error.response.config.url === '/api/login' ||
        error.response.config.url === '/api/ApproveOrRejectRequest') {
        return Promise.reject(error)
      }
      const {
        pathname, search
      } = window.location
      if (pathname && pathname !== '/timout' && pathname !== '/' &&
        pathname !== '/login' && pathname !== '/errorpage') {
        cookies.set('returnUrl', `${pathname}${search || ''}`)
      }
      if (pathname === '/' || pathname === '/login' ||
        error.response.data === 'no_token_defined' ||
        error.response.data === 'Error Page' ||
        error.response.data === 'Must be Admin to access this end point!') {
        cookies.remove('username', {
          path: '/'
        })
        cookies.remove('token', {
          path: '/'
        })
        window.location = '/login'
      } else if (error.response.data === 'Timeout') {
        cookies.remove('username', {
          path: '/'
        })
        cookies.remove('token', {
          path: '/'
        })

        window.location = '/timeout'
      } else if (error.response.data === 'Invalid_token') {
        cookies.remove('username', {
          path: '/'
        })
        cookies.remove('token', {
          path: '/'
        })
        window.location = '/login'
      } else {
        return Promise.reject(error)
      }
    } else {
      return Promise.reject(error)
    }
  })
export default axiosInstance
