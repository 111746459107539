import React, {
  useRef, useContext
} from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  createStyles,
  makeStyles,
  FormControl,
  LinearProgress,
  List,
  ListItem,
  FormControlLabel,
  Checkbox,
  Theme
} from '@material-ui/core'
import {
  ValidatorForm, TextValidator
} from 'react-material-ui-form-validator'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import requestAccess from '../../services/api/requestaccessService'
import reportServices from '../../services/api/reportServices'
import AlertDialog from '../alertbox/alertboxComponent'
import adminServices from '../../services/api/adminConfigService'
import UserContext from '../contexts/UserContext'

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  }
}))

export default function RemoveUserComponent () {
  const {
    user
  } = useContext(UserContext)
  const [loading, setLoading] = React.useState(false)
  const [loadingbar, setLoadingBar] = React.useState(false)
  const [successAlert, setsuccessAlert] = React.useState(false)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [failalertMessage, setFailalertMessage] = React.useState(null)
  const [successalertMessage, setSuccessalertMessage] = React.useState(null)
  const [allProjects, setAllprojects] = React.useState([])
  const [allRoles, setAllroles] = React.useState([])
  const [allUsers, setAllUsers] = React.useState([])
  const [selectedGroups, setSelectedGroups] = React.useState([])
  const [alertbox, Setalertbox] = React.useState(false)
  const [oneGroupAlert, setOneGroupAlert] = React.useState(false)
  const [keyPrefix, setKeyPrefix] = React.useState(null)
  const [realKey, setRealKey] = React.useState(null)
  const classes = useStyles()
  const form = useRef(null)

  // form data declaration
  const [formData, setFormData] = React.useState({
    projectkey: '',
    project: null,
    chooseUser: '',
    chooseUserName: '',
    rolename: '',
    usernameDisplay: ''
  })

  // reset form
  const resetForm = () => {
    setFormData({
      projectkey: '',
      project: null,
      chooseUser: '',
      chooseUserName: '',
      rolename: '',
      usernameDisplay: ''
    })
    setAllUsers([])
    setAllprojects([])
    setAllroles([])
    setSelectedGroups([])
    setKeyPrefix(null)
    setRealKey(null)
    seterrorAlert(false)
  }

  /* function to get the projects list based on the search key */
  const getProjects = (key) => {
    if (key) {
      setLoadingBar(true)
      reportServices.getAllToolsAndKeysWithName(key).then((response) => {
        setLoadingBar(false)
        setAllprojects(response.data)
      })
    }
  }

  /* Change project function to retriew groups to remove */
  const changeKey = (value) => {
    if (value) {
      setAllroles([])
      setKeyPrefix(value.key_prefix)
      setRealKey(value.key_name)
      setLoading(true)
      adminServices.revokeAccessCheck(formData.chooseUserName,
        value.toolname, value.key_name).then((response) => {
        setAllroles(response.data)
        setLoading(false)
        setFormData({
          ...formData,
          projectkey: value
        })
      })
    }
  }

  /* function to get the users list based on the search key */
  const getUsers = (username) => {
    setLoadingBar(true)
    requestAccess.getallusersforautocomplete(username).then((response) => {
      setLoadingBar(false)
      setAllUsers(response.data)
    })
  }

  /* change user selection */
  const changeUser = (value) => {
    if (value) {
      setFormData({
        ...formData,
        chooseUser: value,
        chooseUserName: value.name,
        projectkey: ''
      })
      setAllroles([])
    }
  }

  /* Click checkbox function */
  const clickCheckbox = (role, value, index) => {
    const checkedGroup = selectedGroups
    if (selectedGroups.includes(role.group_name)) {
      checkedGroup.splice(selectedGroups.indexOf(role.group_name), 1)
      setSelectedGroups([...checkedGroup])
    } else {
      checkedGroup.push(role.group_name)
      setSelectedGroups([...checkedGroup])
    }
  }

  // submit function
  const handleSubmit = () => {
    if (selectedGroups.length) {
      Setalertbox(true)
    } else {
      setOneGroupAlert(true)
    }
  }

  /** Close alert */
  const handleClose = () => {
    Setalertbox(false)
  }
  /** Close One user alert */
  const handleOneUserClose = () => {
    setOneGroupAlert(false)
  }

  /** ? Submit function */
  const confirmSubmit = () => {
    setLoading(true)
    const input = {
      user: formData.chooseUserName,
      grouparray: selectedGroups,
      projectkey: realKey,
      key_prefix: keyPrefix,
      toolname: formData.projectkey.toolname
    }
    adminServices.userToGroup('remove', input).then((response) => {
      setLoading(false)
      if (response.data === 'success') {
        setSuccessalertMessage('User removed successfully!')
        setsuccessAlert(true)
        setTimeout(() => {
          setsuccessAlert(false)
        }, 20000)
      } else {
        setFailalertMessage(response.data)
        seterrorAlert(true)
      }
    }, (error) => {
      if (error) {
        //  empty on purpose
      }
      setLoading(false)
      setFailalertMessage(`
        Something went wrong. Contact system administrator!
      `)
      seterrorAlert(true)
    })
    resetForm()
    Setalertbox(false)
  }

  return (
    <div>
      {loading ? <LinearProgress /> : null}
      {' '}
      <div className={classes.alertroot}>
        {' '}
        {successAlert ? (
          <Alert severity='success'>{successalertMessage}</Alert>
        ) : null}
        {errorAlert ? (
          <Alert
            severity='error'
            onClose={() => seterrorAlert(false)}
          >
            {failalertMessage}
          </Alert>
        ) : null}
      </div>
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={12} md={7}>
          <Card variant='outlined'>
            <ValidatorForm ref={form} onSubmit={(event) => handleSubmit()}>
              <CardContent>
                <Typography variant='subtitle1' gutterBottom>
                  Remove user from group
                </Typography>
                <div>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id='choose-user-auto-complete'
                      value={formData.chooseUser}
                      className={classes.inputDisplay}
                      options={allUsers}
                      getOptionLabel={
                        (option) => (option.name
                          ? `${option.name} - ${option.displayname}`
                          : option)
                      }
                      loading={allUsers.length === 0 && loadingbar}
                      filterOptions={(options, state) => options}
                      getOptionSelected={
                        (option, userValue) => option.name === userValue.name
                      }
                      onChange={(event, userValue) => changeUser(userValue)}
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          name='chooseUser'
                          onChange={(event) => getUsers(event.target.value)}
                          value={formData.chooseUser}
                          label='Choose a user '
                          validators={['required']}
                          errorMessages={['This field is required']}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id='select-project'
                      options={allProjects}
                      value={formData.projectkey ? formData.projectkey : ''}
                      getOptionLabel={
                        (option) => (option.key ? option.key : option)
                      }
                      loading={allProjects.length === 0 && loadingbar}
                      className={classes.inputDisplay}
                      disabled={formData.chooseUser === ''}
                      getOptionSelected={
                        (option, value) => option.key === value.key
                      }
                      onChange={(event, value) => changeKey(value)}
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          label='Type a valid project key *'
                          name='projectkey'
                          onChange={(event) => getProjects(event.target.value)}
                          value={formData.projectkey}
                          validators={['required']}
                          errorMessages={['This field is required']}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </CardContent>
              <CardActions className={classes.footer}>
                <Button
                  variant='contained'
                  size='small'
                  onClick={resetForm}
                >
                  Reset
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  size='small'
                  disabled={loading || user.switch_user}
                >
                  Remove User
                </Button>
              </CardActions>
            </ValidatorForm>
          </Card>
        </Grid>
        {allRoles.length
          ? (
            <Grid item xs={12} md={5}>
              <Card variant='outlined'>
                <CardContent>
                  <Typography
                    variant='subtitle1'
                    gutterBottom
                  >
                    Roles with access
                  </Typography>

                  <List component='nav' aria-label='main mailbox folders'>
                    {allRoles.map((role, i) => (
                      <ListItem
                        key={role.group_name}
                        // className={getClassname(role.warnType)}
                      >
                        <FormControlLabel
                          control={(
                            <Checkbox
                              name='checkedA'
                              value={role}
                              checked={
                                selectedGroups.includes(role.group_name)
                              }
                              label={role.group_name}
                              onChange={
                                (event, value) => clickCheckbox(role, value, i)
                              }
                            />
                          )}
                        />
                        {role.group_name}
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          ) : null}
      </Grid>
      <AlertDialog
        handleClose={handleOneUserClose}
        alertopen={oneGroupAlert}
        message='Select a minimum of one group and then try to remove'
        key='alert3'
        title='Alert'
        okbuttonalert
      />
      <AlertDialog
        handleClose={handleClose}
        alertopen={alertbox}
        confirmbutton={confirmSubmit}
        key='alert2'
        message='Are you sure want to remove the user from selected groups?'
        okbuttonalert={false}
        title='Alert'
      />

    </div>
  )
}