import React from 'react'
import {
  Typography, Grid, Switch, makeStyles, TextField
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20
  },
  textfield: {
    width: '80%'
  }
}))

export default function ApplicationConfigComponent ({
  page, configdata, handleChange, switchToggle
}) {
  const classes = useStyles()

  const getDetailContent = () => {
    switch (page) {
    case 'Settings':
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>Email Notification</Grid>
            <Grid item xs={6} md={8}>
              <Switch
                onChange={(e) => switchToggle('settings', e)}
                name='email_notification'
                checked={configdata.settings.email_notification}
              />
            </Grid>
            <Grid item xs={6} md={3}>Provisioning</Grid>
            <Grid item xs={6} md={8}>
              <Switch
                onChange={(e) => switchToggle('settings', e)}
                name='provisioning'
                checked={configdata.settings.provisioning}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                label='Session Timeout(mins)'
                onChange={(e) => handleChange('settings', e)}
                name='session_timeout'
                value={configdata.settings.session_timeout}
                className={classes.textfield}
                type='number'
              />
            </Grid>
          </Grid>
        </div>
      )
    case 'Knowledge Base': return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <TextField
              label='Help Page URL'
              name='help_page'
              onChange={(e) => handleChange('knowledgebase', e)}
              value={configdata.knowledgebase.help_page}
              className={classes.textfield}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              label='Clone Repo Page URL'
              name='clone_repo_page'
              onChange={(e) => handleChange('knowledgebase', e)}
              value={configdata.knowledgebase.clone_repo_page}
              className={classes.textfield}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              label='Request Access Page URL'
              name='request_access_page'
              onChange={(e) => handleChange('knowledgebase', e)}
              value={configdata.knowledgebase.request_access_page}
              className={classes.textfield}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              label='Risk Assessment Page URL'
              name='risk_assessment_page'
              onChange={(e) => handleChange('knowledgebase', e)}
              value={configdata.knowledgebase.risk_assessment_page}
              className={classes.textfield}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              label='New Project Creation Page URL'
              name='project_creation'
              onChange={(e) => handleChange('knowledgebase', e)}
              value={configdata.knowledgebase.project_creation}
              className={classes.textfield}
            />
          </Grid>
        </Grid>
      </div>
    )
    case 'Banner': return (
      <div className={classes.root}>
        {configdata && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                label='Announcement'
                name='message'
                multiline
                rows='4'
                onChange={(e) => handleChange(e)}
                value={configdata.message}
                autoFocus
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <Typography variant='subtitle1'>
                Visibility
              </Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <Switch
                onChange={(e) => handleChange(e)}
                name='visibility'
                checked={configdata.visibility}
              />
            </Grid>
          </Grid>
        )}
      </div>
    )
    case 'Logger':
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={2}>Debug</Grid>
            <Grid item xs={6} md={8}>
              <Switch
                onChange={(e) => switchToggle('logger', e)}
                name='debug'
                checked={configdata.logger.debug}
              />
            </Grid>
          </Grid>
        </div>
      )
    default:
      return null
    }
  }
  return (
    getDetailContent()
  )
}
