import React from 'react'
import {
  makeStyles, Typography
} from '@material-ui/core'
import logo from '../../assets/images/AppDev_Full_Logo.png'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  logo: {
    margin: theme.spacing(5),
    textAlign: 'center'
  },
  container: {
    marginLeft: 200
  },
  link: {
    color: '#3f51b5',
    cursor: 'pointer',
    textDecoration: 'underline'
  }
}))

const Timeout = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <h3>
        Your session has timed out
      </h3>
      <div>
        For your security,
        you have been logged out of your App Dev Portal due to inactivity.
      </div>
      <div>
        <div>
          Please click
          {' '}
          <a
            className={classes.link}
            onClick={() => (window.location = '/login')}
          >
            here
          </a>
          {' '}
          to login again.
        </div>
      </div>
    </div>
  )
}

const ErrorPage = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <h3>
        Access policy evaluation is already in progress
        for your current session.
      </h3>
      <div>
        You might see this message if you are on a different browser tab
        than the one where you started the access policy.
        Please continue to work in the previous browser tab and
        close this window immediately.
      </div>
      <div>
        <div>
          If you have reached this message due to some other error, click
          {' '}
          <a
            className={classes.link}
            onClick={() => (window.location = '/login')}
          >
            here
          </a>
          {' '}
          to create a new session.
        </div>
      </div>
    </div>
  )
}

export default function ErrorPageComponent (props) {
  const classes = useStyles()
  const Redirect = () => {
    window.location = '/login'
  }

  return (
    <div className={classes.root}>
      <div className={classes.logo}>
        <img
          src={logo}
          width='400'
          height='100'
          alt=''
        />
      </div>
      {props.data === '/errorpage' ? ErrorPage() : Timeout()}
    </div>
  )
}
