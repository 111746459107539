/* eslint indent: 0 */
import React, {
  useContext, useRef
} from 'react'
import {
  fade,
  createStyles,
  makeStyles,
  useTheme,
  Theme
} from '@material-ui/core/styles'
import moment from 'moment-timezone'

import {
  TextValidator, ValidatorForm
} from 'react-material-ui-form-validator'
import {
  Autocomplete
} from '@material-ui/lab'
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputBase,
  Badge,
  MenuItem,
  Menu,
  Collapse,
  Hidden,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Grid,
  Card,
  CardContent,
  Popover
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import HowToRegIcon from '@material-ui/icons/HowToReg'
import clsx from 'clsx'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import SearchIcon from '@material-ui/icons/Search'
import AccountCircle from '@material-ui/icons/AccountCircle'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import MoreIcon from '@material-ui/icons/MoreVert'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import {
  BrowserRouter as Router,
  Link,
  withRouter,
  Switch
} from 'react-router-dom'
import parse from 'html-react-parser'
import HomeIcon from '@material-ui/icons/Home'
import AppsIcon from '@material-ui/icons/Apps'
import HelpIcon from '@material-ui/icons/Help'
import AccessibilityIcon from '@material-ui/icons/Accessibility'
import GroupIcon from '@material-ui/icons/Group'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import ListAltIcon from '@material-ui/icons/ListAlt'
import SettingsIcon from '@material-ui/icons/Settings'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import cookies from 'js-cookie'
import PersonIcon from '@material-ui/icons/Person'
import PeopleIcon from '@material-ui/icons/People'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined'
import TrackChangesIcon from '@material-ui/icons/TrackChanges'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import TuneIcon from '@material-ui/icons/Tune'
import GradeIcon from '@material-ui/icons/Grade'
import EmailIcon from '@material-ui/icons/Email'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle'
import QueueIcon from '@material-ui/icons/Queue'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import requestAccess from '../../services/api/requestaccessService'
import logo from '../../assets/images/AppDev_Full_Logo.png'
import PrivateRoute from '../../auth/privateRoute'
import PathContext from '../contexts/PathContext'
import UserContext from '../contexts/UserContext'
import HomePageComponent from '../home/homepageComponent'
import AppLinksComponent from '../applinks/applinksComponent'
import MyApprovalsComponent from '../myapprovals/myapprovalsComponent'
import ByUserComponent from '../byuser/byuserComponent'
import TemporaryAccessComponent
from '../temporaryaccess/temporaryAccessComponent'
import MyRequestsComponent from '../myrequests/myrequestsComponent'
import SupportComponent from '../support/supportComponent'
import ToolAccessComponent from '../toolaccess/toolaccessComponent'
import ProjectAccessComponent from '../projectaccess/projectaccessComponent'
import AccessHistoryComponent from '../accesshistory/accessHistoryComponent'
import ByRoleComponent from '../byrole/byroleComponent'
import LoginService from '../../services/api/loginService'
import CartService from '../../services/api/cartService'
import CartComponent from '../cart/cartComponent'
import DelegateComponent from '../delegate/delegateComponent'
import ModelUserComponent from '../modeluser/modelUserComponent'
import MyTemplatesComponent from '../mytemplates/myTemplatesComponent'
import OtherTemplatesComponent from '../othertemplates/otherTemplatesComponent'
import TeamNamesComponent from '../teamnames/teamNamesComponent'
import CreationTicketComponent from '../support/supportCreationTicketComponent'
import UpdateTicketComponent from '../support/supportUpdateTicketComponent'
import IncidentComponent from '../support/supportIncidentComponent'
import ServiceRequestComponent from '../support/supportServiceRequestComponent'
import TemplateDetailsComponent from
  '../templatedetails/templateDetailsComponent'
import RevokeAccessComponent from '../revokeaccess/revokeAccessComponent'
import SupportViewSubmittedRequestComponent from
  '../support/supportViewSubmittedRequestsComponent'
import ChangeApproverComponent from '../changeapprover/changeApproverComponent'
import ProjectKeyComponent from '../projectkey/projectKeyComponent'
import BulkMailComponent from '../bulkmail/bulkMailComponent'

import DashboardComponent from '../dashboards/dashboardComponent'
import XRayReportComponent from '../xrayreports/XRayReportComponent'
import ViewDashboardComponent from '../dashboards/viewDashboardComponent'
import ApprovalWorkflowComponent from
  '../approvalworkflow/approvalWorkflowComponent'
import SSHKeysComponent from '../sshkeys/sshKeysComponent'
import GPGKeysComponent from '../gpgkeys/gpgKeysComponent'
import CloneRepoComponent from '../clonerepo/cloneRepoComponent'
import JenkinsDashboardComponent from
  '../jenkinsdashboard/JenkinsDashboardComponent'
import AdminConfigComponent from '../adminconfig/adminConfigComponent'
import RtmReportComponent from '../rtmreport/rtmReportComponent'
import ChangeOwnerComponent from '../changeowner/changeOwnerComponent'
import EmailContentComponent from '../emailcontent/emailContentComponent'
import AddUserComponent from '../adduser/addUserComponent'
import RemoveUserComponent from '../removeuser/removeUserComponent'
import CreateBitbucketProjectComponent from
  '../createbitbucketproject/createBitbucketProjectComponent'
import GroupAccessComponent from '../groupaccess/groupAccessComponent'
import CreateJWTTokenComponent from '../createjwttoken/createJWTTokenComponent'
import GroupOwnershipComponent from '../groupownership/groupOwnershipComponent'
import RepoPermissionComponent from '../repoPermission/repoPermissionComponent'
import RiskAssessmentComponent from '../riskAssessment/riskAssessmentComponent'
import ArchivedProjectUsersComponent from
  '../archivedprojectusers/archivedProjectUsers'
import GetUserFromGPGComponent from '../gpgkeys/getUserFromGpgComponent'

import ConfExceptionComponent from
  '../confluencebannedwords/confExceptionComponent'
import MyApprovalsService from '../../services/api/myApprovalsService'
import KubeConfigComponent from '../kubeconfig/kubeConfigComponent'
import ArchivedJiraProjectComponent from
  '../archivedJiraProject/archivedJiraProjectComponent'
import GitHubCopilotActivationComponent from
  '../githubcopilot/githubCopilotActivationComponent'

const drawerWidth = 230 // width of left panel
/* All routes and its components with icons */
const componentsAvailable = [
  {
    icon: <GroupIcon />,
    head: 'Request Access'
  },
  {
    icon: <PlaylistAddCheckIcon />,
    head: 'Management'
  },
  {
    icon: <BusinessCenterIcon />,
    head: 'DevOps'
  },
  {
    icon: <ListAltIcon />,
    head: 'Reports'
  },
  {
    icon: <AccountBoxIcon />,
    head: 'Admin'
  },
  {
    key: 'HomePageComponent',
    icon: <HomeIcon />,
    componentName: HomePageComponent
  },
  {
    key: 'AppLinksComponent',
    icon: <AppsIcon />,
    componentName: AppLinksComponent
  },
  {
    key: 'SupportComponent',
    icon: <HelpIcon />,
    componentName: SupportComponent
  },
  {
    key: 'ToolAccessComponent',
    icon: <AccessibilityIcon />,
    componentName: ToolAccessComponent
  },
  {
    key: 'MyApprovalsComponent',
    icon: <HowToRegIcon />,
    componentName: MyApprovalsComponent
  },
  {
    key: 'ByUserComponent',
    icon: <PersonIcon />,
    componentName: ByUserComponent
  },
  {
    key: 'ByRoleComponent',
    icon: <PeopleIcon />,
    componentName: ByRoleComponent
  },
  {
    key: 'ModelUserComponent',
    icon: <SupervisedUserCircleIcon />,
    componentName: ModelUserComponent
  },
  {
    icon: <AccessTimeIcon />,
    key: 'TemporaryAccessComponent',
    componentName: TemporaryAccessComponent
  },
  {
    key: 'MyRequestsComponent',
    icon: <AssignmentIndIcon />,
    componentName: MyRequestsComponent
  },
  {
    key: 'MyTemplatesComponent',
    icon: <PersonIcon />,
    componentName: MyTemplatesComponent
  },
  {
    key: 'OtherTemplatesComponent',
    icon: <PeopleIcon />,
    componentName: OtherTemplatesComponent
  },
  {
    key: 'RevokeAccessComponent',
    icon: <PersonOutlineOutlinedIcon />,
    componentName: RevokeAccessComponent
  },
  {
    key: 'TeamNamesComponent',
    icon: <PeopleAltOutlinedIcon />,
    componentName: TeamNamesComponent
  },
  {
    key: 'ApprovalWorkflowComponent',
    icon: <TrackChangesIcon />,
    componentName: ApprovalWorkflowComponent
  },
  {
    key: 'GroupOwnershipComponent',
    icon: <GradeIcon />,
    componentName: GroupOwnershipComponent
  },
  {
    key: 'RiskAssessmentComponent',
    icon: <ImportContactsIcon />,
    componentName: RiskAssessmentComponent
  },
  {
    key: 'SSHKeysComponent',
    icon: <VpnKeyIcon />,
    componentName: SSHKeysComponent
  },
  {
    key: 'GPGKeysComponent',
    icon: <VpnKeyIcon />,
    componentName: GPGKeysComponent
  },
  {
    key: 'CloneRepoComponent',
    icon: <FileCopyIcon />,
    componentName: CloneRepoComponent
  },
  {
    key: 'JenkinsDashboardComponent',
    icon: <TuneIcon />,
    componentName: JenkinsDashboardComponent
  },
  {
    key: 'ProjectAccessComponent',
    icon: <ListAltIcon />,
    componentName: ProjectAccessComponent
  },
  {
    key: 'AccessHistoryComponent',
    icon: <PictureAsPdfIcon />,
    componentName: AccessHistoryComponent
  },
  {
    key: 'RtmReportComponent',
    icon: <ViewModuleIcon />,
    componentName: RtmReportComponent
  },
  {
    key: 'GroupAccessComponent',
    icon: <ListAltIcon />,
    componentName: GroupAccessComponent
  },
  {
    key: 'ConfExceptionComponent',
    icon: <ListAltIcon />,
    componentName: ConfExceptionComponent
  },
  {
    key: 'XRayReportComponent',
    icon: <ListAltIcon />,
    componentName: XRayReportComponent
  },
  {
    key: 'ArchivedJiraProjectComponent',
    icon: <ListAltIcon />,
    componentName: ArchivedJiraProjectComponent
  },
  {
    key: 'DashboardComponent',
    icon: <ListAltIcon />,
    componentName: DashboardComponent
  },
  {
    key: 'AdminConfigComponent',
    icon: <SettingsIcon />,
    componentName: AdminConfigComponent
  },
  {
    key: 'ChangeOwnerComponent',
    icon: <GradeIcon />,
    componentName: ChangeOwnerComponent
  },
  {
    key: 'EmailContentComponent',
    icon: <EmailIcon />,
    componentName: EmailContentComponent
  },
  {
    key: 'ProjectKeyComponent',
    icon: <VpnKeyIcon />,
    componentName: ProjectKeyComponent
  },
  {
    key: 'BulkMailComponent',
    icon: <MailOutlineIcon />,
    componentName: BulkMailComponent
  },
  {
    key: 'AddUserComponent',
    icon: <GroupAddIcon />,
    componentName: AddUserComponent
  },
  {
    key: 'RemoveUserComponent',
    icon: <RemoveCircleOutlineIcon />,
    componentName: RemoveUserComponent
  },
  {
    key: 'ChangeApproverComponent',
    icon: <PeopleAltOutlinedIcon />,
    componentName: ChangeApproverComponent
  },
  {
    key: 'CreateBitbucketProjectComponent',
    icon: <QueueIcon />,
    componentName: CreateBitbucketProjectComponent
  },
  {
    key: 'CreateJWTTokenComponent',
    icon: <NewReleasesIcon />,
    componentName: CreateJWTTokenComponent
  },
  {
    key: 'RepoPermissionComponent',
    icon: <HowToRegIcon />,
    componentName: RepoPermissionComponent
  },
  {
    key: 'ArchivedProjectUsersComponent',
    icon: <GroupIcon />,
    componentName: ArchivedProjectUsersComponent
  },
  {
    key: 'GetUserFromGPGComponent',
    icon: <VpnKeyIcon />,
    componentName: GetUserFromGPGComponent
  },
  {
    key: 'KubeConfigComponent',
    icon: <FileCopyIcon />,
    componentName: KubeConfigComponent
  },
  {
    key: 'GitHubCopilotActivationComponent',
    icon: <TrackChangesIcon />,
    componentName: GitHubCopilotActivationComponent
  }
]

// let myWorkRoutes = []
/** const myWorkRoutes = [
  {
    heading: 'Home',
    icon: <HomeIcon />,
    route: '/home',
    component: HomePageComponent
  },
  {
    heading: 'Application Links',
    icon: <AppsIcon />,
    route: '/applinks',
    component: AppLinksComponent
  },
  {
    heading: 'Support',
    icon: <HelpIcon />,
    route: '/Support',
    component: SupportComponent
  },
  {
    heading: 'Tool Access',
    icon: <AccessibilityIcon />,
    route: '/toolaccess',
    component: ToolAccessComponent
  },
  {
    heading: 'My Approvals',
    icon: <HowToRegIcon />,
    route: '/myapprovals',
    component: MyApprovalsComponent
  },

  {
    heading: 'Request Access',

    openmenu: false,
    icon: <GroupIcon />,
    values: [
      {
        icon: <PersonIcon />,
        route: '/byuser',
        title: 'By User',
        component: ByUserComponent
      },
      {
        icon: <PeopleIcon />,
        route: '/byrole',
        title: 'By Role',
        component: ByRoleComponent
      },
      {
        icon: <SupervisedUserCircleIcon />,
        route: '/modeluser',
        title: 'Model User',
        component: ModelUserComponent
      },
      {
        icon: <AssignmentIndIcon />,
        route: '/tempaccess',
        title: 'Temporary Access',
        component: TemporaryAccessComponent
      },
      {
        icon: <AssignmentIndIcon />,
        route: '/myrequests',
        title: 'My Requests',
        component: MyRequestsComponent
      }

    ]
  },
  {
    heading: 'Management',
    openmenu: false,
    icon: <PlaylistAddCheckIcon />,
    values: [
      {
        icon: <PersonIcon />,
        route: '/mytemplates',
        title: 'My Templates',
        component: MyTemplatesComponent
      },
      {
        icon: <PeopleIcon />,
        route: '/othertemplates',
        title: 'Other Templates',
        component: OtherTemplatesComponent
      },
      {
        icon: <PersonOutlineOutlinedIcon />,
        route: '/revokeaccess',
        title: 'Revoke Access',
        component: RevokeAccessComponent
      },
      {
        icon: <PeopleAltOutlinedIcon />,
        route: '/teamnames',
        title: 'Team Names',
        component: TeamNamesComponent
      },
      {
        icon: <TrackChangesIcon />,
        route: '/approvalworkflow',
        title: 'Approval Workflow',
        component: ApprovalWorkflowComponent
      },
      {
        icon: <GradeIcon />,
        route: '/groupownership',
        title: 'Group Ownership',
        component: GroupOwnershipComponent

      },
      {
        icon: <ImportContactsIcon />,
        route: '/importriskassessment',
        title: 'Import Risk Assessment',
        component: RiskAssessmentComponent
      }
    ]
  },
  {
    heading: 'DevOps',
    openmenu: false,
    icon: <BusinessCenterIcon />,
    values: [
      {
        icon: <VpnKeyIcon />,
        route: '/sshkeys',
        title: 'SSH Keys',
        component: SSHKeysComponent
      },
      {
        icon: <VpnKeyIcon />,
        route: '/gpgkeys',
        title: 'GPG Key',
        component: GPGKeysComponent
      },
      {
        icon: <FileCopyIcon />,
        route: '/createrepofromtemplate',
        title: 'Create Repo From Template',
        component: CloneRepoComponent
      }
    ]
  },
  {
    heading: 'Reports',
    openmenu: false,
    icon: <ListAltIcon />,
    values: [
      {
        icon: <ListAltIcon />,
        route: '/projectaccess',
        title: 'Project Access',
        component: ProjectAccessComponent
      },
      {
        icon: <PictureAsPdfIcon />,
        route: '/accesshistory',
        title: 'Access History',
        component: AccessHistoryComponent
      },
      {
        icon: <ViewModuleIcon />,
        route: '/rtmreport',
        title: 'RTM Report',
        component: RtmReportComponent
      },
      {
        icon: <ListAltIcon />,
        route: '/groupaccess',
        title: 'Group Access',
        component: GroupAccessComponent
      },
      {
        icon: <ListAltIcon />,
        route: '/exclusionaryword',
        title: 'Confluence Exclusionary Words',
        component: ConfExceptionComponent
      },
      {
        icon: <ListAltIcon />,
        route: '/xrayreport',
        title: 'Xray reports',
        component: XRayReportComponent
      }
    ]
  },
  {
    icon: <ListAltIcon />,
    route: '/dashboards',
    heading: 'Dashboards',
    component: DashboardComponent
  },
  {
    heading: 'Admin',
    icon: <AccountBoxIcon />,
    openmenu: false,
    values: [
      {
        icon: <SettingsIcon />,
        route: '/adminconfig',
        title: 'Config',
        component: AdminConfigComponent
      },
      {
        icon: <GradeIcon />,
        route: '/ownership',
        title: 'Ownership',
        component: ChangeOwnerComponent
      },
      {
        icon: <EmailIcon />,
        route: '/emailcontent',
        title: 'Email Content',
        component: EmailContentComponent
      },
      {
        icon: <VpnKeyIcon />,
        route: '/projectKey',
        title: 'Create Project Key',
        component: ProjectKeyComponent
      },
      {
        icon: <MailOutlineIcon />,
        route: '/bulkemail',
        title: 'Bulk Mail',
        component: BulkMailComponent
      },
      {
        icon: <GroupAddIcon />,
        route: '/adduser',
        title: 'Add Users',
        component: AddUserComponent
      },
      {
        icon: <RemoveCircleOutlineIcon />,
        route: '/removeuser',
        title: 'Remove Users',
        component: RemoveUserComponent
      },
      {
        icon: <PeopleAltOutlinedIcon />,
        route: '/changeApprover',
        title: 'Change Approver',
        component: ChangeApproverComponent
      },
      {
        icon: <QueueIcon />,
        route: '/createbitbucketproject',
        title: 'Create Bitbucket Project',
        component: CreateBitbucketProjectComponent
      },
      {
        icon: <NewReleasesIcon />,
        route: '/createjwttoken',
        title: 'Create JWT Token',
        component: CreateJWTTokenComponent
      },
      {
        icon: <HowToRegIcon />,
        route: '/repositorypermission',
        title: 'Repository Permission',
        component: RepoPermissionComponent
      },
      {
        icon: <GroupIcon />,
        route: '/archiveprojectusers',
        title: 'Archive / Restore Project Users',
        component: ArchivedProjectUsersComponent
      },
      {
        icon: <VpnKeyIcon />,
        route: '/getuserfromgpgid',
        title: 'Get Username  From GPG ID',
        component: GetUserFromGPGComponent
      }
    ]
  }
] */
const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex'
  },
  menuroot: {
    width: '99.9%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 350
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },

  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    [theme.breakpoints.up('md')]: {
      position: 'relative'
    }
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  footer: {
    right: 0,
    left: 0,
    bottom: 0,
    padding: '1rem',
    paddingBottom: 0,
    'text-align': 'center'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  titleheading: {
    margin: theme.spacing(2)
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  usertext: {
    fontSize: '0.875rem',
    marginRight: 5
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  drawerclose: {
    width: theme.spacing(10) + 1
  },
  iconwidth: {
    minWidth: 36
  },
  profilemenu: {
    padding: 10,
    marginLeft: 10,
    marginRight: 10
  }
}))
// Main Functional Component
function NavbarComponent (props) {
  const classes = useStyles()
  const theme = useTheme()
  const [allroutes, setAllRoutes] = React.useState([])
  const [myWorkRoutes, setMyWorkRoutes] = React.useState([])
  const {
    path, setPath
  } = useContext(PathContext) // to get and set global variables of current path and component
  const {
    user, setUser
  } = useContext(UserContext) // to get and set the current username
  const [mobileOpen, setMobileOpen] = React.useState(false) // will be used in mobile view
  const [open, setOpen] = React.useState(true)
  const [expanded, setExpanded] = React.useState(false)
  const [allSearchResults, setAllSearchResults] = React.useState([])
  const [loadingsearch, setLoadingSearch] = React.useState(false)
  const [searchbox, setSearchbox] = React.useState(false)
  const [anchorElsearch, setAnchorElsearch] = React.useState(null)
  const [switchUser, setSwitchUser] = React.useState('')
  const [switchalert, setSwitchAlert] = React.useState(false)
  const [allUsers, setAllUsers] = React.useState([])
  const [loadingbar, setLoadingbar] = React.useState(false)
  const [showpage, setShowpage] = React.useState(false)
  const form = useRef(null)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(expanded === panel ? false : panel)
  }
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }
  /* eslint no-dupe-keys: 0 */
  /* eslint jsx-a11y/no-distracting-elements: 0 */

  // Get Users for auto complete
  const getUsers = (username) => {
    setLoadingbar(true)
    requestAccess.getallusersforautocomplete(username).then((response) => {
      setLoadingbar(false)
      setAllUsers(response.data)
    })
  }
  const handleSwitchUser = () => {
    LoginService.setSwitchUser(switchUser.name).then((response) => {
      setSwitchAlert(false)
      setUser({
        ...user,
        approvalcount: response.data.approvalcount,
        cartsize: response.data.cartSize,
        switch_user: user.name,
        baseusername: user.name,
        name: switchUser.name
      })
    })
  }

  const changeSwitchUser = () => {
    handleMenuClose()
    if (user.switch_user) {
      deleteSwitchUser()
    } else {
      setSwitchUser('')
      setSwitchAlert(true)
    }
  }

  const getSearchResults = (ssval) => {
    if (ssval.length > 2) {
      setLoadingSearch(true)
      LoginService.getConfHelpPages(ssval).then((response) => {
        setAllSearchResults(response.data)
        setLoadingSearch(false)
        if (response.data.length) {
          setSearchbox(true)
        } else {
          setSearchbox(false)
        }
      })
    }
  }
  const handleClickSearch = (event) => {
    if (allSearchResults.length) {
      setSearchbox(true)
    }
    setAnchorElsearch(event.currentTarget)
  }
  const setSearchKeyValue = (ssvalue) => {
    if (ssvalue) {
      window.open(ssvalue.link, '_blank')
    }
  }
  function deleteSwitchUser () {
    LoginService.deleteSwitchUser().then((response) => {
      setUser({
        ...user,
        approvalcount: response.data.approvalcount,
        cartsize: response.data.cartSize,
        switch_user: null,
        name: user.baseusername
      })
    })
  }
  // It will be called when the page gets loaded, similer to componentDidMount
  React.useEffect(() => {
    LoginService.getActiveADPMenus().then((response) => {
      const myWorkRoutes = response.data
      myWorkRoutes.map((item1, a) => {
        if (myWorkRoutes[a]) {
          const backendComp = myWorkRoutes[a].component
          const componenttest =
          componentsAvailable.filter((item) => item.key ===
          backendComp)
          if (componenttest.length) {
            myWorkRoutes[a].icon = componenttest[0].icon
            myWorkRoutes[a].component = componenttest[0].componentName
          }

          // This If block is for Menu with expansion
          if (!myWorkRoutes[a].component && myWorkRoutes[a].icon) {
            const head = myWorkRoutes[a].heading
            const icontest = componentsAvailable.filter((item) => item.head ===
            head)
            myWorkRoutes[a].icon = icontest[0].icon
          }
        }
        if (myWorkRoutes[a].values.length) {
          const myAnotherWorkRoutes = myWorkRoutes[a].values
          myAnotherWorkRoutes.map((item2, b) => {
            const backendComp2 = myAnotherWorkRoutes[b].component
            const componenttest2 =
            componentsAvailable.filter((item3) => item3.key ===
            backendComp2)
            if (componenttest2.length) {
              myWorkRoutes[a].values[b].icon =
              componenttest2[0].icon
              myWorkRoutes[a].values[b].component =
              componenttest2[0].componentName
            }
          })
        } else {
          delete myWorkRoutes[a].values
        }
      })
      setAllRoutes(myWorkRoutes)
      setMyWorkRoutes(myWorkRoutes)


    const getDataPromise = (value) => new Promise((resolve, reject) => {
      LoginService.checkAdmin({
        username: value.name
      }).then((response) => {
        resolve(!response.data.isAdmin)
      })
    })
    ValidatorForm.addValidationRule('checkNotAdmin', async (value) => {
      if (value) {
        const result = await getDataPromise(value)
        return result
      }
      return true
    })
    //   setOpen(Boolean(anchorEl1))
    if (allroutes) {
      let pathname
      let component
      setUser({
        ...user,
        name: cookies.get('username')
      })
      LoginService.getAdminaccess().then((response) => {
        if (response) {
          CartService.cartSize(response.data.username).then((cartdata) => {
            setUser({
              ...user,
              name: response.data.switch_user &&
                response.data.switch_user.switch_user
                ? response.data.switch_user.switch_user
                : response.data.username,
              loggedIn: true,
              token: cookies.get('token'),
              isAdmin: response.data.isAdmin,
              switch_user: response.data.switch_user
                ? response.data.switch_user.switch_user
                : user.switch_user,
              baseusername: response.data.username,
              cartsize: cartdata.data.cartSize,
              helpurl: response.data.help_page,
              clone_repo_page: response.data.clone_repo_page,
              project_creation: response.data.project_creation,
              request_access_page: response.data.request_access_page,
              email: response.data.email_notification,
              riskurl: response.data.risk_assessment_page
            })
            if (!user.approvalcount || user.approvalcount === '') {
              const timeZone = moment.tz.guess()
              MyApprovalsService.myPendingRequest(response.data
                .username, timeZone)
              .then((approvalresponse) => {
                const {
                  pendingData
                } = approvalresponse.data
                setUser({
                  ...user,
                  name: response.data.switch_user &&
                response.data.switch_user.switch_user
                ? response.data.switch_user.switch_user
                : response.data.username,
              loggedIn: true,
              token: cookies.get('token'),
              isAdmin: response.data.isAdmin,
              switch_user: response.data.switch_user
                ? response.data.switch_user.switch_user
                : user.switch_user,
              baseusername: response.data.username,
              cartsize: cartdata.data.cartSize,
              helpurl: response.data.help_page,
              clone_repo_page: response.data.clone_repo_page,
              project_creation: response.data.project_creation,
              request_access_page: response.data.request_access_page,
              email: response.data.email_notification,
              riskurl: response.data.risk_assessment_page,
                  approvalcount: pendingData.length
                })

            if (!user.gitdetails || user.gitdetails === '') {
              LoginService.getGitdetails().then((gitData) => {
                setUser({
                  ...user,
                  name: response.data.switch_user &&
                    response.data.switch_user.switch_user
                    ? response.data.switch_user.switch_user
                    : response.data.username,
                  loggedIn: true,
                  token: cookies.get('token'),
                  isAdmin: response.data.isAdmin,
                  switch_user: response.data.switch_user
                    ? response.data.switch_user.switch_user
                    : user.switch_user,
                  baseusername: response.data.username,
                  cartsize: cartdata.data.cartSize,
                  helpurl: response.data.help_page,
                  clone_repo_page: response.data.clone_repo_page,
                  project_creation: response.data.project_creation,
                  request_access_page: response.data.request_access_page,
                  email: response.data.email_notification,
                  gitdetails: gitData.data.gitcommit,
                  riskurl: response.data.risk_assessment_page,
                  approvalcount: pendingData.length
                })

                if (!user.bannerdata || user.bannerdata === '') {
                  LoginService.getBannerData().then((bannerData) => {
                    setUser({
                      ...user,
                      name: response.data.switch_user &&
                        response.data.switch_user.switch_user
                        ? response.data.switch_user.switch_user
                        : response.data.username,
                      loggedIn: true,
                      token: cookies.get('token'),
                      isAdmin: response.data.isAdmin,
                      switch_user: response.data.switch_user
                        ? response.data.switch_user.switch_user
                        : user.switch_user,
                      baseusername: response.data.username,
                      cartsize: cartdata.data.cartSize,
                      helpurl: response.data.help_page,
                      clone_repo_page: response.data.clone_repo_page,
                      project_creation: response.data.project_creation,
                      request_access_page: response.data.request_access_page,
                      email: response.data.email_notification,
                      bannerdata: bannerData.data[0],
                      gitdetails: gitData.data.gitcommit,
                      riskurl: response.data.risk_assessment_page,
                      approvalcount: pendingData.length
                    })
                  })
                }
                  })
                }
              })
            }

            // to set the current path and component into PathContext
            for (const i in myWorkRoutes) {
              if (myWorkRoutes[i].values) {
                for (const j in myWorkRoutes[i].values) {
                  if (
                    props.history.location.pathname ===
                        myWorkRoutes[i].values[j].route
                  ) {
                    if (myWorkRoutes[i].heading === 'Admin' &&
                      !response.data.isAdmin) {
                      props.history.push('/home')
                      pathname = '/home'
                      component = HomePageComponent
                    } else {
                      pathname = props.history.location.pathname
                      component = myWorkRoutes[i].values[j].component
                    }
                  }
                }
              } else if (
                props.history.location.pathname === myWorkRoutes[i].route
              ) {
                pathname = props.history.location.pathname
                component = myWorkRoutes[i].component
              }
            }
            if (pathname) {
              setPath({
                pathname,
                component
              })
            } else {
              setPathName()
            }
            setShowpage(true)
          })
        } else {
          props.history.push('/login')
        }
      })
    }
  })
  if (props.location.search.toLowerCase() === '?fullscreen=yes') {
    setOpen(false)
  }
  }, [props.history.location.pathname, setPath, setUser])

  function setPathName () {
    switch (true) {
    case props.history.location.pathname.includes('cart'):
      setPath({
        pathname: '/cart',
        component: CartComponent
      })
      break
    case props.history.location.pathname.includes('delegate'):
      setPath({
        pathname: '/delegate',
        component: DelegateComponent
      })
      break
    case props.history.location.pathname.includes('myIrisRequests'):
      setPath({
        pathname: '/myIrisRequests',
        component: SupportViewSubmittedRequestComponent
      })
      break
    case props.history.location.pathname.includes('createticket'):
      setPath({
        pathname: '/createticket/:type',
        component: CreationTicketComponent
      })
      break
    case props.history.location.pathname.includes('updateproject'):
      setPath({
        pathname: '/updateproject/:type',
        component: UpdateTicketComponent
      })
      break
    case props.history.location.pathname.includes('createincident'):
      setPath({
        pathname: '/othersupport/:type',
        component: IncidentComponent
      })
      break
    case props.history.location.pathname.includes('servicerequest'):
      setPath({
        pathname: '/othersupport/:type',
        component: ServiceRequestComponent
      })
      break
    case props.history.location.pathname.includes('mytemplates/template'):
      setPath({
        pathname: '/mytemplates/template/:id',
        component: TemplateDetailsComponent
      })
      break
    case props.history.location.pathname.includes('/othertemplates/template'):
      setPath({
        pathname: '/othertemplates/template/:id',
        component: TemplateDetailsComponent
      })
      break
    case props.history.location.pathname.includes('/dashboards/view'):
      setPath({
        pathname: '/dashboards/view/:id',
        component: ViewDashboardComponent
      })
      break
    // skip default case
    }
  }
  // Set tab change values
  const parseHtml = (htmlMessage) => parse(htmlMessage)

  const drawer = (
    <div>

      <Divider />

      <List
        component='nav'
        aria-labelledby='nested-list-subheader'
        className={classes.menuroot}
      >
        {allroutes.map((text, index) => (text.values ? (

          text.heading === 'Admin' && !user.isAdmin ? null : (
            <>
              <ListItem
                button
                value={index}
                onClick={handleChange(text.heading)}
              >
                <ListItemIcon title={text.heading}>{text.icon}</ListItemIcon>
                <ListItemText primary={text.heading} />
                {expanded === text.heading ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </ListItem>
              <Collapse
                in={expanded === text.heading}
                timeout='auto'
                unmountOnExit
              >
                {text.values.map((sub, index) => (
                  <List
                    component='div'
                    disablePadding
                    key={index}
                    style={{
                      maxWidth: drawerWidth
                    }}
                  >
                    <Link
                      to={sub.route}
                      value={sub}
                      onClick={() => setPath({
                        pathname: sub.route,
                        component: sub.component
                      })}
                      style={{
                        color: 'rgba(0, 0, 0, 0.87)',
                        textDecoration: 'none'
                      }}
                    >
                      <ListItem button selected={sub.route === path.pathname}>
                        <ListItemIcon title={sub.heading}>
                          {sub.icon}
                        </ListItemIcon>
                        <ListItemText primary={sub.heading} />
                      </ListItem>
                    </Link>
                  </List>
                ))}
              </Collapse>
            </>
          )
        ) : (
          <Link
            to={text.route}
            value={text}

            onClick={() => setPath({
              pathname: text.route,
              component: text.component
            })}
            style={{
              color: 'rgba(0, 0, 0, 0.87)',
              textDecoration: 'none'
            }}
          >
            <ListItem button selected={text.route === path.pathname}>
              <ListItemIcon title={text.heading}>{text.icon}</ListItemIcon>
              <ListItemText primary={text.heading} />
            </ListItem>
          </Link>
        )))}
      </List>
    </div>
  )
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleProfileMenuOpen = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }
  const logout = () => {
    LoginService.logout().then((response) => {
      cookies.remove('username', {
        path: '/'
      })
      cookies.remove('token', {
        path: '/'
      })
      cookies.remove('returnUrl', {
        path: '/'
      })
      setUser({
        ...user,
        name: '',
        loggedIn: false,
        token: '',
        cartsize: 0,
        approvalcount: 0
      })
      props.history.push('/login')
    })
  }

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >

      {user.isAdmin && (
        <MenuItem
          className={classes.profilemenu}
          onClick={() => changeSwitchUser()}
        >
          <ListItemIcon className={classes.iconwidth}>
            <PeopleIcon />
          </ListItemIcon>
          {user.switch_user
            ? (
              <Link
                to='/home'
                onClick={() => setPath({
                  pathname: '/home',
                  component: HomePageComponent
                })}
                style={{
                  color: 'rgba(0, 0, 0, 0.87)',
                  textDecoration: 'none'
                }}
              >
                Back to my Profile
                {' '}
              </Link>
            ) : 'Switch User'}

        </MenuItem>
      )}
      {/* ) : null} */}
      <MenuItem onClick={handleMenuClose} className={classes.profilemenu}>
        <ListItemIcon className={classes.iconwidth}>
          <SwapHorizontalCircleIcon />
        </ListItemIcon>
        <Link
          to='/delegate'
          onClick={() => setPath({
            pathname: '/delegate',
            component: DelegateComponent
          })}
          style={{
            color: 'rgba(0, 0, 0, 0.87)',
            textDecoration: 'none'
          }}
        >
          Delegate
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose} className={classes.profilemenu}>
        <ListItemIcon className={classes.iconwidth}>
          <InfoOutlinedIcon />
        </ListItemIcon>
        <a
          style={{
            textDecoration: 'none',
            color: 'rgba(0, 0, 0, 0.87)'
          }}
          href={user.helpurl}
          target='_blank'
          rel='noreferrer'
        >
          Help
        </a>
      </MenuItem>
      <MenuItem onClick={handleMenuClose} className={classes.profilemenu}>
        <ListItemIcon className={classes.iconwidth}>
          <FileCopyIcon />
        </ListItemIcon>
        <a
          style={{
            textDecoration: 'none',
            color: 'rgba(0, 0, 0, 0.87)'
          }}
          href='/swagger-docs/'
          target='_blank'
        >
          API
        </a>
      </MenuItem>
      <MenuItem
        onClick={(handleMenuClose, logout)}
        className={classes.profilemenu}
      >
        <ListItemIcon className={classes.iconwidth}>
          <ExitToAppIcon />
        </ListItemIcon>

        Logout
      </MenuItem>
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          aria-label='show 4 new mails'
          color='inherit'
          component={Link}
          to='/myapprovals'
          onClick={() => setPath({
            pathname: '/myapprovals',
            component: MyApprovalsComponent
          })}
        >
          <Badge badgeContent={user.approvalcount} color='secondary'>
            <HowToRegIcon />
          </Badge>
        </IconButton>
        <p>Approvals</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          aria-label='cart size'
          color='inherit'
          component={Link}
          to='/cart'
          onClick={() => setPath({
            pathname: '/cart',
            component: CartComponent
          })}
        >
          <Badge
            badgeContent={user.cartsize === '0' ? 0 : user.cartsize}
            color='secondary'
          >
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
        <p>Cart</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label='account of current user'
          aria-controls='primary-search-account-menu'
          aria-haspopup='true'
          color='inherit'
        >
          <AccountCircle />
        </IconButton>
        <p>{user.name}</p>
      </MenuItem>
    </Menu>
  )
  return (
    showpage ? (
      <div className={classes.root}>
        <Router>
          <CssBaseline />

          <AppBar
            position='fixed'
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open
            })}
          >

            <Toolbar disableGutters={!open} className={classes.toolbar}>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={handleDrawerOpen}
                className={clsx(classes.menuButton, {
                  [classes.menuButtonHidden]: open
                })}
              >
                <MenuIcon />
              </IconButton>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder='Search…'
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                  inputProps={{
                    'aria-label': 'search'
                  }}
                  onClick={handleClickSearch}
                  onChange={(event) => getSearchResults(event.target.value)}
                />
                <Popover
                  id='filter-box'
                  open={searchbox}
                  anchorEl={anchorElsearch}
                  onClose={() => setSearchbox(false)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <Card>
                    <CardContent>
                      <List>
                        {allSearchResults.map((item) => (
                          <ListItem
                            button
                            key={item.title}
                            onClick={() => setSearchKeyValue(item)}
                          >
                            {item.title}
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </Card>
                </Popover>
                {/* <Autocomplete
              style={{ width: '300px', float: 'right' }}
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
      options={allSearchResults}
      getOptionLabel={(option) => (option.title ? option.title : option)}
      onChange={(event, value) => setSearchKeyValue(value)}
       loading={allSearchResults.length === 0 && loadingsearch}
       disableClearable
      renderInput={(params) => (
        <TextField
          {...params}
          // label={label}
          // name={name}
          placeholder='Search...'
          disableUnderline={true}
          onChange={(event) => getSearchResults(event.target.value)}

        />
      )}
    /> */}
              </div>
              <div className={classes.grow}>
                {user.switch_user && (
                  <marquee>
                    You are impersonating as
                    {' '}
                    {user.name}
                    {' '}
                    now
                  </marquee>
                )}

              </div>

              <div className={classes.sectionDesktop}>
                <IconButton
                  aria-label='approval count'
                  color='inherit'
                  component={Link}
                  to='/myapprovals'
                  onClick={() => setPath({
                    pathname: '/myapprovals',
                    component: MyApprovalsComponent
                  })}
                >
                  <Badge badgeContent={user.approvalcount} color='secondary'>
                    <HowToRegIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  aria-label='cart size'
                  color='inherit'
                  component={Link}
                  to='/cart'
                  onClick={() => setPath({
                    pathname: '/cart',
                    component: CartComponent
                  })}
                >
                  <Badge
                    badgeContent={user.cartsize === '0' ? 0 : user.cartsize}
                    color='secondary'
                  >
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  edge='end'
                  aria-label='account of current user'
                  aria-controls={menuId}
                  aria-haspopup='true'
                  onClick={handleProfileMenuOpen}
                  color='inherit'
                >
                  <span className={classes.usertext}>{user.name}</span>
                  <AccountCircle />
                </IconButton>

              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label='show more'
                  aria-controls={mobileMenuId}
                  aria-haspopup='true'
                  onClick={handleMobileMenuOpen}
                  color='inherit'
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>

          {renderMobileMenu}
          {renderMenu}

          <nav
            className={open ? classes.drawer : classes.drawerclose}
            aria-label='mailbox folders'
          >
            <Hidden mdUp>
              <Drawer
                variant='temporary'
                onClose={handleDrawerClose}
                classes={{
                  paper: classes.drawerPaper
                }}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
                open={open}
              >
                <div className={classes.toolbarIcon}>

                  <IconButton onClick={handleDrawerClose}>

                    <ChevronLeftIcon />
                  </IconButton>
                </div>
                {drawer}


              </Drawer>
            </Hidden>
            <Hidden smDown implementation='css'>
              <Drawer
                variant='permanent'
                classes={{
                  paper: classes.drawerPaper,
                  paper: clsx({
                    [classes.drawerPaperClose]: !open
                  })
                }}
                open={open}
              >
                <div className={classes.toolbarIcon}>

                  <div className={classes.title}>
                    <img
                      src={logo}
                      width='170'
                      height='60'
                      style={{
                        padding: '10px 0 0 10px'
                      }}
                      alt=''
                    />
                  </div>

                  <IconButton onClick={handleDrawerClose}>

                    <ChevronLeftIcon />
                  </IconButton>
                </div>
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          <main
            className={`${classes.content} ${path
            .component === ViewDashboardComponent ? 'widgetContainer' : ''}`}
          >

            <div className={classes.appBarSpacer} />
            {user.bannerdata && user.bannerdata.visibility && (
              <div style={{
                marginTop: '-20px'
              }}
              >
                {parseHtml(user.bannerdata.message)}
              </div>
            )}
            <div style={{
              minHeight: '75vh',
              width: open ? '81.8vw' : '92vw'
            }}
            >
              <Switch>
                <PrivateRoute
                  key={path.pathname}
                  path={path.pathname}
                  component={path.component}
                  currentUsername={user.name}
                  exact
                />
              </Switch>
            </div>
            <div className={classes.footer}>
              <Typography variant='caption' color='textSecondary'>
                {user.gitdetails}
              </Typography>
            </div>
          </main>
        </Router>
        {/* Dialog box for switch user */}
        <Dialog
          onClose={() => setSwitchAlert(false)}
          aria-labelledby='simple-dialog-title'
          open={switchalert}
        >
          <ValidatorForm
            ref={form}
            onSubmit={(event) => handleSwitchUser()}
          >
            <DialogTitle id='alert-dialog-title'>
              Switch User
            </DialogTitle>
            <DialogContent>


              <Grid item xs={12} md={12}>
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    id='switch-user-auto-complete'
                    className={classes.inputDisplay}
                    options={allUsers}
                    getOptionLabel={
                      (option) => (option.name
                        ? `${option.name} - ${option.displayname}`
                        : option)
                    }
                    loading={allUsers.length === 0 && loadingbar}
                    filterOptions={(options, state) => options}
                    getOptionSelected={
                      (option, userValue) => option.name === userValue.name
                    }
                    onChange={(event, userValue) => setSwitchUser(userValue)}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        name='switchuser'
                        onChange={(event) => getUsers(event.target.value)}
                        value={switchUser}
                        validators={['required', 'checkNotAdmin']}
                        errorMessages={
                          ['This field is required',
                            'User is not allowed to impersonate admin user.']
                        }
                        label='Enter Username*'
                      />
                    )}
                  />
                </FormControl>
              </Grid>

            </DialogContent>

            <DialogActions>
              <Button onClick={() => setSwitchAlert(false)} color='default'>
                Cancel
              </Button>
              <Button type='submit' color='primary'>
                Switch User
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      </div>
    ) : 'Loading... Please Wait'
  )
}

export default withRouter(NavbarComponent)
