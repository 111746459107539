import React, {
  useContext
} from 'react'
import {
  LinearProgress, Typography, makeStyles, Button
} from '@material-ui/core'
import {
  Link
} from 'react-router-dom'
import UserContext from '../contexts/UserContext'
import PathContext from '../contexts/PathContext'
import SupportServices from '../../services/api/supportService'
import EnhancedTable from '../sections/enhancedTable'
import SupportComponent from './supportComponent'

const headCells = [
  {
    id: 'originalkey',
    numeric: false,
    width: '5',
    page: 'View Submitted Request'
  },
  {
    id: 'ticket_number',
    numeric: false,
    label: 'Ticket Number',
    width: '20'
  },
  {
    id: 'requested_by',
    numeric: false,
    label: 'Created By',
    width: '10',
    type: 'username'
  },
  {
    id: 'requested_for',
    numeric: false,
    label: 'Requested For',
    width: '10',
    type: 'username'
  },
  {
    id: 'created_sort',
    type: 'date',
    numeric: false,
    label: 'Created On',
    width: '15'
  },
  {
    id: 'short_desc',
    numeric: false,
    label: 'Ticket Detail',
    width: '30'
  }
]

const useStyles = makeStyles((theme) => ({
  pullRight: {
    float: 'right'
  },
  nodatatext: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(8),
    textAlign: 'center'
  }
}))

export default function SupportViewSubmittedRequestComponent () {
  const [loading, setLoading] = React.useState(false)
  const [tableData, setTableData] = React.useState([])
  const {
    user
  } = useContext(UserContext)
  const {
    setPath
  } = useContext(PathContext)
  const classes = useStyles()

  /* initial method will be called when the page gets loaded */
  React.useEffect(() => {
    setLoading(true)
    SupportServices.getSupportTicketDetails(user.name).then((result) => {
      setLoading(false)
      const tempArray = result.data
      tempArray.map((item, i) => {
        /* eslint prefer-destructuring: ["error", {"array": false}] */
        tempArray[i].short_desc = tempArray[i].ticket_detail
          .toString().split('|')[1]
      })
      setTableData(tempArray)
    })
  }, [])

  return (
    <div>
      {loading ? <LinearProgress /> : null}
      <Typography variant='subtitle1' gutterBottom>
        Submitted IRIS Requests
      </Typography>
      <Button
        className={classes.pullRight}
        variant='contained'
        component={Link}
        to='/support'
        onClick={() => setPath({
          path: '/support',
          component: SupportComponent
        })}
      >
        Back
      </Button>
      {!loading && !tableData.length ? (
        <div className={classes.nodatatext}>No records found</div>
      ) : null}
      {
        tableData.length ? (
          <EnhancedTable
            key='history-table'
            orderby='created_sort'
            headCells={headCells}
            rowsData={tableData}
            sortorder='desc'
          />
        ) : null
      }
    </div>
  )
}
