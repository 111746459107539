import React from 'react'
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
  Input,
  FormControl,
  InputLabel,
  Typography
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20
  },
  textfield: {
    width: '80%'
  },
  bold: {
    fontWeight: 600,
    marginTop: 20
  }
}))

export default function ArtifactoryAdminComponent ({
  configdata, handleChange
}) {
  const classes = useStyles()
  const [showPassword, setShowPassword] = React.useState(false)

  const handlePassword = (type, e) => {
    handleChange(type, e)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label='System admin'
            name='admin_username'
            onChange={(e) => handleChange('artifactory', e)}
            value={configdata.artifactory.admin_username}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.textfield}>
            <InputLabel htmlFor='standard-adornment-password'>
              Password
            </InputLabel>
            <Input
              label='Password'
              onChange={(e) => handlePassword('artifactory', e)}
              name='password'
              value={configdata.artifactory.password}
              className={classes.textfield}
              type={showPassword ? 'text' : 'password'}
              endAdornment={(
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography
            variant='subtitle1'
            gutterBottom
            className={classes.bold}
          >
            Temporary access settings
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='User check API'
            name='user_check_api'
            rows={3}
            onChange={
              (e) => handleChange('artifactory', e, 'temporary_access')
            }
            value={configdata.artifactory.temporary_access.user_check_api}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Permission update API'
            name='permission_check_api'
            onChange={
              (e) => handleChange('artifactory', e, 'temporary_access')
            }
            value={
              configdata.artifactory.temporary_access.permission_check_api
            }
            className={classes.textfield}
          />
        </Grid>
      </Grid>
    </div>
  )
}
