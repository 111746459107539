import React, {
  useContext, useRef
} from 'react'
import {
  LinearProgress, makeStyles, Grid, Typography, FormControl, MenuItem, Button,
  InputLabel, Select
} from '@material-ui/core'
import {
  ValidatorForm, TextValidator, SelectValidator
} from 'react-material-ui-form-validator'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import moment from 'moment-timezone'
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import RadioButtons from '../inputs/radio'
import requestAccess from '../../services/api/requestaccessService'
import reportServices from '../../services/api/reportServices'
import ToolsService from '../../services/api/toolsService'
import UserContext from '../contexts/UserContext'
import MordernTable from '../sections/mordernTable'
import apigeeService from '../../services/api/apigeeService'

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  },
  inputDisplay: {
    width: '80%'
  },
  inputDisplay2: {
    width: '40%'
  },
  btnmargin: {
    float: 'right',
    marginRight: 20
  },
  datemargin: {
    marginTop: 10
  },
  nodatatext: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(8),
    textAlign: 'center'
  }
}))

export default function AccessHistoryComponent () {
  const headCells = [
    {
      id: 'username',
      numeric: false,
      label: 'Username',
      width: '10%'
    },
    {
      id: 'created_sort',
      type: 'date',
      numeric: false,
      label: 'Requested On',
      width: '15%'
    },
    {
      id: 'requested_by',
      numeric: false,
      label: 'Requested By',
      width: '12%',
      type: 'username'
    },
    {
      id: 'action_owner',
      numeric: false,
      label: 'Action Owner',
      width: '12%',
      type: 'username'
    },
    {
      id: 'action_date_sort',
      type: 'date',
      numeric: false,
      label: 'Action Date',
      width: '15%'
    },
    {
      id: 'status',
      numeric: false,
      label: 'Status',
      width: '10%'
    },
    {
      id: 'type',
      numeric: false,
      label: 'Action Type',
      width: '12%'
    },
    {
      id: 'comment',
      numeric: false,
      label: 'Comment',
      width: '14%'
    }
  ]

  const headCells2 = [
    {
      id: 'group_name',
      numeric: false,
      label: 'Groupname',
      width: '10%'
    },
    {
      id: 'created_sort',
      type: 'date',
      numeric: false,
      label: 'Requested On',
      width: '15%'
    },
    {
      id: 'requested_by',
      numeric: false,
      label: 'Requested By',
      width: '12%',
      type: 'username'
    },
    {
      id: 'action_owner',
      numeric: false,
      label: 'Action Owner',
      width: '12%',
      type: 'username'
    },
    {
      id: 'action_date_sort',
      type: 'date',
      numeric: false,
      label: 'Action Date',
      width: '15%'
    },
    {
      id: 'status',
      numeric: false,
      label: 'Status',
      width: '10%'
    },
    {
      id: 'type',
      numeric: false,
      label: 'Action Type',
      width: '12%'
    },
    {
      id: 'comment',
      numeric: false,
      label: 'Comment',
      width: '14%'
    }
  ]

  const {
    user
  } = useContext(UserContext)
  const timeZone = moment.tz.guess()
  const classes = useStyle()
  const [loading, setLoading] = React.useState(false)
  const [loadingexport, setLoadingExport] = React.useState(false)
  const [loadingbar, setLoadingBar] = React.useState(false)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [failalertMessage, setFailalertMessage] = React.useState(null)
  const [radioValue, setRadioValue] = React.useState('Groups')
  const [responseGroups, setResponseGroups] = React.useState([])
  const [searchkey, setSearchkey] = React.useState(null)
  const [startDate, setStartDate] = React.useState(null)
  const [endDate, setEndDate] = React.useState(null)
  const [startDatePDF, setStartDatePDF] = React.useState(null)
  const [endDatePDF, setEndDatePDF] = React.useState(null)
  const [allUsers, setAllUsers] = React.useState([])
  const [alltools, setAlltools] = React.useState([])
  const [allProjects, setAllprojects] = React.useState([])
  const [allRoles, setAllRoles] = React.useState([])
  const [apiToolGroups, setApiToolGroups] = React.useState([])
  const [apigeeOrgs, setApigeeOrgs] = React.useState([])
  const [apigeeOrgTeamsAndRoles, setApigeeOrgTeamsAndRoles] = React.useState([])
  const form = useRef(null)
  const [formData, setFormData] = React.useState({
    revokeRadio: '',
    chooseUser: '',
    chooseUserName: null,
    tools: '',
    projectkey: '',
    key: '',
    rolename: '',
    org: '',
    apigeeTeam: null
  })

  // Change radio button
  const changeOption = (value) => {
    setRadioValue(value)
    resetForm()
  }

  const heads = radioValue === 'Groups' ? headCells : headCells2

  // Reset the value
  const resetForm = () => {
    setAllUsers([])
    setAllprojects([])
    setAllRoles([])
    setResponseGroups([])
    setApiToolGroups([])
    setSearchkey(null)
    setFormData({
      revokeRadio: '',
      chooseUser: '',
      chooseUserName: null,
      tools: '',
      projectkey: '',
      key: '',
      rolename: '',
      org: '',
      apigeeTeam: ''
    })
    setStartDate(null)
    setEndDate(null)
    setStartDatePDF(null)
    setEndDatePDF(null)
    seterrorAlert(false)
  }

  /* to get the available tools for tool selection
    dropdown when the page gets loaded */
  React.useEffect(() => {
    ToolsService.getAvailableTools('all').then((response) => {
      if (response.data) {
        setAlltools(response.data)
      }
    })
  }, [])

  /* Handle Tool change in tool field */
  const changeTool = (e) => {
    resetForm()
    setFormData({
      ...formData,
      tools: e.target.value,
      projectkey: null,
      apigeeTeam: null
    })
    if (!e.target.value.is_key) {
      setLoading(true)
      ToolsService.getApiToolGroups(e.target.value.name).then((response) => {
        if (response.data) {
          setLoading(false)
          seterrorAlert(false)
          setApiToolGroups(response.data)
          setAllprojects(response.data)
        }
      }, (error) => {
        if (error) {
          setLoading(false)
          setFailalertMessage('Something went wrong. ' +
            'Contact system administrator!')
          seterrorAlert(true)
        }
      })
      setAllRoles([])
      if (e.target.value.name.toLowerCase() === 'saucelabs') {
        setFormData({
          ...formData,
          projectkey: null,
          apigeeTeam: null,
          key: null,
          tools: e.target.value
        })
        requestAccess
          .getProjectRoles(e.target.value.name, null, e.target.value.is_key)
          .then((response) => {
            setAllRoles(response.data)
          })
      }
    }
    if (e.target.value.name === 'Apigee') {
      apigeeService.getApigeeOrgs().then((response) => {
        setApigeeOrgs(response.data)
      })
    }
  }

  // Handling the date change. Start date and end date of the record
  const handleDateChange = (date, data) => {
    if (date !== null) {
      if (date.toString().toLowerCase() !== 'invalid date') {
        if (data === 'st') {
          setStartDatePDF(date.toISOString())
          setEndDatePDF(endDate)
        } else if (data === 'ed') {
          setStartDatePDF(startDate)
          setEndDatePDF(date.toISOString())
        }
        const userName = radioValue === 'user'
          ? formData.chooseUserName : user.name
        getCallService(formData.rolename, userName, data, date.toISOString())
      } else {
        setResponseGroups([])
      }
    } else {
      setResponseGroups([])
    }
  }

  /* function to get the projects list based on the search key */
  const getProjects = (toolname, value) => {
    if (toolname.is_key) {
      setLoadingBar(true)
      requestAccess.getprojects(toolname.name, value).then((response) => {
        setAllprojects(response.data)
        setLoadingBar(false)
      })
    }
  }

  /** Function to change the project key */
  const changeProjectKey = (value) => {
    if (value) {
      const key = value.key ? value.key : value.name
      setFormData({
        ...formData,
        projectkey: value.key
          ? `${value.key} - ${value.key_app_name}` : value.name,
        key: value.key ? value.key : value.name
      })
      setLoading(true)
      requestAccess.getProjectRoles(formData.tools.name, key,
        formData.tools.is_key)
        .then((response) => {
          setLoading(false)
          setAllRoles(response.data)
        })
    }
    setStartDate(null)
    setEndDate(null)
    setStartDatePDF(null)
    setEndDatePDF(null)
    setResponseGroups([])
  }

  /** Function to change the Role in form */
  const changeRole = (e) => {
    setStartDate(null)
    setEndDate(null)
    setStartDatePDF(null)
    setEndDatePDF(null)
    getCallService(e.target.value, user, '')
  }

  const handleApigeeChange = (e) => {
    if (e.target.name === 'org') {
      setFormData({
        ...formData,
        org: e.target.value,
        apigeeTeam: null
      })
      apigeeService.getTeamsForOrgs(e.target.value).then((response) => {
        setApigeeOrgTeamsAndRoles(response.data)
      })
    } else if (e.target.name === 'apigeeTeam') {
      setFormData({
        ...formData,
        apigeeTeam: e.target.value
      })
      const roles = apigeeOrgTeamsAndRoles.find((item) => item.name ===
        e.target.value)
      if (roles) {
        setAllRoles(roles.roles)
      }
    }
  }

  // Get Users for auto complete when we type any username
  const getUsers = (username) => {
    setFormData({
      ...formData,
      searchkey: username
    })
    setLoadingBar(true)
    requestAccess.getallusersforautocomplete(username).then((response) => {
      setLoadingBar(false)
      setAllUsers(response.data)
    })
  }

  // Sets the selected user in the userform fields
  const changeUser = (value) => {
    if (value) {
      setAllUsers([])
      getCallService(formData.rolename, value, '')
    }
  }

  // handle searching from the table
  const handleSearch = (value) => {
    setSearchkey(value)
  }

  // Deals with service method to fetch data from backend to load the table
  const getCallService = (rolename, uservalue, dateChange, date) => {
    const typeOfReport = radioValue === 'Users' ? 'user' : 'group'
    const key = radioValue === 'Users' ? true : formData.tools.is_key
    const project = formData.tools.name === 'Apigee'
      ? formData.apigeeTeam : formData.key || 'N/A'
    let startdate = startDate
    let enddate = endDate
    if (date) {
      if (dateChange === 'st') {
        startdate = date
        setStartDate(date)
      } else {
        enddate = date
        setEndDate(date)
      }
    }
    setResponseGroups([])

    setLoading(true)
    reportServices.getExportData(project, typeOfReport, rolename, uservalue.name
      ? uservalue.name : uservalue, timeZone,
    startdate, enddate, key, formData.tools.name).then((response) => {
      setResponseGroups(response.data.results)
      if (dateChange === '') {
        setStartDate(response.data.minDate_sort)
        setEndDate(response.data.maxDate_sort)
      } else if (dateChange === 'st') {
        setEndDate(response.data.maxDate_sort)
      } else if (dateChange === 'ed') {
        setStartDate(response.data.minDate_sort)
      }
      setLoading(false)
      setFormData({
        ...formData,
        rolename
      })
      if (radioValue === 'Users' && uservalue.name) {
        setFormData({
          ...formData,
          chooseUser: `${uservalue.name} - ${uservalue.displayname}`,
          chooseUserName: uservalue.name
        })
      }
    })
  }

  // Handle submit
  const handleSubmit = () => {
    setLoadingExport(true)
    const typeOfReport = radioValue === 'Users' ? 'user' : 'group'
    const key = radioValue === 'Users' ? true : formData.tools.is_key
    const project = formData.key ? formData.key : formData.apigeeTeam || 'N/A'
    const rolename = formData.rolename ? formData.rolename : ''
    const tools = formData.tools.name ? formData.tools.name : ''
    const username = radioValue === 'Users'
      ? formData.chooseUserName : user.name
    const start = !startDatePDF ? 'undefined' : startDatePDF
    const end = !endDatePDF ? 'undefined' : endDatePDF
    const search = !searchkey ? '' : searchkey
    reportServices.exportReport(project, typeOfReport,
      rolename, username, timeZone,
      start, end, key, tools, search).then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/pdf'
      })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.className = 'hidden'
      link.download = `Report_${new Date()}.pdf`
      document.body.appendChild(link)
      link.click()
      setLoadingExport(false)
    })
  }

  return (
    <div className={classes.root}>
      {loading || loadingexport ? <LinearProgress /> : null}
      <div className={classes.alertroot}>
        {errorAlert
          ? (
            <Alert severity='error' onClose={() => seterrorAlert(false)}>
              {failalertMessage}
            </Alert>
          ) : null}
      </div>
      <Typography variant='subtitle1' gutterBottom>
        Access history
      </Typography>
      <ValidatorForm ref={form}>
        <Grid container item xs={12}>
          <Grid item xs={6} md={8}>
            <RadioButtons
              radio={['Groups', 'Users']}
              selectedvalue={radioValue}
              onChange={(value) => changeOption(value)}
            />
          </Grid>
          <Grid item xs={6} md={1}>
            {responseGroups.length ? (
              <div className={classes.datemargin}>Action Date</div>
            ) : null}
          </Grid>
          <Grid item xs={6} md={3}>
            {responseGroups.length ? (
              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit}
                className={classes.btnmargin}
              >
                Export
              </Button>
            ) : null}
            <Button
              variant='contained'
              onClick={resetForm}
              className={classes.btnmargin}
            >
              Reset
            </Button>
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <Grid container item xs={8}>
            <Grid container item xs={12}>
              <Grid item xs={4}>
                {radioValue === 'Groups' ? (
                  <FormControl className={classes.formControl}>
                    <SelectValidator
                      label='Tool'
                      name='tools'
                      className={classes.inputDisplay}
                      value={formData.tools ? formData.tools : ''}
                      onChange={changeTool}
                    >
                      {alltools.map((value, index) => (
                        <MenuItem value={value} key={value.name}>
                          {value.name}
                        </MenuItem>
                      ))}
                    </SelectValidator>
                  </FormControl>
                ) : null}
              </Grid>
              {formData.tools.is_key || apiToolGroups.length ? (
                radioValue === 'Groups' ? (
                  <Grid item xs={4}>
                    <FormControl className={classes.formControl}>
                      <Autocomplete
                        id='select-project'
                        disabled={formData.tools === ''}
                        options={allProjects}
                        value={formData.projectkey
                          ? formData.projectkey : ''}
                        getOptionLabel={(option) => (option.key
                          ? `${option.key} - ${option.key_app_name}`
                          : option.name
                            ? option.name
                            : option)}
                        loading={allProjects.length === 0 && loadingbar}
                        className={classes.inputDisplay}
                        getOptionSelected={
                          (option, value) => option.name === value.name
                        }
                        onChange={(event, value) => changeProjectKey(value)}
                        renderInput={(params) => (
                          <TextValidator
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label='Project or Team *'
                            name='projectkey'
                            onChange={(event) => getProjects(formData.tools,
                              event.target.value)}
                            value={formData.projectkey}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                ) : null
              ) : null}
              {formData.tools.name === 'Apigee' && (
                <>
                  <Grid
                    item
                    xs={2}
                    style={
                      {
                        marginLeft: -20
                      }
                    }
                  >
                    <FormControl className={classes.formControl}>
                      <InputLabel id='org-simple-select-label'>
                        Select Org
                      </InputLabel>
                      <Select
                        labelId='org-simple-select-label'
                        name='org'
                        onChange={handleApigeeChange}
                        value={formData.org}
                      >
                        {apigeeOrgs.map((value, index) => (
                          <MenuItem value={value} key={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs='2'
                    style={
                      {
                        marginLeft: 10,
                        marginRight: 10
                      }
                    }
                  >
                    <FormControl className={classes.formControl}>
                      <InputLabel id='team-simple-select-label'>
                          Apigee Team
                      </InputLabel>
                      <Select
                        labelId='team-simple-select-label'
                        name='apigeeTeam'
                        onChange={handleApigeeChange}
                        value={formData.apigeeTeam}
                      >
                        {apigeeOrgTeamsAndRoles.map((value, index) => (
                          <MenuItem value={value.name} key={value.name}>
                            {value.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item xs={4}>
                {radioValue === 'Groups' ? (
                  <FormControl className={classes.formControl}>
                    <SelectValidator
                      label='Requested Role'
                      name='rolename'
                      className={classes.inputDisplay}
                      disabled={formData.tools === '' ||
                        formData.tools.is_key
                        ? formData.projectkey === '' : null}
                      required
                      value={formData.rolename ? formData.rolename : ''}
                      onChange={changeRole}
                    >
                      {allRoles.map((value, index) => (
                        <MenuItem value={value.name} key={value.name}>
                          {value.name}
                        </MenuItem>
                      ))}
                    </SelectValidator>
                  </FormControl>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          {radioValue === 'Users' ? (
            <Grid item xs={8}>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  id='choose-user-auto-complete'
                  value={formData.chooseUser}
                  className={classes.inputDisplay2}
                  options={allUsers}
                  filterOptions={(options, state) => options}
                  getOptionLabel={(option) => (option.name
                    ? `${option.name} - ${option.displayname}` : option)}
                  loading={allUsers.length === 0 && loadingbar}
                  getOptionSelected={
                    (option, userValue) => option.name === userValue.name
                  }
                  onChange={(event, userValue) => changeUser(userValue)}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name='chooseUser'
                      onChange={(event) => getUsers(event.target.value)}
                      value={formData.chooseUser}
                      label='Choose a user'
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          ) : null}
          <Grid container item xs={4}>
            <Grid container item xs={12}>
              <Grid item xs={6} className={classes.datemargin}>
                {startDate ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      value={startDate}
                      label='Start Date'
                      onChange={(date) => handleDateChange(date, 'st')}
                      format='yyyy/MM/dd hh:mm a'
                    />
                  </MuiPickersUtilsProvider>
                ) : null}
              </Grid>
              <Grid item xs={6} className={classes.datemargin}>
                {endDate ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      value={endDate}
                      label='End Date'
                      onChange={(date) => handleDateChange(date, 'ed')}
                      format='yyyy/MM/dd hh:mm a'
                    />
                  </MuiPickersUtilsProvider>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ValidatorForm>
      {responseGroups.length && !loading
        ? (
          <MordernTable
            key='access-history-table'
            headCells={heads}
            handleTableSearch={handleSearch}
            rowsData={responseGroups}
            orderby='action_date_sort'
            sortorder='desc'
          />
        ) : null}
      {!loading && !responseGroups.length
        ? (
          <div className={classes.nodatatext} key='nodatatext2'>
            No data to display.
          </div>
        ) : null}
    </div>
  )
}
