import React from 'react'
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
  Input,
  FormControl,
  InputLabel
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20
  },
  textfield: {
    width: '80%'
  }
}))

export default function DatabaseConfigComponent ({
  page, configdata, handleChange, switchToggle
}) {
  const classes = useStyles()
  const [showdbpassword, setShowDbPassword] = React.useState(false)
  const [showdbpassword1, setShowDbPassword1] = React.useState(false)
  const [showcrowdpassword, setShowCrowdPassword] = React.useState(false)
  const [showjirapassword, setShowJiraPassword] = React.useState(false)
  const [showjirapassword1, setShowJiraPassword1] = React.useState(false)
  const [showconfpassword, setShowConfPassword] = React.useState(false)
  const [showconfpassword1, setShowConfPassword1] = React.useState(false)
  const [showdbbpassword, setShowBBPassword] = React.useState(false)
  const [showdbbpassword1, setShowBBPassword1] = React.useState(false)
  const [showsqpassword, setShowSQPassword] = React.useState(false)

  const handlePassword = (type, e) => {
    handleChange(type, e)
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const getDetailContent = () => {
    switch (page) {
    case 'System DB':
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label='Host'
                onChange={(e) => handleChange('dbConfig', e)}
                name='host'
                value={configdata.dbConfig.host}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Port'
                onChange={(e) => handleChange('dbConfig', e)}
                name='port'
                value={configdata.dbConfig.port}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='DB Name'
                onChange={(e) => handleChange('dbConfig', e)}
                name='dbName'
                value={configdata.dbConfig.dbName}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Schema Name'
                onChange={(e) => handleChange('dbConfig', e)}
                name='schemaName'
                value={configdata.dbConfig.schemaName}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Username'
                onChange={(e) => handleChange('dbConfig', e)}
                name='userName'
                value={configdata.dbConfig.userName}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.textfield}>
                <InputLabel htmlFor='standard-adornment-password'>
                  Password
                </InputLabel>
                <Input
                  label='Password'
                  onChange={(e) => handlePassword('dbConfig', e)}
                  name='password'
                  value={configdata.dbConfig.password}
                  className={classes.textfield}
                  type={showdbpassword ? 'text' : 'password'}
                  endAdornment={(
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowDbPassword(!showdbpassword)}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showdbpassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Readonly Username'
                onChange={(e) => handleChange('dbConfig', e)}
                name='readonly_username'
                value={configdata.dbConfig.readonly_username}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.textfield}>
                <InputLabel htmlFor='standard-adornment-password'>
                  Readonly Password
                </InputLabel>
                <Input
                  label='Readonly Password'
                  onChange={(e) => handlePassword('dbConfig', e)}
                  name='readonly_password'
                  value={configdata.dbConfig.readonly_password}
                  className={classes.textfield}
                  type={showdbpassword1 ? 'text' : 'password'}
                  endAdornment={(
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowDbPassword1(!showdbpassword1)}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showdbpassword1 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>
      )
    case 'Crowd DB':
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label='Host'
                onChange={(e) => handleChange('crowdDBConfig', e)}
                name='host'
                value={configdata.crowdDBConfig.host}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Port'
                onChange={(e) => handleChange('crowdDBConfig', e)}
                name='port'
                value={configdata.crowdDBConfig.port}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='DB Name'
                onChange={(e) => handleChange('crowdDBConfig', e)}
                name='dbName'
                value={configdata.crowdDBConfig.dbName}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Username'
                onChange={(e) => handleChange('crowdDBConfig', e)}
                name='userName'
                value={configdata.crowdDBConfig.userName}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.textfield}>
                <InputLabel htmlFor='standard-adornment-password'>
                  Password
                </InputLabel>
                <Input
                  label='Password'
                  onChange={(e) => handlePassword('crowdDBConfig', e)}
                  name='password'
                  value={configdata.crowdDBConfig.password}
                  className={classes.textfield}
                  type={showcrowdpassword ? 'text' : 'password'}
                  endAdornment={(
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={
                          () => setShowCrowdPassword(!showcrowdpassword)
                        }
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showcrowdpassword
                          ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>
      )
    case 'Jira DB':
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              {/* <TextField
                label='Host'
                onChange={(e) => handleChange('jiraDBConfig', e)}
                name='host'
                value={configdata.jiraDBConfig.host}
                className={classes.textfield}
              /> */}
              <TextField
                label='Reader Host'
                onChange={(e) => handleChange('jiraDBConfig', e)}
                name='host'
                value={configdata.jiraDBConfig.reader_host}
                className={classes.textfield}
              />
              <TextField
                label='Writer Host'
                onChange={(e) => handleChange('jiraDBConfig', e)}
                name='host'
                value={configdata.jiraDBConfig.writer_host}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Port'
                onChange={(e) => handleChange('jiraDBConfig', e)}
                name='port'
                value={configdata.jiraDBConfig.port}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='DB Name'
                onChange={(e) => handleChange('jiraDBConfig', e)}
                name='dbName'
                value={configdata.jiraDBConfig.dbName}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Username'
                onChange={(e) => handleChange('jiraDBConfig', e)}
                name='userName'
                value={configdata.jiraDBConfig.userName}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.textfield}>
                <InputLabel htmlFor='standard-adornment-password'>
                  Password
                </InputLabel>
                <Input
                  label='Password'
                  onChange={(e) => handlePassword('jiraDBConfig', e)}
                  name='password'
                  value={configdata.jiraDBConfig.password}
                  className={classes.textfield}
                  type={showjirapassword ? 'text' : 'password'}
                  endAdornment={(
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowJiraPassword(!showjirapassword)}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showjirapassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Readonly Username'
                onChange={(e) => handleChange('jiraDBConfig', e)}
                name='readonly_username'
                value={configdata.jiraDBConfig.readonly_username}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.textfield}>
                <InputLabel htmlFor='standard-adornment-password'>
                  Readonly Password
                </InputLabel>
                <Input
                  label='Readonly Password'
                  onChange={(e) => handlePassword('jiraDBConfig', e)}
                  name='readonly_password'
                  value={configdata.jiraDBConfig.readonly_password}
                  className={classes.textfield}
                  type={showjirapassword1 ? 'text' : 'password'}
                  endAdornment={(
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={
                          () => setShowJiraPassword1(!showjirapassword1)
                        }
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showjirapassword1
                          ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>
      )
    case 'Confluence DB':
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label='Host'
                onChange={(e) => handleChange('confDBConfig', e)}
                name='host'
                value={configdata.confDBConfig.host}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Port'
                onChange={(e) => handleChange('confDBConfig', e)}
                name='port'
                value={configdata.confDBConfig.port}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='DB Name'
                onChange={(e) => handleChange('confDBConfig', e)}
                name='dbName'
                value={configdata.confDBConfig.dbName}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Username'
                onChange={(e) => handleChange('confDBConfig', e)}
                name='userName'
                value={configdata.confDBConfig.userName}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.textfield}>
                <InputLabel htmlFor='standard-adornment-password'>
                  Password
                </InputLabel>
                <Input
                  label='Password'
                  onChange={(e) => handlePassword('confDBConfig', e)}
                  name='password'
                  value={configdata.confDBConfig.password}
                  className={classes.textfield}
                  type={showconfpassword ? 'text' : 'password'}
                  endAdornment={(
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowConfPassword(!showconfpassword)}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showconfpassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Readonly Username'
                onChange={(e) => handleChange('confDBConfig', e)}
                name='readonly_username'
                value={configdata.confDBConfig.readonly_username}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.textfield}>
                <InputLabel htmlFor='standard-adornment-password'>
                  Readonly Password
                </InputLabel>
                <Input
                  label='Readonly Password'
                  onChange={(e) => handlePassword('confDBConfig', e)}
                  name='readonly_password'
                  value={configdata.confDBConfig.readonly_password}
                  className={classes.textfield}
                  type={showconfpassword1 ? 'text' : 'password'}
                  endAdornment={(
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={
                          () => setShowConfPassword1(!showconfpassword1)
                        }
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showconfpassword1
                          ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>
      )
    case 'Bitbucket DB':
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label='Host'
                onChange={(e) => handleChange('bbDBConfig', e)}
                name='host'
                value={configdata.bbDBConfig.host}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Port'
                onChange={(e) => handleChange('bbDBConfig', e)}
                name='port'
                value={configdata.bbDBConfig.port}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='DB Name'
                onChange={(e) => handleChange('bbDBConfig', e)}
                name='dbName'
                value={configdata.bbDBConfig.dbName}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Schema Name'
                onChange={(e) => handleChange('bbDBConfig', e)}
                name='schemaName'
                value={configdata.bbDBConfig.schemaName}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Username'
                onChange={(e) => handleChange('bbDBConfig', e)}
                name='userName'
                value={configdata.bbDBConfig.userName}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.textfield}>
                <InputLabel htmlFor='standard-adornment-password'>
                  Password
                </InputLabel>
                <Input
                  label='Password'
                  onChange={(e) => handlePassword('bbDBConfig', e)}
                  name='password'
                  value={configdata.bbDBConfig.password}
                  className={classes.textfield}
                  type={showdbbpassword ? 'text' : 'password'}
                  endAdornment={(
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowBBPassword(!showdbbpassword)}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showdbbpassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Readonly Username'
                onChange={(e) => handleChange('bbDBConfig', e)}
                name='readonly_username'
                value={configdata.bbDBConfig.readonly_username}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.textfield}>
                <InputLabel htmlFor='standard-adornment-password'>
                  Readonly Password
                </InputLabel>
                <Input
                  label='Readonly Password'
                  onChange={(e) => handlePassword('bbDBConfig', e)}
                  name='readonly_password'
                  value={configdata.bbDBConfig.readonly_password}
                  className={classes.textfield}
                  type={showdbbpassword1 ? 'text' : 'password'}
                  endAdornment={(
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowBBPassword1(!showdbbpassword1)}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showdbbpassword1 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>
      )
    case 'Sonar DB':
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label='Host'
                onChange={(e) => handleChange('sonarDBConfig', e)}
                name='host'
                value={configdata.sonarDBConfig.host}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Port'
                onChange={(e) => handleChange('sonarDBConfig', e)}
                name='port'
                value={configdata.sonarDBConfig.port}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='DB Name'
                onChange={(e) => handleChange('sonarDBConfig', e)}
                name='dbName'
                value={configdata.sonarDBConfig.dbName}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Username'
                onChange={(e) => handleChange('sonarDBConfig', e)}
                name='userName'
                value={configdata.sonarDBConfig.userName}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.textfield}>
                <InputLabel htmlFor='standard-adornment-password'>
                  Password
                </InputLabel>
                <Input
                  label='Password'
                  onChange={(e) => handlePassword('sonarDBConfig', e)}
                  name='password'
                  value={configdata.sonarDBConfig.password}
                  className={classes.textfield}
                  type={showsqpassword ? 'text' : 'password'}
                  endAdornment={(
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowSQPassword(!showsqpassword)}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showsqpassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>
      )
    default:
      return null
    }
  }
  return (
    getDetailContent()
  )
}
