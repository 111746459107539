import React, {
  useContext
} from 'react'
import {
  makeStyles
} from '@material-ui/core/styles'
import {
  LinearProgress,
  Tab,
  Tabs,
  Paper,
  Typography,
  Box,
  Grid,
  Button,
  Checkbox,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  TableHead,
  IconButton
} from '@material-ui/core'
import PropTypes, {
  array
} from 'prop-types'
import Accordion from '@material-ui/core/ExpansionPanel'
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails'
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import moment from 'moment-timezone'
import DeleteIcon from '@material-ui/icons/Delete'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import reportServices from '../../services/api/reportServices'
import MyRequestService from '../../services/api/myrequestService'
import UserContext from '../contexts/UserContext'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexGrow: 1
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  heading1: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '17%',
    flexShrink: 0
  },
  heading2: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '10%',
    flexShrink: 0
  },
  nodatatext: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(8),
    textAlign: 'center'
  }
}))

function TabPanel (props) {
  const {
    children, value, index, ...other
  } = props

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

export default function ConfExceptionComponent () {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const [bannedWordApproval, setBannedWordApproval] = React.useState([])
  const [bannedException, setBannedException] = React.useState([])
  const [selectedItem, setSelectedItem] = React.useState([])
  const [selectedValue, setSelectedValue] = React.useState([])
  const [approveOpen, setApproveOpen] = React.useState(false)
  const [bodyContentId, setBodyContentId] = React.useState('')
  const [exceptionResponse, setExceptionResponse] = React.useState('')
  const [selectedChecked, setSelectedChecked] = React.useState(false)
  const [exceptionWord, setExceptionWord] = React.useState([])
  const [erroredWord, setErroredWord] = React.useState('')
  const [pageId, setPageId] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [exceptionOpen, setExceptionOpen] = React.useState(false)
  const [deleteId, setDeleteId] = React.useState([])
  const [usertooltip, setUsertooltip] = React.useState(null)
  const timeZone = moment.tz.guess()
  const {
    user, setUser
  } = useContext(UserContext)
  React.useEffect(() => {
    setBodyContentId('')

    setErroredWord('')
    initMethod()
  }, [])
  function initMethod () {
    setSelectedItem([])
    setSelectedValue([])
    setLoading(true)
    const {
      name
    } = user
    let switchUser = false
    let swtichedUser = user.name
    if (user.switch_user && user.swtiched_user !== name) {
      switchUser = true
      swtichedUser = user.switch_user
    }
    reportServices.bannedWords(timeZone, switchUser, swtichedUser)
      .then((response) => {
        const bannedWordsArray = response.data
        for (const bannedWord of bannedWordsArray) {
          const bannedWordPageId = bannedWord.data[0].bodycontentid
          bannedWord.title = (
            <a
              href={`${bannedWord.data[0].pageurl}`}
              target='_blank'
              rel='noreferrer'
            >
              {' '}
              {bannedWord.title}
              {' '}
            </a>
          )
          bannedWord.isChecked = false
        }

        reportServices.exceptionWords(timeZone, switchUser, swtichedUser)
          .then((response2) => {
            setBannedException(response2.data)
            setBannedWordApproval(response.data)
            setLoading(false)
          })
      })

    setBodyContentId('')
    setErroredWord('')
    setExceptionResponse('')
  }
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleBannedWord = () => {
    setApproveOpen(false)
    for (const i in selectedValue) {
      /* eslint guard-for-in: 0 */
      selectedValue[i].exception_response = exceptionResponse
    }
    const input = selectedValue
    setLoading(true)
    reportServices.addErrorWords(input).then((response) => {
      setLoading(false)
      initMethod()
    })
  }
  const handleApprove = (e, approvalValue) => {
    setExceptionResponse(e.target.value)
  }
  const handleAlert = (i, flag) => {
    setDeleteId(i)
    setExceptionOpen(flag)
  }
  const handleResponse = () => {
    setExceptionOpen(false)
    reportServices.deleteException(deleteId).then((response) => {
      initMethod()
    })
  }

  const handleApproveOpen = (flag) => {
    setApproveOpen(flag)
  }
  const handleCheckBox = (item) => {
    if (!selectedItem.includes(item.rowid)) {
      selectedValue.push({
        bodycontentid: item.bodycontentid,
        erroredword: item.erroredword,
        rowid: item.rowid
      })
      selectedItem.push(item.rowid)
    } else {
      selectedItem.splice(selectedItem.indexOf(item.rowid), 1)
      for (const i in selectedValue) {
        if (selectedValue[i].rowid === item.rowid) {
          selectedValue.splice(i, 1)
        }
      }
    }
    setSelectedValue(selectedValue)
    setSelectedItem([...selectedItem])
  }
  /*  displayname of username on mouseover */
  const getDisplayname = (item) => {
    const display = `${item.id}_created_by`
    const index1 = null
    if (!item[display]) {
      setUsertooltip(<div>Loading...</div>)
      MyRequestService.getDisplayname(item.created_by).then((response) => {
        // const created_by = response.data
        // const display = created_by;
        setUsertooltip(<div>{response.data[0].displayname}</div>)
      })
    } else {
      setUsertooltip(<div>{item}</div>)
    }
  }

  return (
    <div>
      {loading ? (
        <LinearProgress />
      ) : (
        <div>
          <Paper square>
            <Tabs
              value={value}
              onChange={handleChange}
              variant='scrollable'
              scrollButtons='on'
              indicatorColor='primary'
              textColor='primary'
              aria-label='scrollable force tabs example'
            >
              <Tab
                key='exclusionarywords'
                label='Exclusionary words'
                title='Exclusionary words'
              />
              <Tab key='exception' label='Exception' title='Exception' />
            </Tabs>

          </Paper>

          <Dialog aria-labelledby='simple-dialog-title' open={approveOpen}>
            <DialogTitle id='alert-dialog-title' color='black'>
              Exception Reason
            </DialogTitle>
            <DialogContent style={{
              width: '600px'
            }}
            >
              <DialogContentText id='alert-dialog-description'>
                Enter a reason for adding the word to exception list
              </DialogContentText>
              <TextareaAutosize
                aria-label='empty textarea'
                value={exceptionResponse}
                style={{
                  width: '464px',
                  height: '136px'
                }}
                label='Confluence Words'
                name='Text'
                onChange={handleApprove}
              />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={!exceptionResponse || exceptionResponse === ''}
                onClick={() => handleBannedWord()}
                color='primary'
              >
                Approve
              </Button>
              <Button onClick={() => setApproveOpen(false)} color='primary'>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            aria-labelledby='simple-dialog-title'
            open={exceptionOpen}
            style={{
              backgroundColor: 'transparent'
            }}
          >
            <DialogTitle id='alert-dialog-title' color='black'>
              Exception Response
            </DialogTitle>
            <DialogContent style={{
              width: '600px'
            }}
            >
              <DialogContentText id='alert-dialog-description' color='black'>
                Are you sure want to delete exception response
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setExceptionOpen(false)} color='primary'>
                No
              </Button>
              <Button onClick={() => handleResponse(deleteId)} color='primary'>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <TabPanel value={value} index={0} key='tab-exclusionarywords'>
            {selectedItem.length ? (
              <Button
                style={{
                  float: 'right',
                  marginTop: '-65px'
                }}
                variant='contained'
                color='primary'
                disabled={loading || user.switch_user}
                onClick={() => handleApproveOpen(true)}
              >
                Add To Exception List
              </Button>
            ) : null}

            <div className={classes.heading1}>
              {bannedWordApproval.length ? (
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <p style={{
                      marginLeft: '23px'
                    }}
                    >
                      {' '}
                      <b>Space key</b>
                      {' '}
                    </p>
                  </Grid>
                  <Grid item xs={4}>
                    <p style={{
                      marginLeft: '-193px'
                    }}
                    >
                      {' '}
                      <b>Space name</b>
                      {' '}
                    </p>
                  </Grid>
                  <Grid item xs={4}>
                    <p style={{
                      marginLeft: '-222px'
                    }}
                    >
                      {' '}
                      <b>Page</b>
                      {' '}
                    </p>
                  </Grid>
                </Grid>
              ) : null}
            </div>

            {bannedWordApproval.length ? (
              bannedWordApproval.map((item, i) => (
                <Accordion>

                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'
                  >
                    <Typography className={classes.heading2}>
                      {item.spacekey}
                    </Typography>
                    <Typography className={classes.heading}>
                      {item.spacename}
                    </Typography>
                    <Typography className={classes.heading}>
                      {item.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={classes.root}>
                      <Grid container spacing={3}>
                        <Grid item xs={1}>
                          <p> </p>
                        </Grid>
                        <Grid item xs={3}>
                          <p style={{
                            marginTop: '-25px'
                          }}
                          >
                            {' '}
                            <b>Excluded word </b>
                          </p>
                        </Grid>
                        <Grid item xs={8}>
                          <p style={{
                            marginTop: '-25px'
                          }}
                          >
                            {' '}
                            <b>Recomended word</b>
                            {' '}
                          </p>
                        </Grid>
                      </Grid>
                      {item.data.map((details, j) => (
                        <div>
                          <Grid container spacing={3}>
                            <Grid item xs={1}>
                              <Checkbox
                                style={{
                                  marginTop: '-48px'
                                }}
                                name='checkedA'
                                value={details.rowid}
                                checked={
                                  selectedItem.indexOf(details.rowid) > -1
                                }
                                onChange={() => handleCheckBox(details)}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <p style={{
                                marginTop: '-25px'
                              }}
                              >
                                {' '}
                                {details.erroredword}
                                {' '}
                              </p>
                            </Grid>
                            <Grid item xs={8}>
                              <p style={{
                                marginTop: '-25px'
                              }}
                              >
                                {' '}
                                {details.recommendations}
                                {' '}
                              </p>
                            </Grid>
                          </Grid>
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <p>No data to display</p>
            )}
          </TabPanel>

          <TabPanel value={value} index={1} key='tab-exception'>
            {bannedException.length ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width='20%'>
                        <b>Space Name</b>
                      </TableCell>
                      <TableCell width='20%'>
                        <b>Page Name</b>
                      </TableCell>
                      <TableCell width='10%'>
                        <b>Created By</b>
                      </TableCell>
                      <TableCell width='20%'>
                        <b>Timestamp</b>
                      </TableCell>
                      <TableCell width='10%'>
                        <b>Exclusionary word</b>
                      </TableCell>
                      <TableCell width='40%'>
                        <b>Exception Reasons</b>
                      </TableCell>
                      <TableCell width='10%'>
                        <b>Action</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {bannedException.map((item, l) => (
                      <TableRow key={item.exception}>
                        <TableCell width='20%' scope='exceptions'>
                          {item.spacename}
                        </TableCell>
                        <TableCell width='20%' scope='exceptions'>
                          <a
                            href={`${item.pageurl}`}
                            target='_blank'
                            rel='noreferrer'
                          >
                            {' '}
                            {item.title}
                            {' '}
                          </a>
                        </TableCell>

                        <TableCell width='10%' scope='exceptions'>
                          {/* {item.created_by} */}
                          <Tooltip
                            title={(
                              <span
                                style={{
                                  fontSize: 12,
                                  fontFamily: 'Verdana'
                                }}
                              >
                                {usertooltip}
                              </span>
                            )}
                            onMouseEnter={() => getDisplayname(item)}
                          >
                            <span style={{
                              cursor: 'default'
                            }}
                            >
                              {item.created_by}
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell width='20%' scope='exceptions'>
                          {item.created_on}
                        </TableCell>
                        <TableCell width='10%' scope='exceptions'>
                          {item.erroredword}
                        </TableCell>
                        <TableCell width='40%' scope='exceptions'>
                          {item.exception_reasons}
                        </TableCell>
                        <TableCell width='10%'>
                          <IconButton
                            onClick={() => handleAlert(item.id, true)}
                            style={{
                              opacity: loading || user.switch_user ? 0.4 : 1
                            }}
                            disabled={loading || user.switch_user}
                          >
                            <DeleteIcon color='secondary' />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <p>No data to display</p>
            )}
          </TabPanel>
        </div>
      )}
    </div>
  )
}
