import React from 'react'
import {
  LinearProgress,
  makeStyles,
  Typography,
  Select,
  MenuItem,
  Grid,
  Button,
  FormControl,
  InputLabel
} from '@material-ui/core'
import EnhancedTable from '../sections/enhancedTable'
import ToolServices from '../../services/api/toolsService'
import requestAccess from '../../services/api/requestaccessService'
import reportServices from '../../services/api/reportServices'
import ApigeeService from '../../services/api/apigeeService'
import ComboBox from '../inputs/autocomplete'

const headCells = [
  {
    id: 'originalkey',
    numeric: false,
    page: 'Project Access'
  },
  {
    id: 'group_name',
    numeric: false,
    label: 'Groups'
  },
  {
    id: 'owner_username',
    numeric: false,
    label: 'Owner',
    type: 'username'
  },
  {
    id: 'approver_group',
    numeric: false,
    label: 'Approver Group'
  }
]

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  width: {
    width: '90%'
  },
  nodatatext: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(8),
    textAlign: 'center'
  },
  btnmargin: {
    float: 'right',
    right: 50
  }
}))

export default function ProjectAccessComponent () {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const [loadingbar, setLoadingBar] = React.useState(false)
  const [availableTools, setAvailableTools] = React.useState([])
  const [allProjects, setAllProjects] = React.useState([])
  const [tableData, setTableData] = React.useState([])
  const [tool, setTool] = React.useState(null)
  const [projectKey, setProjectKey] = React.useState(null)
  const [org, setOrg] = React.useState(null)
  const [apigeeTeam, setApigeeTeam] = React.useState(null)
  const [apigeeOrgs, setApigeeOrgs] = React.useState([])
  const [apigeeOrgTeamsAndRoles, setApigeeOrgTeamsAndRoles] = React.useState([])

  /* initial method will be called when the page gets loaded */
  React.useEffect(() => {
    setLoading(true)
    ToolServices.getAvailableTools('idms').then((result) => {
      setLoading(false)
      setAvailableTools(result.data)
    })
  }, [])

  // Handling stuff when tool select box is changes
  const handleToolChange = (e) => {
    resetForm()
    setTool(e.target.value)
    if (e.target.value.name === 'Apigee') {
      ApigeeService.getApigeeOrgs().then((response) => {
        setApigeeOrgs(response.data)
      })
    }
  }

  /* function to get the projects list based on the search key */
  const getProjects = (value) => {
    setLoadingBar(true)
    requestAccess.getprojects(tool.name, value).then((response) => {
      setAllProjects(response.data)
      setLoadingBar(false)
    })
  }

  /* To get Access of projects for selected project */
  const changeProject = (value) => {
    if (value) {
      setLoading(true)
      setTableData([])
      reportServices.getGroupsWithOwnerAndApproverGroup(value.key, tool.name)
        .then((response) => {
          setTableData(response.data)
          setLoading(false)
          setProjectKey(`${value.key} - ${value.key_app_name}`)
        })
    }
  }

  const handleApigeeChange = (e) => {
    if (e.target.name === 'org') {
      setOrg(e.target.value)
      setApigeeTeam('')
      setTableData([])
      ApigeeService.getTeamsForOrgs(e.target.value).then((response) => {
        setApigeeOrgTeamsAndRoles(response.data)
      })
    } else if (e.target.name === 'apigeeTeam') {
      setApigeeTeam(e.target.value)
      const roles = apigeeOrgTeamsAndRoles.find((item) => item.name ===
      e.target.value)
      setTableData([])
      const tableData = []
      if (roles) {
        roles.roles.map((role, i) => {
          const approverGroup = roles.roles.find((item) => item.name
            .includes('admin'))
          tableData.push({
            group_name: role.name,
            approver_group: approverGroup.name,
            toolname: 'Apigee',
            id: i + 1,
            org
          })
        })
      }
      setTimeout(() => {
        setTableData([...tableData])
      }, 200)
    }
  }

  // Reset data
  const resetForm = () => {
    setTool(null)
    setAllProjects([])
    setTableData([])
    setProjectKey(null)
    setOrg(null)
    setApigeeTeam(null)
    setApigeeOrgs([])
    setApigeeOrgTeamsAndRoles([])
  }

  return (
    <div className={classes.root}>
      {loading ? <LinearProgress /> : null}
      <Typography variant='subtitle1' gutterBottom>
        Project Access
        <Button
          variant='contained'
          onClick={resetForm}
          className={classes.btnmargin}
        >
          Reset
        </Button>
      </Typography>
      <Grid container spacing={1} item>
        <Grid item xs={4} md={2}>
          <FormControl className={classes.width}>
            <InputLabel id='demo-simple-select-label'>Select Tool</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              value={tool}
              onChange={handleToolChange}
              className={classes.width}
            >
              {availableTools.map((value, i) => (
                <MenuItem value={value} key={value.name}>
                  {value.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} md={3}>
          {tool && tool.name === 'Apigee'
            ? (
              <FormControl className={classes.width}>
                <InputLabel id='org-simple-select-label'>
                  Select Org
                </InputLabel>
                <Select
                  labelId='org-simple-select-label'
                  name='org'
                  onChange={handleApigeeChange}
                  value={org}
                >
                  {apigeeOrgs.map((value, index) => (
                    <MenuItem value={value} key={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
            : (
              <ComboBox
                allProjects={allProjects}
                name='jira-project-key'
                label='Select Project'
                changeOption={changeProject}
                getProjects={getProjects}
                loading={loadingbar}
                inputValue={projectKey}
              />
            )}
        </Grid>
        {tool && tool.name === 'Apigee' &&
        (
          <Grid item xs={4} md={3}>
            <FormControl className={classes.width}>
              <InputLabel id='team-simple-select-label'>
                  Apigee Team
              </InputLabel>
              <Select
                labelId='team-simple-select-label'
                name='apigeeTeam'
                onChange={handleApigeeChange}
                value={apigeeTeam}
              >
                {apigeeOrgTeamsAndRoles.map((value, index) => (
                  <MenuItem value={value.name} key={value.name}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

      </Grid>
      {tableData.length ? (
        <EnhancedTable
          key='history-table'
          // orderby='action_date'
          headCells={headCells}
          rowsData={tableData}
        />
      ) : null}
      {!loading && !tableData.length ? (
        <div className={classes.nodatatext}>No data to display.</div>
      ) : null}

    </div>
  )
}
