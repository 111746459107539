/* It contains all the API functions which is releated to login page */

import axiosInstance from '../../middleware/Interceptor'

/**
 * Get Groups and its approver groups for the key
 * @param {holds the project key} key
 */
function getGroupsWithApproverGroup (key) {
  return axiosInstance.get(`/api/v1/admin/getGroupsWithApproverGroup?projectKey=
${key}`)
}

/**
 * Updates approver group of a group
 * @param {payload for updating approver group} input
 */
function updateApproverGroup (input) {
  return axiosInstance.post('/api/v1/admin/updateApproverGroupOfAGroup', input)
}

const ChangeApproverService = {
  getGroupsWithApproverGroup,
  updateApproverGroup
}

export default ChangeApproverService
