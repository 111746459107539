/* It contains all the API functions which is releated to team names page */

import axiosInstance from '../../middleware/Interceptor'

// to get jenins/Artifactory teams for given username
function getTeamNames (username) {
  return axiosInstance.get(`/api/v1/admin/getTeamnames?username=${username}`)
}

// to add name for key
function createNewTeamKey (input) {
  return axiosInstance.post('/api/v1/admin/createNewTeamKey', input)
}

// to update name for key
function saveTeamname (input) {
  return axiosInstance.post('/api/v1/admin/saveTeamnames', input)
}

const TeamNameService = {
  getTeamNames,
  createNewTeamKey,
  saveTeamname
}

export default TeamNameService
