import React, {
  useEffect
} from 'react'
import clsx from 'clsx'
import {
  makeStyles
} from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import {
  ListItem, Menu,
  ListItemText, TextField,
  Button,
  ButtonGroup,
  Typography,
  Grid,
  MenuItem,
  withStyles,
  Icon,
  DialogActions,
  FormControl,
  List,
  LinearProgress,
  Tooltip as TtoolTip,
  Input,
  InputLabel,
  IconButton
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import RadioGroup from '@material-ui/core/RadioGroup'
import Alert from '@material-ui/lab/Alert'
import {
  ValidatorForm,
  SelectValidator,
  TextValidator
} from 'react-material-ui-form-validator'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CloseIcon from '@material-ui/icons/Close'
import RadioButtons from '../inputs/radio'
import RichTextEditor from './RichTextEditor'

const useStyles = makeStyles({
  list: {
    width: 'auto',
    padding: 20
  },
  fullList: {
    width: 'auto'
  },
  title: {
    marginBottom: 10
  }
})

export default function SummaryConfig ({
  openDialog, currentConfiguration, close, formData, widgetErrorMessage,
  changeChartType, changeRereshInterval, handleChange, switchToAdvance,
  handleConfiguraitonClose, modalLoading, checkMandatoryFields, showDetails,
  loadingbar, changeSummaryWidgetName, changeSummaryEditorValue, changeFormValue
}) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [currentWidget, setCurrentWidget] = React.useState(false)

  useEffect(() => {
    setOpen(openDialog)
    setCurrentWidget('widget1')
  }, [openDialog])

  const closeDialog = (event) => {
    if (event.type === 'keydown' &&
    (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    close()
  }
  const changeEditorValue = (value) => {
    changeSummaryEditorValue(value, currentWidget)
  }
  const handleButtonChange = (widget) => {
    setCurrentWidget(widget)
  }

  const list = (anchor) => (
    <>
      {modalLoading ? <LinearProgress /> : null}
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === 'top' || anchor === 'bottom'
        })}
        role='presentation'
      >
        <Typography variant='h6'>
          {currentConfiguration.name}
          {' '}
              - Configuration
        </Typography>
        <IconButton
          aria-label='close'
          className='widgetCloseButton'
          onClick={closeDialog}
        >
          <CloseIcon />
        </IconButton>
        <ValidatorForm>
          <div className='modelDialog'>
            {' '}
            {widgetErrorMessage ? (
              <Alert severity='error'>{widgetErrorMessage}</Alert>

            ) : null}
            <br />
            <Button
              color={currentWidget === 'widget1' ? 'primary' : 'default'}
              onClick={() => handleButtonChange('widget1')}
            >
                Top-Left
            </Button>
            <Button
              color={currentWidget === 'widget2' ? 'primary' : 'default'}
              onClick={() => handleButtonChange('widget2')}
            >
                Top-Right
            </Button>
            <Button
              color={currentWidget === 'widget3' ? 'primary' : 'default'}
              onClick={() => handleButtonChange('widget3')}
            >
                Bottom-Left
            </Button>
            <Button
              color={currentWidget === 'widget4' ? 'primary' : 'default'}
              onClick={() => handleButtonChange('widget4')}
            >
                Bottom-Right
            </Button>
            {currentConfiguration[currentWidget] && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <FormControl className={`${classes.title} widgetFormControl`}>
                    <TextValidator
                      value={currentConfiguration[currentWidget].displayname}
                      name='displayname'
                      label='Title'
                      onChange={(e) => changeSummaryWidgetName(e,
                        currentConfiguration, currentWidget)}
                    />
                  </FormControl>
                  <div>
                    <RichTextEditor
                      editorValue={currentConfiguration[currentWidget].value}
                      handleEditorChange={changeEditorValue}
                      showTablesModule='false'
                      showTextModule='true'
                    />
                  </div>
                </Grid>
              </Grid>
            )}
          </div>
          <DialogActions className='dialogAction'>
            <Button
              disabled={modalLoading}
              autoFocus
              onClick={() => handleConfiguraitonClose()}
            >
                  Close
            </Button>
            <Button
              type='button'
              disabled={checkMandatoryFields()}
              autoFocus
              onClick={() => showDetails()}
              color='primary'
            >
                  Save changes
            </Button>
          </DialogActions>
        </ValidatorForm>
      </div>
    </>
  )

  return (
    <div>
      <Drawer
        anchor='right'
        open={openDialog}
        onClose={closeDialog}
      >
        {list('right')}
      </Drawer>
      {/* ))} */}
    </div>
  )
}
