import React, {
  useContext, useRef
} from 'react'
import {
  LinearProgress,
  makeStyles,
  Grid,
  Typography,
  Card,
  CardContent,
  FormControl,
  CardActions,
  Button,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select
} from '@material-ui/core'
import {
  Alert
} from '@material-ui/lab'
import moment from 'moment'
import CreateTokenService from '../../services/api/createTokenService'
import AlertDialog from '../alertbox/alertboxComponent'
import ToolsService from '../../services/api/toolsService'

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  },
  formControl1: {
    margin: theme.spacing(1),
    width: '48%'
  },
  inputDisplay: {
    width: '100%'
  },
  footer: {
    margin: theme.spacing(2),
    float: 'right'
  },
  button2: {
    color: 'pink'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  }
}))

export default function CreateJWTTokenComponent (props) {
  const classes = useStyle()
  const [loading, setLoading] = React.useState(false)
  const [token, setToken] = React.useState(null)
  const [errorAlert, seterrorAlert] = React.useState(false)
  const [failalertMessage, setFailalertMessage] = React.useState(null)
  const [alertbox, setAlerbox] = React.useState(false)
  const [expiryDayCount, setExpiryDayCount] = React.useState(null)
  const [expiryDate, setExpiryDate] = React.useState(false)
  const [state, setState] = React.useState({
    'jira:read': false,
    'jira:write': false,
    'confluence:read': false,
    'confluence:write': false,
    'bitbucket:read': false,
    'bitbucket:write': false,
    'artifactory:read': false,
    'artifactory:write': false,
    'adpdb:read': false,
    'adpdb:write': false,
    'jiradb:read': false,
    'bitbucketdb:read': false
  })
  const [permissions, setPermissions] = React.useState([])
  const permissions1 = [{
    key: 'jira:read',
    tool: 'jira'
  }, {
    key: 'jira:write',
    tool: 'jira'
  }, {
    key: 'confluence:read',
    tool: 'confluence'
  }, {
    key: 'confluence:write',
    tool: 'confluence'
  }, {
    key: 'bitbucket:read',
    tool: 'bitbucket'
  }, {
    key: 'bitbucket:write',
    tool: 'bitbucket'
  },
  {
    key: 'artifactory:read',
    tool: 'artifactory'
  }, {
    key: 'artifactory:write',
    tool: 'artifactory'
  }, {
    key: 'adpdb:read',
    tool: 'adp'
  }, {
    key: 'adpdb:write',
    tool: 'adp'
  }, {
    key: 'jiradb:read',
    tool: 'jira'
  }, {
    key: 'bitbucketdb:read',
    tool: 'bitbucket'
  }]

  /* to get the available tools for tool selection dropdown when the page gets loaded */
  React.useEffect(() => {
    ToolsService.getAvailableTools('all').then((result) => {
      let permissionsdata = []
      result.data.map((item) => {
        const value = permissions1.filter((key) => item.name.toLowerCase()
          .includes(key.tool.toLowerCase()))
        if (value && value.length) {
          permissionsdata = permissionsdata.concat(value)
        }
      })
      const value = permissions1.filter((key) => key.tool
        .toLowerCase() === 'adp')
      if (value && value.length) {
        permissionsdata = permissionsdata.concat(value)
      }
      setPermissions(permissionsdata)
    })
  }, [])
  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked
    })
  }
  const reset = () => {
    setExpiryDate(false)
    setExpiryDayCount(null)
    setState({
      jiraread: false,
      bitbucketread: false,
      confluenceread: false,
      artifactoryread: false,
      jirawrite: false,
      bitbucketwrite: false,
      confluencewrite: false,
      artifactorywrite: false,
      adpread: false,
      adpwrite: false,
      jiradbread: false,
      bitbucketdbread: false
    })
    setToken(null)
  }
  const handleExpiryDate = (e) => {
    setExpiryDayCount('')
    setExpiryDate(e.target.checked)
  }

  const getDaysCount = (expiryDayCount) => {
    let days
    if (expiryDayCount === '3 months') {
      days = 90
    } else if (expiryDayCount === '6 months') {
      days = 180
    } else if (expiryDayCount === '9 months') {
      days = 270
    } else if (expiryDayCount === '1 year') {
      days = 365
    } else if (expiryDayCount === '2 years') {
      days = 730
    } else {
      days = 0
    }
    return days
  }
  const getDays = (options) => {
    const days = getDaysCount(expiryDayCount)
    const someDate = new Date()
    const numberOfDaysToAdd = Number(days)
    const result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd)
    return moment(result).format('DD MMM YYYY')
  }
  const handleCreateToken = () => {
    const inputArray = []
    Object.keys(state).forEach((key) => {
      if (state[key] === true) {
        inputArray.push(key)
      }
    })
    if (inputArray.length) {
      setExpiryDate(false)
      setExpiryDayCount(null)
    } else {
      setAlerbox(true)
    }
  }
  const getTextMessageExipiry = (expiryDate, expiryDayCount) => {
    let value = ''
    if (expiryDate && expiryDayCount) {
      value = `The token will expire on ${getDays(expiryDayCount)}`
    } else if (expiryDate && !expiryDayCount) {
      value = 'Select any one option for expiry time period of the token'
    } else {
      value = `Select the above checkbox for
      configuring automatic expiration of token`
    }
    return value
  }
  const createToken = () => {
    const inputArray = []
    Object.keys(state).forEach((key) => {
      if (state[key] === true) {
        inputArray.push(key)
      }
    })
    if (inputArray.length) {
      const days = getDaysCount(expiryDayCount)

      const input = {
        sub: inputArray,
        days
      }
      setLoading(true)
      CreateTokenService.createJWTToken(input).then((response) => {
        setLoading(false)
        if (response.data.token) {
          setToken(response.data.token)
        } else {
          setFailalertMessage(`Something went wrong. 
            Contact system administrator!`)
          seterrorAlert(true)
        }
      },
      (error) => {
        setLoading(false)
        if (error) {
          setFailalertMessage(`Something went wrong. 
            Contact system administrator!`)
          seterrorAlert(true)
        }
      })
    } else {
      setAlerbox(true)
    }
  }
  return (
    <div>
      {loading ? <LinearProgress /> : null}
      <div className={classes.alertroot}>
        {' '}

        {errorAlert ? (
          <Alert
            severity='error'
            onClose={() => seterrorAlert(false)}
          >
            {failalertMessage}
          </Alert>
        ) : null}
      </div>
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={12} md={5}>
          <Card variant='outlined'>

            <CardContent>
              <Typography variant='subtitle1' gutterBottom>
                Create JWT Token
              </Typography>
              <div style={{
                paddingTop: 10
              }}
              >
                <FormControl
                  component='fieldset'
                  className={classes.formControl}
                >
                  <FormLabel component='legend'>Permissions</FormLabel>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={
                      {
                        display: 'flex'
                      }
                    }
                  >
                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        {permissions.filter((role, i) => i < 6).map((item) => (
                          <FormControlLabel
                            control={(
                              <Checkbox
                                checked={state[item.key]}
                                onChange={handleChange}
                                name={item.key}
                              />
                            )}
                            label={item.key}
                          />
                        ))}

                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        {permissions.filter((role, i) => i > 6).map((item) => (
                          <FormControlLabel
                            control={(
                              <Checkbox
                                checked={state[item.key]}
                                onChange={handleChange}
                                name={item.key}
                              />
                            )}
                            label={item.key}
                          />
                        ))}

                      </FormGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </div>
              <Typography variant='subtitle2' gutterBottom>
          For Security reasons, you can set this token to automatically expire.
            You can&apos;t change the expiry date once the token is created.
              </Typography>
              <div>
                <br />
                <br />
                <br />
                <FormControlLabel
                  onClick={handleExpiryDate}
                  control={<Checkbox checked={expiryDate} />}
                  label='Automatic Expiry'
                />
              </div>
              {' '}
              <div>
                {expiryDate && (
                  <FormControl
                    variant='outlined'
                    label='Days Until Expiry'
                    className={classes.formControl1}
                  >
                    <Select
                      id='expiry-days'
                      value={expiryDayCount}
                      onChange={(e) => setExpiryDayCount(e.target.value)}
                    >
                      <MenuItem value='3 months'>3 months</MenuItem>
                      <MenuItem value='6 months'>6 months</MenuItem>
                      <MenuItem value='9 months'>9 months</MenuItem>
                      <MenuItem value='1 year'>1 year</MenuItem>
                      <MenuItem value='2 years'>2 years</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </div>
              <div>
                <Typography variant='subtitle2' gutterBottom>
                  {`${getTextMessageExipiry(expiryDate, expiryDayCount)}`}
                </Typography>
              </div>
            </CardContent>

            <CardActions className={classes.footer}>
              <Button
                variant='contained'
                size='small'
                onClick={reset}
              >
                Reset
              </Button>
              <Button
                variant='contained'
                color='primary'
                size='small'
                disabled={loading}
                onClick={createToken}
              >
                Create JWT
              </Button>

            </CardActions>

          </Card>
        </Grid>
        {token && (
          <Grid item xs={12} md={7}>
            <Card variant='outlined'>

              <CardContent>
                <Typography variant='subtitle1' gutterBottom>
                  Encoded JWT Token
                </Typography>
                <div style={{
                  paddingTop: 10,
                  wordBreak: 'break-word'
                }}
                >
                  {token}
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>

      <AlertDialog
        handleClose={() => setAlerbox(false)}
        alertopen={alertbox}
        message='Select at lease one permission'
        key='alert2'
        title='Alert'
        okbuttonalert
      />
    </div>

  )
}
