/* It contains all the API functions which is releated to Survey */

import axiosInstance from '../../middleware/Interceptor'

function getSurveyQuestion (data) {
  return axiosInstance.post('/api/v1/survey/getSurveyQuestion', data)
}
function getJpmDetails () {
  return axiosInstance.get('/api/v1/survey/jpmDetails')
}

function storeSurveyResponse (data) {
  return axiosInstance.post('/api/v1/survey/surveyResponse', data)
}

const SurveyService = {
  getSurveyQuestion,
  getJpmDetails,
  storeSurveyResponse
}

export default SurveyService