
/* It contains all the API functions which is releated to Create JWT token section */

import axiosInstance from '../../middleware/Interceptor'

function createJWTToken (input) {
  return axiosInstance.post('/api/v1/admin/createJWToken', input)
}
const CreateTokenService = {
  createJWTToken
}

export default CreateTokenService
