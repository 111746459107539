import React, {
  useContext, useState, useRef, useEffect
} from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  makeStyles,
  FormControl,
  LinearProgress,
  IconButton,
  Checkbox,
  Tooltip,
  FormControlLabel,
  MenuItem
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  useParams, Link
} from 'react-router-dom'
import {
  TextValidator, ValidatorForm, SelectValidator
} from 'react-material-ui-form-validator'
import {
  Autocomplete, Alert
} from '@material-ui/lab'
import {
  FileUploader, FileManager
} from 'reactjs-file-uploader'
import InfoIcon from '@material-ui/icons/Info'
import queryString from 'query-string'
import RadioButtons from '../inputs/radio'
import MultipleSelect from '../inputs/multipleselectbox'
import SupportService from '../../services/api/supportService'
import requestAccess from '../../services/api/requestaccessService'
import UserContext from '../contexts/UserContext'
import AlertDialog from '../alertbox/alertboxComponent'
import SupportContext from '../contexts/SupportContext'
import PathContext from '../contexts/PathContext'
import SupportComponent from './supportComponent'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%'
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  },
  pullRight: {
    float: 'right'
  },
  columnDisplay: {
    display: 'flex',
    flexDirection: 'row'
  },
  labelDisplay: {
    margin: '10px',
    width: '25%'
  },
  inputDisplay: {
    width: '70%'
  },
  attachmentColDisplay: {
    display: 'flex',
    flexDirection: 'columns'
  },
  attachmentDisplay: {
    width: '35%'
  },
  templateColDisplay: {
    margin: '10px',
    width: '50%'
  },
  error: {
    color: 'red'
  },
  here: {
    color: '#007bff',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}))

export default function SupportUpdateicketComponent (props) {
  const queryparams = queryString.parse(props.location.search)
  const {
    setPath
  } = useContext(PathContext)
  const {
    user
  } = useContext(UserContext)
  const {
    support, setSupport
  } = useContext(SupportContext)
  const {
    type
  } = useParams()
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const form = useRef(null)
  const [allUsers, setAllUsers] = useState([])
  const [allProjects, setAllProjects] = useState([])
  const [loadingOptions, setLoadingOptions] = useState(false)
  const [alertwithok, setAlertwithok] = React.useState(false)
  const [alertboxMessage, setalertboxMessage] = React.useState('')
  const [files, setFiles] = useState([])
  const [alertAttachment, setAlertAttachment] = useState(false)
  const [raIssueTypes, setRaIssueTypes] = useState([])
  const [gxpComplianceRisk, setGxpComplianceRisk] = useState([])
  const [supplierAudit, setSupplierAudit] = useState([])
  const [allTools, setAllTools] = useState([])
  const [submitLoader, setSubmitLoader] = useState(false)

  // Return Title of the page
  const showTitle = () => {
    switch (type) {
    case 'enablexray':
      return 'Enable XRay for Jira Project'
    case 'enablera':
      return 'Enable Risk Assessment in Jira Project'
    case 'enablestructure':
      return 'Enable Structure in Jira Project'
    default:
      return 'Update Existing Project for all Tools'
    }
  }

  // Returns alert and it's message
  const showAlert = () => {
    if (support.success === false && support.message !== '') {
      return (
        <Alert
          severity='error'
          onClose={() => setSupport({
            success: false,
            message: ''
          })}
        >
          {support.message}
        </Alert>
      )
    }
  }

  useEffect(() => {
    if (type !== 'updateproject' && queryparams.project) {
      requestAccess.getprojects('jira',
        queryparams.project).then((response) => {
        const filtervalue = response.data
          .filter((item) => item.key.toLowerCase() ===
          queryparams.project.toLowerCase())
        if (filtervalue.length && filtervalue.length === 1) {
          changeProject(filtervalue[0])
        }
      })
    }
    if (type === 'enablexray') {
      ValidatorForm.addValidationRule('checkXrayEnabled', async (value) => {
        if (value) {
          const result = await getDataPromise(value)
          return result
        }
        return true
      })
      const getDataPromise = (value) => new Promise((resolve, reject) => {
        setLoading(true)
        SupportService.getIssueType(value).then((response) => {
          setLoading(false)
          const filter = response.data.filter((issueTypes) => (
            issueTypes.issuetype === 'Test'
          ))
          if (filter.length) {
            resolve(false)
          } else {
            resolve(true)
          }
        })
      })
    } else if (type === 'enablera') {
      setLoading(true)
      SupportService.getRAFormData().then((response) => {
        setLoading(false)
        setGxpComplianceRisk(response.data.gxpData)
        setSupplierAudit(response.data.supplierAuditData)
      })
    } else if (type === 'updateproject') {
      setLoading(true)
      SupportService.getTools('update').then((response) => {
        setLoading(false)
        setAllTools(response.data)
        if (queryparams.tool) {
          const filtervalue1 = response.data
            .filter((item) => item.name.toLowerCase() ===
              queryparams.tool.toLowerCase())
          if (filtervalue1.length && filtervalue1.length === 1) {
            setFormData({
              ...formData,
              toolName: filtervalue1[0].name,
              toolNameDisplayValue: filtervalue1[0]
            })
            if (queryparams.project) {
              requestAccess.getprojects(filtervalue1[0].name,
                queryparams.project).then((response) => {
                const filtervalue = response.data
                  .filter((item) => item.key.toLowerCase() ===
                    queryparams.project.toLowerCase())
                if (filtervalue.length && filtervalue.length === 1) {
                  changeProject(filtervalue[0], filtervalue1[0])
                }
              })
            }
          }
        }
      })
    }
  }, [])

  const [formData, setFormData] = useState({
    requestFor: user.name.toUpperCase(),
    requestForName: user.name,
    projectKey: '',
    projectKeyValue: '',
    additionalInfo: '',
    maxFileSize: 10000000,
    attachmentAnchorTag: '',
    selectedIssueTypes: [],
    supplierAuditAssessment: '',
    gxpComplianceRisk: '',
    toolName: ''
  })

  // Handling change for the user name in fields
  const changeUser = (value) => {
    if (value) {
      setFormData({
        ...formData,
        requestFor: `${value.name} - ${value.displayname
          ? value.displayname
          : null}`,
        requestForName: value.name
      })
    }
    setAllUsers([])
  }

  // Check user is part of owner or approver group of selected project
  const checkUserIsOwnerApprover = (username, project, tool) => {
    if (project.key) {
      setLoading(true)
      SupportService.userIsOwnerApprover(username,
        project.key).then((response) => {
        setLoading(false)
        if (!response.data.exists[0].result) {
          const alertMessage = (
            <div>
              <Typography variant='subtitle2' gutterBottom>
                Only Account Owner and Account Approvers
                 can submit this request.
              </Typography>

              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 'bold'
                }}
              >
                Members in the group:
              </Typography>
              {response.data.members.length > 0 ? (
                <ul>
                  {response.data.members.map((member) => (
                    <li>{member.username}</li>
                  ))}
                </ul>
              ) : (
                <Typography variant='subtitle2'>
                  No members found in the groups
                </Typography>
              )}

            </div>
          )
          setAllProjects([])
          setalertboxMessage(alertMessage)
          setAlertwithok(true)
          setFormData({
            ...formData,
            projectKeyValue: '',
            projectKey: ''
          })
        } else {
          if (formData.toolName.length > 0) {
            setFormData({
              ...formData,
              toolName: formData.toolName,
              projectKey: `${project.key} - ${project.key_app_name}`,
              projectKeyValue: project.key
            })
          } else {
            setFormData({
              ...formData,
              toolName: tool ? tool.name : 'Jira',
              toolNameDisplayValue: tool || formData.toolNameDisplayValue,
              projectKey: `${project.key} - ${project.key_app_name}`,
              projectKeyValue: project.key
            })
          }
          setLoading(true)
          if (type === 'enablexray') {
            SupportService.getXrayAlertMessage().then((alertResponse) => {
              setLoading(false)
              setalertboxMessage(alertResponse.data.alertMessage)
              setAlertwithok(true)
            })
          } else if (type === 'enablera') {
            SupportService.getIssueType(project.key)
              .then((issueTypeResponse) => {
                setLoading(false)
                const issues = []
                issueTypeResponse.data.map((value) => (
                  issues.push({
                    value: value.issuetype
                  })
                ))
                setRaIssueTypes(issues)
              })
          } else {
            setLoading(false)
          }
        }
      })
    }
  }

  // Get Users for auto complete
  const getUsers = (username) => {
    setFormData({
      ...formData,
      searchkey: username
    })
    setLoadingOptions(true)
    requestAccess.getallusersforautocomplete(username).then((response) => {
      setLoadingOptions(false)
      setAllUsers(response.data)
    })
  }

  // Handling change for project key field
  const changeProject = (value, tool) => {
    setAllProjects([])
    if (value) {
      checkUserIsOwnerApprover(formData.requestForName, value, tool)
    } else {
      setFormData({
        ...formData,
        projectKey: '',
        projectKeyValue: ''
      })
    }
  }

  // get project for autocomplete
  const getProjects = (toolname, value) => {
    setLoadingOptions(true)
    let tool = ''
    if (toolname) {
      tool = formData.toolName
    } else {
      tool = 'Jira'
    }
    setFormData({
      ...formData,
      searchkey: value
    })
    if (tool !== 'Jenkins - Artifactory') {
      requestAccess.getprojects(tool, value).then((response) => {
        setLoadingOptions(false)
        setAllProjects(response.data)
      })
    } else {
      requestAccess.getprojects(tool, value, 'needEKS').then((response) => {
        setLoadingOptions(false)
        setAllProjects(response.data)
      })
    }
  }

  // Handling change for the fields
  const handleChange = (e) => {
    if (e.target.name === 'gxpComplianceRisk') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.name,
        gxpDisplayValue: e.target.value
      })
    } else if (e.target.name === 'supplierAuditAssessment') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.name,
        supplierAuditDisplayValue: e.target.value
      })
    } else if (e.target.name === 'toolName') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.name,
        toolNameDisplayValue: e.target.value
      })
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      })
    }
  }

  // issue type selection
  const handleIssueTypeSelect = (e) => {
    setFormData({
      ...formData,
      selectedIssueTypes: e.target.value
    })
  }

  // download template
  const downloadTemplate = () => {
    if (formData.toolName.toLowerCase() !== 'qtest') {
      SupportService.downloadTemplate().then((response) => {
        const blob = new Blob([response.data], {
          type: `application/vnd.openxmlformats-officedocument
            .wordprocessingml.document`
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        // link.className = 'hidden'
        link.download = 'Jira Project Group Approval Request Template.docx'
        document.body.appendChild(link)
        link.click()
      })
    } else {
      const link = document.createElement('a')
      link.href = 'https://edmsviewer.jnj.com/EDMSViewerFileShare/Effective/Forms/FRM-9509.doc'
      link.download = 'qTest Project Group Approval Request Template.doc'
      document.body.appendChild(link)
      link.click()
    }
  }

  // handle submit
  const handleSubmit = (event) => {
    event.preventDefault()
    submitRequest()
  }

  // remove attachment
  const removeAttahment = (file) => {
    files.map((fileitem, i) => {
      if (fileitem.name === file.name) {
        SupportService.removeFileUpload('ticket', {
          filename: file.originalname
        }).then((response) => {
          files.splice(i, 1)
          setFiles([...files])
        })
      }
    })
  }

  const removeEntry = () => {
    setAlertAttachment(true)
    setTimeout(() => {
      setAlertAttachment(false)
    }, 20000)
    files.map((item, i) => {
      if (item.size > formData.maxFileSize) {
        files.splice(i, 1)
        setFiles([...files])
      }
    })
  }

  // set original name for file
  const setFileOriginalName = (file, response) => {
    files.map((fileitem, i) => {
      if (fileitem.name === file.name) {
        files[i].originalname = response.filename
        setFiles(files)
      }
    })
  }

  // Upload Files
  const uploadFiles = (uploadedFiles) => uploadedFiles.map(uploadFile)

  // Upload a single file
  const uploadFile = (file) => (
    <FileUploader
      key={file.key}
      file={file}
      url='/api/fileupload?page=ticket'
      formData={{
        file,
        upload_preset: 'public',
        tags: 'vanilla'
      }}
      maxFileSize={10000000}
      readFile
      autoUpload={file.size < formData.maxFileSize}
    >
      {fileProgress}
    </FileUploader>
  )

  // show file progress
  const fileProgress = ({
    uploadReady,
    downloadComplete,
    /*
    Response text Object (JSON)
     */
    response,
    file
  }) => (
    <div>
      {downloadComplete && response && setFileOriginalName(file, response)}
      {downloadComplete && file.size < formData.maxFileSize ? (
        <Grid
          container
          spacing={1}
          style={{
            marginBottom: 20
          }}
        >
          <Grid
            item
            xs={4}
            md={3}
            style={{
              marginLeft: 30
            }}
          >
            {file.name}
          </Grid>
          <Grid item xs={6} md={4}>
            <LinearProgress
              style={{
                width: '80%'
              }}
              variant='determinate'
              value={100}
            />
            <Typography component='div' variant='caption'>
              100% upload completed
            </Typography>
          </Grid>
          <Grid item xs={2} md={2}>
            <IconButton
              aria-label='delete'
              style={{
                padding: 0
              }}
            >
              <DeleteIcon
                color='secondary'
                onClick={() => removeAttahment(file)}
              />
            </IconButton>
          </Grid>
        </Grid>
      ) : (null)}
      {uploadReady && file.size > formData.maxFileSize
        ? (removeEntry())
        : (null)}
    </div>
  )

  // Submitting request
  const submitRequest = () => {
    const date = new Date()
    let input = {
    }
    let reqDetails = {
    }
    let updateType = ''

    formData.attachmentAnchorTag = ''
    if (files.length > 0) {
      formData.attachmentAnchorTag = '<br>Attachments :'
      files.map((item, i) => {
        if (item.originalname !== undefined) {
          formData.attachmentAnchorTag = `${formData.attachmentAnchorTag} </br><a href='https://${document.location.hostname}/api/uploads/${item.originalname}'>https://${document.location.hostname}/api/uploads/${item.originalname}</a>`
        }
      })
    }

    if (type === 'enablexray') {
      updateType = 'Enable XRay'
      reqDetails = {
        Type: updateType,
        Application: formData.toolName,
        'Project Key': formData.projectKey,
        'Requested Date': date.toString(),
        'Created By': user.name.toUpperCase(),
        Comment: `${formData.additionalInfo} ${formData.attachmentAnchorTag}`
      }
    } else if (type === 'enablera') {
      updateType = 'Enable Risk Assessment'
      reqDetails = {
        Type: updateType,
        Application: formData.toolName,
        'Project Key': formData.projectKey,
        'Issue Type': formData.selectedIssueTypes,
        'GxP Compliance Risk': formData.gxpComplianceRisk,
        'Supplier Audit Assessment': formData.supplierAuditAssessment,
        'Requested Date': date.toString(),
        'Created By': user.name.toUpperCase(),
        Comment: `${formData.additionalInfo} ${formData.attachmentAnchorTag}`
      }
    } else if (type === 'enablestructure') {
      updateType = 'Enable Structure'
      reqDetails = {
        Type: updateType,
        Application: formData.toolName,
        'Project Key': formData.projectKey,
        'Requested Date': date.toString(),
        'Created By': user.name.toUpperCase(),
        Comment: `${formData.additionalInfo} ${formData.attachmentAnchorTag}`
      }
    } else if (type === 'updateproject') {
      updateType = 'Update Existing Project'
      reqDetails = {
        Type: updateType,
        Application: formData.toolName,
        'Project Key': formData.projectKey,
        'Requested Date': date.toString(),
        'Created By': user.name.toUpperCase(),
        Comment: `${formData.additionalInfo} ${formData.attachmentAnchorTag}`
      }
    }

    input = {
      request_for: formData.requestForName,
      assgn_grp: 'AS FRAD TOOLS L2',
      task_srt: updateType,
      req_det: reqDetails,
      sla: '5',
      application: formData.toolName
    }

    if (formData.additionalInfo.length === 0) {
      input.req_det.Comment = `NA ${formData.attachmentAnchorTag}`
    }

    setSubmitLoader(true)
    SupportService.ticketCreation(input).then((response) => {
      setSubmitLoader(false)
      if (response.data.body.result.statusCode === 200 ||
          response.data.body.result.statusMessage === 'Success') {
        setSupport({
          success: true,
          message: `IRIS request submitted successfully and
           your request number is ${response.data.body.result
    .returnResponse.requestNumber} - ${response.data.body.result
  .returnResponse.requestedItem}`
        })
      } else {
        setSupport({
          success: false,
          message: 'Something went wrong. Contact system administrator!'
        })
      }
      setPath({
        path: '/support',
        component: SupportComponent
      })
      props.history.push('/support')
    }, (error) => {
      setLoading(false)
      if (error) {
        setSupport({
          success: false,
          message: 'Something went wrong. Contact system administrator!'
        })
      }
    })
  }

  /* to close the alert box */
  const handleClose = () => {
    setAlertwithok(false)
  }

  return (
    <div>
      <div>
        {showAlert()}
      </div>
      <div>
        {loading || submitLoader ? <LinearProgress /> : null}
        {' '}
        <Grid container spacing={1} className={classes.root}>
          <Grid item xs={12} md={12}>
            <Card variant='outlined'>
              <ValidatorForm
                ref={form}
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardContent>
                  <Typography variant='h6' gutterBottom display='block'>
                    {showTitle()}
                  </Typography>
                  <CardActions className={classes.pullRight}>
                    <Button
                      variant='contained'
                      component={Link}
                      to='/Support'
                      onClick={() => setPath({
                        path: '/Support',
                        component: SupportComponent
                      })}
                    >
                      Back
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      type='submit'
                      disabled={loading || submitLoader || user.switch_user}
                    >
                      Submit Request
                    </Button>
                  </CardActions>
                  <div style={{
                    paddingTop: 10
                  }}
                  >
                    <FormControl
                      className={`
                        ${classes.formControl} ${classes.columnDisplay}
                      `}
                    >
                      <Typography
                        htmlFor='requestForLabel'
                        variant='subtitle1'
                        display='block'
                        gutterBottom
                        className={classes.labelDisplay}
                      >
                        Request For
                        <span className={classes.error}> *</span>
                      </Typography>
                      <Autocomplete
                        id='request-for-auto-complete'
                        defaultValue={formData.requestFor}
                        className={classes.inputDisplay}
                        options={allUsers}
                        getOptionLabel={(option) => (option.name
                          ? `${option.name} - ${option.displayname}`
                          : option)}
                        loading={allUsers.length === 0 && loadingOptions}
                        getOptionSelected={(option,
                          userValue) => option.name === userValue.name}
                        onChange={(event, userValue) => changeUser(userValue)}
                        filterOptions={(options, state) => options}
                        disabled
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            name='requestFor'
                            onChange={(event) => getUsers(event.target.value)}
                            value={formData.requestFor}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            disabled
                          />
                        )}
                      />
                    </FormControl>

                    {type === 'updateproject' ? (
                      <FormControl
                        className={`
                          ${classes.formControl} ${classes.columnDisplay}
                        `}
                      >
                        <Typography
                          htmlFor='toolLabel'
                          className={classes.labelDisplay}
                        >
                          Tool
                          <span className={classes.error}> *</span>
                        </Typography>

                        <div style={{
                          width: '70%'
                        }}
                        >
                          <SelectValidator
                            fullWidth
                            name='toolName'
                            onChange={handleChange}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            value={formData.toolNameDisplayValue
                              ? formData.toolNameDisplayValue
                              : ''}
                          >
                            {allTools.map((value, index) => (
                              <MenuItem value={value} key={value.name}>
                                {value.name}
                              </MenuItem>
                            ))}
                          </SelectValidator>
                        </div>
                      </FormControl>
                    ) : null}

                    <FormControl
                      className={`
                        ${classes.formControl} ${classes.columnDisplay}
                      `}
                    >
                      <Typography
                        htmlFor='projectLabel'
                        className={classes.labelDisplay}
                      >
                        Project Key
                        <span className={classes.error}> *</span>
                      </Typography>
                      <Autocomplete
                        id='project-auto-complete'
                        className={classes.inputDisplay}
                        options={allProjects}
                        getOptionLabel={
                          (option) => (option.key
                            ? `${option.key} - ${option.key_app_name}`
                            : option)
                        }
                        loading={allProjects.length === 0 && loadingOptions}
                        getOptionSelected={(option,
                          userValue) => option.key === userValue.key}
                        onChange={(event,
                          userValue) => changeProject(userValue)}
                        value={formData.projectKey}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            name='projectKey'
                            onChange={(event) => getProjects(formData.toolName,
                              event.target.value)}
                            value={formData.projectKeyValue}
                            validators={type === 'enablexray'
                              ? ['required', 'checkXrayEnabled']
                              : ['required']}
                            errorMessages={type === 'enablexray'
                              ? ['This field is required',
                                'X-Ray already enabled for this project']
                              : ['This field is required']}
                          />
                        )}
                      />
                    </FormControl>

                    {type === 'enablera' ? (
                      <>
                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='issueTypeLabel'
                            className={classes.labelDisplay}
                          >
                            Issue Type
                            <span className={classes.error}> *</span>
                            <Tooltip title={(
                              <span style={{
                                fontSize: 12,
                                fontFamily: 'Verdana'
                              }}
                              >
                                Generally applied for Story or Feature
                              </span>
                            )}
                            >
                              <InfoIcon
                                fontSize='small'
                                style={{
                                  color: '#eea508'
                                }}
                              />
                            </Tooltip>
                          </Typography>
                          <MultipleSelect
                            className={classes.inputDisplay}
                            handleChange={handleIssueTypeSelect}
                            options={raIssueTypes}
                            selectedValue={formData.selectedIssueTypes}
                          />
                        </FormControl>

                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='gxpComplianceRiskLabel'
                            className={classes.labelDisplay}
                          >
                            GxP Compliance Risk
                            <span className={classes.error}> *</span>
                          </Typography>
                          <div style={{
                            width: '70%'
                          }}
                          >
                            <SelectValidator
                              fullWidth
                              name='gxpComplianceRisk'
                              onChange={handleChange}
                              validators={['required']}
                              errorMessages={['This field is required']}
                              value={formData.gxpDisplayValue}
                            >
                              {gxpComplianceRisk.map((value, index) => (
                                <MenuItem value={value} key={value.name}>
                                  {value.name}
                                </MenuItem>
                              ))}
                            </SelectValidator>
                          </div>
                        </FormControl>

                        <FormControl
                          className={`
                            ${classes.formControl} ${classes.columnDisplay}
                          `}
                        >
                          <Typography
                            htmlFor='supplierAuditAssessmentLabel'
                            className={classes.labelDisplay}
                          >
                            Supplier Audit Assessment
                            <span className={classes.error}> *</span>
                          </Typography>
                          <div style={{
                            width: '70%'
                          }}
                          >
                            <SelectValidator
                              fullWidth
                              name='supplierAuditAssessment'
                              onChange={handleChange}
                              validators={['required']}
                              errorMessages={['This field is required']}
                              value={formData.supplierAuditDisplayValue}
                            >
                              {supplierAudit.map((value, index) => (
                                <MenuItem value={value} key={value.name}>
                                  {value.name}
                                </MenuItem>
                              ))}

                            </SelectValidator>
                          </div>
                        </FormControl>
                      </>
                    ) : null}

                    <FormControl
                      className={`
                        ${classes.formControl} ${classes.columnDisplay}
                      `}
                    >
                      <Typography
                        htmlFor='additionalInfoLabel'
                        className={classes.labelDisplay}
                      >
                        {type === 'updateproject' ? (
                          <>
                            Update Request
                            <span className={classes.error}> *</span>
                          </>
                        ) : (
                          <>
                            Additional Information
                          </>
                        )}
                      </Typography>
                      <div style={{
                        width: '70%'
                      }}
                      >
                        <TextValidator
                          name='additionalInfo'
                          onChange={handleChange}
                          value={formData.additionalInfo}
                          multiline
                          rows={1}
                          rowsMax={4}
                          fullWidth
                          validators={type === 'updateproject'
                            ? (['required'])
                            : null}
                          errorMessages={type === 'updateproject'
                            ? (['This field is required'])
                            : null}
                        />
                      </div>
                    </FormControl>

                    {type === 'updateproject' ? (
                      <FormControl
                        className={`
                          ${classes.formControl} ${classes.columnDisplay}
                        `}
                      >
                        <Typography
                          variant='subtitle1'
                          className={classes.templateColDisplay}
                        >
                          Click
                          {' '}
                          <span
                            className={classes.here}
                            onClick={downloadTemplate}
                          >
                            here
                          </span>
                          {' '}
                          to download approval workflow template
                        </Typography>
                      </FormControl>
                    ) : null}

                    <FormControl
                      className={`
                        ${classes.formControl} ${classes.attachmentColDisplay}
                      `}
                    >
                      <Typography
                        variant='subtitle1'
                        className={classes.labelDisplay}
                      >
                        Attachments
                        {' '}
                        <small>
                          (
                          <i>File size should not exceed 10 MB </i>
                          )
                        </small>
                      </Typography>
                      <br />
                      <input
                        type='file'
                        name='fileUplaod'
                        id='fileUpload'
                        multiple
                        className={classes.labelDisplay}
                        // renderInput={(params) => params.join(', ')}
                        onChange={(event) => setFiles(files
                          .concat(Array.from(event.target.files)))}
                      />
                      {alertAttachment === true ? (
                        <span className={classes.error}>
                          Please attach file smaller than 10 MB.
                        </span>
                      ) : null}
                      <FileManager files={files}>
                        {uploadFiles}
                      </FileManager>
                    </FormControl>
                  </div>
                </CardContent>
              </ValidatorForm>
            </Card>
            <AlertDialog
              handleClose={handleClose}
              alertopen={alertwithok}
              key='alert'
              message={alertboxMessage}
              okbuttonalert
              title='Alert'
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
